import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  recorderContainer: {

  },
  inputFieldfilename: {
    width: '237px',
    marginRight: '10px', color: '#787878',
    [theme.breakpoints.down('xs')]: {
      width: '190px',
      marginRight: '10px'
    }
  },
  playaudioTrack: {
    width: '432px',
    [theme.breakpoints.down('xs')]: {
      width: '250px', height: 35
    }
  },
  audioTrack: {
    width: '500px', background: '#E9ECED',
    [theme.breakpoints.down('xs')]: {
      width: '220px', height: 35
    }
  },
  audioTrackActive: {
    width: '500px', background: 'rgba(118, 144, 238, 0.5)',
    [theme.breakpoints.down('xs')]: {
      width: '220px', height: 35
    }
  },
  selected: {
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    background: 'rgba(118, 144, 238, 0.5)',
    border: '2px solid #4D72F8',
    // borderRadius: '4px'
    borderTopLeftRadius: '4px',
    borderTopRightRadius: 'none',
    borderBottomLeftRadius: 4,
    // borderBottomRightRadius:4
  },
  unSelected: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
    border: '1px solid #F0F3F5'
  },
  selectedd: {
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    background: 'rgba(118, 144, 238, 0.5)',
    border: '2px solid #4D72F8',
    borderTopLeftRadius: 'none',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    width: '40px', height: '58px',
    [theme.breakpoints.down('xs')]: {
      height: 39
    }
    //borderRadius: '4px',
  },
  unSelectedd: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
    border: '1px solid #F0F3F5',
    width: '40px', height: '56px',
    [theme.breakpoints.down('xs')]: {
      height: 39
    }
  },
  uploadContainer: {
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
  },
  dFlex: {
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  minutes: {
    fontFamily: 'Work Sans ! important',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    color: '#8F8E8E',
    marginTop: '10px',
    lineHeight: '19px'
  },
  filenameFiled: {
    width: '237px',
    height: '35px',
    background: '#FFFFFF',
    border: '0.349264px solid #848282',
    borderRadius: '3px',
  },
  filename: {
    fontfamily: 'Calibri',
    fontstyle: 'normal',
    fontweight: 400,
    fontsize: '14px',
    color: '#848282',
  },
  saveBtn: {
    width: '93px',
    height: '35px',
    background: '#4D72F8',
    borderRadius: '3px',
    color: "#FFFFFF",
    textTransform: 'capitalize'
  },
  saveAsdefaultBtn: {
    width: '170px',
    height: '42px',
    background: '#4D72F8',
    borderRadius: '3px',
    color: "#FFFFFF",
    textTransform: 'capitalize'
  },
  card: {
    width: '100%',
    margin: '20px 0px 20px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  divider: {
    margin: 5,
    backgroundColor: '#E2E2E2',
  },
  gridItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItem1: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  audioRecord: {
    marginLeft: 10,
  },
  div: {
    display: 'grid',
    textAlign: 'center',
  },
  time: {
    //margin: 10,
    fontSize: 20,
  },
  card1: {
    width: 430,
    height: 550,
  },
  cancelIcon: {
    marginRight: 10,
  },
  voiceIcon: {
    border: '0px solid #F8F8F8',
  },
  settingIcon: {
    width: 50,
    height: 50,
    background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)',
    border: '1px solid #F8F8F8',
    borderRadius: 50,
    marginTop: 10,
  },
  divButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 20,
    marginTop: 20,
  },
  recordButton: {
    background: '#FFFFFF',
    border: '1px solid #F2F2F2',
    boxSizing: 'border-box',
    borderRadius: 10,
    margin: 10,
    fontSize: 12,
    padding: '4px 16px',
    textTransform: 'capitalize',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#828282',
  },
  pausedButton: {
    background: '#EDEDED',
    border: '1px solid #D0D0D0',
    boxSizing: 'border-box',
    borderRadius: 10,
    margin: 10,
    fontSize: 12,
    padding: '4px 16px',
    textTransform: 'capitalize',
    color: '#B7B7B7',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500
  },
  recordFile: {
    margin: '10px 0px'
  },
  image: {
    width: '100%',
    height: '60%',
    border: '0.657778px solid #F3F5FF',
    boxSizing: 'border-box',
    borderRadius: '6.57778px',
  },
  display: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  deleteButton: {
    margin: 10,
    color: '#696969',
    background: '#F4F4F4',
    border: ' 1px solid #F4F4F4',
    borderRadius: '4px',
    padding: "5px 34px",
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  saveButton: {
    //margin: 10,
    color: '#696969',
    background: '#F4F4F4',
    border: ' 1px solid #F4F4F4',
    borderRadius: '5px',
    padding: "5px 30px",
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  iconSize: {
    fontSize: 2,
  },
  icons: {},
  crop: {
    background: '#F4F4F4',
    border: '1px solid #F4F4F4',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '1px',
    width: '100%',
  },
  prettoSlider: {
    marginRight: 20,
  },
  timeSlider: {
    display: 'flext',
  },
  volcol: {
    color: '#4D72F8',
  },
}));

export const PrettoSlider = withStyles({
  root: {
    color: 'blue',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    border: '1px solid #DDDDDD',
    background: '#FFFFFF',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 2px 10px rgba(213, 213, 213, 0.25)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

import React from 'react';
import { Card, CardContent, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, Popover, Box, CircularProgress, Divider, ListItemText, Popper, Button, TextField, FormControlLabel, Radio } from '@material-ui/core';
import ReactSelect from 'react-select';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from 'recharts';
import DateRangePicker from "react-daterange-picker";
import { addDays, endOfDay, getUnixTime, startOfDay, subDays } from 'date-fns';
import outgoing from '../../assets/icons/outgoingcalls.png';
import incoming from '../../assets/icons/incomingcalls.png';
import axiosInstance from '../../environment/axiosInstance'

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useStyles } from './Analytics/style';
import './Analytics/styles.css';
import dayjs from 'dayjs';
import moment from "moment";




function CallVolumneOverTime(props: any) {
  const classes = useStyles()
  const history = useHistory()
  const [AllcallschartDataa, setAllcallschartDataa] = React.useState<any>([])
  const [type, setype] = React.useState<any>(1)
  const [loading, setLoading] = React.useState<any>(false)
  const custom_startDate = dayjs('2024-05-01');
  const custom_endDate = dayjs('2024-05-10');
  const startDate = moment(custom_startDate.toDate());
  const endDate = moment(custom_endDate.toDate());
  const [dateRange, setDateRange] = React.useState<any>({ startDate, endDate, });
  const [openCustomPicker, setopenCustomPicker] = React.useState(false)
  const anchorRef = React.useRef(null);


  const officeId = localStorage.getItem('SelectedCompanyId')
  
  const keyOptions = [
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "Last 7 days", value: 3 },
    { label: "Last 30 days", value: 4 },
    { label: "This month ", value: 5 },
    { label: "Last month", value: 6 },
    { label: "Custom", value: 7 },

  ]

  const open = Boolean(openCustomPicker);
  const IDBUT = open ? 'simple-popover' : undefined;

  // GRAPH
  const allCallsChartData = (type:any) => {
    setLoading(true)
    let url = `/dashboard/new-analytics-graph?type=${type}`
    // if (props.location.search) {
    //   url = `/dashboard/new-analytics-graph${props.location.search}`;
    // }
    if (officeId) {
      url += `?officeId=${officeId}`
    }
    if (type == 7 && localStorage.getItem("dbVolumetStartDate") && localStorage.getItem("dbVolumeEndDate")) {
        url += `&startDate=${localStorage.getItem("dbVolumetStartDate")}&endDate=${localStorage.getItem("dbVolumeEndDate")}`;
    }
    axiosInstance.get(url).then((res: any) => {
      if (res.status === 200) {
        const data = res.data.data
        setAllcallschartDataa(data)
        setLoading(false)
      }
    })
  }

  const userStats: any = localStorage.getItem('dbVolumeStats')
  const us = parseInt(userStats)


  React.useEffect(() => {
    allCallsChartData(type)
    setype(us)
  }, [])

  const handleOptions = (e: any) => {
    localStorage.setItem('dbVolumeStats', e.value)
    setype(e.value)

    if (e.value === 7) {
      setopenCustomPicker(true)
    }
    else {
        allCallsChartData(e.value)
    }

  }

  
  const handleCustomPickerClose = () => {
    setopenCustomPicker(false);
  };

  // Handler for changing date range
  const handleSelect = (range: any) => {
    const startDate = range?.start?.format("YYYY-MM-DD");
    const endDate = range?.end?.format("YYYY-MM-DD");
    localStorage.setItem('dbVolumetStartDate', startDate);
    localStorage.setItem('dbVolumeEndDate', endDate);
    setDateRange(range);
  };

  const applyPayPeriod = () => {
    allCallsChartData(type)
  }


  return (
    <div> 
        <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5} className={classes.cardContent}>
            <Grid item md={9} sm={9} className={classes.grid}>
              <Typography className={classes.userText}>Call Volume Over Time </Typography>
            </Grid>
        


            <Grid item md={3} sm={3} xs={12} className={`classes.gridItem ${classes.width}`}>
              <ReactSelect placeholder={'Today'} options={keyOptions}
                onChange={handleOptions}
                value={keyOptions.filter((opt: any) => opt.value === type)} />
            </Grid>
            <Popover
              //   className={classes.Popover}
              id={IDBUT}
              open={open}
              anchorEl={anchorRef.current}
              onClose={handleCustomPickerClose}
              anchorOrigin={{
                vertical: 'top', // Align to the top of the anchor element
                horizontal: 'right', // Align to the left side of the anchor element
              }}
              transformOrigin={{
                vertical: 'bottom', // Align to the top of the popover content
                horizontal: 'right', // Align to the left side of the popover content
              }}
            >
              <Grid container >
                <Grid
                  style={{ borderLeft: "1px solid lightgray" }}
                  item sm={8} xs={8}
                >
                  {/* <DateRangePicker  
                         value={value}                        
                           locale="en-US"                                              
                            onSelect={onSelectStartEndDate}
                            singleDateRange={true}
                            /> */}
                  <div className="date-range-picker-container">
                    <DateRangePicker
                      onSelect={handleSelect}
                      value={dateRange}
                      singleDateRange={true}
                    />
                  </div>

                  <Grid
                    sm={12}
                    style={{ textAlign: "center", margin: "5px" }}
                  >
                    <button
                      className={classes.button}
                      onClick={applyPayPeriod}
                    >
                      Apply
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </CardContent>
        <Divider style={{ marginTop: -5 }} />
     
          <>
          <div style={{ marginTop: 20, borderRadius: 10,  padding: 2 }}>
        <Grid style={{ marginBottom: 20 }}>
        {loading ? 
            <Box>
            <div style={{ width: "100%", textAlign: "center" }}>
              <CircularProgress />
            </div>
          </Box>
            :
            <div style={{ marginTop: 20, borderRadius: 10, padding: 2 }}>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item md={12} sm={12} xs={12} className={classes.select}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={2}>
                      <Grid item md={12} className={classes.cardContent}>
                        <img src={incoming} alt="incoming" />
                        <Typography className={classes.incomingText}>Total Calls</Typography>
                        <img src={outgoing} alt="outgoing" />
                        <Typography className={classes.outgoingText}>Answered Calls</Typography>
    
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Typography className={classes.areaText}>Call Volume Over Time</Typography>
                  <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item md={12} className={classes.gridItem}>
                      <AreaChart width={1130} height={400} data={AllcallschartDataa}>
                        <XAxis
                          interval={0}
                          axisLine={false}
                          tickLine={false}
                          dataKey="lv"
                          padding={{ left: 20, right: 20 }}
                        />
                        <YAxis dataKey="sv" tickCount={10} axisLine={false} tickLine={false} />
                        <Tooltip />
                        <CartesianGrid vertical={false} strokeDasharray="3,3" />
                        <Area
                          type="natural"
                          dataKey="totalCalls"
                          stroke="green"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                        <Area
                          type="monotone"
                          dataKey="answeredCalls"
                          stroke="gold"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                        <Area
                          type="monotone"
                          dataKey="missedCalls"
                          stroke="#4D72F8"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                        <Area
                          type="monotone"
                          dataKey="abandonedCalls"
                          stroke="blue"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                        <Area
                          type="monotone"
                          dataKey="unansweredforwarded"
                          stroke="blueviolet"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                        <Area
                          type="monotone"
                          dataKey="outboundConnected"
                          stroke="gray"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                        <Area
                          type="monotone"
                          dataKey="outboundCancelled"
                          stroke="orange"
                          fill="rgba(51, 108, 251, 0.2)"
                        />
                      </AreaChart>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </div>
          }

        </Grid>
     
      </div>
          </>
        
      </Card>
    
    </div>
  )
}

export default CallVolumneOverTime
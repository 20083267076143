import React from 'react';
import { useStyles } from './style';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    Avatar,
    Typography,
    TextField,
    Tooltip,
    TextareaAutosize,
    Table,

    Button,
    Switch,
    IconButton,
    withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import callTransfer from '../../assets/images/icon-call-transfer.png'
import { VscAdd } from "react-icons/vsc";
import { AiOutlineMinus, AiOutlineClose } from 'react-icons/ai'
import { FaUserPlus } from 'react-icons/fa';
import { MdSend, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd } from 'react-icons/md';
import { BiCheckDouble, BiPlay, BiMinusBack, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown, BiRectangle } from 'react-icons/bi';
import { RiEditBoxFill, RiUser3Fill, RiPauseCircleFill } from 'react-icons/ri';
import Ellipse from '../../assets/icons/Ellipse.png';
import { VscChromeMaximize } from 'react-icons/vsc'
import { AiFillCloseCircle, AiFillStar, AiOutlineEdit, AiOutlinePause } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr'
import { InputAdornment } from '@material-ui/core';
import { ImBlocked, ImPhoneHangUp } from 'react-icons/im';
import { IoIosClose, IoMdKeypad } from 'react-icons/io'
import { BsFillCaretDownFill, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { CgUserAdd } from 'react-icons/cg';
import CallParkIcon from '../../assets/icons/call-park.png'
import { FcCallTransfer } from 'react-icons/fc';
import { GrAdd } from 'react-icons/gr';
import Modal from '@material-ui/core/Modal';
// import { CardHeader } from '../Header/CardHeader';
import { ImRedo2 } from 'react-icons/im'
import SaveIcon from '@material-ui/icons/Save';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TiArrowForward } from 'react-icons/ti';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Popup from "reactjs-popup";
import { useSelector, useDispatch } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
// import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import * as CallActionCreator from '../../store/Actions/CallActionCreator'


export default function NewOutgoing(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [dtmfKeypadIsVisible, setDtmfKeypadIsVisible] = React.useState<boolean>(false);
    const [dtmfNumber, setDtmfNumber] = React.useState<any>("");
    const callingData: any = useSelector((state: any) => state.CallingData);
    const [transferOpen, setTransferOpen] = React.useState<boolean>(false);
    const [sessionData, setSessionData] = React.useState<any>()
    const [mute, setMute] = React.useState<boolean>(false);
    const [hold, setHold] = React.useState<boolean>(false);
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");
    const [transferModalStyle] = React.useState(getTransferModalStyle);
    const pokemon = require('../../assets/sounds/diaal.mp3');
    const audio = new Audio(pokemon)
    const handleTranferOpen = () => {
        setTransferOpen(true);
    }
    const handleTransferClose = () => {
        setTransferOpen(false);
    }

    const handleMute = () => {
        setMute(!mute)
        if (mute) {
            sessionData.unmute()
        } else {
            sessionData.mute()
        }
    }
    const handleHold = () => {
        setHold(!hold)
        if (hold) {
            sessionData.unhold()
        } else {
            sessionData.hold()
        }
    }
    function getTransferModalStyle() {
        const top = 15;
        const left = 30;
        const right = 30;

        return {
            top: `${top}%`,
            left: `${left}%`,
            right: `${right}%`,
            transform: `translate(-${top}%, -${left}%, -${right}%)`,
        };
    }

    const handleEndCall = () => {
        dispatch(CallActionCreator.fetchStatus(null))
        dispatch(CallActionCreator.fetchData(false))
        props.parentEndCall(false)
        props.coolPhone.terminateSessions()
    }
    const handleMinimise = () => {
        props.parentMinimise(false)
    }
    const handleCall = () => {
        //alert('kjkkj')
        const audio: any = new window.Audio()
        console.log('000', callingData?.callData?.options);

        var session = props?.coolPhone?.call(callingData?.callData?.phoneNumber, callingData?.callData?.options)
        setSessionData(session)
        console.log('session', session)
        if (session.connection) {
            console.log('Connection is valid')
            session.connection.addEventListener('addstream', (e: any) => {
                console.log('Add stream')
                audio.srcObject = e.stream
                audio.play()
            })
            session.on('addstream', function (e: any) {
                // set remote audio stream (to listen to remote audio)
                // remoteAudio is <audio> element on page
                const remoteAudio = audio
                remoteAudio.src = window.URL.createObjectURL(e.stream);
                remoteAudio.play();
            });

            session.connection.addEventListener('peerconnection', (e: any) => {
                console.log('Peer connection')
                audio.srcObject = e.stream
                audio.play()
            })
        } else {
            console.log('Connection is null')
        }
    }
    React.useEffect(() => {
        handleCall()
    }, [])

    console.log(callingData)

    const handleNumber = (num: any) => {
        // audio.load()
        // audio.muted = false;
        var promise = audio.play();
        if (promise) {
            //Older browsers may not return a promise, according to the MDN website
            promise.catch(function (error) { console.error(error); });
        }

    }

    const [showDialPad, setDialPad] = React.useState(false)

    const handleDtmf = () => {
        setDtmfKeypadIsVisible(!dtmfKeypadIsVisible);
    }

    const sendDTMF = (digit: any) => {
        setDtmfNumber(dtmfNumber.concat(digit));
        sessionData.sendDTMF(digit);
    }

    return (
        <Card style={{ backgroundColor: 'white' }}>
            <div className={classes.newJhon}>
                <Typography className={classes.wick}>{callingData?.callData?.name}</Typography>
            </div>
            <div className={classes.numberOne}>
                {/* <Typography className={classes.numberTwo}>{callingData?.callData?.phoneNumber}</Typography> */}
            </div>
            {props.callStatus ?
                <div className={classes.timeDivTwo}>
                    <Typography className={classes.timeContentTwo} >{props.timer?.m + " : " + props.timer?.s}</Typography>
                </div> :
                <div className={classes.timeDivTwo}>
                    <Typography className={classes.timeContentTwo}>Ringing...</Typography>
                </div>
            }
            <div className={classes.dailDiv}>
                <div onClick={handleEndCall}>
                    <IconButton className={classes.filledIcon} style={{ background: 'red', color: 'white' }}>
                        <MdCallEnd />
                    </IconButton>
                </div>
                <div onClick={handleDtmf}>
                    <IconButton className={classes.filledIcon} style={{ backgroundColor: '#2645AD', color: 'white' }}>
                        <IoMdKeypad />
                    </IconButton>
                </div>
                {
                    dtmfKeypadIsVisible && (
                        <div className={classes.dtmf}>
                            <div className={classes.dtmfRow}>
                                <p className={classes.dtmfNumber}>{dtmfNumber}</p>
                            </div>
                            <div className={classes.dtmfRow}>
                                <button
                                    className={classes.dtmfBtn}
                                    value="1"
                                    onClick={() => sendDTMF('1')}
                                >1</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="2"
                                    onClick={() => sendDTMF('2')}
                                >2</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="3"
                                    onClick={() => sendDTMF('3')}
                                >3</button>
                            </div>
                            <div className={classes.dtmfRow}>
                                <button
                                    className={classes.dtmfBtn}
                                    value="4"
                                    onClick={() => sendDTMF('4')}
                                >4</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="5"
                                    onClick={() => sendDTMF('5')}
                                >5</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="6"
                                    onClick={() => sendDTMF('6')}
                                >6</button>
                            </div>
                            <div className={classes.dtmfRow}>
                                <button
                                    className={classes.dtmfBtn}
                                    value="7"
                                    onClick={() => sendDTMF('7')}
                                >7</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="8"
                                    onClick={() => sendDTMF('8')}
                                >8</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="9"
                                    onClick={() => sendDTMF('9')}
                                >9</button>
                            </div>
                            <div className={classes.dtmfRow}>
                                <button
                                    className={classes.dtmfBtn}
                                    value="*"
                                    onClick={() => sendDTMF('*')}
                                >*</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="0"
                                    onClick={() => sendDTMF('0')}
                                >0</button>
                                <button
                                    className={classes.dtmfBtn}
                                    value="#"
                                    onClick={() => sendDTMF('#')}
                                >#</button>
                            </div>
                        </div>
                    )
                }
                {mute ?
                    <div onClick={handleMute}>
                        <IconButton className={classes.voicemuteBoard} style={{ backgroundColor: '#2645AD', color: 'white' }}>
                            <BsFillMicMuteFill />
                        </IconButton>
                    </div>
                    :
                    <div onClick={handleMute}>
                        <IconButton className={classes.voicemuteBoard} style={{ backgroundColor: '#2645AD', color: 'white' }}>
                            <BsFillMicFill />
                        </IconButton>
                    </div>
                }
                {/* dialpad */}

                {/* <IconButton className={classes.voicemuteBoard} style={{ backgroundColor: 'green', color: 'white' }}> */}
                {/* <Popup
                        trigger={
                        <IconButton className={classes.voicemuteBoard} style={{ position: 'inherit',backgroundColor: '#2645AD', color: 'white' }}>
                                <MdOutlineDialpad />
                            </IconButton>
                        }
                        position="bottom right"
                    > */}
                {/* <div onClick={() => {
                    setDialPad(!showDialPad)
                }}>
                    <IconButton className={classes.voicemuteBoard} style={{ position: 'inherit', backgroundColor: '#2645AD', color: 'white' }}>
                        <MdOutlineDialpad />
                    </IconButton>
                </div> */}


                {/* </Popup> */}
                {/* </IconButton> */}


                {/* end */}


                {/* {hold ?
                    <div onClick={handleHold} style={{}}>
                        <IconButton className={classes.voicemuteBoard} style={{ backgroundColor: 'green', color: 'white' }}>
                            <BiPlay />
                        </IconButton>
                    </div> :
                    <div onClick={handleHold}>
                        <IconButton className={classes.voicemuteBoard} style={{ backgroundColor: 'green', color: 'white' }}>
                            <AiOutlinePause />
                        </IconButton>
                    </div>
                } */}
                {/* <div>
                        <IconButton className={classes.voiceBoard}
                        onClick={handleTranferOpen}
                        >
                            <FcCallTransfer color="lightgrey" />
                            <img src={callTransfer} alt="" width="23px" height="23px" />
                        </IconButton>
                        <Typography className={classes.muteOne}>Transfer</Typography>
                    </div> */}

            </div>
            <div>
                {
                    showDialPad && (
                        <div>
                            <Card style={{ padding: "10px 20px 10px 0px", background: 'rgb(163, 153, 152)' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(1)}
                                    >
                                        <Typography className={classes.number}>1</Typography>

                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(2)}
                                    >
                                        <Typography className={classes.number}>2</Typography>
                                        {/* <Typography className={classes.text}>abc</Typography> */}
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(3)}
                                    >
                                        <Typography className={classes.number}>3</Typography>
                                        {/* <Typography className={classes.text} >def</Typography> */}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(4)}
                                    >
                                        <Typography className={classes.number}>4</Typography>
                                        {/* <Typography className={classes.text}>ghi</Typography> */}
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(5)}
                                    >
                                        <Typography className={classes.number}>5</Typography>
                                        {/* <Typography className={classes.text}>jkl</Typography> */}
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(6)}
                                    >
                                        <Typography className={classes.number}>6</Typography>
                                        {/* <Typography className={classes.text} >mno</Typography> */}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(7)}
                                    >
                                        <Typography className={classes.number}>7</Typography>
                                        {/* <Typography className={classes.text}>pqrs</Typography> */}
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(8)}
                                    >
                                        <Typography className={classes.number}>8</Typography>
                                        {/* <Typography className={classes.text}>tuv</Typography> */}
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(9)}
                                    >
                                        <Typography className={classes.number}>9</Typography>
                                        {/* <Typography className={classes.text} >wxyz</Typography> */}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber('*')}
                                    >
                                        <Typography className={classes.number}>*</Typography>
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber(0)}
                                    >
                                        <Typography className={classes.number}>0</Typography>
                                        {/* <Typography className={classes.text}>+</Typography> */}
                                    </div>
                                    <div className={classes.iconNumbersOne}
                                        onClick={() => handleNumber('#')}
                                    >
                                        <Typography className={classes.number}>#</Typography>
                                    </div>
                                </div>

                            </Card>
                        </div>
                    )
                }
            </div>
            <div className={classes.addDiv}>
                {/* {hold ?
                    <div onClick={handleHold} style={{ marginLeft: 50 }}>
                        <IconButton className={classes.voiceBoard}>
                            <BiPlay />
                        </IconButton>
                        <Typography className={classes.muteOne} style={{ marginLeft: -1 }}>Unhold</Typography>
                    </div> :
                    <div onClick={handleHold}>
                        <IconButton className={classes.voiceBoard} style={{ marginLeft: 50 }}>
                            <AiOutlinePause />
                        </IconButton>
                        <Typography className={classes.muteOne} style={{ marginLeft: 50 }}>hold</Typography>
                    </div>
                } */}
                {/* <div>
                    <IconButton className={classes.voiceBoard}>
                        <MdOutlineDialpad />
                    </IconButton>
                    <Typography className={classes.muteOne}>Dialpad</Typography>
                </div> */}
            </div>

        </Card>

    )
}

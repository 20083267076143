import React from "react";

import {
    Card,
    Grid,
    Button,
    CardContent,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Table,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
    TextField,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { AiOutlinePlus } from 'react-icons/ai'
import { BsThreeDots } from 'react-icons/bs'
import { useSelector } from "react-redux";
import Departmentservices from "../DepartmentServices/DepartmentServices";
import TableBodyData from "./TableBodyData";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axiosInstance from '../../../environment/axiosInstance';
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
import AddBlockedNumber from "./AddBlockedNumber";

function AdvanceSettings(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true);
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment } = departmentStateData;
    const [blockedNumberData, setBlockedNumberData] = React.useState<any>([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);
    const [advancedSettingData, setAdvancedSettingData] = React.useState<any>([]);
    const [advancedSettings, settAdvancedSettings] = React.useState<any>({
        stickyAgent: " ",
        recordCalls: " ",
        anonymousCallers: " ",
        devicesOfflineEmail: "",
        missedCallsEmail: "",
        devicesOfflineEmailIsChecked: false,
        missedCallsEmailIsChecked: false
    });

    const fetchAdvancedSettingData = () => {
        setLoading(true)
        axiosInstance.get(`departments/${props.match.params.id}/advance-setting`).then((res: any) => {
            if (res.status === 200) {
                setLoading(false)
                const data = res.data.data[0].advanceSetting
                setAdvancedSettingData(res.data.data[0].advanceSetting.blockedNumbers)
                let finalData: any = {
                    stickyAgent: data.stickyAgent,
                    recordCalls: data.recordCalls,
                    anonymousCallers: data.anonymousCallers,
                    devicesOfflineEmail: data.devicesOfflineEmail,
                    missedCallsEmail: data.missedCallsEmail,
                    devicesOfflineEmailIsChecked: data.devicesOfflineEmail ? true : false,
                    missedCallsEmailIsChecked: data.missedCallsEmail ? true : false
                }
                settAdvancedSettings(finalData);
            }
        })
    }



    React.useEffect(() => {
        if (props.match.params.id) {
            fetchAdvancedSettingData()
        }
    }, [props.match.params.id])

    // const getBlockedNumberData = (Departmentid: any) => {
    //     setLoading(true);
    //     Departmentservices.getDepartmentBlockedNumber(Departmentid)
    //         .then((res: any) => {
    //             if (res.data) {
    //                 setLoading(false);
    //                 setBlockedNumberData(res.data);
    //             }
    //         })
    //         .catch((err: any) => {
    //             setLoading(false);
    //             //send err message
    //         });
    // };

    // React.useEffect(() => {
    //     if (props.match.params.id) {
    //         //getBlockedNumberData(props.match.params.id);
    //         fetchAdvancedSettingData()
    //     }
    // }, [props.match.params.id]);


    const updatedAdvancedSetting = (data: any) => {
        Departmentservices.updateAdvancedSettingsCheckbox(props.match.params.id, data).then((res: any) => {
            if (res.success) {
                fetchAdvancedSettingData()
            }
        }).catch((err: any) => {

        })
    }

    const handleChangeOption = (event: any) => {
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                [event.target.name]: !advancedSettings[event.target.name]
            }
        })
        updatedAdvancedSetting({ [event.target.name]: !advancedSettings[event.target.name] })
    };

    const handleChangeDevicesOfflineEmailIsCheckedOption = (event: any) => {
        if (!advancedSettings[event.target.name] == false) {
            settAdvancedSettings((prev: any) => {
                return {
                    ...prev,
                    devicesOfflineEmail: ""
                }
            })
        }
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                [event.target.name]: !advancedSettings[event.target.name]
            }
        })
    }

    const handleMissedCallsEmailIsCheckedChangeOption = (event: any) => {
        if (!advancedSettings[event.target.name] == false) {
            settAdvancedSettings((prev: any) => {
                return {
                    ...prev,
                    missedCallsEmail: ""
                }
            })
        }
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                [event.target.name]: !advancedSettings[event.target.name]
            }
        })
    }

    const handleChangeDevicesOfflineEmail = (event: any) => {
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    };

    const handleChangeMissedCallsEmail = (event: any) => {
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    };

    const expandOpen = () => {
        setOpen(!open);
    }
    const handlePopupModal = () => {
        setOpenModal(!openModal);
    };

    const handleSaveDevicesOfflineEmail = () => {
        updatedAdvancedSetting({
            devicesOfflineEmail: advancedSettings?.devicesOfflineEmail
        })
    }

    const handleSaveMissedCallsEmail = () => {
        updatedAdvancedSetting({
            missedCallsEmail: advancedSettings?.missedCallsEmail
        })
    }

    return (
        <>

            {openModal && (
                <div>
                    <Backdrop handlePopupModal={handlePopupModal} />
                    <Modal title="Add Block Number" handlePopupModal={handlePopupModal}>
                        <div className="add_user_popup" style={{ width: "550px", padding: '0px 10px' }}>
                            <AddBlockedNumber
                                fetchAdvancedSettingData={fetchAdvancedSettingData}
                                handlePopupModal={handlePopupModal}
                                Departmentid={props.match.params.id}

                            />
                            {/* <AddNumberModel
                                getBlockedNumberData={getBlockedNumberData}
                                handlePopupModal={handlePopupModal}
                                Departmentid={props.Departmentid}
                            /> */}
                        </div>
                    </Modal>
                </div>
            )}

            <div id='Advanced Setting'>
                <Card className={classes.card}>
                    <Accordion expanded={open} onChange={expandOpen}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                Advanced Settings
                            </Typography>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails className={classes.accordianNoFlex}>
                            <CardContent style={{ marginTop: -20 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.formPadding}
                                        control={
                                            <Checkbox
                                                checked={advancedSettings?.stickyAgent}
                                                onChange={handleChangeOption}
                                                name="stickyAgent"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <span className={classes.labelText}>
                                                Sticky Agent
                                                <span className={classes.Note}> Note:This features allows you to ensure your callers are always connected to the agent
                                                    he/she last spoke to</span>
                                            </span>
                                        }
                                    />


                                    <FormControlLabel
                                        className={classes.formPadding}
                                        control={
                                            <Checkbox
                                                checked={advancedSettings?.recordCalls}
                                                onChange={handleChangeOption}
                                                name="recordCalls"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <span className={classes.labelText}>
                                                Record Department calls
                                            </span>
                                        }
                                    />
                                </FormGroup>

                            </CardContent>

                            <CardContent style={{ marginTop: -28 }}>
                                <div className={classes.blockedbutton}>
                                    <Typography className={classes.fontPadding}>
                                        Call Blocking & Spam Prevention
                                    </Typography>

                                    <Button
                                        onClick={handlePopupModal}
                                        variant="outlined"
                                        // size="small"
                                        className={classes.button}
                                        startIcon={<AiOutlinePlus style={{ fontSize: '14px' }} />}
                                    >
                                        Add New
                                    </Button>
                                </div>
                                <Typography className={classes.fontPadding2} gutterBottom>
                                    Prevent Certain type of callers from ringing you directely.
                                    Calls and messages from these callers will go to the spam tab
                                    of your inbox. send the following to voice mail and directely
                                    to the spam folder without(sending you) a notification
                                </Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.formPadding}
                                        style={{ marginTop: -6 }}
                                        control={
                                            <Checkbox
                                                checked={advancedSettings?.anonymousCallers}
                                                onChange={handleChangeOption}
                                                name="anonymousCallers"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <span className={classes.labelText}>
                                                Anonymous Callers
                                            </span>
                                        }
                                    />

                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.formPadding}
                                        style={{ marginTop: -6 }}
                                        control={
                                            <Checkbox
                                                checked={advancedSettings?.devicesOfflineEmailIsChecked}
                                                onChange={handleChangeDevicesOfflineEmailIsCheckedOption}
                                                name="devicesOfflineEmailIsChecked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <span className={classes.labelText}>
                                                Send email notification if device goes offline
                                            </span>
                                        }
                                    />
                                    {
                                        advancedSettings?.devicesOfflineEmailIsChecked && (
                                            <TextField
                                                name="devicesOfflineEmail"
                                                label="Email"
                                                variant="outlined"
                                                value={advancedSettings?.devicesOfflineEmail}
                                                style={{ width: "50%" }}
                                                type="email"
                                                onChange={handleChangeDevicesOfflineEmail}
                                            />
                                        )
                                    }
                                    <Button
                                        onClick={handleSaveDevicesOfflineEmail}
                                        variant="outlined"
                                        // size="small"
                                        className={classes.savebutton}
                                    >
                                        Save
                                    </Button>
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        className={classes.formPadding}
                                        style={{ marginTop: -6 }}
                                        control={
                                            <Checkbox
                                                checked={advancedSettings?.missedCallsEmailIsChecked}
                                                onChange={handleMissedCallsEmailIsCheckedChangeOption}
                                                name="missedCallsEmailIsChecked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            <span className={classes.labelText}>
                                                Send email notification if any call is missed
                                            </span>
                                        }
                                    />
                                    {
                                        advancedSettings?.missedCallsEmailIsChecked && (
                                            <TextField
                                                name="missedCallsEmail"
                                                label="Email"
                                                variant="outlined"
                                                value={advancedSettings?.missedCallsEmail}
                                                style={{ width: "50%" }}
                                                type="email"
                                                onChange={handleChangeMissedCallsEmail}
                                            />
                                        )
                                    }
                                    <Button
                                        onClick={handleSaveMissedCallsEmail}
                                        variant="outlined"
                                        // size="small"
                                        className={classes.savebutton}
                                    >
                                        Save
                                    </Button>
                                </FormGroup>
                            </CardContent>
                            <CardContent>
                                <Card>
                                    <TableContainer>
                                        <Table style={{ border: '0.912501px solid #EDEDED' }}>
                                            <TableHead className={classes.tablecolor}>
                                                <TableRow>
                                                    <TableCell className={classes.tableHead}>
                                                        Number
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        Block Date
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        Last Call
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        Block Calls
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        Block SMS
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ border: "0.912501px solid #EDEDED" }}>
                                                {advancedSettingData.map((row: any, i: any) => (
                                                    <TableBodyData
                                                        //advancedSettingData={advancedSettingData}
                                                        fetchAdvancedSettingData={fetchAdvancedSettingData}
                                                        row={row}
                                                        key={i}
                                                        Departmentid={props.match.params.id}
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {loading ?

                                            <Box>
                                                <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                                    <CircularProgress />
                                                </div>
                                            </Box>
                                            :
                                            advancedSettingData.length <= 0 && <p style={{ textAlign: 'center' }}>No Blocked Number found</p>
                                        }
                                    </TableContainer>
                                </Card>
                            </CardContent>
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </div>
        </>
    )
}

export default AdvanceSettings
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import axiosInstance from '../../../../environment/axiosInstance'
import ReactSelect from "react-select";
import { useStyles } from './style'
import Moment from 'moment'
import { Pagination, Stack } from '@mui/material';


function BillingMainAdmin(props:any) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState<any>(false);
  const [transcationOpen, setTransactionsOpen] = React.useState<any>(true);
   const [paymentData, setPaymentdata] = React.useState([]);
  const[stripInvove,setSripInvoice] = React.useState<any>([])
  const [searching, setSearching] = React.useState<any>(false)
  const [callFormData, setCallFormData] = React.useState<any>({
    statusKey:"",
    callStatus: "",
    startDate: new Date().setHours(0, 0, 0, 0),
    callFromDateData: "startDate",
    endDate: new Date().setHours(23, 59, 59, 59),
    remainderDateData: "endDate"
  })
  const paymentOptions = [
    { value: "1", label: "Success" },
    { value: "2", label: "Failure" },
  ]
   const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 100,
        totalPages: 0
    })

  const onContentDueDateChangeEvent = (e: any) => {
    var date = e.target.value
    var datea = Date.parse(date);
    const dateSelected = new Date(date).setHours(0, 0, 0, 0);

    setCallFormData((prev: any) => {
      return {
        ...callFormData,
        startDate: dateSelected,
        callFromDateData: "startDate"
      }
    })
  }

  const getSrtipPayments = (limit:any,page:any) => {
     setLoading(true)
      axiosInstance.get(`admin/billing/${props.location.state}/invoices?limit=${limit}&page=${page}`).then((res:any) => {
        if(res.status === 200 && res.data){
          setLoading(false)
          setSripInvoice(res.data.data)
          setPaginate((prev: any) => {
              return {
                  ...prev,
                  page: res.data.page,
                  totalDocs: res.data.totalDocs,
                  limit: res.data.limit,
                  totalPages: res.data.totalPages
              }
          })
          setSearching(true)
        }
        else {
                setSearching(true)
                setLoading(false)
            }
      }).catch((err) => {
            setSearching(false)
            setLoading(false)
        })
   }

   React.useEffect(()=>{
    getSrtipPayments(paginate.limit,paginate.page)
   },[props.location.state])

  const SearchBilling = () => {
    setLoading(true)
    axiosInstance.get(`/admin/billing/${props.location.state}/invoices?limit=100&${callFormData.statusKey}=${callFormData.callStatus}&${callFormData.callFromDateData}=${callFormData.startDate}&${callFormData.remainderDateData}=${callFormData.endDate}`).then((res: any) => {
      if (res.status === 200 && res.data) {
        setLoading(false)
        setPaymentdata(res.data.data)
      }
    }).catch((err: any) => {
      console.log(err)
      setLoading(false)
    })
  }

  const clearFilter = () => {
    getSrtipPayments(paginate.limit, 1)
    setCallFormData((prev: any) => {
          return {
              ...callFormData,
              statusKey:"",
              callStatus: "",
              startDate: new Date().setHours(0, 0, 0, 0),
              callFromDateData: "startDate",
              endDate: new Date().setHours(23, 59, 59, 59),
              remainderDateData: "endDate"
            }
      })
       setSearching(false)
    }

  const handleChangePage = (data: any, value: any) => {
    setPaginate((prev: any) => {
      return {
        ...prev,
        page: value
      }
    })
    getSrtipPayments(paginate.limit,paginate.page)
  }

 

  const onContentReminderDateChangeEvent = (e: any) => {
    var date = e.target.value
    var datea = Date.parse(date);
    const dateSelected = new Date(date).setHours(23, 59, 59, 59);

    setCallFormData((prev: any) => {
      return {
        ...callFormData,
        endDate: dateSelected,
        remainderDateData: "endDate"
      }
    })
  }
  const displayDates = (dateString: any) => {
    const dateNow = new Date(dateString);
    const year = dateNow.getFullYear();
    const monthWithOffset = dateNow.getMonth() + 1;
    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
    const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
    const materialDateInput = `${year}-${month}-${date}`;
    return materialDateInput
  }
  const onChangeTransactions = () => {
    setTransactionsOpen(!transcationOpen);
  };
  //console.log('id____',props.location.state)
  return (
    <div>
      <Accordion
              style={{ marginTop: 30 }}
              className={classes.cardstyles}
              expanded={transcationOpen}
              onChange={onChangeTransactions}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon style={{ color: "#8392AB" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.clientUpdater}>
                 Billing
                </Typography>
              </AccordionSummary>
              <Divider className={classes.divider} />
              <AccordionDetails>
                <Typography className={classes.filters} style={{ marginTop: 10 }}>Filters</Typography>
              </AccordionDetails>
              <Divider className={classes.divider} />
              <AccordionDetails id="advances-setting">
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <ReactSelect
                      className={classes.selectBox}
                      placeholder={"Select Status "}
                      options={paymentOptions}
                      value={
                        paymentOptions.filter((option: any) =>
                          option.value === callFormData.callStatus)
                      }
                      onChange={(e: any) => setCallFormData((prev: any) => {
                        return {
                          ...callFormData,
                          statusKey:"paymentStatus",
                          callStatus: e.value
                        }
                      })}

                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="dd-mm-yyyy hh:mm ss"
                      fullWidth
                      type="date"
                      defaultValue={displayDates(callFormData.startDate)}
                      name="startDate"
                      className={classes.marginBottom20}
                      onChange={onContentDueDateChangeEvent}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // inputProps={{
                      //   max: dataaa
                      // }}
                    />

                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="dd-mm-yyyy"
                      fullWidth
                      defaultValue={displayDates(callFormData.endDate)}
                      type="date"
                      className={classes.marginBottom20}
                      name="endDate"
                      onChange={onContentReminderDateChangeEvent}
                      // inputProps={{
                      //   max: dataaa
                      // }}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.searchButton}
                        onClick={SearchBilling}
                      >
                        Search
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.clearButton}
                        onClick={clearFilter}
                        // onClick={() => {
                        //   setCallFormData((prev: any) => {
                        //     return {
                        //       ...callFormData,
                        //       statusKey:"",
                        //       callStatus: '',
                        //       startDate: new Date().setHours(0, 0, 0, 0),
                        //       callFromDateData: "startDate",
                        //       endDate: new Date().setHours(23, 59, 59, 59),
                        //       remainderDateData: "endDate"
                        //     }
                        //   })
                        // }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>

              <AccordionDetails>
                <Typography className={classes.totalAmout}>
                  Strip Payments(s)
                </Typography>
              </AccordionDetails>
              <Divider className={classes.divider} />
              <AccordionDetails>
                {loading ? 
                  <div>
                    <Box>
                      <div style={{ width: "100%",marginLeft: '400px', textAlign: "center" }}>
                          <CircularProgress />
                      </div>
                    </Box>
                  </div> :
                  <TableContainer className={classes.cardstyles} style={{ marginTop: 10, }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            Invoice Number
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Amount
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Status
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Created On
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { stripInvove && stripInvove.map((element: any) => {
                          var data = parseInt(element.createdDate)
                          var dataa = Moment(data).format('YYYY-MM-DD HH:mm:ss') 
                          return (
                            <TableRow>
                              <TableCell className={classes.tableBody}>
                                {element.invoiceNumber}
                              </TableCell>
                              <TableCell className={classes.tableBody}>
                                {element.amount}
                                </TableCell>
                              <TableCell className={classes.tableBody}>
                                {element.paymentStatus === 1 ?
                                <div style={{ color: "green" }}> Success
                                </div> :
                                <div style={{ color: "red" }}>Failed</div>}
                                </TableCell>
                              <TableCell className={classes.tableBody}>
                                {dataa}
                              </TableCell>
                            </TableRow>
                          )}
                        )}
                        {
                          stripInvove.length === 0 &&
                          <TableRow>
                            <TableCell className={classes.tableBody} colSpan={5} align="center">
                              There is no data
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                    {stripInvove.length <= 0 ? "" :
                        <Stack spacing={2} style={{ marginTop: 15, float: "right", marginBottom: 15 }}>
                            <Pagination count={paginate.totalPages} page={paginate.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                        </Stack>
                      }
                  </TableContainer>
                }
              </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default BillingMainAdmin
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from '../../style';
import axiosInstance from '../../../../../environment/axiosInstance'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function BuyPhoneNumber(props:any) {
  const classes = useStyles()
  const history = useHistory()
  const [shouldopen, setShouldopen] = React.useState<any>(true);

  const [data, setData] = React.useState<any>({
    "phoneNumber" : ""
  });
   

  const onClickSendData = () => {
    const sendDataa = {
      phoneNumber: data.number
    }
    axiosInstance.post('/admin/phone-numbers/purchase-number',sendDataa).then((res:any)=> {
      if(res.data){
       history.push('/phonenumbers/purchaseNumber')
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000
        })
      }
    })
  }

  React.useEffect(() => {
    setData({
      number: props.match.params.number,
      state: props.match.params.state,
      rateCenter: props.match.params.rateCenter,
      tier: props.match.params.tier,
      vendorId: props.match.params.vendorId,
      vendorName: props.match.params.vendorName,
    });
  }, [])

  const onChangeEvents = () => {
    setShouldopen(!shouldopen);
  };

  return (
    <div>
      <div>
      <Accordion
        className={classes.accordion}
        expanded={shouldopen}
        onChange={onChangeEvents}
        style={{ marginTop: 25 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.availableNumbers}>
            Buy Numbers
          </Typography>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <Typography className={classes.heading1}>
                Are you sure you want to purchase this number?
              </Typography>
            </Grid>
            <Grid item md={12}>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.yesButton}
                  onClick={onClickSendData}
                  //disabled={props.order_number_status === status.IN_PROGRESS}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.noButton}
                  onClick={()=> history.push('/phonenumbers/purchaseNumber')}
                >
                  No
                </Button>
              </div>
            </Grid>
            {/* <Grid item md={2}>
            </Grid>
            <Grid item md={8} >
            </Grid>
            <Grid item md={2}>
            </Grid> */}

            {/* <Grid container spacing={6}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>Id</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>{data.id}</Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid> */}

            <Grid container spacing={6} style={{ marginTop: -12 }}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>Phone Number</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>
                      {data.number}
                      </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: -12 }}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>State</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>
                      {data.state}
                      </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: -12 }}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>Rate Center</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>
                      {data.rateCenter}
                      </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: -12 }}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>Tier</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>
                      {data.tier}
                      </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: -12 }}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>Vendor Id</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>
                      {data.vendorId}
                      </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid>

            <Grid container spacing={6} style={{ marginTop: -12 }}>
              <Grid item md={2}>
              </Grid>
              <Grid item md={8} >
                <Card variant="outlined" style={{ height: 40 }}>
                  <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: 6 }}>
                    <Grid item md={3}>Vendor Name</Grid>
                    <Grid item md={2}>:</Grid>
                    <Grid item md={2}>
                      {data.vendorName}
                      </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionDetails>
          <Grid container spacing={2}>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
    </div>
  )
}

export default BuyPhoneNumber
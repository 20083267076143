import { Button, Card, CardContent, Divider, Accordion, AccordionSummary, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import duration from '../../assets/icons/duration.png';
import { MdPhoneForwarded } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SensorsIcon from '@mui/icons-material/Sensors';
import React from 'react';
import { useStyles } from './styles';
import ReactSelect from 'react-select';
import ListOfCalls from '../History/ListOfCalls/ListOfCalls'
import Reports from "./Reports/Reports"
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import HistoryServices from "./HistoryServices/Services"

function History(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [dropdownvalue, setValue] = React.useState<any>(1);
    const [getTodayData, setgetTodayData] = React.useState<any>({});
    const expandOpen = () => {
        setOpen(!open);
    };
    const HistoryDropdown = [
        { value: 1, label: "Today " },
        { value: 2, label: "Yesterday" },
        { value: 3, label: "Last 7 days" },
        { value: 4, label: "Last 30 Days" },
    ]

    const handleChange = (e: any) => {
        console.log('e', e);

        localStorage.setItem('dbTodayStats', JSON.stringify(e.value))
        setValue(e.value)
        getHistoryyy(e.value)
    }

    const getHistoryyy = (data: any) => {
        setLoading(true)
        HistoryServices.getTodayStats(data).then((res: any) => {
            if (res.success === true) {
                setgetTodayData(res?.data?.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        const todaysStats: any = localStorage.getItem('dbTodayStats');
        const ts: any = JSON.parse(todaysStats)
        if (ts) {
            getHistoryyy(ts)
            setValue(ts);
        }
        if (!ts) {
            getHistoryyy(dropdownvalue)
        }
    }, [])

    // React.useEffect(() => {
    //     if (localStorage.getItem('dbTodayStats')) {
    //         const getValues1: any = localStorage.getItem('dbTodayStats');
    //         const getValue: any = JSON.parse(getValues1);
    //         setValue(getValue);
    //     }
    //     getHistoryyy(dropdownvalue.value)
    // }, [])

    return (
        <>
            <div id='todaysstats'>
                <Card className={classes.card}>
                    <Accordion
                        expanded={open}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="#2645ad" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container className={classes.grid}>
                                <Grid item xs={4} lg={10} md={8} sm={8} xl={10}>
                                    <Typography className={classes.heading}>Today Stats</Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} md={4} sm={4} xl={2}>
                                    <ReactSelect
                                        options={HistoryDropdown}
                                        onChange={handleChange}
                                        value={HistoryDropdown.filter((opt: any) => opt.value == dropdownvalue)}
                                        placeholder="Select key"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        {loading ?
                            <Box className={classes.ldrWidth} sx={{}}>
                                <CircularProgress />
                            </Box> :
                            <>
                                <CardContent className={classes.tableSpace}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} sm={4} xs={12} className={classes.grid}>
                                            <Card className={classes.paper}>
                                                <Typography align={'center'} className={classes.inboundCalls}>Total Inbound Calls</Typography>
                                                <div className={classes.buttondisplaytype}>
                                                    <IconButton className={classes.iconButton}>
                                                        <PhoneCallbackIcon className={classes.phoneCall} />
                                                    </IconButton>
                                                    <Typography className={classes.number}>{getTodayData?.totalInboundCalls}</Typography>
                                                </div>
                                                <Typography className={classes.content}>Today</Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} className={classes.grid}>
                                            <Card className={classes.paper}>
                                                <Typography align={'center'} className={classes.inboundCalls}>Total Missed Calls</Typography>
                                                <div className={classes.buttondisplaytype}>
                                                    <IconButton className={classes.iconButton}>
                                                        <PhoneMissedIcon className={classes.phoneCall} />
                                                    </IconButton>
                                                    <Typography className={classes.number}>{getTodayData?.totalMissedCalls}</Typography>
                                                </div>
                                                <Typography className={classes.content}>Today</Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} className={classes.grid}>
                                            <Card className={classes.paper}>
                                                <Typography align={'center'} className={classes.inboundCalls}>Total Outbound Calls</Typography>
                                                <div className={classes.buttondisplaytype}>
                                                    <IconButton className={classes.iconButton}>
                                                        <MdPhoneForwarded className={classes.phoneCall} />
                                                    </IconButton>
                                                    <Typography className={classes.number}>{getTodayData?.totalOutboundCalls}</Typography>
                                                </div>
                                                <Typography className={classes.content}>Today</Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} sm={4} xs={12} className={classes.grid}>
                                            <Card className={classes.paper}>
                                                <Typography align={'center'} className={classes.inboundCalls}>Average Call Duration</Typography>
                                                <div className={classes.buttondisplaytype}>
                                                    <IconButton className={classes.iconButton}>
                                                        <img alt="Average Call Duration" src={duration} />
                                                    </IconButton>
                                                    <Typography className={classes.number}>{getTodayData?.avgCallDuration}</Typography>
                                                </div>
                                                <Typography className={classes.content}>Minutes</Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} className={classes.grid}>
                                            <Card className={classes.paper}>
                                                <Typography align={'center'} className={classes.inboundCalls}>Available Agents</Typography>
                                                <div className={classes.buttondisplaytype}>
                                                    <IconButton className={classes.iconButton}>
                                                        <RiTeamFill className={classes.phoneCall} />
                                                    </IconButton>
                                                    <Typography className={classes.number}>{getTodayData?.availableAgents}</Typography>
                                                </div>
                                                <Typography className={classes.content}>Users</Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} className={classes.grid}>
                                            <Card className={classes.paper}>
                                                <Typography align={'center'} className={classes.inboundCalls}>Live Calls</Typography>
                                                <div className={classes.buttondisplaytype}>
                                                    <IconButton className={classes.iconButton}>
                                                        <SensorsIcon className={classes.phoneCall} />
                                                    </IconButton>
                                                    <Typography className={classes.number}>{getTodayData?.liveCalls}</Typography>
                                                </div>
                                                <Typography className={classes.content}>Present</Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>}
                    </Accordion>
                </Card>

                <Reports />

                {/* <ListOfCalls /> */}

            </div>
        </>
    )
}
export default History
import React from 'react'
import ReactDom from 'react-dom'
import { IoMdCloseCircle } from 'react-icons/io'
import './modal.css'

function Modal(props: any) {
    const modalBody: any = (
        <div className="modal_container">
            <div className="modal_header">
                <div className="modal_title">
                    <p>{props?.title}</p>
                </div>
                <div className="modal_close_icon" onClick={props.handlePopupModal}>
                    <IoMdCloseCircle size={20} color="gray" />
                </div>
            </div>
            <hr style={{marginTop:-5}}/>
            <div className="modal_body">
                <div>
                   
                </div>
                {props.children}
            </div>
        </div>
    )
    const domElement: any = document.getElementById('modal_popup_root');

    return ReactDom.createPortal(modalBody, domElement)
}

export default Modal

import React from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
// import MySignal from 'assets/icons/Signal.png';
import MySignal from "../../../../assets/icons/Signal.png";
import MyDepts from "../../../../assets/icons/Dept.png";
import GroupUsers from "../../../../assets/icons/groupUsers.png";
import MyTelephone from "../../../../assets/icons/MyTelephone.png";
import MyRecords from "../../../../assets/icons/Record.png";
import { useStyles } from "./styles";
// import OpenHoursVoicemail from "../OpenHoursVoicemail/OpenHoursVoicemail";
// import OpenHoursRecMessages from "../OpenHoursRecMessages/OpenHoursRecMessages";
// import OpenHoursPhoneNumbers from "../OpenHoursPhoneNumbers/OpenHoursPhoneNumbers";
// import OpenHoursDepartments from "../OpenHoursDepartments/OpenHoursDepartments";
// import OpenHoursTeammembers from "../OpenHoursTeammembers/OpenHoursTeammembers";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { MdPhonelink, MdSettingsPhone } from "react-icons/md";
import { RiUserVoiceFill, RiTeamFill } from "react-icons/ri";
import * as profileActionCreator from "../../../store/Actions/userActionCreator";
import { HiOfficeBuilding } from "react-icons/hi";
import { BiMessageDetail } from "react-icons/bi";
import profileServices from "../ProfileServices/ProfileServices";
import { useSelector, useDispatch } from "react-redux";
import UnansweredVoiceMail from "./UnansweredVoiceMail/UnansweredVoiceMail";
import UnansweredDepartments from "./UnansweredDepartments/UnansweredDepartments";
import UnansweredPhoneNumber from "./UnansweredPhoneNumber/UnansweredPhoneNumber";
import UnansweredRecMessages from "./UnansweredRecMessages/UnansweredRecMessages";
// import UnAnsweredVoicemail from "../UnAnsweredVoicemail/UnAnsweredVoicemail";
// import UnAnsweredDepartments from '../UnAnsweredDepartments/UnansweredDepartments';
// import UnAnsweredTeammembers from "../UnAnsweredTeammembers/UnAnsweredTeammembers";
// import UnAnsweredPhoneNumners from "../UnAnsweredPhoneNumners/UnAnsweredPhoneNumners";
// import UnAnsweredRecMessages from "../UnAnsweredRecMessages/UnAnsweredRecMessages";
const openhoursOptions = [
    { key: 6, value: "voicemail" },
    { key: 3, value: "departments" },
    // { key: 4, value: "teammembers" },
    { key: 5, value: "phonenumbers" },
    { key: 7, value: "recmessages" },
];

function UnansweredCalls() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const profileStoreData = useSelector((state: any) => state.profileData);
    const { profileData, loading } = profileStoreData;
    const [tabname, setTabName] = React.useState("");
    const [unanswer, setUnanswer] = React.useState<any>({})
    const [unansweredData, setUnansweredData] = React.useState<any>({})
    const [open, setOpen] = React.useState(true);
    const [voicePath, setVoicePath] = React.useState('');
    const [voiceFileName, setVoiceFileName] = React.useState('');
    const [deptPath, setDeptPath] = React.useState('');
    const [deptFileName, setDeptFileName] = React.useState('');
    const [forwardPath, setForwardPath] = React.useState('');
    const [forwardFileName, setForwardFileName] = React.useState('');
    const [recPath, setRecPath] = React.useState('')
    const [recFileName, setRecFileName] = React.useState('')
    const [selectedOption, setselectedOption] = React.useState<any>({
        currentSelectedOption: null
    })
    const expandOpen = () => {
        setOpen(!open);
    };



    const handleTabChange = (tabname: any) => {
        const findKeyValue: any = openhoursOptions.find(
            (openOption: any) => openOption.value === tabname
        );
        // if (findKeyValue?.key === 1) {
        //     profileServices.updateUnAnsweredCalls({ callHandlingOption: findKeyValue?.key })
        //         .then((res: any) => {
        //             //send success message
        //         })
        //         .catch((err: any) => {
        //             //send error message
        //         });
        // }

        if (tabname === 'voicemail') {
            var myData = selectedOption
            myData.currentSelectedOption = 6
            setselectedOption(myData)
            profileServices.updateUnAnsweredCalls(selectedOption).then((res: any) => {
                getUnanswereddata()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'departments') {
            var myData = selectedOption
            myData.currentSelectedOption = 3
            setselectedOption(myData)
            profileServices.updateUnAnsweredCalls(selectedOption).then((res: any) => {
                getUnanswereddata()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'phonenumbers') {
            var myData = selectedOption
            myData.currentSelectedOption = 5
            setselectedOption(myData)
            profileServices.updateUnAnsweredCalls(selectedOption).then((res: any) => {
                getUnanswereddata()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'recmessages') {
            var myData = selectedOption
            myData.currentSelectedOption = 7
            setselectedOption(myData)
            profileServices.updateUnAnsweredCalls(selectedOption).then((res: any) => {
                getUnanswereddata()
            }).catch((err: any) => {
                //send error message
            })
        }
        setTabName(tabname);
    };



    React.useEffect(() => {
        if (profileData) {
            setUnanswer(profileData.unAnsweredCalls)
        }
    }, [profileData])

    //unanswered get api
    const getUnanswereddata = () => {
        profileServices.getUnAnsweredCalls().then((res: any) => {
            if (res.success) {
                const data = res.data;
                setUnansweredData(data)
                if (res.data.unAnsweredCalls.currentSelectedOption === 6) {
                    const voicemailData = res.data.unAnsweredCalls.voicemail.voicemailAudio
                    voicemailData.map((item: any) => {
                        if (item.isSelected === true) {
                            setVoicePath(item.path)
                            setVoiceFileName(item.fileName)
                        }
                    })
                    if (voicemailData.length === 0) {
                        setVoicePath('')
                        setVoiceFileName('')
                    }
                }
                if (res.data.unAnsweredCalls.currentSelectedOption === 3) {
                    const deptData = res.data.unAnsweredCalls.userAndDepartments.transferingAudio
                    deptData.map((item: any) => {
                        if (item.isSelected === true) {
                            setDeptPath(item.path)
                            setDeptFileName(item.fileName)
                        }
                        // else if (item.isSelected === false) {
                        //     setDeptPath('')
                        //     setDeptFileName('')
                        // }
                    })
                    if (deptData.length === 0) {
                        setDeptPath('')
                        setDeptFileName('')
                    }

                }
                if (res.data.unAnsweredCalls.currentSelectedOption === 5) {
                    const forwardData = res.data.unAnsweredCalls.forwardNumbers.transferingAudio
                    forwardData.map((item: any) => {
                        if (item.isSelected === true) {
                            setForwardPath(item.path)
                            setForwardFileName(item.fileName)
                        }
                    })
                    if (forwardData.length === 0) {
                        setForwardPath("")
                        setForwardFileName("")
                    }
                }
                if (res.data.unAnsweredCalls.currentSelectedOption === 7) {
                    const recData = res.data.unAnsweredCalls.recording.recordingAudio
                    recData.map((item: any) => {
                        if (item.isSelected === true) {
                            setRecPath(item.path)
                            setRecFileName(item.fileName)
                        }
                        // else if (item.isSelected === false) {
                        //     setRecPath('')
                        //     setRecFileName('')
                        // }
                    })
                    if (recData.length === 0) {
                        setRecPath('')
                        setRecFileName('')
                    }
                }
            } else {

            }
        }).catch((err: any) => {

        })
    }

    // React.useEffect(() => {
    //     if (profileData) {
    //       const closedHours = closeHoursData;
    //       openhoursOptions.forEach((option: any) => {
    //         if (closedHours?.closeHours) {
    //           if (option.key === closedHours.closeHours.currentSelectedOption) {
    //             setTabName(option.value)
    //           }
    //         }
    //       })
    //     }
    //   }, [props.match.params.id, closeHoursData]



    React.useEffect(() => {
        if (unansweredData) {
            const unAnsweredCalls = unansweredData;
            openhoursOptions.forEach((option: any) => {
                if (unAnsweredCalls) {
                    if (option.key === unAnsweredCalls?.unAnsweredCalls?.currentSelectedOption) {
                        setTabName(option.value);
                    }
                }

            });
        }
    }, [profileData, unansweredData]);

    // const defaultSelectedTab = async () => {
    // await profileServices.getUnAnsweredCalls().then((res: any) => {
    //         if (res.success) {
    //             openhoursOptions.forEach((option: any) => {
    //                 if (option.key === res.data?.unAnsweredCalls?.currentSelectedOption) {
    //                     setTabName(option.value)
    //                 }
    //             })

    //         }
    //     })
    // }


    React.useEffect(() => {
        dispatch(profileActionCreator.initUnanswred());
    }, []);

    React.useEffect(() => {
        getUnanswereddata()
        //defaultSelectedTab()
    }, [])
    return (
        <>
            <div className={classes.padding}>
                <Card className={classes.card}>
                    <Accordion expanded={open} onChange={expandOpen}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                Un Answered Calls
                            </Typography>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails className={classes.accordianNoFlex}>
                            <CardContent style={{}}>
                                <div className={classes.gridJustify}>
                                    <Card
                                        className={
                                            tabname === "voicemail"
                                                ? classes.cardWidth
                                                : classes.cardWidth1
                                        }
                                        onClick={() => handleTabChange("voicemail")}
                                    >
                                        <CardContent className={classes.align}>
                                            <RiUserVoiceFill
                                                className={
                                                    tabname === "voicemail"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                } />
                                            <Typography
                                                className={
                                                    tabname === "voicemail"
                                                        ? classes.devicesText
                                                        : classes.devicesText1
                                                }
                                            >
                                                Voice Mail
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card
                                        className={
                                            tabname === "departments"
                                                ? classes.cardWidth
                                                : classes.cardWidth1
                                        }
                                        onClick={() => handleTabChange("departments")}
                                    >
                                        <CardContent className={classes.align}>
                                            <HiOfficeBuilding
                                                className={
                                                    tabname === "departments"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                }
                                            />

                                            {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}
                                            <Typography
                                                className={
                                                    tabname === "departments"
                                                        ? classes.devicesText
                                                        : classes.devicesText1
                                                }
                                            >
                                                User & Dept
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card
                                        className={
                                            tabname === "phonenumbers"
                                                ? classes.cardWidth
                                                : classes.cardWidth1
                                        }
                                        onClick={() => handleTabChange("phonenumbers")}
                                    >
                                        <CardContent className={classes.align}>
                                            <MdSettingsPhone
                                                className={
                                                    tabname === "phonenumbers"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                }
                                            />
                                            {/* <img className={classes.deviceWidth} alt="MyTelephone" src={MyTelephone} /> */}
                                            <Typography
                                                className={
                                                    tabname === "phonenumbers"
                                                        ? classes.devicesText
                                                        : classes.devicesText1
                                                }
                                            >
                                                Forward Numbers
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card
                                        className={
                                            tabname === "recmessages"
                                                ? classes.cardWidth
                                                : classes.cardWidth1
                                        }
                                        onClick={() => handleTabChange("recmessages")}
                                    >
                                        <CardContent className={classes.align}>
                                            <BiMessageDetail
                                                className={
                                                    tabname === "recmessages"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                }
                                            />

                                            {/* <img className={classes.deviceWidth} alt="MyRecords" src={MyRecords} /> */}
                                            <Typography
                                                className={
                                                    tabname === "recmessages"
                                                        ? classes.devicesText
                                                        : classes.devicesText1
                                                }
                                            >
                                                Recorded Messages
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CardContent>
                            {tabname === "voicemail" && (
                                <>
                                    <UnansweredVoiceMail
                                        getUnanswereddata={getUnanswereddata}
                                        unansweredData={unansweredData}
                                        voicePath={voicePath}
                                        voiceFileName={voiceFileName}

                                    />
                                    {/* <UnAnsweredVoicemail
                                        callHandlingOption={2}
                                        compType={'voicemails'}
                                        data={unanswer}
                                        unanswered={unansweredData}
                                        getUnanswereddata={getUnanswereddata}
                                        getURL={''} 
                                    /> */}
                                </>
                            )}
                            {tabname === "departments" && (
                                <>
                                    <UnansweredDepartments
                                        getUnanswereddata={getUnanswereddata}
                                        unansweredData={unansweredData}
                                        deptPath={deptPath}
                                        deptFileName={deptFileName}
                                    />
                                    {/* <UnAnsweredDepartments callHandlingOption={3}
                                        data={unanswer}
                                        unanswered={unansweredData}
                                        getUnanswereddata={getUnanswereddata} 
                                        compType={'departments'} 
                                        patchURL={''} 
                                        getURL={''} 
                                    /> */}

                                </>
                            )}
                            {tabname === "teammembers" && (
                                <>
                                    {/* <UnAnsweredTeammembers 
                                        callHandlingOption={4}
                                        data={unanswer}
                                        unanswered={unansweredData}
                                        getUnanswereddata={getUnanswereddata} 
                                        compType={'teammembers'} patchURL={''} 
                                        getURL={''} 
                                    /> */}
                                </>
                            )}
                            {tabname === "phonenumbers" && (
                                <>
                                    <UnansweredPhoneNumber
                                        getUnanswereddata={getUnanswereddata}
                                        unansweredData={unansweredData}
                                        forwardPath={forwardPath}
                                        forwardFileName={forwardFileName}
                                    />
                                    {/* <UnAnsweredPhoneNumners 
                                        callHandlingOption={5}
                                        data={unanswer}
                                        unanswered={unansweredData}
                                        getUnanswereddata={getUnanswereddata} 
                                        compType={'phonenumbers'} patchURL={''} 
                                        getURL={''} 
                                    /> */}
                                </>
                            )}
                            {tabname === "recmessages" && (
                                <>
                                    <UnansweredRecMessages
                                        getUnanswereddata={getUnanswereddata}
                                        unansweredData={unansweredData}
                                        recPath={recPath}
                                        recFileName={recFileName}
                                    />
                                    {/* <UnAnsweredRecMessages callHandlingOption={6}
                                        data={unanswer}
                                        unanswered={unansweredData}
                                        getUnanswereddata={getUnanswereddata} 
                                        compType={'recmessages'} 
                                        patchURL={''} 
                                        getURL={''} 
                                    /> */}
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </div>
        </>
    )
}

export default UnansweredCalls
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead } from '@material-ui/core'
import axios from 'axios';
import React from 'react'
import { useStyles } from '../styles'
import axiosInstance from '../../../../environment/axiosInstance'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import AddEfoneNumber from './AddNumber/AddEfoneNumber';

function ManageNumbers(props: any) {
    const classes = useStyles()
    const [openModalAdduser, setopenModalAdduser] = React.useState(false);
    const [manageNumbers, setManageNumbers] = React.useState([])

    const handlePopupModalAddNumber = () => {
        setopenModalAdduser(!openModalAdduser)
      }
    const fetchMangeNumbers = () => {
        axiosInstance.get(`/deskphone/roomphone/numbers?roomPhoneId=${props.editId}`).then((res: any) => {
            // console.log('resii',res)
            if (res.status === 200) {

            }
        })
    }

    React.useEffect(() => {
        fetchMangeNumbers()
    }, [])

    return (
        <>
            {
                openModalAdduser && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAddNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalAddNumber} >
                            <AddEfoneNumber 
                                //fetchNumberLists={fetchNumberLists}
                                editId={props.editId}
                                handlePopupModal={handlePopupModalAddNumber}
                                handlePopupModalEditdeskphone={props.handlePopupModalEditdeskphone}
                                />
                               
                            {/* <AddEfoneNumber
                                fetchNumberLists={fetchNumberLists}
                                handlePopupModal={handlePopupModalAddNumber}
                            /> */}
                        </Modal>
                    </div>
                )
            }

            <div style={{ padding: 10 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                        </TableHead>
                        <TableBody>
                            <TableCell className={classes.tableBdy}>(289)401-2889</TableCell>
                            <TableCell className={classes.tableBdy}>Primary Number</TableCell>
                            <TableCell className={classes.tableBdy1}>Options</TableCell>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider />
                <Button onClick={handlePopupModalAddNumber} variant='outlined' size='small' className={classes.addNumber}>Add Number</Button>
            </div>
        </>
    )
}

export default ManageNumbers
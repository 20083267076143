import React from 'react';
import {
  Card,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  CircularProgress,
  Box,
  FormControl,
  TextareaAutosize,
  InputLabel,
  Select,
  Grid,
  Container,
  TextField
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axiosInstance from '../../../environment/axiosInstance'
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal'
import { useStyles } from './style';
import * as popUpActionCreator from "../../../store/Actions/globalpopupAlert";
import { connect } from "react-redux";
import './styles.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function EmailNotifications(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = React.useState<any>("");
  const [emailError, setEmailError] = React.useState<any>("");
  const [emailNotifications, setEmailNotifications] = React.useState<any>([]);
  const [timezoneName, setTimezone] = React.useState<any>([]);
  const [departmentNames, setDepartmentNames] = React.useState<any>([]);
  const [Usersrp, setUsersrp] = React.useState<any>([]);
  const [duration, setDuration] = React.useState<any>([]);
  const [hourType, setHourType] = React.useState<any>([]);
  const [sheetType, setSheetType] = React.useState<any>([]);
  const [timeSentAt, setTimeSentAt] = React.useState<any>([]);
  const [type, setType] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(false);

  const [testModalIsOpen, setTestModalIsOpen] = React.useState<any>(false);
  const [index, setIndex] = React.useState<any>(null);


  const getEmailNotifications = () => {
    setLoading(true)
    axiosInstance.get('dashboard/email-notifications').then((res: any) => {
      if (res.status === 200) {
        setEmailNotifications(res.data.data?.map((notification: any) => {
          return { ...notification, isAllDepartmentsSelected: false }
        }));
        setLoading(false)
      }
    }).catch((err: any) => {

    })
  }

  const getData = () => {
    setLoading(true)
    axiosInstance.get('dashboard/email-notifications/fetchStaticData').then((res: any) => {
      if (res.status === 200) {
        setDepartmentNames(res.data.data.departmentNames);
        setDuration(res.data.data.duration);
        setHourType(res.data.data.hourType);
        setSheetType(res.data.data.sheetType);
        setTimeSentAt(res.data.data.timeSentAt);
        setType(res.data.data.type);
        setUsersrp(res.data.data.usersRP)
        setTimezone(res.data.data.timezones)
        getEmailNotifications();
      }
    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    getData();
  }, []);

  const onAddClicked = () => {
    var temp_notifications = Object.assign([], emailNotifications);
    temp_notifications.push({
      _id: null,
      deptIds: [],
      type: "departments",
      duration: duration[0]?.key,
      hourType: "open",
      runAtTime: "00",
      sheetType: "pdf",
      timezoneId: timezoneName[0]?._id,
      emails: ""
    });
    setEmailNotifications(temp_notifications);
  }

  const onDropdownChanged = (e: any, index: any) => {
    var temp_notifications: any = Object.assign([], emailNotifications);

    if (e.target.name == "type") {
      temp_notifications[index].type = e.target.value;
      temp_notifications[index].deptIds = [];
    }
    else if (e.target.name == "duration") {
      temp_notifications[index].duration = e.target.value;
    }
    else if (e.target.name == "hourType") {
      temp_notifications[index].hourType = e.target.value;
    }
    else if (e.target.name == "runAtTime") {
      temp_notifications[index].runAtTime = e.target.value;
    }
    else if (e.target.name == "sheetType") {
      temp_notifications[index].sheetType = e.target.value;
    }

    else if (e.target.name == "timezoneId") {
      temp_notifications[index].timezoneId = e.target.value;
    }

    setEmailNotifications(temp_notifications);
  }




  const departmentNamesChanged = (value: any, index: any) => {
    const isAllSelected = value?.filter((val: any) => val.value == "ALL");

    let temp_notifications = [...emailNotifications];

    if (isAllSelected?.length > 0) {
      var removedOptions = temp_notifications[index].deptIds.filter((option: any) =>
        !value.some((newOption: any) => newOption.value === option)
      );
      if (removedOptions?.length > 0) {
        temp_notifications[index].deptIds = value?.map((val: any) => val.value);
      }
      else {
        temp_notifications[index].deptIds = departmentNames?.map((department: any) => department._id);
      }
    }
    else {
      var removedOptions = temp_notifications[index].deptIds.filter((option: any) =>
        !value.some((newOption: any) => newOption.value === option)
      );
      if (removedOptions?.includes("ALL")) {
        temp_notifications[index].deptIds = [];
      }
      else {
        temp_notifications[index].deptIds = value?.map((val: any) => val.value);
      }
    }

    setEmailNotifications(temp_notifications);
  };

  const usersNamesChanged = (value: any, index: any) => {
    const isAllSelected = value?.filter((val: any) => val.value == "ALL");

    let temp_notifications = [...emailNotifications];

    if (isAllSelected?.length > 0) {
      var removedOptions = temp_notifications[index].deptIds.filter((option: any) =>
        !value.some((newOption: any) => newOption.value === option)
      );
      if (removedOptions?.length > 0) {
        temp_notifications[index].deptIds = value?.map((val: any) => val.value);
      }
      else {
        temp_notifications[index].deptIds = Usersrp?.map((department: any) => department._id);
      }
    } else {
      var removedOptions = temp_notifications[index].deptIds.filter((option: any) =>
        !value.some((newOption: any) => newOption.value === option)
      );
      if (removedOptions?.includes("ALL")) {
        temp_notifications[index].deptIds = [];
      }
      else {
        temp_notifications[index].deptIds = value?.map((val: any) => val.value);
      }
    }

    setEmailNotifications(temp_notifications);
  };

  // const usersNamesChanged = (event: any, index: any) => {
  //   const { target: { value } } = event;
  //   let selectedValues = typeof value === 'string' ? value.split(',') : value;

  //   // Check if "ALL" is selected
  //   const isAllSelected = selectedValues.includes("ALL");
  //   console.log("isAllSelected", isAllSelected)
  //   // If "ALL" is selected, replace selected values with all department IDs
  //   if (isAllSelected) {
  //     selectedValues = Usersrp.map((dept: any) => dept._id);
  //   } else {
  //     // Remove "ALL" from selected values if present
  //     selectedValues = selectedValues.filter((val: any) => val !== "ALL");
  //   }

  //   console.log("selectedValues", selectedValues)

  //   // If no options selected (except "ALL"), clear all selections
  //   const temp_notifications = [...emailNotifications];
  //   temp_notifications[index].deptIds = isAllSelected ? selectedValues : selectedValues.length > 0 ? selectedValues : [];
  //   console.log("temp_notifications", temp_notifications)
  //   setEmailNotifications(temp_notifications);
  // };

  // const departmentNamesChanged = (event:any, index:any) => {
  //   const { target: { value } } = event;
  //   let selectedValues = typeof value === 'string' ? value.split(',') : value;

  //   // Check if "ALL" is selected
  //   const isAllSelected = selectedValues.includes("ALL");
  //   console.log("isAllSelected",isAllSelected)
  //   // If "ALL" is selected, replace selected values with all department IDs
  //   if (isAllSelected) {

  //   } else {
  //     // Remove "ALL" from selected values if present
  //     selectedValues = selectedValues.filter((val:any) => val !== "ALL");
  //   }

  //   console.log("selectedValues",selectedValues)

  //   // If no options selected (except "ALL"), clear all selections
  //   const temp_notifications = [...emailNotifications];
  //   temp_notifications[index].deptIds = isAllSelected ? selectedValues : selectedValues.length > 0 ? selectedValues : [];
  //   console.log("temp_notifications",temp_notifications)
  //   setEmailNotifications(temp_notifications);
  // };

  // Utility function to map selected IDs to names
  const getUserNames = (ids: any) => {
    return ids.map((id: any) => {
      const user = Usersrp.find((user: any) => user._id === id);
      return user ? user.name : id;
    });
  };

  const getDeptNames = (ids: any) => {
    return ids.map((id: any) => {
      const dept = departmentNames.find((dept: any) => dept._id === id);
      return dept ? dept.deptName : id;
    });
  };


  const setEmails = (e: any, index: any) => {
    var temp_notifications: any = Object.assign([], emailNotifications);
    temp_notifications[index].emails = e.target.value;
    setEmailNotifications(temp_notifications);
  }

  const onSaveBtnClicked = (e: any, index: any) => {
    axiosInstance.post('dashboard/email-notifications', {
      type: emailNotifications[index].type,
      deptIds: emailNotifications[index].deptIds,
      duration: emailNotifications[index].duration,
      hourType: emailNotifications[index].hourType,
      runAtTime: emailNotifications[index].runAtTime,
      sheetType: emailNotifications[index].sheetType,
      emails: emailNotifications[index].emails,
      timezoneId: emailNotifications[index].timezoneId
    })
      .then(response => {
        if (response.data) {
          props.popUpAlert(response.data.message, "UPDATE_AGENT_SUCCESS", "success");
          getData();
        } else {
          props.popUpAlert(response.data.message, "UPDATE_AGENT_FAILURE", "error");
        }
      })
      .catch(error => {
        props.popUpAlert(`Update failed: ${error.message}`, "UPDATE_AGENT_FAILURE", "error");
      });
  }



  const onUpdateBtnClicked = (e: any, index: any) => {
    axiosInstance.patch('dashboard/email-notifications?id=' + emailNotifications[index]._id, {
      type: emailNotifications[index].type,
      deptIds: emailNotifications[index].deptIds,
      duration: emailNotifications[index].duration,
      hourType: emailNotifications[index].hourType,
      runAtTime: emailNotifications[index].runAtTime,
      sheetType: emailNotifications[index].sheetType,
      emails: emailNotifications[index].emails,
      timezoneId: emailNotifications[index].timezoneId
    })
      .then(response => {
        if (response.data) {
          props.popUpAlert(response.data.message, "UPDATE_AGENT_SUCCESS", "success");
          getData();
        } else {
          props.popUpAlert(response.data.message, "UPDATE_AGENT_FAILURE", "error");
        }
      })
      .catch(error => {
        props.popUpAlert(`Update failed: ${error.message}`, "UPDATE_AGENT_FAILURE", "error");
      });
  }

  const onDeleteBtnClicked = (e: any, index: any) => {
    axiosInstance.delete('dashboard/email-notifications?id=' + emailNotifications[index]._id)
      .then(response => {
        if (response.data) {
          props.popUpAlert(response.data.message, "UPDATE_AGENT_SUCCESS", "success");
          getData();
        }
      });
  }

  const onTestBtnClicked = (e: any, index: any) => {
    setTestModalIsOpen(true);
    setIndex(index);
  }

  const sendBtn = () => {
    if (email) {
      setEmailError("");

      axiosInstance
        .get("/dashboard/email-notifications/testEmailNotification?id=" + emailNotifications[index]?._id + "&emails=" + email)
        .then((response: any) => {
          if (response.data) {
            props.popUpAlert(response.data.message, "UPDATE_AGENT_SUCCESS", "success");
            setEmail("");
            setIndex(null);
            setTestModalIsOpen(false);
          }
        });
    }
    else {
      setEmailError("*Email is required.");
    }
  }

  const handleTestModalClosed = () => {
    setTestModalIsOpen(false);
  }

  return (
    <div>
      {
        testModalIsOpen && (
          <div>
            <Backdrop handlePopupModal={handleTestModalClosed} />
            <Modal title={""} handlePopupModal={handleTestModalClosed}>
              <Grid container spacing={2} style={{ margin: 15 }}>
                <div className="add_user_popup" style={{ width: "400px" }}>
                  <label>Email:</label>
                  <br />
                  <TextField
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  <p className={classes.error}>{emailError}</p>
                  <br /><br />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant='contained'
                      className={classes.sendBtn}
                      onClick={sendBtn}
                    >Send</Button>
                  </div>
                </div>
              </Grid>
            </Modal>
          </div>
        )
      }
      <Card style={{ marginTop: 10, padding: 20 }} className={classes.card}>
        <Container className={classes.topContainer}>
          <Button
            variant='contained'
            onClick={onAddClicked}
            className={classes.addBtn}
          >
            Add
          </Button>
        </Container>
        {loading ?
          <Box>
            <div style={{ width: "100%", textAlign: "center" }}>
              <CircularProgress />
            </div>
          </Box>
          :
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Department Names</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Hour Type</TableCell>
                <TableCell>Timezone</TableCell>
                <TableCell>Time Sent At</TableCell>
                <TableCell>Sheet Type</TableCell>
                <TableCell>Email Addresses</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                emailNotifications?.map((notification: any, i: any) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <FormControl fullWidth size={'small'} variant="outlined">
                          <InputLabel>Type</InputLabel>
                          <Select
                            name="type"
                            native
                            value={notification.type}
                            label={"Type"}
                            onChange={(e) => onDropdownChanged(e, i)}
                          >
                            {type?.map((option: any, j: any) => (
                              <option key={j} value={option.key}>
                                {option.value}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {notification.type === "usersRP" ?
                          // <FormControl fullWidth size="small"
                          // style={{ width: '100%', maxWidth: '130px' }} // Set a maxWidth to ensure it doesn't grow too large
                          //  variant="outlined"
                          // >
                          // <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
                          // <Select
                          //   labelId="demo-multiple-checkbox-label"
                          //   id="demo-multiple-checkbox"
                          //   multiple
                          //   value={notification?.deptIds || []}
                          //   onChange={(e) => usersNamesChanged(e, i)}
                          //   input={<OutlinedInput label="Select" />}
                          //   renderValue={(selected) => getUserNames(selected).join(', ')} // Map IDs to names for display
                          // >
                          //   {Usersrp.map((user:any) => (
                          //     <MenuItem key={user._id} value={user._id}>
                          //       <Checkbox checked={notification?.deptIds?.includes(user._id)} />
                          //       <ListItemText primary={user.name} />
                          //     </MenuItem>
                          //   ))}
                          // </Select>
                          // </FormControl>                 
                          <ReactSelect
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={Usersrp?.map((users: any, i: any) => {
                              return {
                                value: users._id,
                                label: users.name
                              }
                            })}
                            value={notification?.deptIds?.map((users: any, i: any) => {
                              return {
                                value: users,
                                label: Usersrp?.find((department: any) => department._id == users)?.name
                              }
                            })}
                            onChange={(e) => usersNamesChanged(e, i)}
                            isMulti={true}
                          />
                          :
                          //  <FormControl fullWidth size="small"
                          //  style={{ width: '100%', maxWidth: '130px' }} // Set a maxWidth to ensure it doesn't grow too large
                          //   variant="outlined"
                          //  >
                          //  <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
                          //  <Select
                          //    labelId="demo-multiple-checkbox-label"
                          //    id="demo-multiple-checkbox"
                          //    multiple
                          //    value={notification?.deptIds || []}
                          //    onChange={(e) => departmentNamesChanged(e, i)}
                          //    input={<OutlinedInput label="Select" />}
                          //    renderValue={(selected) => getDeptNames(selected).join(', ')} // Map IDs to names for display
                          //  >
                          //    {departmentNames.map((dept:any) => (
                          //      <MenuItem key={dept._id} value={dept._id}>
                          //        <Checkbox checked={notification?.deptIds?.includes(dept._id)} />
                          //        <ListItemText primary={dept.deptName} />
                          //      </MenuItem>
                          //    ))}
                          //  </Select>
                          //  </FormControl> 
                          <ReactSelect
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            options={departmentNames?.map((dept: any, i: any) => {
                              return {
                                value: dept._id,
                                label: dept.deptName
                              }
                            })}
                            value={notification?.deptIds?.map((dept: any, i: any) => {
                              return {
                                value: dept,
                                label: departmentNames?.find((department: any) => department._id == dept)?.deptName
                              }
                            })}
                            onChange={(e) => departmentNamesChanged(e, i)}
                            isMulti={true}
                          />
                        }

                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size={'small'} variant="outlined">
                          <InputLabel>Duration</InputLabel>
                          <Select
                            name="duration"
                            native
                            value={notification?.duration}
                            label={"Duration"}
                            onChange={(e) => onDropdownChanged(e, i)}
                          >
                            {duration?.map((option: any, j: any) => (
                              <option key={j} value={option.key}>
                                {option.value}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size={'small'} variant="outlined">
                          <InputLabel>Hour Type</InputLabel>
                          <Select
                            name="hourType"
                            native
                            value={notification?.hourType}
                            label={"Hour Type"}
                            onChange={(e) => onDropdownChanged(e, i)}
                          >
                            {hourType?.map((option: any, j: any) => (
                              <option key={j} value={option.key}>
                                {option.value}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size={'small'} variant="outlined">
                          <InputLabel>Timezone</InputLabel>
                          <Select
                            name="timezoneId"
                            native
                            value={notification?.timezoneId}
                            label={"Timezone"}
                            onChange={(e) => onDropdownChanged(e, i)}
                          >
                            {timezoneName?.map((option: any, j: any) => (
                              <option key={option._id} value={option._id}>
                                {option.timezone}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size={'small'} variant="outlined">
                          <InputLabel>Sent At</InputLabel>
                          <Select
                            name='runAtTime'
                            native
                            value={notification?.runAtTime}
                            label={"Sent At"}
                            onChange={(e) => onDropdownChanged(e, i)}
                          >
                            {timeSentAt?.map((option: any, j: any) => (
                              <option key={j} value={option}>
                                {option}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size={'small'} variant="outlined">
                          <InputLabel>Sheet Type</InputLabel>
                          <Select
                            name="sheetType"
                            native
                            value={notification?.sheetType}
                            label={"Sheet Type"}
                            onChange={(e) => onDropdownChanged(e, i)}
                          >
                            {sheetType?.map((option: any, j: any) => (
                              <option key={j} value={option.key}>
                                {option.value}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextareaAutosize style={{ width: '100%', border: '1px solid block' }}
                          placeholder={"Emails with comma separated"}
                          name="loosingCarrierComments"
                          minRows={2}
                          value={notification?.emails}
                          maxRows={2}
                          onChange={(e) => setEmails(e, i)}
                        />
                        {/* <TextField
                        fullWidth
                        size="small"
                        placeholder={"Emails with comma separated"}
                        value={notification?.emails}
                        variant="outlined"
                        onChange={(e) => setEmails(e, i)}
                      /> */}
                      </TableCell>
                      <TableCell>
                        {
                          notification?._id ?
                            <div style={{ display: "flex" }}>
                              <Button
                                variant='outlined'
                                style={{ textTransform: "capitalize" }}
                                onClick={(e) => onUpdateBtnClicked(e, i)}
                                className={classes.editBtn}
                              >
                                Update
                                {/* <ModeEditIcon className={classes.editIcon} /> */}
                              </Button>
                              <Button
                                variant='outlined'
                                style={{ textTransform: "capitalize" }}
                                onClick={(e) => onTestBtnClicked(e, i)}
                                className={classes.testBtn}
                              >
                                Test
                                {/* <ModeEditIcon className={classes.editIcon} /> */}
                              </Button>
                              <Button
                                variant='outlined'
                                onClick={(e) => onDeleteBtnClicked(e, i)}
                                className={classes.deleteBtn}
                              >
                                <DeleteForeverIcon className={classes.trashIcon} />
                              </Button>
                            </div>
                            :
                            <Button
                              variant='outlined'
                              onClick={(e) => onSaveBtnClicked(e, i)}
                            >Save</Button>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        }
      </Card>
    </div>
  )
}

const mapDispatchToProps = (dipatches: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dipatches(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(EmailNotifications)
import { makeStyles, withStyles, Slider } from '@material-ui/core';
import { Margin } from '@mui/icons-material';

export const useStyles = makeStyles((theme) => (
    {
        divSpacing:{
            // marginBottom: 17, 
            height: 500, 
            // overflowY: 'scroll' 
        },
        subheading: {
            fontFamily: 'Calibri',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "24px",
            color: '#A0A0A0',
        },
        heading: {
            fontFamily: 'Calibri ! important',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: "24px",
            // lineHeight: "29px",  
            color: '#646464',
        },
        greetingCard: {
            padding: 25, marginTop: 20,
            margin: '30px 60px 10px 16px',
            boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
            borderRadius: '10px',
            [theme.breakpoints.down('xs')]:{
                margin:0
            }
        },
        actionline: {
            fontFamily: 'Arial ! important',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '19px',
            // lineHeight: '22px',
            color: "rgba(107, 107, 107, 0.86)",
            marginTop: '5px',
            marginBottom: '20px'
        },
        audioContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        audioPlayers: {
            // width: '597px ! important',
            width: '100%',
            height: '44px',
            // backgroundColor: "#E9ECED ! important",
            border: '1px solid #F0F3F5',
            borderRadius: '5px',
            background: '#F0F3F5'
        },
        editbtn: {
            fontFamily: 'Calibri ! important',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '15px',
            color: '#FFFFFF',
            marginRight: '30%',
            background: "#4D72F8",
            padding: '7px 25px',
            borderRadius: "5px",
            textTransform: 'initial',
            "&:hover": {
                backgroundColor: '#4D72F8'
            }
        },
        filename: {
            fontFamily: 'Calibri !important',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: "12px",
            color: '#848282',
            marginTop: '15px'

        },
        saveBtn: {
            background: "#4D72F8",
            borderRadius: "3px",
            color: 'white',
            textTransform: 'capitalize',
            marginLeft: 10
        },
        saveBtnText: {
            fontFamily: 'Calibri',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '15px',
            color: '#4D72F8',
            letterSpacing: "0em"

        },
        greetMainSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        greetTextNew: {
            fontFamily: 'Calibri',
            fontSize: 20,
            fontweight: 700,
            // lineHeight: 24,
            letterspacing: "0em",
            textAlign: "left",
            marginTop: 34,
            marginLeft: 32
        },
        card: {
            borderRadius: 10,
            background: '#FFFFFF',
            boxShadow: '0 0 0.5em #E2E2E2',
            overflow: 'unset'
        },
        switchicon: {
            marginTop: 8,
            marginLeft: 12

        },
        audioPlayer: {
            display: "block",
            width: "100%",
            height: 48,
            '&.selected': {
                color: '#0921a9'
            },
            '&:hover': {
                color: '#4D72F8'
            },
        },
        greetText: {
            fontSize: 16,
            marginTop: -12,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#8392AB',
        },
        grid: {
            height: 150,
        },
        gridContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        iconContainer: {
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        button: {
            display: 'block',
            padding: '0px 10px',
            color: '#787878',
            background: '#F4F4F4',
            border: '1px solid #F4F4F4',
            boxSizing: 'border-box',
            borderRadius: 3,
            marginLeft: 0,
            height: 10
        },
        radioBtnsContainer: {
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        radioBtnMain: {
            display: 'flex',
            alignItems: 'center',
            background: 'grey',
            padding: '5px 10px'
        },
        radioText: {
            fontSize: '0.8rem',
            margin: 0
        },
        uploadButton: {
            color: '#787878',
            background: '#F4F4F4',
            marginTop: -8,
            borderRadius: 3,
            width: '100px',
            height: '35px',
            border: '1px solid #ccc',
            padding: '4px 10px',
        },
        borderColorIcon: {
            fontSize: 10,
            margin: 3,
            color: '#696969',
        },
        borderColorIconText: {
            margin: 3,
            fontSize: 10,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            color: '#696969',
            letterSpacing: 0.217535,
        },
        deleteButton: {
            position: 'absolute',
            padding: 20,
            right: 0,
            borderRadius: 1,
            color: '#787878',
            background: '#F4F4F4',
            borderColor: '#F4F4F4',
        },
        selected: {
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            background: 'rgba(118, 144, 238, 0.5)',
            border: '2px solid #4D72F8',
            // borderRadius: '4px'
            borderTopLeftRadius:'4px',
            borderTopRightRadius:'none',
            borderBottomLeftRadius:4,
            // borderBottomRightRadius:4
        },
        unSelected: {
            display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
            border: '1px solid #F0F3F5'
        },
        selectedd: {
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            background: 'rgba(118, 144, 238, 0.5)',
            border: '2px solid #4D72F8',
            borderTopLeftRadius:'none',
            borderTopRightRadius:4,
            borderBottomRightRadius:4,
            width:'40px',height:'58px'
            //borderRadius: '4px'
        },
        unSelectedd: {
            display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
            border: '1px solid #F0F3F5',
            width:'40px',height:'56px'
        },
        audioTrack: {
            width: '500px', background: '#E9ECED'
        },
        audioTrackActive: {
            width: '500px', 
            //background: 'rgba(118, 144, 238, 0.5)',
        },
        deleteIconColor: {
            fontSize: 12,
            margin: 3,
            color: '#787878',
            borderColor: '#F4F4F4',
            '&:hover': {
                color: '#787878',
                borderColor: '#F4F4F4',
            },
            '&:active': {
                color: '#787878',
                borderColor: '#F4F4F4',
            },
        },
        deleteIconText: {
            fontSize: 12,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: 0.217535,
            margin: 3,
            color: '#787878',
        },
        gridItemCard: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        imgsize: {
            fontSize: '4px',
            height: '13.41px',
            width: '12px',
            marginTop: '21px'
        },
        iconButton: {
            width: '100px',
            height: '35px',
            border: '1px solid #ccc',
            padding: '4px 8px',
            borderRadius: 5,
            color: '#FFFFFF',
            background: '#F4F4F4',
            '&:hover': {
                background: '#DDDDDD',
            },
            '&:active': {
                background: '#DDDDDD',
            },
            "@media (max-width: 768px)": {
                // marginTop: '-22px',
                // marginLeft: '63%'
            }
        },
        wdth: {
            width: "600px",
            "@media (max-width: 768px)": {
                width: "500px",
                height: '500px'
            }
        },
        playbutton: {
            position: 'relative',
            top: 3,
            width: 35,
            height: 35,
            borderRadius: 50,
            color: 'white',
            padding: '8px',
        },
        videolen: {
            marginLeft: 10,
            marginTop: 5,
            fontSize: '12px',
            alignSelf: 'center',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            color: '#464646',
            letterSpacing: 0.208955,
            textTransform: 'none',
        },
        vollen: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        },
        volcol: {
            color: '#4D72F8',
        },
        vollen1: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: '10px',
        },
        dropdownwidth: {
            width: '440px',
            bottom: '17px',
            left: '20px',
        },
        typographywidth: {
            marginBottom: '27px',
            marginLeft: '20px',
        },
        tabs: {
            background: "#FFFFFF",
            // borderRadius: "7.51286px",
            // boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: '0px'
        },
        tab: {
            textTransform: "capitalize",
            background: '#E4E4E4 ! important',
            color: '#A1A1A1 ! important',
            boxSizing: "border-box",
            fontFamily: 'Arial ! important',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 18,
            width: '315px',
            height: '52px',
            letterSpacing: '0.02em',
            borderRadius: '2px'
        },
        tabActive: {
            background: '#4D72F8 ! important',
            color: '#FFFFFF ! important',
            boxSizing: "border-box",
            fontFamily: 'Arial ! important',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 18,
            textTransform: "capitalize",
            width: '315px',
            height: '52px',
            letterSpacing: '0.02em',
            borderRadius: '2px'
        },
        dFlex: {
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        },
        minutes: {
            fontFamily: 'Work Sans ! important',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            color: '#8F8E8E',
            marginTop: '10px',
            lineHeight: '19px'
        },
        saveAsdefaultBtn: {
            width: '170px',
            height: '42px',
            background: '#4D72F8',
            borderRadius: '3px',
            color: "#FFFFFF",
            textTransform: 'capitalize',
            textAlign:'center',
            marginLeft:'35%'
        },
        uploadContainer: {
            padding: '10px',
            borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
        },
        deltecancelButton: {
            margin: 10,
            padding: '6px 22px',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            color: '#787878',
            fontSize: 14,
          },
          editBtn: {
            background: '#4D72F8',
            borderRadius: 5,
            color: '#fff',
            padding: '6px 25px',
            marginRight:'17%',
            textTransform: 'capitalize',
            '&:hover': {
                color: '#fff',
                background: '#4D72F8',
            }
        },
        hoverBorder:{
            '&:hover': {
                border: '2px solid #4D72F8',
                background:'rgba(118, 144, 238, 0.5)',
                borderRadius:4
              },
        }
    })
    );

export const PrettoSlider = withStyles({
    root: {
        position: 'relative',
        top: 0,
        bottom: 0,
        color: '#4D72F8',
        width: '100%',
        marginLeft: 10,
    },
    thumb: {
        height: 17,
        width: 17,
        backgroundColor: '#fff',
        border: '2px solid currentColor',

        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 14,
    },
    rail: {
        height: 8,
        borderRadius: 14,
    },

})(Slider);

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: 10,
        },
        card: {
            margin: '15px 0px 0px 0px',
            borderRadius: 10,
            background: '#FFFFFF',
            boxShadow: '0 0 0.5em #E2E2E2',
        },
        heading: {
            color: "#2645AD",
            fontSize: 18,
            fontWeight: 600,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            "@media (max-width: 426px)": {
                fontSize: '16px'
            }
        },
        accordianNoFlex: {
            display: 'block'
        },
        tableContainer: {
            marginTop: 20,
            width: '100%',
            boxShadow: '0 0 0.5em #E2E2E2',
            borderRadius: 10,
            borderColor: '#E2E2E2',
        },
        divider: {
            marginTop: -10,
            backgroundColor: '#E2E2E2',
        },
        searchText: {
            color: "#464646",
            fontWeight: 500,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: 18,
            marginLeft: 16,
            marginTop:5

        },
        searchButton: {
            // marginTop: 20,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "4px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
        },
        clearButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            color: "#FFFFFF",
            border: "0.791301px solid #8392AB",
            background: "#B70909",
            padding: "4px 20px",
            borderRadius: "2px",
            marginLeft: 15,
            '&:hover': {
                background: "#B70909",
                color: "#FFFFFF",
            }
            // marginTop: 20,
        },
        backButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            color: "#464646",
            border: "0.791301px solid #8392AB",
            padding: "6px 20px",
            borderRadius: "2px",
            marginLeft: 20,
            // marginTop: 20,
        },
        dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -10 },
        accountHeading: {
            color: "#464646",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 20,
            "@media (max-width: 426px)": {
                fontSize: '14px'
            }

        },
        addbutton: {
            marginTop: 5,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "5px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
            "@media (max-width: 426px)": {
                padding: '4px 15px',
                fontSize: 12,
            }

        },
        tableCellBody: {
            padding: 10,
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontWeight: 500,
            color: '#939292',
        },
        activeButton: {
            background: "#009A19",
            border: "1px solid #00C21F",
            color: "white",
            fontWeight: 600,
            height: 21,
            width: 55,
            fontSize: 11,
            fontFamily: "Lato",
            fontStyle: "normal",
            '&:hover': {
                background: "#009A19",
                color: "white",
            }
        },
        inputFields: {
            margin: '10px 0px 0px',
            display: 'flex',
            alignItems: 'center'
        },
        deleteButton: {
            margin: 10,
            background: '#4D72F8',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            padding: '6px 22px',
            color: '#FFFFFF',
            fontSize: 14,
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            }
        },
        CancelButton: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            borderRadius: 5,
            color: '#8392AB',
            fontSize: 14,
            padding: '6px 22px',
        },
        errorMessage: {
            color: 'red',
            fontSize: '0.8rem',
            fontWeight: 500,
            marginTop: 0,
            marginBottom: 0,
        },
        inactiveButton: {
            background: "crimson",
            border: "1px solid crimson",
            color: "white",
            fontWeight: 600,
            height: 21,
            width: 55,
            fontSize: 11,
            fontFamily: "Lato",
            fontStyle: "normal",
            '&: hover': {
                background: "crimson",
                color: "white",
            }
        },
        textColor: {
            color: '#464646',
            marginLeft: 10,
            fontWeight: 'bold',
        },
        tableHead: {
            fontSize: "14px",
            color: "#25282B",
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Work Sans",
        },
        tableBody: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.8rem",
            color: "#787878",
        },
        tablecolor: {
            backgroundColor: "#fbfbfb",
        },
        deviceWidth: {
            width: 30,
            height: 30,
            borderRadius: 50,
            color: 'black',
            // backgroundColor: '#F3F5FF',
            // fill:'#4D72F8',
            padding: '8px',
        },
        buttons: {
            textAlign: "center",
            marginTop: "25px"
        },
        formHeading: {
            marginTop: "20px",
            color: "#464646",
            fontStyle: "normal",
            fontWeight: 500,
            fontFamily: "Work Sans",
            fontSize: "16px",
            textAlign: 'center',
        },
        formHeadingsss: {

            color: "#464646",
            fontStyle: "normal",
            fontWeight: 500,
            fontFamily: "Work Sans",
            fontSize: "16px"
        },
        formTitle: {
            color: "#464646",
            backgroundColor: "#FCFCFC",
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Work Sans",
            fontSize: "20px",
            alignItems: "center",
            width: "1241px",
            height: " 49px",
        },
        formHeading1: {
            marginTop: "8px",

        },
        cardstyles: {
            background: '#FFFFFF',
            borderRadius: '8px',
            boxShadow: '0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)',
        },
        security: {
            color: "#3A416F",
            fontWeight: 500,
            fontFamily: 'Work Sans',
            fontStyle: "normal",
            fontSize: 18,
        },
        userName2: {
            color: "#8392AB",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 16,
            marginTop: '6px',
        },
        webLogin: {
            color: "#3A416F",
            fontFamily: "work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 20,
        },
        marginTop10: {
            marginTop: '10px',
        },


    })
);
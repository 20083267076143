import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    IconButton,
    withStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Switch,
    Button,
} from '@material-ui/core';
import Select from "react-select";

import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { BiMessageSquareAdd } from 'react-icons/bi'
import { CardHeader } from '../../../../components/CardHeader/CardHeader';
import { TableHeaderComponent } from '../../../../components/Table/Table';
import { useStyles } from './Styles';
import HoldMusicGreting from './HoldMusicGreeting/Greeting'
import Greeting from '../OpenHourGreeting/OpenHourGreeting';
import HoldQueue from './HoldQueue/HoldQueue';
import UnanswerCall from './UnanswerCall/unanswerCall';
import Itag from '../../../../assets/icons/italiantag.png';
import RoutingType from './RoutingType/Routingtype';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import DepartmentAgentTable from './DepartmentAgents/DepartmentAgents'
import DepartmentServices from '../../DepartmentServices/DepartmentServices'
import { useSelector } from 'react-redux'
import AgentModal from './AgentModal/AgentModal';
import UnansweredCallsnew from './UnanswerCall/UnAnswerCallNew'
import Slider from '@mui/material/Slider';
import HoldMusicCard from './HoldSettings/HoldMusic/HoldMusicCard'
import OperatorsTable from './OperatorTable/OperatorsTable'
import axiosInstance from '../../../../environment/axiosInstance'
import AddAgent from './AgentModal/AddAgent'
import './styles.css'
import HoldGreeting from './HoldSettings/HoldGreeting/HoldGreeting';
import InterruptHoldMusic from './HoldSettings/InterruptHoldMusic/InterruptHoldMusic';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AddOperators from './Operators/AddOperators';
import AddOperator from './OperatorTable/AddOperator';
toast.configure()

const PrettoSlider = withStyles({
    root: {
        color: '#4D72F8',
        width: '75%',
        marginLeft: 5,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',

        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 10,
        borderRadius: 14,
    },
    rail: {
        height: 10,
        borderRadius: 14,
    },
})(Slider);


// const marks = [
//     {
//       value: 0,
//       label: '0°C',
//     },
//     {
//       value: 20,
//       label: '20°C',
//     },
//     {
//       value: 37,
//       label: '37°C',
//     },
//     {
//       value: 100,
//       label: '100°C',
//     },
//   ];
const marks: any = [10, 15, 20, 25, 30, 35, 40, 45, 50]
function valuetext(value: number) {
    return `${value}°C`;
}


function OpenHoursAgents(props: any) {
    const classes = useStyles();
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment } = departmentStateData;
    const [addAgent, setAddAgent] = React.useState(false)
    const [operator, setOperator] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [AlluserList, setAllUserList] = React.useState([]);
    const [userlist, setUserList] = React.useState<any>([])
    const [openHoursState, setOpenHoursState] = React.useState({})
    const [openHoursData, setOpenHoursData] = React.useState<any>({})
    const [assignedOperatorsData, setassignedOperatorsData] = React.useState<any>([])
    const [value, setValue] = React.useState(0)
    const [open, setOpen] = React.useState(true);
    const [openHoldSeetings, setopenHoldSeetings] = React.useState(true);
    const [opencallsmissed, setopencallsmissed] = React.useState(true);
    const [selectAlwaysOpentriggerAgnt, setselectAlwaysOpentriggerAgnt] = React.useState(true);
    const [triggerBackUpagent, settriggerBackUpagent] = React.useState({
        triggerBackUpCallerReaches: 10,
        triggerHoldDurationReaches: 0
    });


    const handlePopupModalAddAgent = () => {
        setAddAgent(!addAgent)
    }

    const [agentsDurationData, setAgentsDurationData] = React.useState<any>({
        ringDuration: 12,
        userRingType: 3,
        holdQueue: true,
        holdGreeting: true,
        triggerBackupAgents: true,
        triggerBackUpCallerReaches: 1,
        triggerHoldDurationReaches: 2,
        maxQueueSize: 3,
        maxQueueWaitTime: 2

    })



    const getOpenHours = () => {
        DepartmentServices.getOpenHours(props.depId && props.depId).then((res: any) => {
            if (res.success) {
                const data = res.data
                setOpenHoursData(res.data)
                setAgentsDurationData({
                    ringDuration: data?.ringDuration,
                    userRingType: data?.userRingType,
                    holdQueue: data?.holdQueue,
                    triggerBackupAgents: data?.triggerBackupAgents,
                    triggerBackUpCallerReaches: data?.triggerBackUpCallerReaches,
                    triggerHoldDurationReaches: data?.triggerHoldDurationReaches,
                    maxQueueSize: data?.maxQueueSize,
                    maxQueueWaitTime: data?.maxQueueWaitTime
                })
                setRingDurationTime({
                    ringDuration: data?.ringDuration
                })
                settriggerBackUpagent({
                    triggerBackUpCallerReaches: data?.triggerBackUpCallerReaches,
                    triggerHoldDurationReaches: data?.triggerHoldDurationReaches,
                })
            } else {
            }
        }).catch((err: any) => {

        })
    }

    const updateAgents = () => {
        axiosInstance.patch(`departments/${props.depId}/open-hours/agents`, agentsDurationData).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                getOpenHours()
            }
        })
    }

    const [ringDurationTime, setRingDurationTime] = React.useState<any>({
        ringDurationTime: 0
    })

    const [secondsState, setSecondsState] = React.useState<any>(0)

    const expandOpen = () => {
        setOpen(!open);
    }
    const expandHoldOpen = () => {
        setopenHoldSeetings(!openHoldSeetings);
    }
    const expandmissedOpen = () => {
        setopencallsmissed(!opencallsmissed)
    }
    let timer1: any = null;

    const ringDurationHandle = (e: any, data: any) => {
        // console.log("-----timer", data)
        clearInterval(timer1)
        timer1 = setTimeout(() => {
            var myData = agentsDurationData
            myData.ringDuration = data
            setAgentsDurationData(myData)
            updateAgents()
        }, 100)
    }

    const onChnageAssignedOperators = (data: any) => {
        setassignedOperatorsData(data)
    }

    const getusersList = () => {
        setLoading(true)
        DepartmentServices.getUusersListBydeptId(selectedDepartment?._id).then((res) => {
            if (res.success) {
                setLoading(false)
                setAllUserList(res.data.data)
            }
            else {
                //err
                setLoading(false)
            }
        }).catch((err) => {
            //err
            setLoading(false)
        })

    }

   

    const handleModalAddAgent = () => {
        setAddAgent(!addAgent)
    }

    const handleModalOperator = () => {
        setOperator(!operator)
    }


    React.useEffect(() => {
        if (props.depId) {
            getOpenHours()
        }
    }, [props.depId])
    React.useEffect(() => {
        getusersList()
    }, [])

    //new

    const onChangeTriggerAgent = () => {
        setselectAlwaysOpentriggerAgnt(!selectAlwaysOpentriggerAgnt)
        // var myData = newdata
        var myData = agentsDurationData
        myData.triggerBackupAgents = !selectAlwaysOpentriggerAgnt
        setAgentsDurationData(myData)
        updateAgents()

    };

    const onChangeTriggerBackupAgntVals = (e: any) => {
        settriggerBackUpagent((prev: any) => {
            return {
                ...prev,
                [e.target.name]: Number(e.target.value)
            }
        })
        // var myData = newdata
        var myData = agentsDurationData
        myData[e.target.name] = Number(e.target.value)
        setAgentsDurationData(myData)
        updateAgents()
    }

    const onChangeMaxQueSize = (e: any) => {
        setAgentsDurationData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: Number(e.target.value)
            }
        })
        // var myData = newdata
        var myData = agentsDurationData
        myData[e.target.name] = Number(e.target.value)
        setAgentsDurationData(myData)
        updateAgents()
    }


    return (
        <>
            {
                addAgent && (
                    <div>
                        <Backdrop handlePopupModal={handleModalAddAgent} />
                        <Modal title="Add Agent" handlePopupModal={handleModalAddAgent} >
                            <div className={classes.modalwidth}>
                                <AddAgent
                                    handlePopupModalAddAgent={handlePopupModalAddAgent}
                                    getOpenHours={getOpenHours}
                                    openHoursData={openHoursData}
                                    depId={props.depId}
                                />
                            </div>
                        </Modal>
                    </div>
                )
            }

            {
                operator && (
                    <div>
                        <Backdrop handlePopupModal={handleModalOperator} />
                        <Modal title="Add Backup Operator" handlePopupModal={handleModalOperator} >
                            <div className={classes.modalwidth}>
                                <AddOperator
                                    agentsData={props.agentsData}
                                    handleModalOperator={handleModalOperator}
                                    getOpenHours={getOpenHours}
                                    openHoursData={openHoursData}
                                    depId={props.depId}
                                />
                            </div>
                        </Modal>
                    </div>
                )
            }



            <div className={classes.agentsContainer}>
                <RoutingType
                    depId={props.depId}
                // agentsDurationData={agentsDurationData}
                // setAgentsDurationData={setAgentsDurationData}
                // updateAgents={updateAgents}
                />
                
                <Card className={classes.card}>
                    <Accordion
                        expanded={open}
                    // onChange={expandOpen}
                    >
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="#2645ad" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.operatorHeading}>Operators</Typography>
                            < BiMessageSquareAdd className={classes.operators} onClick={handlePopupModalAddAgent} />

                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails>
                            <DepartmentAgentTable
                                openHoursData={openHoursData}
                                getOpenHours={getOpenHours}
                                depId={props.depId}

                            // assignedOperatorDt={assignedOperatorsData}
                            // AlluserList={AlluserList}
                            // getusersList={getusersList}
                            // openHoursData={openHoursData} 
                            />
                        </AccordionDetails>
                        <AccordionDetails className={classes.ringHanldling}>
                            <Typography gutterBottom className={classes.ringDuration}>
                                Ring Handling (Choose how long agents will Ring)
                            </Typography>
                            {/* <Sliders
                            valueLabelDisplay="auto"
                            // components={{ 
                            // ValueLabel: ValueLabelComponent, 
                            // }} 
                            onChange={ringDurationHandle}
                            defaultValue={ringDurationTime.ringDurationTime}
                            aria-label="custom thumb label"
                        // defaultValue={20}
                        /> */}
                            <div>
                                <PrettoSlider
                                    key={`slider-${ringDurationTime.ringDurationTime}`}
                                    onChange={ringDurationHandle}
                                    valueLabelDisplay="auto"
                                    aria-label="pretto slider"
                                    defaultValue={ringDurationTime.ringDuration}
                                    min={10}
                                    max={50}
                                    style={{ display: 'block' }}
                                />
                            </div>

                            {/* <Slider
                                aria-label="Custom marks"
                                defaultValue={20}
                                // getAriaValueText={valuetext}
                                step={5}
                                valueLabelDisplay="auto"
                                marks={marks} /> */}
                        </AccordionDetails>
                    </Accordion>
                </Card>
                <Card className={classes.card}>
                    <Accordion
                        expanded={openHoldSeetings}
                        onChange={expandHoldOpen}
                    >
                        <AccordionSummary
                            expandIcon={openHoldSeetings ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        >
                            <Typography className={classes.holdSettingsHeading}>Hold Settings</Typography>
                        </AccordionSummary>
                        {/* <Divider className={classes.divider} /> */}
                        <AccordionDetails >
                            <div style={{ display: 'flex', flexDirection: 'column' }} className={classes.holdMusicCard}>
                                <div style={{ marginTop: '25px', marginLeft: '15px', marginRight: '15px' }} >
                                    <HoldGreeting
                                        heading="Hold Greetings"
                                        holdGreetingPath={props.holdGreetingPath}
                                        holdGreetingFileName={props.holdGreetingFileName}
                                        depId={props.depId}
                                        openHours={props.openHours}
                                    />
                                    {/* <HoldMusicCard heading="Hold Greetings" /> */}
                                </div>
                                <div style={{ marginTop: '25px', marginLeft: '15px', marginRight: '15px' }}>
                                    <HoldMusicCard
                                        heading="Hold Music"
                                        holdMusicPath={props.holdMusicPath}
                                        holdMusicFileName={props.holdMusicFileName}
                                        depId={props.depId}
                                        getOpenHours={props.getOpenHours}
                                        openHours={props.openHours}
                                    />
                                </div>
                                {/* <Divider style={{ marginTop: '25px', border: '0.937048px solid #D2D2D2' }} /> */}
                                <div style={{ marginTop: '25px', marginLeft: '15px', marginRight: '15px', marginBottom: "25px" }} >
                                    <InterruptHoldMusic
                                        heading="Interrupt Hold Music"
                                        interrptFileName={props.interrptFileName}
                                        interrptPath={props.interrptPath}
                                        depId={props.depId}
                                        openHours={props.openHours}
                                    />
                                    {/* <HoldMusicCard heading="Interrupt Hold Music" /> */}
                                </div>
                                <div>
                                    <CardContent>
                                        <Typography style={{ fontFamily: 'Arial', fontStyle: 'normal', fontWeight: 600, fontSize: '20px', color: '#848282', marginBottom: '20px', marginLeft: '15px' }}>Queue Settings </Typography>
                                        <Grid container style={{ marginLeft: '25px' }}>
                                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Grid container className={classes.inputFields}>
                                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                                        <Typography className={classes.depaertmentName}>
                                                            Max Queue Size
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                                        <TextField
                                                            // fullWidth
                                                            size="small"
                                                            type="number"
                                                            style={{ width: '20%' }}
                                                            name="maxQueueSize"
                                                            // placeholder={props.placeholder}
                                                            value={agentsDurationData.maxQueueSize}
                                                            variant="outlined"
                                                            onChange={onChangeMaxQueSize}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Grid container className={classes.inputFields}>
                                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                                        <Typography className={classes.depaertmentName}>
                                                            Max Queue Waiting Time (mins)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                                        <TextField
                                                            // fullWidth
                                                            size="small"
                                                            type="number"
                                                            style={{ width: '20%' }}
                                                            name="maxQueueWaitTime"
                                                            // placeholder={props.placeholder}
                                                            value={agentsDurationData.maxQueueWaitTime}
                                                            variant="outlined"
                                                            onChange={onChangeMaxQueSize}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                    </CardContent>
                                </div>
                            </div>
                        </AccordionDetails>



                    </Accordion>
                </Card>
                <Card className={classes.card}>
                    <Accordion
                        expanded={opencallsmissed}
                        onChange={expandmissedOpen}
                    >
                        <AccordionSummary
                            expandIcon={opencallsmissed ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}

                        >
                            <Typography className={classes.callsmissed}>Calls Missed By Agents</Typography>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails>
                            <UnansweredCallsnew depId={props.depId} getOpenHours={props.getOpenHours} />
                        </AccordionDetails>
                    </Accordion>

                </Card>
            </div>
        </>

    )
}
export default OpenHoursAgents
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import CombineReducers from './store/AllReducers'
import createSagaMiddleware from '@redux-saga/core';
import IndexSaga from './store/ReduxSaga/IndexSaga'
const combinedAllReducerss: any = combineReducers(CombineReducers);
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sagaMiddleWare = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combinedAllReducerss, composeEnhancers(applyMiddleware(sagaMiddleWare)))
sagaMiddleWare.run(IndexSaga)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react'
import {
    Grid, Button, TextField
} from '@material-ui/core';
import { useStyles } from './styles';
import Select from 'react-select'
export default function SearchFilter(props: any) {
    const classes = useStyles();
    const handleClear = () => {
        props.clearFilter()
    }

    const handleChangeValue = (e: any) => {
        props.onChangeSearchData(e.target.name, e.target.value)
    }
    const [TypeOptions] = React.useState<any>([
        { value: 1, label: "Call" },
        { value: 2, label: "SMS" },
        { value: 3, label: "Features" }
    ])
    const onContentStatusChangeEvent = (e: any) => {
        props.onChangeSearchData("addonType", e.value)
    }
    const searchData = () => {
        props.searchedData(props.paginate.limit, props.paginate.page)
    }
    return (
        <div style={{ marginTop: "15px" }}>
            <Grid container spacing={2}>
                <Grid item md={3} sm={4}xs={12} >
                    <TextField size="small"
                        variant="outlined"
                        placeholder="Addon Name"
                        value={props.data.addonName}
                        onChange={handleChangeValue}
                        name="addonName"
                        fullWidth
                    />
                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                    <Select
                        placeholder="Addon Type"
                        name="addonType"
                        value={
                            TypeOptions.filter((option: any) =>
                                option.value === props.data.addonType)
                        }
                        onChange={onContentStatusChangeEvent}
                        options={TypeOptions} />

                </Grid>
                <Grid item md={3} sm={4} xs={12}>
                    <div className={classes.buttons}>
                        <Button
                        size="small"
                        variant="outlined"
                        className={classes.searchButton}
                        onClick={searchData}
                    >
                        Search
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.clearButton}
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                    </div>
                    

                </Grid>
            </Grid>

        </div>
    )
}

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
  TableHead,
  Box,
  CircularProgress,
  Popover
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import dayjs from 'dayjs';
import moment from "moment";
import ArrowIcon from '../../../assets/icons/arrow.png';
import ReactSelect from 'react-select';
import { useStyles } from './styles';
import Service from '../Service'
import axiosInstance from '../../../environment/axiosInstance'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DateRangePicker from "react-daterange-picker";


function UserStats(props: any) {
  const classes = useStyles();
  const [alignment, setAlignment] = React.useState<string | null>('left');
  const [value, setValue] = React.useState(null);
  const [childRow, setChildRow] = React.useState(false);
  const [childOpenRow, setChildOpenRow] = React.useState(false);
  const [childCloseRow, setChildCloseRow] = React.useState(false);
  const [allData, setAllData] = React.useState<any>([])
  const [openData, setOpenData] = React.useState<any>([])
  const [closeData, setCloseData] = React.useState<any>([])
  const [allDepartmentData, setAllDepartmentData] = React.useState<any>([])
  const [openDepartmentData, setOpenDepartmentData] = React.useState<any>([])
  const [closeDepartmentData, setCloseDepartmentData] = React.useState<any>([])
  const [tabVal, setTabVal] = React.useState("1")
  const [loading, setLoading] = React.useState(false)
  const [id, setId] = React.useState('')
  const [type, setype] = React.useState<any>(1)
  const [openCustomPicker, setopenCustomPicker] = React.useState(false)
  const anchorRef = React.useRef(null);

  const custom_startDate = dayjs('2024-05-01');
  const custom_endDate = dayjs('2024-05-10');
  const startDate = moment(custom_startDate.toDate());
  const endDate = moment(custom_endDate.toDate());
  const [dateRange, setDateRange] = React.useState<any>({ startDate, endDate, });
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };

  const handleChange = (value: any) => {
    setValue(value);
  };



  const keyOptions = [
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "Last 7 days", value: 3 },
    { label: "Last 30 days", value: 4 },
    { label: "This month ", value: 5 },
    { label: "Last month", value: 6 },
    { label: "Custom", value: 7 },

  ]

  // const getAllUserStats = (type: any) => {
  //   Service.getAllUsersStats(type).then((res) => {
  //     if (res.success) {
  //       const data = res.data.data
  //       setAllData(data)

  //     }
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }
  // const getOpenHourUserStats = (type: any, val: any) => {
  //   Service.getHourUsersStats(type, val).then((res) => {
  //     if (res.success) {
  //       const data = res.data.data
  //       setOpenData(data)

  //     }
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }
  // const getClosedHourUserStats = (type: any, val: any) => {
  //   Service.getHourUsersStats(type, val).then((res) => {
  //     if (res.success) {
  //       const data = res.data.data
  //       setCloseData(data)

  //     }
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }
  // React.useEffect(() => {
  //   if (tabVal === "1") {
  //     getAllUserStats(type)
  //   }
  //   else if (tabVal === "2") {
  //     getOpenHourUserStats(type, 1)
  //   }
  //   else if (tabVal === "3") {
  //     getClosedHourUserStats(type, 2)
  //   }
  // }, [])
  const getAllUserDepartmentStats = (type: any, id: any) => {
    Service.getUsersDepartmentAllStats(id, type).then((res) => {
      if (res.success) {
        const data = res.data.data
        setAllDepartmentData(data)

      }
    }).catch(err => {
      console.log(err)
    })
  }
  const getUserDepartmentOpenStats = (type: any, val: any, id: any) => {
    Service.getHourUsersDepartmentStats(id, type, val).then((res) => {
      if (res.success) {
        const data = res.data.data
        setOpenDepartmentData(data)
      }
    }).catch(err => {
      console.log(err)
    })
  }
  const getUserDepartmentcloseStats = (type: any, val: any, id: any) => {
    Service.getHourUsersDepartmentStats(id, type, val).then((res) => {
      if (res.success) {
        const data = res.data.data
        setCloseDepartmentData(data)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  //new user stats api integration
  const handleOptions = (e: any) => {
    localStorage.setItem('dbUserStats', e.value)
    setype(e.value)

    if (e.value === 7) {
      setopenCustomPicker(true)
    }
    else {
      if (tabVal === "1") {
        fetchAllUsersData(e.value)
      }
      else if (tabVal === "2") {
        fetchOpenHoursUsersData(e.value, 1)
      }
      else if (tabVal === "3") {
        fetchCloseHoursUsersData(e.value, 2)
      }
    }

  }

  // Handler for changing date range
  const handleSelect = (range: any) => {
    const startDate = range?.start?.format("YYYY-MM-DD");
    const endDate = range?.end?.format("YYYY-MM-DD");
    localStorage.setItem('dbUserStatsStartDate', startDate);
    localStorage.setItem('dbUserStatsEndDate', endDate);
    setDateRange(range);
  };

  const applyPayPeriod = () => {
    fetchAllUsersData(type)
  }

  const handleCustomPickerClose = () => {
    setopenCustomPicker(false);
  };

  const handleChildRow = (id: any) => {
    setId(id)
    setChildRow(!childRow)
    if (tabVal === "1") {
      // fetchAllUsersData(type)
    }
  }


  const handleOpenChildRow = (id: any) => {
    setId(id)
    setChildOpenRow(!childOpenRow)
    // fetchOpenHoursUsersData(type, 1)
  }

  const handleCloseChildRow = (id: any) => {
    setId(id)
    setChildCloseRow(!childCloseRow)
    // fetchCloseHoursUsersData(type, 2)
  }


  const handleTabValue = (val: any) => {
    setTabVal(val)
    if (val === "1" && tabVal === "1") {
      fetchAllUsersData(type)
    }
    else if (val === "2" && tabVal === "2") {
      fetchOpenHoursUsersData(type, 1)
    }
    else if (val === "3" && tabVal === "3") {
      fetchCloseHoursUsersData(type, 2)
    }
  }

  //all users

  const _ = require("lodash");
  const officeId = localStorage.getItem('SelectedCompanyId')
  const [usersData, setUsersData] = React.useState([])
  const [isPerEnabled, setIsPerEnabled] = React.useState<any>(0)
  const [sortBy, setSortBy] = React.useState<any>(null);
  const [sortOrder, setSortOrder] = React.useState<any>('asc');
  const [sorteduserName, setSortedUserName] = React.useState<any>([]);
  const handlePercentageChange = (event: any) => {
    setIsPerEnabled(event.target.checked ? 1 : 0);
  };

  React.useEffect(() => {
    fetchAllUsersData(type)
  }, [isPerEnabled])

  const fetchAllUsersData = (type: any) => {
    setLoading(true)

    // Convert Day.js objects to Moment.js objects directly in the URL construction
    // const startDate = dateRange?.start?.format("YYYY-MM-DD");
    // const endDate = dateRange?.end?.format("YYYY-MM-DD");

    let queryURL = '';
    if (localStorage.getItem('SelectedCompanyId')) {
      queryURL = `&officeId=${localStorage.getItem('SelectedCompanyId')}`
    }

    let url = `dashboard/user-stats?type=${type}${queryURL}&isPerEnabled=${isPerEnabled === 0 ? 0 : 1}`;

    // if (dateRange.start && dateRange.end) {
    //   url += `&startDate=${startDate}&endDate=${endDate}`;
    // }
    if (type == 7 && localStorage.getItem("dbUserStatsStartDate") && localStorage.getItem("dbUserStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbUserStatsStartDate")}&endDate=${localStorage.getItem("dbUserStatsEndDate")}`;
    }
    

    axiosInstance.get(url).then((res: any) => {
      if (res.status === 200) {
        setLoading(false)
        setUsersData(res.data.data)
      }
    })
  }

  //open hours
  const [openHoursdept, setOpenHoursDept] = React.useState([])

  const fetchOpenHoursUsersData = (type: any, val: any) => {
    setLoading(true)
    axiosInstance.get(`dashboard/user-stats?type=${type}&hourType=${val}&officeId=${officeId}`).then((res: any) => {
      if (res.status === 200) {
        setLoading(false)
        setOpenHoursDept(res.data.data)
      }
    })
  }


  //open hours
  const [closeHoursdept, setCloseHoursDept] = React.useState([])
  const fetchCloseHoursUsersData = (type: any, val: any) => {
    setLoading(true)
    axiosInstance.get(`dashboard/user-stats?type=${type}&hourType=${val}&officeId=${officeId}`).then((res: any) => {
      if (res.status === 200) {
        setLoading(false)
        setCloseHoursDept(res.data.data)
      }
    })
  }


  const userStats: any = localStorage.getItem('dbUserStats')
  const us = parseInt(userStats)
  // if () {
  //   const getValues: any = localStorage.getItem('dbUserStats');
  //   setype(parseInt(getValues));
  // }

  React.useEffect(() => {
    if (tabVal === "1" && us) {
      fetchAllUsersData(us)
      setype(us)
    }
    if (!us) {
      fetchAllUsersData(type)
    }
  }, [tabVal])
  React.useEffect(() => {
    if (tabVal === "2") {
      fetchOpenHoursUsersData(type, 1)
    }
  }, [tabVal])
  React.useEffect(() => {
    if (tabVal === "3") {
      fetchCloseHoursUsersData(type, 2)
    }
  }, [tabVal])


  const handleSort = (column: any) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(column);

  };

  React.useEffect(() => {
    var sorted;
    if (sortBy === "outtotal") {
      sorted = _(usersData).orderBy((item: any) => item.outgoingUserStats.total, sortOrder).value();
    } else if (sortBy === "outConnected") {
      sorted = _(usersData).orderBy((item: any) => item.outgoingUserStats.connected, sortOrder).value();
    }
    else if (sortBy === "OutTotalOutDuration") {
      sorted = _(usersData).orderBy((item: any) => item.outgoingUserStats.totalOutDuration, sortOrder).value();
    }
    else if (sortBy === "Intotal") {
      sorted = _(usersData).orderBy((item: any) => item.incomingUserStats.total, sortOrder).value();
    }
    else if (sortBy === "InConnected") {
      sorted = _(usersData).orderBy((item: any) => item.incomingUserStats.connected, sortOrder).value();
    }
    else if (sortBy === "InMissed") {
      sorted = _(usersData).orderBy((item: any) => item.incomingUserStats.missed, sortOrder).value();
    } else {
      sorted = usersData
    }
    setSortedUserName(sorted);

  }, [usersData, sortBy, sortOrder]);


  // React.useEffect(() => {
  //   if (localStorage.getItem('dbUserStats')) {
  //     const getValues: any = localStorage.getItem('dbUserStats');
  //     setype(parseInt(getValues));
  //   }
  //   if (tabVal === "1") {
  //     fetchAllUsersData(type)
  //   }
  // }, [tabVal])
  // React.useEffect(() => {
  //   if (tabVal === "2") {
  //     fetchOpenHoursUsersData(type, 1)
  //   }
  // }, [tabVal])
  // React.useEffect(() => {
  //   if (tabVal === "3") {
  //     fetchCloseHoursUsersData(type, 2)
  //   }
  // }, [tabVal])

  //redirect page

  const outgoingRedirectNextPage = (id: any, outgoing: any, total: any, connected: any) => {
    // if (total === 1) {
    //   const url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${outgoing?.callDirection}&total=1`
    //   window.open(url, "_blank")
    // } else if (connected === 1) {
    //   const url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${outgoing?.callDirection}&connected=1`
    //   window.open(url, "_blank")
    // }
    let url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${outgoing?.callDirection}`;
    if (total === 1) {
      url += `&total=1`
    } 
    if(connected === 1){
      url += `&connected=1`
    }
    if (type == 7 && localStorage.getItem("dbUserStatsStartDate") && localStorage.getItem("dbUserStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbUserStatsStartDate")}&endDate=${localStorage.getItem("dbUserStatsEndDate")}`;
    }
    window.open(url, "_blank");
  }

  //incoming
  const incomingRedirectNextPage = (id: any, incoming: any, total: any, connected: any, missed: any) => {
    // if (total === 1) {
    //   const url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${incoming?.callDirection}&total=1`
    //   window.open(url, "_blank")
    // } else if (connected === 1) {
    //   const url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${incoming?.callDirection}&connected=1`
    //   window.open(url, "_blank")
    // } else if (missed === 1) {
    //   const url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${incoming?.callDirection}&missed=1`
    //   window.open(url, "_blank")
    // }
    let url = `/dashboard/reports?userId=${id}&type=${type}&callDirection=${incoming?.callDirection}`;
    if (total === 1) {
      url += `&total=1`
    } 
    if(connected === 1){
      url += `&connected=1`
    }
    if(missed === 1){
      url += `&missed=1`
    }
    if (type == 7 && localStorage.getItem("dbUserStatsStartDate") && localStorage.getItem("dbUserStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbUserStatsStartDate")}&endDate=${localStorage.getItem("dbUserStatsEndDate")}`;
    }
    window.open(url, "_blank");
  }

  const open = Boolean(openCustomPicker);
  const IDBUT = open ? 'simple-popover' : undefined;

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5} className={classes.cardContent}>
            <Grid item md={3} sm={3} className={classes.grid}>
              <Typography className={classes.userText}>User/RoomPhone Stats</Typography>
            </Grid>
            <Grid item md={4} sm={4} xs={4} className={classes.gridItem}>
              {/* <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
                <ToggleButton className={classes.button} value="left" onClick={() => handleTabValue("1")}>
                  <Typography className={classes.toggleText}>All</Typography>
                </ToggleButton>
                <ToggleButton className={classes.button} value="center" onClick={() => handleTabValue("2")}>
                  <Typography className={classes.toggleText}>Open Hours</Typography>
                </ToggleButton>
                <ToggleButton className={classes.button} value="right" onClick={() => handleTabValue("3")}>
                  <Typography className={classes.toggleText}>Close Hours</Typography>
                </ToggleButton>
              </ToggleButtonGroup> */}
            </Grid>

            <Grid item md={2} sm={2} xs={12} className={`classes.gridItem ${classes.width}`}>
              <FormControlLabel
                value="isPerEnabled"
                control={<Switch color="primary" onChange={handlePercentageChange} />}
                label="isPercentage"
                labelPlacement="start"
              />
            </Grid>


            <Grid item md={3} sm={3} xs={12} className={`classes.gridItem ${classes.width}`}>
              <ReactSelect placeholder={'Today'} options={keyOptions}
                onChange={handleOptions}
                value={keyOptions.filter((opt: any) => opt.value === type)} />
            </Grid>
            <Popover
              //   className={classes.Popover}
              id={IDBUT}
              open={open}
              anchorEl={anchorRef.current}
              onClose={handleCustomPickerClose}
              anchorOrigin={{
                vertical: 'top', // Align to the top of the anchor element
                horizontal: 'right', // Align to the left side of the anchor element
              }}
              transformOrigin={{
                vertical: 'bottom', // Align to the top of the popover content
                horizontal: 'right', // Align to the left side of the popover content
              }}
            >
              <Grid container >
                <Grid
                  style={{ borderLeft: "1px solid lightgray" }}
                  item sm={8} xs={8}
                >
                  {/* <DateRangePicker  
                         value={value}                        
                           locale="en-US"                                              
                            onSelect={onSelectStartEndDate}
                            singleDateRange={true}
                            /> */}
                  <div className="date-range-picker-container">
                    <DateRangePicker
                      onSelect={handleSelect}
                      value={dateRange}
                      singleDateRange={true}
                    />
                  </div>

                  <Grid
                    sm={12}
                    style={{ textAlign: "center", margin: "5px" }}
                  >
                    <button
                      className={classes.button}
                      onClick={applyPayPeriod}
                    >
                      Apply
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </CardContent>
        <Divider style={{ marginTop: -5 }} />
        {loading ?
          <Box>
            <div style={{ width: "100%", textAlign: "center" }}>
              <CircularProgress />
            </div>
          </Box>
          :
          <>
            {tabVal === "1" ?
              <CardContent>
                <TableContainer component={Card} className={classes.tableContainer} id="tableWidthDashboard">
                  <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell className={classes.tableCell} rowSpan={2} align="center">
                          Users
                        </TableCell>
                        <TableCell className={classes.tableCell} rowSpan={2} align="center">
                          User Type
                        </TableCell>
                        <TableCell className={classes.tableCell} rowSpan={2} align="center">
                          Duration
                        </TableCell>
                        <TableCell className={classes.tableCell} colSpan={3} align="center">
                          Outgoing
                        </TableCell>
                        <TableCell className={classes.tableCell} colSpan={6} align="center">
                          Incoming
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} align="center">
                          Total {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon}
                            onClick={() => handleSort("outtotal")}
                            src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Connected {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon}
                            onClick={() => handleSort("outConnected")}
                            alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("OutTotalOutDuration")}
                          className={classes.tableCell} align="center">
                          Total Out Duration
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("Intotal")}
                          className={classes.tableCell} align="center">
                          Total {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("InConnected")}
                          className={classes.tableCell} align="center">
                          Connected {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("InMissed")}
                          className={classes.tableCell} align="center">
                          Missed {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Queue Wait Time

                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Avg Inc Duration

                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Total Inc Duration

                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {sorteduserName && sorteduserName?.map((item: any) => {
                      return (
                        <>
                          <TableHead className={classes.tableHead1} >
                            <TableRow >
                              <TableCell className={classes.tableCellRow} align="center">
                                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => handleChildRow(item.firstName)}>
                                  {item.firstName}  {item.lastName}
                                  {childRow && id === item.firstName ? (
                                    <KeyboardArrowUp onClick={() => handleChildRow(item.firstName)} />
                                  ) : (
                                    <KeyboardArrowDown onClick={() => handleChildRow(item.firstName)} />
                                  )}
                                </div>
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center">
                                {item.userType}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"

                              >
                                {item.duration}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"
                                onClick={() => outgoingRedirectNextPage(item._id, item.outgoingUserStats, 1, 0)}
                              >
                                {item.outgoingUserStats?.total}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"
                                onClick={() => outgoingRedirectNextPage(item._id, item.outgoingUserStats, 0, 1)}
                              >
                                <div>
                                  {item.outgoingUserStats?.connected}
                                  <div>
                                    <Typography style={{ color: "gray", fontSize: "12px" }}>{item.outgoingUserStats?.outboundDuration}</Typography>
                                  </div>
                                </div>

                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"
                                // onClick={() => outgoingRedirectNextPage(item._id, item.outgoingUserStats, 0, 1)}
                              >
                                <div>
                                  {item.outgoingUserStats?.totalOutDuration}
                                </div>

                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"
                               onClick={() => incomingRedirectNextPage(item._id, item.incomingUserStats, 1, 0, 0)}
                              
                              >
                                {item.incomingUserStats?.total}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"
                               onClick={() => incomingRedirectNextPage(item._id, item.incomingUserStats, 0, 1, 0)}
                              >
                                <div>
                                  {item.incomingUserStats?.connected}
                                  <div>
                                    <Typography style={{ color: "gray", fontSize: "12px" }}>{item.incomingUserStats?.inboundDuration}</Typography>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center"
                               onClick={() => incomingRedirectNextPage(item._id, item.incomingUserStats, 0, 0, 1)}
                              >
                                {item.incomingUserStats?.missed}
                                {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center">
                                {item.incomingUserStats?.queueWaitTime}
                                {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center">
                                {item.incomingUserStats?.avgIncDuration}
                                {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                              </TableCell>
                              <TableCell className={classes.tableCellRow} align="center">
                                {item.incomingUserStats?.totalIncDuration}
                                {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {childRow && (id === item.firstName) &&

                            <TableBody>
                              {item.deptStats && item.deptStats.map((detail: any) => {
                                return (
                                  <>
                                    <TableRow key={detail.name}>
                                      <TableCell className={classes.tableCellBody}>{detail.name}</TableCell>
                                      <TableCell className={classes.tableCellBody}>{detail.userType}</TableCell>
                                      <TableCell className={classes.tableCellBody}>{detail.duration}</TableCell>
                                      <TableCell className={classes.tableCellBody}>{detail.outgoingStats?.total}</TableCell>
                                      <TableCell className={classes.tableCellBody}>
                                        <div>
                                          {detail.outgoingStats?.connected}

                                          <div>
                                            <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outgoingStats?.outboundDuration}</Typography>
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.tableCellBody}>
                                        {detail.outgoingStats?.totalOutDuration}
                                      </TableCell>
                                      <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                      <TableCell className={classes.tableCellBody}>
                                        <div>
                                          {detail.incomingStats?.connected}

                                          <div>
                                            <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.incomingStats?.inboundDuration}</Typography>
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.missed}</TableCell>
                                      <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.queueWaitTime}</TableCell>
                                      <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.avgIncDuration}</TableCell>
                                      <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.totalIncDuration}</TableCell>
                                    </TableRow>


                                  </>
                                );
                              })}
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>

                              <TableCell>
                                {sorteduserName.length <= 0 &&
                                  <p style={{ textAlign: "center", marginRight: '90px', fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>}
                              </TableCell>

                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableBody>
                          }
                        </>
                      )
                    })}
                  </Table>
                  {usersData.length <= 0 &&
                    <p style={{ textAlign: "center", fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                  }
                </TableContainer>
              </CardContent>
              :
              tabVal === "2" ?
                <CardContent>
                  <TableContainer component={Card} className={classes.tableContainer} id="tableWidthDashboard">
                    <Table className={classes.table}>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={classes.tableCell} rowSpan={2} align="center">
                            Users
                          </TableCell>
                          <TableCell className={classes.tableCell} colSpan={2} align="center">
                            Outgoing
                          </TableCell>
                          <TableCell className={classes.tableCell} colSpan={3} align="center">
                            Incoming
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCell} align="center">
                            Total
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Connected
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Total
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Connected
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Missed
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {openHoursdept && openHoursdept.map((item: any, i: any) => {
                        return (
                          <>
                            <TableHead className={classes.tableHead1} key={i}>
                              <TableRow>
                                <TableCell className={classes.tableCellRow} align="center">
                                  <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => handleOpenChildRow(item.name)}>
                                    {item.firstName}    {item.lastName}
                                    {childOpenRow && (id === item.name) ? (
                                      <KeyboardArrowUp onClick={() => handleOpenChildRow(item.name)} />
                                    ) : (
                                      <KeyboardArrowDown onClick={() => handleOpenChildRow(item.name)} />
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.outgoingUserStats?.total}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  <div>
                                    {item.outgoingUserStats?.connected}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                    <div>
                                      <Typography style={{ color: "gray", fontSize: "12px" }}>{item.outgoingUserStats?.outboundDuration}</Typography>
                                    </div>
                                  </div>

                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingUserStats?.total}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  <div>
                                    {item.incomingUserStats?.connected}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                    <div>
                                      <Typography style={{ color: "gray", fontSize: "12px" }}>{item.incomingUserStats?.inboundDuration}</Typography>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingUserStats?.missed}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {childOpenRow && (id === item.name) &&

                              <TableBody>
                                {item.deptStats && item.deptStats.map((detail: any, i: any) => {
                                  return (
                                    <>
                                      <TableRow key={i}>
                                        <TableCell className={classes.tableCellBody}>{detail.name}</TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.outgoingStats?.total}</TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          <div>
                                            {detail.outgoingStats.connected}

                                            <div>
                                              <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outgoingStats?.outboundDuration}</Typography>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          <div>
                                            {detail.incomingStats?.connected}

                                            <div>
                                              <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.incomingStats?.inboundDuration}</Typography>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.missed}</TableCell>
                                      </TableRow>


                                    </>
                                  );
                                })}
                                {openHoursdept.length <= 0 &&
                                  <p style={{ textAlign: "center", fontSize: 14, fontWeight: 400, marginTop: 10 }}> No Data found</p>}
                              </TableBody>
                            }
                          </>
                        )
                      })}


                    </Table>
                    {openHoursdept.length <= 0 &&
                      <p style={{ textAlign: "center", fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                    }
                  </TableContainer>
                </CardContent>
                : tabVal === "3" ?
                  <CardContent>
                    <TableContainer component={Card} className={classes.tableContainer} id="tableWidthDashboard">
                      <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell className={classes.tableCell} rowSpan={2} align="center">
                              Users
                            </TableCell>
                            <TableCell className={classes.tableCell} colSpan={2} align="center">
                              Outgoing
                            </TableCell>
                            <TableCell className={classes.tableCell} colSpan={3} align="center">
                              Incoming
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableCell} align="center">
                              Total
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Connected
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Total
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Connected
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Missed
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {closeHoursdept && closeHoursdept.map((item: any, i: any) => {
                          return (
                            <>
                              <TableHead className={classes.tableHead1} key={i}>
                                <TableRow>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => handleCloseChildRow(item.name)}>
                                      {item.firstName} {item.lastName}
                                      {childCloseRow && (id === item.name) ? (
                                        <KeyboardArrowUp onClick={() => handleCloseChildRow(item.name)} />
                                      ) : (
                                        <KeyboardArrowDown onClick={() => handleCloseChildRow(item.name)} />
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    {item.outgoingUserStats?.total}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    <div>
                                      {item.outgoingUserStats?.connected}
                                      {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                      <div>
                                        <Typography style={{ color: "gray", fontSize: "12px" }}>{item.outgoingUserStats?.outboundDuration}</Typography>
                                      </div>
                                    </div>

                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    {item.incomingUserStats.total}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    <div>
                                      {item.incomingUserStats?.connected}
                                      {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                      <div>
                                        <Typography style={{ color: "gray", fontSize: "12px" }}>{item.incomingUserStats?.inboundDuration}</Typography>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    {item.incomingUserStats?.missed}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {childCloseRow && id === item.name &&

                                <TableBody>
                                  {item.deptStats && item.deptStats.map((detail: any, i: any) => {
                                    return (
                                      <>
                                        <TableRow key={i}>
                                          <TableCell className={classes.tableCellBody}>{detail.name}</TableCell>
                                          <TableCell className={classes.tableCellBody}>{detail.outgoingStats?.total}</TableCell>
                                          <TableCell className={classes.tableCellBody}>
                                            <div>
                                              {detail.outgoingStats?.connected}

                                              <div>
                                                <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outgoingStats?.outboundDuration}</Typography>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                          <TableCell className={classes.tableCellBody}>
                                            <div>
                                              {detail.incomingStats?.connected}

                                              <div>
                                                <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.incomingStats?.inboundDuration}</Typography>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.missed}</TableCell>
                                        </TableRow>


                                      </>
                                    );
                                  })}
                                  {closeHoursdept.length <= 0 &&
                                    <p style={{ textAlign: "center", fontSize: 15, fontWeight: 400, marginTop: 10 }}> No Data found</p>}
                                </TableBody>
                              }
                            </>
                          )
                        })}


                      </Table>
                      {closeHoursdept.length <= 0 &&
                        <p style={{ textAlign: "center", fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                      }
                    </TableContainer>
                  </CardContent> : ""}
          </>
        }
      </Card>

    </>
  )
}
export default UserStats
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) => ({
    leftCard: {
        boxShadow: '0px 12.5214px 16.9039px rgb(0 0 0 / 5%)',
        background: '#FFFFFF',
        height: '80vh',
        padding: 10,
        overflow: 'hidden !important',
        // marginBottom:20
    
    },
    leftDiv: {
        display: 'flex', alignItems: 'center',
        gap: 8,
        // background: '#e2e8f0',
        borderRadius: '0.5rem',
        marginTop: 25,
        height: 65,
        padding: 8,
        // boxShadow: '0px 12.5214px 16.9039px rgb(0 0 0 / 5%)',
        '&:hover': {
            // boxShadow: '0px 12.5214px 16.9039px rgb(0 0 0 / 5%)',
            background: '#e2e8f0',
        }
    },
    groupBtn:{
        textTransform:'capitalize'
    },
    leftDivv: {
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
        borderRadius: '0.5rem',
        '&:hover': {
            background: '#e2e8f0',
            width: '100%',
        }
    },
    rightCard: {
        boxShadow: '0 0 0.5em #e2e2e2',
        height: '80vh',
        overflow: 'hidden'
    },
    rightCardbody: {
        display: 'flex',
        height: '80vh',
        flexFlow: 'column'
    },
    rightCardbodyy: {
        display: 'flex',
        height: '50vh',
        flexFlow: 'column'
    },
    headerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 55,
    },
    inputField: {
        border: 'none',
        width: '100%',
        borderRadius: 20,
        height: 40,
        background: '#e2e8f0',
        outline: 'none',
        margin: '5px'
    },
    sendBtn: {
        borderRadius: 20,
        color: 'white',
        background: '#4299e1',
        textTransform: 'capitalize',
        padding: '8px 20px'
    },
    leftMsg: {
        marginTop: 10,
        padding: 5,
        borderRadius: '10px 10px 10px 0px',
        background: '#e2e8f0',
        width: '290px',
        minHeight: 50,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        marginLeft: 14,
        justifyContent: 'space-between'
    },
    rightMsg: {
        padding: 5,
        width: 280,
        minHeight: 50,
        marginTop: 10,
        float: 'right',
        borderRadius: '10px 10px 0px 10px',
        background: '#e2e8f0',
        display: 'flex', alignItems: 'center', gap: 5,
        justifyContent: 'space-between',

        // marginRight: 20
    },
    callicon: {
        fontSize: 20
    },
    voiceCall: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 6,
    },
    voiceCalls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 6,
        background: '#ecffe5',
        borderRadius: '8px',
        padding: '7px 10px'
    },
    missesdCalls: {
        fontSize: 14,
        color: 'blue'
    },
    createRoomBtn: {
        fontSize: 12,
        marginTop: 8,
        textTransform: 'capitalize',
        padding: '2px 30px',
        borderRadius: 20,
        width: '100%'
    },
    pink: {

    },
    dFlex: {
        marginTop: 20,
        float: 'right'
    },
    cancelBtn: {
        textTransform: 'capitalize',
        fontSize: 13,
        color: 'blue'
    }
}))
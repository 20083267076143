import React from 'react'
import { Button, Grid, Typography, makeStyles, Tabs, Tab, } from '@material-ui/core'
import { useStyles } from '../style'
import Select from 'react-select'
import Backdrop from '../../../../../../components/Modal/Backdrop';
import Modal from '../../../../../../components/Modal/Modal'
import { TabContext, TabPanel } from '@material-ui/lab';
import axiosInstance from '../../../../../../environment/axiosInstance'
import ReactAudioPlayer from 'react-audio-player';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import UploadAudio from './UploadAudio';
import RecordAudio from './RecordAudio';
const muiTheme = createMuiTheme({});

// import AudioCard from '../OpenHourGreeting/OpenHourGreetingCard'
const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });
function UnAnsweredRecMessage(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [depts, setDepts] = React.useState([])
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }

    
    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div  className={classes.editmodal}  >
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            openHoursData={props.openHoursData}
                                            getOpenHours={props.getOpenHours}
                                            depId={props.depId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadAudio
                                            openHoursData={props.openHoursData}
                                            getOpenHours={props.getOpenHours}
                                            depId={props.depId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }
            <div style={{ marginLeft: '26px' }}>

                <div className={classes.audiocard}>
                    <div className={classes.audioContainer}>
                        <ThemeProvider theme={muiTheme}>
                            <ReactAudioPlayer
                                controls
                                // className={classes.audioPlayer}
                                onLoadedMetadata={handleLoadMetadata}
                                src={props.recPath}
                                className={classes.audioPlayers}
                            />

                        </ThemeProvider>
                        <Button className={classes.editbtnn} onClick={handlePopupModalAdd}>
                            Edit
                        </Button>
                    </div>
                    <div style={{ textAlign: 'left', marginTop: '0px' }}>
                        {/* <p className={classes.filename}>Filename.mp3</p> */}
                        <span className={classes.filename}>{props.recFileName}</span>
                    </div>
                    {/* <AudioCard /> */}
                </div>
            </div>
        </>
    )
}

export default UnAnsweredRecMessage
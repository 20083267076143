import React from 'react'
import {
  TableCell,
  TableRow,
  Table, TableBody, TableContainer, TableHead,
  IconButton,
  Checkbox,
  Typography,
  Button,
  MenuItem,
  Divider,
  Grid,
  TextField,
  Card,
  CardContent
} from '@material-ui/core';
import { useStyles } from '../styles';
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Select from 'react-select';
import { InputAdornment } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import axiosInstance from '../../../../environment/axiosInstance'
import BusinessServices from "../BusinessAccountsServices/services"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

function Form(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const [showPw, setShowPw] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false);
  const [planlist, setPlanList] = React.useState<any>([]);
  const [Status] = React.useState<any>([
    { value: 1, label: "Enabled" },
    { value: 2, label: "Disabled" },
  ])
  const [data, setData] = React.useState<any>({
    // callAddons: [],
    // smsAddons: [],
    // featureAddons: [],
    firstName: "",
    lastName: "",
    // planId: "",
    status: 1,
    companyName: "",
    contactName: "",
    gstNumber: "",
    address: "",
    city: "",
    province: "",
    country: "",
    zipcode: "",
    users:'5'
  })
  const [addonlist, setAddonList] = React.useState<any>([]);

  const getAddons = React.useCallback(() => {
    setLoading(true);
    axiosInstance.get('/admin/addons').then((res: any) => {
      if (res.data.data) {
        setAddonList(res.data.data)
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }, [])

  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then((results) => {
        var myResults = results[0]
        var city = ""
        //var state = ""
        var country = ""
        var postalCode = ""
        var streetNumber = ""
        var streetName = ""
        var province = ""


        getLatLng(myResults).then((latLong) => {
          myResults.address_components.map((item) => {
            if (item.types.includes("locality")) {
              city = item.long_name
            }
            // if (item.types.includes("administrative_area_level_1")) {
            //   state = item.long_name
            // }
            if (item.types.includes("country")) {
              country = item.long_name
            }
            if (item.types.includes("postal_code")) {
              postalCode = item.long_name
            }
            if (item.types.includes("street_number")) {
              streetNumber = item.long_name
            }
            if (item.types.includes("route")) {
              streetName = item.short_name
            }
            if (item.types.includes("administrative_area_level_1")) {
              province = item.short_name
            }

          })
          setData(() => {
            return {
              ...data,
              address: myResults.formatted_address,
              city: city,
              country: country,
              zipcode: postalCode,
              province: province,
            }
          });
        })
      })
      .catch(error => console.error('Error', error));
  };


  var callDropdown: any = [];
  var smsDropdown: any = [];
  var featureDropdown: any = [];
  addonlist.map((item: any) => {
    if (item.addonType === 1) {
      var object = {
        "value": item.id,
        "label": item.addonName
      }
      callDropdown.push(object)
    }
    if (item.addonType === 2) {
      var object = {
        "value": item.id,
        "label": item.addonName
      }
      smsDropdown.push(object)
    }
    if (item.addonType === 3) {
      var object = {
        "value": item.id,
        "label": item.addonName
      }
      featureDropdown.push(object)
    }
  })

  const handleChangecallAddons = (e: any) => {
    const newValieedata: any = [];
    e.map((item: any) => {
      newValieedata.push(item.value)
    })
    setData((prev: any) => {
      return {
        ...data,
        callAddons: newValieedata,
      }
    })
  };

  const handleChangesmsAddons = (e: any) => {
    const newValieedata: any = [];
    e.map((item: any) => {
      newValieedata.push(item.value)
    })
    setData((prev: any) => {
      return {
        ...data,
        smsAddons: newValieedata,
      }
    })
  };

  const handleChangeFeaturesAddons = (e: any) => {
    const newValieedata: any = [];
    e.map((item: any) => {
      newValieedata.push(item.value)
    })
    // setData((prev: any) => {
    //   return {
    //     ...data,
    //     featureAddons: newValieedata,
    //   }
    // })
  };


  const getPlans = React.useCallback(() => {
    setLoading(true);
    axiosInstance.get('/admin/plans').then((res: any) => {
      if (res.data.data) {
        setPlanList(res.data.data)
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }, [])

  var PlanDropwdown: any = [];
  planlist.map((item: any) => {
    var object = {
      "value": item.id,
      "label": item.planName
    }
    PlanDropwdown.push(object)
  })

  React.useEffect(() => {
    getPlans()
    getAddons()
  }, [])

  const handleChangeblocks = (e: any, name: any) => {
    setData((prev: any) => {
      return {
        ...data,
        [name]: e.value,
      }
    })
  };

  const onContentChangeEvent = (e: any) => {
    const val = e.target.value
    if (val !== " ") {
      setData((prev: any) => {
        return {
          ...data,
          [e.target.name]: e.target.value,
        }
      })
    }

  }
  const handleChange = (newData: any) => {
    setData(() => {
      return {
        ...data,
        address: newData
      }
    });
  };

  const onSubmitdata = () => {
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      BusinessServices.postBusinessAccount(data).then((res) => {
        if (res.success) {
          history.push("/businessAccounts")
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const [shouldopen, setShouldopen] = React.useState<any>(true);
  const onChangeEvents = () => {
    setShouldopen(!shouldopen);
  };

  const habdleshowPW = () => {
    setShowPw(!showPw)
  }

  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      email: { ...validData },
      password: { ...validData },
      companyName: { ...validData },
      contactName: { ...validData },
      firstName: { ...validData },
      lastName: { ...validData },
      phoneNumber: { ...validData },
      country: { ...validData },
      address: { ...validData },
      city: { ...validData },
      status: { ...validData },
      province: { ...validData },
      zipcode: { ...validData },
      users: { ...validData },
    };
    let isValid = true;
    if (submitted) {
      const validateEmail = (email: any) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      if (data.email === " ") {
        retData.email = {
          isValid: false,
          message: "Please enter  email space is not allowed"
        };
        isValid = false;
      }
      if (!validateEmail(data.email)) {
        retData.email = {
          isValid: false,
          message: "Please enter  valid email "
        };
        isValid = false;
      }
      if (!data.email) {
        retData.email = {
          isValid: false,
          message: "Please enter email"
        };
        isValid = false;
      }
      if (data.password === " ") {
        retData.password = {
          isValid: false,
          message: "Please enter  password space is not allowed"
        };
        isValid = false;
      }
      if (data.password?.length < 6) {
        retData.password = {
          isValid: false,
          message: "Password should be of  6 digit "
        };
        isValid = false;
      }
      if (!data.password) {
        retData.password = {
          isValid: false,
          message: "Please enter password"
        };
        isValid = false;
      }
      if (!data.companyName) {
        retData.companyName = {
          isValid: false,
          message: "Please enter Company Name"
        };
        isValid = false;
      }
      if (!data.contactName) {
        retData.contactName = {
          isValid: false,
          message: "Please enter Contact Name"
        };
        isValid = false;
      }
      if (data.firstName === " ") {
        retData.firstName = {
          isValid: false,
          message: "Please enter first Name space is not allowed"
        };
        isValid = false;
      }
      if (!data.firstName) {
        retData.firstName = {
          isValid: false,
          message: "Please enter first Name"
        };
        isValid = false;
      }
      if (data.lastName === " ") {
        retData.lastName = {
          isValid: false,
          message: "Please enter Last name space is not allowed"
        };
        isValid = false;
      }
      if (!data.lastName) {
        retData.lastName = {
          isValid: false,
          message: "Please enter Last name"
        };
        isValid = false;
      }
      if (data.status === " ") {
        retData.status = {
          isValid: false,
          message: "Please enter status space is not allowed"
        };
        isValid = false;
      }
      if (data.status === " ") {
        retData.status = {
          isValid: false,
          message: "Please select status space is not allowed"
        };
        isValid = false;
      }
      if (!data.status) {
        retData.status = {
          isValid: false,
          message: "Please select status"
        };
        isValid = false;
      }
      // if (data.planId === " ") {
      //   retData.planId = {
      //     isValid: false,
      //     message: "Please select planId space is not allowed"
      //   };
      //   isValid = false;
      // }
      // if (!data.planId) {
      //   retData.planId = {
      //     isValid: false,
      //     message: "Please select planId"
      //   };
      //   isValid = false;
      // }
      if (data.address === " ") {
        retData.address = {
          isValid: false,
          message: "Please enter address space is not allowed"
        };
        isValid = false;
      }
      if (!data.address) {
        retData.address = {
          isValid: false,
          message: "Please enter address"
        };
        isValid = false;
      }
      if (data.city === " ") {
        retData.city = {
          isValid: false,
          message: "Please enter city space is not allowed"
        };
        isValid = false;
      }
      if (!data.city) {
        retData.city = {
          isValid: false,
          message: "Please enter city"
        };
        isValid = false;
      }
      if (data.province === " ") {
        retData.province = {
          isValid: false,
          message: "Please enter province space is not allowed"
        };
        isValid = false;
      }
      if (!data.province) {
        retData.province = {
          isValid: false,
          message: "Please enter province"
        };
        isValid = false;
      }
      if (data.zipcode === " ") {
        retData.zipcode = {
          isValid: false,
          message: "Please enter postal Code space is not allowed"
        };
        isValid = false;
      }
      if (!data.zipcode) {
        retData.zipcode = {
          isValid: false,
          message: "Please enter postal Code"
        };
        isValid = false;
      }
      if (data.country === " ") {
        retData.country = {
          isValid: false,
          message: "Please enter country space is not allowed"
        };
        isValid = false;
      }
      if (!data.country) {
        retData.country = {
          isValid: false,
          message: "Please enter country"
        };
        isValid = false;
      }
      if (data.users === " ") {
        retData.users = {
          isValid: false,
          message: "Please enter number "
        };
        isValid = false;
      }
      if (!data.users) {
        retData.users = {
          isValid: false,
          message: "Please enter number"
        };
        isValid = false;
      }
    }
    
    return {
      ...retData,
      isValid
    };
  };

  const errors = validate(submitted);

  return (
    <div>

      <CardContent>
        <Card >

          {/* <Accordion className={classes.cardstyles} expanded={shouldopen} onChange={onChangeEvents}> */}
          <Typography className={classes.security} style={{ padding: 15 }}>Security</Typography>
          <Divider style={{marginTop:-8}}/>
          <CardContent style={{marginTop:-10}}>
            <Grid container spacing={6}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.marginTop10}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}> Email</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      name="email"
                      onChange={onContentChangeEvent}
                      placeholder="Enter email"
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.email.isValid}
                    />
                    {
                      !errors.email.isValid &&
                      <p className={classes.errorMessage}>{errors.email.message}</p>
                    }

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.formHeading}> Password</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      name="password"
                      // autoComplete='new-password'
                      type={showPw ? "text" : "password"}
                      onChange={onContentChangeEvent}
                      placeholder="Enter Password"
                      value={data.password}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer" }} /> :
                              <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer" }} />}
                          </InputAdornment>

                        ),
                        autoComplete: 'new-password',
                      }}
                      error={!errors.password.isValid}
                    />
                    {
                      !errors.password.isValid &&
                      <p className={classes.errorMessage}>{errors.password.message}</p>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* </Accordion> */}
          </CardContent>
        </Card>



        <Card style={{ marginTop: 20 }}>
          <Typography className={classes.security} style={{ padding: 15 }}>Personal Information</Typography>
          <Divider style={{marginTop:-8}}/>
          <CardContent style={{marginTop:-20}}>
            <Grid container spacing={6} style={{ marginTop: -18 }}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}></Grid>

                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>First Name</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small"
                      name="firstName"
                      value={data.firstName}
                      onChange={onContentChangeEvent}
                      placeholder="Enter First Name"
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.firstName.isValid}
                    />
                    {
                      !errors.firstName.isValid &&
                      <p className={classes.errorMessage}>{errors.firstName.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>Company Name</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small"
                      name="companyName"
                      value={data.companyName}
                      onChange={onContentChangeEvent}
                      placeholder="Enter company Name"
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.companyName.isValid}
                    />
                    {
                      !errors.companyName.isValid &&
                      <p className={classes.errorMessage}>{errors.companyName.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.formHeading}>Contact Name</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter Contact Name"
                      name="contactName"
                      value={data.contactName}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.contactName.isValid}
                    />
                    {
                      !errors.contactName.isValid &&
                      <p className={classes.errorMessage}>{errors.contactName.message}</p>
                    }
                  </Grid>
                </Grid>
                                
               
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.formHeading}>Address</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <PlacesAutocomplete
                      value={data.address ? data.address : ""}
                      onChange={handleChange}
                      onSelect={handleSelect}

                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                        <div>
                          {/* <input
                                            style={{
                                            width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                                            borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                                            border: "1px solid #3f51b5"
                                            }}
                                            {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                            })}
                                        /> */}
                          <TextField
                            style={{ marginTop: '12px' }}
                            fullWidth
                            variant="outlined"
                            size="small"
                            error={!errors.address.isValid}
                            {...getInputProps({
                              placeholder: 'Search address',
                              className: 'location-search-input',
                            })}
                          />
                          {
                            !errors.address.isValid &&
                            <p className={classes.errorMessage}>{errors.address.message}</p>
                          }
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion: any) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>Province</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter Province"
                      name="province"
                      value={data.province}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.province.isValid}
                    />
                    {
                      !errors.province.isValid &&
                      <p className={classes.errorMessage}>{errors.province.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>Zipcode</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter Zipcode"
                      name="zipcode"
                      value={data.zipcode}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.zipcode.isValid}
                    />
                    {
                      !errors.zipcode.isValid &&
                      <p className={classes.errorMessage}>{errors.zipcode.message}</p>
                    }
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.formHeading}>Last Name</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter Last Name"
                      name="lastName"
                      value={data.lastName}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.lastName.isValid}
                    />
                    {
                      !errors.lastName.isValid &&
                      <p className={classes.errorMessage}>{errors.lastName.message}</p>
                    }
                  </Grid>
                </Grid>
                 <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>GST Number(optional)</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small"
                      name="gstNumber"
                      value={data.gstNumber}
                      onChange={onContentChangeEvent}
                      placeholder="Enter GST Number"
                      variant="outlined" fullWidth className={classes.formHeading1}
                      // error={!errors.firstName.isValid}
                    />
                    {/* {
                      !errors.firstName.isValid &&
                      <p className={classes.errorMessage}>{errors.firstName.message}</p>
                    } */}
                  </Grid>
                </Grid>
                 <Grid container className={classes.inputFields} style={{marginTop:70}}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    {/* <Typography className={classes.formHeading}>GST Number(optional)</Typography> */}
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    {/* <TextField size="small"
                      name="gstNumber"
                      value={data.gstNumber}
                      onChange={onContentChangeEvent}
                      placeholder="Enter GST Number"
                      variant="outlined" fullWidth className={classes.formHeading1}
                      // error={!errors.firstName.isValid}
                    /> */}
                    {/* {
                      !errors.firstName.isValid &&
                      <p className={classes.errorMessage}>{errors.firstName.message}</p>
                    } */}
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>City</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter City"
                      name="city"
                      value={data.city}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.city.isValid}
                    />
                    {
                      !errors.city.isValid &&
                      <p className={classes.errorMessage}>{errors.city.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.formHeading}>Country</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter Country name"
                      name="country"
                      value={data.country}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth className={classes.formHeading1}
                      error={!errors.country.isValid}
                    />
                    {
                      !errors.country.isValid &&
                      <p className={classes.errorMessage}>{errors.country.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.formHeading}>Status</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <Select placeholder="Enable"
                      options={Status}
                      name="status"
                      value={
                        Status.filter((option: any) =>
                          option.value === data.status)
                      }
                      onChange={(e: any) => handleChangeblocks(e, "status")}
                      className={classes.formHeading1}
                      error={!errors.status.isValid}
                    />
                    {
                      !errors.status.isValid &&
                      <p className={classes.errorMessage}>{errors.status.message}</p>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ marginTop: 20 }}>
          <Typography className={classes.security} style={{ padding: 15 }}>Billing</Typography>
          <Divider style={{marginTop:-8}}/>
          <CardContent style={{marginTop:-20}}>
            <Grid container spacing={6} style={{ marginTop: -18 }}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}></Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12} >
                    <Typography className={classes.formHeading}>Total Numbers </Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField size="small" placeholder="Enter number count "
                      name="users"
                      value={data.users}
                      onChange={onContentChangeEvent}
                      variant="outlined" fullWidth 
                      className={classes.formHeading1}
                      error={!errors.users.isValid}
                    />
                    {
                      !errors.users.isValid &&
                      <p className={classes.errorMessage}>{errors.users.message}</p>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid item md={12} style={{ marginTop: "35px", textAlign: 'center' }}>
          <Button
            size="small"
            variant="outlined"
            className={classes.searchButton}
            onClick={onSubmitdata}
          >
            Add
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={classes.backButton}
            onClick={() => history.push("/businessAccounts")}

          >
            Back
          </Button>

        </Grid>

      </CardContent>

    </div >
  )
}
export default Form

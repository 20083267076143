import { Button, Card, Grid, TextField, Typography, Tabs, Tab } from '@material-ui/core'
import React from 'react'
import { TabContext, TabPanel } from '@material-ui/lab';
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import { BiMessageSquareAdd } from 'react-icons/bi'
import axiosInstance from '../../../../environment/axiosInstance'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import Fileupload from './Fileupload';
import AddNumber from './AddNumberModal';
import { useStyles } from './styles';
import RecordAudio from './RecordAudio';

function OpenHoursPhoneNumber(props: any) {
    const classes = useStyles()
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [value, setValue] = React.useState("2");
    const [numList, setNumList] = React.useState<any>([])
    const [addNumberModal, setAddNumberModal] = React.useState(false);
    const handlePopupModalNumber = () => {
        setAddNumberModal(!addNumberModal)
    }
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };


    const newList: any = []
    numList?.map((item: any) => {
        const obj: any = {
            value: item._id,
            label: item.phoneNumber,
            isSelected: item.isSelected
        }
        newList.push(obj)
        // if(item.isSelected === true){
        //     setselectedNum(item)
        // }
    })



    const selectedNum = numList?.findIndex((option: any) => {

        if (option?.isSelected === true) {
            return option
        }
    })

    const fetchPhoneNumbers = () => {
        axiosInstance.get(`/profile/settings/open-hours`).then((res: any) => {
            console.log(res, "res")
            if (res.status === 200) {
                // console.log(res.data.data.voicemail.voicemailAudio)
                setNumList(res.data.data.forwardNumbers.phoneNumbers)
                // const data = res.data.data.ForwardNumbers

            }
        })
    }

    const onChangePhoneNumberDropDown = (e: any) => {
        // console.log("e", e)
        const id = e.value
        const data = {
            forwardNumberId: id
        }
        axiosInstance.patch(`/profile/settings/open-hours/forward-number`, data).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                fetchPhoneNumbers()
            }

        })

    }

    React.useEffect(() => {
        fetchPhoneNumbers()
    }, [])
    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title='Edit ForwardNumbers' handlePopupModal={handlePopupModalAdd} >
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            getOpenHours={props.getOpenHours}
                                            openHours={props.openHours}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/* <p>File Upload</p> */}
                                        <Fileupload
                                            getOpenHours={props.getOpenHours}
                                            openHours={props.openHours}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Modal>
                    </div>
                )
            }

            {
                addNumberModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalNumber} >
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <AddNumber fetchPhoneNumbers={fetchPhoneNumbers} handlePopupModal={handlePopupModalNumber} />
                            </div>
                        </Modal>
                    </div>
                )
            }

            <div  className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 320 }}>
                            <ReactSelect
                                // placeholder="select phone number"
                                options={newList}
                                value={newList[selectedNum]}
                                onChange={onChangePhoneNumberDropDown}
                            />
                        </div>
                        <BiMessageSquareAdd className={classes.deviceWidth} onClick={handlePopupModalNumber} />
                    </div>

                    <Grid container spacing={3} style={{marginTop:'30px'}}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ReactAudioPlayer
                                controls
                                className={classes.audioPlayer}
                                // onLoadedMetadata={handleLoadMetadata}
                                src={props.frwPath}
                            />
                            <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                {/* <p className={classes.filename}>Filename.mp3</p> */}
                                <span className={classes.filename}>{props.frwFileName}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>Edit</Button>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </>

    )
}

export default OpenHoursPhoneNumber
import React from 'react'
import {
    Card,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Typography,
    Grid,
    TextField,
    Button,
    IconButton,
} from '@material-ui/core';
import { Block, Search } from '@material-ui/icons';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import EditIcon from '../../../../assets/icons/editIcon.png';
import AddIcon from '../../../../assets/icons/addIcon.png';
import { BiMessageSquareAdd } from 'react-icons/bi'
import { Multiselect } from "multiselect-react-dropdown";
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';

import axiosInstance from '../../../../environment/axiosInstance'
import userServices from '../userServices';
import pencil from '../../../../assets/images/Pencil.png'
import userNumbersServices from '../../PhoneNumbers/userNumbersServices/usersNumbersServices';
import AddEfoneNumber from '../AllNumbers/AddNumber/AddEfoneNumber';

import { useStyles } from './styles';

const formGroup: any = [
    {
        field: 'email',
        valid: true,
        message: ''
    },
]

const options =
    [
        {
            value: 1,
            key: 'Admin'
        },
        {
            value: 2,
            key: 'Billing'
        },
        {
            value: 3,
            key: 'Analytics'
        },
        {
            value: 4,
            key: 'Recordings'
        },

    ]


function AddUser(props: any) {
    const classes = useStyles();
    const [searchEmail, setSearchEmail] = React.useState('')
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [submit, setSubmit] = React.useState(false)
    const [showEditPhoneNumber, setShowEditPhoneNumber] = React.useState(false);
    const [showEditDesc, setShoweditDes] = React.useState(false)
    const [avaliableData, setAvaliableData] = React.useState({
        email: '',
        phoneNumber: '',
        description: '',
        // userType: null,
        permissions: [],
        departmentIds: [],
    })
    const [loading, setLoading] = React.useState<any>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [permissions, setPermissions] = React.useState<any>([]);
    const [deptList, setDeptList] = React.useState<any>([]);
    const [selectedDeptList, setSelectedDeptList] = React.useState<any>([]);
    const [openModalAdduser, setopenModalAdduser] = React.useState(false);
    const [numberListData, setNumberListData] = React.useState<any>([]);
    const [selectedNumber, setSelectedNumber] = React.useState<any>();

    const postSearchEmail = () => {
       
        const errorData = validate(true);
        if (errorData.isValid) {
            setLoading(true)
            userServices.searchEmail({ email: searchEmail }).then((res) => {
                // console.log(res)
                if (res.success) {
                    // setSubmit(true)
                    setLoading(false)
                    setSubmitted(true);
                    const { email, phoneNumber, desciption } = res.data;
                    setAvaliableData((prev) => {
                        return {
                            ...prev,
                            email: email,
                            phoneNumber: phoneNumber,
                            description: desciption
                        }
                    })
                }
                else if (res.success === false) {
                    setSubmitted(false);
                    
                }
            }).catch((err) => {
            })
        }
    }

    const createUser = (data: any) => {
        let dataToUpdate = data;
        // dataToUpdate.userType = 3;
        dataToUpdate.permissions = permissions
        dataToUpdate.departmentIds = selectedDeptList
        console.log("user create_____", dataToUpdate)
        setLoading(true)
        userServices.CreateUserList(dataToUpdate).then((res) => {
            if (res.success) {
                props.fetchUserLists()
                props.handlePopupModal()
                setLoading(false)
                setSubmit(false)
            } else {
                setLoading(false)
                setSubmit(false)
            }
        }).catch((err) => {
            setLoading(false)
            setSubmit(false)
        })
    }

    const validateEmail = (email: any) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            email: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (!searchEmail) {
                retData.email = {
                    isValid: false,
                    message: "Please enter email address"
                };
                isValid = false;
            }
            if (searchEmail && !validateEmail(searchEmail)) {
                retData.email = {
                    isValid: false,
                    message: "Please enter valid address"
                };
                isValid = false;
            }
        }
        return {
            ...retData,
            isValid
        };
    };


    const onChangeEmail = (e: any) => {
        setSearchEmail(e.target.value)
    }

    const onChangeData = (e: any) => {
        setAvaliableData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChaneSelect = (e: any) => {
        // console.log(e)
        const arr: any = []
        e.map((item: any) => {
            arr.push(item.value)
        })
        setPermissions(arr)
    }

    const onChangeDeptSelect = (e: any) => {
        // console.log(e)
        const arr: any = []
        e.map((item: any) => {
            arr.push(item.value)
        })
        setSelectedDeptList(arr)
    }

    const getDepartmentList = () => {
        axiosInstance.get('/departments').then((res: any) => {
            if (res.data.data) {
                const list = res.data.data
                const arr: any = []
                list.map((item: any) => {
                    const obj: any = {
                        value: item.id,
                        key: item.name,
                    }
                    arr.push(obj)
                })

                setDeptList(arr)
                // console.log("list", arr)
            }
        }).catch((err: any) => {

        })
    }

    const fetchNumberLists = () => {
        userNumbersServices.getNumbersList().then((res: any) => {
            if (res.success) {
                setNumberListData(res?.data)
            } else {

            }
        }).catch((err: any) => {

        })
    }

    React.useEffect(() => {
        getDepartmentList()
    }, [])

    const handlePopupModalAddNumber = (num: any) => {
        setopenModalAdduser(!openModalAdduser)
        console.log("handlePopupModalAddNumber", num)
        let dataToUpdate = avaliableData;
        if (num !== '') {
            dataToUpdate.phoneNumber = `${num}`
        }
        // setSelectedNumber(data)
       
    }

    const errors = validate(submitted);


    return (
        <div>
            {
                openModalAdduser && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAddNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalAddNumber} >
                            <AddEfoneNumber
                                fetchNumberLists={fetchNumberLists}
                                handlePopupModal={handlePopupModalAddNumber}
                            />
                        </Modal>
                    </div>
                )
            }

            <CardContent>
                <Card className={classes.modalCard}>
                    <CardContent>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className={classes.userText}>
                                Email to add new users to office
                            </Typography>
                            <Grid container spacing={2} className={classes.gridItem}>
                                <Grid item md={8} sm={8} className={classes.gridItem}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Enter email to add user"
                                        variant="outlined"
                                        value={searchEmail}
                                        onChange={onChangeEmail}
                                        error={!errors.email.isValid}
                                        InputProps={{
                                            startAdornment: (
                                                <IconButton className={classes.search}>
                                                    <Search />
                                                </IconButton>
                                            ),
                                        }}
                                        className={classes.searchfilter}
                                       
                                    />
                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        className={classes.submitButton}
                                        onClick={postSearchEmail}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {
                                    !errors.email.isValid &&
                                    <p className={classes.errorMessage}>{errors.email.message}</p>
                                }
                            </Grid>
                        </CardContent>
                        {
                            loading ?
                                <Box>
                                    <div style={{ textAlign: "center", marginBottom: "15px",marginTop:55 }}>
                                        <CircularProgress />
                                    </div>
                                </Box>
                                :
                                <>
                                     <TableContainer className={classes.adduserTableContainer} >
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.tableCellRow}>Name</TableCell>
                                                        <TableCell className={classes.tableCellRow}>Permission</TableCell>
                                                        <TableCell className={classes.tableCellRow}>Department</TableCell>
                                                        <TableCell className={classes.tableCellRow}>Assigned Number</TableCell>
                                                        <TableCell className={classes.tableCellRow}>Description</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {
                                                    submitted &&
                                                    (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className={classes.modalTableCellBody}>{avaliableData?.email}</TableCell>
                                                                <TableCell className={classes.modalTableCellBody}>
                                                                    <Multiselect
                                                                        options={options}
                                                                        showCheckbox
                                                                        displayValue="key"
                                                                        onSelect={onChaneSelect}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.modalTableCellBody}>
                                                                    <Multiselect
                                                                        options={deptList}
                                                                        showCheckbox
                                                                        displayValue="key"
                                                                        placeholder='Select Department'
                                                                        onSelect={onChangeDeptSelect}
                                                                    />
                                                                </TableCell>
                                                                <TableCell className={classes.modalTableCellBody}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                        <span style={{ marginRight: 10 }}>{avaliableData.phoneNumber}</span>
                                                                        <img src={pencil} alt='edit' style={{ width: 16, height: 16 }}
                                                                            onClick={() => handlePopupModalAddNumber('')} />
                                                                    </div>
                                                                </TableCell>
                                                                {/* <TableCell className={classes.modalTableCellBody}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                <span style={{ marginRight: 5 }}>{avaliableData.description}</span>
                                                                <img src={pencil} alt='edit' />
                                                            </div>
                                                        </TableCell> */}
                                                                <TableCell className={classes.modalTableCellBody}>
                                                                    {
                                                                        avaliableData?.description !== '' && (
                                                                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                                                                {
                                                                                    showEditDesc ? (
                                                                                        <div style={{ width: '150px' }}>
                                                                                            <TextField
                                                                                                fullWidth
                                                                                                size="small"
                                                                                                placeholder="Description"
                                                                                                variant="outlined"
                                                                                                name="description"
                                                                                                value={avaliableData?.description}
                                                                                                onChange={onChangeData}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <Typography className={classes.numberText}>{avaliableData?.description}</Typography>
                                                                                    )
                                                                                }
                                                                                <img
                                                                                    className={classes.editIcon}
                                                                                    src={pencil}
                                                                                    alt="edit"
                                                                                    onClick={() => setShoweditDes(!showEditDesc)}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )
                                                }

                                            </Table>
                                        </TableContainer>

                                    <CardContent>
                                        <div className={classes.btnRight}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                className={classes.cancelButton}
                                                onClick={() => props.handlePopupModal()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="small"
                                                className={classes.addButton}
                                                onClick={() => createUser(avaliableData)}
                                            >Add
                                            </Button>

                                        </div>
                                    </CardContent>
                                </>

                        }
                    </CardContent>
                </Card>
            </CardContent>
        </div>
    )
}
export default AddUser
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
     card: {
        margin: '15px 0px 0px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
    },
    heading: {
        color: "#2645AD",
        fontSize: 18,
        fontWeight: 600,
        fontFamily: "Work Sans",
        fontStyle: "normal",
        "@media (max-width: 426px)": {
            fontSize: '16px'
        }
    },
     divider: {
        marginTop: -10,
        backgroundColor: '#E2E2E2',
    },
    searchText: {
        color: "#464646",
        fontWeight: 500,
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontSize: 20,
        marginLeft: 16,
        marginTop:7
    },
    accordianNoFlex: {
        display: 'block'
    },
    dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 },
    accountHeading: {
        color: "#464646",
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 20,
        "@media (max-width: 426px)": {
            fontSize: '14px'
        }
    },
     addbutton: {
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "5px 18px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
            "@media (max-width: 426px)": {
                padding: '4px 15px',
                fontSize: 12,
            }
        },
        tablecolor: {
            backgroundColor: "#fbfbfb",
        },
        tableHead: {
            fontSize: "14px",
            color: "#25282B",
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Work Sans",
        },
        tableBody: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.8rem",
            color: "#787878",
        },
        phoneNum: {
            color:'#464646',
            fontWeight:500,
        },
        text: {
            marginBottom: 10,
            color: '#464646',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
        },
        addButton: {
            padding: '5px 15px',
            background:'#4D72F8',
            color:'#fff'
        },
        cancelButton:{
            padding: '5px 15px',
            background:'#EFEFEF',
            border: '1px solid #AEAEAE'
        },
                searchButton: {
            // marginTop: 20,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "4px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
        },
        clearButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            color: "#FFFFFF",
            border: "0.791301px solid #8392AB",
            background: "#B70909",
            padding: "4px 20px",
            borderRadius: "2px",
            marginLeft: 15,
            '&:hover': {
                background: "#B70909",
                color: "#FFFFFF",
            }
            // marginTop: 20,
        },
         tableCellBody: {
            //padding: 10,
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontWeight: 500,
            color: '#939292',
        },
        deviceWidth: {
            width: 30,
            height: 30,
            borderRadius: 50,
            color: 'black',
            textAlign: "center",
            // backgroundColor: '#F3F5FF',
            // fill:'#4D72F8',
            padding: '8px',
        },
        deleteButton: {
            margin: 10,
            background: '#4D72F8',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            padding: '6px 22px',
            color: '#FFFFFF',
            fontSize: 14,
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            }
        },
        CancelButton: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            borderRadius: 5,
            color: '#8392AB',
            fontSize: 14,
            padding: '5px 18px',
            textTransform: "capitalize",

        },
        purchaseButton: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
            background: '#00B807',
            border: '1px solid #AEAEAE',
            color: '#fff',
             fontSize: 16,
            padding: "5px 18px",
            borderRadius: "2px",
            textTransform: "capitalize",
            '&:hover': {
                background: '#00B807',
                color: '#FFFFFF',
            }

        },
        portButton: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
            background: '#A37500',
            border: '1px solid #AEAEAE',
            borderRadius: 5,
            color: '#fff',
            fontSize: 14,
            padding: '3px 15px',
            textTransform: "capitalize",
        },
        tablecell: {

        },
        accordion: {
            background: "#FFFFFF",
            borderRadius: "7.51286px",
            boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
        },
        heading1: {
            fontSize: 18,
            textAlign: "center",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            color: "#3A416F"
        },
        availableNumbers: {
            color: "#3A416F",
            fontWeight: 500,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: 20,
        },
        yesButton: {
            backgroundColor: "#219653",
            borderRadius: 5,
            color: "#FFFFFF",
            fontSize: 12,
            textTransform: "capitalize",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
        },
        noButton: {
            backgroundColor: "#EB5757",
            borderRadius: 5,
            color: "#FFFFFF",
            fontSize: 12,
            textTransform: "capitalize",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            marginLeft: 20
        },
        heading2: {
            fontSize: 13,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textAlign: "center",
            color: "#8392AB",
            backgroundColor: "#F5F5F5",
            border: "1px solid #E7E7E7",
            boxSizing: "border-box",
            borderRadius: 4,
        },
          AssignButton: {
            margin: 10,
            background: '#4D72F8',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 4,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            padding: '6px 22px',
            color: '#FFFFFF',
            fontSize: 14,
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            }
        },
        CancelAssignButton: {
            padding: '6px 22px',
            margin: 10,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 4,
            color: '#787878',
            fontSize: 14,
        },
}))
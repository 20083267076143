import React from 'react'
import {
    Card,
    CardContent,
    Divider,
    TextField,
    Typography,
    Accordion,
    AccordionSummary, AccordionDetails,
    Grid,
    Button
} from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useStyles } from './style'
import {
    Table, TableHead, TableCell, TableRow,
    TableBody,
    TableContainer,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from '../../../environment/axiosInstance'
import Box from '@mui/material/Box';
import ReactSelect from 'react-select'

function EditPhoneNumber(props:any) {
    const classes = useStyles()
    const history = useHistory()
    const anchorRef: any = React.useRef(null);
    const [open, setOpen] = React.useState(true);
    const[usersList,setUsersList] =React.useState([]);
    const [update, setUpdate] = React.useState(false)
     const [AddPhoneNumberData, setAddphoneNumberData] = React.useState({
        phoneNumber: "",
        assignedTo: "",
        status: "1"
    })
    const [loadOptions] = React.useState<any>([
        { value: '1', label: "Active" },
        { value: '2', label: "InActive" }
    ]) 
    const handleOpen = () => {
        setOpen(!open)
    }

    const updatePhoneNumbes = () => {
        axiosInstance.patch(`/admin/phone-numbers/${props.match.params.id}`,AddPhoneNumberData).then((res:any)=>{
            if(res.data){
                history.push("/phone-numbers")
            }
        })
    }
    //  React.useEffect(()=>{
    // },[props.match.params.id])

    React.useEffect(()=>{
        getUesr()
    },[])

    React.useEffect(() => {
    if (props.match.params.id) {
      setUpdate(true)
      axiosInstance.get(`/admin/phone-numbers/${props.match.params.id}`).then((res) => {
        if (res.data.data) {
          const data = res.data.data
          setAddphoneNumberData({
            phoneNumber: data.phone,
            status: data.status,
            assignedTo: data.parentId
          })
        }
      })
    }
  }, [props.match.params.id])

    // const phoneNumberDetails = (id: any) => {
    //     axiosInstance.get(`admin/phone-numbers/{id}`)
    // }
    

    const getUesr = () => {
        axiosInstance.get('/admin/business-accounts').then((res:any) =>{
            if(res.data){
                const list : any = []
                res.data.data.map((item:any, i:any)=> {
                    var obj = {
                        value : item.id,
                        label : item.firstName + ' ' + item.lastName
                    }
                    list.push(obj)
                })
                setUsersList(list)
            }
        })
    }

    const onContentChangeEvent = (e: any) => {
    setAddphoneNumberData((prev) => {
      return {
        ...AddPhoneNumberData,
        [e.target.name]: e.target.value,
      }
    })
  }

    const onContentStatusChangeEvent = (e: any) => {
        setAddphoneNumberData((prev: any) => {
            return {
                ...AddPhoneNumberData,
                status: e.value,
            }
        })
    }

     const onContentAsignChangeEvent = (e:any) => {
         setAddphoneNumberData((prev: any) => {
            return {
                ...AddPhoneNumberData,
                assignedTo: e.value,
            }
        })
     }

    //console.log('idddddddd____',props.match.params.id)

  return (
    <div>
        <Card className={classes.card}>
        <Accordion expanded={open}
            onChange={handleOpen}>
            <AccordionSummary
                expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>
                    Update Phone Number
                </Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
                {/* <Card  style={{ marginTop: '20px',padding:10 }}> */}
                    <Grid container style={{padding:'25px 20px'}}>
                        <Grid item md={4}></Grid>
                        <Grid item md={4}>
                            <div>
                                <Typography className={classes.phoneNum}>Phone Number</Typography>
                                <TextField 
                                    size="small"
                                    placeholder='Phone Number'
                                    variant='outlined'
                                    fullWidth
                                    style={{marginTop:8}}
                                    name="phoneNumber"
                                    value={AddPhoneNumberData.phoneNumber}
                                    onChange={onContentChangeEvent}
                                />
                            </div>
                            <div>
                                <Typography style={{marginTop:8,marginBottom:8,color:'#464646',fontWeight:500,}}>Assigned to</Typography>
                                <ReactSelect 
                                    size="small"
                                    placeholder="Assigned to"
                                    options={usersList}
                                    onChange={onContentAsignChangeEvent}
                                    value={
                                            usersList.filter((option: any) =>
                                            option.value === AddPhoneNumberData.assignedTo)
                                        }
                                />
                            </div>
                            <div>
                                <Typography style={{marginTop:8,marginBottom:8,color:'#464646',fontWeight:500,}}>Status</Typography>
                                <ReactSelect
                                    placeholder="Slect status"
                                    size="small"
                                    options={loadOptions}
                                    onChange={onContentStatusChangeEvent}
                                    name="status"
                                    value={
                                        loadOptions.filter((option: any) =>
                                            option.value == AddPhoneNumberData.status)
                                    }
                                />
                            </div>
                            <div style={{display:'flex',justifyContent:'center',gap:10,marginTop:15}}>
                                <Button
                                    className={classes.addButton}
                                    variant='outlined'
                                    size='small'
                                    onClick={updatePhoneNumbes}
                                >Update
                                </Button>
                                <Button
                                    className={classes.cancelButton}
                                    variant='outlined'
                                    size='small'
                                     onClick={() => history.push('/phone-numbers')}
                                >Cancel
                                </Button>
                            </div>
                        </Grid>
                        <Grid item md={4}></Grid>
                        
                    </Grid>
                {/* </Card> */}
            </AccordionDetails>
        </Accordion>
    </Card>
    </div>
  )
}

export default EditPhoneNumber
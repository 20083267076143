// import { useHistory } from 'react-router-dom'
// const history = useHistory()

class localStorageData {
    setLocalUserData(userData: any) {
        localStorage.setItem('user_data', JSON.stringify(userData))
        
    }
    getLocalUserData() {
        const localUser: any = localStorage.getItem('user_data')
        const parseUserData = JSON.parse(localUser);
        return parseUserData;
    }
}

const localStorageContent = new localStorageData()

export default localStorageContent;
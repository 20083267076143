import { initProfileData, userHoursAction, userDepartmentsAction } from './Actions'


export const initUserProfile = () => {
    return {
        type: initProfileData.INIT_PROFILE_DATA
    }
}

export const userProfileDataSuccess = (profileData: any) => {
    return {
        type: initProfileData.INIT_PROFILE_DATA_SUCCESS,
        data: profileData
    }
}

export const profileDataLoading = (status: any) => {
    return {
        type: initProfileData.PROFILE_DATA_LOADING,
        status: status
    }
}

export const initOpenhour = () => {
    return {
        type: userHoursAction.INIT_OPEN_HOURS
    }
}

export const initOpenhourSuccess = (openhourdata: any) => {
    return {
        type: userHoursAction.OPEN_HOURS_SUCCESS,
        openhourdata: openhourdata
    }
}


export const initClosedhour = () => {
    return {
        type: userHoursAction.INIT_CLOSED_HOURS
    }
}

export const initClosedhourSuccess = (closedhourdata: any) => {
    return {
        type: userHoursAction.CLOSED_HOURS_SUCCESS,
        closedhourdata: closedhourdata
    }
}

export const initUnanswred = () => {
    return {
        type: userHoursAction.INIT_UNANSWRED_HOURS
    }
}

export const initunansweredhourSuccess = (unansweredhourdata: any) => {
    return {
        type: userHoursAction.UNANSWRED_HOURS_SUCCESS,
        unansweredhourdata: unansweredhourdata
    }
}

export const getUserDepartments = (userId: string) => {
    return {
        type: userDepartmentsAction.USER_DEPARTMENT,
        payload: userId
    }
}

export const getUserDepartmentsRequest = (status: any) => {
    return {
        type: userDepartmentsAction.REQUEST,
        status: status
    }
}

export const getUserDepartmentsSuccess = (userDepartmentData: any) => {
    return {
        type: userDepartmentsAction.SUCCESS,
        data: userDepartmentData
    }
}
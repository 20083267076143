import { initProfileData, userHoursAction, userDepartmentsAction } from '../Actions/Actions'

const userProfileData =  {
    profileData: null,
    loading: false
}

export const userProfileDataFun = (state = userProfileData, action: any) => {
    switch (action.type) {
        case initProfileData.INIT_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                profileData: action.data
            } 
        case initProfileData.PROFILE_DATA_LOADING:
            return {
                ...state,
                loading: action.status
            }
        default:
            return state
    }
}


const hoursData = {
    openHours: null,
    closedHours: null,
    unanswered: null
}

export const userHoursData = (state = hoursData, action: any) => {
    switch (action.type) {
        case userHoursAction.OPEN_HOURS_SUCCESS:
            return {
                ...state,
                openHours: action.openhourdata
            }
        case userHoursAction.CLOSED_HOURS_SUCCESS:
            return {
                ...state,
                closedHours: action.closedhourdata
            }
        case userHoursAction.UNANSWRED_HOURS_SUCCESS:
            return {
                ...state,
                unanswered: action.unansweredhourdata
            }
        default:
            return state
    }
}

export const userDepartment = (state = {}, action: any) => {
    switch (action.type) { 
        case userDepartmentsAction.REQUEST:
            return {
                ...state,
                loading: action.status
            }
        case userDepartmentsAction.SUCCESS:
            return {
                ...state,
                userDepartmentData: action.data,
                loading: false
            }
        default:
            return state
    }
}
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '20px 0px 20px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
  },
  divider: {
    margin: 5,
    backgroundColor: '#E2E2E2',
    
  },
  space: {
    marginBottom: 10,
    color: '#8392AB',
    fontSize: 18,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  tableCell: {
    color: '#4D72F8',
  },
  addIcon: {},
  plusBtton: {
    textAlign: 'center',
    marginRight: 0,
    background: '#787878',
    color: '#787878',
  },
  tableContainer: {
    width: '100%',
    boxShadow: '0 0 0em #E2E2E2',
    borderRadius: 0,
    borderColor: '#E2E2E2',
  },
  tableHead: {
    background: '#fbfbfb',
  },
  tableCellRow: {
    padding: 10,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#464646',
  },
  tableCellBody: {
    padding: 10,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#939292',
  },
  tableCellBody1: {
    padding: 10,
    fontSize: 14,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.21553,
    textAlign: 'right',
    color: '#4D72F8',
  },
}));

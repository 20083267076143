import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modalCard: {
    margin: '0px 0px 0px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0 0',
  },
  userText: {
    marginBottom: '10px',
    fontSize: 18,
    fontFamily: 'Work Sans',
    fontStyle: 'Regular',
    fontWeight: 500,
    color: '#848484',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  selectField: {
    marginTop: 10
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    // width: '-webkit-fill-available',
    justifyContent: 'flex-start',
  },
  dropdownContainer: {
    width: 280, height: 45,
    "@media (max-width: 426px)": {
      width: 210, height: 25
    }
  },
  deptSelectDropdown:{
     width: 280, height: 38,
     "@media (max-width: 426px)": {
      width: 210, height: 25
    }
  },
  addDeptBtn: {
    width: 100,
    height: 38,
    background: '#4D72F8',
    '&:hover': {
      backgroundColor: '#4D72F8',
    },
    '&:active': {
      backgroundColor: '#4D72F8',
    },
    border: '1px solid #4D72F8',
    borderRadius: '4px',
    textTransform: 'none',
    color: '#ffffff',
    marginLeft: '10px',
    "@media (max-width: 426px)": {
      width: 70, height: 35,
      marginLeft: 10,marginTop:13,
      fontSize:12
    }
  },
  submitButton: {
    height: 35,
    borderRadius: 8,
    marginTop: 10,
    transform: 'translate(-4px, 0px)',
    color: '#FFFFFF',
    backgroundColor: '#4D72F8',
    borderColor: '#4D72F8',
    padding: '6px 22px',
    '&:hover': {
      backgroundColor: '#4D72F8',
    },
    '&:active': {
      backgroundColor: '#4D72F8',
    },
  },
}))
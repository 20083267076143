import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
    heading: {
        color: '#4D72F8',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: "Calibri"
    },
    padding: {
        padding: 15,
    },
    divider: {
        color: '#C2C2C2',
        marginTop: '-14'
    },
    accordianNoFlex: {
        display: 'block'
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: 'white',
        fontFamily: 'Calibri',
        float: 'right',
        fontSize: 14,
        padding: '6px 20px',
        background: '#4D72F8',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#4D72F8',
            color: 'white'
        },
        marginRight:15,
    },
    usersTable: {
        marginTop: 25,
        border: '1px solid rgba(194, 194, 194, 0.47)',
        borderRadius: 5,
    },
    tableHead: {
        fontFamily: 'Work Sans',
        color: 'rgba(0, 0, 0, 0.75)',
        fontWeight: 600,
        fontSize: 16,
    },
    tableBody: {
        fontFamily: 'Arial',
        color: '#929292',
        fontWeight: 500,
        fontSize: 14
    },
    assignBtn: {
        color: '#4D72F8',
        textTransform: 'capitalize',
        padding: '3px 15px'

    },
    actionButton: {
        borderWidth: 1,
        minWidth: 0,
        padding: '2px 20px',
    },
    adminIcon: {
        width: 25,
        height: 25,
        color: '#464646',
    },
    delete: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
    },
    textColor: {
        color: '#464646',
        marginLeft: 10,
        fontWeight: 'bold',
    },
    pText: {
        textAlign: 'center', color: "#787878", fontWeight: 500, padding: '10px 2px'
    },

}))
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: '17px 0px 17px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  userText: {
    fontSize: 18,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#2645ad',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    border: '1px solid lightgrey',
    borderRadius: '50px',
    boxSizing: 'border-box',
    padding: '6px 22px',
  },
  toggleText: {
    fontSize: 14,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#2645ad',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  width: {
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: '#f4f4f4',
  },
  tableHead1: {
    backgroundColor: '#fbfbfb',
  },
  tableContainer: {
    boxShadow: '0px 0px 1px',
    borderRadius: 0,
    borderColor: '#E2E2E2',
  },
  tableCell: {
    padding: 10,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0.217535,
    color: '#545454',
    borderRight: '1px solid lightgrey',
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px',
      fontWeight: 600
    }
  },
  tableCellRow: {
    padding: 10,
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0.217535,
    color: '#4D72F8',
    cursor: 'pointer'
  },
  tableCellBody: {
    padding: 10,
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.21553,
    textAlign: 'center',
    color: '#939292',
  },
  divider: {
    position: 'absolute',
    height: 90,
    top: 2463,
    left: 223,
  },
  divider1: {
    position: 'absolute',
    height: 90,
    top: 2463,
    left: 660,
  },
  cell: {
    position: 'sticky',
    left: 32,
  },
  addIcon: {
    marginLeft: 10,
    cursor:"pointer"
  },
}))
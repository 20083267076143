import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";

import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
// import { GrFormNextLink } from "react-icons/gr";
// import { BiRefresh } from 'react-icons/bi'
import { useStyles } from "../styles";
// import ProfileServices from "../../../../Profile/ProfileServices/ProfileServices";
import ProfileServices from "../../../../Profile/ProfileServices/ProfileServices"

function CountryStates(props: any) {
  const classes = useStyles();


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} className={classes.gridItem}>
          <Typography className={classes.text}>Country</Typography>
          <FormControl fullWidth size="small" variant="outlined">
            <ReactSelect
              fullWidth
              options={props.countryOptions}
              value={props.country}
              placeholder="Select Country"
              inputProps={{ "aria-label": "Without label" }}
              onChange={props.onChangeCountryType}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} className={classes.gridItem}>
          <Typography className={classes.text}>State</Typography>
          <FormControl fullWidth size="small" variant="outlined">
            <ReactSelect
              native
              fullWidth
              options={props.states}
              value={props.state}
              placeholder="Select State"
              inputProps={{ "aria-label": "Without label" }}
              onChange={props.onChangeStateType}
            ></ReactSelect>
          </FormControl>
        </Grid>
        <Grid item md={4} className={classes.gridItem}>
          <Typography className={classes.text}>cities</Typography>
          <FormControl fullWidth size="small" variant="outlined">
            <ReactSelect
              native
              fullWidth
              //displayEmpty
              value={props.city}
              options={props.cities}
              onChange={props.onChangeCity}
              placeholder="Select city"
              inputProps={{ "aria-label": "Without label" }}
              //onChange={props.onChangeStateType}
            ></ReactSelect>
          </FormControl>
        </Grid>
      </Grid>
    </div>

  )
}

export default CountryStates;
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BorderBottom } from '@material-ui/icons';
import { padding } from '@mui/system';
import { BiFontFamily } from 'react-icons/bi';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles((theme) =>
    createStyles({
        cardStyle: {
            background: 'linear-gradient(180deg, rgba(78, 114, 228, 0.77) 0%, #735DC1 100%)',
            padding: 30,
            borderRadius: 10,
           
        },
        tableBack: {
            borderRadius: '10px 10px 0px 0px',
            overflow: 'hidden'
        },
        tableHeadBack: {
            background: '#F1F1F1',
            borderRadius: '10px',
            overflow: 'hidden'
        },
        icons: {
            border: '1px solid rgba(255, 255, 255, 0.4)',
            width: 30,
            height: 30,
            padding: 5,
            color: '#fff'
        },
        dFlx: {
            display: 'flex',
            alignItems: 'center',
            gap: 15,
        },
        name: {
            fontWeight: 600,
            color: '#FFFFFF',
            fontFamily: 'Work Sans',
            fontSize: '18px'
        },
        email: {
            fontWeight: 500,
            color: '#FFFFFF',
            fontFamily: 'Work Sans',
            fontSize: '16px'
        },
        dataPicker: {
            display: 'flex',
            alignItems: 'center'
        },
        card: {
            background: '#FFFFFF',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: '10px',
            marginTop: 30,
            maxHeight: 300,
            height: 250,
            overflow: 'hidden',
            // width: 389,
            // [theme.breakpoints.down("xs")]:{
            //     width:0
            // }
        },
        cardTwo: {
            background: '#FFFFFF',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: '10px',
            marginTop: 30,
            // padding:'15px',
            maxHeight: 300,
            height: 250,
            overflow: 'hidden'
        },
        tRow: {
            //  background:'#F1F1F1',
            // borderRadius:'10px',
            // overflow:'none'
        },
        tHead: {
            color: '#575A5E',
            fontSize: 13,
            fontWeight: 600,
            fontFamily: 'Arial'
        },
        tBdy: {
            color: '#848282',
            fontWeight: 500,
            fontFamily: 'Arial',
            fontSize: 12
        },
        password: {
            color: '#7182DE',
            background: '#FFFEFE',
            borderRadius: '25px',
            textTransform: 'capitalize',
            padding: '6px 20px',
            fontFamily: 'Work Sans',
            '&:hover': {
                color: '#7182DE',
                background: '#FFFEFE',
            },
            [theme.breakpoints.down('xs')]: {
                // padding: '3px 20px',
            }

        },
        divFlex:{
            [theme.breakpoints.down('xs')]: {
                // display:'flex'
                display:'grid',
                gap:'8px',
                justifyContent:'center'

            }
        },
        passwords: {
            color: '#7182DE',
            background: '#FFFEFE',
            borderRadius: '25px',
            marginLeft: 20,
            textTransform: 'capitalize',
            fontFamily: 'Work Sans',
            padding: '6px 20px',
            '&:hover': {
                color: '#7182DE',
                background: '#FFFEFE',
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
            }
        },
        headDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 15
        },
        number: {
            color: '#4D72F8',
            fontSize: '15px',
            fontWeight: 600
        },
        addBtn: {
            color: '#4D72F8',
            background: '#FEFEFE',
            border: '1px solid #4D72F8',
            borderRadius: '5px',
            textTransform: 'capitalize',
            padding: '3px 6px',
            fontSize: 12
        }
    }))
import React from 'react'
import {
    Button,
    Grid,
    TextField,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import { useStyles } from './styles';
import ReactSelect from 'react-select'
import axiosInstance from '../../../../../environment/axiosInstance'
//import UserNumbersServices from '../../../../../../../userNumbersServices/usersNumbersServices'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const options = [
    { label: "User", value: 1 },
    { label: "Roomphone", value: 2 }

]

function AddAgent(props: any) {
    const classes = useStyles();
    const [userListData, setUserListData] = React.useState<any>([])
    const [userid, setUserId] = React.useState<any>()
    const [agnetId, setAgentId] = React.useState<any>()
    const [agents, setAgents] = React.useState([])
    const [option, setOption] = React.useState('')
    const [agentName, setAgentAddName] = React.useState({});
    const [roomphone, setRoomphone] = React.useState([])

    React.useEffect(() => {
        const agentss: any = [];
        if (props.openHoursData) {
            props.openHoursData?.Agents?.map((item: any) => {
                var obj = {
                    // label: item.firstName + ' ' + item.lastName ? item.firstName + ' ' + item.lastName: item.email,
                    // value: item.firstName + ' ' + item.lastName ? item.firstName + ' ' + item.lastName: item.email,
                    value: item.email,
                    label: item.email,
                    userId: item._id
                }
                agentss.push(obj)
            })
            setAgents(agentss)
        }
        const roomphones: any = [];
        if (props.openHoursData) {
            props.openHoursData.roomPhones?.map((item: any) => {
                var obj = {
                    value: item.name,
                    label: item.name,
                    userId: item._id
                }
                roomphones.push(obj)
            })
            setRoomphone(roomphones)
        }
    }, [props.openHoursData])




    const onSubmitAddAgent = () => {
        axiosInstance.post(`departments/${props.depId}/open-hours/agents/operator`, agentName).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.handlePopupModalAddAgent()
                props.getOpenHours()

            }
        })
        
    };
    // const onChangeUser = (data: any) => {
    //     const sendId: any = data.userId
    //     console.log('aakkk', data)
    //     setAgentAddName((prev: any) => {
    //         return {
    //             ...prev,
    //             userId: sendId
    //         };
    //     });
    // };

    const onChangeUser = (data: any) => {
        if (option == '1') {
            setAgentAddName({
                assignedFor: option,
                userId: data.userId
            })
        } else {
            setAgentAddName({
                assignedFor: option,
                userId: data.userId
            })
        }
    }


    const onChangeAssignType = (data: any) => {
        if (data.value === 2) {
            //fetchUserLists()
        }
        setOption(data.value)
    }




    return (
        <>
            <CardContent>
                <Card style={{ padding: '10px' }}>
                    <Grid container spacing={2} style={{ padding: 18 }}>
                        <Grid item md={6}>
                            <Typography className={classes.textDropdown}>Select</Typography>
                            <ReactSelect
                                fullWidth
                                placeholder="Select"
                                options={options}
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={onChangeAssignType}
                            />
                        </Grid>
                        <Grid item md={6}>
                            {
                                option == "1" ? (
                                    <Grid item md={12}>
                                        <Typography className={classes.user}>Select User</Typography>
                                        <ReactSelect
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            placeholder={"select User"}
                                            options={agents}
                                            className={classes.mtop}
                                            // value={
                                            //     agents.filter((option: any) =>
                                            //         option.value === props.closeHoursData.closeHours?.teamMemberId)
                                            // }
                                            onChange={onChangeUser}
                                        />
                                    </Grid>
                                ) : ""
                            }
                            {
                                option == "2" ? (
                                    <Grid item md={12}>
                                        <Typography className={classes.user}>Select Roomphone</Typography>
                                        <ReactSelect
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            placeholder={"select User"}
                                            options={roomphone}
                                            className={classes.mtop}
                                            // value={
                                            //     agents.filter((option: any) =>
                                            //         option.value === props.closeHoursData.closeHours?.teamMemberId)
                                            // }

                                            onChange={onChangeUser}
                                        />
                                    </Grid>
                                ) : ""
                            }
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <div className={classes.bflex} style={{ textAlign: 'center' }}>
                                <Button
                                    variant="outlined"
                                    className={classes.deleteButton}
                                    onClick={onSubmitAddAgent}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="outlined"
                                    className={classes.deltecancelButton}
                                    onClick={props.handlePopupModalAddAgent}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </CardContent>
        </>
    )
}
export default AddAgent

import React from 'react'
import { Button, TextField, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ProfileServices from '../../../Profile/ProfileServices/ProfileServices'
import './style.css'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { InputAdornment } from '@material-ui/core';
function Signin(props: any) {
    const { location } = useHistory();
    const history = useHistory();
    const [token, setToken] = React.useState<any>()
    const [showconfirmPw, setShowConfirmPw] = React.useState(false)
    const [showPw, setShowPw] = React.useState(false)
    const [form, setForm] = React.useState<any>({
        data: {},
        errors: {}
    })
    const [data, setData] = React.useState<any>({
        "firstname": "",
        "lastname": "",
        "password": "",
        "confirmPassword": ""
    })
    const habdleshowPW = () => {
        setShowPw(!showPw)
    }
    const handleConfirmPw = () => {
        setShowConfirmPw(!showconfirmPw)
    }
    const onChangeEvent = (e: any) => {
        let data = form.data;
        // data[e.target.name] = e.target.value;
        // setForm({
        //     data: data
        // })
        setData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })

    }


    const submitSigninForm = (e: any) => {
        e.preventDefault()
        setForm({
            data: data
        })
        if (validate()) {
            console.log(form);
            // let data = {};
            // form["firstname"] = "";
            // form["lastname"] = "";
            // form["password"] = "";
            // form["confirmPassword"] = "";
            // setForm({ data: data });
            const newdata = {
                "firstName": data?.firstname,
                "lastName": data?.lastname,
                "password": data?.password
            }
            ProfileServices.postuserNamePW(token, newdata).then((res) => {
                if (res.success) {
                    history.push("/")
                }
            }).catch((err) => {

            })

        }
    }


    const validate = () => {
        let datavalue = form.data;
        let errors: any = {};
        let isValid = true;

        if (!data["firstname"]) {
            isValid = false;
            errors["firstname"] = "Please enter your firstname.";
        }

        if (!data["lastname"]) {
            isValid = false;
            errors["lastname"] = "Please enter your lastname.";
        }


        if (!data["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        if (!data["confirmPassword"]) {
            isValid = false;
            errors["confirmPassword"] = "Please enter your confirm password.";
        }

        if (typeof data["password"] !== "undefined" && typeof data["confirmPassword"] !== "undefined") {

            if (data["password"] != data["confirmPassword"]) {
                isValid = false;
                errors["password"] = "Passwords don't match.";
                setForm({
                    data: {
                        password: "",
                        confirmPassword: ""
                    }
                });
            }
        }

        setForm({
            errors: errors
        });

        return isValid;
    }

    React.useEffect(() => {
        setToken(location.search.slice(7))
    }, [])
    return (
        <div className="signin_container">
            <div className="signin_title">
                <h2>Set Password</h2>
            </div>
            <div className="form_container" >
                <div className="signin_input_section">
                    <h4>First Name</h4>
                    <input type="text"
                        name="firstname"
                        placeholder="Enter First Name"
                        value={data?.firstName}
                        onChange={onChangeEvent} />
                    <div className="text-danger">{form?.errors?.firstname}</div>
                </div>
                <div className="signin_input_section">
                    <h4>Last Name</h4>
                    <input type="text"
                        name="lastname"
                        placeholder="Enter Last Name"
                        value={data?.lastname}
                        onChange={onChangeEvent} />
                    <div className="text-danger">{form?.errors?.lastname}</div>
                </div>
                <div className="signin_input_section">
                    <h4>Password</h4>
                    <TextField
                        type={showPw ? "text" : "password"}
                        name="password" id="pasword"
                        onChange={onChangeEvent}
                        placeholder="password"
                        value={data?.password}
                        style={{ padding: "3px 8px", width: "100%", margin: "2px auto 10px", borderRadius: "20px", outline: 0, border: "1px solid #c7c7c7" }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="start">
                                    {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} /> :
                                        <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} />}
                                </InputAdornment>
                            ),
                        }} />
                    {/* <input type="Password" name="password"
                        placeholder="password"
                        value={form?.data?.password}
                        onChange={onChangeEvent} /> */}
                    <div className="text-danger">{form?.errors?.password}</div>
                </div>
                <div className="signin_input_section">
                    <h4>Confirm Password</h4>
                    <TextField
                        type={showconfirmPw ? "text" : "password"} name="confirmPassword" id="confirmPassword"
                        onChange={onChangeEvent}
                        placeholder="Confirm Password"
                        value={data?.confirmPassword}
                        style={{ padding: "3px 8px", width: "100%", margin: "2px auto 10px", borderRadius: "20px", outline: 0, border: "1px solid #c7c7c7" }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="start">
                                    {showconfirmPw ? <FiEye onClick={handleConfirmPw} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} /> :
                                        <FiEyeOff onClick={handleConfirmPw} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} />}
                                </InputAdornment>
                            ),
                        }} />
                    {/* <input type="password" name="confirmPassword"
                        placeholder="Confirm Password"
                        value={form?.data?.confirmPassword}
                        onChange={onChangeEvent} /> */}
                    <div className="text-danger">{form?.errors?.confirmPassword}</div>

                </div>

                <div className="signin_btn_section" onClick={submitSigninForm}>
                    <Button type="submit">Submit</Button>
                </div>


            </div>
        </div>
    )
}

export default Signin

import { Button, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import { MoreHoriz } from '@material-ui/icons';
import Moment from 'moment'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import AssignNumber from './AssignNumber/AssignNumber';
import axiosInstance from '../../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function TableBodyData(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const [user, SetUser] = React.useState('')
    const anchorRef: any = React.useRef(null);
    const [assignModal, setAssignModal] = React.useState(false)
    const [Id, setId] = React.useState('')
    //const { phoneNUmberDetail, assignedDetail } = props.data
    const [data, setData] = React.useState<any>({})
    const { phone, id, departmentId, userId, status, createdDate } = props?.item
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    React.useEffect(() => {
        if (props.data) {
            props.data.map((dptName: any) => {
                if (dptName._id === props.item.departmentId) {
                    SetUser(dptName.name)
                    setId(dptName._id)
                }
            })
        }
    }, [props.item, props.data])
    React.useEffect(() => {
        if (props.Userdata) {
            props.Userdata.map((usermail: any) => {
                if (usermail._id === props.item.userId) {
                    SetUser(usermail.email)
                    setId(usermail._id)
                }
            })
        }
    }, [props.Userdata])


    const handleAssignNumber = () => {
        let sendData: any
        if (props.assigned === 1) {
            sendData = {
                "assignedFor": props.assigned,
                "departmentId": Id
            }
        } else {
            sendData = {
                "assignedFor": props.assigned,
                "userId": Id
            }
        }
        axiosInstance.patch(`/phoneNumbers/unassign-phonenumber?phoneNumberId=${props.item._id}`, sendData).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.fetchPhoneNumbers(1,10)
               
            }
        }).catch((err: any) => {

        })
    }






    var datadate = parseInt(props.item.createdDate)
    var dataa = Moment(datadate).format('YYYY-MM-DD HH:mm:ss')

    const handleAssignNumberModal = () => {
        setAssignModal(!assignModal)
    }


    return (
        <>
            {
                assignModal && (
                    <div className={classes.padding}>
                        <Backdrop handlePopupModal={handleAssignNumberModal} />
                        <Modal title="Assign Number" handlePopupModal={handleAssignNumberModal} >
                            <div className="add_user_popup" style={{ width: "600px", padding: '0px 15px' }}>
                                <AssignNumber
                                    number={props.item.phone}
                                    id={props.item._id}
                                    fetchPhoneNumbers={props.fetchPhoneNumbers}
                                    handleAssignNumberModal={handleAssignNumberModal}
                                />
                            </div>
                        </Modal>
                    </div>
                )

            }
            <TableRow>
                <TableCell className={classes.tableBody}>{props.item.phone}</TableCell>
                <TableCell className={classes.tableBody}>
                    {props.assign ?
                        <>
                            <Button
                                variant='outlined' size='small'
                                className={classes.assignBtn}
                                onClick={handleAssignNumberModal}
                            >
                                Assign
                            </Button>
                        </> :
                        user
                    }
                </TableCell>
                <TableCell className={classes.tableBody}>{props.item.createdDate ? dataa : ''}</TableCell>
                <TableCell className={classes.tableBody}>_</TableCell>
                <TableCell className={classes.tableBody}>
                    <Button className={classes.actionButton} size="small">
                        <MoreHoriz className={classes.adminIcon}
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        />
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        // placement === "right" ? "right bottom" : "center left",
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}>
                                <Paper style={{ border: '1px solid #D9D9D9', borderRadius: "5px", background: '#FFFFFF', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)' }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {/* <MenuItem className={classes.delete}>
                                                <ListItemIcon style={{ alignItems: 'center' }}>
                                                    <ListItemText className={classes.textColor}>Edit</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem> */}
                                            {user ?
                                                <MenuItem className={classes.delete}
                                                    onClick={handleAssignNumber}
                                                >
                                                    UnAssign</MenuItem> :
                                                <MenuItem
                                                //onClick={handlePopupModalNumber}
                                                >Delete</MenuItem>
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </TableCell>
            </TableRow>

        </>
    )
}

export default TableBodyData
import React from "react";
import {
  Card,
  Grid,
  Button,
  CardContent,
  Typography,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useStyles } from "./styles";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import Departmentservices from "../DepartmentServices/DepartmentServices";
import moment from 'moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function AddBlockedNumber(props: any) {
  const classes = useStyles();
  const Dateandtime = moment(new Date(), 'YYYY.MM.DD HH:MM:SS').unix();
  const dt = Dateandtime * 1000
  const [selectedNumber, setSelectedNumber] = React.useState<any>(null);
  const [startDate, setStartDate] = React.useState<any>(new Date());
  const [error, setError] = React.useState("");
  const [addBlockNumber, setAddBlockNumber] = React.useState({
    blockCalls: true,
    blockSms: true,
    lastCallReceived: dt,
    phoneNumber: "",
  });
  const [state, setState] = React.useState({
    checkedB: false,
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [statecheck, setStatecheck] = React.useState({
    checkedA: false,
  });
  const handleChange1 = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChange = (event: any) => {
    setAddBlockNumber((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.checked
      }
    })

  };
  const onChangeBlockNumber = (data: any) => {
    const val = data.target.value

    setSelectedNumber(data.target.value)
    if (val !== " ") {
      setAddBlockNumber((prev: any) => {
        return {
          ...prev,
          "phoneNumber": data.target.value.replace(/[^0-9,.]+/g, ""),
        }
      })
    }


  }

  const AddBlockNumberData = () => {
    if (!addBlockNumber.blockCalls && !addBlockNumber.blockSms) {
      toast.error("Enter check to block calls or sms", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return
    }
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData?.isValid) {
      Departmentservices.postDepartmentBlockedNumber(props.Departmentid, addBlockNumber)
        .then((res: any) => {
          if (res.success) {
            props.handlePopupModal();
            props.fetchAdvancedSettingData();
          } else {
          }
        })
        .catch((err: any) => {

          console.log(err);
          // send error
        });
    }
  };
  const onChangeDateandTime = (date: any) => {
    const Dateandtime = moment(date, 'YYYY.MM.DD HH:MM:SS').unix();
    const dt = Dateandtime * 1000
    setStartDate(dt)
    // updateUserProfile(dndDatetime);
    setAddBlockNumber((prev: any) => {
      return {
        ...prev,
        lastCallReceived: dt
      }
    })

  };
  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      phoneNumber: { ...validData },


    };
    let isValid = true;
    if (submitted) {
      if (!addBlockNumber.phoneNumber) {
        retData.phoneNumber = {
          isValid: false,
          message: "Please enter phoneNumber"
        };
        isValid = false;
      }

      if (addBlockNumber.phoneNumber.length < 10 || addBlockNumber.phoneNumber.length > 15) {
        retData.phoneNumber = {
          isValid: false,
          message: "Please enter phone number between 10 to 15  digits"
        };
        isValid = false;
      }

    }
    return {
      ...retData,
      isValid
    };
  };

  const errors = validate(submitted);
  return (
    <div >
      <Grid container spacing={2} style={{ padding:'10px 15px' }}>
        <Grid item md={12}>
          <Typography className={classes.textColorOne}>Add Number</Typography>
          <TextField
            className={classes.input}
            placeholder="Enter Number"
            size="small"
            value={addBlockNumber.phoneNumber}
            // type="number"
            inputProps={{ min: 0, inputmode: 'numeric', pattern: '[0-9]*' }}
            variant="outlined"
            fullWidth
            onChange={onChangeBlockNumber}
            error={!errors.phoneNumber.isValid}
          />
          {
            !errors.phoneNumber.isValid &&
            <p className={classes.errorMessage}>{errors.phoneNumber.message}</p>
          }
          <Typography className={classes.textColorOne}>Last Call Time</Typography>
          {/* <TextField type="date" size="small" variant="outlined" /> */}
          <div className="date_and_time_picker_section">
            <DatePicker
              selected={startDate}
              onChange={onChangeDateandTime}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              maxDate={new Date()}
              className={classes.inputdate}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              className={classes.formPadding}
              control={
                <Checkbox
                  checked={addBlockNumber.blockCalls}
                  onChange={handleChange}
                  name="blockCalls"
                  color="primary"
                />
              }
              label={<span className={classes.labelText}>Block Calls</span>}
            />
            <FormControlLabel
              className={classes.formPadding}
              control={
                <Checkbox
                  checked={addBlockNumber.blockSms}
                  onChange={handleChange}
                  name="blockSms"
                  color="primary"
                />
              }
              label={<span className={classes.labelText}>Block SMS</span>}
            />
          </FormGroup>
        </Grid>
      </Grid>


      <div className={classes.wButton}>
        <Button size="medium" className={classes.submitButton} onClick={AddBlockNumberData}>Submit</Button>
        <Button size="medium" onClick={() => props.handlePopupModal()} className={classes.cancelButton}>Cancel</Button>
      </div>
    </div>
  );
}

export default AddBlockedNumber;

import { MdDashboard, MdSettings } from 'react-icons/md'
import { IoPersonSharp } from 'react-icons/io5'
import { FaMoneyCheckAlt, FaUserTie } from 'react-icons/fa'
import { BiPhoneCall } from 'react-icons/bi'
import { HiDocumentReport, HiOutlineHome } from "react-icons/hi";
import { VscAccount } from 'react-icons/vsc'
import home from '../../assets/images/home 1.png'
import user from '../../assets/images/user 1.png'
import departments from '../../assets/images/sitemap 1.png'
import phone from '../../assets/images/phone-book 1.png'
import billing from '../../assets/images/bill 1.png'
import account from '../../assets/images/profile 1.png'
import { useSelector } from 'react-redux';

// const childRoutes:any = []
// export function ChildSidebarLinks(){
//     const departmentStateData = useSelector((state: any) => state.departmentModule);
//     const { selectedDepartment,departmentsData, loading } = departmentStateData;
//     console.log("departments data",departmentsData)
//     const arr:any = []
//     departmentsData.map((item:any)=>{
//         const obj:any = {
//             title:item.name,
//             id:item.id
//         }
//         arr.push(obj)
//     })
//     childRoutes.push(arr)
//     return arr;
// }
// const result = ChildSidebarLinks()
// console.log("result",result)
// console.log("childRoutes",childRoutes)


const SidebarLinksNew: any = [

    {
        title: 'Dashboard',
        path: '/dashboard',
        Icon: home,
        color: '#4D72F8',
        IconSize: 22,
        childTitle: '',
        routes: [
            // {
            //     title: 'Callflow',
            //     path: '/dashboard/callflow',
            //     Icon: MdDashboard,
            //     color: '',
            //     IconSize: 22,
            // },
            // {
            //     title: 'Recordings',
            //     path: '/dashboard/recordings',
            //     // Icon: MdDashboard,
            //     color: '',
            //     IconSize: 22,
            // },
            // {
            //     title: 'Analytics',
            //     path: '/dashboard/analytics',
            //     // Icon: MdDashboard,
            //     color: '',
            //     IconSize: 22,
            // },
            {
                title: 'Reports',
                path: '/dashboard/reports',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
            },
            {
                title: 'Email Notifications',
                path: '/dashboard/email-notifications',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
            }
        ]
    },
    {
        title: 'Users & Deskphones',
        path: '/users&deskphones/user',
        Icon: user,
        color: '',
        IconSize: 22,
        childTitle: '',
        routes: [
            {
                title: 'User',
                path: '/users&deskphones/users',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [],
            },
            {
                title: 'Deskphones',
                path: '/users&deskphones/deskphones',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [],
            },
            {
                title: 'Phone Numbers',
                path: '/users&deskphones/phonenumbers',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [
                    // {
                    //     title: 'All Number',
                    //     path: '/users&deskphones/phonenumbers/allnumbers',
                    //     Icon: MdDashboard,
                    //     color: '',
                    //     IconSize: 22,
                    // },
                    // {
                    //     title: 'Port Number',
                    //     path: '/users&deskphones/phonenumbers/portnumbers',
                    //     Icon: MdDashboard,
                    //     color: '',
                    //     IconSize: 22,
                    // },
                ]
            },
            {
                title: 'Offices',
                path: '/users&deskphones/offices',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [],
            },
        ]
    },
    {
        title: 'Departments',
        path: '/departments',
        Icon: departments,
        IconSize: 22,
        color: '',
        childTitle: '',
        routes: [
            {
                title: 'Mainline',
                path: '/mainline',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [
                    {
                        title: 'Schedule',
                        path: '/departments/departmenta/schedule',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Holidays',
                        path: '/departments/departmenta/holidays',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Open Hours',
                        path: '/openhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Closed Hours',
                        path: '/closedhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Advanced Settings',
                        path: '/advancedsettings',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Call History',
                        path: '/callhistory',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                ]
            },
            {
                title: 'Department A',
                path: '/departments/departmenta',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [
                    {
                        title: 'Schedule',
                        path: '/departments/departmenta/schedule',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Holidays',
                        path: '/departments/departmenta/holidays',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Open Hours',
                        path: '/openhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Closed Hours',
                        path: '/departments/closehours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Advanced Settings',
                        path: '/advancedsettings',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Call History',
                        path: '/callhistory',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                ]
            },
            {
                title: 'Department B',
                path: '/departmentb',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [
                    {
                        title: 'Schedule',
                        path: '/Schedule',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Holidays',
                        path: '/Holidays',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Open Hours',
                        path: '/openhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Closed Hours',
                        path: '/closedhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Advanced Settings',
                        path: '/advamcedsettings',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Call History',
                        path: '/callhistory',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                ]
            },
            {
                title: 'Department C',
                path: '/departmentc',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: [
                    {
                        title: 'Schedule',
                        path: '/Schedule',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Holidays',
                        path: '/Holidays',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Open Hours',
                        path: '/openhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Closed Hours',
                        path: '/closedhours',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Advanced Settings',
                        path: '/advamcedsettings',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                    {
                        title: 'Call History',
                        path: '/callhistory',
                        // Icon: MdDashboard,
                        color: '',
                        IconSize: 22,
                    },
                ]
            },
        ]
    },
    // {
    //     title: 'Billing',
    //     path: '/billing',
    //     Icon: billing,
    //     IconSize: 22,
    //     color: '',
    //     childTitle: '',
    //     routes: [
    //         {
    //             title: 'All DeskPhone',
    //             path: '/billing/alldeskphone',
    //             // Icon: MdDashboard,
    //             color: '',
    //             IconSize: 22,
    //         },
    //     ]
    // },
    {
        title: 'My Account',
        path: '/myaccount',
        Icon: account,
        IconSize: 22,
        color: '',
        childTitle: '',
        routes: [
            {
                title: 'My Setting',
                path: '/myaccount/mysettings',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: []
            },
            {
                title: 'Schedule',
                path: '/myaccount/mysettings/schedule',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: []
            },
            {
                title: 'Open Hours',
                path: '/myaccount/mysettings/openhours',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: []
            },
            {
                title: 'Closed Hours',
                path: '/myaccount/mysettings/closehours',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: []
            },
            {
                title: 'Advanced Settings',
                path: '/myaccount/mysettings/advancedsettings',
                // Icon: MdDashboard,
                color: '',
                IconSize: 22,
                childRoutes: []
            },
            // {
            //     title: 'Unanswered Call',
            //     path: '/myaccount/mysettings/unansweredcall',
            //     // Icon: MdDashboard,
            //     color: '',
            //     IconSize: 22,
            //     childRoutes: []
            // },



            // {
            //     title: 'Logout',
            //     path: '/myaccount/logout',
            //     // Icon: MdDashboard,
            //     color: '',
            //     IconSize: 22,
            // },
        ]
    },
    {
        title: 'Contacts',
        path: '/contacts',
        Icon: account,
        IconSize: 22,
        color: '',
        childTitle: '',
        routes: []
    },
    {
        title: 'Messages',
        path: '/messages',
        Icon: account,
        IconSize: 22,
        color: '',
        childTitle: '',
        routes: []
    },
  
   
]

export default SidebarLinksNew
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import ReactSelect from 'react-select';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from 'recharts';
import outgoing from '../../../../assets/icons/outgoingcalls.png';
import incoming from '../../../../assets/icons/incomingcalls.png';

export function CallStats(): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const handleChange = (value: any) => {
    setValue(value);
  };

  const lineData = [
    {
      uv: 195,
      mv: 95,
      sv: 0,
      lv: 2020,
    },
    {
      uv: 150,
      mv: 115,
      sv: 50,
      lv: 2020,
    },
    {
      uv: 250,
      mv: 157,
      sv: 100,
      lv: 2020,
    },
    {
      uv: 205,
      mv: 152,
      sv: 150,
      lv: 2020,
    },
    {
      uv: 270,
      mv: 190,
      sv: 200,
      lv: 2020,
    },
    {
      uv: 220,
      mv: 180,
      sv: 250,
      lv: 2020,
    },
    {
      uv: 290,
      mv: 270,
      sv: 300,
      lv: 2020,
    },
  ];

  const barData = [
    {
      uv: 50000,
      sv: 'Mar 1-7',
    },
    {
      uv: 125000,
      sv: 'Mar 8-14',
    },
    {
      uv: 125000,
      sv: 'Mar 15-21',
    },
    {
      uv: 125000,
      sv: 'Mar 22-28',
    },
    {
      uv: 190000,
      sv: 'Final Week',
    },
  ];

  return (
    <>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={6} className={classes.select}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={5}>

                <Grid item md={12} className={classes.cardContent}>
                  <img src={outgoing} alt="outgoing" />
                  <Typography className={classes.outgoingText}>Outgoing Calls</Typography>
                  <img src={incoming} alt="incoming" />
                  <Typography className={classes.incomingText}>Incoming Calls</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Typography className={classes.areaText}>Last 7 Days</Typography>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} className={classes.gridItem}>
                  <AreaChart width={600} height={300} data={lineData}>
                    <XAxis
                      interval={0}
                      axisLine={false}
                      tickLine={false}
                      dataKey="lv"
                      padding={{ left: 20, right: 20 }}
                    />
                    <YAxis dataKey="sv" tickCount={7} axisLine={false} tickLine={false} />
                    <Tooltip />
                    <CartesianGrid vertical={false} strokeDasharray="3,3" />
                    <Area
                      type="monotone"
                      dataKey="uv"
                      stroke="#4D72F8"
                      fill="rgba(51, 108, 251, 0.2)"
                    />
                    <Area
                      type="monotone"
                      dataKey="mv"
                      stroke="gold"
                      fill="rgba(51, 108, 251, 0.2)"
                    />
                  </AreaChart>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} className={classes.select}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={5}>
                <Grid item md={5} className={classes.select}>
                  <ReactSelect
                    placeholder={'Last 30 Days Calls'}
                    value={value}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={7} className={classes.select}></Grid>
              </Grid>
            </CardContent>
            <CardContent className={classes.padding}>
              <Card className={classes.barCard}>
                <Grid container spacing={5}>
                  <Grid item md={12} className={classes.gridItem}>
                    <BarChart width={590} height={300} data={barData}>
                      <XAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey="sv"
                        scale="point"
                        padding={{ left: 50, right: 50 }}
                      />
                      <YAxis tickCount={5} axisLine={false} tickLine={false} />
                      <Tooltip />
                      <CartesianGrid vertical={false} strokeDasharray="3,3" />
                      <Bar dataKey="uv" fill="#5A75FF" radius={[12.551, 12.551, 0, 0]} />
                    </BarChart>
                  </Grid>
                </Grid>
              </Card>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

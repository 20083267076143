import { Card, CardContent, Divider, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { InputAdornment } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useStyles } from './styles';
import { CardHeader } from '../../components/CardHeader/CardHeader';

function Settings (){
    const classes = useStyles();
    return(
        <>
            <Card className={classes.card}>
                <CardHeader name={'Office Info'} remove={true} />
                <Divider className={classes.divider} />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Typography className={classes.space}>Office Name</Typography>
                             <TextField variant="outlined" size="small" placeholder="Main Line" fullWidth />
                        </Grid>
                    </Grid> 
                </CardContent>
                <CardContent>
                    <Grid container spacing={2}>
                    <Grid item lg={5} md={6} sm={6} xs={12}>
                    <Typography className={classes.space}>Office Administration</Typography>
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Add people for your team"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AddCircleOutlineIcon className={classes.addIcon} />
                                </InputAdornment>
                        ),
                        }}
                    />
                    </Grid>
                    
                    </Grid>
                </CardContent>
                <Grid item md={12}>
                    <Grid item md={12} sm={12} xs={12}> 
                        <CardContent>
                            <Typography className={classes.space}>3 Office Administration</Typography>
                                    <TableContainer className={classes.tableContainer}>
                                    <Table>
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                <TableCell className={classes.tableCellRow}>Name</TableCell>
                                                 <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.tableCellBody}>User1</TableCell>
                                                <TableCell className={classes.tableCellBody1}>
                                                    options
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableCellBody}>User2</TableCell>
                                                <TableCell className={classes.tableCellBody1}>
                                                    options
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}
export default Settings
import { AuthActions } from './Actions'


export const initAuth = (authData: any) => {
    return {
        type: AuthActions.INIT_AUTH,
        data: authData
    }
}


export const initAuthLoginAs = (authData: any) => {
    return {
        type: AuthActions.INIT_AUTH_LOGIN_AS,
        data: authData
    }
}

export const AuthLoading = (status: any) => {
    return {
        type: AuthActions.INIT_AUTH_LOADING,
        status: status
    }
}

export const authSuccess = (authData: any) => {
    return {
        type: AuthActions.INIT_AUTH_SUCCESS,
        data: authData
    }
}

export const authStatusCheck = () => {
    return {
        type: AuthActions.INIT_AUTH_STATUS
    }
}

export const authTimeout = (tokenData: any) => {
    return {
        type: AuthActions.INIT_AUTH_TIMEOUT,
        tokenData: tokenData
    }
}

export const initAuthLogout = () => {
    return {
        type: AuthActions.INIT_AUTH_LOGOUT
    }
}

export const initAuthLogoutSuccess = () => {
    return {
        type: AuthActions.INIT_AUTH_LOGOUT_SUCCESS
    }
}

export const initRefreshToken = () => {
    return {
        type: AuthActions.INIT_REFRESH_TOKEN
    }
}
import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useStyles } from "../MyLogins/styles";
import addIcon from "../../../assets/icons/addIcon.png";
// import Vector from '../../../assets/icons/Vector.png';
import VectorButton from "../../../assets/icons/VectorButton.png";
import ProfileServices from "../ProfileServices/ProfileServices";
import DeleteIcon from "@material-ui/icons/Delete";
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
import AddEfoneNumber from "./AddEfoneNumber/AddEfoneNumber";
import AddDepartmentNumber from "./AddDepartment/AddDepartment";
import { useSelector } from 'react-redux'
import DeptTable from "./DeptTable";
import { profile } from "console";
function tableData(name: any, PrimaryNumbers: any) {
  return { name, PrimaryNumbers };
}

const datas = [
  tableData("Sales(Department)", "(123) 456 7778"),
  tableData("Support(Department)", "(123) 456 7778"),
  tableData("Support(Department)", "(123) 456 7778"),
  tableData("Support(Department)", "(123) 456 7778"),
  tableData("Support(Department)", "(123) 456 7778"),
  tableData("Support(Department)", "(123) 456 7778"),
];
const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];
function EfoneNumberDepartments() {
  const classes: any = useStyles();
  const [recordAllCalls, setRecordAllCalls] = React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const [openModalAdduser, setopenModalAdduser] = React.useState(false);
  const [departmentData, setDepartmentData] = React.useState<any>([]);
  const [profileNumberData, setProfileNumberData] = React.useState<any>([]);
  const [openModalAddDepartment, setopenModalAddDepartment] =
    React.useState(false);
  const profileDataState: any = useSelector((state: any) => state.profileData);

  const updateUserProfileData = (data: any) => {
    ProfileServices.updateprofileData(data).then((res) => {
      if (res.success) {

      } else {

      }
    }).catch((err) => {

    })
  }

  const fetchDepartmentData = () => {
    setLoading(true);
    ProfileServices.getDepartmentData()
      .then((res: any) => {
        if (res.success) {
          setDepartmentData(res.data);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        //send err message
      });
  };

  const fetchProfileNumberData = () => {
    setLoading(true);
    ProfileServices.geteFoneNumbers()
      .then((res: any) => {

        if (res.data) {
          setProfileNumberData(res.data);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        //send err message
      });
  };

  const handlePopupModalAddNumber = () => {
    setopenModalAdduser(!openModalAdduser);
  };

  const handlePopupModalAddDepartment = () => {
    setopenModalAddDepartment(!openModalAddDepartment);
  };

  const onChangeRecordAllCalls = () => {
    setRecordAllCalls(!recordAllCalls)
    updateUserProfileData({ recordAllCalls: !recordAllCalls })
  }

  React.useEffect(() => {
    fetchDepartmentData();
    fetchProfileNumberData();
  }, []);

  React.useEffect(() => {
    if (profileDataState) {
      const { profileData } = profileDataState;
      setRecordAllCalls(profileData?.recordAllCalls)
    }
  }, [profileDataState])


  return (
    <div className={classes.padding}>
      {openModalAdduser && (
        <div>
          <Backdrop handlePopupModal={handlePopupModalAddNumber} />
          <Modal
            title="Add Number"
            handlePopupModal={handlePopupModalAddNumber}
          >
            <AddEfoneNumber fetchProfileNumberData={fetchProfileNumberData}
              handlePopupModal={handlePopupModalAddNumber} />
          </Modal>
        </div>
      )}

      {openModalAddDepartment && (
        <div>
          <Backdrop handlePopupModal={handlePopupModalAddDepartment} />
          <Modal
            title="Add New User"
            handlePopupModal={handlePopupModalAddDepartment}
          >
            <div className="add_user_popup" style={{ width: "600px" }}>
              <AddDepartmentNumber
                handlePopupModal={handlePopupModalAddDepartment}
                getDepartment={fetchDepartmentData}
              />
              {/* <button onClick={handlePopupModal}>close</button> */}
            </div>
          </Modal>
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item md={6} className={classes.grid}>
          <Card className={classes.cardlength}>
            <CardContent>
              <Grid container spacing={2} className={classes.container}>
                <Grid item md={11}>
                  <Typography className={classes.textName}>
                    Direct efone(s) Numbers
                  </Typography>
                </Grid>
                {profileDataState?.profileData?.userType === 2 || profileDataState?.profileData?.userType === 3 && profileDataState?.profileData?.isAdmin ? 
                <Grid item md={1}>
                  <img
                    alt="addIcon"
                    src={addIcon}
                    onClick={handlePopupModalAddNumber}
                  />
                </Grid> : ""}
              </Grid>
            </CardContent>
            <Divider className={classes.divider} />
            <div id="scrollbar">
              <CardContent >
                {profileNumberData.slice(0, 6).map((data: any) => (
                  <div className={classes.vector} >
                    <img
                      className={classes.iconWidth}
                      alt="vector"
                      src={VectorButton}
                    />
                    <Typography className={classes.textWidth}>
                      {data.phoneNumber}{" "}
                      <small>{data.isPrimary ? "(primary)" : ""} </small>
                    </Typography>
                  </div>
                ))}
                <div className={classes.vector}>
                  <Checkbox checked={recordAllCalls} color={"primary"} onChange={onChangeRecordAllCalls} />
                  <Typography className={classes.text}>
                    Record all Calls
                  </Typography>
                </div>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item md={6} className={classes.grid}>
          <Card className={classes.cardlength}>
            <CardContent>
              <Grid container spacing={2} className={classes.container}>
                <Grid item md={11}>
                  <Typography className={classes.textName}>
                    Departments
                  </Typography>
                </Grid>
                {profileDataState?.profileData?.userType === 2 || profileDataState?.profileData?.userType === 3 && profileDataState?.profileData?.isAdmin ? 
                <Grid item md={1}>
                  <img
                    alt="addIcon"
                    src={addIcon}
                    onClick={handlePopupModalAddDepartment}
                  />
                </Grid> : ""}
              </Grid>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent id="scrollbar">
              <Table >
                <TableHead className={classes.headColor}>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Name</TableCell>
                    <TableCell className={classes.tableHead}>
                      Primary Numbers
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departmentData.length > 0 ? departmentData.map((data: any) => (
                    // <TableRow key={data.id}>
                    //   <TableCell className={classes.tableBody}>
                    //     {data.departmentName}
                    //   </TableCell>
                    //   <TableCell className={classes.tableBody}>
                    //     {data.primaryNumber}
                    //   </TableCell>
                    // </TableRow>
                    <DeptTable data={data} />
                  )) : <p style={{ textAlign: "center" }}>No Departments Found</p>}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default EfoneNumberDepartments;

import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react'
import { useStyles, PrettoSlider } from '../Users/styles';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import axiosInstance from '../../../environment/axiosInstance'
import ReactSelect from 'react-select'
import { GrUpload } from 'react-icons/gr';
import { useDispatch, useSelector } from "react-redux";
import * as authActionCreators from '../../../store/Actions/AuthActionCreator'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import AddEfoneNumber from './ManageNumbers/addroomphoneaddnumber/AddEfoneNumber';
import UserNumberServices from '../../UsersNumbers/userNumbersServices/usersNumbersServices'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function AddDeshphone(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const [openExpand, setOpenExpand] = React.useState(true);
    const [open, setOpen] = React.useState(true)
    const [opeing, setOpeing] = React.useState(true)
    const [loading, setLoading] = React.useState(false);
    const [openClose, setOpenClose] = React.useState(true)
    const [ringDurationTime, setRingDurationTime] = React.useState<any>({
        ringDurationTime: 0
    })
    const [deptList, setdeptList] = React.useState<any>();
    const [teammembList, setteammembList] = React.useState<any>();
    const audioFileInput: any = React.createRef();
    const [fileBuffer, setFileBuffer] = React.useState('')
    const [fileName, setFileName] = React.useState('')
    const [source, setSource] = React.useState<any>('')
    const [openManagePhoneNumber, setOpenManagePhoneNumber] = React.useState(false);

    const [roomData, setRoomData] = React.useState<any>({
        name: '',
        ringduraion: 10,
        forwardingRule: 0,
        emergencyLocation: "",
        outboundId: "",
        isOutboundCalling: false,
        macAddress: '',
        departmentId: '',
        teamMemberId: ''
    })


    const handlePopupModalManagePhoneNumber = () => {
        setOpenManagePhoneNumber(!openManagePhoneNumber)
        // setEditId(id)
    }


    const getDeptList = () => {
        axiosInstance.get('/deskphone/departments-teammembers').then((res: any) => {
            if (res.data) {
                const Teams = res.data.Teams
                const depts = res.data.Departments
                var TeamsList: any = [];
                Teams?.map((item: any) => {
                    var object = {
                        "value": item._id,
                        "label": item.firstName + item.lastName
                    }
                    TeamsList.push(object)
                })
                setteammembList(TeamsList)

                var deptsList: any = [];
                depts?.map((item: any) => {
                    var object = {
                        "value": item._id,
                        "label": item.name
                    }
                    deptsList.push(object)
                })
                setdeptList(deptsList)
            } else {

            }
        }).catch((err: any) => {

        })
    }


    const expandOpen = () => {
        setOpenExpand(!openExpand)
    }
    const expanding = () => {
        setOpen(!open)
    }
    const expanded = () => {
        setOpeing(!opeing)

    }
    const expandClose = () => {
        setOpenClose(!openClose)
    }

    const handleChange = (newData: any) => {
        //console.log('kkk', newData)
        setRoomData(() => {
            return {
                ...roomData,
                emergencyLocation: newData
            }
        });
    };

    const onChangeTextCheckBox = () => {
        if (roomData.forwardingRule === 1) {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 0
                }
            })
        } else {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 1
                }
            })
        }

    }
    const onChangeTeamemberCheckBox = () => {
        // alert(' lj')
        if (roomData.forwardingRule === 2) {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 0
                }
            })
        } else {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 2
                }
            })
        }
    }


    const onChangedeptCheckBox = () => {
        // alert(' lj')
        if (roomData.forwardingRule === 3) {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 0
                }
            })
        } else {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 3
                }
            })
        }
    }

    const handleChangeName = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                name: e.target.value
            }
        })
    }

    const handleDeptSelection = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                departmentId: e.value
            }
        })
    }
    const handleTeammemberSelection = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                teamMemberId: e.value
            }
        })
    }

    const handleChangemacaddress = (e: any) => {
        // console.log(e.target.value)
        setRoomData((prev: any) => {
            return {
                ...prev,
                macAddress: e.target.value
            }
        })
    }

    const onChangeRadio = (data: any) => {
        // alert('jnjknj')
        // console.log('e', e)
        setRoomData((prev: any) => {
            return {
                ...prev,
                isOutboundCalling: data
            }
        })
    }

    let timer1: any = null;
    const ringDurationHandle = (e: any, data: any) => {
        // console.log("-----timer", data)
        setRoomData((prev: any) => {
            return {
                ...prev,
                ringduraion: data
            }
        })
        // clearInterval(timer1)
        // timer1 = setTimeout(() => {
        //     var myData = roomData
        //     myData.ringDuration = data
        //     setRoomData(myData)
        //     //onSubmitForm()
        // }, 100)
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results) => {
                var myResults = results[0]
                var city = ""
                //var state = ""
                var country = ""
                var postalCode = ""
                var streetNumber = ""
                var streetName = ""
                var province = ""
                var emergencyLocation = ""

                getLatLng(myResults).then((latLong) => {
                    myResults.address_components.map((item) => {
                        if (item.types.includes("locality")) {
                            city = item.long_name
                        }
                        // if (item.types.includes("administrative_area_level_1")) {
                        //   state = item.long_name
                        // }
                        if (item.types.includes("country")) {
                            country = item.long_name
                        }
                        if (item.types.includes("postal_code")) {
                            postalCode = item.long_name
                        }
                        if (item.types.includes("street_number")) {
                            streetNumber = item.long_name
                        }
                        if (item.types.includes("route")) {
                            streetName = item.short_name
                        }
                        if (item.types.includes("administrative_area_level_1")) {
                            province = item.short_name
                        }
                        if (item.types.includes("emergencyLocation")) {
                            emergencyLocation = item.long_name
                        }

                    })
                    setRoomData(() => {
                        return {
                            ...roomData,
                            // //address: myResults.formatted_address,
                            // city: city,
                            // country: country,
                            // zipcode: postalCode,
                            // province: province,
                            //emergencyLocation: emergencyLocation
                            emergencyLocation: myResults.formatted_address
                        }
                    });
                })
            })
            .catch(error => console.error('Error', error));
    };

    const onChanePhoneNumber = (e: any) => {
        console.log('e', e)
        setRoomData((prev: any) => {
            return {
                ...prev,
                outboundId: e.phoneId
            }
        })
    }

    //phone numbers
    const [phnList, setPhnList] = React.useState<any>([]);
    const fetchPhoneNumbers = React.useCallback(() => {
        setLoading(true);
        axiosInstance.get('/phoneNumbers').then((res: any) => {
            // console.log('resph__', res)
            if (res.data.data) {
                const data = res.data.data[0].userNumbers
                // console.log('resph__', data)
                var List: any = [];
                data.map((item: any) => {
                    var object = {
                        "value": item.phone,
                        "label": item.phone,
                        'id': item._id
                    }
                    List.push(object)
                })
                setPhnList(List)
            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }, [])
    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [outboundVal, setOutboundVal] = React.useState<any>({})

    const getOutboundCallers = () => {
        axiosInstance.get('/phoneNumbers/outbound-caller-id').then((res: any) => {
            if (res.status === 200) {
                const data = res.data.data
                setOutBoundData(data)
                setOutboundVal({
                    "value": data[0].id,
                    "label": data[0].value,
                    "name": data[0].name,
                    "phoneId": data[0].phoneId

                })
            }
        }).catch((err: any) => {
            if (err.response?.status == 402) {
                // window.location.href = "http://bp.efone.ca/"
                // sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    var OutboundCallersList: any = [];
    outboundData?.map((item: any) => {
        var object = {
            "value": item?.id,
            "label": item?.value + "  (" + item?.name + ")",
            "name": item?.name,
            "phoneId": item?.phoneId
        }
        OutboundCallersList.push(object)
    })

    React.useEffect(() => {
        fetchPhoneNumbers()
        getDeptList()
        getOutboundCallers()
    }, [])

    const onChangeAudioFile = (e: any) => {
        const fileData = e.target.files;
        var file: any = fileData[0];
        // setFile(file)
        setFileBuffer(file)
        let reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setSource(reader.result)
        };

        setFileName(fileData[0]?.name)
        // onPostDataToServer(file, fileData[0]?.name)

    }
    const [resData, setResdata] = React.useState<any>({})

    const uploadFile = async (data: any) => {
        console.log("inside uploadFile")
        console.log("resData", resData)
        const id = data.id
        let formData = new FormData()
        formData.append('fileName', fileName)
        formData.append('file', fileBuffer)
        formData.append('fileType', '2')
        // formData.append('soundType', 'IvrGreeting')
        // formData.append('hoursType', '1')
        // formData.append('departmentId', depId)
        console.log(formData)
        await axiosInstance.post(`/deskphone/voicemail?roomPhoneId=${id}`, formData).then((res: any) => {
            console.log(res, "res")
            if (res.status === 200) {
                setFileName('')
                setSource('')
                // fetchAudioFiles()
                // SetLoading(true)
                // props.openHours()
            }
        })
    }

    const [addData, setAddData] = React.useState<any>()

    const addNumberToRoomphone = (editId:any) => {
        UserNumberServices.PostroomPhoneNumbers(addData, editId).then((res: any) => {
            if (res.success) {

            }
            else {

            }
        }).catch((err: any) => {
            // send error
        })

    }

    const selectedNumber = (num: any) => {
        console.log(num)
        setAddData(num)
    }


    const onSubmitdata = async () => {
        var sendData = {}
        if (roomData.forwardingRule === 1) {
            sendData = {
                name: roomData.name,
                ringduraion: roomData.ringduraion,
                forwardingRule: roomData.forwardingRule,
                emergencyLocation: roomData.emergencyLocation,
                outboundId: roomData.outboundId,
                isOutboundCalling: roomData.isOutboundCalling,
                macAddress: roomData.macAddress,
                // departmentId: roomData.departmentId,
                //    teamMemberId: roomData.teamMemberId

            }
        }
        if (roomData.forwardingRule === 2) {
            sendData = {
                name: roomData.name,
                ringduraion: roomData.ringduraion,
                forwardingRule: roomData.forwardingRule,
                emergencyLocation: roomData.emergencyLocation,
                outboundId: roomData.outboundId,
                isOutboundCalling: roomData.isOutboundCalling,
                macAddress: roomData.macAddress,
                // departmentId: roomData.departmentId,
                teamMemberId: roomData.teamMemberId

            }
        }
        if (roomData.forwardingRule === 3) {
            sendData = {
                name: roomData.name,
                ringduraion: roomData.ringduraion,
                forwardingRule: roomData.forwardingRule,
                emergencyLocation: roomData.emergencyLocation,
                outboundId: roomData.outboundId,
                isOutboundCalling: roomData.isOutboundCalling,
                macAddress: roomData.macAddress,
                departmentId: roomData.departmentId,
                // teamMemberId: roomData.teamMemberId

            }
        }

        await axiosInstance.post('deskphone/roomPhone', sendData).then((res: any) => {
            if (res.status === 200) {
                console.log(res.data.data)
                addNumberToRoomphone(res.data.data.id)
                if (res.data.data.forwardingRule === 1) {
                    console.log("inside fupload")
                    setResdata(res.data.data)
                    uploadFile(res.data.data)
                }
                props.fetchRoomPhones()
                props.handlePopupModal()
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
            }
        }).catch((res: any) => {
            toast.error(res.response.data.message, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 1000
            })
        })
    }
    const uploadFileClick = () => {
        // setUploadingFile(true)
        if (audioFileInput.current) {
            audioFileInput.current.click()
        }
    }
   

    return (
        <div>
            {
                openManagePhoneNumber && (
                    <div>
                        {/* <Backdrop handlePopupModal={handlePopupModalManagePhoneNumber} />
                        <Modal title="Manage Numbers" >
                            <div >
                                <ManageNumbers editId={props.editId} handlePopupModalEditdeskphone={props.handlePopupModalEditdeskphone} />
                            </div>
                        </Modal> */}

                        <Backdrop handlePopupModal={handlePopupModalManagePhoneNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalManagePhoneNumber} >
                            <AddEfoneNumber
                                //fetchNumberLists={fetchNumberLists}
                                editId={props.editId}
                                handlePopupModal={handlePopupModalManagePhoneNumber}
                                selectedNumber={selectedNumber}
                            />

                            {/* <AddEfoneNumber
                                fetchNumberLists={fetchNumberLists}
                                handlePopupModal={handlePopupModalAddNumber}
                            /> */}
                        </Modal>
                    </div>
                )
            }

            <div style={{ padding: '15px' }}>
                <Accordion expanded={openExpand} style={{ borderRadius: 5 }}
                    onChange={expandOpen}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    >
                        <Typography style={{ color: '#FFFFFF' }}>Name</Typography>
                        <Typography style={{ color: '#FFFFFF' }}>Skylinks Bovaid</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            fullWidth
                            placeholder='Skylinks Bovaid'
                            variant='outlined'
                            value={roomData.name}
                            name="name"
                            size='small'
                            onChange={handleChangeName}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={open}
                    onChange={expanding}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ color: '#FFFFFF' }}>Ring Duration & Forwarding</Typography>
                            <Typography style={{ color: '#FFFFFF' }}>45s ,To Message</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        <div>
                            <Typography>Choose how long this phone will ring before forwarding to Contact or
                                play a message (default 45s)
                            </Typography>
                            <div>
                                <div>
                                    <PrettoSlider
                                        key={`slider-${ringDurationTime.ringDurationTime}`}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={ringDurationTime.ringDurationTime}
                                        //onChange={ringHandlingChange}
                                        onChange={ringDurationHandle}
                                        min={0}
                                        max={30}
                                    // marks={marks}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    When your Phone Rings for ring for ring duration,call will sent:
                                </FormLabel>
                                <FormGroup aria-label="position" >

                                    <FormControlLabel
                                        // value="a"
                                        control={<Checkbox color="primary" checked={roomData.forwardingRule === 1} onChange={() => onChangeTextCheckBox()} />}
                                        label="To a Message(no voicemail)"
                                    />
                                    {roomData.forwardingRule === 1 &&
                                        <div>
                                            {/* <ReactSelect /> */}
                                            <p>{fileName}</p>
                                            <div style={{ display: 'flex', alignItems:'center',cursor: 'pointer', marginTop:'10px'}} onClick={uploadFileClick}>
                                                <GrUpload />
                                                <span style={{ color: '#4D72F8', fontWeight: 700 }}>UploadFile</span>
                                                <div style={{ display: 'none' }}>
                                                    <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <FormControlLabel
                                        // value="b"
                                        control={<Checkbox color="primary" checked={roomData.forwardingRule === 2} onChange={() => onChangeTeamemberCheckBox()} />}
                                        label="To Team Members room Phone  "
                                    />
                                    {roomData.forwardingRule === 2 &&
                                        (<ReactSelect options={teammembList} placeholder='Select Team Member'
                                            onChange={handleTeammemberSelection} />)
                                    }
                                    <FormControlLabel
                                        // value="c"
                                        control={<Checkbox color="primary" checked={roomData.forwardingRule === 3} onChange={() => onChangedeptCheckBox()} />}
                                        label="To Department,office or call center"
                                    />
                                    {roomData.forwardingRule === 3 &&
                                        (<ReactSelect options={deptList} placeholder='Select Department' onChange={handleDeptSelection} />)
                                    }
                                </FormGroup>
                            </FormControl>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={opeing}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                    onChange={expanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}

                    >
                        <Typography style={{ color: '#FFFFFF' }}>Registered Emergency Sevices Location</Typography>
                        <Typography style={{ color: '#FFFFFF' }}></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        <Typography
                            style={{ color: '#A5A5A5', marginTop: '10px' }}
                        >
                            This Address will be used in the event of Emergency call;
                        </Typography>
                        {/* <TextField style={{ marginTop: '20px' }}
                        fullWidth
                        placeholder='253 QUEEN STE,BRAMPTON,ON LWGD'
                        variant='outlined'
                    /> */}
                        <PlacesAutocomplete
                            value={roomData.emergencyLocation ? roomData.emergencyLocation : ""}
                            // name="emergencyLocation"
                            onChange={handleChange}
                            onSelect={handleSelect}

                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                                <div>
                                    {/* <input
                                            style={{
                                            width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                                            borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                                            border: "1px solid #3f51b5"
                                            }}
                                            {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                            })}
                                        /> */}
                                    <TextField
                                        style={{ marginTop: '12px' }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        //error={!errors.address.isValid}
                                        {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    {/* {
                                    !errors.address.isValid &&
                                    <p className={classes.errorMessage}>{errors.address.message}</p>
                                } */}
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion: any) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={openClose}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                    onChange={expandClose}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}

                    >
                        <Typography style={{ color: '#FFFFFF' }}>Outbound ID</Typography>
                        <Typography style={{ color: '#FFFFFF' }}>(906)354-887</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        {/* <TextField
                        style={{
                            marginTop: '20px',
                            color: '#A5A5A5'
                        }}
                        fullWidth
                        placeholder='skylinks Bovaid:(906)354-887'
                        variant='outlined'
                    /> */}
                        <ReactSelect

                            // value={OutboundCallersList.filter((opt: any) => {
                            //     return opt.value === outboundVal?.value
                            // })}
                            onChange={(e: any) => onChanePhoneNumber(e)}
                            options={OutboundCallersList}
                            // options={phnList}
                            // onChange={onChanePhoneNumber}
                            size="small"
                            placeholder='Select Outbound Caller ID'
                        />
                        <div style={{ marginTop: '20px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Outbound Calling
                                </FormLabel>
                                <RadioGroup
                                    aria-label="position" row
                                // onChange={onChangeRadio}
                                //name="isOutboundCalling"

                                >
                                    <FormControlLabel
                                        //value="isOutboundCalling"
                                        control={
                                            <Radio color="primary"
                                                name='isOutboundCalling'
                                                // value={roomData.isOutboundCalling}
                                                checked={roomData.isOutboundCalling === true}
                                                onChange={() => onChangeRadio(true)}

                                            />}
                                        label="Enable"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                name='isOutboundCalling'
                                                // value={roomData.isOutboundCalling}
                                                checked={roomData.isOutboundCalling === false}
                                                color="primary"
                                                onChange={() => onChangeRadio(false)}
                                            />}
                                        label="Disable"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={openExpand} style={{ borderRadius: 5, marginTop: '20px' }}
                    onChange={expandOpen}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    >
                        <Typography style={{ color: '#FFFFFF' }}>MAC Address</Typography>
                        {/* <Typography style={{ color: '#FFFFFF' }}>Skylinks Bovaid</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            fullWidth
                            placeholder='Enter MAC Address'
                            variant='outlined'
                            value={roomData.macAddress}
                            name="macAddress"
                            size='small'
                            onChange={handleChangemacaddress}
                        />
                    </AccordionDetails>
                </Accordion>

                <div>
                    <Button
                        size="small"
                        variant='outlined'
                        className={classes.addNumber}
                        onClick={handlePopupModalManagePhoneNumber}
                    >Add Number</Button>
                </div>

                <div style={{ display: 'flex', gap: '20px', marginLeft: '180px', marginTop: '50px' }}>
                    <Button onClick={props.handlePopupModal} variant='outlined' style={{ background: 'gray', color: 'white', padding: '7px 20px' }}>Cancel</Button>
                    <Button onClick={onSubmitdata} variant='outlined' style={{ background: 'blue', color: 'white', padding: '7px 20px' }}>Done</Button>
                </div>
            </div>
        </div>

    )
}

export default AddDeshphone

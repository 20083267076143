import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
 
  textField: {
    marginTop: 25,
  },
  textColor: {
    color: '#9A9A9A',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    margin: '0 8px'
  },
  textColorOne: {
    margin: 7,
    color: '#707070',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 17,
  },
  submitButton: {
    margin: 10,
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: "6px 22px",
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    margin: 10,
    padding: "6px 22px",
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    marginLeft: 15,
    background: '#EFEFEF',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#787878',
    fontSize: 14,
  },
  padding: {
    padding: 15,
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  card: {
    marginTop: -33,
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
    cursor: 'pointer'
  },
  divider: {
    marginTop: -10,
    backgroundColor: '#E2E2E2',
  },
  gridJustify: {
    marginTop: '40px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    marginBottom: 20,
    "@media (max-width: 1024px)": {
      gridTemplateColumns: 'auto auto auto',
    },
    "@media (max-width: 426px)": {
      gridTemplateColumns: 'auto auto',
      display: 'grid'
    }
  },
  heading: {
    color: "#4D72F8",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  accordianNoFlex: {
    display: 'block'
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  cardWidth: {
    display: 'flex',
    justifyContent: 'center',
    height: '90px',
    width: '134px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: '#4D72F8',
  },
  cardWidth1: {
    display: 'flex',
    justifyContent: 'center',
    height: '90px',
    width: '134px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: 'FFFFFF',
  },
  align: {
    textAlign: 'center',
  },
  deviceWidth: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#8392AB',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },
  deviceWidth1: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#4D72F8',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },

  devicesText: {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#FFFFFF',
    letterSpacing: 0.208955,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    }
  },
  devicesText1: {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#828282',
    letterSpacing: 0.208955,
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    }
  },
  headTexts: {
    fontSize: 17,
    marginTop: 20,
    marginLeft: 20,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#A0A0A0',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  tablecolor: {
    backgroundColor: '#F1F1F1',
  },
  tableHead: {
    fontSize: 16,
    color: '#464646',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  tableBody: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    color: '#787878',
  },
  addNewButton: {
    borderRadius: 50,
    border: '1px solid #CDCDCD',
    padding: '5px 10px',
    textTransform: 'capitalize',
    textAlign: 'center',
    // marginRight: 30,
    background: '#FAFAFA',
    boxShadow: '0px 2px 10px rgba(0, 76, 222, 0.25)',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      height: 39,
      width: 115,
    }

  },
  ringHandle: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: '0',
  },
  menuBody: {
    zIndex: 300
  },
  divStyles: {
    border: '1px solid lightgrey',
    background: '#FFFFFF',
    boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
    margin: '20px 60px 0px 20px',
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  }
}));

export const PrettoSlider = withStyles({
  root: {
    color: '#4D72F8',
    width: '75%',
    marginLeft: 10,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',

    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 14,
  },
  rail: {
    height: 10,
    borderRadius: 14,
  },

})(Slider);

import { Grid, Select, TextField,Button } from '@material-ui/core'
import React from 'react'
import ReactSelect from 'react-select'
import axiosInstance from '../../../environment/axiosInstance'
import { useStyles } from './style'

function SearchFilter(props:any) {
  const classes = useStyles()
  const[usersList,setUsersList] =React.useState([])
    const [AddPhoneNumberData, setAddphoneNumberData] = React.useState({
        "phoneNumber": "",
        "assignedTo": "",
        "status": "1"
    })
  const [status] = React.useState<any>([
        { value: 1, label: "Active" },
        { value: 2, label: "InActive" }
    ])

     const handleChange = (e: any) => {
        props.onChangeSearchData('status', e.value)
    }
     const handleChangePhoneNumber = (e: any) => {
        props.onChangeSearchData(e.target.name, e.target.value)
    }
    const handleChangeassignTo = (e:any) => {
        props.onChangeSearchData('assignedTo', e.value)
    }

     const getUesr = () => {
        axiosInstance.get('/admin/business-accounts').then((res:any) =>{
            if(res.data){
                const list : any = []
                res.data.data.map((item:any, i:any)=> {
                    var obj = {
                        value : item.id,
                        label : item.firstName + ' ' + item.lastName
                    }
                    list.push(obj)
                })
                setUsersList(list)
            }
        })
    }
     React.useEffect(()=>{
        getUesr()
    },[])

    const onContentAsignChangeEvent = (e:any) => {
         setAddphoneNumberData((prev: any) => {
            return {
                ...AddPhoneNumberData,
                assignedTo: e.value 
            }
        })
     }



  return (
    <div>
      <div style={{ marginTop: "15px" }}>
            <Grid container spacing={2}>
                <Grid item lg={2} md={3} sm={6} xs={12} >
                    <TextField 
                        size='small'
                        variant='outlined'
                        fullWidth
                        placeholder='Phone number'
                        value={props.searchData.phone}
                        name="phone"
                        onChange={handleChangePhoneNumber}
                    />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12} >
                    <ReactSelect
                        placeholder="Assigned to "
                        name="assignedTo"
                        value={
                            usersList.filter((option: any) =>
                                option.value === props.searchData.assignedTo)
                        }
                        onChange={handleChangeassignTo}
                        options={usersList} 
                      />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <ReactSelect
                        placeholder="Select Status"
                        name="status"
                        value={
                            status.filter((option: any) =>
                                option.value === props.searchData.status)
                        }
                        onChange={handleChange}
                        options={status} 
                      />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.searchButton}
                            onClick={props.searchPhoneNumber}
                        >
                            Search
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.clearButton}
                            onClick={props.clearFilter}
                        >
                            Clear
                        </Button>
                    </div>

                </Grid>
            </Grid>

        </div>
    </div>
  )
}

export default SearchFilter
import { takeEvery } from 'redux-saga/effects'
import { AuthActions, initProfileData, userHoursAction, userDepartmentsAction } from '../Actions/Actions'
import { getUserDepartments } from '../Actions/userActionCreator'
import { 
    init_auth_saga, 
    init_auth_status_check, 
    init_auth_timeout, 
    init_auth_logout, 
    init_refresh_token,
    init_auth_saga_LoginAs 
} from './AuthSaga'
import { 
    init_profile_data, 
    init_openhours_data, 
    init_closed_data, 
    init_unanswered_data,
    init_users_departments 
} from './profileDataSaga'

export default function* indexSaga(){
    yield takeEvery(AuthActions.INIT_AUTH, init_auth_saga)
    yield takeEvery(AuthActions.INIT_AUTH_LOGIN_AS, init_auth_saga_LoginAs)
    yield takeEvery(AuthActions.INIT_AUTH_TIMEOUT, init_auth_timeout)
    yield takeEvery(AuthActions.INIT_AUTH_STATUS, init_auth_status_check)
    yield takeEvery(AuthActions.INIT_AUTH_LOGOUT, init_auth_logout)
    yield takeEvery(AuthActions.INIT_REFRESH_TOKEN, init_refresh_token)
    yield takeEvery(initProfileData.INIT_PROFILE_DATA, init_profile_data)
    yield takeEvery(userHoursAction.INIT_OPEN_HOURS, init_openhours_data)
    yield takeEvery(userHoursAction.INIT_CLOSED_HOURS, init_closed_data)
    yield takeEvery(userHoursAction.INIT_UNANSWRED_HOURS, init_unanswered_data)
    yield takeEvery(userDepartmentsAction.USER_DEPARTMENT, init_users_departments)
}
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
    
    {
        editmodal: {
            marginTop: -20, marginBottom: 17, height: 600, overflowY: 'scroll',
            [theme.breakpoints.down('xs')]: {
                height: 500,
                width: 380,
                marginTop: -10
            }
        },
        ivrOptions: {
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            color: '#FFFFFF',
            border: '0px',
            background: '#4D72F8',
            outline: 'none',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '18px',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            paddingLeft: '2px',
            [theme.breakpoints.down('xs')]: {
                width: '30px',
                height: '30px',
                fontSize: "13px",
            }
        },
        accordianNoFlex: {
            display: 'block'
        },
        heading: {
            fontFamily: 'Calibri ! important',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: "24px",
            // lineHeight: "29px",  
            color: '#646464',
            [theme.breakpoints.down('xs')]: {
                fontSize: "16px",
            }
        },
        actionline: {
            fontFamily: 'Arial ! important',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '19px',
            // lineHeight: '22px',
            color: "rgba(107, 107, 107, 0.86)",
            marginTop: '5px',
            marginBottom: '20px',
            [theme.breakpoints.down('xs')]: {
                fontSize: "13px",
            }
        },
        tab: {
            textTransform: "capitalize",
            background: '#E4E4E4 ! important',
            color: '#A1A1A1 ! important',
            boxSizing: "border-box",
            fontFamily: 'Arial ! important',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 20,
            // width: '315px',
            // height: '52px',
            letterSpacing: '0.02em',
            borderRadius: '2px',
            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 25,
                fontSize: 13,
            }
        },
        tabs: {
            background: "#FFFFFF",
            // borderRadius: "7.51286px",
            // boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: '0px'
        },
        tabActive: {
            background: '#4D72F8 ! important',
            color: '#FFFFFF ! important',
            boxSizing: "border-box",
            fontFamily: 'Arial ! important',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 20,
            textTransform: "capitalize",
            // width: '315px',
            // height: '52px',
            letterSpacing: '0.02em',
            borderRadius: '2px',
            [theme.breakpoints.down('xs')]: {
                width: 150,
                height: 25,
                fontSize: 13,
            }
        },
        greetMainSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '988px',
            height: '200px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
            borderRadius: '12.1816px',
            paddingRight: '15px',
            paddingTop: '10px',
            paddingLeft: '25px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '35px',
            [theme.breakpoints.down('xs')]: {
                width: '340px',
                height: '150px',
                marginLeft: '0px',
            },
        },
        audioContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },
        audioPlayers: {
            width: '597px ! important',
            height: '44px',
            // backgroundColor: "#E9ECED ! important",
            border: '1px solid #F0F3F5',
            borderRadius: '5px',
            background: '#F0F3F5',
            [theme.breakpoints.down('xs')]: {
                width: '240px ! important',
                height: '38px',
                marginRight: 10
            }
        },
        filename: {
            fontfamily: 'Calibri',
            fontstyle: 'normal',
            fontweight: 400,
            fontsize: '14px',
            color: '#848282',
        },
        editbtn: {
            marginRight: '230px',
            fontFamily: 'Calibri ! important',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '15px',
            color: '#FFFFFF',
            padding: '7px 25px',
            // width: "107px",
            // height: "40px",
            // left: "16px",
            // top: "9px",
            // right: '10px',
            background: "#4D72F8",
            borderRadius: "5px",
            textTransform: 'initial',
            "&:hover": {
                backgroundColor: '#4D72F8'
            },
            [theme.breakpoints.down('xs')]: {
                marginRight: '0px',
                padding: '5px',
                fontSize: '12px',
            }
        },
        saveAsdefaultBtn: {
            width: '170px',
            height: '42px',
            background: '#4D72F8',
            borderRadius: '3px',
            color: "#FFFFFF",
            textTransform: 'capitalize'
        },
        uploadContainer: {
            cursor: 'pointer',
            padding: '10px',
            borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
        },
        minutes: {
            fontFamily: 'Work Sans ! important',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            color: '#8F8E8E',
            marginTop: '10px',
            lineHeight: '19px'
        },
        saveBtn: {
            width: '93px',
            height: '35px',
            background: '#4D72F8',
            borderRadius: '3px',
            color: "#FFFFFF",
            textTransform: 'capitalize'
        },
        dFlex: {
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        },
        audioTrack: {
            width: '500px', background: '#E9ECED'
        },
        audioTrackActive: {
            width: '500px', background: 'rgba(118, 144, 238, 0.5)',
        },
        selected: {
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            background: 'rgba(118, 144, 238, 0.5)',
            border: '2px solid #4D72F8',
            borderRadius: '4px'
        },
        unSelected: {
            display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
            border: '1px solid #F0F3F5'
        },
        ivrmenu: {
            fontFamily: 'Calibri ! important',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            color: '#646464',
            marginLeft: '45px',
            marginBottom: '0px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '18px',
            }
        },
        tableContainer: {
            width: '90%',
            boxShadow: '0px 0px 0px',
            borderRadius: 0,
            borderColor: '#E2E2E2',
            overflow: 'unset',

        },
        table: {
            tableLayout: 'fixed',
            marginLeft: '35px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '25px',
            }
        },
        tableHead: {
            backgroundColor: '#FFFFFF',
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px'
            }
        },
        firstdropdown:{
            [theme.breakpoints.down('xs')]: {
                width: 95,
               
            }
        },
        width90: { width: '90%' ,
        [theme.breakpoints.down('xs')]: {
            width: 95,
            marginLeft:5
        }
    },
        tableCellRow: {
            // padding: 10,
            fontSize: 18,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: 0.217535,
            color: '#464646',
            border: '0px',
            [theme.breakpoints.down('xs')]: {
                fontSize: 13,
            }
        },
        tableCell: {
            fontSize: 18,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: 0.217535,
            color: '#464646',

        },
        tableCellDiv: {
            width: 35,
            height: 35,
            marginLeft: 25,
            paddingLeft: 12,
            paddingTop: 5,
            fontSize: 18,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: 0.21553,
            color: '#FFFFFF',
            border: '0px',
            borderRadius: '50px',
            background: '#4D72F8',
            outline: 'none'
        },
        tableCellBody: {
            padding: 10,
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: 0.21553,
            color: '#464646',
            border: '0px',
        },
        tableCellBody1: {
            padding: 10,
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: 0.21553,
            color: '#464646',
            border: '0px',
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,padding:5,
            }
        },
        tableDeleteIcon: {
            fontSize: 25,
            marginLeft: 40,
            color: '#C9C9C9',
            borderColor: '#F4F4F4',
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
            }
        },
        divider: {
            width: "100%",
            backgroundColor: '#D2D2D2',
        },
        upload: {
            width: 357
        },
        dflex: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        deviceWidth: {
            width: 35,
            height: 35,
            borderRadius: 5,
            color: '#4D72F8',
            backgroundColor: '#FFFFFF',
            padding: '7px',
            border: '1px solid #ebebeb',
            position: 'relative',
            left: 10,
        },
        width: { width: '70%', },
        addButton: {
            marginTop: 10,
            marginLeft: 20,
            borderRadius: 22,
            color: "#4D72F8",
            borderColor: 'e8eaf6',
            fontSize: 10,
            '&:hover': {
                color: 'indigo',
                borderColor: 'indigo',
            },
        }
    }
)


);
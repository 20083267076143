import { Card, Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Divider, Tooltip, TextField, TableContainer } from '@material-ui/core'
import React from 'react'
// import TimePicker from "react-time-picker";
import profileImg from '../../assets/images/profileImg.png'
import { useStyles } from './styles'
import { FcGoogle } from 'react-icons/fc'
import { BiLock } from 'react-icons/bi'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { AiOutlineDelete } from 'react-icons/ai'
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from "react-redux";
import profileServices from "./ProfileServices/ProfileServices";
import DatePicker from "react-datepicker";
import moment from 'moment';
import axiosInstance from '../../environment/axiosInstance'
import Backdrop from '../../components/Modal/Backdrop';
import Modal from '../../components/Modal/Modal';
import AddEfoneNumber from './AllNumbers/AddNumber/AddEfoneNumber'
import NewPassword from '../Header/Newpassword';

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function MyAccount() {
    const classes = useStyles()
    const profileDataStore: any = useSelector((state: any) => state.profileData);
    // console.log('profileDataState__',profileDataStore)
    const { profileData, loading } = profileDataStore;
    const [dndselect, setdndSelect] = React.useState(false);
    const [value, setValue] = React.useState("");
    const [startDate, setStartDate] = React.useState<any>();
    const [timevalue, settimevalue] = React.useState<any>();
    const [assignNumbers, setAssignNumbers] = React.useState<any>([]);
    const [editPasswordModal, seteditPasswordModal] = React.useState(false);
    const [deleteModal, setdeleteModal] = React.useState<any>(false);
    const [numberId, setNumberId] = React.useState<any>(false);
    const [profileDetails, setProfileDetails] = React.useState<any>({});
    // console.log("profileData",profileData)
    const [editProfileModal, seteditProfileModal] = React.useState<any>(false);
    const dndhandleChange = () => {
        setdndSelect(!dndselect);
        const userDatadndChange = { dndStatus: !dndselect };
        if (dndselect) {
            updateUserProfile(userDatadndChange);
        }

    };
    const Assignusers = () => {
        axiosInstance.get('/profile/assignedNumbers').then((res: any) => {
            // console.log('charan', res)
            if (res.status === 200) {
                setAssignNumbers(res.data.data[0].AssignedNumbers)
            }
        })
    }
    const getProfileDetails = () => {
        profileServices.getProfileData().then((res: any) => {
            setProfileDetails(res.data)
            setdndSelect(res.data.dndStatus)
            setValue(res.data.dndType.toString())
            const dt = parseInt(res.data.dndDate) * 1000
            setStartDate(dt)
            settimevalue(res.data.dndTime)
        })
    }
    React.useEffect(() => {
        Assignusers()
        getProfileDetails()
    }, [])
    const onChangeDateandTime = (date: any) => {
        const Dateandtime = moment(date, 'YYYY.MM.DD HH:MM:SS').unix();
        const dndDatetime = { dndDate: Dateandtime.toString(), dndStatus: dndselect };
        setStartDate(Dateandtime * 1000);
        updateUserProfile(dndDatetime);
        getProfileDetails()

    };
    const onChangeTime = (time: any) => {
        // console.log("time", time.target.value)
        const dndDatetime = { dndTime: time.target.value }
        updateUserProfile(dndDatetime);
        getProfileDetails()
    }
    const updateUserProfile = (updateData: any) => {
        profileServices
            .updateprofileData(updateData)
            .then((res: any) => {
                //to close input element
            })
            .catch((err) => { });
    };
    const handleChangeRadioGroup = (event: any) => {
        setValue(event.target.value);
        if (event.target.value === "3") {
            const untillTurnOn = {
                dndDate: "null",
                dndType: Number(event.target.value),
                dndStatus: dndselect
            };
            updateUserProfile(untillTurnOn);
        } else {
            updateUserProfile({ dndType: Number(event.target.value), dndStatus: dndselect });
        }
    };
    const [addNumberModal, setAddNumberModal] = React.useState(false);

    const handlePopupModalNumber = () => {
        setAddNumberModal(!addNumberModal)
    }

    const selectedNumber = async (num: any) => {
        //  console.log(num)
        const selected = num.data
        const data: any = {
            "assignedFor": 2,
            "userId": `${profileData.id}`

        }
        // console.log(data)
        await axiosInstance.patch(`/phoneNumbers/assign-phonenumber?phoneNumberId=${selected.id}`, data).then((res: any) => {
            // console.log(res)
            if (res.status === 200) {
                Assignusers()
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
            }
        }).catch((err) => {
            //send err
        })
    }
    const handleDeleteNumModal = () => {
        setdeleteModal(!deleteModal);
    }
    const handleEditPassword = () => {
        seteditPasswordModal(!editPasswordModal)
    }
    const handleDeleteAssignedNumbers = async (id: any) => {
        await axiosInstance.delete(`/profile/assignedNumbers?id=${id}`).then((res: any) => {
            // console.log(res)
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                handleDeleteNumModal()
                Assignusers()
            }
        }).catch((err) => {
            //send err
        })
    }


    const handleAssignedNumber = async (id: any) => {
        await axiosInstance.patch(`/profile/assignedNumbers?id=${id}`).then((res: any) => {
            // console.log(res)
            if (res.status === 200) {
                toast.success("Primary Number Assigned Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                Assignusers()
            }
        }).catch((err) => {
            //send err
        })
    }


    const handleDeleteOption = (id: any) => {
        setNumberId(id)
        handleDeleteNumModal()
    };

    const editProfileDetails = () => {
        seteditProfileModal(!editProfileModal)
    }

    return (
        <div>
            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeleteOption} />
                        <Modal title="Are you sure want to unAssign this Number ?" handlePopupModal={handleDeleteOption} >
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div style={{ margin: '10px' }}>
                                            <Button size="medium" onClick={() => handleDeleteAssignedNumbers(numberId)} variant='contained' style={{ marginRight: '5px', background: '#4D72F8', color: 'white', textTransform: 'none' }}>UnAssign</Button>
                                            <Button size="medium" onClick={handleDeleteNumModal} variant='contained' style={{ marginLeft: '5px', background: '#4D72F8', color: 'white' }}>Cancel</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div >
                )
            }
            {
                addNumberModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalNumber} >
                            <div className="add_user_popup" >
                                <AddEfoneNumber
                                    handlePopupModal={handlePopupModalNumber}
                                    selectedNumber={selectedNumber}
                                    Assignusers={Assignusers}

                                />
                            </div>
                        </Modal>
                    </div>
                )
            }
            {
                editPasswordModal && (
                    <div>
                        <Backdrop handlePopupModal={handleEditPassword} />
                        <Modal title="Change Password" handlePopupModal={handleEditPassword} >
                            <div className="add_user_popup" style={{ width: 650 }}>
                                <NewPassword />
                                {/* <AddEfoneNumber
                                    handlePopupModal={handlePopupModalNumber}
                                    selectedNumber={selectedNumber}
                                    Assignusers={Assignusers}

                                /> */}
                            </div>
                        </Modal>
                    </div>
                )
            }
            {
                editProfileModal && (
                    <div>
                        <Backdrop handlePopupModal={editProfileDetails} />
                        <Modal title="Edit Details" handlePopupModal={editProfileDetails} >
                            <div style={{ width: 450, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <TextField size='small' value={profileDetails?.firstName} variant="outlined" />
                                <TextField style={{ marginTop: 8 }} size='small' value={profileDetails?.email} variant="outlined" />
                                <Button
                                    style={{color:'white',textTransform:'capitalize',
                                             marginTop: 8, 
                                             background:'linear-gradient(180deg, rgba(78, 114, 228, 0.77) 0%, #735DC1 100%)'}}
                                    size='small' variant='outlined'>Save Changes</Button>
                            </div>
                        </Modal>
                    </div>
                )
            }

            <div>
                <Card className={classes.cardStyle}>
                    <div style={{ float: 'right' }}>
                        <IconButton className={classes.icons}>
                            <MdOutlineModeEditOutline onClick={editProfileDetails} />
                        </IconButton>
                    </div>
                    <div className={classes.dFlx}>
                        <div>
                            <img src={profileImg} alt="profile" width="70px" height="70px" />
                        </div>
                        <div>
                            <Typography className={classes.name}>{profileDetails?.firstName} {profileDetails?.lastName}</Typography>
                            <Typography className={classes.email}>{profileDetails?.email}</Typography>
                        </div>
                    </div>
                    <div style={{ marginTop: 30 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <div className={classes.divFlex}>
                                    <Button
                                        className={classes.password}
                                        startIcon={<BiLock />}
                                        onClick={handleEditPassword}
                                    >
                                        Change Password
                                    </Button>
                                    {/* <Button className={classes.passwords}
                                        startIcon={<FcGoogle />}
                                    >
                                        Login with Google
                                    </Button> */}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
                <div>
                    <Grid container spacing={2}>
                        {/* <Grid item md={4} sm={6} xs={12}>
                            <Card className={classes.card}>
                                <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tRow}>
                                            <TableCell className={classes.tHead}>Type</TableCell>
                                            <TableCell className={classes.tHead}>Email</TableCell>
                                            <TableCell className={classes.tHead}>Password</TableCell>
                                            <TableCell className={classes.tHead}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tBdy}>charan</TableCell>
                                            <TableCell className={classes.tBdy}>saic4234@gmail.com</TableCell>
                                            <TableCell className={classes.tBdy}>********</TableCell>
                                            <TableCell className={classes.tBdy}>
                                                <BsThreeDots style={{ fontSize: 15 }} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tBdy}>Google</TableCell>
                                            <TableCell className={classes.tBdy}>Test@gmail.com</TableCell>
                                            <TableCell className={classes.tBdy}>********</TableCell>
                                            <TableCell className={classes.tBdy}>
                                                <BsThreeDots style={{ fontSize: 15 }} />
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </Card>
                        </Grid> */}
                        <Grid item md={4} sm={6} xs={12}>
                            <Card className={classes.cardTwo}>
                                <div className={classes.headDiv}>
                                    <Typography className={classes.number}>Assigned Numbers</Typography>
                                    <Button
                                        className={classes.addBtn}
                                        startIcon={<BiLock style={{ fontSize: 15 }} />}
                                        onClick={handlePopupModalNumber}
                                    >
                                        Add new
                                    </Button>
                                </div>
                                <Divider style={{ marginTop: 2 }} />
                                <div style={{ padding: 15 }}>
                                    {
                                        assignNumbers.map((item: any) => {
                                            return (
                                                <>
                                                    <FormControl component="fieldset">
                                                        <div>
                                                            <div style={{ marginTop: -20, gap: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 30px 0px 5px' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Radio
                                                                        size='small'
                                                                        checked={item.isPrimary}
                                                                        color="primary"
                                                                        onChange={() => handleAssignedNumber(item._id)}
                                                                    />
                                                                    <Typography>{item.phone}</Typography>
                                                                    {(item.isPrimary) &&
                                                                        <Typography style={{ marginLeft: 8, color: 'blue' }}>(Primary)</Typography>
                                                                    }

                                                                </div>
                                                                {!(item.isPrimary) &&
                                                                    <div>
                                                                        <AiOutlineDelete style={{ marginTop: 14, color: '#BB271A', marginLeft: 110 }}
                                                                            onClick={() => handleDeleteOption(item._id)} />

                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </FormControl>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                {/* <div style={{marginTop:-20,display:'flex',alignItems:'center',justifyContent:'space-between',padding:'0px 30px 0px 5px'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <Radio size='small' />
                                    <Typography>{}</Typography>
                                    <Typography>(Primary)</Typography>
                                </div>
                                <div>
                                    <AiOutlineDelete style={{ marginTop: 14, color: '#BB271A', marginLeft: 110 }} />
                                </div>
                            </div> */}

                            </Card>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Card className={classes.cardTwo}>
                                <div className={classes.headDiv}>
                                    <Typography className={classes.number}>Do Not Disturb</Typography>
                                    <Switch
                                        checked={dndselect}
                                        onChange={dndhandleChange}
                                        color="primary"
                                    />
                                </div>
                                <Divider style={{ marginTop: -9 }} />
                                <div style={{ padding: 15 }}>
                                    {dndselect && (
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                //name="gender1"
                                                value={value}
                                                onChange={handleChangeRadioGroup}
                                            >
                                                <div className={classes.dataPicker}>
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio size='small' />}
                                                        label="dnd Upto Time and Date"
                                                    />
                                                    <div className="date_and_time_picker_section">
                                                        {value === "1" && (
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={onChangeDateandTime}
                                                                timeInputLabel="Time:"
                                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                                showTimeInput
                                                                className="date_and_time_pickers"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio size='small' />}
                                                        label="dnd for hours"
                                                    />
                                                    <div className="date_and_time_picker_section">
                                                        {
                                                            value === "2" && (
                                                                <input type='time'
                                                                    value={timevalue}
                                                                    onChange={onChangeTime}
                                                                    className="date_and_time_pickers"
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <FormControlLabel
                                                    value="3"
                                                    control={<Radio size='small' />}
                                                    label="dnd untill I turned off"
                                                />


                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                </div>
                            </Card>
                        </Grid>
                        <Grid item md={4} >
                            <div style={{ marginTop: 40 }}>
                                <a href="javascript:void(0)" data-billsby-type="checkout">Subscribe</a>
                                {/* <script src="https://checkoutlib.billsby.com/checkout.min.js" data-billsby-company="ovvision"></script> */}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>

    )
}

export default MyAccount
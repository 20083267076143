import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CircularProgress, ClickAwayListener, Divider, Grid, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useStyles } from './styles'
import { AiOutlinePlusCircle, AiTwotoneDelete } from 'react-icons/ai'
import { BiEdit, } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import { useLocation, Redirect} from 'react-router-dom'
import axiosInstance from '../../environment/axiosInstance'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MoreHorizRounded } from '@material-ui/icons'
import { MdAddBox } from 'react-icons/md'
import Backdrop from '../../components/Modal/Backdrop';
import Modal from '../../components/Modal/Modal';
import AddNumber from './AddNumberModal/AddNumberModal';
import DepartmentsNumberBody from './DepartmentsNumberBody';
import AddEfoneNumber from './AllNumbers/AddNumber/AddEfoneNumber';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
toast.configure()

function DepartmentsNumbers(props: any) {

    const classes = useStyles()
    const location = useLocation()
    const [loading, setloading] = React.useState(false)
    const [deleteModal, setdeleteModal] = React.useState<any>(false);
    const [deleteDepartmentModal, setDeleteDepartmentModal] = React.useState<any>(false);
    const [selectedPhoneId, setSelectedPhoneId] = React.useState<any>();
    const [expanded, setExpanded] = React.useState<any>(true);
    const departmetStateData = useSelector((state: any) => state?.departmentModule);
    const params = useParams()
    console.log("deptid",params)
    const obj: any = params
    const [name, setName] = React.useState<any>();



    React.useEffect(() => {
        if (obj.id) {
            departmetStateData?.departmentsData?.map((item: any) => {
                if (item.id === obj.id) {
                    setName(item.name)
                }
            })
        }

    }, [departmetStateData, obj.id])

    const [selectAlwaysOpen, setselectAlwaysOpen] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const expandOpen = () => {
        setOpen(!open);
        setExpanded(true);
    }
    const [departmentNumbers, setDepartmentNumbers] = React.useState<any>([])

    const fetchNumbers = () => {
        setloading(true)
        axiosInstance.get(`/departments/${obj.id}/phone-number`).then((res: any) => {
            //    console.log(res)
            if (res.status === 200) {
                setloading(false)
                setDepartmentNumbers(res.data)
            }
        }).catch((err) => {
            //send err
        })
    }
    React.useEffect(() => {
        if (obj.id) {
            fetchNumbers()
        }
    }, [obj.id])




    const handleDelete = () => {
        setdeleteModal(!deleteModal);
    }
    const handleDeleteDepartment = () =>{
        setDeleteDepartmentModal(!deleteDepartmentModal)
    }
    const handleDeleteOption = (id: any) => {
        setSelectedPhoneId(id)
        handleDelete()
    };

    const handleDeleteDepartmenttab  = async () => {
        await axiosInstance.delete(`/departments/${props.match.params.id}/delete-department`).then((res: any) => {
            // console.log("handleDeletePhoneNumber", res)
            if (res.status === 200) {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                handleDeleteDepartment()
            }
        }).catch((err) => {
            //send err
        })
    }

    const selectedNumber = async (num: any) => {
        // console.log(num)
        const selected = num.data
        const data: any = {
            "assignedFor": 1,
            "departmentId": `${props.match.params.id}`

        }
        // console.log(data)
        await axiosInstance.patch(`/phoneNumbers/assign-phonenumber?phoneNumberId=${selected.id}`, data).then((res: any) => {
            //    console.log(res)
            if (res.status === 200) {
                setloading(false)
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                fetchNumbers()
            }
        }).catch((err) => {
            //send err
        })
    }
    const handleDeletePhoneNumber = async () => {
        await axiosInstance.delete(`/departments/${props.match.params.id}/phone-number?id=${selectedPhoneId}`).then((res: any) => {
            // console.log("handleDeletePhoneNumber", res)
            if (res.status === 200) {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                handleDelete()
                fetchNumbers()
            }
        }).catch((err) => {
            //send err
        })
    }
    // console.log(departmentNumbers)

    // React.useEffect(()=> {n

    //     localStorage.setItem('departmentUrl',location.pathname)

    // }, [location.pathname])

    // const departmentUrl : any = localStorage.getItem('departmentUrl')
    // console.log('departmentUrl__',departmentUrl)
    // if(departmentUrl !== undefined && departmentUrl !== "" && departmentUrl !== '/department'){
    //     return <Redirect to={departmentUrl} />

    // }
    const [addNumberModal, setAddNumberModal] = React.useState(false);

    const handlePopupModalNumber = () => {
        setAddNumberModal(!addNumberModal)
    }

    const [editName, seteditName] = React.useState(false);

    const handleEditname = (e: any) => {
        setName(e.target.value)
    }
    const handleDptnameEdit = () => {
        seteditName(!editName)
    }

    const handleDptnameEditApi = async () => {
        const data: any = {
            "name": name,
        }
        await axiosInstance.patch(`/departments?id=${obj.id}`,data).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                handleDptnameEdit()
            }else{
                toast.error(res.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            }
        }).catch((err) => {
            if (err.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 });
            } else if (err.request) {
                // The request was made but no response was received
                toast.error("No response received from the server", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 });
            } else {
                // Something happened in setting up the request that triggered an Error
                toast.error(err.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 });
            }
        })  
    }
    return (
        <div>
            {
                addNumberModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalNumber} >
                            <div className="add_user_popup" >
                                <AddEfoneNumber handlePopupModal={handlePopupModalNumber}
                                    selectedNumber={selectedNumber} />
                            </div>
                        </Modal>
                    </div>
                )
            }

            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeleteOption} />
                        <Modal title="Are you sure want to UnAssign this Number?" handlePopupModal={handleDeleteOption} >
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div style={{ margin: '10px' }}>
                                            <Button size="medium" onClick={handleDeletePhoneNumber} variant='contained' style={{ marginRight: '5px', background: '#4D72F8', color: 'white' }}>UnAssign</Button>
                                            <Button size="medium" onClick={handleDeleteOption} variant='contained' style={{ marginLeft: '5px', background: '#4D72F8', color: 'white' }}>Cancel</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div >
                )
            }

            {
                deleteDepartmentModal && (
                    <div>
                    <Backdrop handlePopupModal={handleDeleteDepartment} />
                    <Modal title="Are you sure want to delete this Department?" handlePopupModal={handleDeleteDepartment} >
                        <div className="add_user_popup" style={{ width: "500px" }}>
                            <Grid container spacing={2}>
                                <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                    <div style={{ margin: '10px' }}>
                                        <Button size="medium" onClick={handleDeleteDepartmenttab} variant='contained'style={{ marginRight: '5px', background: '#4D72F8', color: 'white' }}>Delete</Button>
                                        <Button size="medium" onClick={handleDeleteDepartment} variant='contained' style={{ marginLeft: '5px', background: '#4D72F8', color: 'white' }}>Cancel</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Modal>
                </div >
                )
            }

            <Card className={classes.card}>
                <Accordion
                    expanded={expanded}
                    onChange={expandOpen}
                >
                    {/* <AccordionSummary
                        //expandIcon={expanded ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                        
                    > */}
                        {/* <div className={classes.dflex}>
                                <Typography className={classes.heading}>{name}</Typography>
                                <BiEdit style={{ color: 'lightgrey' }} />
                            </div> */}
                        <Grid style={{ marginLeft: 25 }}>
                            <div style={{display:"flex",justifyContent:"space-between"}}>

                           
                            {
                                editName ?
                                    <div className={classes.dflex} style={{marginTop: 20}}>
                                        <div>
                                            {/* <TextField value={name} variant='outlined' size='small'
                                            onChange={handleEditname} /> */}
                                            <input type='text' value={name} onChange={handleEditname} />
                                        </div>
                                        <div>
                                            <BiEdit className={classes.editPencilIcon} onClick={handleDptnameEdit} />
                                        </div>
                                        <div>
                                            <CheckCircleRoundedIcon className={classes.editcheckIcon} onClick={handleDptnameEditApi} />
                                        </div>
                                    </div>
                                    :
                                    <div className={classes.dflex} style={{marginTop: 20}}>
                                        <h1 className={classes.heading}>{name}</h1>
                                        <Tooltip title="Edit Department Name">
                                            <Grid>
                                                <BiEdit className={classes.editPencilIcon} onClick={handleDptnameEdit} />
                                            </Grid>
                                        </Tooltip>
                                    </div>

                            }
                           <Button size="medium" onClick={handleDeleteDepartment} variant='contained' style={{ marginRight: '10px', background: '#4D72F8', color: 'white',marginTop: 20,height:"39px" }}>Delete</Button>
                             </div>
                        </Grid>

                    {/* </AccordionSummary> */}
                    <Divider className={classes.divider} />
                    <div className={classes.dflex}>
                        <Typography className={classes.callFlow}>Call Flow</Typography>
                        <BiEdit style={{ color: 'lightgrey', marginTop: 30 }} />
                    </div>
                    <div>
                        <Grid item md={12}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 50 }}>
                                <Typography className={classes.departmentheading}>Department Number(s)</Typography>
                                <AiOutlinePlusCircle style={{ fontSize: 20, color: 'rgb(77, 114, 248)' }}
                                    onClick={handlePopupModalNumber}
                                />
                            </div>
                        </Grid>
                    </div>
                    <Divider style={{ marginTop: 8 }} />
                    <div>
                        <Grid container spacing={2}>
                            <Grid item md={4} style={{ marginLeft: 16, marginTop: 30, marginBottom: 30 }}>
                                <TableContainer style={{ border: '1px solid #F1F1F1', borderRadius: 5 }}>
                                    <Table>
                                        <TableHead>

                                            <TableRow className={classes.tRow}>
                                                <TableCell className={classes.tCell}>Numbers</TableCell>
                                                <TableCell className={classes.tCell}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loading ?
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginLeft: 90, marginTop: 20, marginBottom: 10 }}>
                                                    <CircularProgress />
                                                </Box>
                                                :
                                                departmentNumbers.length > 0 ?
                                                    departmentNumbers?.map((item: any) => {
                                                        // const no = item?.phone
                                                        // const newNo = `(${no?.slice(0, 3)}) ${no?.slice(3)}`
                                                        return (

                                                            <DepartmentsNumberBody data={item} handleDeleteOption={handleDeleteOption} />
                                                        )
                                                    })

                                                    :
                                                    (<TableRow>
                                                        <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                                            No Department Numbers Found
                                                        </TableCell>

                                                    </TableRow>)

                                            }


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item md={6}></Grid>
                        </Grid>
                    </div>
                </Accordion>
            </Card>
        </div >
    )
}

export default DepartmentsNumbers
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import TableBodyData from './TableBodyData';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import AddEfoneNumber from './AddNumber/AddEfoneNumber';
import UserNumbersServices from '../userNumbersServices/usersNumbersServices'
import axiosInstance from '../../../../environment/axiosInstance'
import { Pagination, Stack } from '@mui/material';
import { useHistory } from "react-router-dom";


function AllNumbers() {
  const classes = useStyles()
  const history = useHistory()
  const [openExpand, setOpenExpand] = React.useState(true);
  const [openModalAdduser, setopenModalAdduser] = React.useState(false);
  const [numberListData, setNumberListData] = React.useState<any>([])
  const [deptData, setDeptData] = React.useState([])
  const [deptNumber, setDeptNumbers] = React.useState([])
  const [userList, setUsersList] = React.useState([])
  const [userNumbers, setUserNumbers] = React.useState([])
  const [unAssignNumbers, setUnAssignNumbers] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [paginate, setPaginate] = React.useState({
    page: 1,
    totalDocs: 0,
    limit: 10,
    totalPages: 0
  })
  const expandOpen = () => {
    setOpenExpand(!openExpand)
  }

  const handlePopupModalAddNumber = () => {
    setopenModalAdduser(!openModalAdduser)
  }

  const fetchNumberLists = () => {
    UserNumbersServices.getNumbersList().then((res: any) => {
      if (res.success) {
        setNumberListData(res?.data)
      } else {

      }
    }).catch((err: any) => {

    })
  }
  React.useEffect(() => {
    fetchNumberLists()
  }, [])

  //phone numbers
  const fetchPhoneNumbers = (page: any, limit: any) => {
    setLoading(true)
    axiosInstance.get(`/phoneNumbers?limit=${limit}&page=${page}`).then((res: any) => {
      console.log('resllll', res)
      if (res.status === 200) {
        const data = res.data.data[0]
        const userNumbers = res.data.data[0].userNumbers
        const userList = res.data.userList
        const deptNumbers = res.data.data[0].deptNumbers
        const departmentList = res.data.departmentList
        const unAssignedNumbers = res.data.data[0].unAssignedNumbers
        //console.log('resllll', departmentList,deptNumbers)
        setDeptData(departmentList)
        setDeptNumbers(deptNumbers)
        setUsersList(userList)
        setUserNumbers(userNumbers)
        setUnAssignNumbers(unAssignedNumbers)
        setLoading(false)
        setPaginate((prev) => {
          return {
            ...prev,
            page: res.data.data.page,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            totalPages: res.data.totalPages
          }
        })
      }
    }).catch((err: any) => {

    })
  }

  const handleChangePage = (event: any, value: any) => {
    setPaginate((prev) => {
      return {
        ...prev,
        page: value
      }
    });
  }
  const handlePortnumbers = () => {
    history.push("/users&deskphones/phonenumbers/portnumbers")
  }

  React.useEffect(() => {
    fetchPhoneNumbers(paginate.page, paginate.limit)
  }, [])

  //console.log('depttttttData',deptData,userList);

  const [search, setSearch] = React.useState('')

  return (
    <>
      {
        openModalAdduser && (
          <div>
            <Backdrop handlePopupModal={handlePopupModalAddNumber} />
            <Modal title="Add Number" handlePopupModal={handlePopupModalAddNumber} >
              <AddEfoneNumber
                fetchNumberLists={fetchNumberLists}
                handlePopupModal={handlePopupModalAddNumber}
                paginate={paginate}
              />
            </Modal>
          </div>
        )
      }

      <Accordion expanded={openExpand}
        onChange={expandOpen}>
        <AccordionSummary
          expandIcon={openExpand ? <FiMinusCircle color="lightgrey" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>All Numbers</Typography>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails className={classes.accordianNoFlex}>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item md={4}>
              <TextField
                size='small'
                fullWidth
                placeholder='search'
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                variant='outlined'
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={7} >
              <Button className={classes.gridItem}
                size="small"
                variant='outlined'
                color='primary'
                onClick={handlePopupModalAddNumber}
              >
                Add Numbers
              </Button>
              <Button className={classes.gridItem}
                size="small"
                variant='outlined'
                color='primary'
                onClick={handlePortnumbers}
              >
                Port Number
              </Button>
            </Grid>
          </Grid>
          <div className={classes.usersTable}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Numbers</TableCell>
                    <TableCell className={classes.tableHead}>Assigned to</TableCell>
                    <TableCell className={classes.tableHead}>Created at</TableCell>
                    <TableCell className={classes.tableHead}>Status</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {
                    numberListData.length > 0 && (
                      numberListData.map((numberData: any, i: any) => <NumbersTable
                        fetchNumberLists={fetchNumberLists}
                        numberData={numberData}
                        key={i}
                      />
                      )
                    )
                  } */}
                  {loading ?
                    <Box>
                      <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                        <CircularProgress />
                      </div>
                    </Box> :
                    deptNumber.filter((item: any) => item.phone.includes(search)).map((item: any) => {
                      return <TableBodyData
                        item={item}
                        data={deptData}
                        assigned={1}
                        assign={false}
                        fetchPhoneNumbers={fetchPhoneNumbers}
                      />
                    })}
                  {
                    loading ?
                      <Box>
                        <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                          <CircularProgress />
                        </div>
                      </Box> :
                      userNumbers.filter((item: any) => item.phone.includes(search)).map((item) => {
                        return <TableBodyData
                          item={item}
                          Userdata={userList}
                          assigned={2}
                          assign={false}
                          fetchPhoneNumbers={fetchPhoneNumbers}
                        />
                      })}
                  {
                    loading ?
                      <Box>
                        <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                          <CircularProgress />
                        </div>
                      </Box> :
                      unAssignNumbers.filter((item: any) => item.phone.includes(search)).map((item) => {
                        return <TableBodyData
                          item={item}
                          assign={true}
                          fetchPhoneNumbers={fetchPhoneNumbers}
                        />
                      })}

                </TableBody>
                {/* {numberListData.length <= 0 &&
                  <p className={classes.pText}>
                    No Number found
                  </p>
                } */}
              </Table>

            </TableContainer>
            <Stack spacing={2} style={{ marginTop: 15, marginBottom: 15, float: "right" }}>
              <Pagination
                // count={Math.floor(state.totalDocs / state.limit)}
                // page={state.page}
                count={paginate.totalPages}
                page={paginate?.page}
                onChange={handleChangePage}
                variant="outlined"
                color="secondary"
              />
            </Stack>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AllNumbers
import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    editmodal: {
        height: 500,
        [theme.breakpoints.down('xs')]: {
            height: 500,
            width: 380,
            marginTop: -10
        }
    },
    cardStyle: {
        padding: 30,
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        borderRadius: 10
    },
    modalCard: {
        margin: '10px 0px 10px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
        height:180
    },
    userText: {
        margin: '10px 0px 10px 0px',
        fontSize: 18,
        fontFamily: 'Work Sans',
        textAlign: 'left',
        fontWeight: 500,
        color: '#8392AB',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        justifyContent: 'flex-start',
    },
    button: {
        border: '1px solid lightgrey',
        width: 39,
        height: 37,
        padding: 7,
        color: '#4D72F8',
        display: 'grid',
        justifyContent: 'center',
        fontSize: 20,
        borderRadius: 4
    },
    bttns: { textAlign: 'center', marginTop: 10 },
    SaveButton: {
        background: '#4D72F8',
        border: '1px solid #ccc',
        borderRadius: 5,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 15,
        padding: '6px 22px',
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
        },
    },
    cancelButton: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        borderRadius: 5,
        color: '#8392AB',
        fontSize: 14,
        padding: '6px 22px',
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    title: {
        color: '#A0A0A0',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Calibri',
        marginBottom: 15
    },
    divPadding:{
        margin: '20px 60px 30px 20px',
        [theme.breakpoints.down('xs')]:{
            margin:0
        }   
    },
    audioPlayer: {
        display: "block",
        width: "100%",
        height: 40,
        borderRadius: 'none',
        '&.selected': {
            color: '#0921a9'
        },
        '&:hover': {
            color: '#4D72F8'
        },
    },
    editBtn: {
        background: '#4D72F8',
        borderRadius: 5,
        color: '#fff',
        padding: '6px 25px',
        marginRight:'15%',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#fff',
            background: '#4D72F8',
        }
    },
    tabActive: {
        background: '#4D72F8 ! important',
        color: '#FFFFFF ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        textTransform: "capitalize",
        width: '315px',
        height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px'
    },
    tabs: {
        background: "#FFFFFF",
        // borderRadius: "7.51286px",
        // boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '0px'
    },
    tab: {
        textTransform: "capitalize",
        background: '#E4E4E4 ! important',
        color: '#A1A1A1 ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        width: '315px',
        height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px'
    },
    dFlex: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    uploadContainer: {
        cursor: 'pointer',
        padding: '10px',
        borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
    },
    minutes: {
        fontFamily: 'Work Sans ! important',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        color: '#8F8E8E',
        marginTop: '10px',
        lineHeight: '19px'
    },
    saveBtn: {
        background: "#4D72F8",
        borderRadius: "3px",
        color: 'white',
        textTransform: 'capitalize',
        marginLeft: 10
    },

    selectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        borderTopLeftRadius:'none',
        borderTopRightRadius:4,
        borderBottomRightRadius:4,
        width:'40px',height:'58px'
        //borderRadius: '4px'
    },
    unSelectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5',
        width:'40px',height:'56px'
    },
    selected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        // borderRadius: '4px'
        borderTopLeftRadius:'4px',
        borderTopRightRadius:'none',
        borderBottomLeftRadius:4,
        // borderBottomRightRadius:4
    },
    unSelected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5'
    },
    audioTrack: {
        width: '500px', background: '#E9ECED'
    },
    audioTrackActive: {
        width: '500px', 
        //background: 'rgba(118, 144, 238, 0.5)',
    },
    filename: {
        fontFamily: 'Calibri !important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "12px",
        color: '#848282',
        marginTop: '15px'

    },
    saveAsdefaultBtn: {
        width: '170px',
        height: '42px',
        background: '#4D72F8',
        borderRadius: '3px',
        color: "#FFFFFF",
        textTransform: 'capitalize',
        textAlign: 'center',
        marginLeft: '35%'
    },

}))
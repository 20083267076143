import React from 'react'
import { Button, Grid, Card, CardContent, Typography, TextField, Divider } from '@material-ui/core'
import ProfileData from './profile'
import { useHistory } from 'react-router-dom'
import { InputAdornment } from '@material-ui/core';
import { FiEye, FiEyeOff } from 'react-icons/fi'
import ProfileServices from '../Profile/ProfileServices/ProfileServices'
import { useStyles } from "./styles";
import { useDispatch } from 'react-redux'
import * as gloalActionCreator from '../../store/Actions/globalpopupAlert'
import { toast } from 'react-toastify'
import { textAlign } from '@mui/system';


const formGroup: any = [
    {
        field: 'oldPassword',
        valid: true,
        message: ''
    },
    {
        field: 'newPassword',
        valid: true,
        message: ''
    },
    {
        field: 'confirmPassword',
        valid: true,
        message: ''
    },
]

function NewPassword() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch: any = useDispatch()
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [showPw1, setshowPw1] = React.useState(false)
    const [showPw2, setshowPw2] = React.useState(false)
    const [showPw3, setshowPw3] = React.useState(false)
    const habdleshowPW3 = () => {
        setshowPw3(!showPw3)
    }

    const habdleshowPW2 = () => {
        setshowPw2(!showPw2)
    }

    const habdleshowPW1 = () => {
        setshowPw1(!showPw1)
    }

    const [form, setForm] = React.useState<any>({
        data: {},
        errors: {}
    })



    const [passData, setPassData] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',

    })

    const onChangeEvent = (e: any) => {
        validateForm({ property: `${e.target.name}`, value: e.target.value })
        setPassData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const onSubmitForm = () => {
        const { oldPassword, newPassword, confirmPassword } = passData
        validateForm({ property: 'oldPassword', value: passData.oldPassword })
        validateForm({ property: 'newPassword', value: passData.newPassword })
        validateForm({ property: 'confirmPassword', value: passData.confirmPassword })
        if (newPassword !== confirmPassword) {
            dispatch(gloalActionCreator.initglobalPopup("Passwords not matched", "UPDATE_PASSWORD", "info"))
            toast.error('Passwords not matched', { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            // alert('Passwords not matched')

        }
        const newdata = {
            oldPassword: passData.oldPassword,
            newPassword: passData.newPassword
        }
        if (passData.newPassword === passData.confirmPassword) {
            ProfileServices.createNewPassword(newdata).then((res) => {
                if (res.success) {
                    dispatch(gloalActionCreator.initglobalPopup("Passwords changed successfully", "UPDATE_PASSWORD", "success"))
                    setPassData((prev: any) => {
                        return {
                            ...prev,
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: ""

                        }
                    })
                    history.push("/")
                } else {
                }
            }).catch((err) => {
                dispatch(gloalActionCreator.initglobalPopup(err.message, "UPDATE_PASSWORD", "info"))
            })

        }

    }
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];

        if (dataToValidate.property === 'oldPassword') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'oldPassword') {
                        formG.valid = false;
                        formG.message = 'oldPassword should not be empty'
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'Password must be greater than or equal to  6 characters'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'oldPassword') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'newPassword') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'newPassword') {
                        formG.valid = false;
                        formG.message = 'newPassword should not be empty'
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'newPassword') {
                        formG.valid = false;
                        formG.message = 'newPassword must be greater than or equal to  6 characters'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'newPassword') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }
        if (dataToValidate.property === 'confirmPassword') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmPassword') {
                        formG.valid = false;
                        formG.message = 'confirmPassword should not be empty'
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmPassword') {
                        formG.valid = false;
                        formG.message = 'confirmPassword must be greater than or equal to  6 characters'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'confirmPassword') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }

        }
        

    }

    return (
        <div>
            <Card style={{width: '100%'}}>
                <CardContent>
                    {/* <div >
                        <Typography className={classes.mainHeader}>Change Password </Typography>
                    </div> */}
                    {/* <Divider style={{marginTop: 10}} /> */}
                    <Grid container spacing={1}  >
                        <Grid item md={2} sm={2}></Grid>
                        <Grid item md={3} sm={3}>
                            <Typography className={classes.NameText}>Old Password</Typography>
                        </Grid>
                        <Grid item md={5} sm={5} style={{ marginTop: "25px" }}>
                            <TextField
                                variant={'outlined'}
                                size="small"
                                autoComplete='new-password'
                                type={showPw1 ? "text" : "password"}
                                placeholder="Enter Old password"
                                name="oldPassword"
                                onChange={onChangeEvent}
                                fullWidth
                                InputProps={{
                                    autoComplete: 'none',
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {showPw1 ? <FiEye onClick={habdleshowPW1} style={{ cursor: 'pointer' }} /> :
                                                <FiEyeOff onClick={habdleshowPW1} style={{ cursor: 'pointer' }} />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p className="error_messages_popup">{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'oldPassword') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                        </Grid>
                         <Grid item md={2} sm={2}></Grid>
                    </Grid>
                    <Grid container spacing={1} >
                         <Grid item md={2} sm={2}></Grid>
                        <Grid item md={3} sm={3}>
                            <Typography className={classes.NameText}>New Password</Typography>
                        </Grid>
                        <Grid item md={5} sm={5} style={{ marginTop: "25px" }}>
                            <TextField
                                className={classes.Textfield}
                                variant={'outlined'}
                                size="small"
                                autoComplete='new-password'
                                name="newPassword"
                                onChange={onChangeEvent}
                                type={showPw2 ? "text" : "password"}
                                placeholder="Enter New password"
                                fullWidth
                                InputProps={{
                                    autoComplete: 'none',
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {showPw2 ? <FiEye onClick={habdleshowPW2} style={{ cursor: 'pointer' }} /> :
                                                <FiEyeOff onClick={habdleshowPW2} style={{ cursor: 'pointer' }} />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p className="error_messages_popup">{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'newPassword') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                        </Grid>
                         <Grid item md={2} sm={2}></Grid>
                    </Grid>
                    <Grid container spacing={1} >
                         <Grid item md={2} sm={2}></Grid>
                        <Grid item md={3} sm={3}>
                            <Typography className={classes.NameText}>Confirm Password</Typography>
                        </Grid>
                        <Grid item md={5} sm={5} style={{ marginTop: "25px" }}>
                            <TextField
                                className={classes.Textfield}
                                variant={'outlined'}
                                autoComplete='new-password'
                                size="small"
                                type={showPw3 ? "text" : "password"}
                                name="confirmPassword"
                                onChange={onChangeEvent}
                                placeholder="Enter Confirm password"
                                fullWidth
                                InputProps={{
                                    autoComplete: 'none',
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {showPw3 ? <FiEye onClick={habdleshowPW3} style={{ cursor: 'pointer' }} /> :
                                                <FiEyeOff onClick={habdleshowPW3} style={{ cursor: 'pointer' }} />}
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            <p className="error_messages_popup">{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'confirmPassword') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                        </Grid>
                         <Grid item md={2} sm={2}></Grid>
                    </Grid>
                    <Grid container  style={{ marginTop: "18px", textAlign: 'center' }}>                       
                        <Grid item md={12} sm={12}>
                                <Button style={{
                                    border: "0.898113px solid #3A416F",
                                    borderRadius: 5,
                                    background: "#3A416F",
                                    color: " #FFFFFF",
                                    fontFamily: "Work Sans",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    marginBottom: 20,
                                    textTransform: "capitalize",
                                    fontSize: 14,
                                    padding: "6px 20px"
                                }} onClick={onSubmitForm}>Update</Button>
                                <Button  variant="outlined" className={classes.passwordcancelButton}
                                    onClick={() => history.push('/dashboard')}>Cancel
                                </Button>
                        
                        </Grid>
                        
                    </Grid>


                </CardContent>
            </Card>
        </div>
    )
}

export default NewPassword

import React from "react";
import ProfilePic from "../../../assets/icons/avtar.jpg";
import PlayIcon from "../../../assets/icons/playIcon.png";
import ProfileEdit from "../../../assets/icons/profileEdit.png";
import EmergencyIcon from "../../../assets/icons/emergency.png";

import { useStyles } from "../styles";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import EmergencyAddress from "./EmergAddModal";
import {
  CardContent,
  Typography,
  Button,
  Grid,
  Tooltip,
  Switch,
  Select,
} from "@material-ui/core";
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
import { FaUserEdit } from 'react-icons/fa'
function ProfileData(props: any) {
  const classes = useStyles();
  const { loading, profileData } = props;
  const [userData, setUserData] = React.useState<any>(null);
  const [openInput, setOpenInput] = React.useState<any>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [city, setCity] = React.useState("");
  const openEditElement = (tabtobeedit: any) => {

    setOpenInput(tabtobeedit);
  };

  const changeName = (e: any) => {
    setUserData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handlePopupModal = () => {
    setOpenModal(!openModal);
  };
  React.useEffect(() => {
    setUserData(profileData);
  }, [profileData]);

  const updateUserData = () => {
    //send request to uoadte data
    let userDataToUpdate: any = {
      email: userData.email,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      nickName: userData?.nickName,
    };

    props.updateUserData(userDataToUpdate);
    setOpenInput(null);
  };
  const handleChangecity = (event: any) => {
    setCity(event.target.value);
  };

  return (
    <CardContent className={classes.cardContent1}>
      <img className={classes.profile} alt="Remy Sharp" src={ProfilePic} />
      <CardContent className={classes.align}>
        <div>
          {openInput && openInput === 'firstandlast' ? (
            <div className="update_inputs_flex1">
              <div>
                <input
                  type="text"
                  name="firstName"
                 
                  value={userData?.firstName}
                  onChange={changeName}
                />
              </div>
              <div>
                <input
                  
                  type="text"
                  name="lastName"
                  value={userData?.lastName}
                  onChange={changeName}
                />
              </div>
              <div className="update_profile_icions_flex">
                <AiFillCheckCircle
                  onClick={updateUserData}
                  size={25}
                  color="#149a01"
                  style={{cursor:'pointer'}}
                />
                <AiFillCloseCircle
                  onClick={() => setOpenInput(null)}
                  size={25}
                  color="#E91C1C"
                  style={{cursor:'pointer'}}
                />
              </div>
            </div>
          ) : loading ? (
            <p>Loading...</p>
          ) : (
            <div className={classes.dFlex}>
              <Typography className={classes.head1}>
                {userData?.firstName} {"  "} {userData?.lastName}

              </Typography>
              {(openInput == null || openInput === undefined || openInput === '') && (
                <FaUserEdit
                  color="rgb(133 133 133)"
                  size={20}
                  style={{ marginLeft: '10px',cursor:'pointer' }}
                  onClick={() => openEditElement("firstandlast")}
                />
              )}
            </div>
          )}
        </div>

        <Tooltip
          title="This name will be used as Outbound id name when using my direct phone numbers"
          placement="right-start"
        >
          {openInput && openInput === 'nickname' ? (
            <div className="update_inputs_flex2">
              <div>
                <input
                  type="text"
                  name="nickName"
                  value={userData?.nickName}
                  onChange={changeName}
                />
              </div>
              <div className="update_profile_icions_flex">
                <AiFillCheckCircle
                  onClick={updateUserData}
                  size={25}
                  color="#149a01"
                />
                <AiFillCloseCircle
                  onClick={() => setOpenInput(null)}
                  size={25}
                  color="#E91C1C"
                />
              </div>
            </div>
          ) : (
            <div className={classes.dFlex}>
              <Typography className={classes.headSmall} >
                {userData?.nickName}
              </Typography>
              {(openInput == null || openInput === undefined || openInput === '') && (
                <FaUserEdit
                  color="rgb(133 133 133)"
                  style={{ marginLeft: '10px',cursor:'pointer' }}
                  size={20}
                  onClick={() => openEditElement("nickname")}
                />
              )}
            </div>
          )}
        </Tooltip>
        <div className={classes.dFlex} >
          <Typography align="center">
            {openInput && openInput === 'email' ? (
              <div className="update_inputs_flex3">
                <div>
                  <input
                    type="text"
                    name="email"
                    value={userData?.email}
                    onChange={changeName}
                  />
                </div>
                <div className="update_profile_icions_flex">
                  <AiFillCheckCircle
                    onClick={updateUserData}
                    size={25}
                    color="#149a01"
                    style={{ cursor:'pointer' }}
                  />
                  <AiFillCloseCircle
                    onClick={() => setOpenInput(null)}
                    size={25}
                    color="#E91C1C"
                    style={{cursor:'pointer' }}
                    
                  />
                </div>
              </div>
            ) : (
              <u className={classes.gmail}>{userData?.email}</u>
            )}
          </Typography>
          {(openInput == null || openInput === undefined || openInput === '') && (
            <FaUserEdit
              color="rgb(133 133 133)"
              style={{ marginLeft: '10px',cursor:'pointer' }}
              size={20}
              onClick={() => openEditElement("email")}
            />
          )}
        </div>
        <Tooltip
          title="default or recorded name will be played in the greetings"
          placement="right-start">
          <Button
            variant="outlined"
            className={classes.button}
            startIcon={<img src={PlayIcon} alt="play" />}
          >
            Recorded Name
          </Button>
        </Tooltip>
        <div>
          {openModal && (
            <div>
              <Backdrop handlePopupModal={handlePopupModal} />
              <Modal
                title="Enter Your Details"
                handlePopupModal={handlePopupModal}
              >
                <EmergencyAddress
                  handlePopupModal={handlePopupModal}
                  getAddressData={userData}
                />
              </Modal>
            </div>
          )}
          <Button
            variant="outlined"
            className={classes.button1}
            startIcon={<img src={EmergencyIcon} alt="emergency" />}
            onClick={handlePopupModal}
          >
            e911 Emergency Address
          </Button>
        </div>
      </CardContent>
    </CardContent>
  );
}

export default ProfileData;

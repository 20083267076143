import { departmentModle } from '../Actions/Actions';
import { companyModule } from '../Actions/Actions';
const departmentState = {
    departmentsData: [],
    companyData: [],
    companyId: '',
    loading: false,
    selectedDepartment: null,
    selectedCompany: null
}

export const departmentsReducerFunction = (state: any = departmentState, action: any) => {
    switch (action.type) {
        case departmentModle.FETCH_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                departmentsData: action.departmentsData
            }
        case departmentModle.ONCHANGE_SELECT_DEPARTMENT:
            return {
                ...state,
                selectedDepartment: action.selectedDepartment
            }
        case departmentModle.FETCH_LOADING_DEPARTMENTS:
            return {
                ...state,
                loading: action.status
            }
        case companyModule.FETCH_COMPANY_SUCCESS:
            return {
                ...state,
                companyData: action.companyData
            }
        case companyModule.SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompany: action.selectedCompany
            }
        default:
            return state
    }
}
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  padding: {
    padding: 15,
  },
  card: {
    width: '100%',
    margin: '15px 0px 0px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.8rem',
    fontWeight: 500,
    marginTop: "-13px",
    marginBottom: 0,
  },
  labelText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#8392AB'
  },
  Note: {
    fontSize: '13px',
    display: 'block',
    color: '#8392AB'
  },
  formPadding: {
    padding: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.8rem",
  },
  divider: {
    backgroundColor: '#E2E2E2',
    marginTop:-10
  },
  heading: {
    color: "#4D72F8",
    fontSize:20,
    fontWeight: 600,
  },
  accordianNoFlex: {
    display: "block",
  },
  tablecolor: {
    backgroundColor: "#F1F1F1",
  },
  form: {
    padding: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
  },
  fontPadding: {
    padding: 5,
    fontWeight: 600,
    color: "#8392AB",
    fontSize: 18,
    "@media (max-width: 768px)": {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]:{
      fontSize: '13px',
    }

  },
  fontPadding1: {
    padding: 5,
    fontSize: "14px",

    fontStyle: "normal",
    fontWeight: 500,
    color: "#6B6B6B",
    letterSpacing: 0.208955,
    textTransform: "none",

  },
  fontPadding2: {
    padding: 5,
    fontSize: 14,
    fontWeight: 500,
    color: "#8392AB",
  },
  button: {
    margin: 10,
    fontSize: 12,
    textTransform:'capitalize',
    fontWeight: 500,
    textAlign: "center",
    padding: '6px 22px',
    // borderRadius: 25,
    color: "#4D72F8",
    backgroundColor: "#FFFFFF",
    borderColor: "#4D72F8",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
    "@media (max-width: 768px)": {
      height: 40,
      padding: '6px 40px',
    },
    [theme.breakpoints.down('xs')]:{
      height: 34,
      padding: '6px 15px',
    }
  },
  savebutton: {
    margin: "10px 0px",
    fontSize: 12,
    textTransform:'capitalize',
    fontWeight: 500,
    textAlign: "center",
    padding: '6px 22px',
    // borderRadius: 25,
    color: "#4D72F8",
    backgroundColor: "#FFFFFF",
    borderColor: "#4D72F8",
    width: "100px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
    "@media (max-width: 768px)": {
      height: 40,
      padding: '6px 40px',
    },
    [theme.breakpoints.down('xs')]:{
      height: 34,
      padding: '6px 15px',
    }
  },
  blockedbutton: {
    display: "flex",
    alignItems: "center",
    justifyContent:'space-between'
  },
  tableHead: {
    fontSize: 15,
    color: "#25282B",
    fontWeight: 600,
    fontFamily: 'Work Sans'
  },
  blocknumber: {
    width: "25ch",
  },
  tableBody: {
    fontWeight: 500,
    fontSize: 14,
    color: "#828282",
    fontFamily: 'Work Sans'
  },
  input: {
    width: "100%",
    height: "47px",
    background: " #FFFFFF",
    marginBottom: 10,
    borderRadius: "5px",
  },
  inputdate: {
    width: "100%",
    height: "40px",
    padding: "8px",
    color: "rgb(164, 165, 165)",
    borderRadius: "5px",
    border: "1px solid grey",

  },
  actionButton: {
    borderWidth: 1,
    minWidth: 0,
    padding: 10,
    borderRadius: 50,
  },
  adminIcon: {
    width: 25,
    height: 25,
    color: '#979797',
  },
  textColorOne: {
    marginBottom: 10,
    color: '#464646',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 16,
  },
  tableCellBody: {
    padding: 5,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.21553,
    textAlign: 'center',
    color: '#464646',
  },
  wButton: { width: '100%', textAlign: 'center',marginBottom:15 },
  submitButton: {
    margin: 10,
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '6px 22px',
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  deleteButton: {
    margin: 10,
    padding: '6px 22px',
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    margin: 10,
    padding: '6px 22px',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    marginLeft: 15,
    background: '#EFEFEF',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#787878',
    fontSize: 14,
  },

  textAbsolute: {
    marginLeft: 10,
    fontSize: "0.8rem",

    fontStyle: "normal",
    fontWeight: 500,
    color: "#464646",
    letterSpacing: 0.208955,
    textTransform: "none",
  },
}));

import { Button, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import userServices from '../userServices'
import { Description, MoreHorizRounded, Person, RecordVoiceOver, Timeline } from '@material-ui/icons';

import { useStyles } from './styles'
import { AiTwotoneDelete } from 'react-icons/ai';

function DepartmentTableBody(props: any) {
    const classes = useStyles()
    const anchorRef: any = React.useRef<any>(null);
    const [openOptions, setOpenOption] = React.useState<any>(false);
    const [departmentNumbers, setDepartmentNumbers] = React.useState<any>([]);
    const [depList, setdepList] = React.useState<any>();

    React.useEffect(() => {
        setdepList(props.data)
    }, [props.data])

    React.useEffect(() => {
        setDepartmentNumbers(props.userListdepartmentNumbers)
    }, [props.userListdepartmentNumbers])

    // console.log("depList", depList)
    const numList: any = []
    departmentNumbers.map((item: any) => {
        if (item.departmentId === props.data.deptId) {
            numList.push(item)
        }
    })
    // console.log("numList", numList)
    // console.log("departmentNumbers", departmentNumbers)
    const handleToggle = () => {
        // console.log("handleToggle", openOptions)
        setOpenOption((prevOpen: any) => !prevOpen);
    };

    const handleClose = (event: any) => {

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenOption(false);
        // console.log("handleClose", openOptions)
    };

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenOption(false);
        }
    }

    const onChangeOperations = (id: any, permissions: any, deptId: any) => {
        if (permissions.includes(id)) {
            const index = permissions.findIndex((item: any) => {
                if (item === id) {
                    return item
                }
            })
            // console.log(index)
            // console.log("permisions", permisions)
            permissions.splice(index, 1)
            const data = {
                departmentId: deptId,
                permissions: permissions
            }
            //   console.log(data)
            userServices.updateUserDepartmentPermissions(data, props.id).then((res: any) => {
                // console.log("patchresponse", res)
                if (res.success) {
                    props.fetchList()
                }
            }).catch((err: any) => {

            })

        } else {
            permissions.push(id)
            const data = {
                departmentId: deptId,
                permissions: permissions
            }
            console.log(data)
            userServices.updateUserDepartmentPermissions(data, props.id).then((res: any) => {
                // console.log("patchresponse", res)
                if (res.success) {
                    props.fetchUsersList()
                }
            }).catch((err: any) => {

            })
        }
    }

    const Permissions = (props: any) => {
        // console.log(props.deptId)
        // const arr: any = permissions.permissions[0]
        // console.log("arr", arr)
        return (
            <div style={{ display: 'flex' }}>

                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(1, props.permissions[0], props.deptId)}
                            className={`${props.permissions[0]?.includes(1) ? classes.activeButton : classes.button}`}
                        >
                            {/* <Person className={`${operations?.isAdmin === true ? classes.userIcon : classes.adminIcon}`} /> */}
                            <Person
                                className={`${props.permissions[0]?.includes(1) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Admin</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(2, props.permissions[0], props.deptId)}
                            className={`${props.permissions[0]?.includes(2) ? classes.activeButton : classes.button}`}
                        >
                            <Description className={`${props.permissions[0]?.includes(2) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Billing</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(3, props.permissions[0], props.deptId)}
                            className={`${props.permissions[0]?.includes(3) ? classes.activeButton : classes.button}`}
                        >
                            <Timeline className={`${props.permissions[0]?.includes(3) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Analytics</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(4, props.permissions[0], props.deptId)}
                            className={`${props.permissions[0]?.includes(4) ? classes.activeButton : classes.button}`}
                        >
                            <RecordVoiceOver
                                className={`${props.permissions[0]?.includes(4) ? classes.activeIcon : classes.inActiveIcon}`}
                            />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Recordings</Typography>
                </div>

                {/* <Tooltip title={"Analytics"} placement="bottom" className="tooltip" style={{ bottom: 10 }}>
                    <IconButton
                        className={`${permissions?.includes(2) ? classes.activeButton : classes.button}`}
                    >
                        <img src={WebAnalytics} alt="WebAnalytics" className={`${permissions?.includes(2) ? classes.userIcon : classes.adminIcon}`} />
                    </IconButton>
                </Tooltip> */}

                {/* <Tooltip title={"Billings"} placement="bottom" className="tooltip" style={{ bottom: 10 }}>
                    <IconButton
                        className={`${operations?.billingEnbale === true ? classes.button : classes.adminButton}`}
                        onClick={() => onChangeOperations('billingEnbale')}>
                        <Description className={`${operations?.billingEnbale === true ? classes.userIcon : classes.adminIcon}`} />
                    </IconButton>
                </Tooltip> */}


            </div>
        );
    };
    // const handleDeleteUserFromDept = (selectedDeptId: any) => {
    //     // console.log("selectedDeptId", selectedDeptId)
    //     const data: any = {
    //         departmentIds: selectedDeptId
    //     }
    //     // console.log("depid",data)
    //     userServices.unAsignUserfromDept(props.id, data).then((res: any) => {
    //         if (res.success) {
    //             props.handleDelete()
    //             props.fetchUsersList()
    //         }
    //     }).catch((err: any) => {

    //     })
    // }


    return (
        <TableRow key={props.key}>
            <TableCell className={classes.body}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span >{props.data.deptName}</span>
                    <span>(Agent)</span>
                </div>
            </TableCell>
            <TableCell className={classes.body}>
                {numList.map((item: any) => {
                    return (
                        <span>{item?.phone}<br /></span>
                    )
                })}
            </TableCell>
            <TableCell className={classes.body}>
                <Permissions permissions={props.data.permissions} deptId={props.data.deptId} />
            </TableCell>
            <TableCell className={classes.body}>
                <Button
                    ref={anchorRef}
                    aria-controls={openOptions ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    <MoreHorizRounded />
                </Button>
                <Popper open={openOptions} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList  autoFocusItem={openOptions} id="menu-list-grow" onKeyDown={handleListKeyDown} >
                                        <MenuItem >
                                            <ListItemIcon className={classes.updateuserAction} style={{ alignItems: 'center' }}
                                                onClick={() => props.handleDeleteOption(props.data.deptId)}>
                                                <AiTwotoneDelete style={{ color: '#BB271A' }} className={classes.iconClor} />
                                                <ListItemText className={classes.textColor} >UnAssign</ListItemText>
                                            </ListItemIcon>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </TableCell>
        </TableRow>
    )
}

export default DepartmentTableBody
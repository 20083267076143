
import { Button, Card, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import ReactSelect from 'react-select';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import { TabContext, TabPanel } from '@material-ui/lab';
import axiosInstance from '../../../../environment/axiosInstance'
import ReactAudioPlayer from 'react-audio-player';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UploadFile from './UploadFile';
import RecordAudio from './RecordAudio';
toast.configure()

const muiTheme = createMuiTheme({});

const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });


function CloseHoursDepartments(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [depts, setDepts] = React.useState([])
    const [teamMembers, setTeamMembers] = React.useState([])
    const [teamValue, setTeamValue] = React.useState<any>('');
    const [deptValue, setDeptValue] = React.useState<any>('');
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }

    //departments   
    React.useEffect(() => {
        const departmentss: any = []
        if (props.closedData) {
            props.closedData?.departments?.map((item: any) => {
                var myObj = {
                    value: item._id,
                    label: item.name,
                }
                departmentss.push(myObj)
            })
            setDepts(departmentss)
        }
    }, [props.closedData])

    const handleDeptOnchange = (e: any) => {
        console.log('datttt', e)
        setDeptValue(e.value)
        const sendData = {
            departmentId: e.value
        }
        axiosInstance.patch('/profile/settings/closed-hours/user-department', sendData).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getCloseHours()
            }
        }).catch((err: any) => {
            //err
            toast.success(err.message, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 1000
            })
        })
    };

    const selectedDept = depts.find((option: any) =>
        option.value = props.closedData?.userAndDepartments?.departmentId
    )
    // console.log('selectedDept',selectedDept);

    //TeamMembers
    React.useEffect(() => {
        const teamMembers: any = []
        if (props.closedData) {
            props.closedData.Teams?.map((item: any) => {
                var myObj = {
                    label: item.firstName + ' ' + item.lastName,
                    value: item.firstName + ' ' + item.lastName,
                    id: item._id
                }
                teamMembers.push(myObj)
            })
            setTeamMembers(teamMembers)
        }
    }, [props.closedData])

    const handleTeamMembersOnchange = (data: any) => {
        setTeamValue(data.value)
        const sendData = {
            teamMemberId: data.id
        }
        axiosInstance.patch('/profile/settings/closed-hours/user-department', sendData).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getCloseHours()
            }
        }).catch((err: any) => {
            //err
            toast.success(err.message, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 1000
            })
        })
    };




    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div style={{ marginBottom: 17, height: 600,}}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            closedData={props.closedData}
                                            getCloseHours={props.getCloseHours}
                                        />

                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadFile
                                            closedData={props.closedData}
                                            getCloseHours={props.getCloseHours}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }

            <div className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={6}>
                        <Grid item md={4} xs={12} sm={6}>
                            <Typography className={classes.title}>To Team Member</Typography>
                            <ReactSelect
                                placeholder="Select team Member"
                                options={teamMembers}
                                name="firstName"
                                value={teamMembers.filter((option: any) =>
                                    option.id === props.closedData?.userAndDepartments?.teamMemberId
                                )}
                                onChange={handleTeamMembersOnchange}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} sm={6} className={classes.gridMargins}>
                            <Typography className={classes.title}>To Department</Typography>
                            <ReactSelect
                                options={depts}
                                name="name"
                                value={selectedDept}
                                onChange={handleDeptOnchange}
                                placeholder="Select department" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: '30px' }}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ReactAudioPlayer
                                controls
                                className={classes.audioPlayer}
                                onLoadedMetadata={handleLoadMetadata}
                                // src={props.deptPath !== "" ? props.deptPath : ""}
                                src={props.deptPath}
                            />
                            <div style={{ textAlign: 'left', marginTop: '5px' }}>
                                <span className={classes.filename}>{props.deptFileName}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>Edit</Button>
                        </Grid>
                    </Grid>
                </Card>

            </div>
        </>
    )
}

export default CloseHoursDepartments
import React from 'react';
import { Card, CardContent, Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
//import MySignal from 'assets/icons/Signal.png';
import MySignal from '../../../../assets/icons/Signal.png'
import MyDepts from '../../../../assets/icons/Dept.png';
import GroupUsers from '../../../../assets/icons/groupUsers.png';
import MyTelephone from '../../../../assets/icons/MyTelephone.png';
import MyRecords from '../../../../assets/icons/Record.png';
import { useStyles } from './styles';
import { CardHeader } from '../../../../components/CardHeader/CardHeader';
import OpenHoursVoicemail from '../OpenHoursVoicemail/OpenHoursVoicemail';
import OpenHoursPhoneNumbers from '../OpenHoursPhoneNumbers/OpenHoursPhoneNumbers';
import OpenHoursRecMessages from '../OpenHoursRecMessages/OpenHoursRecMessages';
import OpenHoursDepartments from '../OpenHoursDepartments/OpenHoursDepartments';
import OpenHoursTeammembers from '../OpenHoursTeammembers/OpenHoursTeammembers';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { MdPhonelink, MdSettingsPhone } from 'react-icons/md'
import { RiUserVoiceFill, RiTeamFill } from 'react-icons/ri'
import { HiOfficeBuilding } from 'react-icons/hi'
import { BiMessageDetail } from 'react-icons/bi'
import profileServices from '../../ProfileServices/ProfileServices'
import { useSelector, useDispatch } from 'react-redux'
import CloseHoursVoicemail from '../CloseHoursVoicemail/CloseHoursVoicemail';
import CloseHoursDepartments from '../CloseHoursDepartments/CloseHoursDepartments';
import CloseHoursRecMessages from '../CloseHoursRecMessages/CloseHoursRecMessages'
import CloseHoursTeamMembers from '../CloseHoursTeamMembers/CloseHoursTeamMembers'
import CloseHourPhoneNumbers from '../CloseHoursPhoneNumbers/CloseHoursPhoneNumbers'

import * as profileActionCreator from '../../../../store/Actions/userActionCreator'

const openhoursOptions = [{ key: 2, value: "voicemail" }, { key: 3, value: "departments" }, { key: 4, value: "teammembers" }, { key: 5, value: "phonenumbers" }, { key: 6, value: "recmessages" }]

function CloseHours() {
  const classes = useStyles();
  const profileStoreData = useSelector((state: any) => state.profileData)
  const { profileData, loading } = profileStoreData;
  const [tabname, setTabName] = React.useState('voicemail');
  const [open, setOpen] = React.useState(true);
  const [closeHour, setClosedHour] = React.useState<any>({})
  const [closedData, setClosedData] = React.useState<any>({})
  const dispatch = useDispatch();

  const expandOpen = () => {
    setOpen(!open);
  }

  const handleTabChange = (tabname: any) => {
    const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
    if (findKeyValue?.key === 1) {
      profileServices.updateClosedHours({ callHandlingOption: findKeyValue?.key }).then((res: any) => {
        //send success message
      }).catch((err: any) => {
        //send error message
      })
    }
    setTabName(tabname)
  }
  const getCloseHours = () => {
    profileServices.getClosedHours().then((res) => {
      if (res.success) {
        setClosedHour(res.data)
      }
    }).catch(err => {

    })
  }
  React.useEffect(() => {
    if (profileData) {
      setClosedData(profileData.closedHours)
    }

  }, [profileData])
  React.useEffect(() => {
    if (profileData) {
      const { closedHours } = profileData;
      openhoursOptions.forEach((option: any) => {
        if (option.key === closedHours?.callHandlingOption) {
          setTabName(option.value)
        }
      })
    }
  }, [profileData])
  React.useEffect(() => {
    getCloseHours()
  }, [])

  React.useEffect(() => {
    dispatch(profileActionCreator.initClosedhour())
  }, [])



  return (
    <>
      <div className={classes.padding}>
        <Card className={classes.card}>
          <Accordion onChange={expandOpen}>
            <AccordionSummary
              expandIcon={open ? <FiPlusCircle color="#2645ad" /> : <FiMinusCircle color="#2645ad" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Close Hours Call Handling</Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
              <CardContent>
                <Grid container spacing={2} className={classes.grid}>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'voicemail' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange("voicemail")}>
                      <CardContent className={classes.align}>
                        <RiUserVoiceFill
                          className={
                            tabname === "voicemail"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          } />
                        <Typography className={tabname === 'voicemail' ? classes.devicesText : classes.devicesText1}>
                          Voice Mail
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'departments' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange("departments")}>
                      <CardContent className={classes.align}>
                        <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} />
                        {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}

                        <Typography
                          className={tabname === 'departments' ? classes.devicesText : classes.devicesText1}>
                          Departments
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'teammembers' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange("teammembers")}>
                      <CardContent className={classes.align}>
                        <RiTeamFill className={tabname === 'teammembers' ? classes.deviceWidth1 : classes.deviceWidth} />
                        {/* <img className={classes.deviceWidth} alt="GroupUsers" src={GroupUsers} /> */}

                        <Typography
                          className={tabname === 'teammembers' ? classes.devicesText : classes.devicesText1}>
                          Team Members
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'phonenumbers' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange("phonenumbers")}>
                      <CardContent className={classes.align}>
                        <MdSettingsPhone className={tabname === 'phonenumbers' ? classes.deviceWidth1 : classes.deviceWidth} />
                        {/* <img className={classes.deviceWidth} alt="MyTelephone" src={MyTelephone} /> */}

                        <Typography
                          className={tabname === 'phonenumbers' ? classes.devicesText : classes.devicesText1}>
                          Phone Numbers
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'recmessages' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange("recmessages")}>
                      <CardContent className={classes.align}>
                        <BiMessageDetail className={tabname === 'recmessages' ? classes.deviceWidth1 : classes.deviceWidth} />


                        {/* <img className={classes.deviceWidth} alt="MyRecords" src={MyRecords} /> */}
                        <Typography
                          className={tabname === 'recmessages' ? classes.devicesText : classes.devicesText1}>
                          Recorded Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}></Grid>
                </Grid>
              </CardContent>

              {tabname === 'voicemail' && (
                <>
                  <CloseHoursVoicemail closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} callHandlingOption={2} compType={'voicemail'} />
                  {/* <OpenHoursVoicemail /> */}
                  {/* <Voicemails /> */}
                </>
              )}
              {tabname === 'departments' && (
                <>
                  <CloseHoursDepartments callHandlingOption={3} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} />
                  {/* <OpenHoursDepartments /> */}
                  {/* <Departments /> */}
                </>
              )}
              {tabname === 'teammembers' && (
                <>
                  <CloseHoursTeamMembers callHandlingOption={4} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} />
                </>
              )}
              {tabname === 'phonenumbers' && (
                <>
                  <CloseHourPhoneNumbers callHandlingOption={5} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} />
                </>
              )}
              {tabname === 'recmessages' && (
                <>
                  <CloseHoursRecMessages callHandlingOption={6} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} />
                </>
              )}

            </AccordionDetails>
          </Accordion>


          {/* <CardHeader
            name={'Close Hours Call Handling'}
            textfield={false}
            label={'Close Hours Call Handling'}
            addbox={true}
          /> */}



        </Card>
      </div>
    </>
  );
}
export default CloseHours;

import React from 'react'
import ReactDom from 'react-dom'
import { IoMdCloseCircle } from 'react-icons/io'
import './style.css'

function SidebarPopUpModal(props: any) {
    const modalBody: any = (
        <div className="modal_container_sidebar">
            {props.children}
        </div>
    )
    const domElement: any = document.getElementById('modal_popup_root2');

    return ReactDom.createPortal(modalBody, domElement)
}

export default SidebarPopUpModal


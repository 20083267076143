import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
class PlanServices {
    getPlans = async (limit: any, page: any) => {
        try {
            const response = await axiosInstance.get(`/admin/plans?limit=${limit}&page=${page}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getPlansByID = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/admin/plans/${id}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getPlansforbusiness = async () => {
        try {
            const response = await axiosInstance.get(`/admin/plans`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getPlansbyName = async (limit: any, page: any, planName: any) => {
        try {
            const response = await axiosInstance.get(`/admin/plans?limit=${limit}&page=${page}&planName=${planName}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getPlansbyCost = async (limit: any, page: any, cost: any) => {
        try {
            const response = await axiosInstance.get(`/admin/plans?limit=${limit}&page=${page}&cost=${cost}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getPlansbyall = async (limit: any, page: any, planName: any, cost: any) => {
        try {
            const response = await axiosInstance.get(`/admin/plans?limit=${limit}&page=${page}&cost=${cost}&planName=${planName}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    postPlans = async (sendData: any) => {
        try {
            const response = await axiosInstance.post("/admin/plans", sendData);

            if (response.data) {
                const data = response.data;
                toast.success("Plan added successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    deletePlan = async (id: any) => {
        try {
            const response = await axiosInstance.delete(`/admin/plans/${id}`);
            if (response.data.data) {
                const data = response.data.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
         toast.error( error.response.data.message, { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    patchPlans = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/admin/plans/${id}`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                // console.log("----updated switch", response.data)
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

}
const plans = new PlanServices()
export default plans
import React from 'react'
import {
    TableCell,
    TableRow,
    Table, TableBody, TableContainer, TableHead,
    IconButton,
    Checkbox,
    Typography,
    Button,
    MenuItem,
    Grid,
    TextField,
    Card,
    CardContent
} from '@material-ui/core';
import { useStyles } from './styles'
import Moment from 'moment'
export default function BusinessTableBody(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const anchorRef: any = React.useRef(null);
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    var datadate = parseInt(props.reportData.createdDate)
    var dataa = Moment(datadate).format('YYYY-MM-DD HH:mm:ss')
    return (
        <>

            <TableRow >
                <TableCell className={classes.tableCellBody}>{props.reportData?.parentId?.firstName} {props.reportData?.parentId?.lastName}</TableCell>
                <TableCell className={classes.tableCellBody}>
                        {props.reportData?.userId?.firstName} {props.reportData?.userId?.lastName}
                    </TableCell>
                   
                <TableCell className={classes.tableCellBody}>
                     {props.reportData?.departmentId?.name}
                    </TableCell>
                <TableCell className={classes.tableCellBody}>
                    {props.reportData?.agentId?.firstName} {props.reportData?.agentId?.lastName}
                </TableCell>
                <TableCell className={classes.tableCellBody}>{props.reportData?.direction === 1 ? "Inbound" : "Outbound"}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.reportData?.eFoneNumber}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.reportData?.phoneNumber}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.reportData.createdDate ? dataa : ""}</TableCell>

                <TableCell className={classes.tableCellBody}>{props.reportData?.duration}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.reportData.recordingPath ? props.reportData.recordingPath : ""}</TableCell>


            </TableRow>



        </>
    )
}

import {
  Button,
  Grid,
  TextField,
  Card,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { InputAdornment } from '@material-ui/core';
import React from "react";
import { useStyles } from "./style";
import axiosInstance from '../../../environment/axiosInstance'
import * as popUpActionCreator from "../../../store/Actions/globalpopupAlert";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { toast } from 'react-toastify'
function UpdatePassword(props: any) {
  const classes = useStyles();
  const { location } = useHistory();
  const history: any = useHistory();
  const [showPw, setShowPw] = React.useState(false)
  const [showConfirmPw, setShowConfirmPw] = React.useState(false)
  const [user, setUser] = React.useState<any>({})
  const [submitted, setSubmitted] = React.useState(false);
  const [token, settoken] = React.useState<any>()
  const [passwordData, setPasswordData] = React.useState({
    password: "",
    confirmPassword: ""
  })

  const habdleshowPW = () => {
    setShowPw(!showPw)
  }
  const habdleshowConfirmPW = () => {
    setShowConfirmPw(!showConfirmPw)
  }
  const onContentChangeEvent = (e: any) => {
    e.preventDefault();
    const val = e.target.value
    if (val !== " ") {
      setPasswordData((prev: any) => {
        return {
          ...passwordData,
          [e.target.name]: e.target.value,
        }
      })
    }

  }





  React.useEffect(() => {
    const token = location.search.slice(7)
    const index = token.indexOf("&")
    const NewToken = token.substring(0, index)
    settoken(NewToken)
  }, [])


  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      password: { ...validData },
      confirmPassword: { ...validData },
    };
    let isValid = true;
    if (submitted) {
      if (!passwordData.password) {
        retData.password = {
          isValid: false,
          message: "Please enter password"
        };
        isValid = false;
      }
      if (passwordData.password != passwordData.confirmPassword) {
        retData.confirmPassword = {
          isValid: false,
          message: "Both fields should be same"
        };
        isValid = false;
      }
      if (!passwordData.confirmPassword) {
        retData.confirmPassword = {
          isValid: false,
          message: "Please re-enter password"
        };
        isValid = false;
      }
    }
    return {
      ...retData,
      isValid
    };
  };

  const onSubmitForm = (event: any) => {
    event.preventDefault();
    setSubmitted(true);
    const errorData = validate(true);
    var object = {
      password: passwordData.password
    }
    if (errorData.isValid) {
      if (passwordData.password === passwordData.confirmPassword) {
        axiosInstance.post(`/auth/reset-password?token=${token}`, object).then((res: any) => {
          if (res.data) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              style: {
                borderRadius: "2px",
                border: "1px solid green"
              }, autoClose: 1000
            })
            history.push('/')
          } else {
            toast.error(res.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 });
          }
        }).catch((err: any) => {
          toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
        })
      }
    }

  }

  const errors = validate(submitted);

  return (

    <div className="sign_wrapper">
      <div >
        <Typography className={classes.mainHeader} >Create Password</Typography>
        <Typography className={classes.subHeader}  >Please enter create and confirm password</Typography>
      </div>
      <div className="sign_form_container">
        <form >
          <div style={{ marginTop: 20 }}>
            <Typography className={classes.newPass}>New Password:</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type={showPw ? "text" : "password"}
              name="password"
              value={passwordData.password}
              onChange={onContentChangeEvent}
              placeholder="Enter new password"
              className={classes.textField}
              error={!errors.password.isValid}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showPw ? <FiEye onClick={habdleshowPW} /> :
                      <FiEyeOff onClick={habdleshowPW} />}
                  </InputAdornment>
                ),
              }}
            />

            {
              !errors.password.isValid &&
              <p className={classes.errorMessage}>{errors.password.message}</p>
            }
          </div>
          <div>
            <Typography className={classes.conPass}>Confirm Password:</Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type={showConfirmPw ? "text" : "password"}
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={onContentChangeEvent}
              placeholder="Re-enter password"
              className={classes.textField}
              error={!errors.confirmPassword.isValid}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showConfirmPw ? <FiEye onClick={habdleshowConfirmPW} /> :
                      <FiEyeOff onClick={habdleshowConfirmPW} />}
                  </InputAdornment>
                ),
              }}
            />

            {
              !errors.confirmPassword.isValid &&
              <p className={classes.errorMessage}>{errors.confirmPassword.message}</p>
            }
          </div>



          <div className={classes.buttons}>
            <Button type="submit" size="small"
              className={classes.submitButton} style={{ width: '50%' }}
              onClick={onSubmitForm}>
              Save</Button>
            <Button type="submit" size="small" variant="outlined" className={classes.backbutton}
              onClick={() => history.push('/')} style={{ width: '50%' }}>Back</Button>
          </div>
          <div  >

          </div>
        </form>
      </div>

    </div>



  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    popUpAlert: (err: any, instance: any, type: any) => dispatch(popUpActionCreator.initglobalPopup(err, instance, type))
  }
}

export default connect(null, mapDispatchToProps)(UpdatePassword)


import React from "react";
import {
  Card,
  Grid,
  Button,
  CardContent,
  Typography,
  FormControl,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import { useSelector } from 'react-redux'
// import UserNumbersServices from "../userNumbersServices/usersNumbersServices";
// import ProfileServices from '../../Profile/ProfileServices/ProfileServices'
import axiosInstance from '../../../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


const assignoptions = [
  { label: "Department", value: 1 },
  { label: "User", value: 2 }

]
function AssignNumber(props: any) {
  const classes = useStyles();
  const profileDataState: any = useSelector((state: any) => state.profileData)
  const [option, setOption] = React.useState('')
  const [userListData, setUserListData] = React.useState<any>([])
  const [departmentList, setDepartmentList] = React.useState<any>([])
  const [assignNumber, setAssignNumber] = React.useState({})
  const [numberAssign, setNumberAssign] = React.useState<any>(false)
  const [numberListData, setNumberListData] = React.useState<any>([])
  const [deptData, setDeptData] = React.useState([])
  const [deptNumber, setDeptNumbers] = React.useState([])
  const [userList, setUsersList] = React.useState([])
  const [userNumbers, setUserNumbers] = React.useState([])
  const [unAssignNumbers, setUnAssignNumbers] = React.useState([])
  //   const { phoneNUmberDetail } = props.NumberData
  //   const { id, phone } = phoneNUmberDetail
  const [deptList, setDeptList] = React.useState([])
  const [userEmail, setUserEmail] = React.useState([])
  // const { id, phone } = props?.item


  React.useEffect(() => {
    const departments: any = []
    if (props.departmentdata) {
      props.departmentdata.departmentList?.map((item: any) => {
        var myObj = {
          label: item.name,
          value: item._id
        }
        departments.push(myObj)
      })
      setDeptList(departments)
    }
  }, [props.departmentdata])

  const onChangeAssignType = (data: any) => {
    if (data.value === 2) {
      //fetchUserLists()
    }
    setOption(data.value)
  }

  const fetchPhoneNumbers = () => {
    axiosInstance.get(`/phoneNumbers`).then((res: any) => {
      if (res.status === 200) {
        const data = res.data.data[0]
        const userNumbers = res.data.data[0].userNumbers
        const userList = res.data.userList
        const deptNumbers = res.data.data[0].deptNumbers
        const departmentList = res.data.departmentList
        const unAssignedNumbers = res.data.data[0].unAssignedNumbers

        const departments: any = []
        departmentList.map((item: any) => {
          var obj: any = {
            label: item.name,
            value: item._id
          }
          departments.push(obj)
        })
        setDeptList(departments)

        const users: any = []
        userList.map((user: any) => {
          var obj: any = {
            label: user.email,
            value: user._id
          }
          users.push(obj)
        })
        setUserEmail(users)
      }
    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    fetchPhoneNumbers()
  }, [])


  //   const fetchDepartmentData = () => {
  //     const pageLimit = 50;
  //     UserNumbersServices.getDepartmentList(pageLimit)
  //       .then((res: any) => {
  //         if (res.success) {
  //           const mappedStateData: any = res.data.map((item: any) => {
  //             return { label: item.name, value: item.id };
  //           });
  //           setDepartmentList(mappedStateData);
  //           // console.log("----hiii departments---", res);
  //         }
  //       })
  //       .catch((err: any) => {

  //         //send err message
  //       });
  //   };
  //   const fetchUserLists = () => {
  //     UserNumbersServices.getUusersList().then((res) => {

  //       if (res.success) {
  //         setUserListData([])
  //         const mappedStateData: any = res.data.map((item: any, i: any) => {
  //           return { 
  //             label: item.userName !== undefined ? item.userName : `Null user ${i}`, value: item.id 
  //           };
  //         });

  //         setUserListData(mappedStateData)
  //       } else {

  //       }
  //     }).catch((err: any) => {

  //     })
  //   }

  //   const handleAssignNumber = () => {
  //     UserNumbersServices.assignNumbers(id, assignNumber).then((res) => {
  //       console.log("---assignNumber", res)
  //       if (res.success) {
  //         setNumberAssign(true)
  //         props.fetchNumberLists()
  //         props.handlePopupModal()

  //       }
  //       else {
  //         setNumberAssign(false)
  //       }
  //     }).catch((err: any) => {
  //       setNumberAssign(false)
  //     })
  //   }

  const handleassignNumbers = () => {
    axiosInstance.patch(`phoneNumbers/assign-phonenumber?phoneNumberId=${props.id}`, assignNumber).then((res: any) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1000
        })
        props.handleAssignNumberModal()
        props.fetchPhoneNumbers(1,10)
       
      }
    }).catch((err: any) => [

    ])
  }

  const changeAssign = (data: any) => {
    if (option == '1') {
      setAssignNumber({
        assignedFor: option,
        departmentId: data.value
      })
    } else {
      setAssignNumber({
        assignedFor: option,
        userId: data.value
      })
    }
  }



  return (
    <div >
      <Typography className={classes.assignedNum}> Number: {props.number}</Typography>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item md={6} className={classes.asigngridItem}>
          <Typography className={classes.text}>Assign for :</Typography>
          <ReactSelect
            fullWidth
            options={assignoptions}
            placeholder="Select "
            inputProps={{ "aria-label": "Without label" }}
            onChange={onChangeAssignType}
          />
        </Grid>
        <Grid item md={6}>
          <Grid container spacing={2} >
            {option == '1' ? (
              <Grid item md={12}>
                <Typography className={classes.text}>Select Department</Typography>
                <ReactSelect
                  fullWidth
                  placeholder="Select Department "
                  options={deptList}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={changeAssign}
                />
              </Grid>
            ) : ""}
            {option == '2' ? (
              <Grid item md={12}>
                <Typography className={classes.text}>Select User</Typography>
                <ReactSelect
                  fullWidth
                  options={userEmail}
                  placeholder="Select User "
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={changeAssign}
                />
              </Grid>
            ) : ""}
          </Grid>
        </Grid>
      </Grid>


      <Grid container spacing={2} style={{ textAlign: 'center', marginTop: 20 }}>
        <Grid item md={12}>
          <div>
            <Button size="medium" className={classes.AssignButton}
              onClick={handleassignNumbers}
            >Assign</Button>
            <Button size="medium" onClick={props.handleAssignNumberModal} className={classes.CancelAssignButton}>Cancel</Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AssignNumber;

import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from 'react-redux'
import { useStyles } from "./styles";
import axiosInstance from '../../../../environment/axiosInstance'
// import DepartmentServices from "../../../../Departments/DepartmentServices/DepartmentServices";

function AddNumber(props: any) {
    const classes = useStyles();
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, loading } = departmentStateData;
    const [submitted, setSubmitted] = React.useState(false);
    const [addName, setAddName] = React.useState({ phoneNumber: "", departmentId: selectedDepartment?._id });
    const [addNumber, setAddNumber] = React.useState({
        phoneNumber: ''
    })

    const onSubmit = () => {
        axiosInstance.post('/profile/settings/un-answered-call/forward-number', addNumber).then((res: any) => {
            if (res.status === 200) {
                props.handlePopupModal()
                props.getUnanswereddata()
            }
        })
    }
    const onChangeNumber = (data: any) => {
        const val = data.target.value
        if (val !== " ") {
            setAddNumber((prev: any) => {
                return {
                    ...prev,
                    phoneNumber: data.target.value.replace(/[^0-9,.]+/g, ""),
                };
            });
        }
    };

   

    // const addNumber = () => {
    //     setSubmitted(true);
    //     const errorData = validate(true);
    //     if (errorData.isValid) {
    //         DepartmentServices.postNumber(addName)
    //             .then((res: any) => {
    //                 if (res.success) {
    //                     props.handlePopupModal();
    //                     props.getOpenHours();
    //                 } else {
    //                 }
    //             })
    //             .catch((err: any) => {
    //                 // send error
    //             });
    //     }
    // };


    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            phoneNumber: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (addName.phoneNumber === "") {

                retData.phoneNumber = {
                    isValid: false,
                    message: "Please enter phone number"
                };
                isValid = false;
            }
            else if (addName.phoneNumber.length < 10) {
                retData.phoneNumber = {
                    isValid: false,
                    message: "Phone Number should be less than 10 digits"
                };
                isValid = false;
            }
            else if (addName.phoneNumber.length > 15) {
                retData.phoneNumber = {
                    isValid: false,
                    message: "Phone Number should not be greater than 15 digits"
                };
                isValid = false;
            }



        }
        return {
            ...retData,
            isValid
        };
    };
    const errors = validate(submitted);
    return (
        <div>
            <Card className={classes.modalCard}>
                <CardContent>
                    <Typography className={classes.userText}>
                        Number
                    </Typography>
                    <Grid container spacing={2} className={classes.gridItem}>
                        <Grid item md={12} className={classes.gridItem}>
                            <TextField
                                fullWidth
                                size="small"
                                placeholder="Enter Number"
                                value={addNumber.phoneNumber}
                                variant="outlined"
                                onChange={onChangeNumber}
                                error={!errors.phoneNumber.isValid}
                            />
                            {
                                !errors.phoneNumber.isValid &&
                                <p className={classes.errorMessage}>{errors.phoneNumber.message}</p>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item md={12}>
                            <div className={classes.bttns}>
                                <Button size="medium" className={classes.SaveButton} onClick={onSubmit}>Save</Button>
                                <Button size="medium" onClick={props.handlePopupModal} className={classes.cancelButton} >Cancel</Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        </div>
    );
}

export default AddNumber;

import React from 'react'
import {
    Grid, Button, Typography, Divider,
    Table, TableHead, TableCell, TableRow,
    Card,
    TextField,
    TableBody,
    CardContent,
    TableContainer,
    Accordion,
    AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import ReactSelect from 'react-select'
import { useStyles } from '../style'
import PurchaseNumbersTable from './PurchaseNumbersTable/PurchaseNumbersTable';

function PurchaseNumbers() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => {
        setOpen(!open)
    }
  return (
    <div>
        <Card className={classes.card}>
            <Accordion expanded={open}
                onChange={handleOpen}>
                <AccordionSummary
                    expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading} >
                        Phone Numbers
                    </Typography>
                </AccordionSummary>
                <Divider className={classes.divider} />
                
                <AccordionDetails className={classes.accordianNoFlex}>
                   
                    <PurchaseNumbersTable />
                    {/* <PhoneNumbersTable 
                        phoneNumbersData={phoneNumbersData}
                        paginate={paginate}
                        getPhoneNumbers={getPhoneNumbers}
                        loading={loading}
                        handleChangePage={handleChangePage}
                    /> */}
                </AccordionDetails>
            </Accordion>
        </Card>
    </div>
  )
}

export default PurchaseNumbers
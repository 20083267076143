import { Button, TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { GrNext } from 'react-icons/gr'
import { useHistory } from 'react-router-dom'
import { useStyles } from '../../style'

function TableBdy(props: any) {
    const classes = useStyles()
    const history = useHistory()
    return (
        <>
            <TableRow key={props.item.id}>
                <TableCell className={classes.tableCellBody}>{props.item.number}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.item.state}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.item.rateCenter}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.item.tier}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.item.vendorId}</TableCell>
                <TableCell className={classes.tableCellBody}>{props.item.vendorName}</TableCell>
                <TableCell className={classes.tableCellBody}>
                    <Button
                        size="small" variant='outlined'
                        style={{ background: '#219653', color: '#fff', fontSize: '12px', padding: '3px 9px' }}
                        onClick = {()=>history.push(`/phonenumbers/buyNumber/${props.item.number}/${props.item.state}/${props.item.rateCenter}/${props.item.tier}/${props.item.vendorId}/${props.item.vendorName}`)}
                     
                    >
                        Buy
                    </Button>
                </TableCell>
            </TableRow>
            {/* {
                props.item.length > 0 ?
                    <div style={{ float: "right", margin: "15px", cursor: "pointer", display: "flex" }} onClick={props.handleChangeCities}>
                        <Typography style={{ marginTop: "-4px" }}>Next</Typography> <GrNext onClick={props.handleChangeCities} />
                    </div>
                    : ""
            } */}
        </>
    )
}

export default TableBdy
import React from 'react'
import {
  TableCell,
  TableRow,
  Table, TableBody, TableContainer, TableHead,
  IconButton,
  Checkbox,
  Typography,
  Button,
  MenuItem,
  Grid,
  TextField,
  AccordionDetails,
  Card,
  CardContent
} from '@material-ui/core';
import { useStyles } from '../styles';
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import axiosInstance from '../../../../environment/axiosInstance'
import BusinessServices from "../BusinessAccountsServices/services"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import WebAccess from './WebAccess'
import PersonalInf from './PersonalIformatn'
function Form(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false);
  const [getDataById, setGetDataById] = React.useState<any>({
    firstName: "",
    lastName: "",
    // planId: "",
    status: 1,
    address: "",
    companyName: "",
    contactName: "",
    gstNumber: "",
    city: "",
    province: "",
    country: "",
    zipcode: "",
    users:""
  })
  const [selVal, setSelVal] = React.useState<any>(null)
  const [email, setEmail] = React.useState<any>('')
  const [selPlan, setSelPlan] = React.useState<any>(null)
  const [planlist, setPlanList] = React.useState<any>([]);
  const [Status] = React.useState<any>([
    { value: "1", label: "Enabled" },
    { value: "2", label: "Disabled" },
  ])

  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then((results) => {
        var myResults = results[0]
        var city = ""
        //var state = ""
        var country = ""
        var zipcode = ""
        var streetNumber = ""
        var streetName = ""
        var province = ""

        getLatLng(myResults).then((latLong) => {
          myResults.address_components.map((item) => {
            if (item.types.includes("locality")) {
              city = item.long_name
            }
            // if (item.types.includes("administrative_area_level_1")) {
            //   state = item.long_name
            // }
            if (item.types.includes("country")) {
              country = item.long_name
            }
            if (item.types.includes("postal_code")) {
              zipcode = item.long_name
            }
            if (item.types.includes("street_number")) {
              streetNumber = item.long_name
            }
            if (item.types.includes("route")) {
              streetName = item.short_name
            }
            if (item.types.includes("administrative_area_level_1")) {
              province = item.short_name
            }

          })
          setGetDataById(() => {
            return {
              ...getDataById,
              address: myResults.formatted_address,
              city: city,
              country: country,
              zipcode: zipcode,
              province: province,
              // companyName: companyName,
              // contactName: contactName,
              // gstNumber: gstNumber
            }
          });
        })
      })
      .catch(error => console.error('Error', error));
  };


  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      email: { ...validData },
      password: { ...validData },
      firstName: { ...validData },
      lastName: { ...validData },
      phoneNumber: { ...validData },
      // planId: { ...validData },
      country: { ...validData },
      address: { ...validData },
      companyName: { ...validData },
      contactName: { ...validData },
      city: { ...validData },
      status: { ...validData },
      province: { ...validData },
      zipcode: { ...validData },
      users: { ...validData }, 
    };
    let isValid = true;
    if (submitted) {
      if (getDataById.firstName === " ") {
        retData.firstName = {
          isValid: false,
          message: "Please enter first Name space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.companyName) {
        retData.companyName = {
          isValid: false,
          message: "Please enter Company Name"
        };
        isValid = false;
      }
      if (!getDataById.firstName) {
        retData.firstName = {
          isValid: false,
          message: "Please enter first Name"
        };
        isValid = false;
      }
      if (!getDataById.contactName) {
        retData.contactName = {
          isValid: false,
          message: "Please enter contact Name"
        };
        isValid = false;
      }
      if (getDataById.lastName === " ") {
        retData.lastName = {
          isValid: false,
          message: "Please enter Last name space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.lastName) {
        retData.lastName = {
          isValid: false,
          message: "Please enter Last name"
        };
        isValid = false;
      }
      if (getDataById.status === " ") {
        retData.status = {
          isValid: false,
          message: "Please enter status space is not allowed"
        };
        isValid = false;
      }

      // if (getDataById.planId === " ") {
      //   retData.planId = {
      //     isValid: false,
      //     message: "Please enter planId space is not allowed"
      //   };
      //   isValid = false;
      // }
      // if (!getDataById.planId) {
      //   retData.planId = {
      //     isValid: false,
      //     message: "Please enter planId"
      //   };
      //   isValid = false;
      // }
      if (getDataById.address === " ") {
        retData.address = {
          isValid: false,
          message: "Please enter address space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.address) {
        retData.address = {
          isValid: false,
          message: "Please enter address"
        };
        isValid = false;
      }
      if (getDataById.city === " ") {
        retData.city = {
          isValid: false,
          message: "Please enter city space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.city) {
        retData.city = {
          isValid: false,
          message: "Please enter city"
        };
        isValid = false;
      }
      if (getDataById.province === " ") {
        retData.province = {
          isValid: false,
          message: "Please enter province space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.province) {
        retData.province = {
          isValid: false,
          message: "Please enter province"
        };
        isValid = false;
      }
      if (getDataById.zipcode === " ") {
        retData.zipcode = {
          isValid: false,
          message: "Please enter postal Code space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.zipcode) {
        retData.zipcode = {
          isValid: false,
          message: "Please enter postal Code"
        };
        isValid = false;
      }
      if (getDataById.country === " ") {
        retData.country = {
          isValid: false,
          message: "Please enter country space is not allowed"
        };
        isValid = false;
      }
      if (!getDataById.country) {
        retData.country = {
          isValid: false,
          message: "Please enter country"
        };
        isValid = false;
      }
      if (!getDataById.users) {
        retData.users = {
          isValid: false,
          message: "Please enter number"
        };
        isValid = false;
      }
    }
    return {
      ...retData,
      isValid
    };
  };


  const getPlans = React.useCallback(() => {
    setLoading(true);
    axiosInstance.get('/admin/plans').then((res: any) => {
      if (res.data.data) {
        setPlanList(res.data.data)
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }, [])

  var PlanDropwdown: any = [];
  planlist.map((item: any) => {
    var object = {
      "value": item.id,
      "label": item.planName
    }
    PlanDropwdown.push(object)
  })

  React.useEffect(() => {
    getPlans()
  }, [])

  const handleChangeblocks = (e: any, name: any) => {
    // if (name === "planId") {
    //   setGetDataById((prev: any) => {
    //     return {
    //       ...getDataById,
    //       planId: e.value,
    //     }
    //   })
    // }
    if (name === "status") {
      const filterVal = Status.filter((option: any) => {
        const status =  option.value === e.value ? option.label : ""
        return option.label == status
      })
        if(filterVal) {
        setSelVal(filterVal)
      }
      setGetDataById((prev: any) => {
        return {
          ...getDataById,
          status: e.value,
        }
      })
    }
  };
  const onContentStatusChangeEvent = (e: any) => {
    const filterVal = Status.filter((option: any) =>
      option.value == e.value)
      if (filterVal) {
      setSelPlan(filterVal)
     }
    setGetDataById((prev: any) => {
      return {
        ...getDataById,
        status: e.value,
      }
    })
  }
  const onContentChangeEvent = (e: any) => {
    setGetDataById((prev: any) => {
      return {
        ...getDataById,
        [e.target.name]: e.target.value,
      }
    })

  }
  const handleChange = (data: any) => {
    setGetDataById(() => {
      return {
        ...getDataById,
        address: data
      }
    });
  };

  const Submitdata = () => {
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      BusinessServices.UpdateAccount(props.id, getDataById).then((res) => {
        if (res.success) {
          history.push("/businessAccounts")
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  console.log("cvdvbchjbd",props.data)

  React.useEffect(() => {
    const getData = props.data
    setGetDataById({
      firstName: getData.firstName,
      lastName: getData.lastName,
      // planId: getData.planId,
      status: getData.status,
      address: getData.address,
      companyName: getData.companyName,
      contactName: getData.contactName,
      gstNumber: getData.gstNumber,
      city: getData.city,
      province: getData.province,
      country: getData.country,
      zipcode: getData.zipcode,
      users:getData.users
    })
    setEmail(getData.email)
    const filterVal = Status.filter((option: any) => {
      const status = props.data.status === 1 ? "Enabled" : "Disabled"
      return option.label == status
    }
    )
    if (filterVal) {
      setSelVal(filterVal)
    }
  }, [props.data])
  const errors = validate(submitted);
  return (
    <div>

      <WebAccess email={email} />
      <PersonalInf errors={errors}
       onContentChangeEvent={onContentChangeEvent}
        PlanDropwdown={PlanDropwdown} handleChangeblocks={handleChangeblocks} selPlan={getDataById.planId}
        Status={Status} 
        getDataById={getDataById}
        handleSelect={handleSelect}
        handleChange={handleChange}
        selVal={selVal} onContentStatusChangeEvent={onContentStatusChangeEvent} />
      <Grid item md={12} style={{ marginTop: "26px", textAlign: 'center' }}>
        <Button
          size="small"
          variant="outlined"
          className={classes.searchButton}
          onClick={Submitdata}
        >
          Update
        </Button>
        <Button
          size="small"
          variant="outlined"
          className={classes.backButton}
          onClick={() => history.push("/businessAccounts")}

        >
          Back
        </Button>

      </Grid>


    </div >
  )
}
export default Form
import React from "react";
import {
  Card,
  Grid,
  Button,
  CardContent,
  Typography,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useStyles } from "./styles";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import ProfileServices from "../ProfileServices/ProfileServices";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function AddNumberModel(props: any) {
  const classes = useStyles();
  const [selectedNumber, setSelectedNumber] = React.useState<any>(null);
  const [startDate, setStartDate] = React.useState<any>(new Date());
  const [error, setError] = React.useState("");
  const [addBlockNumber, setAddBlockNumber] = React.useState({
    blockCalls: true,
    blockSms: true,
    lastCallReceived: " ",
    phoneNumber: "",
  });
  const [state, setState] = React.useState({
    checkedB: false,
  });
  const [statecheck, setStatecheck] = React.useState({
    checkedA: false,
  });
  const handleChange1 = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChange = (event: any) => {
    setAddBlockNumber((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.checked
      }
    })

  };
  const onChangeBlockNumber = (data: any) => {
    const val = data.target.value
    if (!val.match(/[%+-<>\\$'"]/)) {
      setError("");
    } else {
      setError("Not allowed +-");
    }
    setSelectedNumber(data.target.value)
    setAddBlockNumber((prev: any) => {
      return {
        ...prev,
        "phoneNumber": data.target.value,
      }
    })
  }
  const AddBlockNumberData = () => {
    const validation = addBlockNumber.phoneNumber === "" || addBlockNumber.phoneNumber.length > 15 || addBlockNumber.phoneNumber.length < 10
    if (validation) {
      toast.error("Enter valid phone number", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return
    }
    else if (!addBlockNumber.blockCalls && !addBlockNumber.blockSms) {
      toast.error("Enter check to block calls or sms", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return
    }
    const sendDt = addBlockNumber
    addBlockNumber.lastCallReceived = addBlockNumber.lastCallReceived.toString()
    ProfileServices.postBloackNumberData(addBlockNumber)
      .then((res: any) => {
        if (res.success) {
          props.handlePopupModal();
          // props.getBlockedNumberData();
          props.fetchAdvancedSetting()
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err);
        // send error
      });
  };
  const onChangeDateandTime = (date: any) => {
    // console.log(date)
    const Dateandtime = moment(date, 'YYYY.MM.DD HH:MM:SS').unix();
    const dt = Dateandtime * 1000
    // updateUserProfile(dndDatetime);
    setStartDate(dt);
    setAddBlockNumber((prev: any) => {
      return {
        ...prev,
        lastCallReceived: dt,
      }
    })
  };

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: 8, padding:'0px 10px'}}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography className={classes.textColorOne}>Add Number</Typography>
          <TextField
            className={classes.input}
            placeholder="Enter Number"
            value={addBlockNumber.phoneNumber}
            size="small"
            //type="number"
            variant="outlined"
            fullWidth
            onChange={onChangeBlockNumber}
          />
          <Typography className={classes.textColorOne}>Last Call Time</Typography>
          {/* <TextField type="date" size="small" variant="outlined" /> */}
          <div className="date_and_time_picker_section">
            <DatePicker
              selected={startDate}
              onChange={onChangeDateandTime}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              maxDate={new Date()}
              className={classes.inputdate}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              className={classes.formPadding}
              control={
                <Checkbox
                  checked={addBlockNumber.blockCalls}
                  onChange={handleChange}
                  name="blockCalls"
                  color="primary"
                />
              }
              label={<span className={classes.labelText}>Block Calls</span>}
            />
            <FormControlLabel
              className={classes.formPadding}
              control={
                <Checkbox
                  checked={addBlockNumber.blockSms}
                  onChange={handleChange}
                  name="blockSms"
                  color="primary"
                />
              }
              label={<span className={classes.labelText}>Block SMS</span>}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2} style={{ marginTop: 10,marginBottom:15, textAlign: 'center' }}>
        <Grid item md={12} sm={12} xs={12}>
          <div>
            <Button size="medium" className={classes.submitButton} onClick={AddBlockNumberData}>Submit</Button>
            <Button size="medium" onClick={() => props.handlePopupModal()} className={classes.cancelButton}>Cancel</Button>
          </div>
        </Grid>
      </Grid>




    </div>
  );
}

export default AddNumberModel;

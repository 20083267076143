import React from 'react'
import {
    Grid, Button, Typography, Divider,
    Table, TableHead, TableCell, TableRow,
    Card,
    TextField,
    TableBody,
    CardContent,
    TableContainer,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useStyles } from '../style'
import TableBodyData from './TableBodyData';

function PhoneNumbersTable(props:any) {
    const classes = useStyles()
    const history = useHistory()
  return (
    <div>
        <div>
            <div className={classes.dflex} >
                <Typography className={classes.accountHeading}>
                    {/* Total Records:{props.paginate.totalDocs} */}
                </Typography>
                <div style={{display:'flex',gap:10}}>
                    <Button
                        className={classes.addbutton}
                        variant="outlined"
                        size="small"
                        onClick={() => history.push('/add-phonenumber')}
                    >
                        Add New
                    </Button>
                    <Button
                        className={classes.purchaseButton}
                        variant="outlined"
                        size="small"
                        onClick={() => history.push('/phonenumbers/purchaseNumber')}
                    >
                        Purchase
                    </Button>
                </div>
            </div>
            <Divider className={classes.divider} style={{ marginTop: "10px" }} />

            <Card  style={{ marginTop: '20px' }}>
                <TableContainer>
                    <Table >
                        <TableHead className={classes.tablecolor}>
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    Phone number
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Assign to
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Status
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                     Created Date
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Action
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                             { 
                                props.loading === false && props.phoneNumbersData.length > 0 && 
                                    props.phoneNumbersData?.map((Data: any, i: any) => <TableBodyData
                                    getPhoneNumbers={props.getPhoneNumbers}
                                    paginate={props.paginate}
                                    loading={props.loading}
                                    phoneNumbersData={Data}
                                    key={i}
                                    />
                
                                )
                            }
                        </TableBody>
                         
                    </Table>
                    {
                        props.loading ? 
                        <div className='loader_class'>
                            <div style={{ width: "100%",marginLeft: '130px', textAlign: "center" }}>
                                <CircularProgress />
                            </div>
                        </div> 
                        : 
                        props.phoneNumbersData.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                    }
                 </TableContainer>
            </Card>
            {props.phoneNumbersData.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 15, float: "right", marginBottom: 15 }}>
                    <Pagination count={props.paginate.totalPages} page={props.paginate.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />
                </Stack>
            }
        </div>
    </div>
  )
}

export default PhoneNumbersTable
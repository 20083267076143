import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: 10,
        },
        card: {
            margin: '15px 0px 0px 0px',
            borderRadius: 10,
            background: '#FFFFFF',
            boxShadow: '0 0 0.5em #E2E2E2',
        },
        textField: {
            marginTop: 5,
        },
        select: {
            marginTop: 8,
        },
        errorMessage: {
            color: 'red',
            fontSize: '0.8rem',
            fontWeight: 500,
            marginTop: 0,
            marginBottom: 0,
        },

        heading: {
            color: "#2645AD",
            fontSize: 26,
            fontWeight: 600,
            fontFamily: "Work Sans",
            fontStyle: "normal",
        },
        accordianNoFlex: {
            display: 'block'
        },
        tableContainer: {
            marginTop: 20,
            width: '100%',
            boxShadow: '0 0 0.5em #E2E2E2',
            borderRadius: 10,
            borderColor: '#E2E2E2',
        },
        divider: {
            margin: 5,
            backgroundColor: '#E2E2E2',
        },
        searchText: {
            color: "#464646",
            fontWeight: 500,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: 20,

        },
        searchButton: {
            // marginTop: 20,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "4px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
        },
        clearButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            color: "#FFFFFF",
            border: "0.791301px solid #8392AB",
            background: "#B70909",
            padding: "4px 20px",
            borderRadius: "2px",
            marginLeft: 20,
            // marginTop: 20,
        },
        dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 },
        accountHeading: {
            color: "#464646",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 20,

        },
        addbutton: {
            marginTop: 5,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "5px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },

        },
        tableCellBody: {
            padding: 10,
            fontSize: 16,
            textAlign:"left",
            fontFamily: 'Work Sans',
            fontWeight: 500,
            color: '#939292',
        },
        activeButton: {
            background: "#009A19",
            border: "1px solid #00C21F",
            color: "white",
            fontWeight: 600,
            height: 21,
            width: 55,
            fontSize: 11,
            fontFamily: "Lato",
            fontStyle: "normal",
            '&:hover': {
                background: "#009A19",
                color: "white",
            }
        },
        textColor: {
            color: '#464646',
            marginLeft: 10,
            fontWeight: 'bold',
        },
        tableHead: {
            fontSize: "14px",
            color: "#25282B",
            textAlign:"left",
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Work Sans",
        },
        tableBody: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.8rem",
            color: "#787878",
        },
        inputFields: {
            margin: '10px 0px 20px',
            display: 'flex',
            alignItems: 'center'
        },
        tablecolor: {
            backgroundColor: "#fbfbfb",
        },
        saveButton: {
            border: "0.898113px solid #3A416F",
            borderRadius: 5,
            background: "#3A416F",
            color: " #FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            marginBottom: 20,
            textTransform: "capitalize",
            fontSize: 14,
            padding: "6px 20px",
            // width: "40%",
            "&:hover": {
                background: "#3A416F",
                color: " #FFFFFF",
            },
        },
        cancelButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: 14,
            color: "#8392AB",
            // width: "40%",
            borderRadius: 5,
            marginLeft: 30,
            padding: "6px 20px",
            marginBottom: 20,
        },
        inactiveButton: {
            background: "crimson",
            border: "1px solid crimson",
            color: "white",
            fontWeight: 600,
            height: 21,
            width: 55,
            fontSize: 11,
            fontFamily: "Lato",
            fontStyle: "normal",
            '&:hover': {
                background: "crimson",
                 color: "white",
            }
        },
        deleteButton: {
            margin: 10,
            background: '#4D72F8',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            padding: '6px 22px',
            color: '#FFFFFF',
            fontSize: 14,
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            }
        },
        CancelButton: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            borderRadius: 5,
            color: '#8392AB',
            fontSize: 14,
            padding: '6px 22px',
        },
        labelOne: {
            // padding: '50px 0px',
            //marginTop: 0,
            //  color: "#8392AB",
            fontFamily: "open Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 12,
            color: "#8392AB",
        },
        textFieldOne: {
            marginTop: 4,
            color: "#8392AB"
        },
        addName: {
            color: "#8392AB",
            fontSize: 16,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            // marginTop: 25,
        },
        deviceWidth: {
            width: 30,
            height: 30,
            borderRadius: 50,
            color: 'black',
            textAlign: "center",
            // backgroundColor: '#F3F5FF',
            // fill:'#4D72F8',
            padding: '8px',
        },
        buttons: {
            display: 'flex'
        }


    })
);
import React from 'react'
import { Button, Card, Grid, Typography, Tabs, Tab, } from '@material-ui/core'
import { useStyles } from '../style'
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../../../../components/Modal/Backdrop';
import Modal from '../../../../../../components/Modal/Modal'
import { TabContext, TabPanel } from '@material-ui/lab';
import RecordAudio from './RecordAudio';
import UploadAudio from './UploadAudio';
import { BiPlusCircle } from 'react-icons/bi'
// import AddNumber from './AddNumber';
import axiosInstance from '../../../../../../environment/axiosInstance'
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AddNumber from './AddNumber';
toast.configure()

const muiTheme = createMuiTheme({});
const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });


function ForwardNumber(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [addNumberModal, setAddNumberModal] = React.useState(false);
    const [frwNumber, setFrwNumber] = React.useState([])
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }

    const handlePopupModalNumber = () => {
        setAddNumberModal(!addNumberModal)
    }

    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }

    React.useEffect(() => {
        const numbers: any = []
        if (props.openHoursData) {
            props.openHoursData.unAnsweredCalls?.forwardNumbers?.phoneNumbers?.map((item: any) => {
                var myObj = {
                    label: item.phoneNumber,
                    value: item._id,
                    isSelected: item.isSelected
                }
                numbers.push(myObj)
            })
            setFrwNumber(numbers)
        }
    }, [props.openHoursData])

    const selectedNum = frwNumber.findIndex((option: any) => {
        if (option.isSelected === true) {
            return option
        }
    })



    //update number
    const onChangePhoneNumberDropDown = (e: any) => {
        const id = e.value
        const data = {
            id: id
        }
        axiosInstance.patch(`/departments/${props.depId}/agents/unanswered-calls/forward-number`, data).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getOpenHours()
            }

        }).catch((err: any) => {
            //send err
        })

    }

    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            openHoursData={props.openHoursData}
                                            getOpenHours={props.getOpenHours}
                                            depId={props.depId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadAudio
                                            openHoursData={props.openHoursData}
                                            getOpenHours={props.getOpenHours}
                                            depId={props.depId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }
            {
                addNumberModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalNumber}>
                            <div className="adduserpopup" >
                                <AddNumber depId={props.depId} handlePopupModal={handlePopupModalNumber} getOpenHours={props.getOpenHours} />
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div style={{ marginLeft: '32px' }}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={6} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item md={4} className={classes.addnumContainer}>
                            <Typography className={classes.title}>Enter Number</Typography>
                            <div
                                className={classes.button}
                                style={{ border: '1px solid lightgrey' }}
                                onClick={handlePopupModalNumber}
                            >
                                <BiPlusCircle />
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <ReactSelect
                                options={frwNumber}
                                value={frwNumber[selectedNum]}
                                onChange={onChangePhoneNumberDropDown}
                                placeholder="Select Number"
                                className={classes.forwardNumAudio}
                            />
                        </Grid>
                    </Grid>


                    <div className={classes.cardStyle1}>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <div className={classes.audioPlayerscont}>
                                        <ThemeProvider theme={muiTheme}>
                                            <ReactAudioPlayer
                                                controls
                                                // className={classes.audioPlayer}
                                                onLoadedMetadata={handleLoadMetadata}
                                                src={props.forwardPath}
                                                className={classes.audioPlayers}
                                            />

                                        </ThemeProvider>

                                        <Button className={classes.editbtn} onClick={handlePopupModalAdd}>
                                            Edit
                                        </Button>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <span className={classes.filename}>{props.forwardFileName}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                </Card>
            </div>
        </>
    )
}

export default ForwardNumber
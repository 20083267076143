import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactSelect from "react-select";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import { useStyles } from "../styles";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import AddonServices from '../AddonServices/services'

function AddNewUser(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const [addOpen, setAddOpen] = React.useState<any>(true);
  const [value, setValue] = React.useState<any>("True");
  const [countrieslist, setCountriesList] = React.useState<any>([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [addonTypes] = React.useState<any>([
    { value: "1", label: "Calls" },
    { value: "2", label: "SMS" },
    { value: "3", label: "Features" }
  ])
  const [addonFormData, setAddonFormData] = React.useState<any>({
    addonName: "",
    addonCost: "",
    addonType: "",
    countries: [],

    freeCount: "",
    status: 1,
  })

  const getCountries = () => {
    AddonServices.getCountries().then((res) => {
      if (res.data) {
        setCountriesList(res.data.data)
      }
    })
  }


  React.useEffect(() => {
    getCountries();
  }, [])

  var CountryList: any = [];
  countrieslist.map((item: any) => {
    var object = {
      "value": item.id,
      "label": item.CountryName
    }
    CountryList.push(object)
  })

  const onContentChangeEvent = (e: any) => {
    const data = e.target.value
    if (data !== " ") {
      setAddonFormData((prev: any) => {
        return {
          ...addonFormData,
          [e.target.name]: e.target.value,
        }
      })
    }

  }

  const onContentAddonTypeChangeEvent = (e: any) => {
    setAddonFormData((prev: any) => {
      return {
        ...addonFormData,
        addonType: e.value,
      }
    })
  }

  const onContentCountryChangeEvent = (e: any) => {
    const data: any = [];
    e.map((item: any) => {
      data.push(item.value)
    })
    setAddonFormData((prev: any) => {
      return {
        ...addonFormData,
        countries: data,
      }
    })
  }

  const handleChangeData = (e: any) => {
    setAddonFormData((prev: any) => {
      return {
        ...addonFormData,
        status: e.value
      }
    })
  }

  const [status] = React.useState<any>([
    { value: 1, label: "Active" },
    { value: 2, label: "InActive" }
  ])



  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const retData = {
      addonName: { ...validData },
      addonType: { ...validData },
      addonCost: { ...validData },
      countries: { ...validData },
      discount: { ...validData },
      freeCount: { ...validData },
      status: { ...validData },

    };
    let isValid = true;
    if (submitted) {
      if (addonFormData.addonName === " ") {
        retData.addonName = {
          isValid: false,
          message: "Please enter addon name"
        };
        isValid = false;
      }
      if (!addonFormData.addonName) {
        retData.addonName = {
          isValid: false,
          message: "Please enter addon name"
        };
        isValid = false;
      }
      if (!addonFormData.addonType) {
        retData.addonType = {
          isValid: false,
          message: "Please enter addon type"
        };
        isValid = false;
      }
      if (format.test(addonFormData.addonCost)) {
        retData.addonCost = {
          isValid: false,
          message: "Please enter valid addon cost"
        };
        isValid = false;
      }
      if (addonFormData.addonCost === " ") {
        retData.addonCost = {
          isValid: false,
          message: "Please enter addon cost"
        };
        isValid = false;
      }
      if (!addonFormData.addonCost) {
        retData.addonCost = {
          isValid: false,
          message: "Please enter addon cost"
        };
        isValid = false;
      }

      if (addonFormData.countries.length === 0 && addonFormData.addonType != "3") {
        retData.countries = {
          isValid: false,
          message: "Please enter countries"
        };
        isValid = false;
      }
      if (format.test(addonFormData.freeCount)) {
        retData.freeCount = {
          isValid: false,
          message: "Please enter valid freeCount"
        };
        isValid = false;
      }
      if (!addonFormData.freeCount && addonFormData.addonType != "3") {
        retData.freeCount = {
          isValid: false,
          message: "Please enter free minutes"
        };
        isValid = false;
      }

    }
    return {
      ...retData,
      isValid
    };
  };


  const onSubmitForm = () => {
    setSubmitted(true);
    const errorData = validate(true);
    var myObject: any = {
      addonName: addonFormData.addonName,
      addonCost: addonFormData.addonCost,
      addonType: addonFormData.addonType,
      discount: addonFormData.discount,
    }
    if (addonFormData.addonType != "3") {
      myObject["freeCount"] = addonFormData.freeCount
      myObject["status"] = addonFormData.status
      myObject["countries"] = addonFormData.countries
    }
    if (errorData.isValid) {
      AddonServices.postAddon(myObject).then((res: any) => {
        if (res.data.data) {
          setAddonFormData({
            addonName: "",
            addonCost: "",
            addonType: "",
            countries: [],
            discount: "",
            freeCount: "",
            status: 1,

          })
          history.push('/addons')
        } else {
          setLoading(false)

        }
      }).catch((err: any) => {
        setLoading(false)

      })
    }
  }

  const onChangeEvent = () => {
    setAddOpen(!addOpen);
  };

  const errors = validate(submitted);


  return (
    <div>
      <Card className={classes.card}>
        <Accordion expanded={addOpen}
          onChange={onChangeEvent}>
          <AccordionSummary
            expandIcon={addOpen ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Addons-Add New
            </Typography>
          </AccordionSummary>
          <Divider className={classes.divider} />
          <AccordionDetails style={{ marginTop: 15 }}>
            <Grid container spacing={6}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName}>Addon Name</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="addonName"
                      value={addonFormData.addonName}
                      onChange={onContentChangeEvent}
                      className={classes.textField}
                      placeholder="Enter Addon Name"
                      error={!errors.addonName.isValid}
                    />
                    {
                      !errors.addonName.isValid &&
                      <p className={classes.errorMessage}>{errors.addonName.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName}>
                      Addon Type
                    </Typography>

                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <Select
                      variant="outlined"
                      fullWidth
                      onChange={onContentAddonTypeChangeEvent}
                      name="addonType"
                      options={addonTypes}
                      className={classes.select}
                      error={!errors.addonType.isValid}
                    />
                    {
                      !errors.addonType.isValid &&
                      <p className={classes.errorMessage}>{errors.addonType.message}</p>
                    }
                  </Grid>
                </Grid>
                {addonFormData.addonType === "3" ? "" :
                  <Grid container className={classes.inputFields}>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                      <Typography className={classes.addName}>
                        Select Country
                      </Typography>
                    </Grid>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                      <Grid>
                        <Select
                          //defaultValue={[colourOptions[2], colourOptions[3]]}
                          name="countries"
                          isMulti
                          options={CountryList}
                          onChange={onContentCountryChangeEvent}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          error={!errors.countries.isValid}
                        />
                        {
                          !errors.countries.isValid &&
                          <p className={classes.errorMessage}>{errors.countries.message}</p>
                        }
                      </Grid>
                    </Grid>
                  </Grid>}

              </Grid>
              {/* end first col-6 */}
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName}>Addon Cost($)</Typography>

                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="addonCost"
                      onChange={onContentChangeEvent}
                      className={classes.textField}
                      placeholder="Enter Addon Cost"
                      error={!errors.addonCost.isValid}
                    />
                    {
                      !errors.addonCost.isValid &&
                      <p className={classes.errorMessage}>{errors.addonCost.message}</p>
                    }
                  </Grid>
                </Grid>


                {addonFormData.addonType === "3" ? "" :
                  <Grid container className={classes.inputFields}>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                      <Typography className={classes.addName}>Status</Typography>

                    </Grid>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                      <Select
                        placeholder="Select"
                        name="status"
                        options={status}
                        value={
                          status.filter((option: any) =>
                            option.value === addonFormData.status)
                        }
                        onChange={handleChangeData}
                      />
                    </Grid>
                  </Grid>}
                {addonFormData.addonType === "3" ? "" :
                  <Grid container className={classes.inputFields}>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                      <Typography className={classes.addName}>Free Count</Typography>
                    </Grid>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                      <Grid>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          onChange={onContentChangeEvent}
                          name="freeCount"
                          className={classes.textField}
                          placeholder="Enter Free Count"
                          error={!errors.freeCount.isValid}
                        />
                        {
                          !errors.freeCount.isValid &&
                          <p className={classes.errorMessage}>{errors.freeCount.message}</p>
                        }
                      </Grid>
                    </Grid>
                  </Grid>}

              </Grid>
              {/* end last col-6 */}
            </Grid>
          </AccordionDetails>

          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item md={12} style={{ textAlign: "center" }}>
                <Button
                  //fullWidth
                  size="small"
                  variant="outlined"
                  className={classes.saveButton}
                  onClick={onSubmitForm}
                >
                  Add
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.cancelButton}
                  onClick={() => history.push("/addons")}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
    </div>
  );
}

export default AddNewUser


import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import {
    TableCell,
    TableRow,
} from '@material-ui/core';
import { useStyles } from '../styles';
import ProfileServices from '../../../ProfileServices/ProfileServices'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { BsThreeDots } from 'react-icons/bs'
import Moment from 'moment'


function MydeviceTableBody(props: any) {
    const classes = useStyles();
    const [rindDeivce, setRingDevice] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: any) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const { id, deviceType, deviceName, lastLoginDate,createdDate, ringDevice } = props.device;

    const onChangeRingDevice = () => {
        setRingDevice(!rindDeivce)
        updateRingDevice({ ringDevice: !rindDeivce })
    }

    React.useEffect(() => {
        setRingDevice(ringDevice)
    }, [])

    const updateRingDevice = (data: any) => {
        ProfileServices.updateDeviceRing(id, data).then((res: any) => {
            if (res.success) {
                // props.getMyDeviceLists()
            } else {

            }
        }).catch((err) => {

        })
    }

    const deleteDevice = () => {
        ProfileServices.deleteMydevice(id).then((res: any) => {
            if (res.success) {
                props.getMyDeviceLists()
                setOpen(false);
            } else {

            }
        }).catch((err) => {

        })
    }
    var data = parseInt(createdDate)
    //var dataa = Moment(data).format(' ddd MMM DD ')
    var dataa = Moment(data).format(' YYYY-MM-DD HH:mm:ss ')
    // var dataa = Moment(data).format(' YYYY.MM.DD')

    return (
        <TableRow>
            <TableCell className={classes.tableBody}>{deviceType === 1 ? "Android" : deviceType === 2 ? "IOS" : deviceType === 3 ? "Home Phone" : ""}</TableCell>
            <TableCell className={classes.tableBody}>{deviceName}</TableCell>
            <TableCell className={classes.tableBody}>{dataa}</TableCell>
            <TableCell>
                <Checkbox
                    checked={rindDeivce}
                    onChange={onChangeRingDevice}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </TableCell>
            <TableCell className={classes.tableBody}>
                <div>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <BsThreeDots />
                    </Button>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} >
                                            <MenuItem onClick={deleteDevice}>Delete</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </TableCell>

        </TableRow>
    )
}

export default MydeviceTableBody

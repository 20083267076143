import React from 'react'
import {
    TableCell,
    TableRow,
    Table, TableBody, TableContainer, TableHead,
    IconButton,
    Checkbox,
    Typography,
    Button,
    MenuItem,
    Grid,
    TextField,
    Card,
    CardContent
} from '@material-ui/core';
import Moment from 'moment'
import { Link } from 'react-router-dom';
import { useStyles } from './styles'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { MdDelete } from "react-icons/md"
import { AiFillEdit } from "react-icons/ai";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { BsThreeDots } from "react-icons/bs";
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal'
import BusinessServices from "./BusinessAccountsServices/services"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';
import {FaRegMoneyBillAlt} from 'react-icons/fa'


export default function BusinessTableBody(props: any) {
    const classes = useStyles();
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
    const [editModal, setEditModal] = React.useState(false)
    const [editId, setEditId] = React.useState<any>('')
    const [DeleteModal, setDeleteModal] = React.useState(false)
    const [delId, setDelId] = React.useState<any>('')

    const anchorRef: any = React.useRef(null);
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handlePopupDeletePlan = (id: any) => {
        setDeleteModal(!DeleteModal)
        setDelId(id)
    }

    const handleEditModal = (id: any) => {
        setEditModal(!editModal)
        setEditId(id)
    }



    const deleteBusinessAccountData = () => {
        BusinessServices.deleteBusinessUser(delId).then((res: any) => {
            if (res.success) {
                props.fetchBusinessUsers(props.paginate.limit, props.paginate.page)
                setDeleteModal(!DeleteModal)
            } else {
            }
        }).catch((err: any) => {
        })
    }
    var datadate = parseInt(props.PlanData.createdDate)
    var dataa = Moment(datadate).format('YYYY-MM-DD HH:mm:ss')
    const changeBackground = (e: any) => {
        e.target.style.color = 'blue';
        e.target.style.textDecoration = "underline"
    }
    const MouseOut = (event: any) => {
        event.target.style.color = "#8392AB";
        event.target.style.textDecoration = "none"
    }


    return (
        <>
            <div>
                {
                    DeleteModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupDeletePlan} />
                            <Modal title="Are you sure to delete this Business account ?" handlePopupModal={handlePopupDeletePlan} >
                                <Grid container spacing={2} style={{ margin: 15 }}>
                                    <div className="add_user_popup" style={{ width: "500px" }}>
                                        <Grid item md={12} style={{ textAlign: 'center' }}>
                                            <div>
                                                <Button size="medium" className={classes.deleteButton} onClick={deleteBusinessAccountData}>Delete</Button>
                                                <Button size="medium" onClick={handlePopupDeletePlan} className={classes.CancelButton}>Cancel</Button>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Modal>
                        </div>
                    )
                }
            </div>

            <TableRow >
                <TableCell className={classes.tableCellBody}>
                    <Link to={`/editbusinessAccounts/${props.PlanData?.id}`} style={{ cursor: 'pointer', color: '#8392AB', textDecoration: 'none', }} onMouseOver={changeBackground} onMouseOut={MouseOut}>
                        {props.PlanData?.firstName}  {props.PlanData?.lastName}
                    </Link>
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    <Link to={`/editbusinessAccounts/${props.PlanData?.id}`} style={{ cursor: 'pointer', color: '#8392AB', textDecoration: 'none', }} onMouseOver={changeBackground} onMouseOut={MouseOut}>
                        {props.PlanData?.email}
                    </Link>
                </TableCell>
                {/* <TableCell className={classes.tableCellBody}>{props.PlanData?.planId?.planName}</TableCell> */}
                <TableCell className={classes.tableCellBody}>{dataa}</TableCell>
                <TableCell className={classes.tableCellBody}>
                    {props.PlanData?.status === 1 ?
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.activeButton}>Active</Button> :
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.inactiveButton}>InActive</Button>}
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    <div>
                        <Button
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <BsThreeDots />
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper style={{ borderRadius: "0px 10px 10px 10px", background: '#F3F5FF' }}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={() => history.push(`/editbusinessAccounts/${props.PlanData?.id}`)} >
                                                    <AiFillEdit
                                                    onClick={() => history.push(`/editbusinessAccounts/${props.PlanData?.id}`)}
                                                    className={classes.deviceWidth} />

                                                    Edit</MenuItem>

                                                <MenuItem onClick={() => handlePopupDeletePlan(props.PlanData?.id)}>
                                                    <MdDelete
                                                    className={classes.deviceWidth}
                                                    onClick={() => handlePopupDeletePlan(props.PlanData?.id)} />
                                                    Delete</MenuItem>
                                                    <MenuItem onClick={()=>history.push({pathname:'/mainadmin-billing',state:props.PlanData?.id})}>
                                                        <FaRegMoneyBillAlt 
                                                            
                                                             className={classes.deviceWidth}
                                                        />
                                                        Billing
                                                    </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
            </TableRow>

        </>
    )
}

import React from 'react'
import {
    TableCell,
    TableRow,
    Table, TableBody, TableContainer, TableHead,
    IconButton,
    Checkbox,
    Typography,
    Button,
    Accordion,
    Grid,
    AccordionSummary,
    TextField,
    AccordionDetails,
    Card,
    CardContent,
    Divider
} from '@material-ui/core';
import { useStyles } from '../styles';
import Select from 'react-select';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'

export default function WebAccess(props: any) {
    const classes = useStyles();
    const [weblogin, setWeblogin] = React.useState<any>(true);
    const onChangeWeblogin = () => {
        setWeblogin(!weblogin);
    };
    return (
        <div style={{ marginLeft: "20px" }}>

            <Typography className={classes.security}>Security</Typography>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Grid container className={classes.marginTop10}>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                            <Typography className={classes.userName2}>Email</Typography>
                        </Grid>
                        <Grid xl={8} lg={8} md={6} sm={6} xs={12}>
                            <Typography className={classes.userName2} style={{ color: "black" }}>{props.email}</Typography>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>


        </div >
    )
}

import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class BusinessServices {

    getBusinessUsers = async (limit: any, page: any) => {
        try {
            const response = await axiosInstance.get(`/admin/business-accounts/?limit=${limit}&page=${page}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getBusinessAccount = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/admin/business-accounts/${id}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getBusinessAccountBySerach = async (data: any) => {
        console.log("---data", data)
        try {
            const response = await axiosInstance.get(`/admin/business-accounts?limit=50&${data.searchData.firstNameKey}=${data.searchData.firstNameValue}&${data.searchData.lastNameKey}=${data.searchData.lastNameValue}&${data.searchData.emailKey}=${data.searchData.emailValue}&${data.searchData.planIdKey}=${data.searchData.planIdValue}&${data.searchData.statusKey}=${data.searchData.statusValue}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            //toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }

    deleteBusinessUser = async (id: any) => {
        try {
            const response = await axiosInstance.delete(`/admin/business-accounts/${id}`);
            if (response.data.data) {
                const data = response.data.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    postBusinessAccount = async (sendData: any) => {
        try {
            const response = await axiosInstance.post("/admin/business-accounts", sendData);

            if (response.data) {
                const data = response.data;
                toast.success("Business user added successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    UpdateAccount = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/admin/business-accounts/${id}`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                // console.log("----updated switch", response.data)
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };


}
const business = new BusinessServices()
export default business

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    marLt: {
        marginTop: "125px", 
        marginLeft: "160px",
        "@media (max-width: 1024px)":{
            marginLeft: 90,
        },
        "@media (max-width: 768px)":{
            marginLeft: 40,
        }
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
}))
import { Accordion, AccordionDetails, AccordionSummary, Card, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useStyles } from './styles'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { BiEdit, } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import { useLocation, Redirect } from 'react-router-dom'
import axiosInstance from '../../environment/axiosInstance'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'


function DepartmentsNumbers() {

    const classes = useStyles()
    const location = useLocation()
    const [expanded, setExpanded] = React.useState<any>(true);
    const departmetStateData = useSelector((state: any) => state?.departmentModule);
    const id = useParams()
    const obj: any = id
    const [name, setName] = React.useState<any>();

    React.useEffect(() => {
        if (obj.id) {
            departmetStateData?.departmentsData?.map((item: any) => {
                if (item.id === obj.id) {
                    setName(item.name)
                }
            })
        }

    }, [departmetStateData, obj.id])

    const [selectAlwaysOpen, setselectAlwaysOpen] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const expandOpen = () => {
        setOpen(!open);
        setExpanded(!expanded);
    }
    const [departmentNumbers, setDepartmentNumbers] = React.useState<any>([])
    // console.log('charan',departmentNumbers)

    const fetchNumbers = () => {
        axiosInstance.get(`/departments/${obj.id}/phone-number`).then((res: any) => {
            //    console.log(res)
            if (res.status === 200) {
                setDepartmentNumbers(res.data)
            }
        }).catch((err)=>{
            //send err
        })
    }
    React.useEffect(() => {
        if (obj.id) {
            fetchNumbers()
        }
    }, [obj.id])
    // console.log(departmentNumbers)

    // React.useEffect(()=> {n

    //     localStorage.setItem('departmentUrl',location.pathname)

    // }, [location.pathname])

    // const departmentUrl : any = localStorage.getItem('departmentUrl')
    // console.log('departmentUrl__',departmentUrl)
    // if(departmentUrl !== undefined && departmentUrl !== "" && departmentUrl !== '/department'){
    //     return <Redirect to={departmentUrl} />

    // }

    return (
        <div>
            {/* <Card className={classes.card}>
                <Accordion
                    expanded={expanded}
                    onChange={expandOpen}
                >
                    <AccordionSummary
                        expandIcon={expanded ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.dflex}>
                            <Typography className={classes.heading}>{name}</Typography>
                            <BiEdit style={{ color: 'lightgrey' }} />
                        </div>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <div className={classes.dflex}>
                        <Typography className={classes.callFlow}>Call Flow</Typography>
                        <BiEdit style={{ color: 'lightgrey', marginTop: 30 }} />
                    </div>
                    <div>
                        <Grid item md={12}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 50 }}>
                                <Typography className={classes.departmentheading}>Department Number(s)</Typography>
                                <AiOutlinePlusCircle style={{ fontSize: 20, color: 'rgb(77, 114, 248)' }}
                                />
                            </div>
                        </Grid>
                    </div>
                    <Divider style={{ marginTop: 8 }} />
                    <div>
                        <Grid container spacing={2}>
                            <Grid item md={4} style={{ marginLeft: 16, marginTop: 30, marginBottom: 30 }}>
                                <TableContainer style={{ border: '1px solid #F1F1F1', borderRadius: 5 }}>
                                    <Table>
                                        <TableHead>

                                            <TableRow className={classes.tRow}>
                                                <TableCell className={classes.tCell}>Numbers</TableCell>
                                                <TableCell className={classes.tCell}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                departmentNumbers.length > 0 ?
                                                    departmentNumbers?.map((item: any) => {
                                                        // const no = item?.phone
                                                        // const newNo = `(${no?.slice(0, 3)}) ${no?.slice(3)}`
                                                        return (
                                                            <TableRow>
                                                                <TableCell className={classes.tbody}>{item.phone}</TableCell>
                                                                <TableCell>
                                                                    <BsThreeDots className={classes.tbody} />
                                                                </TableCell>
                                                            </TableRow>

                                                        )
                                                    })

                                                    :
                                                    (<TableRow>
                                                        <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                                                            No Department Numbers Found
                                                        </TableCell>

                                                    </TableRow>)

                                            }


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item md={6}></Grid>
                        </Grid>
                    </div>
                </Accordion>
            </Card> */}
        </div>
    )
}

export default DepartmentsNumbers
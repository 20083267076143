import { Avatar, Button, Card, Grid, TextField, Typography } from '@material-ui/core'
import profileImg from '../../assets/images/profileImg.png'
import { useStyles } from './style'
import './style.css'
import socket from '../../containers/Authentication/Config/socket';
import { useHistory } from "react-router-dom";
import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../contexts/ConversationState'
import { CiSearch } from 'react-icons/ci';
import axiosInstance from '../../environment/axiosInstance'
import ConverSation from './ConverSation';
import { GoPrimitiveDot } from 'react-icons/go';
import ChatModalBackDrop from '../../components/ChatModal/ChatModalBackDrop';
import ChatModal from '../../components/ChatModal/ChatModal';
import CreateGroup from './CreateGroup/CreateGroup';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function LeftConversation(props: any) {
    const classes = useStyles()
    const history = useHistory()
    const { conversations, addConversation, getConversation } = useContext(GlobalContext);
    const [search, setSearch] = React.useState('')
    const [searchedResultData, setSearchedResultData] = React.useState([])
    const [coversationList, setConversationList] = React.useState<any>([])
    const [issearching, setIssearching] = React.useState(false);
    const { searchResult, chats, list, userId } = props;
    const [chatModalOpen, setChatModalOpen] = React.useState(false)
    const [modalOpen, setModalOpen] = React.useState(false)
    const [groupName, setGroupName] = React.useState('')


    // const onSearchFun = (e: any) => {
    //     setSearch(e.target.value)
    //     console.log('target', e.target.value)
    //     const filterData = searchResult.filter((search: any) => search?._id.toUpperCase().includes(e.target.value.toUpperCase()));
    //     console.log('filterData', filterData)
    //     console.log('searchResult', searchResult)

    //     setSearchedResultData(filterData)
    // }

    // React.useEffect(() => {
    //     setSearchedResultData(searchResult)

    // }, [])
    // console.log('datt', props.searchResult);

    //fetchPeople function is called when we focus on the search box


    const fetchPeople = () => {
        axiosInstance.get(`/contacts?isEfoneNumber=1`).then((res: any) => {
            const data: any = res.data.Data
            if (res.status !== 200) {
                searchResult.length = 0;
            }
            else {
                setSearchedResultData(data)
            }
        })
    }



    React.useEffect(() => {
        if (list.length > 0) {
            setConversationList(list)
            list.forEach((el: any) => {
                // console.log('chat', el._id);
                socket.emit('user-join-room', { roomId: el._id });
            });
            if (!props.chatId && list.length > 0) {
                history.push(`/messages/${list[0]._id}`)
                // socket.emit('user-join-room', { roomId: list[0]._id });

            }
        }
    }, [list]);



    const createNewChat = (userdata: any) => {
        const { efoneId, _id, firstName } = userdata
        history.push(`/messages/${_id}`)
        let conversationCopy: any = [...coversationList]
        conversationCopy.unshift(userdata)
        setConversationList(conversationCopy)
        // props.listConversations()
    }


    const openConversation = (id: any) => {
        history.push(`/messages/${id}`)
        socket.emit('user-join-room', { roomId: id });
        // axiosInstance.get(`/conversation?id1=${userId}&id2=${id}`).then((res: any) => {
        //     if (res.status === 200) {
        //         const newConversation = getConversation()
        //         if (newConversation) {
        //         } else {
        //             addConversation(res._id);
        //             socket.emit('new-conversation', {
        //                 //     conversation: obj.conversation,
        //                 createId: userId
        //             });
        //         }
        //         // updateSearchFocusing(false);
        //         // updateMouseOverResult(false);
        //         // history.push(`/chat/${obj.conversation._id}`);
        //     }
        // })
    }



    const handlePopupAddGroup = () => {
        setChatModalOpen(!chatModalOpen)
        // groupNameEmpty()
    }

    const handleContactsmodal = () => {
        setModalOpen(!modalOpen)
    }

    const groupNameEmpty = () => {
        setGroupName('')
    }


    return (
        <div>
            {
                chatModalOpen && (
                    <div>
                        <ChatModalBackDrop handlePopupModal={handlePopupAddGroup} />
                        <ChatModal handlePopupModal={handlePopupAddGroup}>
                            <div className="add_user_popup" style={{ width: '300px', padding: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                                    <div>
                                        <Avatar className={classes.pink}>
                                            <CameraAltIcon />
                                        </Avatar>
                                    </div>
                                    <div>
                                        <TextField
                                            size='small'
                                            label="Group Name"
                                            autoFocus={true}
                                            value={groupName ? groupName : ''}
                                            name="groupName"
                                            onChange={(e: any) => setGroupName(e.target.value)}
                                        />
                                    </div>

                                </div>
                                <div className={classes.dFlex}>
                                    <Button size='small' className={classes.cancelBtn}
                                        onClick={handlePopupAddGroup}
                                    >
                                        Cancel</Button>
                                    <Button size='small' className={classes.cancelBtn}
                                        onClick={() => {
                                            if (groupName === "") {
                                                toast.error("Please enter group name", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
                                                return
                                            }
                                            handleContactsmodal()
                                            handlePopupAddGroup()
                                        }}>
                                        Next
                                    </Button>
                                </div>

                            </div>
                        </ChatModal>
                    </div>
                )
            }
            {
                modalOpen && (
                    <div>
                        <ChatModalBackDrop handlePopupModal={handleContactsmodal} />
                        <ChatModal handlePopupModal={handleContactsmodal}>
                            <div className="add_user_popup">
                                <div style={{ marginTop: 5, display: 'flex' }}>
                                    <CreateGroup
                                        handlePopupAddGroup={handlePopupAddGroup}
                                        searchResult={searchResult}
                                        handleContactsmodal={handleContactsmodal}
                                        groupName={groupName}
                                        listConversations={props.listConversations}
                                        groupNameEmpty={groupNameEmpty}
                                    />
                                </div>
                            </div>
                        </ChatModal>
                    </div>
                )
            }
            <Card className={classes.leftCard}>
                <div>
                    <div style={{ top: 10, overflow: 'hidden' }} className='mx-2 w-full px-4 mt-1 hidden md:flex items-center relative mb-4'>
                        <svg
                            className='h-4 w-4 fill-current text-gray-700 absolute left ml-4'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                        >
                            <path d='M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z'></path>
                        </svg>
                        <input
                            type='search'
                            className='w-full px-12 py-2 bg-gray-200 text-gray-700 rounded-full outline-none'
                            placeholder='Search by name...'
                            onFocus={() => {
                                fetchPeople()
                                setIssearching(true)

                            }}
                            onBlur={event => {
                                setTimeout(() => {
                                    setIssearching(false)
                                }, 500)

                            }}
                            onChange={event => {
                                // onSearchFun(event)
                                props.searchPeople(event.target.value);
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            onClick={handlePopupAddGroup}
                            className={classes.createRoomBtn}
                            size='small' variant='outlined'>
                            Create Group
                        </Button>
                    </div>
                </div>

                {
                    issearching ? (
                        <ConverSation
                            joinRoom={openConversation}
                            createNewChat={createNewChat}
                            coversationList={list}
                            listConversations={props.listConversations}
                            coversationType="SEARCH"
                            list={searchedResultData}
                            userId={userId}
                        />
                    ) : (
                        <ConverSation
                            joinRoom={openConversation}
                            createNewChat={createNewChat}
                            coversationList={list}
                            listConversations={props.listConversations}
                            coversationType="COVERSATION_LIST"
                            list={coversationList}
                            userId={userId}
                        />
                    )
                }
            </Card>
        </div>
    )
}

export default LeftConversation
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class userServices {

    getAllUsers = async () => {
        try {
            const response = await axiosInstance.get("/users/getAllUsers");
            if (response.data.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    searchEmail = async (data: any) => {
        try {
            const response = await axiosInstance.post("/users/search", data);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    fetchUsers = async () => {
        try {
            const response = await axiosInstance.get("/users?limit=50");
            if (response.data.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    CreateUserList = async (data: any) => {
        try {
            const response = await axiosInstance.post("/users", data);
            console.log("userservices", response)
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data.message, data: null };
            }
        } catch (error: any) {
            console.log("userservices", error)
            toast.error(error.response.data.error, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    updateUserPermissions = async (data: any, id: any) => {
        try {
            const response = await axiosInstance.patch(`/users?userId=${id}`, data);
            if (response.data.data) {
                const data = response.data.data;
                toast.success("UserDetails Updated Successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    updateUserDepartmentPermissions = async (data: any, id: any) => {
        try {
            const response = await axiosInstance.patch(`/users/update-user-department-permission?userId=${id}`, data);
            if (response.data.data) {
                const data = response.data.data;
                toast.success("UserDetails Updated Successfully", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    addUsertoDepartment = async (data: any, userId: any) => {
        try {
            const response = await axiosInstance.post(`/users/add-user-to-department?userId=${userId}`, { departmentIds: [data] });
            // console.log("addUsertoDepartment", response.data.message)
            if (response.statusText === 'OK') {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                return { success: true }
            }
            else {
                return { success: false, message: response?.data.message, data: null };
            }

        } catch (error: any) {
            console.log("addUsertoDepartment", error)
            toast.error(error.response.data.error, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    deleteUser = async (id: any) => {
        try {
            const response = await axiosInstance.delete(`/users/${id}`);
            if (response.data.data) {
                const data = response.data.data;
                // console.log("data",data,response)
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    unAsignUserfromDept = async(userId: any,val: any)=>{
        try {
            const response = await axiosInstance.delete(`/users/remove-user-to-department?userId=${userId}`, { data: val });
            if (response.statusText === 'OK') {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                return { success: true }
            }
            else {
                return { success: false, message: response?.data.message, data: null };
            }

        } catch (error: any) {
            console.log("addUsertoDepartment", error)
            toast.error(error.response.data.error, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

}
const userservices = new userServices()
export default userservices;
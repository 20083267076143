import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tabCard: {
    borderRadius: 10,
    // height: 49,
    background: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  },
  bFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availableButton: {
    fontSize: 16,
    textTransform: 'capitalize',
    fontFamily: 'work sans',
    padding: '-1px 20px',
  },
  tabs: {
    padding: 10,
    paddingBottom: 10,
  },
  tab: {
    minHeight: 10,
    // height: 30,
    borderRadius: 5,
    background: '#4D72F8',
    boxSizing: 'border-box',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#FFFFFF',
    textTransform: 'none',
  },
  tabActive: {
    minHeight: 10,
    // height: 30,
    borderRadius: 5,
    background: '#FAFAFA',
    boxSizing: 'border-box',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#B8B8B8',
    textTransform: 'none',
  },
  gridItem: {
    width: '100%',
  },
  text: {
    marginTop: 5,
    marginBottom: 5,
    color: '#464646',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  availableCard: {
    marginTop: 25,
    borderRadius: 10,
    background: '#FFFFFF',
    border: '1px solid #E4E4E4'
    //boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  },
  addNumber: {
    marginLeft: 8,
    background: '#377DEF',
    color: 'white',
    border: '1px solid #377DEF',
    textTransform: 'capitalize',
    fontSize: '15',
    padding: '8px 20px',
    borderRadius: '5px',
    '&:hover': {
      background: '#377DEF',
      color: 'white',
    }
  },
  cancelNumber: {
    marginLeft: 8,
    background: '#C4C5C6',
    color: 'rgba(0, 0, 0, 0.5)',
    border: '1px solid #C4C5C6',
    textTransform: 'capitalize',
    fontSize: '15',
    padding: '8px 20px',
    borderRadius: '5px',
    '&:hover': {
      background: '#C4C5C6',
      color: 'rgba(0, 0, 0, 0.5)',
    }
  },
  divider: {
    //margin: 5,
    backgroundColor: '#E2E2E2',
  },
  numberButton: {
    display: 'flex',
    marginTop: "10px",
    background: '#F3F3F3',
    border: '1px solid #B8B8B8',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    width: "76%",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "blue",

    },
  },
  numberActive: {
    backgroud: 'red'
  },
  dropdownselect: {
    width: "200px",

  },
  viewbatchButton: {
    display: 'flex',
    margin: "10px auto",
    background: '#F3F3F3',
    border: '1px solid #B8B8B8',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    width: '220px',
    padding: '6px',
    color: '#8392AB',
  },
  vanitytext: {
    display: 'flex',
    // width:'297px',

  },
  Numbersgrid:{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '2px',
    marginTop:13
  },
  NumberData:{
    height: '50px',
    //border: '1px solid black'
    // background: 'black'
  },
  forward:{

  },
  Numbers: {
    // display: "flex",
    // flexWrap: "wrap",
    // justifyContent: "space-between",
    // width: "100%",
  },
  vanityText2: {
    width: '100%',
    height: '38px',
    border: '1px solid #ccc',
    fontSize: '14px',
    // borderLeftColor: 'transparent',
    borderRadius: '0px 3px 3px 0px',
    position: 'relative',
    zIndex: 0,
    paddingLeft: 10,
    borderLeft: '1px solid #ccc',
    left: '-2px',
    "&:focus": {
      border: "2px solid #4D72F8",
    },
  },
  nextIcon: {
    background: '#ECECEC',
    float: 'right',
    margin: 10,
    '&:hover': {
      background: '#ECECEC',
    },
    "&:active": {
      backgroundColor: "blue",
    },
  },
  nextIconActive: {
    background: '#4D72F8',
    float: 'right',
    margin: 10,
    '&:hover': {
      background: '#ECECEC',
    },
    "&:active": {
      backgroundColor: "blue",
    },
  },
  numberButtonActive: {
    display: 'flex',
    marginTop: "10px",
    background: '#4D72F8',
    border: '1px solid #B8B8B8',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    width: "76%",
    fontWeight: 600,
    color: 'white',
    '&:hover': {
      backgroundColor: '#4D72F8',
    },
    '&:active': {
      backgroundColor: '#4D72F8',
    },
  },
  icon: {
    // background: '#cfcfcf',
    borderRadius: 14,
    color: '#8392AB'
  },
  errMsg: {
    lineHeight: "18px",
    background: "#fff8e1",
    borderRadius: "2px",
    border: " 1px solid #efd581",
    color: "#6c6344",
    padding: "14px",
    display: "table",
    textAlign: "center",
    marginBottom: "18px",

  },
}))
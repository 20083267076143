import React from 'react'
import {
    Grid, Button, TextField
} from '@material-ui/core';
import { useStyles } from './styles';
import Select from 'react-select'
import PlanServices from '../Plan/PlanServices'
export default function SearchFilter(props: any) {
    const classes = useStyles();
    const [planData, setPlanData] = React.useState<any>([]);
    const handleClear = () => {
        props.clearFilter()
    }

    const [status] = React.useState<any>([
        { value: 1, label: "Active" },
        { value: 2, label: "InActive" }
    ])
    const [options] = React.useState<any>([
        { value: "firstName", label: "First Name" },
        { value: "lastName", label: "Last Name" },
        { value: "email", label: "Email" },
    ])
    const onContentStatusChangeEvent = (e: any) => {
        props.onChangeSearchData("status", e.value)
    }

    const handleChangeValue = (e: any) => {
        props.onChangeSearchData('id', e.value)
    }
    const handleChangeValueOne = (e: any) => {
        props.onChangeSearchData(e.target.name, e.target.value)
    }


    const searchData = () => {
        props.searchedData(props.paginate.limit, props.paginate.page)
    }
    const getPlans = () => {
        PlanServices.getPlansforbusiness().then((res) => {
            if (res.data) {
                setPlanData(res.data.data)
            }
        })
    }
    const onContentCountryChangeEvent = (e: any) => {
        props.onChangeSearchData("planId", e.value)
    }
    var PlanList: any = [];
    planData.map((item: any) => {
        var object = {
            "value": item.id,
            "label": item.planName
        }
        PlanList.push(object)
    })

    React.useEffect(() => {
        getPlans()
    }, [])

    return (
        <div style={{ marginTop: "5px" }}>
            <Grid container spacing={2}>
                <Grid item lg={2} md={3} sm={6} xs={12} >
                    <Select
                        variant='outlined'
                        size="small"
                        fullWidth
                        options={options}
                        name="id"
                        onChange={handleChangeValue}
                        placeholder='Select options'
                        value={
                            options.filter((option: any) =>
                                option.value === props.data.id)
                        }
                    />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12} >
                    <TextField
                        variant='outlined'
                        size="small"
                        fullWidth
                        value={props.data.searchValue}
                        placeholder={props.data.id == "firstName" ? "Enter First Name"
                            : props.data.id == "lastName" ? "Enter Last Name"
                                : props.data.id == "email" ? "Enter Email"
                                    : "Enter Key..."}
                        onChange={handleChangeValueOne}
                        name="searchValue"
                    />
                </Grid>
                {/* <Grid item md={2} sm={6} xs={12}>
                    <TextField size="small"
                        variant="outlined"
                        placeholder="First Name"
                        value={props.data.firstNameValue}
                        onChange={handleChangeValue}
                        name="firstName"
                        fullWidth
                    />
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                    <TextField size="small"
                        variant="outlined"
                        placeholder="Last Name"
                        value={props.data.lastNameValue}
                        onChange={handleChangeValue}
                        name="lastName"
                        fullWidth
                    />
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                    <TextField size="small"
                        variant="outlined"
                        placeholder="Email"
                        value={props.data.emailValue}
                        onChange={handleChangeValue}
                        name="email"
                        fullWidth
                    />
                </Grid> */}
                {/* <Grid item md={2} sm={6} xs={12}>
                    <Select
                        //defaultValue={[colourOptions[2], colourOptions[3]]}
                        name="planId"
                        value={
                            PlanList.filter((option: any) =>
                                option.value === props.data.planIdValue)
                        }
                        options={PlanList}
                        onChange={onContentCountryChangeEvent}
                        className="basic-multi-select"
                        classNamePrefix="select"

                    />
                </Grid> */}
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <Select
                        placeholder="Select Status"
                        name="status"
                        value={
                            status.filter((option: any) =>
                                option.value === props.data.status)
                        }
                        onChange={onContentStatusChangeEvent}
                        options={status} />
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.searchButton}
                            onClick={searchData}
                        >
                            Search
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.clearButton}
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </div>

                </Grid>
            </Grid>

        </div>
    )
}

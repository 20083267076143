import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles((theme) => ({
  padding: {
    //padding: 15,
  },
  card: {
    marginTop: 15,
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  divider: {
    backgroundColor: '#E2E2E2',
    marginTop: -10
  },
  departmentNameText: {
    fontSize: 18,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    textTransform: "none",
    // margin: '15px 20px 10px 22px',
    color: '#4D72F8',
    marginTop: 25,
    marginLeft: 16
  },
  heading: {
    color: "#4D72F8",
    fontSize: 20,
    fontWeight: 600,
    marginLeft: 16,
  },
  gridJustify: {
    marginTop: '40px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    marginBottom: 20,
    "@media (max-width: 1024px)": {
      gridTemplateColumns: 'auto auto auto',
    },
    "@media (max-width: 426px)": {
      gridTemplateColumns: 'auto auto',
      display: 'inline-grid'
    }
  },
  accordianNoFlex: {
    display: 'block'
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 10,
  },
  cardWidth: {
    display: 'flex',
    justifyContent: 'center',
    // height: '100px',
    // width: '180px',
    width: '125px',
    height: '90px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: '#4D72F8',
  },
  cardWidth1: {
    display: 'flex',
    justifyContent: 'center',
    // height: '100px',
    // width: '180px',
    width: '125px',
    height: '90px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: 'FFFFFF',
  },
  align: {
    textAlign: 'center',
  },
  deviceWidth: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#8392AB',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },
  deviceWidth1: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#4D72F8',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb',
    
  },
  devicesText: {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]:{
      fontSize: '11px',
    }
  },
  devicesText1: {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#464646',
    [theme.breakpoints.down('sm')]:{
      fontSize: '11px',
    }
  },
  greetText: {
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.217535,
    color: '#8392AB',
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioBtnsContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  uploadButton: {
    padding: '4px 10px',
    color: '#787878',
    background: '#F4F4F4',
    borderRadius: 3,
    width: '100px',
    height: '35px',
    border: '1px solid #ccc',
  },
  deleteIconText: {
    fontSize: 12,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0.217535,
    margin: 3,
    color: '#787878',
  },
  deleteIconColor: {
    fontSize: 12,
    margin: 3,
    color: '#787878',
    borderColor: '#F4F4F4',
    '&:hover': {
      color: '#787878',
      borderColor: '#F4F4F4',
    },
    '&:active': {
      color: '#787878',
      borderColor: '#F4F4F4',
    },
  },
  gridItemCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    position: 'absolute',
    padding: 20,
    right: 0,
    borderRadius: 1,
    color: '#787878',
    background: '#F4F4F4',
    borderColor: '#F4F4F4',
  },
  borderColorIcon: {
    fontSize: 10,
    margin: 3,
    color: '#696969',
  },
  borderColorIconText: {
    margin: 3,
    fontSize: 10,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#696969',
    letterSpacing: 0.217535,
  },
  iconButton: {
    width: '100px',
    height: '35px',
    border: '1px solid #ccc',
    padding: '4px 8px',
    borderRadius: 5,
    color: '#FFFFFF',
    background: '#F4F4F4',
    '&:hover': {
      background: '#DDDDDD',
    },
    '&:active': {
      background: '#DDDDDD',
    },
  },
  vollen1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: '10px',
  },
  vollen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  typographywidth: {
    marginBottom: '27px',
    marginLeft: '20px',
  },
  dropdownwidth: {
    width: '440px',
    bottom: '17px',
    left: '20px',
  },
  cardStyle: {
    padding: 30,
    background: '#FFFFFF',
    boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
    borderRadius: 10
  },
  title: {
    color: '#A0A0A0',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Calibri',
    marginBottom: 15
  },
  audioPlayer: {
    display: "block",
    width: "70%",
    height: 40,
    borderRadius: 'none',
    '&.selected': {
      color: '#0921a9'
    },
    '&:hover': {
      color: '#4D72F8'
    },
  },
  editBtn: {
    background: '#4D72F8',
    marginTop: '-70px%',
    marginTLeft: '90%',
    borderRadius: 5,
    color: '#fff',
    padding: '6px 25px',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#fff',
      background: '#4D72F8',
    }
  },
  filename: {
    fontFamily: 'Calibri !important',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "12px",
    color: '#848282',
    marginTop: '15px'

  },
  audioPlayers: {
    width: '590px',
    height: '44px',
    backgroundColor: "#E9ECED ! important",
    border: '1px solid #F0F3F5',
    borderRadius: '5px',
  },
  uploadContainer: {
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
  },
  selected: {
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    background: 'rgba(118, 144, 238, 0.5)',
    border: '2px solid #4D72F8',
    borderRadius: '4px'
  },
  unSelected: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
    border: '1px solid #F0F3F5'
  },
  audioTrack: {
    width: '500px', background: '#E9ECED'
  },
  audioTrackActive: {
    width: '500px', background: 'rgba(118, 144, 238, 0.5)',
  },
}));

import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
import { BorderColor, Delete, Save } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import ReactSelect from 'react-select';
import Select from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi'
import AudioPlayer from 'material-ui-audio-player'
import ProfileServices from '../../ProfileServices/ProfileServices'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import * as userActionCreators from '../../../../store/Actions/userActionCreator'

const useStyleses = makeStyles(
  (theme: any) => {
    return {
      root: {
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
      },
      loopIcon: {
        color: '#4D72F8',
        '&.selected': {
          color: '#0921a9'
        },
        '&:hover': {
          color: '#4D72F8'
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      playIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8',
        },
      },
      pauseIcon: {
        color: '#4D72F8',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8',
        },
      },
      volumeSlider: {
        color: '#4D72F8'
      },
      progressTime: {
        color: '#6b6b6d'
      },
      mainSlider: {
        color: '#4D72F8',
        '& .MuiSlider-rail': {
          color: '#4D72F8'
        },
        '& .MuiSlider-track': {
          color: '#4D72F8'
        },
        '& .MuiSlider-thumb': {
          color: '#4D72F8'
        }
      }
    }
  });

function Voicemails(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [newAudioUpload, setNewAudioUpload] = React.useState(false)
  const [shouldRecord, setShouldRecord] = React.useState(false)
  const [fileName, setFileName] = React.useState('')
  const [selectedAudio, setselectedAudio] = React.useState<any>('')
  const [fileBuffer, setFileBuffer] = React.useState<any>('')
  const audioFileInput: any = React.createRef();
  const [Loading, setLoading] = React.useState<any>(false)
  const [uploadId, setUploadId] = React.useState<any>('')
  const [dropDownValues, setDropDown] = React.useState<any>([]);
  const [selectedAudioFile, setSelectedAudioFile] = React.useState<any>({})
  const [upperDropDownData, setupperDropDownData] = React.useState<any>(null)
  const [voicemailArray, setVoicemailArray] = React.useState<any>([])
  const [dropDownState, setDropDownState] = React.useState<any>({
    callHandlingValue: '',
    callHandlingOption: props?.callHandlingOption
  })
  const [dropDownStateValue, setDropDownStateValue] = React.useState<any>({
    callHandlingValue: ''
  })

  const profileStoreData = useSelector((state: any) => state.profileData);
  const { profileData, loading } = profileStoreData;
  const [uploadingFile, setUploadingFile] = React.useState<any>(false)


  const shouldRecordChange = () => {
    setShouldRecord(!shouldRecord)
  }

  const uploadFileClick = () => {
    setUploadingFile(true)
    setNewAudioUpload(true)
    if (audioFileInput.current) {
      audioFileInput.current.click()
    }

  }
  const onChangeAudioFile = (e: any) => {
    const fileData = e.target.files;
    var file: any = fileData[0]
    setFileBuffer(file)
    let reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setselectedAudio(reader.result)
    };

    setFileName(fileData[0]?.name)
    onPostDataToServer(file, fileData[0]?.name)
  }



  const updateOpenHoursData = (data: any) => {
    setLoading(true)
    ProfileServices.updateOpenHours(data).then((res: any) => {
      if (res.success) {
        setLoading(false)
        props.getOpenHours()
        dispatch(userActionCreators.initUserProfile())
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }

  const onChangeVoicemail = (data: any) => {

    setDropDownStateValue((prev: any) => {
      return {
        ...prev,
        callHandlingValue: data.value
      }
    })

    if (voicemailArray.length > 0) {
      const filterFile = voicemailArray.find((voice: any) => voice.id === data.value)
      if (filterFile) {
        setSelectedAudioFile(filterFile)
        setFileName(filterFile.fileName)
        setselectedAudio(filterFile.filePath)
        setFileBuffer(filterFile.filePath)
        setDropDownState((prev: any) => {
          return {
            ...prev,
            callHandlingValue: filterFile?.id
          }
        })
        // setTimeout(() => {
        updateOpenHoursData({ ...dropDownState, callHandlingValue: filterFile?.id })
        // }, 1500)
      }
    }
  }
  React.useEffect(() => {
    if (props.openHours) {
      const openHours = props.openHours
      const voicemailsArray: any = openHours?.voicemails;

      if (voicemailsArray && voicemailsArray.length > 0) {
        setVoicemailArray(voicemailsArray)
      }
      const filterVoiceMail = voicemailsArray?.map((voice: any) => {
        return {
          label: voice.fileName,
          value: voice.id
        }
      })
      setupperDropDownData(filterVoiceMail)

      if (props.data) {
        const defaultData = props.data
        const filterDepartmentVal = voicemailsArray?.find((dept: any) => dept.id === defaultData.voicemail.currentSelectedValue)
        if (filterDepartmentVal) {
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: filterDepartmentVal.id
            }

          })
          setselectedAudio(filterDepartmentVal.filePath)
          setDropDownState((prev: any) => {
            return {
              ...prev,
              callHandlingValue: filterDepartmentVal.id
            }
          })

        }


      }
    }


  }, [props.openHours, props.data])


  const onPostDataToServer = (fileBuffer: any, fileName: any) => {
    let formData = new FormData()
    formData.append('fileName', fileName)
    formData.append('type', '1')
    formData.append('hoursType', '1')
    if (uploadingFile) {
      formData.append('file', fileBuffer)
      formData.append('soundType', 'Voicemail')
      setLoading(true)
      ProfileServices.uploadFileData(formData).then((res) => {
        if (res.success) {
          //success fully uploaded
          console.log("-------fileBuffer", res)
          onChangeVoicemail({ label: res.data?.data.fileName, value: res?.data.data.id })
          setupperDropDownData([...upperDropDownData, { label: res.data?.data.fileName, value: res?.data?.data.id }])
          updateOpenHoursData({ ...dropDownState, callHandlingValue: res?.data?.data.id })
          setUploadingFile(false)
          setLoading(false)
          props.getOpenHours()
          setNewAudioUpload(false)
          setVoicemailArray((prev: any) => {
            return [...prev, res.data]
          })
          setSelectedAudioFile(res?.data?.data)
          setFileBuffer(res?.data)
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: res?.data?.data.id
            }
          })
        } else {

        }
      }).catch((err) => {
        //send err
        console.log(err)
      })
    } else {

      if (selectedAudioFile) {
        setLoading(true)
        ProfileServices.patchFileData(selectedAudioFile?.id, formData).then((res) => {
          if (res.success) {
            //success fully uploaded
            setupperDropDownData([...upperDropDownData, { label: res.data?.fileName, value: res?.data.id }])
            setUploadingFile(false)
            setVoicemailArray([...voicemailArray, res.data])
            setSelectedAudioFile(res?.data)
            setLoading(false)
            setFileBuffer(res?.data)
            props.getOpenHours()
            setDropDownStateValue((prev: any) => {
              return {
                ...prev,

                callHandlingValue: res?.data?.id
              }
            })

          } else {
            setLoading(false)
          }
        }).catch((err) => {
          //send err
          console.log(err)
          setLoading(false)
        })
      }

    }
  }



  const deleteAudioFile = () => {
    if (selectedAudioFile) {
      setLoading(true)
      ProfileServices.deleteFileData(selectedAudioFile?.id).then((res) => {
        if (res.success) {
          //success fully uploaded
          updateOpenHoursData({ ...dropDownState })
          setupperDropDownData(upperDropDownData.filter((dpvals: any) => dpvals.value !== selectedAudioFile?.id))
          setVoicemailArray(voicemailArray.filter((dpFil: any) => dpFil.id === selectedAudioFile?.id))
          setUploadingFile(false)
          setFileName("")
          setSelectedAudioFile(null)
          setLoading(false)
          setselectedAudio('')
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: null
            }
          })

        } else {

        }
      }).catch((err) => {
        //send err
        console.log(err)
      })
    }
  }

  return (
    <>
      {Loading || loading ?
        <Box sx={{ display: 'flex', marginTop: "25px", marginLeft: "520px" }}>
          <CircularProgress />
        </Box> : (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.greetText}>To VoiceMail Greeting</Typography>
              <Grid container spacing={5}>
                <Grid item md={5} sm={12} xs={12} className={classes.grid}>
                  {/* <ReactSelect
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    placeholder={props.placeholder}
                    options={upperDropDownData}
                    value={dropDownStateValue?.callHandlingValue}
                    onChange={onChangeVoicemail}
                  /> */}
                  <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    placeholder={"select voicemail"}
                    options={upperDropDownData}
                    value={
                      upperDropDownData?.filter((option: any) =>
                        option.value === dropDownStateValue.callHandlingValue)
                    }
                    onChange={onChangeVoicemail}
                  />
                  <div className={classes.radioBtnsContainer}>
                    <IconButton className={classes.uploadButton} onClick={shouldRecordChange}>
                      {
                        shouldRecord ? (
                          <BiRadioCircleMarked size={15} color="#787878" />
                        ) : (
                          <BiRadioCircle size={15} color="#787878" />
                        )
                      }
                      <Typography className={classes.deleteIconText}>Record</Typography>
                    </IconButton>
                    <IconButton className={classes.uploadButton} onClick={uploadFileClick}>
                      <PublishIcon className={classes.deleteIconColor} />
                      <Typography className={classes.deleteIconText} >Upload</Typography>
                      <div style={{ display: 'none' }}>
                        <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
                      </div>
                    </IconButton>
                  </div>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Card className={classes.card}>

                    <CardContent>
                      <Grid container spacing={5} className={classes.gridItemCard}>
                        <Grid item md={9} sm={8} xs={12} className={classes.gridItemCard}>
                          <TextField
                            variant={'outlined'}
                            fullWidth
                            size="small"
                            label={fileName}
                            value={fileName}
                            onChange={(e: any) => setFileName(e.target.value)}
                            placeholder="No File selected"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  variant={'filled'}
                                  className={classes.deleteButton}>
                                  <BorderColor className={classes.borderColorIcon} />
                                  <Typography className={classes.borderColorIconText}>
                                    Rename
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} className={classes.gridItemCard}>
                          <IconButton className={classes.iconButton} onClick={deleteAudioFile}>
                            <Delete className={classes.deleteIconColor} />
                            <Typography className={classes.deleteIconText}>Delete</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={5} className={classes.gridItemCard}>
                        <Grid item md={9} sm={8} xs={12} className={classes.vollen1}>

                          <ReactAudioPlayer
                            controls
                            className={classes.audioPlayer}
                            // onLoadedMetadata={handleLoadMetadata}
                            src={selectedAudio}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} className={classes.vollen}>
                          <IconButton className={classes.iconButton} onClick={() => onPostDataToServer(fileBuffer, fileName)}>
                            <Save className={classes.deleteIconColor} />
                            <Typography className={classes.deleteIconText}>Save</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        )
      }
    </>
  );
}
export default Voicemails
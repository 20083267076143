import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    permissName: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        color: '#929292',
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalwidth: {
        width: 600,
        // "@media (max-width: 768px)":{
        //   width : 500
        // }
    },
    addNewOper: {
        textTransform: 'initial',
        background: '#FEFEFE',
        border: '1px solid #4D72F8',
        borderRadius: '4px',
        fontFamily: 'Calibri',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        color: '#4D72F8',
        [theme.breakpoints.down('xs')]: {
            width: 85, height: 35,
            fontSize: '12px',
        }
    },
    audioTransferContainer: {
        width: '940px',
        height: '350px',
        background: '#FFFFFF',
        boxShadow: "0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)",
        borderRadius: "12px",
        marginLeft: '30px',
        marginBottom: '30px',
        padding: '25px'
    },
    flex2: { display: 'flex', alignItems: 'center', padding: '20px 0px 0px' },
    switchicon: {
        marginLeft: 12
    },
    textfont: {
        fontSize: 16,
        margin: '15px 0px ',
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#9B9B9B',
    },
    editBtn: {
        background: '#4D72F8',
        marginLeft: '18%',
        marginTop: '-5px',
        borderRadius: 5,
        color: '#fff',
        padding: '6px 25px',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#fff',
            background: '#4D72F8',
        }
    },
    audiocard: {
        // display:'flex',
        // // justifyContent:'center',
        // alignItems:'center',
        width: '888px',
        height: '146px',
        background: '#FFFFFF',
        boxShadow: "0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)",
        borderRadius: "12px",
        // marginLeft:'30px',
        marginBottom: '30px',
        marginTop: '30px',
        padding: '40px 25px 40px 25px'
    },
    audioTransferHeading: {
        fontSize: 20,
        fontFamily: 'Calibri ! important',
        fontStyle: "normal",
        fontWeight: 700,
        color: '#A0A0A0',
        marginBottom: '30px'
    },
    spanborder: {
        width: '130px',
        borderRadius: '3px',
        marginLeft: '20px',
    },
    secstext: {
        color: '#828282',
        fontSize: '12px',
        fontWeight: 500,
        marginLeft: '10px',
        marginTop: '8px',
    },
    agenttext: {
        fontSize: 15,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#8392AB',
        marginLeft: '20px',
    },
    flexdisplay: {
        display: 'flex',
        padding: '10px',
        alignItems: "center"
    },
    headTexts: {
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#8392AB',
    },
    inputFields: {
        // margin: '10px 0px 20px',
        display: 'flex',
        alignItems: 'center'
    },
    selectField: {
        width: '110px', height: '44px', background: '#FFFEFE', borderColor: '#BEBDBD', borderRadius: '4px'
    },
    depaertmentName: {
        // marginTop: 20,
        fontSize: 16,
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 400,
        color: "#9B9B9B",
    },
    holdMusicCard: {
        borderRadius: 12,
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        width: '95%',
        // padding:'15px'
        marginLeft: '20px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 15,
        }
    },
    audioContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    audioPlayers: {
        width: '597px ! important',
        height: '44px',
        backgroundColor: "#E9ECED ! important",
        border: '1px solid #F0F3F5',
        borderRadius: '5px',
    },
    editbtn: {
        fontFamily: 'Calibri ! important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        color: '#FFFFFF',
        background: "#4D72F8",
        borderRadius: "5px",
        textTransform: 'initial',
        padding: '5px 30px',
        "&:hover": {
            backgroundColor: '#4D72F8'
        }
    },
    filename: {
        fontFamily: 'Calibri ! important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "14px",
        color: '#848282',

    },
    modalwd: {
        width: 600,
        '@media (max-width: 768px)': {
            width: 500
        },
    },

    agentsContainer: {
        marginLeft: '35px'
    },
    accordianNoFlex: {
        display: 'block'
    },
    agentoperators: {
        backgroundColor: '#FFFFFF',
        boxshadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        borderradius: 12,
    },
    card: {
        borderRadius: 12,
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        // width: '962.89px',
        marginBottom: '50px',
        marginLeft: '19px',
        marginRight: '35px'
    },
    backOperatorCard: {
        borderRadius: 5,
        background: '#FEFEFE',
        border: '1px solid #BEBDBD',
        marginBottom: '50px',
        marginLeft: '4px',
        marginRight: '184px',
        [theme.breakpoints.down('xs')]: {
            overflowX: "auto",
            overflowY: "auto",
            width: "90%",
            marginLeft: '0px',
            marginRight: '0px',
        }
    },
    backOperatorCard1: {
        borderRadius: 5,
        background: '#FEFEFE',
        border: '1px solid #BEBDBD',
        marginBottom: '50px',
        marginLeft: '35px',
        marginRight: '46px',
        [theme.breakpoints.down('xs')]: {
            width: "83%",
            borderRadius: 12
        }
    },
    backupOperatorHeading: {
        marginLeft: '5px',
        fontFamily: 'Calibri',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: "18px",
        color: "#4D72F8",
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
        }
    },
callsmissed:{
    marginLeft: '25px', 
    fontFamily: 'Calibri', 
    fontStyle: 'normal',
     fontWeight: 600,
      fontSize: "20px",
     color: "#4D72F8",
     [theme.breakpoints.down('xs')]: {
        fontSize: "16px",marginLeft: '15px', 
     }
},

    accordion: {
        width: '962.89px',
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        borderRadius: '12.1816px',
        marginTop: '50px',
        marginLeft: '15px',
    },
    padding: {
        padding: 15,
    },
    cards: {
        margin: '8px 18px 0px 18px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',

    },
    pText: {
        textAlign: 'center', color: "#787878", fontWeight: 500, padding: '10px 2px'
    },
    deleteButton: {
        margin: 10,
        background: '#4D72F8',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 5,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        padding: '6px 22px',
        color: '#FFFFFF',
        fontSize: 14,
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
        }
    },
    CancelButton: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        borderRadius: 5,
        color: '#8392AB',
        fontSize: 14,
        padding: '6px 22px',
    },
    imgsize: {
        fontSize: '4px',
        height: '13.41px',
        width: '12px',
        marginTop: '21px',
        marginLeft: 25
    },
    flex: { display: 'flex', justifyContent: 'flexStart', paddingBottom: '25px', alignItems: 'center' },
    greetText: {
        // marginLeft: 20,
        fontSize: "18px",
        fontFamily: 'Calibri !important',
        fontWeight: 600,
        fontStyle: 'normal',
        color: '#000000',
        marginRight: '10px'
    },
    actionText: {
        fontSize: "16px",
        fontFamily: 'Arial !important',
        fontWeight: 400,
        fontStyle: 'normal',
        color: 'rgba(107, 107, 107, 0.86)'
    },
    divider: {
        marginTop: -8,
        // backgroundColor: '#E2E2E2',
        backgroundColor: '#D2D2D2',
        width: '100%'
    },
    tableContainer: {
        // width: '100%',
        width: '800px',
        boxShadow: '0px 0px 1px',
        borderRadius: 0,
        borderColor: '#E2E2E2',
        marginLeft: '15px',
        marginTop: '25px',
        overflowX: 'auto',
        overflowY: 'auto',
        [theme.breakpoints.down("xs")]: {
            width: 350,
            marginLeft: '8px',
            marginTop: '15px',

        }
    },
    tablecont: {
        overflowX: 'auto',
        overflowY: 'auto',
    },
    userIcon1: {
        color: '#8392AB',
    },
    tableCellBody: {
        padding: 5,
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        textAlign: 'center',
        color: '#939292',
        borderBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        }
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 31,
        height: 31,
        borderWidth: 2,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
        border: '2px solid #ebebeb',
        color: '#ebebeb',
        // borderColor: '#ebebeb',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
    },
    activeButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 31,
        height: 31,
        borderWidth: 2,
        borderRadius: 50,
        padding: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
        border: '2px solid #4D72F8',
        color: '#4D72F8',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
    },
    activeIcon: {
        width: 20,
        height: 21,
        color: '#4D72F8',
    },
    inActiveIcon: {
        width: 20,
        height: 21,
        color: '#8392AB',

    },

    // button: {
    //     backgroundColor: '#4D72F8',
    //     width: 40,
    //     height: 40,
    //     borderRadius: 50,
    //     color: '#8392AB',
    //     padding: '10px',
    //     border: '1px solid #ebebeb',
    //     margin: '0px 10px',
    //     '&:hover': {
    //         backgroundColor: '#4D72F8',
    //     },
    //     '&:active': {
    //         backgroundColor: '#4D72F8',
    //     },
    // },
    // ringDuration: { fontSize: 16, fontWeight: 500, color: '#8392AB' },
    ringDuration: {
        fontSize: 16,
        fontWeight: 700,
        color: '#A2A2A2',
        fontFamily: 'Roboto',
        fontStyle: 'normal', letterSpacing: '0em'
        , [theme.breakpoints.down('xs')]: {
            fontSize: 13,
        }

    },
    button1: {
        width: 40,
        height: 40,
        borderRadius: 50,
        color: '#8392AB',
        backgroundColor: '#fbfbfb',
        padding: '10px',
        border: '1px solid #ebebeb',
        margin: '0px 10px',

    },
    ringHanldling: {
        marginLeft: '35px',
        display: 'flex',
        flexDirection: 'column',
        width: '800px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '15px',
            width: '250px',
        }
    },
    userIcon: {
        width: 25,
        height: 25,
        color: '#FFFFFF',
    },
    tHead: {
        color: '#464646',
        fontWeight: 600,
        fontSize: 16,
        fontFamily: 'Work Sans',
    },
    tBdy: {
        color: '#777777',
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Work Sans',
    },
    operators: {
        color: 'blue',
        marginLeft: '17px',
        fontSize: '20px',
        marginTop: '6px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    operatorHeading: {
        marginLeft: 5, fontFamily: 'Calibri',
        fontStyle: 'normal', fontWeight: 600,
        fontSize: "20px", color: "#4D72F8",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        }
    },
    holdSettingsHeading: {
        marginLeft: '25px',
        fontFamily: 'Calibri',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: "20px",
        color: "#848282",
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            marginLeft: '15px',
        }
    },
}));
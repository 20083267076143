import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { useStyles } from "../MyLogins/styles";
export default function DeptTable(props: any) {
    const classes: any = useStyles();
    return (
        <TableRow >
            <TableCell className={classes.tableBody}>
                {props.data.departmentName}
            </TableCell>
            <TableCell className={classes.tableBody}>
                {props.data.primaryNumber}
            </TableCell>
        </TableRow>
    )
}

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
         cardstyles: {
            background: "#FFFFFF",
            borderRadius: "8px",
            //boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
        },
        clientUpdater: {
            color: "#3A416F",
            fontWeight: 500,
            fontFamily: "work Sans",
            fontStyle: "normal",
            fontSize: 20,
        },
        divider: {
            background: "#E2E2E2",
        },
         filters: {
            color: "#4d6289",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 19,
        },
        selectBox: {
            // marginTop: 6,
            fontSize: 14,
            color: '#8392AB',
        },
        marginBottom20: {
            marginBottom: '20px',
        },
        searchButton: {
            marginTop: 5,
            marginRight: 12,
            background: " #3A416F",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: 12,
            padding: "5px 20px",
            borderRadius: "4.7478px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
        },
        totalAmout: {
            color: "#4d6289",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 20,
        },
        clearButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: 12,
            color: "#8392AB",
            border: "0.791301px solid #8392AB",
            background: "#F5F5F5",
            padding: "6px 20px",
            borderRadius: "4.7478px",
            marginLeft: 10,
            marginTop: 5,
        },
        tableHead: {
            color: "#8392AB",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: 16,
            lineHeight: "16px",
            // letterSpacing: -0.332482
        },
        tableBody: {
            color: "#3A416F",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 13.7377,
            lineHeight: "19px",
            letterSpacing: -0.379979
        },
    }))
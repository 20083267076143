import React, { useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import './App.css';
// import Layout from './containers/Layout/Layout';
import Layout from './containers/Layout/NewLayout';
import Routes from './services/Routes/Sidebar_routes'
import AuthIndex from './containers/Authentication/AuthIndex';
import { useSelector, useDispatch } from 'react-redux'
import * as authActionCreators from './store/Actions/AuthActionCreator'
import * as userProfileActionCreator from "./store/Actions/userActionCreator";
import AgentPassword from './containers/Authentication/Agentpassword/AgentPassword'
import ProtectedRoute from './containers/Authentication/ProtectedRoute';
import ForgetPassword from './containers/Authentication/ForgetPassword/index'
import UpdatePassword from './containers/Authentication/UpdatePassword/Auth'
import * as CallActionCreator from './store/Actions/CallActionCreator'
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';
import JsSIP from 'jssip'
import LoginAs from './containers/LoginAs/LoginAs';
import Reports from './containers/History/Reports/Reports';
import CreateAccount from './containers/Authentication/CreateAccount/CreateAccount'

function App() {
  let start = 0

  let intrvl: any = React.useRef(null)
  const [timer, setTimer] = React.useState({
    s: "00",
    m: "00",
    h: "00"
  })
  const history = useHistory()
  const dispatch = useDispatch()
  const [sipUser, setSipUser] = React.useState<any>()
  //const userAuthState = useSelector((state: any) => state.authState)
  const userStateData = useSelector((state: any) => state.authState)
  // console.log('userStateData', userStateData);
  const [tokendata, setTokenData] = useState<any>()
  const [tokenStatus, setTokenStatus] = useState(false)
  const [callStatus, setCallStatus] = useState<any>()
  // console.log('callStatus', callStatus)
  const [name, setName] = useState<any>('')
  const [callType, setCallType] = React.useState('')
  const [session, setSession] = useState<any>()
  const [CallRouteFrom, setCallRouteFrom] = React.useState('undefined')
  const [CallRouteFromPhoneNumber, setCallRouteFromPhoneNumber] = React.useState('')
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);
  const [data, setData] = useState<any>()
  const [notify, setNotify] = useState(false);
  const [status, setStatus] = useState('Registering')
  React.useLayoutEffect(() => {
    dispatch(authActionCreators.authStatusCheck())
  }, [])


  React.useEffect(() => {
    if (userStateData.userData) {
      dispatch(userProfileActionCreator.initUserProfile());
    }
  }, [userStateData, userStateData.userData])


  //incomming call

  const incomingCallRing = require('./assets/sounds/ring.mp3');
  var ringAudio = new Audio(incomingCallRing);
  ringAudio.loop = true;
  // console.log('12121212',ringAudio.play());
  // ringAudio.play()
  // console.log('12121212');

  //outgoing
  const outgoingCallRing = require('./assets/sounds/outgoing.mp3');
  var outgoing = new Audio(outgoingCallRing);
  outgoing.loop = true;

  const [answerModal, setAnswerModal] = useState(false)

  const handleAnswerCall = () => {
    setAnswerModal(!answerModal)
  }
  function setZeros(i: any) {
    if (i < 10) return "0" + i;
    return i;
  }

  //start timer
  const startTimer = () => {
    // // if startTimer is already running
    console.log("time started")
    if (start == 1) return;

    start = 1; // set startTimer is running
    let ss = 0,
      mm = 0,
      hh = 0;

    intrvl.current = setInterval(() => {
      ss++;
      if (ss == 60) {
        ss = 0;
        mm++;
      }
      if (mm == 60) {
        mm = 0;
        hh++;
      }
      setTimer({
        s: setZeros(ss),
        m: setZeros(mm),
        h: setZeros(hh)
      })

    }, 1000);

  }; // start timer ends
  const stopTimer = () => {
    clearInterval(intrvl.current);
    // if (start == 0) return;
    // start = 0;
    setTimer({
      s: "00",
      m: "00",
      h: "00"
    });

    // clearInterval(intrvl.current);

  }; // stop timer ends
  const callEnded = () => {
    stopTimer()
  }

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
  };

  //jssip
  React.useEffect(() => {
    var local_user = localStorage.getItem("user_data")
    var current_user = local_user ? JSON.parse(local_user) : {}
    // const token = Object.keys(current_user.tokenData).length && current_user.tokenData.access ? current_user.tokenData.access.token : null;
    const token = Object.keys(current_user).length && current_user?.tokenData?.access ? current_user?.tokenData?.access?.token : null;
    //const token: any = Object.keys(current_user).length && current_user.access ? current_user.access.token : null;
    //console.log('tokn', token)
    if (token) {
      setTokenData(token)
      setTokenStatus(true)
      const decodedHeader: any = jwt_decode(token);
      if (decodedHeader?.key) {
        const decryptData2 = (encryptedData: any) => {
          var C = CryptoJS;
          var Key = C.enc.Utf8.parse("oSOhgvKFi2AbgyVwtKMKwFV8pSc5kyxU");
          var IV = C.enc.Utf8.parse("oSOhgvKFi2AbgyVw");
          var decryptedText = C.AES.decrypt(encryptedData, Key, {
            iv: IV,
            mode: C.mode.CBC,
            padding: C.pad.Pkcs7
          });
          return decryptedText.toString(CryptoJS.enc.Utf8);
        }
        var userData = JSON.parse(decryptData2(decodedHeader?.key)) ? JSON.parse(decryptData2(decodedHeader?.key)) : ""
        setSipUser(userStateData?.userData?.sipUsername)
      }
      setName(userStateData?.userData?.name)
      //Creating sip 
      // let uri: any = process.env.REACT_APP_JSSIP_WEB_SOCKET_INTERFACE_URI;
      // let sip_ip: any = process.env.REACT_APP_JSSIP_WEB_SOCKET_INTERFACE_SIPIP;
      var socket = new JsSIP.WebSocketInterface('wss://wphone.efone.ca:7443');
      // sip configuration with username and password by decrypting token
      var configuration = {
        'sockets': [socket],
        'uri': 'sip:' + userStateData?.userData?.sipUsername + '@wphone.efone.ca',
        'password': userStateData?.userData?.sipPassword,
        'session_timers': false,
        'registrar_server': '143.198.45.154',
        'authorization_user': userStateData?.userData?.sipUsername,
        'pcConfig': {
          'iceServers': [
            {
              'urls': ['stun:stun.l.google.com:19302']
            }
          ],
          //"iceTransportPolicy": "all",
          "rtcpMuxPolicy": "negotiate"
        }
      };
      //console.log('configuration', configuration);
      var coolPhone = new JsSIP.UA(configuration);
      JsSIP.debug.enable('*');
      coolPhone.start()
      setData(coolPhone)
      coolPhone.on('connected', function (e: any) {
        setStatus('Connected')
        console.log('Connected');
      });
      coolPhone.on('disconnected', function (e: any) {
        setStatus('Disconnected')
        //console.log('disconnected');
      });
      coolPhone.on('registrationFailed', function (e: any) {
        setStatus('Registration Failed')
        //console.log('registrationFailed');
      });

      coolPhone.on('newRTCSession', (data: any) => {
        const session = data.session
        setSession(session)
        if (session.direction === "incoming") {
          if (data.request.headers.Calltype) {
            if (data.request.headers.Calltype[0]?.raw) {
              setCallRouteFrom(data.request.headers.Calltype[0].raw)
            } else {
              setCallRouteFrom('undefined')
            }
            if (data.request.headers.Phonenumber[0].raw) {
              setCallRouteFromPhoneNumber(data.request.headers.Phonenumber[0].raw)
            } else {
              setCallRouteFromPhoneNumber('undefined')
            }
          }
          //incoming call here
          // getLocalStream()
          console.log("Incoming12")
          setCallType('incoming')
          handleAnswerCall()
          //setAnswerModal(true)
          ringAudio.play()
          session.on("accepted", function () {
            // the call has answered
            // console.log("Accepted")
            startTimer()
            ringAudio.pause()
            setCallStatus("Accepted")
            handleStart()

          });
          session.on("confirmed", function () {
            // this handler will be called for incoming calls too
            // console.log("confirmed")
            ringAudio.pause()
            // startTimer()
            setCallStatus("confirmed")

          });
          session.on("ended", function (request: any) {
            // the call has ended
            var cause = request.cause; //sometimes this is request.reason_phrase
            stopTimer()
            handleReset()
            // console.log("ended")
            setAnswerModal(false)
            ringAudio.pause()
            setCallStatus("ended")

          });
          session.on("failed", function () {
            // unable to establish the call
            stopTimer()
            // console.log("failed")
            setAnswerModal(false)
            ringAudio.pause()
            setCallStatus("failed")
          });
          session.on('peerconnection', function (data: any) {
            data.peerconnection.addEventListener('addstream', function (e: any) {
              // set remote audio stream
              const remoteAudio1 = new window.Audio()
              remoteAudio1.srcObject = e.stream
              //remoteAudio1.src = window.URL.createObjectURL(e.stream);
              remoteAudio1.play();
            });
          });
        }
        else if (session.direction === "outgoing") {
          setCallType('outgoing')

          //outgoing?.play()
          // outgoing call here
          setCallStatus("")
          //outgoing?.pause()
          session.on("accepted", function () {
            // the call has answered
            // startTimer()
            // console.log("Accepted")
            setCallStatus("Accepted")
            handleStart()

          });
          session.on("confirmed", function () {
            //outgoing?.pause()
            // this handler will be called for incoming calls too
            // console.log("confirmed")
            // startTimer()
          });
          session.on("ended", function (request: any) {

            // the call has ended
            // stopTimer()
            // console.log("ended")

            setCallStatus("ended")
            dispatch(CallActionCreator.fetchStatus(null))
            dispatch(CallActionCreator.fetchData(false))
            handleReset()
          });
          session.on("failed", function (request: any) {
            // unable to establish the call
            // stopTimer()
            var cause = request.cause; //sometimes this is request.reason_phrase
            if (cause === JsSIP.C.causes.REJECTED) {

            }
            handleReset()
            // console.log("failed")
            setCallStatus("failed")

            dispatch(CallActionCreator.fetchStatus(null))
            dispatch(CallActionCreator.fetchData(false))
          });
          session.on('peerconnection', function (data: any) {
            data.peerconnection.addEventListener('addstream', function (e: any) {
              // set remote audio stream
              const remoteAudio1 = new window.Audio()
              remoteAudio1.srcObject = e.stream
              //remoteAudio1.src = window.URL.createObjectURL(e.stream);
              remoteAudio1.play();

            });
          });
        }
        session.on('addstream', function (e: any) {
          // set remote audio stream (to listen to remote audio)
          // remoteAudio is <audio> element on page
          const remoteAudio = audio
          remoteAudio.src = window.URL.createObjectURL(e.stream);
          remoteAudio.play();

        });

      })
    }

  }, [localStorage.getItem("user_data"), userStateData.isAuthenticated])

  var eventHandlers = {
    'progress': function (e: any) {
      console.log('call is in progress');
      console.log('200')
      //outgoing?.pause()
    },
    'failed': function (e: any) {
      setCallStatus("failed")
      stopTimer()
      console.log('call failed with cause: ' + e.data?.cause);
    },
    'ended': function (e: any) {
      setCallStatus("ended")
      stopTimer()
      console.log('call ended with cause: ' + e.data?.cause);
    },
    'confirmed': function (e: any) {
      //outgoing?.pause()
      console.log('call confirmed');
      startTimer()
    },
    'addstream': (e: any) => {
      console.log('100')
      //outgoing?.pause()
      console.log('Add stream (event handlers)')
      audio.srcObject = e.stream
      audio.play()
    }
  };
  const audio = new window.Audio()

  var options: any = {
    'eventHandlers': eventHandlers,
    'mediaConstraints': { 'audio': true, 'video': false },
    'sessionTimersExpires': 120,
    'pcConfig': {
      'iceServers': [
        {
          'urls': ['stun:stun.linphone.org:3478']
        },
        // {
        //     'urls': 'turn:numb.viagenie.ca', 'credential': 'muazkh', 'username': 'webrtc@live.com'
        // }
      ],
      //"iceTransportPolicy": "all",
      "rtcpMuxPolicy": "negotiate"
    }
  };
  const handleLogout = () => {
    data.unregister()
    // console.log('hhhh',data)
  }

  const mediaStreamConstraints = {
    audio: true,
    video: true
  };


  // const getLocalStream = () => {
  //   navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
  //     audio.srcObject = stream;
  //     // window.localAudio.srcObject = stream;
  //     // window.localAudio.autoplay = true;
  //   })
  //     .catch((err) => {
  //       console.error('eror', err);
  //     });
  // }

  const getLocalStream = () => {
    navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((mediaStreamConstraints) => {
      audio.srcObject = mediaStreamConstraints;
    })
      .catch((err) => {
        console.error('eror', err);
      });
  }


  // const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
  // console.log('hhhh',permissions)
  // const getLocalStream = () => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: false, audio: true }).then((stream:any) => {
  //       // window.localStream = stream; // A
  //       // window.localAudio.srcObject = stream; // B
  //       // window.localAudio.autoplay = true; // C
  //     })
  //     .catch((err: any) => {
  //       console.error('you got an error: ${ err }');
  //     });
  // }

  // var options :any = {
  //   all: true
  // };

  // unregister(options);

  //  React.useEffect(() => {
  //       //Connection

  //       // const socket = io("ws://134.122.36.172:7785", {
  //       //     transports: ["websocket"],
  //       //     extraHeaders: {
  //       //         deviceUDID: sipUser
  //       //     }
  //       // })
  //       //ws://newbpsocket.efone.ca:7785
  //       //https://newdemo.efone.ca:7789
  //       sessionStorage.setItem('Notification', JSON.stringify(0))
  //       const socket = io("https://bpsocket.efone.ca:7785", {
  //           "path": "",
  //           "transports": ["websocket", "polling"],
  //           "transportOptions": {
  //               "polling": {
  //                   "extraHeaders": {
  //                       "deviceUDID": sipUser
  //                   }
  //               }
  //           },
  //           "auth": {
  //               "deviceUDID": sipUser
  //           },

  //           "rejectUnauthorized": false,
  //           "timeout": 20000,
  //           "reconnection": false,
  //           "reconnectionAttempts": 3,
  //           "reconnectionDelay": 1000,
  //           "reconnectionDelayMax": 5000
  //       });
  //       socket.on("connect", () => {
  //           // console.log("Socket Connected", socket)
  //           setSocketData(socket)

  //       })

  //       socket.on("eb_inboundSMS", (data: any) => {
  //           const count: any = sessionStorage.getItem("Notification")
  //           var countnotifi: any = parseInt(count)
  //           countnotifi++
  //           sessionStorage.setItem("Notification", countnotifi)
  //           // console.log("listner====>", data, countnotifi)
  //           setMessages(data)
  //           setNotify(true)
  //       })
  //       socket.on("eb_outboundACK", (newData: any) => {
  //           // console.log("listner====>", newData)
  //           // if (newData.statusCode == 200 || newData.statusCode == 201) {
  //           // toast.success(newData.message, {
  //           //     position: toast.POSITION.TOP_RIGHT,
  //           //     style: {
  //           //         borderRadius: "2px",
  //           //         border: "1px solid green"
  //           //     }, autoClose: 1000
  //           // })


  //           // } else {
  //           //     toast.error(newData.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
  //           // }
  //       })

  //       socket.on('connect_error', function (err) {
  //           // console.log('Connection Failed', err);
  //       });
  //   }, [])
  const handleNotify = (child: any) => {
    setNotify(child)
  }



  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={AuthIndex} />
        <Route exact path="/login-as" component={LoginAs} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route path="/reset-password" component={UpdatePassword} />
        <Route path="/set-password" component={AgentPassword} />
        <Route path="/createaccount" component={CreateAccount}/>

        <Layout
          callModal={handleAnswerCall}
          callType={callType}
          handleLogout={handleLogout}
          callOptions={options}
          time={time}
          parentEnd={callEnded}
          session={session}
          options={options}
          answerModal={answerModal}
          callStatus={callStatus}
          status={status}
          name={name}
          handleNotify={handleNotify}
          notify={notify}
          timer={timer}
          coolPhone={data}
          CallRouteFrom={CallRouteFrom}
          CallRouteFromPhoneNumber={CallRouteFromPhoneNumber}
        >
          {
            Routes.map((route: any, i: any) => (
              <ProtectedRoute
                callModal={handleAnswerCall}
                callOptions={tokenStatus && options}
                //coolPhone={tokenStatus && data}
                coolPhone={data}
                callType={callType}
                answerModal={answerModal}
                session={session}
                callStatus={callStatus}
                status={status}
                name={name}
                time={time}
                parentEnd={callEnded}
                timer={timer}
                handleLogout={handleLogout}
                CallRouteFrom={CallRouteFrom}
                CallRouteFromPhoneNumber={CallRouteFromPhoneNumber}
                //messages={messages}
                //socketData={socketData}
                exact path={route?.path} Component={route.Component} routes={route} key={i}
              />

            ))
          }
        </Layout>
      </Switch>
    </div>
  );
}

export default App;

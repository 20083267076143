import { departmentModle } from './Actions';

export const fetchDepartmentsLoding = (status: any) => {
    return {
        type: departmentModle.FETCH_LOADING_DEPARTMENTS,
        status: status
    }
}

export const fetchDepartmentsSuccess = (data: any) => {
    return {
        type: departmentModle.FETCH_DEPARTMENTS_SUCCESS,
        departmentsData: data
    }
}

export const onChangeDepartments = (departmentData: any) => {
    return {
        type: departmentModle.ONCHANGE_SELECT_DEPARTMENT,
        selectedDepartment: departmentData
    }
}


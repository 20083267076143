import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react'
import { useStyles, PrettoSlider } from '../Users/styles';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import axiosInstance from '../../../environment/axiosInstance'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import ManageNumbers from './ManageNumbers/ManageNumbers';
import { useDispatch, useSelector } from "react-redux";
import AddEfoneNumber from './ManageNumbers/AddNumber/AddEfoneNumber';

import * as authActionCreators from '../../../store/Actions/AuthActionCreator'
import { GrUpload } from 'react-icons/gr';
toast.configure()


function EditDeskphone(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const [openExpand, setOpenExpand] = React.useState(true);
    const [open, setOpen] = React.useState(true)
    const [opeing, setOpeing] = React.useState(true)
    const [loading, setLoading] = React.useState(false);
    const [openClose, setOpenClose] = React.useState(true)
    const [manageOpen, setManageOpen] = React.useState(true)
    const [openManagePhoneNumber, setOpenManagePhoneNumber] = React.useState(false);
    const audioFileInput: any = React.createRef();
    const [fileBuffer, setFileBuffer] = React.useState('')
    const [fileName, setFileName] = React.useState('')
    const [source, setSource] = React.useState<any>('')
    const [deptList, setdeptList] = React.useState<any>();
    const [teammembList, setteammembList] = React.useState<any>();
    const [resData, setResdata] = React.useState<any>()
    const [roomData, setRoomData] = React.useState<any>({
        name: '',
        ringduraion: 30,
        forwardingRule: 1,
        emergencyLocation: "",
        outboundId: "",
        isOutboundCalling: true,
        macAddress: '',
        departmentId: '',
        teamMemberId: '',
        VoiceMail: [],
        phone: []
    })
    console.log("roomData", roomData)
    const [ringDurationTime, setRingDurationTime] = React.useState<any>({
        ringDurationTime: 0
    })

    const manageExpand = () => {
        setManageOpen(!manageOpen)
    }

    const expandOpen = () => {
        setOpenExpand(!openExpand)
    }
    const expanding = () => {
        setOpen(!open)
    }
    const expanded = () => {
        setOpeing(!opeing)

    }
    const expandClose = () => {
        setOpenClose(!openClose)
    }

    const handleChange = (newData: any) => {
        setRoomData(() => {
            return {
                ...roomData,
                emergencyLocation: newData
            }
        });
    };

    let timer1: any = null;
    const ringDurationHandle = (e: any, data: any) => {
        //console.log("-----timer", data)
        clearInterval(timer1)
        timer1 = setTimeout(() => {
            var myData = roomData
            console.log('myData', myData);

            myData.ringduraion = data
            setRoomData(myData)
            //onSubmitForm()
        }, 100)
    }

    const onChangeCheckBox = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                forwardingRule: e
            }
        })
    }

    const handleChangeName = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                name: e.target.value
            }
        })
    }
    const onChangeRadio = (data: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                isOutboundCalling: data
            }
        })
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results) => {
                var myResults = results[0]
                var city = ""
                //var state = ""
                var country = ""
                var postalCode = ""
                var streetNumber = ""
                var streetName = ""
                var province = ""

                getLatLng(myResults).then((latLong) => {
                    myResults.address_components.map((item) => {
                        if (item.types.includes("locality")) {
                            city = item.long_name
                        }
                        // if (item.types.includes("administrative_area_level_1")) {
                        //   state = item.long_name
                        // }
                        if (item.types.includes("country")) {
                            country = item.long_name
                        }
                        if (item.types.includes("postal_code")) {
                            postalCode = item.long_name
                        }
                        if (item.types.includes("street_number")) {
                            streetNumber = item.long_name
                        }
                        if (item.types.includes("route")) {
                            streetName = item.short_name
                        }
                        if (item.types.includes("administrative_area_level_1")) {
                            province = item.short_name
                        }
                        if (item.types.includes("emergencyLocation")) {
                            province = item.short_name
                        }

                    })
                    setRoomData(() => {
                        return {
                            ...roomData,
                            // //address: myResults.formatted_address,
                            // city: city,
                            // country: country,
                            // zipcode: postalCode,
                            // province: province,
                            //emergencyLocation: emergencyLocation
                            emergencyLocation: myResults.formatted_address
                        }
                    });
                })
            })
            .catch(error => console.error('Error', error));
    };

    const onChanePhoneNumber = (e: any) => {
        console.log('eee', e)
        setRoomData((prev: any) => {
            return {
                ...prev,
                outboundId: e.phoneId
            }
        })
    }


    //phone numbers
    const [phnList, setPhnList] = React.useState<any>([]);
    const fetchPhoneNumbers = React.useCallback(() => {
        setLoading(true);
        axiosInstance.get('/phoneNumbers').then((res: any) => {
            // console.log('resph__',res)
            if (res.data.data) {
                const data = res.data.data[0].userNumbers
                var List: any = [];
                data.map((item: any) => {
                    var object = {
                        "value": item.phone,
                        "label": item.phone,
                        'id': item._id
                    }
                    List.push(object)
                })
                setPhnList(List)
            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }, [])

    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [outboundVal, setOutboundVal] = React.useState<any>({})

    console.log('outboundData', outboundData);
    
    const getOutboundCallers = () => {
        axiosInstance.get('/phoneNumbers/outbound-caller-id').then((res: any) => {
            if (res.status === 200) {
                const data = res.data.data
                setOutBoundData(data)
                setOutboundVal({
                    "value": data[0].id,
                    "label": data[0].value,
                    "name": data[0].name,
                    "phoneId": data[0].phoneId

                })
            }
        }).catch((err: any) => {
            if (err.response?.status == 402) {
                // window.location.href = "http://bp.efone.ca/"
                // sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    let OutboundCallersList: any = [];

   React.useEffect(()=>{
       outboundData?.map((item: any) => {
           var object = {
               "value": item?.id,
               "label": item?.value + "  (" + item?.name + ")",
               "name": item?.name,
               "phoneId": item?.phoneId
           }
           OutboundCallersList.push(object)
       })
   }, [OutboundCallersList])

    const getDeptList = () => {
        axiosInstance.get('/deskphone/departments-teammembers').then((res: any) => {
            if (res.data) {
                const Teams = res.data.Teams
                const depts = res.data.Departments
                var TeamsList: any = [];
                Teams?.map((item: any) => {
                    var object = {
                        "value": item._id,
                        "label": item.firstName + item.lastName
                    }
                    TeamsList.push(object)
                })
                setteammembList(TeamsList)

                var deptsList: any = [];
                depts?.map((item: any) => {
                    var object = {
                        "value": item._id,
                        "label": item.name
                    }
                    deptsList.push(object)
                })
                setdeptList(deptsList)
            } else {

            }
        }).catch((err: any) => {

        })
    }

    React.useEffect(() => {
        fetchPhoneNumbers()
        getOutboundCallers()
        getDeptList()
    }, [])


    //edit 
    React.useEffect(() => {
        if (props.editId) {
            axiosInstance.get(`/deskphone/roomPhone?id=${props.editId}`).then((res: any) => {
                console.log('editdaaa', res)
                if (res.status === 200) {
                    const data = res.data.data
                    const filterData = data.filter((item: any) => item._id === props.editId)
                    console.log('filterData', filterData)
                    setRoomData(filterData[0])
                    const vmobj = filterData[0].VoiceMail.map((item: any) => {
                        return {
                            fileName: item.fileName,
                            value: item._id,
                            path: item.path,
                            isSelected: item.isSelected
                        }
                    })
                    console.log("vmobj", vmobj)
                    setRoomData((prev: any) => {
                        return {
                            name: filterData[0]?.name,
                            ringduraion: filterData[0]?.ringDuration,
                            forwardingRule: filterData[0]?.forwardingRule,
                            emergencyLocation: filterData[0]?.emergencyLocation,
                            outboundId: filterData[0]?.outboundId,
                            isOutboundCalling: filterData[0].isOutboundCalling,
                            macAddress: filterData[0].macAddress,
                            teamMemberId: filterData[0].teamMemberId,
                            departmentId: filterData[0].departmentId,
                            VoiceMail: vmobj,
                            phone: filterData[0].phone
                        }

                    })
                    setRingDurationTime({
                        ringduraion: filterData[0]?.ringDuration
                    })
                }
            })
        }
    }, [props.editId])

    const uploadFile = async (data: any) => {
        const id = data.id
        let formData = new FormData()
        formData.append('fileName', fileName)
        formData.append('file', fileBuffer)
        formData.append('fileType', '2')
        // formData.append('soundType', 'IvrGreeting')
        // formData.append('hoursType', '1')
        // formData.append('departmentId', depId)
        await axiosInstance.post(`/deskphone/voicemail?roomPhoneId=${id}`, formData).then((res: any) => {
            console.log(res, "res")
            if (res.status === 200) {
                setFileName('')
                setSource('')
                // fetchAudioFiles()
                // SetLoading(true)
                // props.openHours()
            }
        })
    }

    const onSubmitForm = () => {
        // console.log('selectedDateTime__',selectedDateTime)
        //setSubmitted(true);
        // const errorData = validate(true);
        // if (errorData.isValid) {
        axiosInstance.patch(`/deskphone/roomPhone?id=${props.editId}`, roomData).then((res: any) => {
            if (res.status === 200) {
                if (res.data.data.forwardingRule === 1) {
                    setResdata(res.data.data)
                    uploadFile(res.data.data)
                }
                setLoading(false)
                props.fetchRoomPhones()
                props.handlePopupModalEditdeskphone()
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                //props.popUpAlert(res.data.message, "UPDATE_AGENT_SUCCESS", "success");
            } else {
                //props.popUpAlert(res.data.message, "UPDATE_AGENT_FAIL", "error");
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
            //props.popUpAlert(err.response.data.message, "UPDATE_AGENT_FAIL", "error");
        })


        // }
    }
    const handlePopupModalManagePhoneNumber = () => {
        setOpenManagePhoneNumber(!openManagePhoneNumber)
        // setEditId(id)
    }


    const uploadFileClick = () => {
        // setUploadingFile(true)
        if (audioFileInput.current) {
            audioFileInput.current.click()
        }
    }
    const onChangeAudioFile = (e: any) => {
        const fileData = e.target.files;
        var file: any = fileData[0];
        // setFile(file)
        setFileBuffer(file)
        let reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setSource(reader.result)
        };

        setFileName(fileData[0]?.name)
        // onPostDataToServer(file, fileData[0]?.name)

    }
    const handleDeptSelection = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                departmentId: e.value
            }
        })
    }
    const handleTeammemberSelection = (e: any) => {
        setRoomData((prev: any) => {
            return {
                ...prev,
                teamMemberId: e.value
            }
        })
    }

    const handleChangemacaddress = (e: any) => {
        console.log(e.target.value)
        setRoomData((prev: any) => {
            return {
                ...prev,
                macAddress: e.target.value
            }
        })
    }
    const onChangeTextCheckBox = () => {
        if (roomData.forwardingRule === 1) {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 0
                }
            })
        } else {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 1
                }
            })
        }

    }
    const onChangeTeamemberCheckBox = () => {
        // alert(' lj')
        if (roomData.forwardingRule === 2) {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 0
                }
            })
        } else {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 2
                }
            })
        }
    }

    const onChangevoicemailfile = (e: any) => {
        const fileId = e.target.value
        const roomData: any = {
            "forwardingRule": 1,
            "_id": fileId
        }
        axiosInstance.patch(`/deskphone/voicemail?roomPhoneId=${props.editId}`, roomData).then((res: any) => {
            if (res.status === 200) {

            } else {

            }
        }).catch((err: any) => {

        })

    }
    const onChangedeptCheckBox = () => {
        // alert(' lj')
        if (roomData.forwardingRule === 3) {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 0
                }
            })
        } else {
            setRoomData((prev: any) => {
                return {
                    ...prev,
                    forwardingRule: 3
                }
            })
        }
    }

    const defaultValue = roomData?.VoiceMail?.filter((item: any) => item.isSelected === true)
    console.log("defaultValue", defaultValue)

    return (
        <div>
            {
                openManagePhoneNumber && (
                    <div>
                        {/* <Backdrop handlePopupModal={handlePopupModalManagePhoneNumber} />
                        <Modal title="Manage Numbers" >
                            <div >
                                <ManageNumbers editId={props.editId} handlePopupModalEditdeskphone={props.handlePopupModalEditdeskphone} />
                            </div>
                        </Modal> */}

                        <Backdrop handlePopupModal={handlePopupModalManagePhoneNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalManagePhoneNumber} >
                            <AddEfoneNumber
                                //fetchNumberLists={fetchNumberLists}
                                editId={props.editId}
                                handlePopupModal={handlePopupModalManagePhoneNumber}

                            />

                            {/* <AddEfoneNumber
                                fetchNumberLists={fetchNumberLists}
                                handlePopupModal={handlePopupModalAddNumber}
                            /> */}
                        </Modal>
                    </div>
                )
            }
            <div style={{ padding: '15px' }}>
                <Accordion expanded={openExpand} style={{ borderRadius: 5 }}
                    onChange={expandOpen}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    >
                        <Typography style={{ color: '#FFFFFF' }}>Name</Typography>
                        <Typography style={{ color: '#FFFFFF' }}>{roomData?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            fullWidth
                            placeholder='Skylinks Bovaid'
                            variant='outlined'
                            value={roomData?.name}
                            name="name"
                            size='small'
                            onChange={handleChangeName}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={open}
                    onChange={expanding}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ color: '#FFFFFF' }}>Ring Duration & Forwarding</Typography>
                            <Typography style={{ color: '#FFFFFF' }}>45s ,To Message</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        <div>
                            <Typography>Choose how long this phone will ring before forwarding to Contact or
                                play a message (default 45s)
                            </Typography>
                            <div>
                                <PrettoSlider
                                    key={`slider-${ringDurationTime?.ringduraion}`}
                                    valueLabelDisplay="auto"
                                    aria-label="pretto slider"
                                    defaultValue={roomData?.ringduraion}
                                    //onChange={ringHandlingChange}
                                    onChange={ringDurationHandle}
                                    min={0}
                                    max={30}
                                // marks={marks}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    When your Phone Rings for ring for ring duration,call will sent:
                                </FormLabel>
                                <FormGroup aria-label="position" >

                                    <FormControlLabel
                                        // value="a"
                                        control={<Checkbox color="primary" checked={roomData.forwardingRule === 1} onChange={() => onChangeTextCheckBox()} />}
                                        label="To a Message(no voicemail)"
                                    />
                                    {roomData.forwardingRule === 1 &&
                                        <div>
                                            <ReactSelect options={roomData?.VoiceMail}
                                                value={defaultValue}
                                                onchange={onChangevoicemailfile} />
                                            <p>{fileName}</p>
                                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '10px' }} onClick={uploadFileClick}>
                                                <GrUpload />
                                                <span style={{ color: '#4D72F8', fontWeight: 700 }}>UploadFile</span>
                                                <div style={{ display: 'none' }}>
                                                    <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <FormControlLabel
                                        // value="b"
                                        control={<Checkbox color="primary" checked={roomData.forwardingRule === 2} onChange={() => onChangeTeamemberCheckBox()} />}
                                        label="To Team Members room Phone  "
                                    />
                                    {roomData.forwardingRule === 2 &&
                                        (<ReactSelect options={teammembList} placeholder='Select Team Member'
                                            onChange={handleTeammemberSelection}
                                            defaultValue={teammembList?.filter((item: any) => item.value === roomData.teamMemberId)} />)
                                    }
                                    <FormControlLabel
                                        // value="c"
                                        control={<Checkbox color="primary" checked={roomData.forwardingRule === 3} onChange={() => onChangedeptCheckBox()} />}
                                        label="To Department,office or call center"
                                    />
                                    {roomData.forwardingRule === 3 &&
                                        (<ReactSelect options={deptList} placeholder='Select Department' onChange={handleDeptSelection}
                                            defaultValue={deptList.filter((item: any) => item.value === roomData.departmentId)} />)
                                    }
                                </FormGroup>
                                {/* <FormGroup aria-label="position" >

                                    <FormControlLabel
                                        // value="a"
                                        control={<Checkbox color="primary" checked={roomData?.forwardingRule === 1} onChange={() => onChangeCheckBox(1)} />}
                                        label="To Message"
                                    />
                                    <FormControlLabel
                                        // value="b"
                                        control={<Checkbox color="primary" checked={roomData?.forwardingRule === 2} onChange={() => onChangeCheckBox(2)} />}
                                        label="To Team Members room Phone  "
                                    />
                                    <FormControlLabel
                                        // value="c"
                                        control={<Checkbox color="primary" checked={roomData?.forwardingRule === 3} onChange={() => onChangeCheckBox(3)} />}
                                        label="To Department,office or call center"
                                    />
                                </FormGroup> */}
                            </FormControl>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={opeing}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                    onChange={expanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}

                    >
                        <Typography style={{ color: '#FFFFFF' }}>Registered Emergency Sevices Location</Typography>
                        <Typography style={{ color: '#FFFFFF' }}></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        <Typography
                            style={{ color: '#A5A5A5', marginTop: '10px' }}
                        >
                            This Address will be used in the event of Emergency call;
                        </Typography>
                        {/* <TextField style={{ marginTop: '20px' }}
                        fullWidth
                        placeholder='253 QUEEN STE,BRAMPTON,ON LWGD'
                        variant='outlined'
                    /> */}
                        <PlacesAutocomplete
                            value={roomData?.emergencyLocation ? roomData?.emergencyLocation : ""}
                            onChange={handleChange}
                            onSelect={handleSelect}

                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                                <div>
                                    {/* <input
                                            style={{
                                            width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                                            borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                                            border: "1px solid #3f51b5"
                                            }}
                                            {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                            })}
                                        /> */}
                                    <TextField
                                        style={{ marginTop: '12px' }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        //error={!errors.address.isValid}
                                        {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    {/* {
                                    !errors.address.isValid &&
                                    <p className={classes.errorMessage}>{errors.address.message}</p>
                                } */}
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion: any) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={openClose}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                    onChange={expandClose}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}

                    >
                        <Typography style={{ color: '#FFFFFF' }}>Outbound ID</Typography>
                        <Typography style={{ color: '#FFFFFF' }}>{OutboundCallersList.filter((item: any) => item.phoneId === roomData?.outboundId)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        {/* <TextField
                        style={{
                            marginTop: '20px',
                            color: '#A5A5A5'
                        }}
                        fullWidth
                        placeholder='skylinks Bovaid:(906)354-887'
                        variant='outlined'
                    /> */}
                        <ReactSelect
                            value={OutboundCallersList.filter((opt: any) => {
                                return opt.value === outboundVal?.value
                            })}
                            onChange={(e: any) => onChanePhoneNumber(e)}
                            options={OutboundCallersList}
                            // options={phnList}
                            // onChange={onChanePhoneNumber}
                            size="small"
                            name="outboundId"
                            // value={phnList.filter((ph:any)=>{
                            //     ph.id === roomData.outboundId
                            // })}
                            placeholder='skylinks Bovaid:(906)354-887'
                        />
                        <div style={{ marginTop: '20px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Outbound Calling
                                </FormLabel>
                                <RadioGroup
                                    aria-label="position" row
                                // onChange={onChangeRadio}
                                //name="isOutboundCalling"

                                >
                                    <FormControlLabel
                                        //value="isOutboundCalling"
                                        control={
                                            <Radio color="primary"
                                                name='isOutboundCalling'
                                                // value={roomData.isOutboundCalling}
                                                checked={roomData.isOutboundCalling === true}
                                                onChange={() => onChangeRadio(true)}

                                            />}
                                        label="Enable"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                name='isOutboundCalling'
                                                // value={roomData.isOutboundCalling}
                                                checked={roomData.isOutboundCalling === false}
                                                color="primary"
                                                onChange={() => onChangeRadio(false)}
                                            />}
                                        label="Disable"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={manageOpen}
                    style={{ marginTop: '20px', borderRadius: 5 }}
                    onChange={manageExpand}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}

                    >
                        Manage Number
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                        {
                            roomData?.phone.map((item: any) =>
                            (
                                <>
                                    <div>
                                        <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                <Typography>{item}</Typography>
                                            </div>

                                        </div>
                                    </div>
                                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                                </>
                            )
                            )
                        }

                        {/* <div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <Typography>(289)401-2889</Typography>
                                    <Button
                                        size="small"
                                        className={classes.smsbtn}
                                    >SMS</Button>
                                </div>
                                <div>
                                    <Typography>Secondary Number</Typography>
                                </div>
                            </div>
                        </div>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} /> */}
                        <div>
                            <Button
                                size="small"
                                variant='outlined'
                                className={classes.addNumber}
                                onClick={handlePopupModalManagePhoneNumber}
                            >Add Number</Button>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={openExpand} style={{ borderRadius: 5, marginTop: '20px' }}
                    onChange={expandOpen}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ background: '#377DEF', color: 'white', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    >
                        <Typography style={{ color: '#FFFFFF' }}>MAC Address</Typography>
                        {/* <Typography style={{ color: '#FFFFFF' }}>Skylinks Bovaid</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            fullWidth
                            placeholder='Enter MAC Address'
                            variant='outlined'
                            value={roomData.macAddress}
                            name="macAddress"
                            size='small'
                            onChange={handleChangemacaddress}
                        />
                    </AccordionDetails>
                </Accordion>

                <div style={{ display: 'flex', gap: '20px', marginLeft: '180px', marginTop: '50px' }}>
                    <Button variant='outlined' style={{ background: 'gray', color: 'white', padding: '7px 20px' }}
                        onClick={props.handlePopupModalEditdeskphone}>Cancel</Button>
                    <Button onClick={onSubmitForm} variant='outlined' style={{ background: 'blue', color: 'white', padding: '7px 20px' }}>Done</Button>
                </div>
            </div>

        </div>

    )
}

export default EditDeskphone

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { padding } from '@mui/system';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: 10,
        },
        card: {
            //   margin: '15px 0px 0px 0px',
            borderRadius: 8,
            background: '#FFFFFF',
            //   boxShadow: '0 0 0.5em #E2E2E2',
            boxShadow: '0 12.5214px 16.9039px rgb(0 0 0 / 5%)'
        },
        heading: {
            color: "#4D72F8",
            fontSize: 20,
            fontWeight: 600,
        },
        departmentheading: {
            color: '#4D72F8',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            textTransform: 'none',
            marginLeft: 16
        },
        headings: {
            color: '#A0A0A0',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 600,
            textTransform: 'none',
            marginLeft: 16
        },
        accordianNoFlex: {
            display: 'block'
        },
        callFlow: {
            color: '#A0A0A0',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 600,
            textTransform: 'none',
            marginLeft: 16,
            marginTop: 30
        },
        dflex: {
            display: 'flex',
            alignItems: 'center',
            gap: 8
        },
        dflexCheck: {
            display: 'flex',
            alignItems: 'center',
            gap: 100,
            marginTop: 15
        },
        tRow: {
            background: '#F1F1F1',
            borderRadius: 6
        },
        tCell: {
            color: '#464646',
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Work Sans'
        },
        tbody: {
            color: '#787878',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Work Sans'
        },
        scheduleCard: {
            background: 'rgba(240, 243, 245, 0.98)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '6px',
            padding: 16,
            display: 'flex',
            justifyContent: 'space-between'

        },
        divWdth: {
            background: '#fff',
            // marginTop: 10,
            width: 86,
            // height: 290,
            // padding: 8,
            border: '1px solid rgba(161, 161, 161, 0.5)',
            borderRadius: '10px',
            cursor: 'pointer'
        },
        divWdths:{
            background: '#4D72F8',
            border: '1px solid rgba(161, 161, 161, 0.5)',
            cursor: 'pointer',
            color: 'white',
            marginTop: 26,
            padding: 7,
            width: '122%',
            marginLeft: -8,
            height:80,
        },
        time:{
            marginTop:10,
            fontFamily:'Calibri'
        },
        divWdthsTwo:{
            background: '#4D72F8',
            border: '1px solid rgba(161, 161, 161, 0.5)',
            cursor: 'pointer',
            color: 'white',
            marginTop: 75,
            padding: 7,
            width: '122%',
            marginLeft: -8,
        },
        divWdthsThree:{
            background: '#4D72F8',
            border: '1px solid rgba(161, 161, 161, 0.5)',
            cursor: 'pointer',
            color: 'white',
            marginTop: 105,
            padding: 7,
            width: '122%',
            marginLeft: -8,
        },
        divWdthss:{
            background: '#4D72F8',
            border: '1px solid rgba(161, 161, 161, 0.5)',
            cursor: 'pointer',
            color: 'white',
            marginTop: 140,
            padding: 7,
            width: '122%',
            marginLeft: -8,
        },
        specificContent: {
            display: 'content',
        },
        specificText: {
            marginBottom: 18,
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontWeight: 600,
            color: '#464646',
            letterSpacing: 0.01,
            textTransform: 'none',
        },
        tableContainer1: {
            boxShadow: '0px 0px 0px',
            borderRadius: 0,
            borderColor: '#E2E2E2',
            border:'none',
            marginTop:-10,
            // marginLeft:-22,
            // width:'105%'
        },
        tableCellBody1: {
            padding: 10,
            border: 'none',
            fontSize: 15,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: 0.21553,
            textAlign: 'center',
            color: '#464646',
        },
        tableCellBody2: {
            padding: '10px',
            border: 'none',
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            letterSpacing: 0.21553,
            color: '#464646',
        },
        saveBtn: {
            background: '#5679F9',
            padding: '10px 50px',
            color: 'white',
            textTransform:'capitalize',
            fontFamily: 'Work Sans',
            fontWeight:600,
            letterSpacing:'0.1px',
            '&:hover': {
                background: '#5679F9',
                color: 'white'
            }
        },
        paper: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            outline: 0, // Disable browser on-focus borders
            boxShadow: theme.shadows[5],
        },
        contentCard: {
            margin: 10,
            background: '#F3F5FF',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: 10,
        },
        cardContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        media: {
            margin: 10,
            height: 125,
            width: '25%',
        },
        divider: {
            //margin: 5,
            marginTop: -12,
            backgroundColor: '#E2E2E2',
        },
        dndCardContent: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "16px 14px 5px",
            "@media (max-width: 768px)": {
                marginTop: -15,
            }
        },
        mt8: {
            marginTop: '-8px'
        },
        outBondCard: {
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
            borderRadius: 10,
            background: "#FFFFFF",
            marginTop: 25,
            //marginBottom: 60,
        },
        editIcon: {
            color: "#4D72F8",
            marginLeft: 5,
            marginTop: -12,
        },
        departmentNameText: {
            fontSize: 15,
            marginTop: -12,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            color: "#464646",
            letterSpacing: 0.1,
            textTransform: "none",
            "@media (max-width: 768px)": {
                marginTop: -5,
            }
            // marginTop: 5,
            // marginLeft: 7,
        },
        header: {
            color: '#4D72F8',

        },

    })
);

import React from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { withStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Card, CardContent, Typography, Grid, TableContainer, Popover,Table, TableHead, TableRow, TableCell, TableBody, Box, CircularProgress, Divider, ListItemText, Popper, Button, TextField, FormControlLabel, Radio } from '@material-ui/core';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from 'recharts';
import DateRangePicker from "react-daterange-picker";
import { addDays, endOfDay, getUnixTime, startOfDay, subDays } from 'date-fns';
import outgoing from '../../../assets/icons/outgoingcalls.png';
import incoming from '../../../assets/icons/incomingcalls.png';
import axiosInstance from '../../environment/axiosInstance'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sortDown from '../../../assets/images/Sort Down.png'
import Cbreakdown from './Analytics/ccbkd/Callbrakdown';
import { useStyles } from './Analytics/style';
import './Analytics/styles.css';
import Select from '@mui/material/Select';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';
import moment from "moment";





const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: 'rgb(77, 114, 248);',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


function CallCountDown(props: any) {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = React.useState<any>(false)
  const [anchorEll, setAnchorEll] = React.useState<any>(null);
  const [groupUserData, setGroupUserData] = React.useState<any>({});
  const [anchorEldt, setAnchorEldt] = React.useState<any>(null);
  const [selectOffice, setselectOffice] = React.useState<any>('HELLO')

//   const urlPath = props.location.search

  const departmentStateData = useSelector((state: any) => state.departmentModule);
  const officeId = localStorage.getItem('SelectedCompanyId')
  const [type, setype] = React.useState<any>(1)
  const [openCustomPicker, setopenCustomPicker] = React.useState(false)
  const anchorRef = React.useRef(null);
  const custom_startDate = dayjs('2024-05-01');
  const custom_endDate = dayjs('2024-05-10');
  const startDate = moment(custom_startDate.toDate());
  const endDate = moment(custom_endDate.toDate());
  const [dateRange, setDateRange] = React.useState<any>({ startDate, endDate, });
  //all users data
  const allUsersData = () => {
    axiosInstance.get('dashboard/teams-departments-companies').then((res: any) => {
      if (res.status === 200) {
        setGroupUserData(res.data.Data);
      }
    }).catch((err: any) => {

    })
  }
  
  const keyOptions = [
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "Last 7 days", value: 3 },
    { label: "Last 30 days", value: 4 },
    { label: "This month ", value: 5 },
    { label: "Last month", value: 6 },
    { label: "Custom", value: 7 },

  ]

  const open = Boolean(openCustomPicker);
  const IDBUT = open ? 'simple-popover' : undefined;

  const userStats: any = localStorage.getItem('dbCountStats')
  const us = parseInt(userStats)


  React.useEffect(() => {
    setype(us)
    fetchCallbreakdownData(type)
    allUsersData()
  }, [])


  const [data, setData] = React.useState<any>([]);

  // call break down
  const fetchCallbreakdownData = async (type:any) => {
    setLoading(true)
    // Convert Day.js objects to Moment.js objects directly in the URL construction
    const startDate = dateRange?.start?.format("YYYY-MM-DD");
    const endDate = dateRange?.end?.format("YYYY-MM-DD");
    let url = `/dashboard/call-count-breakdown?type=${type}`
    if (officeId) {
      url += `?officeId=${officeId}`
    }
    if (selectOffice) {
      url += `?companyId=${selectOffice}`
    }
    if (type == 7 && localStorage.getItem("dbCallCountStartDate") && localStorage.getItem("dbCallCountEndDate")) {
        url += `&startDate=${localStorage.getItem("dbCallCountStartDate")}&endDate=${localStorage.getItem("dbCallCountEndDate")}`;
    }

    await axiosInstance.get(url).then((res: any) => {
      if (res.data.data) {
        setAnchorEldt(null)
        setLoading(false)
        setAnchorEll(null)
        setData(res.data.data)
      }

    }).catch((err: any) => {

    })
  }

  const handleOptions = (e: any) => {
    localStorage.setItem('dbCountStats', e.value)
    setype(e.value)

    if (e.value === 7) {
      setopenCustomPicker(true)
    }
    else {
        fetchCallbreakdownData(e.value)
    }

  }

  
  const handleCustomPickerClose = () => {
    setopenCustomPicker(false);
  };

  // Handler for changing date range
  const handleSelect = (range: any) => {
    const startDate = range?.start?.format("YYYY-MM-DD");
    const endDate = range?.end?.format("YYYY-MM-DD");
    localStorage.setItem('dbCallCountStartDate', startDate);
    localStorage.setItem('dbCallCountEndDate', endDate);
    setDateRange(range);
  };

  const applyPayPeriod = () => {
    fetchCallbreakdownData(type)
  }

  return (
    <div>

<Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5} className={classes.cardContent}>
            <Grid item md={9} sm={9} className={classes.grid}>
              <Typography className={classes.userText}>Call Count Breakdown </Typography>
            </Grid>
        


            <Grid item md={3} sm={3} xs={12} className={`classes.gridItem ${classes.width}`}>
              <ReactSelect placeholder={'Today'} options={keyOptions}
                onChange={handleOptions}
                value={keyOptions.filter((opt: any) => opt.value === type)} />
            </Grid>
            <Popover
              //   className={classes.Popover}
              id={IDBUT}
              open={open}
              anchorEl={anchorRef.current}
              onClose={handleCustomPickerClose}
              anchorOrigin={{
                vertical: 'top', // Align to the top of the anchor element
                horizontal: 'right', // Align to the left side of the anchor element
              }}
              transformOrigin={{
                vertical: 'bottom', // Align to the top of the popover content
                horizontal: 'right', // Align to the left side of the popover content
              }}
            >
              <Grid container >
                <Grid
                  style={{ borderLeft: "1px solid lightgray" }}
                  item sm={8} xs={8}
                >
                  {/* <DateRangePicker  
                         value={value}                        
                           locale="en-US"                                              
                            onSelect={onSelectStartEndDate}
                            singleDateRange={true}
                            /> */}
                  <div className="date-range-picker-container">
                    <DateRangePicker
                      onSelect={handleSelect}
                      value={dateRange}
                      singleDateRange={true}
                    />
                  </div>

                  <Grid
                    sm={12}
                    style={{ textAlign: "center", margin: "5px" }}
                  >
                    <button
                      className={classes.button}
                      onClick={applyPayPeriod}
                    >
                      Apply
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </CardContent>
        <Divider style={{ marginTop: -5 }} />
     
          <>
          <div style={{ marginTop: 20, borderRadius: 10,  padding: 2 }}>
        <Grid style={{ marginBottom: 20 }}>
        {loading ? 
            <Box>
            <div style={{ width: "100%", textAlign: "center" }}>
              <CircularProgress />
            </div>
          </Box>
            :
          <Card className={classes.card} style={{ paddingTop: 20 }}>
            {/* <Typography className={classes.areaText}>Call Count Breakdown </Typography> */}
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 951 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ background: '#c9c9c9', width: '12px', height: '12px', borderRadius: '50%', marginRight: 10 }} > </div>
                <p > Total calls</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ background: '#ab7eff', width: '12px', height: '12px', borderRadius: '50%', marginRight: 10 }} > </div>
                <p > Answered calls</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ background: '#815df4', width: '12px', height: '12px', borderRadius: '50%', marginRight: 10 }} > </div>
                <p > Missed calls</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ background: '#815df4', width: '12px', height: '12px', borderRadius: '50%', marginRight: 10 }} > </div>
                <p > Unanswered Transferred calls</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ background: '#815df4', width: '12px', height: '12px', borderRadius: '50%', marginRight: 10 }} > </div>
                <p > Outbound (Connected)</p>
              </div>
        
            </div>
            <Cbreakdown calldata={data} />
          </Card>}

        </Grid>
     
      </div>
          </>
        
      </Card>

    </div>
  )
}

export default CallCountDown
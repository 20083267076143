import React from 'react'
import { Button, Card, Grid, Typography, TextField, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './styles'
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import { TabContext, TabPanel } from '@material-ui/lab';
import RecordAudio from './RecordAudio';
import UploadAudio from './UploadAudio';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core';
const muiTheme = createMuiTheme({});

const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });




function CloseHoursVoiceMail(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }


    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div className={classes.divSpacing}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            closeHoursData={props.closeHoursData}
                                            getClosedHours={props.getClosedHours}
                                            deptId={props.deptId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadAudio
                                            closeHoursData={props.closeHoursData}
                                            getClosedHours={props.getClosedHours}
                                            deptId={props.deptId}
                                            Loading={props.Loading}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={3}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ThemeProvider theme={muiTheme}>
                                <ReactAudioPlayer
                                    controls
                                    // className={classes.audioPlayer}
                                    onLoadedMetadata={handleLoadMetadata}
                                    src={props.selectedVoiceData}
                                    className={classes.audioPlayers}
                                />
                            </ThemeProvider>
                            <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                <span className={classes.filename}>{props.selectedVoiceDataname}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </>
    )
}

export default CloseHoursVoiceMail
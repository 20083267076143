import React from 'react'
import {
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';
import { useStyles } from './styles';
import Select from 'react-select'
import { useHistory } from "react-router-dom";
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import PlanServices from './PlanServices'
export default function AddPlan(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [submitted, setSubmitted] = React.useState<any>(false);
  const [data, setData] = React.useState<any>({
    planName: "",
    cost: "",
    userCount: "",
    phoneNumberCount: ""
  })
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleChangePlan = (e: any) => {
    const val = e.target.value
    if (val !== " ") {
      setData((prev: any) => {
        return {
          ...data,
          [e.target.name]: e.target.value
        }
      })
    }

  }

  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const retData = {
      planName: { ...validData },
      cost: { ...validData },
      discount: { ...validData },
      userCount: { ...validData },
      phoneNumberCount: { ...validData },
    };
    let isValid = true;
    if (submitted) {
      if (!data.planName) {
        retData.planName = {
          isValid: false,
          message: "Please enter plan name"
        };
        isValid = false;
      }
      if (format.test(data.cost)) {
        retData.cost = {
          isValid: false,
          message: "Please enter valid cost"
        };
        isValid = false;
      }
      if (!data.cost) {
        retData.cost = {
          isValid: false,
          message: "Please enter cost"
        };
        isValid = false;
      }
      if (data.cost === " ") {
        retData.cost = {
          isValid: false,
          message: "Please enter  cost"
        };
        isValid = false;
      }
      if (format.test(data.userCount)) {
        retData.userCount = {
          isValid: false,
          message: "Please enter valid user count"
        };
        isValid = false;
      }
      if (!data.userCount) {
        retData.userCount = {
          isValid: false,
          message: "Please enter user count"
        };
        isValid = false;
      }
      if (data.userCount === " ") {
        retData.userCount = {
          isValid: false,
          message: "Please enter  user count "
        };
        isValid = false;
      }
      if (format.test(data.phoneNumberCount)) {
        retData.phoneNumberCount = {
          isValid: false,
          message: "Please enter valid phone number count"
        };
        isValid = false;
      }
      if (!data.phoneNumberCount) {
        retData.phoneNumberCount = {
          isValid: false,
          message: "Please enter phone number count"
        };
        isValid = false;
      }
      if (data.phoneNumberCount === " ") {
        retData.phoneNumberCount = {
          isValid: false,
          message: "Please enter phone number count "
        };
        isValid = false;
      }

    }
    return {
      ...retData,
      isValid
    };
  };



  const SubmitPlans = () => {
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      PlanServices.postPlans(data).then((res) => {
        if (res.success) {
          // props.handlePopupModal() 
          history.push('/plans')
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const errors = validate(submitted);


  return (
    <div>
      <Card className={classes.card}>
        <Accordion expanded={open} onChange={handleOpen}>
          <AccordionSummary
            expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Add Plans
            </Typography>
          </AccordionSummary>
          <Divider className={classes.divider} />
          <AccordionDetails style={{ display: 'block' }}>
            <Grid container spacing={6}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName}>Plan Name</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      className={classes.textfild}
                      size="small"
                      placeholder="Enter Plan Name"
                      value={data.planName}
                      name='planName'
                      variant="outlined"
                      fullWidth
                      onChange={handleChangePlan}
                      error={!errors.planName.isValid}
                    />
                    {
                      !errors.planName.isValid &&
                      <p className={classes.errorMessage}>{errors.planName.message}</p>
                    }
                  </Grid>
                </Grid>

                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName11}>Phone Number Count</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      className={classes.textfild}
                      size="small"
                      placeholder="Enter Phone Number Count"
                      value={data.phoneNumberCount}
                      name="phoneNumberCount"
                      onChange={handleChangePlan}
                      variant="outlined"
                      fullWidth
                      error={!errors.phoneNumberCount.isValid}
                    />
                    {
                      !errors.phoneNumberCount.isValid &&
                      <p className={classes.errorMessage}>{errors.phoneNumberCount.message}</p>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName}>Cost ($)</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      className={classes.textfild}
                      size="small"

                      placeholder="Enter Cost"
                      value={data.cost}
                      name="cost"
                      variant="outlined"
                      fullWidth
                      onChange={handleChangePlan}
                      error={!errors.cost.isValid}
                    />
                    {
                      !errors.cost.isValid &&
                      <p className={classes.errorMessage}>{errors.cost.message}</p>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.inputFields}>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography className={classes.addName11}>User Count</Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <TextField
                      className={classes.textfild}
                      size="small"
                      placeholder="Enter User Count"
                      value={data.userCount}
                      name="userCount"
                      variant="outlined"
                      onChange={handleChangePlan}

                      fullWidth
                      error={!errors.userCount.isValid}
                    />
                    {
                      !errors.userCount.isValid &&
                      <p className={classes.errorMessage}>{errors.userCount.message}</p>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ textAlign: "center", marginTop: "30px" }}>

              <Button
                size="small"
                variant="outlined"
                className={classes.saveButton}
                onClick={SubmitPlans}
              >
                Add
              </Button>
              <Button
                size="small"
                variant="outlined"
                className={classes.cancelButton}
                //onClick={props.handlePopupModal} 
                onClick={() => history.push('/plans')}
              >
                Back
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </Card>

    </div>
  )
}
import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Table,
    Accordion, AccordionSummary, AccordionDetails, Button, TableContainer
} from '@material-ui/core';

import MyDevices from '../../../../assets/icons/Devices.png';
import MySignal from '../../../../assets/icons/Signal.png';
import MyDepts from '../../../../assets/icons/Dept.png';
import GroupUsers from '../../../../assets/icons/groupUsers.png';
import MyTelephone from '../../../../assets/icons/MyTelephone.png';
import MyRecords from '../../../../assets/icons/Record.png';
import { useStyles, PrettoSlider } from './styles';
// import OpenHoursVoicemail from '../OpenHoursVoicemail/OpenHoursVoicemail';
// import OpenHoursPhoneNumbers from '../OpenHoursPhoneNumbers/OpenHoursPhoneNumbers';
// import OpenHoursRecMessages from '../OpenHoursRecMessages/OpenHoursRecMessages';
// import OpenHoursDepartments from '../OpenHoursDepartments/OpenHoursDepartments';
// import OpenHoursTeammembers from '../OpenHoursTeammembers/OpenHoursTeammembers';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import profileServices from '../ProfileServices/ProfileServices'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
// import AddNewDevice from './AddNewDevice/AddNewDevice';
// import MydeviceTableBody from './MyDevice-table-body/MydeviceTableBody';
import { useDispatch } from 'react-redux'
import * as profileActionCreators from '../../../store/Actions/userActionCreator'
import { MdPhonelink, MdSettingsPhone } from "react-icons/md";
import { RiUserVoiceFill, RiTeamFill } from "react-icons/ri";
import { HiOfficeBuilding } from "react-icons/hi";
import { BiMessageDetail } from "react-icons/bi";
import { profile } from 'console';
import OpenHoursVoiceMail from './OpenHoursVoiceMail/OpenHoursVoiceMail';
import OpenHoursDepartments from './OpenHoursDepartments/OpenHoursDepartments';
import OpenHoursRecordMessages from './OpenHoursRecordMessages/OpenHoursRecordMessages';
import OpenHoursPhoneNumber from './OpenHoursPhoneNumber/OpenHoursPhoneNumber';
import MydeviceTableBody from './MyDeviceTableBody/MyDeviceTableBody';
import AddDevice from './AddDevice/AddDevice';
import { MoreHoriz } from '@material-ui/icons';
import UnansweredCalls from '../UnansweredCalls/UnansweredCalls';

const marks: any = []
for (let i = 0; i <= 60 / 5; i++) {
    const number = i * 5;
    marks.push({
        value: number,
        label: <span>{number}</span>,
    })
}

const openhoursOptions = [{ key: 1, value: "mydevices" }, { key: 3, value: "departments" }, { key: 5, value: "forwardNumber" }, { key: 6, value: "voicemails" }, { key: 7, value: "recmessages" }]

export function OpenHours(props: any) {
    const classes = useStyles();
    const profileDataStore: any = useSelector((state: any) => state.profileData);
    const { profileData, loading } = profileDataStore;
    const [tabname, setTabName] = React.useState('1');
    const [openModalAddNewDevice, setopenModalAddNewDevice] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [openHours, setOpenHours] = React.useState<any>({})
    const [openHoursData, setOpenHoursData] = React.useState<any>({})
    const [departmentId, setDepartmentId] = React.useState<any>()
    const [teamemberId, setteammemberId] = React.useState<any>()
    const [ringDurationTime, setRingDurationTime] = React.useState({
        ringDurationTime: 0
    })
    const [ringVal, setRingVal] = React.useState<any>(0)
    const expandOpen = () => {
        setOpen(!open);
    }
    const [selectedOption, setselectedOption] = React.useState<any>({
        currentSelectedOption: null
    })
    const [myDevices, setMyDevices] = React.useState<any>([])
    const [udsDept, setUdsDept] = React.useState<any>([])
    const [udsData, setudsData] = React.useState<any>([])
    const [udsTeamMembers, setudsTeamMembers] = React.useState<any>([])
    const [UdsVoiceData, setUdsVoiceData] = React.useState<any>('')
    const [UdsVoiceName, setUdsVoiceName] = React.useState<any>('')
    const [numVoiceData, setNumVoiceData] = React.useState<any>('')
    const [numVoiceName, setNumVoiceName] = React.useState<any>('')
    const [frwPath, setFrwPath] = React.useState<any>('')
    const [frwFileName, setFrwFileName] = React.useState<any>('')
    const [voicePath, setVoicePath] = React.useState<any>('')
    const [voiceFileName, setVoiceFileName] = React.useState<any>('')
    const [recPath, setRecPath] = React.useState('')
    const [recFileName, setRecFileName] = React.useState('')

    const dispatch = useDispatch()
    const handlePopupModalAddNewDevice = () => {
        setopenModalAddNewDevice(!openModalAddNewDevice)
    }

    const handleTabChange = (tabname: any) => {
        const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
        // if (findKeyValue.key === 1) {
        //     profileServices.updateOpenHours({ callHandlingOption: findKeyValue?.key }).then((res: any) => {
        //         //send success message
        //     }).catch((err: any) => {
        //         //send error message
        //     })
        // }
        if (findKeyValue?.key === 1) {
            // var myData = selectedOption
            // myData.currentSelectedOption = 1
            // setselectedOption(myData)
            profileServices.updateOpenHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (findKeyValue?.key === 3) {
            // var myData = selectedOption
            // myData.currentSelectedOption = 3
            // setselectedOption(myData)
            profileServices.updateOpenHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (findKeyValue?.key === 5) {
            // var myData = selectedOption
            // myData.currentSelectedOption = 5
            // setselectedOption(myData)
            profileServices.updateOpenHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (findKeyValue?.key === 6) {
            // var myData = selectedOption
            // myData.currentSelectedOption = 6
            // setselectedOption(myData)
            profileServices.updateOpenHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (findKeyValue?.key === 7) {
            // var myData = selectedOption
            // myData.currentSelectedOption = 7
            // setselectedOption(myData)
            profileServices.updateOpenHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        setTabName(tabname)
    }


    const getOpenHours = () => {
        profileServices.getopenHours().then((res: any) => {
            if (res.success) {
                setOpenHours(res.data)
                if (res.data.currentSelectedOption === 3) {
                    const data = res.data.userAndDepartments.transferingAudio
                    setudsData(res.data.userAndDepartments.transferingAudio)
                    setUdsDept(res.data.departments)
                    setudsTeamMembers(res.data.Teams)
                    setDepartmentId(res.data.userAndDepartments.departmentId)
                    setteammemberId(res.data.userAndDepartments.teamMemberId)
                    // setSelectedDeptId(res.data.departmentId)
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setUdsVoiceData(item.path)
                            setUdsVoiceName(item.fileName)
                        }
                    })
                    if (data.length === 0) {
                        setUdsVoiceData("")
                        setUdsVoiceName("")
                    }
                }
                if (res.data.currentSelectedOption === 5) {
                    const data = res.data.forwardNumbers.transferingAudio
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setFrwPath(item.path)
                            setFrwFileName(item.fileName)
                        }
                    })
                    if (data.length === 0) {
                        setFrwPath("")
                        setFrwFileName("")
                    }
                }
                if (res.data.currentSelectedOption === 6) {
                    const data = res.data.voicemail.voicemailAudio
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setVoicePath(item.path)
                            setVoiceFileName(item.fileName)
                        }
                    })
                    if (data.length === 0) {
                        setVoicePath("")
                        setVoiceFileName("")
                    }
                }
                if (res.data.currentSelectedOption === 7) {
                    const data = res.data.recording.recordingAudio
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setRecPath(item.path)
                            setRecFileName(item.fileName)
                        }
                    })
                    if (data.length === 0) {
                        setRecPath("")
                        setRecFileName("")
                    }

                }

            }
        }).catch((er: any) => {
            //send er message
        })
    }


    function createData(Type: any, devicename: any, lastconnected: any, ringthisdevice: any) {
        return { Type, devicename, lastconnected, ringthisdevice };
    }

    const rows = [
        createData('Android', '(123) 456-0008', 'Today 8:30PM', '...'),
        createData('ios', '(123) 456-0008', 'Yesturday 5:00pm', '...'),
        createData('Android', '(123) 456-0008', '2 days ago', '...'),
    ];
    React.useEffect(() => {
        if (profileData) {
            setOpenHoursData(profileData.openHours)
        }
    }, [profileData])

    React.useEffect(() => {
        if (openHoursData) {
            const openHours = openHoursData;
            console.log('dd',openHours);
            openhoursOptions.forEach((option: any) => {
                // console.log('option',option,closedHours);
                if (option.key === openHours?.currentSelectedOption) {
                    setTabName(option.value)
                }
            })
        }
    }, [openHoursData])

    React.useEffect(() => {
        if (openHoursData) {
            setRingDurationTime((prev) => {
                return {
                    ...prev,
                    ringDurationTime: openHoursData?.ringDuration,
                }
            })
        }
    }, [openHoursData])


    let timer: any = null;
    const ringHandlingChange = (e: any, data: any) => {
        const ringDurationTime = data

        clearInterval(timer)
        timer = setTimeout(() => {
            profileServices.updateOpenHours({ callHandlingOption: 1, ringDuration: ringDurationTime }).then((res: any) => {
                if (res.success) {
                } else {
                }
            }).catch((err: any) => {
            })
        }, 300)

    }

    const getMyDeviceLists = () => {
        profileServices.fetchDeviceLists().then((res: any) => {
            if (res.success) {
                setMyDevices(res.data)
            } else {
                //send error
            }
        }).catch((er: any) => {
            //send er message
        })
    }

    React.useEffect(() => {
        getOpenHours()
    }, [])
    // React.useEffect(() => {
    //     if (profileData) {
    //         const { openHours } = profileData;
    //         openhoursOptions.forEach((option: any) => {
    //             if (option.key === openHours?.callHandlingOption) {
    //                 setTabName(option.value)
    //             }
    //         })
    //     }
    // }, [profileData])


    React.useEffect(() => {
        getMyDeviceLists()
        dispatch(profileActionCreators.initOpenhour())
    }, [])



    return (
        <>
            <div className={classes.padding}>
                {
                    openModalAddNewDevice && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalAddNewDevice} />
                            <Modal title="Add Device" handlePopupModal={handlePopupModalAddNewDevice} >
                                <div className="add_user_popup" style={{ width: '600px' }}>
                                    <AddDevice getMyDeviceLists={getMyDeviceLists} handlePopupModal={handlePopupModalAddNewDevice} />
                                </div>
                            </Modal>
                        </div>
                    )
                }

            </div>

            <div className={classes.padding}>
                <Card className={classes.card}>
                    {/* <CardHeader
                        name={'Open Hours Call Handling'}
                        textfield={false}
                        label={'Open Hours Call Handling'}
                        addbox={true}
                    /> */}
                    <Accordion
                        expanded={open} onChange={expandOpen}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Open Hours Call Handling</Typography>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails className={classes.accordianNoFlex}>
                            <CardContent style={{}}>
                                <div className={classes.gridJustify}>
                                    <Card
                                        className={tabname === 'mydevices' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('mydevices')}>
                                        <CardContent className={classes.align}>
                                            <MdPhonelink className={tabname === "mydevices" ? classes.deviceWidth1 : classes.deviceWidth} />
                                            {/* <img className={classes.deviceWidth} alt="MySignal" src={MyDevices} /> */}
                                            <Typography className={tabname === 'mydevices' ? classes.devicesText : classes.devicesText1}>
                                                My Devices
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className={tabname === 'departments' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('departments')}>
                                        <CardContent className={classes.align}>
                                            <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} />
                                            {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}
                                            <Typography
                                                className={tabname === 'departments' ? classes.devicesText : classes.devicesText1}>
                                                User & Dept
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className={tabname === 'forwardNumber' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('forwardNumber')}>
                                        <CardContent className={classes.align}>
                                            <MdSettingsPhone
                                                className={
                                                    tabname === "forwardNumber"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                }
                                            />
                                            <Typography
                                                className={tabname === 'forwardNumber' ? classes.devicesText : classes.devicesText1}>
                                                forward
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className={tabname === 'voicemails' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('voicemails')}>
                                        <CardContent className={classes.align}>
                                            <RiUserVoiceFill className={tabname === "voicemails" ? classes.deviceWidth1 : classes.deviceWidth} />
                                            <Typography className={tabname === 'voicemails' ? classes.devicesText : classes.devicesText1}>
                                                Voice Mail
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                                        <Card
                                        className={tabname === 'teammembers' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('teammembers')}>
                                        <CardContent className={classes.align}>
                                            <RiTeamFill
                                            className={
                                                tabname === "teammembers"
                                                ? classes.deviceWidth1
                                                : classes.deviceWidth
                                            }
                                            />
                                            <Typography
                                            className={tabname === 'teammembers' ? classes.devicesText : classes.devicesText1}>
                                            Team members
                                            </Typography>
                                        </CardContent>
                                        </Card>
                                    </Grid> */}

                                    <Card
                                        className={tabname === 'recmessages' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('recmessages')}>
                                        <CardContent className={classes.align}>
                                            {/* <BiMessageDetail className={recmessages ? classes.deviceWidth1 : classes.deviceWidth}/> */}

                                            <BiMessageDetail
                                                className={
                                                    tabname === "recmessages"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                }
                                            />
                                            <Typography
                                                className={tabname === 'recmessages' ? classes.devicesText : classes.devicesText1}>
                                                Recorded Messages
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </div>
                            </CardContent>
                            {tabname === 'mydevices' && (
                                <>
                                    <div className={classes.divStyles}>
                                        <div>
                                            <Typography className={classes.headTexts}>My Devices</Typography>
                                        </div>
                                        <Divider style={{ marginTop: 10 }} />
                                        <CardContent style={{}}>
                                            <Card>
                                                <TableContainer>
                                                    <Table style={{ background: '#FFFFFF', border: '1px solid rgba(156, 156, 156, 0.47)', borderRadius: 5 }}>
                                                        <TableHead className={classes.tablecolor}>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHead}>Type</TableCell>
                                                                <TableCell className={classes.tableHead}>Device Name</TableCell>
                                                                <TableCell className={classes.tableHead}>Last Connected</TableCell>
                                                                <TableCell className={classes.tableHead}>Ring this device</TableCell>
                                                                <TableCell className={classes.tableHead}>Action</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {
                                                            myDevices.length > 0 && (
                                                                myDevices.map((device: any, i: any) => {
                                                                    return <MydeviceTableBody getMyDeviceLists={getMyDeviceLists} device={device} key={i} />
                                                                })
                                                            )
                                                        }
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        size="small"
                                                                        fullWidth
                                                                        className={classes.addNewButton}
                                                                        onClick={handlePopupModalAddNewDevice}
                                                                    >
                                                                        <MoreHoriz />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    {myDevices.length <= 0 && <p style={{ textAlign: 'center' }}>No Devices found</p>}
                                                </TableContainer>
                                            </Card>

                                        </CardContent>
                                    </div>
                                    <div className={classes.divStyles}>
                                        <div>
                                            <Typography className={classes.headTexts}>Ring Handling (Choose how long Your device will ring)</Typography>
                                        </div>
                                        <Divider style={{ marginTop: 10 }} />
                                        <CardContent>
                                            <PrettoSlider
                                                key={`slider-${ringDurationTime.ringDurationTime}`}
                                                valueLabelDisplay="auto"
                                                aria-label="pretto slider"
                                                defaultValue={ringDurationTime.ringDurationTime}
                                                onChange={ringHandlingChange}
                                                // onChange={ringHandlingChange}
                                                min={0}
                                                max={100}
                                            // marks={marks}
                                            />
                                        </CardContent>
                                    </div>
                                </>

                            )}
                            {tabname === 'voicemails' && (
                                <>
                                    <OpenHoursVoiceMail
                                        fwdVoiceData={numVoiceData}
                                        fwdVoiceName={numVoiceName}
                                        openHours={openHours}
                                        getOpenHours={getOpenHours}
                                        voicePath={voicePath}
                                        voiceFileName={voiceFileName}
                                    />
                                    {/* <OpenHoursVoicemail data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={2} compType={'voicemails'} patchURL={''} getURL={''} /> */}
                                </>
                            )}
                            {tabname === 'departments' && (
                                <>
                                    <OpenHoursDepartments
                                        UdsVoiceData={UdsVoiceData}
                                        UdsVoiceName={UdsVoiceName}
                                        udsDept={udsDept}
                                        teamMembers={udsTeamMembers}
                                        getOpenHours={getOpenHours}
                                        data={udsData}
                                        openHours={openHours}
                                        departmentId={departmentId}
                                        teamemberId={teamemberId}
                                    />
                                    {/* <OpenHoursDepartments data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={3} compType={'departments'} patchURL={''} getURL={''} /> */}
                                </>
                            )}

                            {tabname === 'forwardNumber' && (
                                <>
                                    <OpenHoursPhoneNumber
                                        fwdVoiceData={numVoiceData}
                                        fwdVoiceName={numVoiceName}
                                        openHours={openHours}
                                        getOpenHours={getOpenHours}
                                        frwPath={frwPath}
                                        frwFileName={frwFileName}
                                    />
                                    {/* <OpenHoursPhoneNumbers data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={5} compType={'phonenumbers'} patchURL={''} getURL={''} /> */}
                                </>
                            )}
                            {tabname === 'recmessages' && (
                                <>
                                    <OpenHoursRecordMessages
                                        openHours={openHours}
                                        getOpenHours={getOpenHours}
                                        recPath={recPath}
                                        recFileName={recFileName}
                                    />
                                    {/* <OpenHoursRecMessages data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={6} compType={'recmessages'} patchURL={''} getURL={''} /> */}
                                </>
                            )}
                        </AccordionDetails>

                        {/* UnansweredCalls */}

                        <UnansweredCalls />
                        
                    </Accordion>
                </Card>
            </div>
        </>
    );
}

import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Table,
    TableBody,
    Grid,
    TableCell,
    TableContainer,
    IconButton,
    Button,
    MenuItem,
} from '@material-ui/core';
import { useStyles } from '../Styles';
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { BsThreeDots } from "react-icons/bs";
import Backdrop from '../../../../../components/Modal/Backdrop';
import Modal from '../../../../../components/Modal/Modal'
import Grow from "@material-ui/core/Grow";
import { TableHeaderComponent } from '../../../../../components/Table/Table';
import { HeadsetMic, Person, Timeline, RecordVoiceOver, Description } from '@material-ui/icons';
import DepartmentServices from '../../../DepartmentServices/DepartmentServices'
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from '../../../../../environment/axiosInstance'
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function TableBodyData(props: any) {
    const classes = useStyles();
    const [permisions, setPermissions] = React.useState<any>([])
    const [open, setOpen] = React.useState(false);
    const [DeleteModal, setDeleteModal] = React.useState(false)
    const [userId, setUserId] = React.useState<any>('')
    const [loading, setLoading] = React.useState(false)
    const anchorRef: any = React.useRef(null);
    const [delId, setDelId] = React.useState<any>('')
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment } = departmentStateData;
    const [updatePermission, setUpdatePermissions] = React.useState<any>({
        isAdmin: false,
        recordingEnable: false,
        analytics: false
    })

    const { permissions } = props.operator

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handlePopupDelete = (id: any) => {
        setDeleteModal(!DeleteModal)
        setDelId(id)
        // console.log('bhhh', id)
    }
    const handledClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const deleteDepartmentAgents = (id: any) => {
        setLoading(true)
        axiosInstance.delete(`departments/${props.depId}/open-hours/agents/operator?userId=${id}`).then((res: any) => {
            if (res.status === 200) {
                setDeleteModal(!DeleteModal)
                setLoading(false)
                props.getOpenHours()
            } else {
                setDeleteModal(!DeleteModal)
                setLoading(false)
            }
        }).catch((err) => {
            setDeleteModal(!DeleteModal)
            setLoading(false)
        })

    }

    const updatePermisions = (data: any) => {
        // console.log('jjj', data)
        DepartmentServices.patchUserDepartmentpermissions(props.operator?.userId, props.depId, data).then((res) => {
            if (res.success) {
                //err
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getOpenHours()
            } else {
                //err
            }
        }).catch((err) => {
            //err
        })
    }

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const onChangeOperations = (id: any) => {

        if (permissions.includes(id)) {
            const index = permissions.findIndex((item: any) => {
                if (item === id) {
                    return item
                }
            })
            // console.log("index", index)

            permissions.splice(index, 1)
            const data = {
                permissions: permissions
            }
            // console.log("permissions", permissions)
            // console.log(data)
            updatePermisions(data)

        } else {
            permissions.push(id)
            const data = {
                permissions: permissions
            }
            updatePermisions(data)
        }
    }

    // const onChangeOperations = (operation: any) => {
    //     setUpdatePermissions((prev: any) => {
    //         return {
    //             ...prev,
    //             [operation]: !prev[operation]
    //         }
    //     })
    //     updatePermisions({ ...updatePermission, [operation]: !updatePermission[operation] })
    // }


    React.useEffect(() => {
        const { permissions } = props.operator
        // console.log("permissions",permissions)

        if (props.operator) {
            setUpdatePermissions({
                isAdmin: props.operator?.isAdmin,
                recordingEnable: props.operator?.recordingEnable,
                analytics: props.operator?.analytics
            })
            setPermissions(permissions)
            // setUserId({ userId: [props.operator?.userId] })
        }
    }, [props.operator])
    // console.log("permisions", permisions)

    const Permissions = () => {

        return (
            <div style={{ display: 'flex' }}>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(1)}
                            className={`${permissions?.includes(1) ? classes.activeButton : classes.button}`}
                        >
                            {/* <Person className={`${operations?.isAdmin === true ? classes.userIcon : classes.adminIcon}`} /> */}
                            <Person
                                className={`${permissions?.includes(1) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Admin</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(2)}
                            className={`${permissions?.includes(2) ? classes.activeButton : classes.button}`}
                        >
                            <Description className={`${permissions?.includes(2) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Billing</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(3)}
                            className={`${permissions?.includes(3) ? classes.activeButton : classes.button}`}
                        >
                            <Timeline className={`${permissions?.includes(3) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Analytics</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(4)}
                            className={`${permissions?.includes(4) ? classes.activeButton : classes.button}`}
                        >
                            <RecordVoiceOver
                                className={`${permissions?.includes(4) ? classes.activeIcon : classes.inActiveIcon}`}
                            />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Recordings</Typography>
                </div>

                {/* <Tooltip title={"Analytics"} placement="bottom" className="tooltip" style={{ bottom: 10 }}>
                    <IconButton
                        className={`${permissions?.includes(2) ? classes.activeButton : classes.button}`}
                    >
                        <img src={WebAnalytics} alt="WebAnalytics" className={`${permissions?.includes(2) ? classes.userIcon : classes.adminIcon}`} />
                    </IconButton>
                </Tooltip> */}

                {/* <Tooltip title={"Billings"} placement="bottom" className="tooltip" style={{ bottom: 10 }}>
                    <IconButton
                        className={`${operations?.billingEnbale === true ? classes.button : classes.adminButton}`}
                        onClick={() => onChangeOperations('billingEnbale')}>
                        <Description className={`${operations?.billingEnbale === true ? classes.userIcon : classes.adminIcon}`} />
                    </IconButton>
                </Tooltip> */}


            </div>
        );
    };

    




    //console.log("ope", props.operator)
    return (
        <>
            {
                DeleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupDelete} />
                        <Modal title="Are you sure to delete this Department Agent ?" handlePopupModal={handlePopupDelete} >
                            <Grid container spacing={2} style={{ margin: 15 }}>
                                <div className="add_user_popup" style={{ width: "500px" }}>
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.deleteButton} onClick={() => deleteDepartmentAgents(delId)}>Delete</Button>
                                            <Button size="medium" onClick={handlePopupDelete} className={classes.CancelButton}>Cancel</Button>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                        </Modal>
                    </div>
                )
            }

            <TableBody>

                <TableCell className={classes.tableCellBody}>
                    {
                        props.operator?.assignedFor === 2 ? <>{props.operator.name}</> : <>{props.operator?.firstName} {props.operator?.lastName}</>
                    }
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    {props.operator?.email}
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    <Permissions />
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    <div>
                        <Button
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <BsThreeDots />
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handledClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={() => handlePopupDelete(props.operator.userId)}>Delete</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>

                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>

            </TableBody>



        </>


    )
}

export default TableBodyData

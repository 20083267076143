import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class DashboardServices {
    getStats = async () => {
        try {
            const response = await axiosInstance.get(`/admin/home/stats`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
}
const Dashboard = new DashboardServices()
export default Dashboard
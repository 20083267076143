import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Divider,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Table,
    Accordion, AccordionSummary, AccordionDetails, Button, Radio, Tooltip
} from '@material-ui/core';
import { MdRingVolume } from 'react-icons/md'
import { BiBullseye } from 'react-icons/bi'
import { IoMaleFemale } from 'react-icons/io5'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { HiOutlineBell } from 'react-icons/hi'
import { CgChevronDoubleRightO } from 'react-icons/cg'
import { MdLowPriority } from 'react-icons/md'
import idle from '../../../../../assets/icons/idle.png'
import Talk from '../../../../../assets/icons/Talk.png'
import Agent from '../../../../../assets/icons/callsagent.png'
import { useStyles } from './styles';
import axiosInstance from '../../../../../environment/axiosInstance'
import { useSelector } from 'react-redux'
import DepartmentServices from '../../../DepartmentServices/DepartmentServices'
import reload from '../../../../../assets/images/reload.jpg'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()



function RoutingType(props: any) {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = React.useState(1)
    const [openHours, setOpenHours] = React.useState({})
    const departmentDataState = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment } = departmentDataState;
    const [openHoursData, setOpenHoursData] = React.useState<any>({})
    const [newdata, setNewData] = React.useState<any>({
        userRingType: 1
    })


    const getOpenHours = () => {
        DepartmentServices.getOpenHours(props.depId && props.depId).then((res: any) => {
            if (res.success) {
                const data = res.data

                setSelectedOption(res.data?.userRingType)
                setOpenHoursData(res.data)
                setNewData({
                    userRingType: data?.userRingType,

                })

            } else {
            }
        }).catch((err: any) => {

        })
    }

    const updateUserRingType = () => {
        axiosInstance.patch(`departments/${props.depId}/open-hours/agents`, newdata).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                getOpenHours()
            }
        })
    }


    const onSelectRingType = (option: any) => {
        setSelectedOption(option)
        var myData = newdata
        myData.userRingType = option
        setNewData(myData)
        updateUserRingType()
    }
    React.useEffect(() => {
        getOpenHours()
    }, [])

    

    const reloadDepartmentSettings = () => {
        axiosInstance.get(`/departments/reloadSettings?departmentId=${props.depId}`).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
            }
        })
    }

   


    const handleClick = () => {
        reloadDepartmentSettings()
    }

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <Typography className={classes.departmentNameText}>Routing Type</Typography>
                <Tooltip title="Reload Settings">
                    <img src={reload} alt='reload page' className={classes.reloagImg} onClick={handleClick} />
                </Tooltip>
            </div>

            {/* <Divider className={classes.divider} />
            <Typography className={classes.texttypo}>What would like to happen first?  </Typography> */}
            <CardContent>

                <div className={classes.dFlex}>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 1 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(1)}
                    >
                        <CardContent className={classes.align}>
                            <MdRingVolume className={
                                selectedOption === 1
                                    ? classes.deviceWidth1
                                    : classes.deviceWidth
                            }


                            />
                            <Typography className={
                                selectedOption === 1
                                    ? classes.devicesTextActive
                                    : classes.devicesText}>
                                Ring All
                            </Typography>
                            {/* <Radio size='small'/> */}
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 2 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(2)}
                    >
                        <CardContent className={classes.align}>
                            <BiBullseye
                                className={
                                    selectedOption === 2
                                        ? classes.deviceWidth1
                                        : classes.deviceWidth
                                }

                            />
                            <Typography className={
                                selectedOption === 2
                                    ? classes.devicesTextActive
                                    : classes.devicesText}>
                                Round Robin
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 3 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(3)}
                    >
                        <CardContent className={classes.align}>
                            <AiOutlineUnorderedList
                                className={
                                    selectedOption === 3
                                        ? classes.deviceWidth1
                                        : classes.deviceWidth
                                }

                            />
                            <Typography className={
                                selectedOption === 3
                                    ? classes.devicesTextActive
                                    : classes.devicesText}>
                                In Fixed Order
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 4 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(4)}
                    >
                        <CardContent className={classes.align}>
                            {/* <img src={idle} className={
                                selectedOption === 4
                                    ? classes.deviceWidth1
                                    : classes.deviceWidth
                            } /> */}
                            <IoMaleFemale
                                className={
                                    selectedOption === 4
                                        ? classes.deviceWidth1
                                        : classes.deviceWidth
                                }

                            />
                            <Typography className={
                                selectedOption === 4
                                    ? classes.devicesTextActive
                                    : classes.devicesText}>
                                Longest Idle First
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 5 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(5)}
                    >
                        <CardContent className={classes.align}>
                            {/* <img src={Talk} className={classes.deviceWidth} /> */}
                            <CgChevronDoubleRightO
                                className={
                                    selectedOption === 5
                                        ? classes.deviceWidth1
                                        : classes.deviceWidth
                                }

                            />
                            <Typography className={
                                selectedOption === 5
                                    ? classes.devicesTextActive
                                    : classes.devicesText}>
                                Least Talk Time First
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 6 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(6)}
                    >
                        <CardContent className={classes.align}>
                            <MdLowPriority
                                className={
                                    selectedOption === 6
                                        ? classes.deviceWidth1
                                        : classes.deviceWidth
                                }
                            />
                            <Typography className={
                                selectedOption === 6
                                    ? classes.devicesTextActive
                                    : classes.devicesText}>
                                Fewest calls Agents First
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        className={classes.cardWidth}
                        style={{ background: `${selectedOption === 7 ? '#4D72F8' : ''}` }}
                        onClick={() => onSelectRingType(7)}
                    >
                        <CardContent className={classes.align}>
                            <HiOutlineBell
                                className={
                                    selectedOption === 7
                                        ? classes.deviceWidth1
                                        : classes.deviceWidth
                                }

                            />
                            <Typography className={
                                selectedOption === 7
                                    ? classes.devicesTextActive2
                                    : classes.devicesText2}
                            >
                                Fixed Order + Keep Ringing Previous All
                            </Typography>
                        </CardContent>
                    </Card>
                </div>

            </CardContent>
        </div>
    )
}
export default RoutingType
import { Button, Card, Grid, Typography, Tabs, Tab } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import { TabContext, TabPanel } from '@material-ui/lab';
import axiosInstance from '../../../../environment/axiosInstance'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import Fileupload from './Fileupload';
import RecordAudio from './RecordAudio';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function OpenHoursDepartments(props: any) {
    const classes = useStyles()
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [value, setValue] = React.useState("2");
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    const depOptions: any = []
    props.udsDept?.map((item: any) => {
        const obj: any = {
            value: item._id,
            label: item.name
        }
        depOptions.push(obj)
    })

    const selectedDept = depOptions.find((item: any) => {
        if (item.value === props?.departmentId) {
            return item
        }
    })

    const TeamMembOptions: any = []
    props.teamMembers?.map((item: any) => {
        const obj: any = {
            value: item._id,
            label: item.firstName + ' ' + item.lastName
        }
        TeamMembOptions.push(obj)
    })

    const selectedteammemb = TeamMembOptions.find((item: any) => {
        if (item.value === props?.teamemberId) {
            return item
        }
    })

    // console.log('openHours',props.openHours);

    const handelDepartmentChange = async (e: any) => {
        const data = {
            departmentId: e.value
        }
        await axiosInstance.patch(`/profile/settings/open-hours/user-department`, data).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                toast.success('Selected Department updated', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getOpenHours()
                // fetchOpenHrAudioFiles()
            }
        })
    }
    const handelTeamMemberChange = async (e: any) => {
        const data = {
            teamMemberId: e.value
        }
        await axiosInstance.patch(`/profile/settings/open-hours/user-department`, data).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                toast.success('Selected Team Member updated', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getOpenHours()
                // fetchOpenHrAudioFiles()
            }
        })
    }


    return (
        <div>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title={"Edit Users & departments"} handlePopupModal={handlePopupModalAdd} >
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />
                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            getOpenHours={props.getOpenHours}
                                            openHours={props.openHours}
                                        />
                                        {/* <p>record file</p> */}
                                        {/* <RecordAudio depId={props.depId}/> */}
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/* <p>File Upload</p> */}
                                        <Fileupload
                                            getOpenHours={props.getOpenHours}
                                            openHours={props.openHours}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Modal>
                    </div>
                )
            }

            <div className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={6}>
                        <Grid item md={4} xs={12} sm={6}>
                            <Typography className={classes.title}>To Team Member</Typography>
                            <ReactSelect
                                placeholder="Select team Member"
                                options={TeamMembOptions}
                                value={selectedteammemb}
                                onChange={handelTeamMemberChange}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography className={classes.title}>To Department</Typography>
                            <ReactSelect
                                placeholder="Select department"
                                options={depOptions}
                                value={selectedDept}
                                onChange={handelDepartmentChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{marginTop:'30px'}}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ReactAudioPlayer
                                controls
                                className={classes.audioPlayer}
                                src={props.UdsVoiceData}
                            // onLoadedMetadata={handleLoadMetadata}
                            // src={srcss}
                            />
                            <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                {/* <p className={classes.filename}>Filename.mp3</p> */}
                                <span className={classes.filename}>{props.UdsVoiceName}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>Edit</Button>
                        </Grid>
                    </Grid>

                </Card>


            </div>
        </div>

    )
}

export default OpenHoursDepartments
import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class AddonServices {

    getAddons = async (page: any, limit: any) => {
        try {
            const response = await axiosInstance.get(`/admin/addons/?limit=${limit}&page=${page}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getAddonsByID = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/admin/addons/${id}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getAddonsSearchByName = async (name: any) => {
        try {
            const response = await axiosInstance.get(`/admin/addons/?limit=50&addonName=${name}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getAddonsSearchBytype = async (name: any) => {
        try {
            const response = await axiosInstance.get(`/admin/addons/?limit=50&addonType=${name}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getAddonsSearch = async (name: any, type: any) => {
        try {
            const response = await axiosInstance.get(`/admin/addons/?limit=50&addonType=${type}&addonName=${name}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getCountries = async () => {
        try {
            const response = await axiosInstance.get(`/admin/addons/countries`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    postAddon = async (sendData: any) => {
        try {
            const response = await axiosInstance.post("/admin/addons", sendData);

            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    deleteAddon = async (id: any) => {
        try {
            const response = await axiosInstance.delete(`/admin/addons/${id}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
         toast.error( error.response.data.message, { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    UpdateAddon = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/admin/addons/${id}`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                // console.log("----updated switch", response.data)
                return { success: true, data: data };
            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };


}
const addons = new AddonServices()
export default addons

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  cardContent: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "4px 12px",
  },
  gridItem: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent1: {
    display: "flex",
    alignItems: "center",
  },
  profile: {
    width: 150,
    height: 150,
  },
  heading: {
    color: "#2645ad",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  head1: {
    fontSize: "18px",
    color: "#565656",
    fontWeight: 500,
    fontFamily:"Work Sans"
  },
  headSmall: {
    fontSize: "15px",
    margin: 5,
    fontWeight: 600,
    color: "#565656",

  },
  align: {
    textAlign: "center",
  },
  dFlex: {
    display: "flex", justifyContent: "center", alignItems: "center"
  },
  gmail: {
    fontSize: "15px",
    margin: 5,
    fontWeight: 600,
    color: "rgb(177 177 177)",
  },
  userEdit: {
    width: 25,
    marginLeft: 20,
  },
  button: {
    margin: 10,
    fontSize: 14,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 0.208955,
    textTransform: "none",
    textAlign: "center",
    borderRadius: 25,
    padding: "4px 20px",
    color: "#4D72F8",
    backgroundColor: "#FFFFFF",
    borderColor: "#4D72F8",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
  },
  button1: {
    margin: 10,
    fontSize: 14,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 0.208955,
    textTransform: "none",
    textAlign: "center",
    borderRadius: 25,
    padding: "4px 20px",
    color: "#E91C1C",
    backgroundColor: "#FFFFFF",
    borderColor: "#E91C1C",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
  },
  grid: {
    width: "100%",
  },
  doNotDisturbCard: {
    //padding: 10,
    borderRadius: 10,
    background: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
  },
  textfieldOne:{
    color: '#9A9A9A',
    fontFamily: "Work Sans",
    fontStyle: "normal",
   },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
  },
  textfield: {
    marginTop: 15,
    color: '#9A9A9A',
    fontFamily: "Work Sans",
    fontStyle: "normal",
  },
  submitbutton: {
    margin: 15,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 0.208955,

    textAlign: "center",
    borderRadius: 4,
    color: "#787878",
    backgroundColor: "#EFEFEF",


  },
  outBondCard: {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    background: "#FFFFFF",
    marginTop: 25,
    //marginBottom: 60,
  },
  switchColor: {
    color: "#E91C1C",
  },
  cardColor: {
    color: "#2645ad",
    fontFamily: "work Sans",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 600,
  },
  prelative: {
    position: "relative",
    top: 20,
  },
  divider: {
    margin: 5,
    backgroundColor: "#E2E2E2",
  },
  dndCardContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px 14px 5px",
  },
  dndBullet: {
    fontSize: "12px",
    margin: 10,
  },
  dndText: {
    fontSize: "14px",
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#707070",
    letterSpacing: "0.02em",
    textTransform: "none",
  },
  departmentNameText: {
    fontSize: 16,
    //marginRight: 20,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#464646",
    letterSpacing: 0.1,
    textTransform: "none",
    // marginTop: 5,
    // marginLeft: 7,
  },
  editIcon: {
    color: "#4D72F8",
    marginLeft: 5,
  },
  dnd_flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  }, select: { width: "125px" }
}));

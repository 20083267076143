import React from 'react';
import {
    Card, CardContent, Grid, Typography, Divider, Accordion,
    AccordionSummary, AccordionDetails, IconButton, TextField, makeStyles, InputAdornment, FormControl, Button
} from '@material-ui/core';
//import MySignal from 'assets/icons/Signal.png';
import MySignal from '../../../../assets/icons/Signal.png'
import MyDepts from '../../../../assets/icons/Dept.png';
import GroupUsers from '../../../../assets/icons/groupUsers.png';
import MyTelephone from '../../../../assets/icons/MyTelephone.png';
import MyRecords from '../../../../assets/icons/Record.png';
import { useStyles } from './styles';
import { CardHeader } from '../../../components/CardHeader/CardHeader';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi'
import PublishIcon from '@material-ui/icons/Publish';
import { MdPhonelink, MdSettingsPhone } from 'react-icons/md'
import { RiUserVoiceFill, RiTeamFill } from 'react-icons/ri'
import { HiOfficeBuilding } from 'react-icons/hi'
import { BiMessageDetail } from 'react-icons/bi'
import { BorderColor, Delete } from '@material-ui/icons';
import AudioPlayer from 'material-ui-audio-player'
import ReactSelect from 'react-select';
import { DiDotnet } from 'react-icons/di'
import profileServices from '../../Profile/ProfileServices/ProfileServices'
// import { useSelector, useDispatch } from 'react-redux'
// import CloseHoursVoicemail from './CloseHoursVoicemail/CloseHoursVoicemail';
// import CloseHoursDepartments from './CloseHoursDepartments/CloseHoursDepartments';
// import CloseHoursRecMessages from './CloseHoursRecMessages/CloseHoursRecMessages'
// import CloseHoursTeamMembers from './CloseHoursTeamMembers/CloseHoursTeamMembers'
// import CloseHourPhoneNumbers from './CloseHoursPhoneNumbers/CloseHoursPhoneNumbers'
// import Greeting from '../../../ClosedHourGreeting/ClosedHourGreeting'
import Greeting from '../../../containers/Departments/CloseHours/ClosedHourGreeting/ClosedHourGreeting'
import DepartmentServices from '../DepartmentServices/DepartmentServices'
import * as DepartmentActionCreators from '../../../store/Actions/DepartmentsActionXCreators'
import UserNumbersServices from '../../UsersNumbers/userNumbersServices/usersNumbersServices'
import CloseHoursGreeting from './CloseHoursGreeting/CloseHoursGreeting';
import ReactAudioPlayer from 'react-audio-player';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../environment/axiosInstance'
import CloseHoursRecordMessages from './CloseHoursRecordMessages/CloseHoursRecordMessages';
import CloseHoursVoiceMail from './CloseHoursVoiceMAil/CloseHoursVoiceMail';
import UsersDepartments from './User&Dept/UsersDepartments';
import ForwardNumber from './ForwardNumber/ForwardNumber';
import { CompressOutlined } from '@mui/icons-material';

const openhoursOptions = [
    { key: 3, value: "departments" },
    { key: 5, value: "phonenumbers" }, { key: 6, value: "voicemail" }, { key: 7, value: "recmessages" }]


const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });





function CloseHours(props: any) {
    const classes = useStyles();
    const params = useParams()
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const audioFileInput: any = React.createRef();
    const [srcss, setSrcss] = React.useState<any>('')
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, loading } = departmentStateData;
    const [closeHoursData, setCloseHoursData] = React.useState<any>({})
    const [userListData, setUserListData] = React.useState<any>([])
    const [tabname, setTabName] = React.useState<any>('voicemail');
    const [open, setOpen] = React.useState(true);
    const [teamMembersDropDownData, setteamMembersDropDownData] = React.useState<any>([]);
    const [dropDownStateValue, setDropDownStateValue] = React.useState<any>({})
    const [teamMembersArray, setteamMembersArray] = React.useState<any>([])
    const [filesList, setFilesList] = React.useState<any>();
    const [Loading, setLoading] = React.useState<any>(false)
    const [selectedVoiceData, setSelectedVoiceData] = React.useState<any>('')
    const [selectedVoiceDataname, setSelectedVoiceDataname] = React.useState<any>('')
    const [recPath, setRecPath] = React.useState<any>('')
    const [recPathName, setRecPathName] = React.useState<any>('')
    const [useDeptPath, setUserDeptPath] = React.useState<any>('')
    const [userDeptPathName, setUserDeptsetPathName] = React.useState<any>('')
    const [frwNumberPath, setFrwNumberPath] = React.useState<any>('')
    const [frwNumberPathName, setFrwNumberPathName] = React.useState<any>('')
    const [closeHoursGreeting, setCloseHoursGreeting] = React.useState<any>([])
    const [recordType, setRecordType] = React.useState<any>('')

    const [selectedOption, setselectedOption] = React.useState<any>({
        currentSelectedOption: null
    })
    

    //const dispatch = useDispatch();
    const [newData, setNewData] = React.useState<any>({
        departments: {
            transferingAudio: null,
            currentSelectedValue: null
        },
        teamMember: {
            transferingAudio: null,
            currentSelectedValue: null
        },
        phoneNumber: {
            transferingAudio: null,
            currentSelectedValue: null
        },
        voicemail: {
            currentSelectedValue: null
        },
        recording: {
            currentSelectedValue: null
        },
        welcomeGreetings: null,
        currentSelectedOption: null,
        currentSelectedValue: null,
        transferingAudio: null,
        additionalPhoneNumbers: []
    })
    const shouldRecordChange = () => {
        setShouldRecord(!shouldRecord)
    }

    const getClosedHours = () => {
        DepartmentServices.getCloseHours(props.match.params.id).then((res: any) => {
            if (res.success) {
                console.log('resClose__', res)
                setCloseHoursData(res.data)
                const greetindData = res.data.closeHours.closeHrGreetingFiles[0]
                setCloseHoursGreeting(greetindData)
                // console.log('greet', greetindData)
                // greetindData.closeHrGreetingFiles[0].map((item:any)=>{
                //     console.log('item', item)
                // })
              
               
                if(res)
                setselectedOption((prev: any) => {
                    return {
                        ...prev,
                        currentSelectedOption: res.data.currentSelectedOption
                    }
                })
                if (res.data.closeHours.currentSelectedOption === 6) {
                    const voiceData = res.data.closeHours.voicemail.voicemailAudio
                    voiceData.map((item: any) => {
                        if (item.isSelected === true) {
                            setSelectedVoiceData(item.path)
                            setSelectedVoiceDataname(item.fileName)
                        }
                    })
                    if(voiceData.length === 0){
                        setSelectedVoiceData('')
                        setSelectedVoiceDataname('')
                    }
                }
                if (res.data.closeHours.currentSelectedOption === 7) {
                    const recDataaaa = res.data.closeHours.recording.recordingAudio
                    recDataaaa.map((item: any) => {
                        if (item.isSelected === true) {
                            setRecPath(item.path)
                            setRecPathName(item.fileName)
                        }
                        // else if(item.isSelected === false){
                        //     setRecPath('')
                        //     setRecPathName('')
                        // }
                    })
                    if(recDataaaa.length === 0){
                        setRecPath('')
                        setRecPathName('')
                    }
                }
                if (res.data.closeHours.currentSelectedOption === 3) {
                    const useData = res.data.closeHours.transferingAudio
                    useData.map((item: any) => {
                        if (item.isSelected === true) {
                            setUserDeptPath(item.path)
                            setUserDeptsetPathName(item.fileName)
                        }
                    })
                    if(useData.length === 0){
                        setUserDeptPath('')
                        setUserDeptsetPathName('')
                    }
                }
                if (res.data.closeHours.currentSelectedOption === 5) {
                    const numData = res.data.closeHours.ForwardNumberTransferringAudio
                    numData.map((item: any) => {
                        if (item.isSelected === true) {
                            setFrwNumberPath(item.path)
                            setFrwNumberPathName(item.fileName)
                        }
                    })
                }
            } else {

            }
        }).catch((err: any) => {

        })
    }

    React.useEffect(() => {
        if (props.match.params.id) {
            const closedHours = closeHoursData;
            openhoursOptions.forEach((option: any) => {
                if (closedHours?.closeHours) {
                    if (option.key === closedHours.closeHours.currentSelectedOption) {
                        setTabName(option.value)
                    }
                }
            })
        }
    }, [props.match.params.id, closeHoursData])



    const expandOpen = () => {
        setOpen(!open);
    }

    const handleTabChange = (tabname: any) => {
        const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
        if (tabname === 'departments') {
            var myData = selectedOption
            myData.currentSelectedOption = 3
            setselectedOption(myData)

            DepartmentServices.updateClosedHours(props.match.params.id && props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getClosedHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        // if (tabname === 'teammembers') {
        //     var myData = newData
        //     myData.currentSelectedOption = 4
        //     setNewData(myData)
        //     DepartmentServices.updateClosedHours(props.match.params.id && props.match.params.id, newData).then((res: any) => {
        //         //send success message
        //     }).catch((err: any) => {
        //         //send error message
        //     })
        // }
        if (tabname === 'phonenumbers') {
            var myData = selectedOption
            myData.currentSelectedOption = 5
            setselectedOption(myData)
            DepartmentServices.updateClosedHours(props.match.params.id && props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getClosedHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'voicemail') {
            var myData = selectedOption
            myData.currentSelectedOption = 6
            setselectedOption(myData)
            DepartmentServices.updateClosedHours(props.match.params.id && props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getClosedHours()

            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'recmessages') {
            var myData = selectedOption
            myData.currentSelectedOption = 7
            setselectedOption(myData)
            DepartmentServices.updateClosedHours(props.match.params.id && props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getClosedHours()

            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'nameDirector') {
            var myData = selectedOption
            myData.currentSelectedOption = 8
            setselectedOption(myData)
            DepartmentServices.updateClosedHours(props.match.params.id && props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getClosedHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        setTabName(tabname)
    }



    const updateClosedHourss = () => {
        setLoading(true)
        var myData = selectedOption
        myData.currentSelectedOption = props?.callHandlingOption
        setselectedOption(myData)
        DepartmentServices.updateClosedHours(props.match.params.id, selectedOption).then((res: any) => {
            if (res.success) {
                getClosedHours()
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }

    const defaultSelectedTab = async () => {
        await DepartmentServices.getCloseHours(props.match.params.id).then((res: any) => {
            if (res.success) {
                openhoursOptions.forEach((option: any) => {
                    if (option.key === res.data?.currentSelectedOption) {
                        setTabName(option.value)
                    }
                })
                getClosedHours()
            }
        })
    }

    React.useEffect(() => {
        getClosedHours()
        defaultSelectedTab()
    }, [])

    return (
        <>
            <div className={classes.padding} id='Close Hours'>
                <Card className={classes.card}>
                    <Accordion expanded={open}
                        onChange={expandOpen}>
                        <AccordionSummary
                            expandIcon={open ? < FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                Close Hours
                            </Typography>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails className={classes.accordianNoFlex}>
                            <CloseHoursGreeting
                                closeHoursData={closeHoursData}
                                getClosedHours={getClosedHours}
                                deptId={props.match.params.id}
                                Loading={Loading}
                                closeHoursGreeting={closeHoursGreeting}

                            />
                            <Typography className={classes.departmentNameText}>Call Handling</Typography>
                            <Divider
                                style={{ marginTop: 8 }}
                            //className={classes.divider} 
                            />
                            <CardContent>
                                <div className={classes.gridJustify}>
                                    <Card
                                        className={tabname === 'recmessages' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange("recmessages")}>
                                        <CardContent className={classes.align}>
                                            <BiMessageDetail className={tabname === 'recmessages' ? classes.deviceWidth1 : classes.deviceWidth} />


                                            {/* <img className={classes.deviceWidth} alt="MyRecords" src={MyRecords} /> */}
                                            <Typography
                                                className={tabname === 'recmessages' ? classes.devicesText : classes.devicesText1}>
                                                Recorded Msg
                                            </Typography>
                                        </CardContent>
                                    </Card>


                                    <Card
                                        className={tabname === 'departments' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange("departments")}>
                                        <CardContent className={classes.align}>
                                            <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} />
                                            {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}

                                            <Typography
                                                className={tabname === 'departments' ? classes.devicesText : classes.devicesText1}>
                                                User & Dept
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card
                                        className={tabname === 'phonenumbers' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange("phonenumbers")}>
                                        <CardContent className={classes.align}>
                                            <MdSettingsPhone className={tabname === 'phonenumbers' ? classes.deviceWidth1 : classes.deviceWidth} />
                                            {/* <img className={classes.deviceWidth} alt="MyTelephone" src={MyTelephone} /> */}

                                            <Typography
                                                className={tabname === 'phonenumbers' ? classes.devicesText : classes.devicesText1}>
                                                Forward Number
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className={tabname === 'voicemail' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange("voicemail")}>
                                        <CardContent className={classes.align}>
                                            <RiUserVoiceFill
                                                className={
                                                    tabname === "voicemail"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                } />
                                            <Typography className={tabname === 'voicemail' ? classes.devicesText : classes.devicesText1}>
                                                Voice Mail
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card
                                        className={tabname === 'nameDirector' ? classes.cardWidth : classes.cardWidth1}
                                        onClick={() => handleTabChange('nameDirector')}>
                                        <CardContent className={classes.align}>
                                            <DiDotnet
                                                className={
                                                    tabname === "nameDirector"
                                                        ? classes.deviceWidth1
                                                        : classes.deviceWidth
                                                }
                                            />
                                            <Typography
                                                className={tabname === 'nameDirector' ? classes.devicesText : classes.devicesText1}>
                                                Name Director
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CardContent>

                            {tabname === 'voicemail' && (
                                <CloseHoursVoiceMail callHandlingOption={6}
                                    closeHoursData={closeHoursData}
                                    deptId={props.match.params.id}
                                    Loading={Loading}
                                    getClosedHours={getClosedHours}
                                    selectedVoiceData={selectedVoiceData}
                                    selectedVoiceDataname={selectedVoiceDataname}

                                />
                            )}
                            {tabname === 'departments' && (
                                <UsersDepartments callHandlingOption={3}
                                    closeHoursData={closeHoursData}
                                    getClosedHours={getClosedHours}
                                    deptId={props.match.params.id}
                                    Loading={Loading}
                                    useDeptPath={useDeptPath}
                                    userDeptPathName={userDeptPathName}

                                />
                            )}
                            {tabname === 'teammembers' && (
                                <>
                                    {/* <CloseHoursTeamMembers callHandlingOption={4} id={props.Departmentid}
                                        getClosedHours={getClosedHours}
                                        userListData={userListData}
                                        closedHours={closeHoursData} /> 
                                    */}
                                </>
                            )}
                            {tabname === 'phonenumbers' && (
                                <>
                                    <ForwardNumber callHandlingOption={5}
                                        closeHoursData={closeHoursData}
                                        getClosedHours={getClosedHours}
                                        deptId={props.match.params.id}
                                        Loading={Loading}
                                        frwNumberPath={frwNumberPath}
                                        frwNumberPathName={frwNumberPathName}
                                    />
                                </>

                            )}
                            {tabname === 'recmessages' && (
                                <CloseHoursRecordMessages callHandlingOption={7}
                                    closeHoursData={closeHoursData}
                                    getClosedHours={getClosedHours}
                                    deptId={props.match.params.id}
                                    Loading={Loading}
                                    recPath={recPath}
                                    recPathName={recPathName}

                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </div>
        </>
    );
}
export default CloseHours;
import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles({
  card: {
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset'
  },
  audioPlayer: {
    display: "block",
    width: "100%",
    height: 48,
    '&.selected': {
      color: '#0921a9'
    },
    '&:hover': {
      color: '#4D72F8'
    },
    '*::-webkit-media-controls-panel': {
      backgroundColor: "red"
    }

  },
  greetText: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0.217535,
    margin: 3,
    color: '#464646',
  },
  grid: {
    height: 180,
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    display: 'block',
    padding: '0px 10px',
    color: '#787878',
    background: '#F4F4F4',
    border: '1px solid #F4F4F4',
    boxSizing: 'border-box',
    borderRadius: 3,
    marginLeft: 0,
    height: 10
  },
  radioBtnsContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  radioBtnMain: {
    display: 'flex',
    alignItems: 'center',
    background: 'grey',
    padding: '5px 10px'
  },
  radioText: {
    fontSize: '0.8rem',
    margin: 0
  },
  uploadButton: {
    padding: '4px 10px',
    color: '#787878',
    background: '#F4F4F4',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    borderRadius: 3,
    width:'100px',
    height:'35px',
  },
  borderColorIcon: {
    fontSize: 10,
    margin: 3,
    color: '#696969',
  },
  borderColorIconText: {
    margin: 3,
    fontSize: 10,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#696969',
    letterSpacing: 0.217535,
  },
  deleteButton: {
    position: 'absolute',
    padding: 20,
    right: 0,
    borderRadius: 1,
    color: '#787878',
    background: '#F4F4F4',
    borderColor: '#F4F4F4',
  },
  deleteIconColor: {
    fontSize: 12,
    margin: 3,
    color: '#787878',
    borderColor: '#F4F4F4',
    '&:hover': {
      color: '#787878',
      borderColor: '#F4F4F4',
    },
    '&:active': {
      color: '#787878',
      borderColor: '#F4F4F4',
    },
  },
  deleteIconText: {
    fontSize: 12,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0.217535,
    margin: 3,
    color: '#787878',
  },
  gridItemCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    padding: '4px 8px',
    borderRadius: 5,
    color: '#FFFFFF',
    background: '#F4F4F4',
    border: '1px solid #ccc',
    width:'100px',
    height:'35px',
    '&:hover': {
      background: '#DDDDDD',
    },
    '&:active': {
      background: '#DDDDDD',
    },
  },
  playbutton: {
    position: 'relative',
    top: 3,
    width: 35,
    height: 35,
    borderRadius: 50,
    color: 'white',
    padding: '8px',
  },
  videolen: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: '12px',
    alignSelf: 'center',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  vollen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  volcol: {
    color: '#4D72F8',
  },
  vollen1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: '10px',
  },
  dropdownwidth: {
    width: '528px',
    bottom: '17px',
  },
  typographywidth: {
    marginTop: 10,
    marginBottom: '27px',
  },
});

export const PrettoSlider = withStyles({
  root: {
    position: 'relative',
    top: 0,
    bottom: 0,
    color: '#4D72F8',
    width: '100%',
    marginLeft: 10,
  },
  thumb: {
    height: 17,
    width: 17,
    backgroundColor: '#fff',
    border: '2px solid currentColor',

    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 14,
  },
  rail: {
    height: 8,
    borderRadius: 14,
  },

})(Slider);

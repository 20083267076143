import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Accordion, AccordionSummary, AccordionDetails, Button,TableContainer
} from '@material-ui/core';

import MyDevices from '../../../../assets/icons/Devices.png';
import MySignal from '../../../../assets/icons/Signal.png';
import MyDepts from '../../../../assets/icons/Dept.png';
import GroupUsers from '../../../../assets/icons/groupUsers.png';
import MyTelephone from '../../../../assets/icons/MyTelephone.png';
import MyRecords from '../../../../assets/icons/Record.png';
import { useStyles, PrettoSlider } from './styles';
import OpenHoursVoicemail from '../../OpenHoursVoicemail/OpenHoursVoicemail';
import OpenHoursPhoneNumbers from '../../OpenHoursPhoneNumbers/OpenHoursPhoneNumbers';
import OpenHoursRecMessages from '../../OpenHoursRecMessages/OpenHoursRecMessages';
import OpenHoursDepartments from '../../OpenHoursDepartments/OpenHoursDepartments';
import OpenHoursTeammembers from '../../OpenHoursTeammembers/OpenHoursTeammembers';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import profileServices from '../../../ProfileServices/ProfileServices'
import Backdrop from '../../../../../components/Modal/Backdrop';
import Modal from '../../../../../components/Modal/Modal';
import AddNewDevice from './AddNewDevice/AddNewDevice';
import MydeviceTableBody from './MyDevice-table-body/MydeviceTableBody';
import { useDispatch } from 'react-redux'
import * as profileActionCreators from '../../../../../store/Actions/userActionCreator'
import { MdPhonelink, MdSettingsPhone } from "react-icons/md";
import { RiUserVoiceFill, RiTeamFill } from "react-icons/ri";
import { HiOfficeBuilding } from "react-icons/hi";
import { BiMessageDetail } from "react-icons/bi";
import { profile } from 'console';

const marks: any = []
for (let i = 0; i <= 60 / 5; i++) {
  const number = i * 5;
  marks.push({
    value: number,
    label: <span>{number}</span>,
  })
}

const openhoursOptions = [{ key: 1, value: "mydevices" }, { key: 2, value: "voicemails" }, { key: 3, value: "departments" }, { key: 4, value: "teammembers" }, { key: 5, value: "phonenumbers" }, { key: 6, value: "recmessages" }]

export function OpenHours(props: any) {
  const classes = useStyles();
  const profileDataStore: any = useSelector((state: any) => state.profileData);
  const { profileData, loading } = profileDataStore;
  const [tabname, setTabName] = React.useState('mydevices');
  const [openModalAddNewDevice, setopenModalAddNewDevice] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openHours, setOpenHours] = React.useState<any>({})
  const [openHoursData, setOpenHoursData] = React.useState<any>({})
  const [ringDurationTime, setRingDurationTime] = React.useState({
    ringDurationTime: 0
  })
  const [ringVal, setRingVal] = React.useState<any>(0)
  const expandOpen = () => {
    setOpen(!open);
  }

  const [myDevices, setMyDevices] = React.useState<any>([])
  const dispatch = useDispatch()
  const handlePopupModalAddNewDevice = () => {
    setopenModalAddNewDevice(!openModalAddNewDevice)
  }

  const handleTabChange = (tabname: any) => {
    const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
    if (findKeyValue.key === 1) {
      profileServices.updateOpenHours({ callHandlingOption: findKeyValue?.key }).then((res: any) => {
        //send success message
      }).catch((err: any) => {
        //send error message
      })
    }
    setTabName(tabname)
  }



  function createData(Type: any, devicename: any, lastconnected: any, ringthisdevice: any) {
    return { Type, devicename, lastconnected, ringthisdevice };
  }

  const rows = [
    createData('Android', '(123) 456-0008', 'Today 8:30PM', '...'),
    createData('ios', '(123) 456-0008', 'Yesturday 5:00pm', '...'),
    createData('Android', '(123) 456-0008', '2 days ago', '...'),
  ];
  React.useEffect(() => {
    if (profileData) {
      setOpenHoursData(profileData.openHours)
    }
  }, [profileData])

  React.useEffect(() => {
    if (openHoursData) {
      setRingDurationTime((prev) => {
        return {
          ...prev,
          ringDurationTime: openHoursData?.ringDuration,
        }
      })
    }
  }, [openHoursData])


  let timer: any = null;
  const ringHandlingChange = (e: any, data: any) => {
    const ringDurationTime = data

    clearInterval(timer)
    timer = setTimeout(() => {
      profileServices.updateOpenHours({ callHandlingOption: 1, ringDuration: ringDurationTime }).then((res: any) => {
        if (res.success) {
        } else {
        }
      }).catch((err) => {
      })
    }, 300)

  }

  const getMyDeviceLists = () => {
    profileServices.fetchDeviceLists().then((res: any) => {
      if (res.success) {
        setMyDevices(res.data)
      } else {
        //send error
      }
    }).catch((er: any) => {
      //send er message
    })
  }
  const getOpenHours = () => {
    profileServices.getopenHours().then((res: any) => {
      if (res.success) {
        setOpenHours(res.data)
      } else {
        //send error
      }
    }).catch((er: any) => {
      //send er message
    })
  }
  React.useEffect(() => {
    getOpenHours()
  }, [])
  React.useEffect(() => {
    if (profileData) {
      const { openHours } = profileData;
      openhoursOptions.forEach((option: any) => {
        if (option.key === openHours?.callHandlingOption) {
          setTabName(option.value)
        }
      })
    }
  }, [profileData])


  React.useEffect(() => {
    getMyDeviceLists()
    dispatch(profileActionCreators.initOpenhour())
  }, [])



  return (
    <>
      <div className={classes.padding}>
        {
          openModalAddNewDevice && (
            <div>
              <Backdrop handlePopupModal={handlePopupModalAddNewDevice} />
              <Modal title="Add Device" handlePopupModal={handlePopupModalAddNewDevice} >
                <div className="add_user_popup" style={{ width: '600px' }}>
                  <AddNewDevice getMyDeviceLists={getMyDeviceLists} handlePopupModal={handlePopupModalAddNewDevice} />
                </div>
              </Modal>
            </div>
          )
        }
      </div>

      <div className={classes.padding}>
        <Card className={classes.card}>
          <Accordion
            expanded={open} onChange={expandOpen}>
            <AccordionSummary
              expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>My Device</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianNoFlex}>
              <Divider className={classes.dividerTop} />
              <CardContent>
                <Grid container spacing={2} className={classes.grid}>
                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'mydevices' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange('mydevices')}>
                      <CardContent className={classes.align}>
                        <MdPhonelink className={tabname === "mydevices" ? classes.deviceWidth1 : classes.deviceWidth} />
                        <Typography className={tabname === 'mydevices' ? classes.devicesText : classes.devicesText1}>
                          My Devices
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'voicemails' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange('voicemails')}>
                      <CardContent className={classes.align}>
                        <RiUserVoiceFill className={tabname === "voicemails" ? classes.deviceWidth1 : classes.deviceWidth} />
                        <Typography className={tabname === 'voicemails' ? classes.devicesText : classes.devicesText1}>
                          Voice Mail
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'departments' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange('departments')}>
                      <CardContent className={classes.align}>
                        <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} />
                        
                        <Typography
                          className={tabname === 'departments' ? classes.devicesText : classes.devicesText1}>
                          Departments
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'teammembers' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange('teammembers')}>
                      <CardContent className={classes.align}>
                        <RiTeamFill
                          className={
                            tabname === "teammembers"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />
                        <Typography
                          className={tabname === 'teammembers' ? classes.devicesText : classes.devicesText1}>
                          Team members
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'phonenumbers' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange('phonenumbers')}>
                      <CardContent className={classes.align}>
                        <MdSettingsPhone
                          className={
                            tabname === "phonenumbers"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />
                        <Typography
                          className={tabname === 'phonenumbers' ? classes.devicesText : classes.devicesText1}>
                          Phone Numbers
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'recmessages' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange('recmessages')}>
                      <CardContent className={classes.align}>
                        <BiMessageDetail
                          className={
                            tabname === "recmessages"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />
                        <Typography
                          className={tabname === 'recmessages' ? classes.devicesText : classes.devicesText1}>
                          Recorded Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                </Grid>
              </CardContent>
              {tabname === 'mydevices' && (
                <>
                  <CardContent style={{marginTop:-55}}>
                  {/* <Typography className={classes.headTexts}>My Devices</Typography> */}
                  </CardContent>
                  <CardContent>
                    <TableContainer>
                    <Table>
                      <TableHead className={classes.tablecolor}>
                        <TableRow>
                          <TableCell className={classes.tableHead}>Type</TableCell>
                          <TableCell className={classes.tableHead}>Device Name</TableCell>
                          <TableCell className={classes.tableHead}>Last Connected</TableCell>
                          <TableCell className={classes.tableHead}>Ring this device</TableCell>
                          <TableCell className={classes.tableHead}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {
                        myDevices.length > 0 && (
                          myDevices.map((device: any, i: any) => {
                            return <MydeviceTableBody getMyDeviceLists={getMyDeviceLists} device={device} key={i} />
                          })
                        )
                      }
                      </TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              fullWidth
                              className={classes.addNewButton}
                              onClick={handlePopupModalAddNewDevice}
                            >
                              Add New
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    {myDevices.length <= 0 && <p style={{ textAlign: 'center' }}>No Devices found</p>}
                      </TableContainer>
                  </CardContent>
                  <CardContent>
                    <Typography gutterBottom className={classes.ringHandle}>
                      Ring Handling(Choose how long your devices will Ring )
                    </Typography>
                    <PrettoSlider
                      key={`slider-${ringDurationTime.ringDurationTime}`}
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      defaultValue={ringDurationTime.ringDurationTime}
                      onChange={ringHandlingChange}
                      // onChange={ringHandlingChange}
                      min={0}
                      max={100}
                      // marks={marks}
                    />
                  </CardContent>
                </>
              )}
              {tabname === 'voicemails' && (
                <>
                  <OpenHoursVoicemail data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={2} compType={'voicemails'} patchURL={''} getURL={''} />
                </>
              )}
              {tabname === 'departments' && (
                <>
                  <OpenHoursDepartments data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={3} compType={'departments'} patchURL={''} getURL={''} />
                </>
              )}
              {tabname === 'teammembers' && (
                <>
                  <OpenHoursTeammembers data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={4} compType={'teammembers'} patchURL={''} getURL={''} />
                </>
              )}
              {tabname === 'phonenumbers' && (
                <>
                  <OpenHoursPhoneNumbers data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={5} compType={'phonenumbers'} patchURL={''} getURL={''} />
                </>
              )}
              {tabname === 'recmessages' && (
                <>
                  <OpenHoursRecMessages data={openHoursData} openHours={openHours} getOpenHours={getOpenHours} callHandlingOption={6} compType={'recmessages'} patchURL={''} getURL={''} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Card>

      </div>
    </>
  );
}

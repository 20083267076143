import { Accordion, AccordionSummary, Box, Button, CircularProgress, ClickAwayListener, Divider, Grid, Grow, ListItemText, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import {useDispatch} from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance';
import { useStyles } from './styles';
import OfficetableBody from './OfficesTableBody';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import * as CompanyActionsCreator from '../../../store/Actions/CompanyActionsCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function Offices(props: any) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const [openUsers, setOpenUsers] = React.useState<any>(true);
    const [loading, setloading] = React.useState(false)
    const [companyList, setCompanyList] = React.useState<any>([]);
    const [deleteModal, setdeleteModal] = React.useState<any>(false);
    const [selectedCompanyId, setselectedCompanyId] = React.useState<any>();
    const [addCompanyModal, setAddCompanyModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newCompanyName, setNewCompanyName] = React.useState({
        name: ''
    });

    const handleDelete = () => {
        setdeleteModal(!deleteModal);
    }

    const expandOpen = () => {
        setOpenUsers(!openUsers);
    }

    const getcompanyList = React.useCallback(() => {
        setloading(true);
        axiosInstance.get('/company').then((res: any) => {
            if (res.data.data) {
                const data = res.data.data
                dispatch(CompanyActionsCreator.fetchCompanySuccess(data))
                var List: any = [];
                data.map((item: any) => {
                    var object = {
                        "value": item.id,
                        "label": item.name,
                        "createdDate": item.createdDate,
                        "deptCount":item.deptCount,
                    }
                    List.push(object)
                })
                setCompanyList(List)
                setloading(false)
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            setloading(false)
        })
    }, [])
    React.useEffect(() => {
        getcompanyList()
    }, [])

    const handleDeleteOption = (id: any) => {
        setselectedCompanyId(id)
        handleDelete()
    };

    const handleDeletePhoneNumber = async () => {
        await axiosInstance.delete(`/company/${selectedCompanyId}`).then((res: any) => {
            // console.log("handleDeletePhoneNumber", res)
            if (res.status === 200) {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                handleDelete()
                getcompanyList()
            }
        }).catch((err:any) => {
            toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT })

        })
    }
    const onSubmitAddCompany = () => {
        axiosInstance.post('/company', newCompanyName).then((res: any) => {
            if (res.data) {
                getcompanyList();
                handleAddCompanyPopupModal()
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                }
                )
            } else {
            }
        })
            .catch((err: any) => {
                toast.error("Error", { position: toast.POSITION.TOP_RIGHT })
                // send error
            });
    };
    const onChangeCompany = (e: any) => {
        setNewCompanyName((prev: any) => {
            return {
                ...prev,
                name: e.target.value
            }
        })
    }

    const handleAddCompanyPopupModal = () => {
        setAnchorEl(null);
        setNewCompanyName({ name: '' });
        setAddCompanyModal(!addCompanyModal);
    }

    return (
        <div>
            <Grid>
                {
                    deleteModal && (
                        <div>
                            <Backdrop handlePopupModal={handleDeleteOption} />
                            <Modal title="Are you sure want to Delete this Office?" handlePopupModal={handleDeleteOption} >
                                <div className={classes.deletepopup}>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                            <div style={{ margin: '10px' }}>
                                                <Button size="medium" onClick={handleDeletePhoneNumber} variant='contained' style={{ marginRight: '5px', background: '#4D72F8', color: 'white' }}>Delete</Button>
                                                <Button size="medium" onClick={handleDeleteOption} variant='contained' style={{ marginLeft: '5px', background: '#4D72F8', color: 'white' }}>Cancel</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Modal>
                        </div >
                    )
                }
                {
                    addCompanyModal && (
                        <div>
                            <Backdrop handlePopupModal={handleAddCompanyPopupModal} />
                            <Modal title="Add Office" handlePopupModal={handleAddCompanyPopupModal}>
                                <div className={classes.addpopup}>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                <Typography className={classes.officeName}>Office Name</Typography>
                                                {/* <input type="text" name="name" value={addcompanyName.name}  onChange={onChangeCompanyHandlr}/> */}
                                                <TextField
                                                    variant="outlined"
                                                    placeholder='Enter Office Name'
                                                    size='small'
                                                    value={newCompanyName?.name}
                                                    name="name"
                                                    onChange={onChangeCompany}
                                                    className={classes.inputcompanyFiled}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item md={12}>
                                            <div className={classes.addBtnsContainer}>
                                                <Button
                                                    variant="outlined"
                                                    size="small" onClick={onSubmitAddCompany}
                                                    style={{ background: '#4D72F8', color: 'white', textTransform: 'capitalize' }}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    style={{ background: '#FFFFFF', color: '#4D72F8', textTransform: 'capitalize' }}
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleAddCompanyPopupModal}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Modal>
                        </div>
                    )
                }

                <Accordion expanded={openUsers}
                    className={classes.card}
                    onChange={expandOpen}
                >
                    <AccordionSummary
                        expandIcon={openUsers ? < FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="lightgrey" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.users}>Offices</Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <Grid container xs={6} sm={6} md={12} >
                        <div className={classes.addBtnContainer}>
                            <Button className={classes.add} onClick={handleAddCompanyPopupModal}>Add Office</Button>
                        </div>

                        <Grid item md={12} >
                            <TableContainer className={classes.tablebodyContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.tRow}>
                                            <TableCell className={classes.tCell}>Office</TableCell>
                                            <TableCell className={classes.tCell}>Created Date</TableCell>
                                            <TableCell className={classes.tCell}>Departments Count</TableCell>
                                            <TableCell className={classes.tCell}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {loading ?
                                        (
                                            <TableBody>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginLeft: 250, marginTop: 20, marginBottom: 10 }}>
                                                    <CircularProgress />
                                                </Box>
                                            </TableBody>
                                        )
                                        :
                                        <TableBody>{
                                            companyList.map((item: any, i:any) =>
                                            (
                                                <OfficetableBody data={item} handleDeleteOption={handleDeleteOption} key={i} />
                                            )
                                            )
                                        }
                                        </TableBody>
                                    }

                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </div>
    )
}

export default Offices
import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class DashboardServices {
    getStats = async () => {
        try {
            const response = await axiosInstance.get(`/dashboard/stats`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getAllDepartmentStats = async (type: any) => {
        try {
            const response = await axiosInstance.get(`/dashboard/department-stats?type=${type}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getHourDepartmentStats = async (type: any, val: any) => {
        try {
            const response = await axiosInstance.get(`/home/department-stats?type=${type}&hourType=${val}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getAllUsersStats = async (type: any) => {
        try {
            const response = await axiosInstance.get(`/home/user-stats?type=${type}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getHourUsersStats = async (type: any, val: any) => {
        try {
            const response = await axiosInstance.get(`/home/user-stats?type=${type}&hourType=${val}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getDepartmentUsersAllStats = async (id: any, type: any) => {
        try {
            const response = await axiosInstance.get(`/home/user-stats/${id}?type=${type}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getDepartmentHourUsersStats = async (id: any, type: any, val: any) => {
        try {
            const response = await axiosInstance.get(`/home/user-stats/${id}?type=${type}&hourType=${val}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getUsersDepartmentAllStats = async (id: any, type: any) => {
        try {
            const response = await axiosInstance.get(`/home/department-stats/${id}?type=${type}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getHourUsersDepartmentStats = async (id: any, type: any, val: any) => {
        try {
            const response = await axiosInstance.get(`/home/department-stats/${id}?type=${type}&hourType=${val}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }



}
const Dashboard = new DashboardServices()
export default Dashboard

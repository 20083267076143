import { makeStyles, createStyles } from '@material-ui/core/styles';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles((theme) =>
    createStyles({
        editPencilIcon:{
            color: 'lightgrey','&:hover':{backgroundColor: '#4D72F8'}
        },
        editcheckIcon:{
            color: 'green',
            cursor:"pointer"
          
        },
        textColor: {
            color: '#464646',
            marginLeft: 10,
            fontWeight: 'bold',
        },
        padding: {
            padding: 10,
        },
        card: {
            //   margin: '15px 0px 0px 0px',
            borderRadius: 8,
            background: '#FFFFFF',
            //   boxShadow: '0 0 0.5em #E2E2E2',
            boxShadow: '0 12.5214px 16.9039px rgb(0 0 0 / 5%)'
        },
        heading: {
            color: "#4D72F8",
            fontSize: 18,
            fontWeight: 600,
        },
        departmentheading: {
            color: '#4D72F8',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 600,
            textTransform: 'none',
            marginLeft: 16
        },
        accordianNoFlex: {
            display: 'block'
        },
        callFlow: {
            color: '#A0A0A0',
            fontSize: 15,
            fontStyle: 'normal',
            fontWeight: 600,
            textTransform: 'none',
            marginLeft: 16,
            marginTop:30
        },
        dflex: {
            display: 'flex',
            alignItems: 'center',
            gap: 8
        },
        tRow: {
            background: '#F1F1F1',
            borderRadius: 6
        },
        tCell: {
            color: '#464646',
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Work Sans'
        },
        tbody: {
            color: '#787878',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Work Sans'
        },
        paper: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            outline: 0, // Disable browser on-focus borders
            boxShadow: theme.shadows[5],
        },
        contentCard: {
            margin: 10,
            background: '#F3F5FF',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: 10,
        },
        cardContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        media: {
            margin: 10,
            height: 125,
            width: '25%',
        },
        divider: {
            //margin: 5,
            marginTop: -12,
            backgroundColor: '#E2E2E2',
        },
        dndCardContent: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "16px 14px 5px",
            "@media (max-width: 768px)": {
                marginTop: -15,
            }
        },
        mt8: {
            marginTop: '-8px'
        },
        outBondCard: {
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
            borderRadius: 10,
            background: "#FFFFFF",
            marginTop: 25,
            //marginBottom: 60,
        },
        editIcon: {
            color: "#4D72F8",
            marginLeft: 5,
            marginTop: -12,
        },
        departmentNameText: {
            fontSize: 15,
            marginTop: -12,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            color: "#464646",
            letterSpacing: 0.1,
            textTransform: "none",
            "@media (max-width: 768px)": {
                marginTop: -5,
            }
            // marginTop: 5,
            // marginLeft: 7,
        },
        header: {
            color: '#4D72F8',

        },

    })
);

import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    card: {
        background: "#FFFFFF",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        borderRadius: "6px",
        padding: "20px",
    },
    submitButton: {
        padding: '4px 50px',
        border: '1px solid #00407B',
        borderRadius: '8px',
        fontWeight: 'bold',
        color: '#fff',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        marginRight: 15,
        background: '#00407B',
        "&:hover": {
            color: '#00407B',
        },
    },
    btns: {
        marginTop: 30,
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    cancelButton: {
        padding: '4px 34px',
        border: '1px solid #7b7b7c',
        borderRadius: '8px',
        fontWeight: 'bold',
        color: '#7b7b7c',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        background: '#E7E7E7',
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500
    },
    errorMessages: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginLeft: 64
    },
    inputFields: {
        margin: '10px 0px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    cardstyles: {
        background: '#FFFFFF',
        borderRadius: '8px',
        boxShadow: '0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)',
    },
    security: {
        color: "#646464",
        fontWeight: 'bold',
        fontStyle: "normal",
        fontSize: 18,
    },
    divider: {
        background: '#E2E2E2',
    },
    names: {
        color: '#646464',
        // fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        marginTop: -15,
    },
    password: {
        // marginTop: 6,
        color: '#646464',
        // fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        marginTop: -15,
    },
    mt10: { marginTop: 10, },
    textField: {
        fontSize: 14,
        // marginTop: 6,
    },
    saveButton: {
        textTransform: 'capitalize',
        border: '1px solid #00407B',
        color: '#fff',
        borderRadius: 5,
        background: '#312E81',
        '&:hover': {
            background: '#312E81',
            color: '#fff',
        }
    },
    saveSec: {
        textAlign: "center",
        background: "#fff",
        padding: "20px 0px",
        margin: "20px 0px 0px",
        boxShadow: "0px 2px 10px rgb(0 0 0 / 20%)",
        borderRadius: "5px",
    },
    addBtn:{
        background:'linear-gradient(180deg, rgba(78, 114, 228, 0.77) 0%, #735DC1 100%)',
        color:'white',
        textTransform:'capitalize'
        
    }
})
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  padding: {
    padding: 15,
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    },
  },
  card: {
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
    cursor: 'pointer'
  },
  divider: {
    marginTop: -10,
    backgroundColor: '#E2E2E2',
  },
  heading: {
    color: "#4D72F8",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  accordianNoFlex: {
    display: 'block'
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  cardWidth: {
    display: 'flex',
    justifyContent: 'center',
    width: '133px',
    height: '90px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: '#4D72F8',
  },
  cardWidth1: {
    display: 'flex',
    justifyContent: 'center',
    width: '134px',
    height: '90px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: 'FFFFFF',
  },
  align: {
    textAlign: 'center',
  },
  gridJustify: {
    marginTop: '40px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    marginBottom: 20,
    "@media (max-width: 1024px)": {
      gridTemplateColumns: 'auto auto auto',
    },
    "@media (max-width: 426px)": {
      display: 'inline-grid',
      gridTemplateColumns: 'auto auto',

    }
  },

  deviceWidth: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#8392AB',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },
  deviceWidth1: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#4D72F8',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },
  devicesText: {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  devicesText1: {
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
}));

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  {
    container: {
      // position: 'relative',
      // left: 0,
      // right: 0,
      // top: 50,
      // margin: 30,
      backgroundColor: 'inherit',
    },
    padding: {
      padding: 5,
    },
    container1: {
      //position: 'relative',
      // position: 'absolute',
      // left: 70,
      width: '100%'
    },
    statsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& > div': {
        margin: '10px 0px 30px 0px',
      },
    },
    display: {
      display: 'block',
      flexWrap: 'nowrap',
    },
  }, { index: 1 }
)
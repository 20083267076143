import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { TableCell, TableRow, Menu, MenuItem, Grid } from "@material-ui/core";
import ProfileServices from "../../Profile/ProfileServices/ProfileServices";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { useSelector } from "react-redux";
import MenuList from "@material-ui/core/MenuList";
import { BsThreeDots } from "react-icons/bs";
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
// import UpdateNumber from './UpdateNumberModel'
import Departmentservices from "../DepartmentServices/DepartmentServices";
import UpdateNumber from "./UpdateNumber";
import { useStyles } from './styles'


function TableBodyData(props: any) {
    const classes = useStyles()
    const { _id, phoneNumber, blockDate, lastCallReceived, blockSms, blockCalls } = props.row;
    const [blockcalls, setBlockcalls] = React.useState<any>(blockCalls);
    const [BlockSms, setBlockSms] = React.useState<any>(blockSms);
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment } = departmentStateData;
    const [open, setOpen] = React.useState(false);
    const [EditModal, setEditModal] = React.useState(false)
    const [deleteModal, setDeleteModal] = React.useState(false)
    const anchorRef: any = React.useRef(null);

    const onChangeNumber = () => {
        setBlockcalls(!blockcalls);
        updateNumber(props.Departmentid, { "phoneNumber": phoneNumber, "blockCalls": !blockCalls, "blockSms": blockSms });
    };
    const onChangeNumberSms = () => {
        setBlockSms(!BlockSms);
        updateNumber(props.Departmentid, { "phoneNumber": phoneNumber, "blockCalls": blockCalls, "blockSms": !blockSms });
    };

    const updateNumber = (depId: any, data: any) => {
        Departmentservices.updateDepartmentBlockedNumber(depId, _id, data)
            .then((res: any) => {
                if (res.success) {
                    props.fetchAdvancedSettingData()
                    // props.getMyDeviceLists()
                } else {
                }
            })
            .catch((err) => { });
    };

    const deleteNumber = () => {
        Departmentservices.deleteDepartmentBlockedNumber(props.Departmentid, _id)
            .then((res: any) => {
                if (res.success) {
                    props.fetchAdvancedSettingData();
                    setOpen(false);
                    setDeleteModal(!deleteModal)
                } else {
                    setDeleteModal(!deleteModal)
                }
            })
            .catch((err) => {
                setDeleteModal(!deleteModal)
            });
    };

    const handlePopupModalName = () => {
        setEditModal(!EditModal)
    }
    const handlePopupDeleteModalNumber = () => {
        setDeleteModal(!deleteModal)
    }



    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    return (
        <>
            <div>
                {
                    EditModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalName} />
                            <Modal title="Update Blocked Number" handlePopupModal={handlePopupModalName} >
                                <div className="add_user_popup" style={{ width: "500px" }}>
                                    <UpdateNumber
                                        NumberData={props.row}
                                        handlePopupModalName={handlePopupModalName}
                                        id={_id}
                                        deptId={props.Departmentid}
                                        fetchAdvancedSettingData={props.fetchAdvancedSettingData} />
                                    {/* <UpdateNumber NumberData={props.row} id={_id} deptId={props.Departmentid} getBlockedNumberData={props.getBlockedNumberData}
                                        handlePopupModal={handlePopupModalName} 
                                    /> */}
                                </div>

                            </Modal>
                        </div>
                    )
                }

                {
                    deleteModal && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupDeleteModalNumber} />
                            <Modal title="Are you sure to delete this Number ?" handlePopupModal={handlePopupDeleteModalNumber} >
                                <div className="add_user_popup" style={{ width: "500px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} style={{ textAlign: 'center' }}>
                                            <div>
                                                <Button size="medium" className={classes.deleteButton} onClick={deleteNumber}>Delete</Button>
                                                <Button size="medium" onClick={handlePopupDeleteModalNumber} className={classes.cancelButton}>Cancel</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Modal>
                        </div>
                    )
                }

            </div>
            <TableRow>
                <TableCell className={classes.tableBody}>{phoneNumber}</TableCell>
                <TableCell className={classes.tableBody}>
                    {new Date(Number(blockDate)).toLocaleString()}
                </TableCell>
                <TableCell className={classes.tableBody}>
                    {new Date(Number(lastCallReceived)).toLocaleString()}
                </TableCell>
                <TableCell>
                    <Checkbox
                        checked={blockcalls}
                        onChange={onChangeNumber}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </TableCell>
                <TableCell>
                    <Checkbox
                        checked={BlockSms}
                        onChange={onChangeNumberSms}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                </TableCell>

                <TableCell className={classes.tableBody}>
                    <div>
                        <Button
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <BsThreeDots style={{ color: "#4D72F8" }} />
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem
                                                    onClick={handlePopupModalName}
                                                >Edit</MenuItem>
                                                <MenuItem
                                                    onClick={handlePopupDeleteModalNumber}
                                                >
                                                    Delete</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default TableBodyData
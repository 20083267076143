import React from 'react'
import {
    Grid, Button, Typography, Divider,
    TableCell,
    TableRow,
    Table, TableHead,
    Card,
    TextField,
    TableBody,
    CardContent,
    TableContainer, AccordionDetails
} from '@material-ui/core';
import { TableHeaderComponent } from '../../../components/Table/Table';
import { useStyles } from './styles';
import TableConatainer from './TableBody'
import { useHistory } from 'react-router-dom';
import AddonServices from "./AddonServices/services"
import SearchFilter from './SearchFilter';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axiosInstance from '../../../environment/axiosInstance'
export default function BusinessTable() {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [addonData, setAddonData] = React.useState<any>([])
    const [countrieslist, setCountriesList] = React.useState<any>([]);
    const [state, setState] = React.useState({
        page: 1,
        totalCount: 0,
        limit: 10,
        totalPages: 0
    })
    const [searchData, setSearchData] = React.useState<any>({
        addonName: '',
        addonType: ''

    })
    const [searching, setSearching] = React.useState<any>(false)
    const onChangeSearchData = (name: any, value: any) => {
        setSearchData((prev: any) => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }
    React.useEffect(() => {
        if (searchData.addonName !== '' || searchData.addonType !== '') {
            setSearching(true)
        } else {
            setSearching(false)
        }
    }, [searchData])
    const clearFilter = () => {
        getAddonData(state.page, state.limit);
        setSearchData((prev: any) => {
            return {
                ...prev,
                addonName: '',
                addonType: ''
            }
        })
        setSearching(false)
    }
    const searchedData = (limit: any, page: any) => {
        if (searchData.addonName !== '') {
            setLoading(true)
            AddonServices.getAddonsSearchByName(searchData.addonName).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setAddonData(res?.data?.data);
                    setState((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })
        }
        else if (searchData.addonType !== '') {
            setLoading(true)
            AddonServices.getAddonsSearchBytype(searchData.addonType).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setAddonData(res?.data?.data);
                    setState((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })
        }
        else {
            setLoading(true)
            AddonServices.getAddonsSearch(searchData.addonName, searchData.addonType).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setAddonData(res?.data?.data);
                    setState((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })
        }
    }


    const getAddonData = (page: any, limit: any) => {
        setLoading(true);
        AddonServices.getAddons(page, limit).then((res: any) => {
            // console.log("----callhistory table", res.data)
            if (res.data) {
                setLoading(false)
                setAddonData(res?.data?.data);
                setState((prev) => {
                    return {
                        ...prev,
                        totalCount: res?.data?.totalDocs,
                        page: page,
                        limit: limit,
                        totalPages: res.data.totalPages
                    }
                })
            }
            else {
                setLoading(false)
            }
        }).catch((err: any) => {

            alert(err.message)
            setLoading(false)

        })
    }


    React.useEffect(() => {
        getAddonData(state.page, state.limit);
        getCountryList()
    }, [])

    React.useEffect(() => {
        getAddonData(state.page, state.limit);
        // console.log("----state.page, state.limit", state.page, state.limit)
    }, [state.page])

    const handleChangePage = (event: any, value: any) => {
        setState((prev) => {
            return {
                ...prev,
                page: value
            }
        });
    }
    const getCountryList = React.useCallback(() => {
        setLoading(true);
        axiosInstance.get('/admin/addons/countries').then((res: any) => {
            if (res.data.data) {
                setCountriesList(res.data.data)
            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
            setLoading(false)
        })
    }, [])


    return (
        <div>
            <AccordionDetails className={classes.accordianNoFlex}>
                <Typography className={classes.searchText}>
                    Search Filters
                </Typography>
                <SearchFilter
                    onChangeSearchData={onChangeSearchData}
                    paginate={state}
                    searchedData={searchedData}
                    data={searchData}
                    clearFilter={clearFilter} />
            </AccordionDetails>
            <div className={classes.dflex} >
                <Typography className={classes.accountHeading}>
                    Addon List
                </Typography>
                <div>
                    <Button
                        className={classes.addbutton}
                        variant="outlined"
                        size="small"
                        onClick={() => history.push('/addAddons')}
                    >
                        Add New
                    </Button>
                </div>
            </div>
            <Divider className={classes.divider}   />
                <Card className='marginTable' style={{marginTop:'20px'}}>
                    <Table className="marginTable" >
                        <TableHead className={classes.tablecolor}>
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    Addon Name
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Addon Cost($)
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Addon Type
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Countries
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Created Date
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Status
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ?
                            <Box>
                                <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                                    <CircularProgress />
                                </div>
                            </Box>
                            :
                            <TableBody>
                                {
                                    addonData.length > 0 && (
                                        addonData?.map((Data: any, i: any) => <TableConatainer
                                            getAddonData={getAddonData}
                                            Data={Data}
                                            countrieslist={countrieslist}
                                            paginate={state}
                                            loading={loading}
                                            key={i}
                                        />
                                        )
                                    )
                                }
                            </TableBody>}
                        {addonData.length <= 0 && !loading &&
                            <TableBody>
                                <TableRow style={{ position: "absolute", width: "100%", textAlign: "center", fontSize: 20, fontWeight: 700, marginTop: 10 }} >
                                    No Data found
                                </TableRow>

                            </TableBody>}
                    </Table>
                </Card>
                {addonData.length <= 0 ? "" :
                    <Stack spacing={2} style={{ margin: '15px 0px', float: "right", }}>
                        <Pagination count={state.totalPages} page={state.page} onChange={handleChangePage} variant="outlined" color="secondary" />
                    </Stack>}
            
        </div>
    )
}



import Dashboard from "../../containers/Dashboard/Dashboard";
import Profile from "../../containers/Profile/Profile";
import UsersNumbers from "../../containers/UsersNumbers/UsersNumbers";
// import Users from "../../containers/Users/Users"

import Departments from '../../containers/NewDepartments/Departments'
import DepartmentsNumbers from '../../containers/NewDepartments/DepartmentsNumbers'
import Billing from "../../containers/Billing/Billing";
import History from "../../containers/History/History";
import Settings from "../../containers/Settings/Settings";
import Newpassword from "../../containers/Header/Newpassword";
import AddNewPortRequest from "../../containers/UsersNumbers/NumberPorting/AddNewPortRequest/AddNewPortRequest";
import BusinessAccounts from '../../containers/BusinessUsers/BusinessAccounts/index'
import Addons from "../../containers/BusinessUsers/Addons/index"
import Reports from "../../containers/BusinessUsers/Reports/index"
import AddAccount from "../../containers/BusinessUsers/BusinessAccounts/AddBusinessAccount/AddAccount";
import AddAccountAddons from "../../containers/BusinessUsers/Addons/AddNewAddon/AddNew";
import Plan from "../../containers/BusinessUsers/Plan/Plan";
import AddPlan from "../../containers/BusinessUsers/Plan/AddPlan";
import EditBusinessAccount from '../../containers/BusinessUsers/BusinessAccounts/EditBusinessAccount/EditBusinessAccount'
import EditPlan from "../../containers/BusinessUsers/Plan/EditPlan";
import EditAddon from "../../containers/BusinessUsers/Addons/EditAddon";
import BusinessDashboard from '../../containers/BusinessUsers/Dashboard/Dashboard'
import BillingMainAdmin from "../../containers/BusinessUsers/BusinessAccounts/Billing/BillingMainAdmin";
import PhoneNumbers from "../../containers/BusinessUsers/PhoneNumbers/PhoneNumbers";
import AddPhoneNumber from "../../containers/BusinessUsers/PhoneNumbers/AddPhoneNumber";
import EditPhoneNumber from "../../containers/BusinessUsers/PhoneNumbers/EditPhoneNumber";
import PurchaseNumbers from "../../containers/BusinessUsers/PhoneNumbers/PurchaseNumbers/PurchaseNumbers";
import BuyPhoneNumber from "../../containers/BusinessUsers/PhoneNumbers/PurchaseNumbers/AvailableNumbersTable/BuyPhoneNumber";
import Schedule from "../../containers/NewDepartments/Schedule/Schedule";
import Schedules from '../../containers/MyAccount/Schedule/Schedule'
import Holidays from "../../containers/NewDepartments/Holidays/Holidays";
import Openhours from "../../containers/Departments/OpenHours/OpenhoursNew";
import AdvanceSettings from "../../containers/NewDepartments/AdvanceSettings/AdvanceSettings";
import { AdvancedSettings } from "../../containers/Departments/AdvancedSetting/AdvancedSetting";
import CallHistory from "../../containers/NewDepartments/CallHistory/CallHistory";
import MyAccount from "../../containers/MyAccount/MyAccount";
import AdvancedSetting from '../../containers/MyAccount/AdvancedSettings/AdvancedSettings'

import Callflow from '../../containers/Dashboard/Callflow'
import Recordings from '../../containers/Dashboard/Recordings'
import Analytics from '../../containers/Dashboard/Analytics/Analytics'
import SReports from '../../containers/Dashboard/Reports/Reports'
// import Users from '../../containers/UsersNumbers/Users'
// import deskphones from '../../containers/UsersNumbers/Deskphones'
import addnumber from '../../containers/PhoneNumbers/Addnumbers'
import Allnumbers from '../../containers/PhoneNumbers/Allnumbers'
import Portnumbers from '../../containers/PhoneNumbers/Portnumbers'
import Addnumbers from "../../containers/PhoneNumbers/Addnumbers";
import PhoneNumberss from '../../containers/UsersDeskphones/PhoneNumbers/PhoneNumbers'
import CloseHourss from '../../containers/NewDepartments/CloseHours/CloseHours'
import alldeskphone from '../../containers/Billing/Alldeskphones'
import CloseHours from "../../containers/MyAccount/CloseHours/CloseHours";
import { OpenHours } from "../../containers/MyAccount/OpenHours/OpenHours";
import UnansweredCalls from "../../containers/MyAccount/UnansweredCalls/UnansweredCalls";
import Users from "../../containers/UsersDeskphones/Users/Users";
import Deskphones from "../../containers/UsersDeskphones/Deskphones/Deskphones";
import departments from "../../containers/Departments/DepartmentServices/DepartmentServices";
import AllNumbers from "../../containers/UsersDeskphones/PhoneNumbers/AllNumbers/AllNumbers";
import PortNumbers from "../../containers/UsersDeskphones/PhoneNumbers/PortNumbers/PortNumbers";
import Offices from "../../containers/UsersDeskphones/Offices/Offices";
import Reportss from '../../containers/History/Reports/Reports'
import Contacts from "../../containers/Contacts/Contacts";
import Messages from "../../containers/Messages/Messages";
import EmailNotifications from "../../containers/Dashboard/EmailNotifications/EmailNotifications";

const sideBarRoutes: any = [

    {
        title: 'Dashboard',
        path: '/dashboard',
        Component: Dashboard,
        childRoutes: false,
        dynamicRoutes: false,
        childTitle: ''
    },
    {
        title: 'Dashboard',
        path: '/company/:id/dashboard',
        Component: Dashboard,
        childRoutes: false,
        dynamicRoutes: false,
        childTitle: ''
    },
    {
        title: 'Dashboard',
        path: '/dashboard/callflow',
        Component: Callflow,
        childRoutes: false,
        dynamicRoutes: false,
        childTitle: ''
    },
    {
        title: 'Dashboard',
        path: '/dashboard/recordings',
        Component: Recordings,
        childRoutes: false,
        dynamicRoutes: false,
        childTitle: ''
    },
    // {
    //     title: 'Dashboard',
    //     path: '/dashboard/analytics',
    //     Component: Analytics,
    //     childRoutes: false,
    //     dynamicRoutes: false,
    //     childTitle: ''
    // },
    {
        title: 'Dashboard',
        path: '/dashboard/reports',
        Component: SReports,
        childRoutes: false,
        dynamicRoutes: false,
        childTitle: ''
    },
    {
        title: 'Email Notifications',
        path: '/dashboard/email-notifications',
        Component: EmailNotifications,
        childRoutes: false,
        dynamicRoutes: false,
        childTitle: ''
    },
    {
        title: 'Users & Numbers',
        path: '/users&deskphones/users',
        childRoutes: true,
        dynamicRoutes: false,
        Component: Users,
        childTitle: 'User & Numbers',
    },
    {
        title: 'Users & Numbers',
        path: '/users&deskphones/deskphones',
        childRoutes: true,
        dynamicRoutes: false,
        Component: Deskphones,
        childTitle: 'User & Numbers',
    },
    {
        title: 'Offices',
        path: '/users&deskphones/offices',
        childRoutes: true,
        dynamicRoutes: false,
        Component: Offices,
    },
    {
        title: 'Users & Numbers',
        path: '/users&deskphones/phonenumbers',
        childRoutes: true,
        dynamicRoutes: false,
        Component: AllNumbers,
        childTitle: 'User & Numbers',
    },
    {
        title: 'All Number',
        path: '/users&deskphones/phonenumbers/allnumbers',
        childRoutes: true,
        dynamicRoutes: false,
        Component: AllNumbers,
        childTitle: 'User & Numbers',
    },
    {
        title: 'port Number',
        path: '/users&deskphones/phonenumbers/portnumbers',
        childRoutes: true,
        dynamicRoutes: false,
        Component: PortNumbers,
        childTitle: 'User & Numbers',
    },   
    {
        title: 'Department',
        path: '/departments',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Departments,
        childTitle: '',
    },
    {
        title: 'Department',
        path: '/departments/:id',
        childRoutes: true,
        dynamicRoutes: true,
        Component: DepartmentsNumbers,
        childTitle: '',
    },
    {
        title: 'Department',
        path: `/departments/:id/openhours`,
        childRoutes: true,
        dynamicRoutes: true,
        Component: Openhours,
        childTitle: '',
    },
    {
        title: 'Department',
        path: '/departments/:id/closehours',
        childRoutes: true,
        dynamicRoutes: true,
        Component: CloseHourss,
        childTitle: '',
    },
    {
        title: 'Schedule',
        path: '/departments/:id/schedule',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Schedule,
        childTitle: '',
    },
    {
        title: 'Holidays',
        path: '/departments/:id/holidays',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Holidays,
        childTitle: '',
    },
    {
        title: 'AdvancedSettings',
        path: '/departments/:id/advancedsettings',
        childRoutes: true,
        dynamicRoutes: true,
        Component: AdvanceSettings,
        //Component:AdvancedSettings,
        childTitle: '',
    },
    {
        title: 'Call History',
        path: '/departments/:id/callhistory',
        childRoutes: true,
        dynamicRoutes: true,
        Component: CallHistory,
        childTitle: '',
    },
    {
        title: 'AddNumbers',
        path: '/phonenumber/addnumber',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Addnumbers,
        childTitle: '',
    },
    {
        title: 'AllNumbers',
        path: '/phonenumber/allnumbers',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Allnumbers,
        childTitle: '',
    },
    {
        title: 'PortNumbers',
        path: '/phonenumber/portnumber',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Portnumbers,
        childTitle: '',
    },
    {
        title: 'AllDeskphones',
        path: '/billing/alldeskphone',
        childRoutes: true,
        dynamicRoutes: true,
        Component: alldeskphone,
        childTitle: '',
    },
    {
        title: 'My Setttings',
        path: '/myaccount/mysettings',
        childRoutes: true,
        dynamicRoutes: true,
        Component: MyAccount,
        childTitle: '',
    },
    {
        title: 'Schedule',
        path: '/myaccount/mysettings/schedule',
        childRoutes: true,
        dynamicRoutes: true,
        Component: Schedules,
        childTitle: '',
    },
    {
        title: 'Open Hours',
        path: '/myaccount/mysettings/openhours',
        childRoutes: true,
        dynamicRoutes: true,
        Component: OpenHours,
        childTitle: '',
    },
    {
        title: 'Close Hours',
        path: '/myaccount/mysettings/closehours',
        childRoutes: true,
        dynamicRoutes: true,
        Component: CloseHours,
        childTitle: '',
    },
    {
        title: 'UnAnswered Calls',
        path: '/myaccount/mysettings/unansweredcall',
        childRoutes: true,
        dynamicRoutes: true,
        Component: UnansweredCalls,
        childTitle: '',
    },
    {
        title: 'Advanced Setttings',
        path: '/myaccount/mysettings/advancedsettings',
        childRoutes: true,
        dynamicRoutes: true,
        Component: AdvancedSetting,
        childTitle: '',
    },
    {
        title: 'Billing',
        path: '/billing',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Billing,
        childTitle: '',
    },
    {
        title: 'Billing',
        path: '/mainadmin-billing',
        childRoutes: false,
        dynamicRoutes: false,
        Component: BillingMainAdmin,
        childTitle: '',
    },
    {
        title: 'History',
        path: '/history',
        childRoutes: true,
        dynamicRoutes: false,
        Component: History,
        childTitle: 'Users & Numbers',
    },
    {
        title: 'Settings',
        path: '/settings',
        childRoutes: true,
        dynamicRoutes: false,
        Component: Settings,
        childTitle: 'Settings',
    },
    {
        title: 'Profile',
        path: '/profile',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Profile,
        childTitle: '',
    },
    {
        title: 'Change Password',
        path: '/ChangePassword',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Newpassword,
        childTitle: '',
    },
    {
        title: 'New Port Request',
        path: '/newportrequest',
        childRoutes: false,
        dynamicRoutes: false,
        Component: AddNewPortRequest,
        childTitle: '',
    },

    {
        title: 'Reports',
        path: '/dashboard/allreports',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Reportss,
        childTitle: '',
    },
    // {
    //     title: 'Dashboard',
    //     path: '/dashboard',
    //     childRoutes: false,
    //     dynamicRoutes: false,
    //     Component: BusinessUsersMain,
    //     childTitle: '',
    // },
    {
        title: 'Business Accounts',
        path: '/businessAccounts',
        childRoutes: false,
        dynamicRoutes: false,
        Component: BusinessAccounts,
        childTitle: '',
    },
    {
        title: 'Phone Numbers',
        path: '/phone-numbers',
        childRoutes: false,
        dynamicRoutes: false,
        Component: PhoneNumbers,
        childTitle: '',
    },
    {
        title: 'Phone Numbers',
        path: '/add-phonenumber',
        childRoutes: false,
        dynamicRoutes: false,
        Component: AddPhoneNumber,
        childTitle: '',
    },
    {
        title: 'Purchase Numbers',
        path: '/phonenumbers/purchaseNumber',
        childRoutes: false,
        dynamicRoutes: false,
        Component: PurchaseNumbers,
        childTitle: '',
    },
    {
        path: "/phonenumbers/buyNumber/:number/:state/:rateCenter/:tier/:vendorId/:vendorName",
        Title: "Buy Phone Number",
        Component: BuyPhoneNumber,
        routes: [],
        // crud_name : "purchase",    
        // permission_index:4,
        // permission_name:"phoneNumbers",
    },
    {
        title: 'Update Phone Number',
        path: '/editPhonenumber/:id',
        childRoutes: false,
        dynamicRoutes: false,
        Component: EditPhoneNumber,
        childTitle: '',
    },
    {
        title: 'Add Business Accounts',
        path: '/addbusinessAccounts',
        childRoutes: false,
        dynamicRoutes: false,
        Component: AddAccount,
        childTitle: '',
    },
    {
        title: 'Update Business Accounts',
        path: '/editbusinessAccounts/:id',
        childRoutes: false,
        dynamicRoutes: false,
        Component: EditBusinessAccount,
        childTitle: '',
    },
    {
        title: 'Plans',
        path: '/addplans',
        childRoutes: false,
        dynamicRoutes: false,
        Component: AddPlan,
        childTitle: '',
    },
    {
        title: 'EditPlan',
        path: '/plan/editPlan/:id',
        childRoutes: false,
        dynamicRoutes: false,
        Component: EditPlan,
        childTitle: '',
    },
    {
        title: 'BusinessDashboard',
        path: '/businessDashboard',
        childRoutes: false,
        dynamicRoutes: false,
        Component: BusinessDashboard,
        childTitle: '',
    },
    {
        title: 'Addons',
        path: '/addons',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Addons,
        childTitle: '',
    },
    {
        title: 'Addons',
        path: '/addAddons',
        childRoutes: false,
        dynamicRoutes: false,
        Component: AddAccountAddons,
        childTitle: '',
    },
    //edit
    {
        title: 'Addons',
        path: '/addon/edit/:id',
        childRoutes: false,
        dynamicRoutes: false,
        Component: EditAddon,
        childTitle: '',
    },

    {
        title: 'Reports',
        path: '/reports',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Reports,
        childTitle: '',
    },
    {
        title: 'Contacts',
        path: '/contacts',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Contacts,
        childTitle: '',
    },
    {
        title: 'Messages',
        path: '/messages',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Messages,
        childTitle: '',
    },
    {
        title: 'Messages',
        path: '/messages/:id',
        childRoutes: false,
        dynamicRoutes: false,
        Component: Messages,
        childTitle: '',
    },
]

export default sideBarRoutes;
import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import { BiRefresh } from 'react-icons/bi'
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from "../styles";
import { BiSearchAlt2 } from "react-icons/bi";
import ProfileServices from "../../../../../Profile/ProfileServices/ProfileServices";
import { AnyIfEmpty } from "react-redux";
import { useSelector } from 'react-redux'
import CountryStates from "../CountryStates/CountryStates";
import { RiEyeCloseFill } from "react-icons/ri";
import UserNumberServices from '../../../../../UsersNumbers/userNumbersServices/usersNumbersServices'
import logo from '../../../../../../assets/images/Lodingg.gif'
const VanityOptions = [
  { label: "Begins With", value: "1" },
  { label: "Contains", value: "2" },
  { label: "Ends With", value: "3" },
];
function Vanity(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [vanity, setVanity] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const [vanityNumber, setVanityNumber] = React.useState<any>([]);
  const [mobile, setmobile] = React.useState("");
  const [mobileno, setMobileNo] = React.useState("")
  const [isError, setIsError] = React.useState(false);
  const [option, setOption] = React.useState('1')
  const [Npa, setNpa] = React.useState<any>();
  const [load, setLoad] = React.useState(false)

  const [addData, setAddData] = React.useState<any>({

    "phoneNumber": "",

  })



  const handleSelectedNumbers = () => {

    UserNumberServices.PostNumbers(addData).then((res: any) => {

      if (res.success) {
        setVanityNumber(res.data);
        props.handlePopupModal()
        props.fetchNumberLists()
      } else {

      }
    }).catch((err: any) => {
      // send error
    })

  }
  const handleOption = (data: any) => {
    setOption(data.value)
    if (mobile) {
      if (mobile.length > 10) {
        setIsError(true);
      };
      if (data.value == "3") {
        setMobileNo(mobile.padEnd(3, '_') + mobile.padStart(7, '_'))
      }
      else if (data.value == '1') {
        setMobileNo(mobile.padEnd(7, '_'))
      }
      else if (data.value == "2") {
        setMobileNo(mobile.padEnd(4, '_') + mobile.padEnd(3, '_'))

      }
    }

  }
  const handleContactNumber = (e: any) => {
    setmobile(e.target.value);
    if (e.target.value === "") {
      setVanityNumber([])
      setVanity(false)
    }
    if (e.target.value.length > 10) {
      setIsError(true);
    };
    // if (option == "3") {
    //   const val = Npa + e.target.value
    //   setMobileNo(Npa.padEnd(3, '*') + e.target.value.padStart(7, '*'))
    // }
    // else if (option == '1') {
    //   const val = Npa + e.target.value
    //   setMobileNo(val.padEnd(10, '*'))
    // }
    // else if (option == "2") {
    //   setMobileNo(Npa.padEnd(4, '*') + e.target.value.padEnd(6, '*'))

    // }
    if (option == "3") {
      const val = Npa + e.target.value
      setMobileNo(e.target.value.padStart(7, '_'))
    }
    else if (option == '1') {
      const val = e.target.value
      setMobileNo(val.padEnd(7, '_'))
    }
    else if (option == "2") {
      setMobileNo(''.padEnd(3, '_') + e.target.value.padEnd(4, '_'))

    }


  }
  const handleChangeNumbers = () => {
    setLoading(true)
    var myIndex: any = vanityNumber.length > index + 5 ? index + 5 : 0
    setIndex(myIndex)
    setTimeout(() => {
      setLoading(false)
    }, 1000);

  }
  const fetchLocalNumbers = () => {
    setVanity(true)
    const npafilter = props.cities.filter((itm: any) => itm.id === props.city.id)

    const filterObject: any = {
      stateValue: props.state?.value,
      // cityValue: props.city.id,
      npa: npafilter[0]?.npa,
      number: mobileno,
      searchType: 3
    }
    setLoading(true)
    ProfileServices.fetchVanityNumbers(filterObject)
      .then((res: any) => {

        if (res.success) {
          setLoading(false)
          setVanityNumber(res.data);
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => { setLoading(false) });
  }
  React.useEffect(() => {
    const npafilter = props.cities.filter((itm: any) => itm.value === props.city.value)
    setmobile("")
    setNpa(npafilter[0]?.npa)
  }, [props.city])
  const handleSelectedNumber = (num: any) => {
    setLoad(true)
    setAddData((prev: any) => {
      return {
        ...prev,
        phoneNumber: num
      }
    })
    setTimeout(() => {
      setLoad(false)
    }, 1000);
  }
  return (
    <div>
      <TabPanel value="3" classes={{ root: classes.padding }}>
        <CountryStates countryOptions={props.countryOptions}
          onChangeStateType={props.onChangeStateType} states={props.states} state={props.state}
          onChangeCountryType={props.onChangeCountryType} country={props.country} />
        <Grid container spacing={2}>
          <Grid item md={6} className={classes.gridItem}>

            <Typography className={classes.text} style={{ marginTop: 15 }}>
              Area Code
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                value={props.city}
                placeholder="Area Code"
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} className={classes.gridItem}>

            <Typography className={classes.text} style={{ marginTop: 15 }}>
              Vanity Number
            </Typography>
            <FormControl>
              <div className={classes.vanitytext}>
                <ReactSelect
                  className={classes.dropdownselect}
                  displayEmpty
                  options={VanityOptions}
                  onChange={handleOption}
                  value={VanityOptions.filter((opt: any) => opt.value === option)}
                  inputProps={{ "aria-label": "Without label" }}
                // onChange={onChangeVanityNumber}
                />
                <input
                  className={classes.vanityText2}
                  placeholder="Enter Number"
                  type="tel"
                  maxLength={7}
                  value={mobile}
                  onChange={handleContactNumber}
                />
                <div>
                  <Button disabled={mobile.length >= 3 ? false : true} onClick={fetchLocalNumbers} style={{ cursor: "pointer", padding: '7px 8px', borderRadius: "10px", marginLeft: "40px", height: "37px", border: '1px solid #abc8ff', backgroundColor: mobile.length >= 3 ? "#4D72F8" : "#dedede", width: "50%" }}>
                    <BiSearchAlt2 />
                  </Button>
                </div>
              </div>
            </FormControl>
          </Grid>
        </Grid>
        <Card className={classes.availableCard}>
          <CardContent>
            <div className={classes.bFlex} >
              <div>
                <Typography className={classes.text}>Available Numbers</Typography>
              </div>
              <div>
                <Button className={classes.availableButton}
                  // style={{ marginLeft: "128px" }}
                  onClick={handleChangeNumbers}
                  startIcon={<BiRefresh />}>Refresh Numbers</Button>
              </div>
            </div>
            <Divider className={classes.divider} />
            <CardContent>
              {loading ?
                <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", textAlign: 'center', width: "100%", height: "80px", }}>
                  {/* <CircularProgress disableShrink /> */}
                  <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
                </div> :
                <div className={classes.Numbers} >

                  {vanityNumber?.length > 0 ? (
                    vanityNumber.slice(index, index + 4).map((loc: any, i: any) => (

                      <Grid container spacing={2} style={{}}>
                        <Grid item md={2}></Grid>
                        <Grid item md={10} >

                          <Button
                            key={i}
                            className={addData?.phoneNumber === loc?.number ? classes.numberButtonActive : classes.numberButton}
                            variant="outlined"
                            size="small"
                            fullWidth
                            startIcon={<PhoneIcon />}
                            onClick={() => setAddData((prev: any) => {
                              return {
                                ...prev,
                                phoneNumber: loc?.number
                              }
                            })}
                          >
                            {loc?.number}
                          </Button>
                        </Grid>
                      </Grid>
                    ))
                  ) :
                    !loading && vanity ?

                      <div className={classes.errMsg} style={{ textAlign: 'center',margin: '10px auto' }}>
                        <p style={{ textAlign: 'center', }}>Sorry, we can't find any numbers that contain {mobile} </p>
                      </div>
                      : < div style={{ alignItems: "center", color: "lightgray", textAlign: 'center', width: "100%" }}>
                        {/* <CircularProgress disableShrink /> */}
                        <p style={{ textAlign: 'center', marginTop: "23px" }}>Search Result will find here </p>
                      </div>
                  }
                </div>
              }



            </CardContent>
          </CardContent>
        </Card>
        <div style={{ marginLeft: "-51px" }}>
          <IconButton className={addData?.phoneNumber !== '' ? classes.nextIconActive : classes.nextIcon} onClick={handleSelectedNumbers}>
            <GrFormNextLink className={classes.icon} />
          </IconButton>
        </div>
      </TabPanel>
    </div>
  );
}

export default Vanity;

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      color: '#B4B3B3',
      "@media (max-width: 426px)": {
        height:12,
        paddingLeft:0
      }
    },
    modalCard: {
      // margin: '10px 0px 10px 0px',
      height:'500px',
      marginTop: '-20px',
      borderRadius: 10,
      background: '#FFFFFF',
      boxShadow: '0 0 0 0',
      "@media (max-width: 426px)": {
        height:400,
        marginTop: '-20px',
      }
    },
    userText: {
      fontSize: 18,
      fontFamily: 'Arial ! important',
      // textAlign: 'center',
      fontWeight: 400,
      color: '#848484',
      letterSpacing: 0.208955,
      textTransform: 'none',
      marginBottom: 38,
      "@media (max-width: 426px)": {
        fontSize: 14,
        marginBottom: 26,
        marginTop:-15
      }
    },
    searchfilter:{
      marginRight: 15,
      "@media (max-width: 426px)": {
        height:35,
      }
    },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
      "@media (max-width: 426px)": {
        marginLeft:-90
      }
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'row' ,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    submitButton: {
      width: 110,
      height: 45,
      borderRadius: 5,
      border: '1px solid #F0EEEE',
      color: '#FFFFFF',
      backgroundColor: '#4D72F8',
      textTransform: 'none',
      padding: '6px 22px',
      '&:hover': {
        backgroundColor: '#4D72F8',
      },
      '&:active': {
        backgroundColor: '#4D72F8',
      },
      "@media (max-width: 426px)": {
       width: 110,
        height: 35,
        padding: '4px',
      }
    },
    adduserTableContainer:{
      overflowX: 'unset',
      "@media (max-width: 426px)":{
        overflowX:"auto",
        overflowY:"auto",
      }

    },
    table:{
      // "@media (max-width: 426px)":{
      //   overflowX:"auto",
      //   overflowY:"auto",
      // }
    },
    tableCellRow: {
      fontSize: 14,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      //textAlign: 'center',
      letterSpacing: 0.217535,
      color: '#545454',
      "@media (max-width: 426px)":{
        fontSize: 12,
      }
    },
    modalTableCellBody: {
      padding: 10,
      fontSize: 15,
      border: 0,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: 0.21553,
      //textAlign: 'center',
      color: '#939292',
      "@media (max-width: 426px)":{
        fontSize: 12,
         padding: 10,
      }
    },
    numberText: {
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: '#A5A5A5',
      fontSize: 13,
      textAlign: 'center',
    },
    editIcon: {
      marginLeft: 15,
    },
    btnRight: {
      marginTop:'30px',
      marginBottom:'0px',
      display: 'flex',
      alignItems: 'center',
      width: '30%',
      margin: 'auto',
      "@media (max-width: 426px)":{
        width: '40%',
      }
    },
    addButton: {
      width: 110,
      height: 45,
      marginRight: 10,
      borderRadius: 5,
      color: '#FFFFFF',
      fontWeight: 700,
      fontSize: 16,
      fontFamily: 'Arial ! important',
      fontStyle: 'normal',
      textTransform: 'none',
      backgroundColor: '#4D72F8',
      border: '1px solid #4D72F8',
      padding: '6px 22px',
      '&:hover': {
        backgroundColor: '#4D72F8',
      },
      '&:active': {
        backgroundColor: '#4D72F8',
      },
      "@media (max-width: 426px)":{
        width: 110,
        height: 30,
        marginRght: 10,
        fontSize: 12,
        padding: '8px',
      }
    },
    cancelButton: {
      width: 110,
      height: 45,
      marginRight: 15,
      borderRadius: 5,
      color: '#7A7A7A',
      fontWeight: 700,
      fontSize: 16,
      fontFamily: 'Arial ! important',
      fontStyle: 'normal',
      textTransform: 'none',
      backgroundColor: '#F1F1F2',
      border: '1px solid #F0EEEE',
      padding: '6px 22px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      '&:active': {
        backgroundColor: '#FFFFFF',
      },
      "@media (max-width: 426px)":{
        width: 110,
        height: 30,
        marginRight: 10,
        fontSize: 12,
        padding: '8px',
      }
    },
  }))
import React from 'react'
import { Button, Card, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useStyles } from './style'
import axisoInstace from '../../../environment/axiosInstance'


function EditContact(props: any) {
    const classes = useStyles()
    const [showPw, setshowPw] = React.useState(false)
    const habdleshowPW = () => {
        setshowPw(!showPw)
    }
    const [addContact, setAddContact] = React.useState({
        // email: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
    })

    const postContact = () => {
        axisoInstace.post('/contacts', addContact).then((res: any) => {
            if (res.status === 201) {
                props.fetchContacts()
                props.handlePopupModal()
            }
        })
    }

    const onChangeFields = (e: any) => {
        setAddContact((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }
    return (
        <div style={{ padding: 10 }}>

            <Grid container className={classes.mt10}>
                <Grid item xl={5} lg={8} md={5} sm={5} xs={12}>
                    <Grid container className={classes.inputFields}>
                        <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
                            <Typography className={classes.names} style={{ textAlign: 'center' }}>First name</Typography>
                        </Grid>
                        <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter firstname"
                                // className={classes.textField}
                                onChange={onChangeFields}
                                value={addContact.firstName}
                                name="firstName"
                            // error={!errors.email.isValid}
                            />
                            {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.inputFields}>
                        <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
                            <Typography className={classes.names} style={{ textAlign: 'center' }}>Last Name</Typography>
                        </Grid>
                        <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter lastname"
                                // className={classes.textField}
                                onChange={onChangeFields}
                                value={addContact.lastName}
                                name="lastName"
                            // error={!errors.email.isValid}
                            />
                            {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.inputFields}>
                        <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
                            <Typography className={classes.names} style={{ textAlign: 'center' }}>Email</Typography>
                        </Grid>
                        <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter email"
                                // className={classes.textField}
                                // onChange={onChangeFields}
                                // value={addUser.email}
                                name="email"
                            // error={!errors.email.isValid}
                            />
                            {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.inputFields}>
                        <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
                            <Typography className={classes.names} style={{ textAlign: 'center' }}>Phone Number</Typography>
                        </Grid>
                        <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
                            <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                placeholder="Enter Phone number"
                                // className={classes.textField}
                                onChange={onChangeFields}
                                value={addContact.contactNumber}
                                name="contactNumber"
                            // error={!errors.email.isValid}
                            />
                            {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
                        </Grid>
                    </Grid>
                </Grid>

                {/* end first col-6 */}
                <Grid item xl={1} lg={1} md={12} sm={12} xs={12}></Grid>

                {/* end last col-6 */}
            </Grid>
            <div>
                <div style={{ marginTop: 25, gap: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={postContact} className={classes.addBtn} size='small' variant='outlined'>Add</Button>
                    <Button size='small' variant='outlined' onClick={props.handlePopupModal}>Cancel</Button>
                </div>
            </div>

        </div>
    )
}

export default EditContact
import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
class Services {
    getholidayNameList = async (deptId:any,name: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${deptId}/holidays?name=${name}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
}
const HolidaysServices = new Services()
export default HolidaysServices;
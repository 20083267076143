import React from 'react'
import {
  TableCell,
  TableRow,
  Button,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { useStyles } from './styles'

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { MdDelete } from "react-icons/md"
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { AiFillEdit } from 'react-icons/ai'
import axiosInstance from '../../../environment/axiosInstance'
import Moment from 'moment'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal'
import AddonServices from './AddonServices/services'
import EditAddon from './EditAddon'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { BsThreeDots } from "react-icons/bs";

export default function BusinessTableBody(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [delId, setDelId] = React.useState<any>('')
  const [editData, setEditData] = React.useState<any>({})
  const [editModal, setEditModal] = React.useState(false)
  const [addonNamee, setAddonName] = React.useState("")
  const [editId, setEditId] = React.useState<any>('')
  const anchorRef: any = React.useRef(null);
  const [loading, setLoading] = React.useState(false)
  const [countries, setCountries] = React.useState<any>([]);
  const pages = [5, 10, 25]
  const [page, setPage] = React.useState(0)

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useLayoutEffect(() => {
    const data = props.addonData;
    setData(data);
    setCountries(props.countrieslist)
  }, [props.addonData, props.countrieslist]);

  const changeBackground = (e: any) => {
    e.target.style.color = 'blue';
    e.target.style.textDecoration = "underline"
  }
  const MouseOut = (event: any) => {
    event.target.style.color = "#8392AB";
    event.target.style.textDecoration = "none"
  }


  const handleEditModal = (id: any, data: any) => {
    setEditModal(!editModal)
    setEditId(id)
    setEditData(data)
  }
  const handleDeleteModal = (id: any, name: any) => {
    setDeleteModal(!deleteModal)
    setAddonName(name)
    setDelId(id)
  }


  const handleDeleteClose = () => {
    setDeleteModal(!deleteModal)
  }



  const deleteAddonsData = () => {
    AddonServices.deleteAddon(delId).then((res) => {
      if (res.data) {
        props.getAddonData(props.paginate.page, props.paginate.limit)
        setDeleteModal(!deleteModal)
      }
    })
  }
  var Datedata: any = parseInt(props.Data.createdDate)
  var dataa = Moment(Datedata).format('YYYY-MM-DD HH:mm:ss')
  var CountryName: any = [];
  props.Data.countries.map((item: any) => {
    countries.map((country: any) => {
      if (item === country.id) {
        CountryName.push(country.CountryName)
      }
    })
  })
  return (
    <>
      {
        deleteModal && (
          <div>
            <Backdrop handlePopupModal={handleDeleteModal} />
            <Modal title={"Are you sure to delete" + " " + addonNamee + " " + "Addon ?"} handlePopupModal={handleDeleteModal} >
              <Grid container spacing={2} style={{ margin: 15 }}>
                <div className="add_user_popup" style={{ width: "500px" }}>
                  <Grid item md={12} style={{ textAlign: 'center' }}>
                    <div>
                      <Button size="medium" className={classes.deleteButton} onClick={deleteAddonsData}>Delete</Button>
                      <Button size="medium" onClick={handleDeleteClose} className={classes.CancelButton}>Cancel</Button>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Modal>
          </div>
        )
      }
      {
        editModal && (
          <div>
            <Backdrop handlePopupModal={handleEditModal} />
            <Modal title="Addons-Update" handlePopupModal={handleEditModal} >
              <Grid container spacing={2} style={{ margin: 15 }}>
                <div className="add_user_popup" style={{ width: "500px" }}>
                  <EditAddon handlePopupModal={handleEditModal}
                    getAddonData={props.getAddonData}
                    id={editId}
                    data={editData} />
                </div>
              </Grid>
            </Modal>
          </div>
        )
      }

      <TableRow >
        <TableCell className={classes.tableCellBody}>
          <Link to={`/addon/edit/${props.Data.id}`} style={{ cursor: 'pointer', color: '#8392AB', textDecoration: 'none', }} onMouseOver={changeBackground} onMouseOut={MouseOut}>
            {props.Data.addonName}
          </Link>
        </TableCell>
        <TableCell className={classes.tableCellBody}>{props.Data.addonCost}</TableCell>
        <TableCell className={classes.tableCellBody}>{props.Data.addonType === 1 ? "Call" : props.Data.addonType === 2 ? "Sms" : "Features"}</TableCell>
        <TableCell className={classes.tableCellBody}>
          {CountryName.map((item: any) =>
            <div>{item}</div>
          )}
        </TableCell>
        <TableCell className={classes.tableCellBody}>{dataa}</TableCell>
        <TableCell className={classes.tableCellBody}>
          {props.Data.status === 1 ?
            <Button
              size="small"
              variant="outlined"
              className={classes.activeButton}>Active</Button> :
            <Button
              size="small"
              variant="outlined"
              className={classes.inactiveButton}>InActive</Button>}
        </TableCell>
        <TableCell className={classes.tableCellBody}>
          <div>
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <BsThreeDots />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper style={{ borderRadius: "0px 10px 10px 10px", background: '#F3F5FF' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() => history.push(`/addon/edit/${props.Data.id}`)}
                        >
                          <AiFillEdit
                            className={classes.deviceWidth}
                          />

                          Edit</MenuItem>

                        <MenuItem onClick={() => handleDeleteModal(props.Data?.id, props.Data.addonName)} >
                          <MdDelete
                            className={classes.deviceWidth}
                            onClick={() => handleDeleteModal(props.Data?.id, props.Data.addonName)} />
                          Delete</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </TableCell>
        {/* <TableCell className={classes.tableCellBody}>
                  <div style={{ display: "flex" }}>
                    <AiFillEdit
                      //onClick={() => handleEditModal(item.id, item)} 
                      onClick={() => history.push(`/addon/edit/${item.id}`)}
                      className={classes.deviceWidth}
                    />
                    <MdDelete className={classes.deviceWidth} onClick={() => handleDeleteModal(item.id)} />
                  </div>
                </TableCell> */}
      </TableRow>

    </>
  )
}

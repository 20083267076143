import React from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { withStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Card, CardContent, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, CircularProgress, Divider, ListItemText, Popper, Button, TextField, FormControlLabel, Radio } from '@material-ui/core';
import ReactSelect from 'react-select';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from 'recharts';
import { addDays, endOfDay, getUnixTime, startOfDay, subDays } from 'date-fns';
import outgoing from '../../../assets/icons/outgoingcalls.png';
import incoming from '../../../assets/icons/incomingcalls.png';
import axiosInstance from '../../../environment/axiosInstance'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sortDown from '../../../assets/images/Sort Down.png'
import { useStyles } from './style';
import './styles.css';
import ReportsComponent from '../../History/Reports/AllReports';


function Reports(props: any) {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = React.useState<any>(false)
  const [AllcallschartDataa, setAllcallschartDataa] = React.useState([])
  const [anchorEll, setAnchorEll] = React.useState<any>(null);
  const [groupUserData, setGroupUserData] = React.useState<any>({});
  const [anchorEldt, setAnchorEldt] = React.useState<any>(null);
  const [startDate, setstartDate] = React.useState<any>()
  const [endDate, setendDate] = React.useState<any>()
  const [selectOffice, setselectOffice] = React.useState<any>('HELLO')
  

  const departmentStateData = useSelector((state: any) => state.departmentModule);
  const { selectedCompany } = departmentStateData;
  const officeId = localStorage.getItem('SelectedCompanyId')
  
  // GRAPH
  const allCallsChartData = () => {
    let url = `/dashboard/new-analytics-graph`
    if (props.location.search) {
      url = `/dashboard/new-analytics-graph${props.location.search}`;
    }
    if (officeId) {
      url = `/dashboard/new-analytics-graph?officeId=${officeId}`
    }
    axiosInstance.get(url).then((res: any) => {
      if (res.status === 200) {
        const data = res.data.data
        setAllcallschartDataa(data)
      }
    })
  }

  //all users data
  const allUsersData = () => {
    axiosInstance.get('dashboard/teams-departments-companies').then((res: any) => {
      if (res.status === 200) {
        setGroupUserData(res.data.Data);
      }
    }).catch((err: any) => {

    })
  }

  const open = Boolean(anchorEll);
  const id = open ? "department-popper" : undefined;

  const opendt = Boolean(anchorEldt);
  const iddt = open ? "date-popper" : undefined;
  React.useEffect(() => {
    // fetchCallbreakdownData()
    // allCallsChartData()
    // fetchAgentsCallData()
  }, [props.location.search])

  React.useEffect(() => {
    allUsersData()
  }, [])

  const displayDates = (dateString: any) => {
    const dateNow = new Date(dateString);
    const year = dateNow.getFullYear();
    const monthWithOffset = dateNow.getMonth() + 1;
    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
    const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
    const materialDateInput = `${year}-${month}-${date}`;
    return materialDateInput
  }

  const [data, setData] = React.useState<any>();

  // call break down
  const fetchCallbreakdownData = async () => {
    let url = `/dashboard/call-count-breakdown`
    if (officeId) {
      url = `/dashboard/call-count-breakdown?officeId=${officeId}`
    }
    if (selectOffice) {
      url += `?companyId=${selectOffice}`
    }
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`
    }
    if (props.location.search) {
      url = `/dashboard/call-count-breakdown${props.location.search}`;
    }
    await axiosInstance.get(url).then((res: any) => {
      if (res.data.data) {
        setAnchorEldt(null)
        setAnchorEll(null)
        setData(res.data.data)
      }

    }).catch((err: any) => {

    })
  }




  //leaderboards
  const [agentsData, setAgentsData] = React.useState<any>([])
  const fetchAgentsCallData = () => {
    setLoading(true)
    let url = `/dashboard/agent-calls`
    if (officeId) {
      url = `/dashboard/agent-calls?officeId=${officeId}`
    }
    if (props.location.search) {
      url = `/dashboard/agent-calls${props.location.search}`;
    }
    axiosInstance.get(url).then((res: any) => {
      if (res.status === 200) {
        setLoading(false)
        setAgentsData(res.data.data)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }

  return (
    <div>
      <Card style={{ marginTop: 10 }} className={classes.card}>
          <ReportsComponent />
      </Card>
    </div>
  )
}

export default Reports
import { Grid, Typography,Button } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ReactSelect from 'react-select'
import axiosInstance from '../../../../environment/axiosInstance'
import { useStyles } from '../style'

function AssignPhoneNumber(props:any) {
    const classes = useStyles()
    const history = useHistory()
    const[usersList,setUsersList] =React.useState([])
    const [assign,setAssign] = React.useState({
           userId:''
    })
    const assignPhoneNumbers = () => {
        axiosInstance.patch(`/admin/phone-numbers/assign-number/${props.id}`,assign).then((res:any)=>{
             if (res.data) {
                // setNumberAssign(true)
                //props.getPhoneNumbers(props.paginate.limit,props.paginate.page)
                props.getPhoneNumbers()
                props.handlePopupModal()
            }
        })
    }

    const changeAssign = (e:any) =>{
        setAssign((prev:any)=>{
            return{
                ...assign,
                userId:e.value
            }
        })
    }

    const getUesr = () => {
        axiosInstance.get('/admin/business-accounts').then((res:any) =>{
            if(res.data){
                const list : any = []
                res.data.data.map((item:any, i:any)=> {
                    var obj = {
                        value : item.id,
                        label : item.firstName + ' ' + item.lastName
                    }
                    list.push(obj)
                })
                setUsersList(list)
            }
        })
    }
     React.useEffect(()=>{
        getUesr()
    },[])
    // console.log('props.phoneNumbersData',props.phoneNumbersData)
    // props.match.params.number,
  return (
    <div>
        <Grid item md={6}>
            <Typography> Number: {props.phoneNumbersData?.phone} </Typography>
            <Typography className={classes.text} style={{marginTop:8}}>Assign To</Typography>
            <ReactSelect
                fullWidth
                options={usersList}
                placeholder="Select User"
                inputProps={{ "aria-label": "Without label" }}
                onChange={changeAssign}
            />
        </Grid>
        <div style={{display:'flex',gap:5,marginTop:10}}>
            <Button 
                size="small" variant="outlined" 
                className={classes.AssignButton}
                onClick={assignPhoneNumbers}
            >
                Assign
            </Button>
            <Button 
                variant="outlined"
                className={classes.CancelAssignButton}
                onClick={props.handlePopupModal}
            >
                Cancel
            </Button> 

        </div>

    </div>
  )
}

export default AssignPhoneNumber
import React from "react";
import {
  CardContent,
  Typography,
  Button,
  Grid,
  Tooltip,
  Switch,
  Select,
} from "@material-ui/core";
import { useStyles } from "../styles";
import ReactSelect from "react-select";
import TextField from "@material-ui/core/TextField";
import ProfileServices from "../ProfileServices/ProfileServices";
import { useDispatch, useSelector } from 'react-redux'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import * as ProfileDataservices from '../../../store/Actions/userActionCreator'



function EmergencyAddress(props: any) {
  const classes = useStyles();
  const [selectedData, setSelectedData] = React.useState<any>(null);
  const [openInput, setOpenInput] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [addData, setAddData] = React.useState<any>({
    postalCode: "",
    city: "",
    province: "",
    address: "",
    country: "",
    zipcode: "",
    streetNumber: "",
    streetName: ""
  });
  const profileStateData = useSelector((state: any) => state.profileData);
  const dispatch = useDispatch()
  const { profileData } = profileStateData
  const address = props.getAddressData.e911 
  // const {apartment,streetName,streetNumber,city,province,zipcode,country}=props.getAddressData.e911
  const onChangeAddress = (e: any) => {
    setSelectedData(e.target.value);
    setAddData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const onSubmit = () => {
    setSubmitted(true);
    const errorData = validate(true);
  }

  // const onSubmitdata = () => {
  //   setSubmitted(true);
  //   const errorData = validate(true);
  //   if (errorData.isValid) {
  //     BusinessServices.postBusinessAccount(data).then((res) => {
  //       if (res.success) {
  //         history.push("/businessAccounts")
  //       }
  //     }).catch((err) => {
  //       console.log(err)
  //     })
  //   }
  // }

  const addEmergencyAddress = (data: any) => {
     setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      ProfileServices.postEmergencyAddressData(data)
        .then((res: any) => {

          if (res.success) {
            props.handlePopupModal();
            dispatch(ProfileDataservices.initUserProfile())
            // props.getAddressData();
          } else {
          }
        })
        .catch((err: any) => {
          // send error
        })
    }
  };



  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then((results) => {
        var myResults = results[0]
        var city = ""
        //var state = ""
        var country = ""
        var postalCode = ""
        var streetNumber = ""
        var streetName = ""
        var province = ""

        getLatLng(myResults).then((latLong) => {
          myResults.address_components.map((item) => {
            if (item.types.includes("locality")) {
              city = item.long_name
            }
            // if (item.types.includes("administrative_area_level_1")) {
            //   state = item.long_name
            // }
            if (item.types.includes("country")) {
              country = item.long_name
            }
            if (item.types.includes("postal_code")) {
              postalCode = item.long_name
            }
            if (item.types.includes("street_number")) {
              streetNumber = item.long_name
            }
            if (item.types.includes("route")) {
              streetName = item.short_name
            }
            if (item.types.includes("administrative_area_level_1")) {
              province = item.short_name
            }

          })
          setAddData(() => {
            return {
              ...addData,
              apartment: myResults.formatted_address,
              city: city,
              country: country,
              zipCode: postalCode,
              province: province,
              // apartment: "",
              streetNumber: streetNumber,
              streetName: streetName,

            }
          });
        })
      })
      .catch(error => console.error('Error', error));
  };


  React.useEffect(() => {
    if (profileData) {
      const { apartment, streetNumber, streetName, city, province, zipCode, country } = profileData.e911[0] ? profileData.e911[0] : "";
      setAddData(profileData.e911.length > 0 ? profileData.e911[0] : "")
    }
  }, [profileData])

  console.log("xbjbshcbjdh", addData)
  const handleChange = (data: any) => {
    setAddData(() => {
      return {
        ...addData,
        apartment: data
      }
    });
  };


  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      country: { ...validData },
      address: { ...validData },
      city: { ...validData },
      status: { ...validData },
      province: { ...validData },
      zipCode: { ...validData },
      streetName:{...validData },
      streetNumber:{...validData },
    };
    let isValid = true;
    if (submitted) {
      
      if (addData.address === " ") {
        retData.address = {
          isValid: false,
          message: "Please enter address space is not allowed"
        };
        isValid = false;
      }
      // if (!addData.address) {
      //   retData.address = {
      //     isValid: false,
      //     message: "Please enter address"
      //   };
      //   isValid = false;
      // }
      if (addData.city === " ") {
        retData.city = {
          isValid: false,
          message: "Please enter city space is not allowed"
        };
        isValid = false;
      }
      if (!addData.city) {
        retData.city = {
          isValid: false,
          message: "Please enter city"
        };
        isValid = false;
      }
      if (addData.province === " ") {
        retData.province = {
          isValid: false,
          message: "Please enter province space is not allowed"
        };
        isValid = false;
      }
      if (!addData.province) {
        retData.province = {
          isValid: false,
          message: "Please enter province"
        };
        isValid = false;
      }
      if (addData.zipCode === " ") {
        retData.zipCode = {
          isValid: false,
          message: "Please enter postal Code space is not allowed"
        };
        isValid = false;
      }
      if (!addData.zipCode) {
        retData.zipCode = {
          isValid: false,
          message: "Please enter postal Code"
        };
        isValid = false;
      }
      if (addData.country === " ") {
        retData.country = {
          isValid: false,
          message: "Please enter country space is not allowed"
        };
        isValid = false;
      }
      if (!addData.country) {
        retData.country = {
          isValid: false,
          message: "Please enter country"
        };
        isValid = false;
      }
      if (addData.streetName === " ") {
        retData.country = {
          isValid: false,
          message: "Please enter streetname space is not allowed"
        };
        isValid = false;
      }
      if (!addData.streetName) {
        retData.streetName = {
          isValid: false,
          message: "Please enter streetname"
        };
        isValid = false;
      }
      if (addData.streetNumber === " ") {
        retData.streetNumber = {
          isValid: false,
          message: "Please enter streetnumber space is not allowed"
        };
        isValid = false;
      }
      if (!addData.streetNumber) {
        retData.streetNumber = {
          isValid: false,
          message: "Please enter streenumber"
        };
        isValid = false;
      }
    }
    return {
      ...retData,
      isValid
    };
  };

  const errors = validate(submitted);


  return (
    <div className="add_user_popup" style={{ width: "500px" }}>


      <Grid container spacing={2} className={classes.textfield}>
        <Grid item md={6}>
          <PlacesAutocomplete
            value={addData.apartment ? addData.apartment : ""}
            onChange={handleChange}
            onSelect={handleSelect}

          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
              <div>
                {/* <input
                      style={{
                      width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                      borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                      border: "1px solid #3f51b5"
                      }}
                      {...getInputProps({
                      placeholder: 'Search address',
                      className: 'location-search-input',
                      })}
                  /> */}
                <TextField
                  //style={{ marginTop: '12px' }}
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={!errors.address.isValid}
                  {...getInputProps({
                    placeholder: 'Search address',
                    className: 'location-search-input',
                  })}
                />
                {
                  !errors.address.isValid &&
                  <p className={classes.errorMessage}>{errors.address.message}</p>
                }
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion: any) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {/* <TextField
            placeholder="Apartment No."
            name="apartment"
            variant="outlined"
            fullWidth
            value={addData.apartment}
            size="small"
            onChange={onChangeAddress}
          /> */}

          <TextField
            className={classes.textfield}
            placeholder="Street Name"
            name="streetName"
            variant="outlined"
            fullWidth
            value={addData.streetName}
            size="small"
            onChange={onChangeAddress}
             error={!errors.streetName.isValid}
          />
           {
            !errors.streetName.isValid &&
            <p className={classes.errorMessage}>{errors.streetName.message}</p>
          }

          <TextField
            className={classes.textfield}
            placeholder="Province"
            name="province"
            variant="outlined"
            value={addData.province}
            fullWidth
            size="small"
            onChange={onChangeAddress}
            error={!errors.province.isValid}
          />
           {
            !errors.province.isValid &&
            <p className={classes.errorMessage}>{errors.province.message}</p>
          }

          <TextField
            className={classes.textfield}
            placeholder="Select Country"
            name="country"
            variant="outlined"
            value={addData.country}
            fullWidth
            size="small"
            onChange={onChangeAddress}
           error={!errors.country.isValid}
          />
           {
            !errors.country.isValid &&
            <p className={classes.errorMessage}>{errors.country.message}</p>
          }
  
          </Grid>
        <Grid item md={6}>
          <TextField
            placeholder="Street No."
            name="streetNumber"
            variant="outlined"
            value={addData.streetNumber}
            fullWidth
            size="small"
            onChange={onChangeAddress}
            error={!errors.streetNumber.isValid}
          />
           {
            !errors.streetNumber.isValid &&
            <p className={classes.errorMessage}>{errors.streetNumber.message}</p>
          }
          <TextField
            className={classes.textfield}
            placeholder="Select city"
            name="city"
            variant="outlined"
            value={addData.city}
            fullWidth
            size="small"
            onChange={onChangeAddress}
           error={!errors.city.isValid}
          />
           {
            !errors.city.isValid &&
            <p className={classes.errorMessage}>{errors.city.message}</p>
          }

          <TextField
            className={classes.textfield}
            placeholder="ZipCode"
            name="zipCode"
            value={addData.zipCode}
            variant="outlined"
            fullWidth
            size="small"
            onChange={onChangeAddress}
           error={!errors.zipCode.isValid}
          />
           {
            !errors.zipCode.isValid &&
            <p className={classes.errorMessage}>{errors.zipCode.message}</p>
          }
        </Grid>

      </Grid>






      <Grid container spacing={2}>
        <Grid item md={5}></Grid>
        <Grid item md={4}>
          <Button
            onClick={() => addEmergencyAddress(addData)}
            className={classes.submitbutton}
            variant="outlined"
            size="small"
          >
            Submit
          </Button>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
    </div>
  );
}
export default EmergencyAddress;

import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./style";

import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Select from 'react-select';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
function PersonalInf(props: any) {
    const classes: any = useStyles();
    const [shouldopen, setShouldopen] = React.useState<any>(true);
    const formGroupData: any = props.formGroupData
    const [personalForm, setPersonalForm] = React.useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        planId: '',
        address: '',
        companyName: "",
        contactName: "",
        gstNumber: "",
        city: '',
        province: '',
        country: "",
        zipcode: "",
        status: 1,
        users:''
    })
    const onChangeEvents = () => {
        setShouldopen(!shouldopen);
    };

    return (
        <>
            <Accordion className={classes.cardstyles} expanded={shouldopen} onChange={onChangeEvents}>
            <AccordionSummary
                expandIcon={shouldopen ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.security}>
                    Personal Information
                </Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails>
                <Grid container spacing={6} style={{ marginTop: -18, }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.names}>First Name</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="firstName"
                                    fullWidth
                                    value={props.getDataById.firstName}
                                    placeholder="Enter your first name"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.firstName.isValid}
                                />
                                {
                                    !props.errors.firstName.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.firstName.message}</p>
                                }
                            </Grid>
                        </Grid>
                        
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.names}>Company Name</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="companyName"
                                    fullWidth
                                    value={props.getDataById.companyName}
                                    placeholder="Enter your company name"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.companyName.isValid}
                                />
                                {
                                    !props.errors.companyName.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.companyName.message}</p>
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.names}>Contact Name</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="contactName"
                                    fullWidth
                                    value={props.getDataById.contactName}
                                    placeholder="Enter Contact name"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.contactName.isValid}
                                />
                                {
                                    !props.errors.contactName.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.contactName.message}</p>
                                }
                            </Grid>
                        </Grid>
                       
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>Address</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <PlacesAutocomplete
                                    value={props.getDataById.address ? props.getDataById.address : ""}
                                    onChange={props.handleChange}
                                    onSelect={props.handleSelect}

                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
                                        <div>
                                            {/* <input
                                            style={{
                                            width: "95%", padding: 10, color: '#8392AB', position: 'relative',
                                            borderRadius: ' 4px', borderColor: 'rgba(0, 0, 0, 0.23)', fontFamily: "sans-serif",
                                            border: "1px solid #3f51b5"
                                            }}
                                            {...getInputProps({
                                            placeholder: 'Search address',
                                            className: 'location-search-input',
                                            })}
                                        /> */}
                                            <TextField
                                                style={{ marginTop: '12px' }}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                {...getInputProps({
                                                    placeholder: 'Search address',
                                                    className: 'location-search-input',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion: any) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div style={{ display: "flex", border: "1px solid #d4d5d9", padding: 14 }} >
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                {
                                    !props.errors.address.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.address.message}</p>
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>Provience/State</Typography>

                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="province"
                                    fullWidth
                                    value={props.getDataById.province}
                                    placeholder="Enter your provience or city"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.province.isValid}
                                />
                                {
                                    !props.errors.province.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.province.message}</p>
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>Zip Code</Typography>

                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="zipcode"
                                    fullWidth
                                    value={props.getDataById.zipcode}
                                    placeholder="Enter your postal code"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.zipcode.isValid}
                                />
                                {
                                    !props.errors.zipcode.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.zipcode.message}</p>
                                }
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    {/* end first col-6 */}
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.names}>Last Name</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="lastName"
                                    fullWidth
                                    value={props.getDataById.lastName}
                                    placeholder="Enter your last name"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.lastName.isValid}
                                />
                                {
                                    !props.errors.lastName.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.lastName.message}</p>
                                }
                            </Grid>
                        </Grid>
                         <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.names}>GST Number(optional)</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="gstNumber"
                                    fullWidth
                                    value={props.getDataById.gstNumber}
                                    placeholder="Enter GST Number"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    // error={!props.errors.contactName.isValid}
                                />
                                {/* {
                                    !props.errors.contactName.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.contactName.message}</p>
                                } */}
                            </Grid>
                        </Grid>
                        {/* <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>Plan  </Typography>

                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <Select
                                    placeholder="Select an option"
                                    options={props.PlanDropwdown}
                                    name="planId"
                                    value={
                                        props.PlanDropwdown.filter((option: any) =>
                                          option.value === props.selPlan)
                                      }
                                    //value={props.selPlan}
                                    onChange={(e: any) => props.handleChangeblocks(e, "planId")}
                                    className={classes.formHeading1} />

                                {
                                    !props.errors.planId.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.planId.message}</p>
                                }
                            </Grid>
                        </Grid> */}
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                {/* <Typography className={classes.names}>Contact Name</Typography> */}
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                {/* <TextField
                                    size="small"
                                    variant="outlined"
                                    name="contactName"
                                    fullWidth
                                    value={props.getDataById.contactName}
                                    placeholder="Enter Contact name"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.contactName.isValid}
                                />
                                {
                                    !props.errors.contactName.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.contactName.message}</p>
                                } */}
                            </Grid>
                        </Grid>
                        <Grid container className={classes.inputFields} style={{marginTop:70}}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>City</Typography>

                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="city"
                                    fullWidth
                                    value={props.getDataById.city}
                                    placeholder="Enter your city"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.city.isValid}
                                />
                                {
                                    !props.errors.city.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.city.message}</p>
                                }
                            </Grid>
                        </Grid>
                         <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>Country</Typography>

                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="country"
                                    fullWidth
                                    value={props.getDataById.country}
                                    placeholder="Enter your country"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.country.isValid}
                                />
                                {
                                    !props.errors.country.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.country.message}</p>
                                }
                            </Grid>
                        </Grid>
                        
                       
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.password}>status</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <Select placeholder="Enable"
                                    options={props.Status}
                                    name="status"
                                    value={props.selVal}
                                    onChange={(e: any) => props.handleChangeblocks(e, "status")}
                                    className={classes.formHeading1} />

                                {
                                    !props.errors.status.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.status.message}</p>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* end last col-6 */}
                </Grid>
            </AccordionDetails>
        </Accordion>
        <Accordion className={classes.cardstyles} expanded={shouldopen} onChange={onChangeEvents}>
            <AccordionSummary
                expandIcon={shouldopen ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.security}>
                    Billing 
                </Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails>
                <Grid container spacing={6} style={{ marginTop: -18, }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className={classes.inputFields}>
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography className={classes.names}>Total Numbers</Typography>
                            </Grid>
                            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    name="users"
                                    fullWidth
                                    value={props.getDataById.users}
                                    placeholder="Enter Number count"
                                    className={classes.textField}
                                    onChange={props.onContentChangeEvent}
                                    error={!props.errors.users.isValid}
                                />
                                {
                                    !props.errors.users.isValid &&
                                    <p className={classes.errorMessage}>{props.errors.users.message}</p>
                                }
                            </Grid>
                        </Grid>
                        
                        
                        
                    </Grid>
                    {/* end first col-6 */}
                   
                    {/* end last col-6 */}
                </Grid>
            </AccordionDetails>
        </Accordion>
        </>
        
    );
}

export default PersonalInf;

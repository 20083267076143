import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class DepartmentServices {

    getDepartments = async () => {
        try {
            const response = await axiosInstance.get(`/departments`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    getDepartmentsData = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${id}/schedule`);;
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }

    postDepartments = async (sendData: any) => {
        try {
            const response = await axiosInstance.post("/departments/", sendData);

            if (response.data) {
                const data = response.data;

                toast.success("Department added successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    postNumber = async (sendData: any) => {
        try {
            const response = await axiosInstance.post("/phoneNumbers/forward-number", sendData);

            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    unassignNumbers = async (phoneId: any, NumberData: any) => {
        try {
            const response = await axiosInstance.patch(`/phoneNumbers/unassign-phonenumber/${phoneId}`, NumberData);

            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    postClosedNumber = async (deptId: any, sendData: any) => {
        try {
            const response = await axiosInstance.post(`/departments/${deptId}/close-hour-add-Number`, sendData);

            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    postUnansweredNumber = async (deptId: any, sendData: any) => {
        try {
            const response = await axiosInstance.post(`/departments/${deptId}/unanswered-call-add-Number`, sendData);

            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    updateDepartmentsData = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/departments/${id}/schedule`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                // console.log("----updated switch", response.data)
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    updateSlotsData = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(`/departments/${id}/scheduleSlots`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                // console.log("----updated switch", response.data)
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };


    deleteDepartmentsData = async (id: any) => {
        try {
            const response = await axiosInstance.delete(`/departments/${id}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    uploadFileData = async (data: any) => {
        try {
            const response = await axiosInstance.post("/files", data);
            if (response.data) {
                const data = response.data;
                toast.success("Uploaded successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    patchFileData = async (id: any, fileData: any) => {
        try {
            const response = await axiosInstance.patch(`/files/${id}`, fileData);
            if (response.data) {
                const data = response.data;
                toast.success("Updated file successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }

    }

    deleteFileData = async (id: any) => {
        try {
            const response = await axiosInstance.delete(`/files/${id}`);
            if (response.data) {
                const data = response.data;
                toast.success("Deleted file successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }

    }

    getDepartmentHolidaysList = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${id}/holidays`);
            if (response.data) {
                const data = response.data;

                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getOpenHours = async (deptId: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${deptId}/open-hours`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getCloseHours = async (deptId: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${deptId}/close-hours`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getUnansweredCallsHours = async (deptId: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${deptId}/unanswered-call`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateOpenHours = async (deptId: any, updatedData: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${deptId}/open-hours`,
                updatedData
            );
            // console.log("-----departmentservices data", deptId, updatedData)
            if (response.data.data) {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    updateClosedHours = async (deptId: any, updatedData: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${deptId}/close-hours`,
                updatedData
            );
            if (response.data.data) {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    updateUnAnsweredCalls = async (deptId: any, updatedData: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${deptId}/unanswered-call`,
                updatedData
            );

            if (response.data.data) {
                toast.success(response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    postDepartmentHolidaysList = async (id: any, sendData: any) => {
        try {
            const response = await axiosInstance.post(`/departments/${id}/holidays`, sendData);
            if (response.data) {
                const data = response.data;
                toast.success("Holiday added successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateDepartmentHolidaysList = async (departmentid: any, id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${departmentid}/holidays/${id}`,
                data
            );
            if (response.data) {
                const data = response.data;
                toast.success("Updated holiday successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    deleteDepartmentHolidaysList = async (departmentid: any, id: any) => {
        try {
            const response = await axiosInstance.delete(`/departments/${departmentid}/holidays/${id}`);
            if (response.data) {
                const data = response.data;
                toast.success("Deleted holiday successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error("Error to delete", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getDepartmentWorkingList = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${id}/working-days`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }



    }
    postDepartmentWorkingList = async (id: any, sendData: any) => {
        try {
            const response = await axiosInstance.post(`/departments/${id}/working-days`, sendData);

            if (response.data.docs) {
                toast.success("Posting Working Day successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                const data = response.data.docs;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateDepartmentWorkingList = async (departmentid: any, id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${departmentid}/working-days/${id}`,
                data
            );
            if (response.data.data) {
                toast.success("updated workingday successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    deleteDepartmentWorkingList = async (departmentid: any, id: any) => {
        try {
            const response = await axiosInstance.delete(`/departments/${departmentid}/working-days/${id}`);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getDepartmentNumberList = async (id: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${id}/phone-number`);

            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    postDepartmentNumberList = async (id: any, data: any) => {
        try {
            const response = await axiosInstance.post(`/phoneNumbers`, data);
            if (response.data) {
                const data = response.data;
                toast.success("Added Number successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateDepartmentNumberList = async (departmentid: any, data: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${departmentid}/change-primary-status`,
                data
            );
            if (response.data) {
                const data = response.data;
                toast.success("Updated Number successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error to update Number status", { position: toast.POSITION.TOP_RIGHT })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    deleteDepartmentNumberList = async (departmentid: any, id: any) => {
        try {
            const response = await axiosInstance.delete(`/departments/${departmentid}/phone-number/${id}`);
            if (response.data) {
                const data = response.data;
                toast.success("Deleted Number successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getDepartmentBlockedNumber = async (departmentid: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${departmentid}/blocked-numbers`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    postDepartmentBlockedNumber = async (departmentid: any, sendData: any) => {
        try {
            const response = await axiosInstance.post(`/departments/${departmentid}/advance-setting/block-numbers`, sendData);

            if (response.data) {
                const data = response.data;
                toast.success("Added blocked number successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateDepartmentBlockedNumber = async (departmentid: any, id: any, data: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${departmentid}/advance-setting/block-numbers?id=${id}`,
                data
            );
            if (response.data) {
                const data = response.data;
                toast.success("Updated blocked number successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateAdvancedSettingsCheckbox = async (departmentid: any, data: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${departmentid}/advance-setting`,
                data
            );
            if (response.data) {
                const data = response.data;
                toast.success("Updated  successfully", {
                    position: toast.POSITION.TOP_RIGHT, style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    updateAdvancedSettings = async (departmentid: any, data: any) => {
        try {
            const response = await axiosInstance.patch(
                `/departments/${departmentid}`,
                data
            );
            if (response.data) {
                const data = response.data;
                toast.success("Updated  successfully", {
                    position: toast.POSITION.TOP_RIGHT, style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    deleteDepartmentBlockedNumber = async (departmentid: any, id: any) => {
        try {
            const response = await axiosInstance.delete(`/departments/${departmentid}/advance-setting/block-numbers?id=${id}`);

            if (response.data) {
                const data = response.data;
                toast.success("Deleted blocked number successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    fetchVanityNumbers = async (data: any) => {
        try {
            const response = await axiosInstance.get(
                `/teli/local-numbers?state=${data.stateValue}&npa=${data.npa}&search=${data.number}&searchType=${data.searchType}`
            );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getCallHistoryList = async (depId: any, page: any, limit: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${depId}/call-history/?limit=${limit}&page=${page}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getCallHistorySearchList = async (depId: any, limit: any, startdate: any, endDate: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${depId}/call-history/?limit=${limit}&startDate=${startdate}&endDate=${endDate}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    postCallHistoryList = async (sendData: any) => {
        try {
            const response = await axiosInstance.post("/call-history", sendData);

            if (response.data.docs) {
                const data = response.data.docs;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error("Error", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    fetchStates = async (value: any) => {
        try {
            const response = await axiosInstance.get("/teli/states?country=" + value);
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    fetchAreas = async (id: any) => {
        try {
            const response = await axiosInstance.get(
                "/teli/area-codes?state=" + id
            );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    fetchCities = async (id: any) => {
        try {
            const response = await axiosInstance.get(
                "/teli/rate-centers?state=" + id
            );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };

    fetchLocalNumbers = async (data: any) => {
        const SearchType = data.searchType

        try {
            var response
            if (SearchType === "1") {
                response = await axiosInstance.get(
                    `/teli/local-numbers?state=${data.stateValue}&ratecenter=${data.cityValue}&npa=${data.npa}&searchType=${data.searchType}`
                );
            }
            else {
                response = await axiosInstance.get(
                    `/teli/local-numbers?state=${data.stateValue}&npa=${data.npa}&searchType=${data.searchType}`
                );
            }
            // try {
            //     const response = await axiosInstance.get(
            //         `/teli/local-numbers?state=${data.stateValue}&ratecenter=${data.cityValue}&npa=${data.npa}`
            //     );
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };
    getUusersList = async () => {
        try {
            const response = await axiosInstance.get("/users");
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getUusersListBydeptId = async (deptid: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${deptid}/assigned-operators`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    PostBulkusersList = async (data: any) => {
        try {
            const response = await axiosInstance.post("/users/get-bulk-user", data);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    CreateUserList = async (data: any) => {
        try {
            const response = await axiosInstance.post("/users", data);
            if (response.data.data) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    postUserDepartment = async (userId: any, data: any) => {
        try {
            const response = await axiosInstance.post(`/users/${userId}/add-department`, data);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid green"
                }, autoClose: 1000
            })
            if (response.data.data) {
                const data = response.data.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    postBackUpAgents = async (deptId: any, data: any) => {
        try {
            const response = await axiosInstance.post(`/departments/${deptId}/backup-agents`, data);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid green"
                }, autoClose: 1000
            })
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: null };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    patchUserDepartmentpermissions = async (userId: any, departmentId: any, userData: any) => {
        try {
            const response = await axiosInstance.patch(`/users/update-permissions/${departmentId}/${userId}`, userData);

            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getDepartmentBackUpAgents = async (departmentid: any) => {
        try {
            const response = await axiosInstance.get(`/departments/${departmentid}/backup-agents`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    DeleteBackupAgents = async (departmentid: any, data: any) => {
        try {
            const response = await axiosInstance.delete(`/departments/${departmentid}/backup-agents`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    DeleteDepartmentAgents = async (userId: any, data: any) => {
        console.log("-----data", data)
        try {
            const response = await axiosInstance.delete(`/users/${userId}/delete-deparatment-user`, data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid green"
                    }, autoClose: 1000
                })
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }


}
const departments = new DepartmentServices()
export default departments

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    padding: {
      padding: 15,
    },
    modalCard: {
      margin: '10px 0px 10px 0px',
      borderRadius: 10,
      background: '#FFFFFF',
      boxShadow: '0 0 0.5em #E2E2E2',
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
      width: '-webkit-fill-available',
      justifyContent: 'flex-start',
    },
    asigngridItem: {
      //display: 'flex',
      alignItems: 'center',
      width: '-webkit-fill-available',
      justifyContent: 'flex-start',
    },
    heading: {
      color: "#2645ad",
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 0.208955,
      textTransform: 'none',
    },
    accordianNoFlex: {
      display: 'block'
    },
    assignedNum: {
      marginTop: 24,
      color: '#8392AB',
      fontFamily: 'Work Sans',
      fontWeight: 500,
      fontSize: 18,
    },
    bFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    availableButton: {
      fontSize: 16,
      textTransform: 'capitalize',
      fontFamily: 'work sans',
      padding: '-1px 20px',
    },
    pText: {
      textAlign: 'center', color: "#787878", fontWeight: 500, padding: '10px 2px'
    },
    text: {
      marginBottom: 10,
      color: '#464646',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    deleteButton: {
      margin: 10,
      padding: '6px 22px',
      background: '#4D72F8',
      border: '1px solid #AEAEAE',
      boxSizing: 'border-box',
      borderRadius: 5,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#FFFFFF',
      fontSize: 14,
      '&:hover': {
        background: '#4D72F8',
        color: '#FFFFFF',
      }
    },
    cancelButton: {
      margin: 10,
      padding: '6px 22px',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      marginLeft: 15,
      background: '#EFEFEF',
      border: '1px solid #AEAEAE',
      boxSizing: 'border-box',
      borderRadius: 5,
      color: '#787878',
      fontSize: 14,
    },
    AssignButton: {
      margin: 10,
      background: '#4D72F8',
      border: '1px solid #AEAEAE',
      boxSizing: 'border-box',
      borderRadius: 4,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      padding: '6px 22px',
      color: '#FFFFFF',
      fontSize: 14,
      '&:hover': {
        background: '#4D72F8',
        color: '#FFFFFF',
      }
    },
    CancelAssignButton: {
      padding: '6px 22px',
      margin: 10,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      marginLeft: 15,
      background: '#EFEFEF',
      border: '1px solid #AEAEAE',
      boxSizing: 'border-box',
      borderRadius: 4,
      color: '#787878',
      fontSize: 14,
    },
    card: {
      margin: '15px 0px 0px 0px',
      borderRadius: 10,
      background: '#FFFFFF',
      boxShadow: '0 0 0.5em #E2E2E2',
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    Searchbutton: {
      height: 42,
      borderRadius: '0px 8px 8px 0px',
      transform: 'translate(-16px, 0px)',
      color: '#FFFFFF',
      backgroundColor: '#4D72F8',
      borderColor: '#4D72F8',
      '&:hover': {
        backgroundColor: '#4D72F8',
      },
      '&:active': {
        backgroundColor: '#4D72F8',
      },
    },
    search: {
      position: 'absolute',
      top: 10,
      left: 20,
    },
    divider: {
      marginTop: -14,
      backgroundColor: '#E2E2E2',
    },
    tableContainer: {
      width: '100%',
      boxShadow: '0 0 0.5em #E2E2E2',
      borderRadius: 10,
      borderColor: 'transparent',
    },
    tableCellBody: {
      padding: '15px 5px',
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: 0.21553,
      textAlign: 'center',
      color: '#939292',
    },
    subText: {
      fontSize: 12,
      color: '#6D6D6D',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    userIcon1: {
      width: 25,
      height: 25,
      color: '#464646',
    },
    assignButton: {
      textTransform: 'capitalize',
      fontSize: 12,
      color: '#4D72F8',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      background: '#fbfbfb',
      border: '1px solid #ebebeb',
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '6px 22px',

    }
  }))
import React from 'react'
import { useStyles } from './style'
import profileImg from '../../assets/images/profileImg.png'
import { Badge, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory, useParams } from 'react-router-dom';
import axiosInstance from '../../environment/axiosInstance'
import './style.css'
import { FaDotCircle } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go'


function ConverSation(props: any) {
  const classes = useStyles()
  const { list, coversationType, userId, coversationList, createNewChat } = props
  const params: any = useParams()
  const history = useHistory()
  

  return (
    <div id="scrollbarChat" style={{ height: '70vh', overflowY: 'auto' }}>
      {
        list?.map((item: any) => {
          // console.log('item', item);
          return (
            <div
              onClick={() => {
                if (coversationType === 'SEARCH') {
                  let findExistingConversation = coversationList.find((lisItm: any) => lisItm.secondId?.toString() === item.efoneId?.toString())
                  // console.log('findExistingConversation', findExistingConversation)
                  if (findExistingConversation !== undefined) {
                    history.push(`/messages/${findExistingConversation._id}`)
                  } else if (findExistingConversation == undefined) {
                    //create room
                    axiosInstance.get(`/chat/conversation?id1=${userId}&id2=${item.efoneId}`).then((res: any) => {
                      createNewChat(res.data.conversation)

                    })
                  }
                  // alert('create room')
                } else {
                  props.joinRoom(item._id)
                }
              }}
            >
              <>
                <div style={{ marginRight: 8 }} className={classes.leftDiv + ` ${item?._id?.toString() === params.id?.toString() && 'current_chat_active'}`}>

                  <AccountCircleIcon style={{ fontSize: 30 }} />
                  {/* <Typography><GoPrimitiveDot style={{ color: `${item?.status ? 'green' : 'red'}`, marginTop: -15, marginLeft: -20 }} /></Typography> */}
                  {
                    item?.isOnline && <Typography><GoPrimitiveDot style={{ color: 'green', marginTop: 5, marginLeft: -18 }} /></Typography>
                  }
                  <div>
                    <Typography>{item?.contactNumber}</Typography>
                    <Typography>
                      {
                        coversationType === 'SEARCH' ? (
                          <p>{item?.firstName}</p>
                        ) : (
                          item.isGroup === true ?
                            <p>{item.groupName}</p>
                            :
                            item.secondId === props.userId ? <p>{item?.firstUserName}</p> : <p>{item?.secondUserName}</p>

                        )
                      }
                    </Typography>
                    <Typography>{item.lastMessage?.length > 20 ? item.lastMessage.substring(0, 20) + "..." : item.lastMessage} </Typography>

                  </div>
                </div>
              </>
            </div>

          )
        })
      }
    </div>
  )
}

export default ConverSation
import React from 'react'
import { Button, CircularProgress, ClickAwayListener, Divider, Grid, Grow, ListItemText, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { MoreHorizRounded } from '@material-ui/icons';
import { useStyles } from './styles';
import moment from 'moment';


function OfficesTableBody(props: any) {
    const classes = useStyles()
    const anchorRef: any = React.useRef<any>(null);

    const [openOptions, setOpenOptions] = React.useState<any>(false);

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenOptions(false);
    };
    const handleToggle = () => {
        setOpenOptions((prevOpen: any) => !prevOpen);
    };
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenOptions(false);
        }
    }

    var data = parseInt(props.data.createdDate)
    var dataa = moment(data).format('DD MMM YYYY')

    return (
        <TableRow key={props.key}>
            <TableCell>{props.data.label}</TableCell>
            <TableCell>
                {dataa}
            </TableCell>
            <TableCell>
                {props.data.deptCount}
            </TableCell>
            <TableCell>
                <Button
                    ref={anchorRef}
                    aria-controls={openOptions ? "menu-list-grow" : undefined}
                    onClick={handleToggle}>
                    <MoreHorizRounded />
                </Button>
                <Popper
                    open={openOptions}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "" : "",
                            }}>
                            <Paper style={{ borderRadius: "0px 10px 10px 10px", background: '#F3F5FF' }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={openOptions}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem>
                                            <ListItemText className={classes.textColor}
                                                onClick={() => props.handleDeleteOption(props.data?.value)}>Delete</ListItemText>
                                        </MenuItem>

                                    </MenuList>

                                </ClickAwayListener>
                            </Paper>
                        </Grow>

                    )}

                </Popper>



            </TableCell>
        </TableRow>
    )
}

export default OfficesTableBody
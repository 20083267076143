import axios from 'axios'

import localStorageContent from '../services/localstorage/localstorage';
import * as authActionCreators from '../store/Actions/AuthActionCreator';

const getToken = () => {
    const localData = localStorageContent.getLocalUserData()
    const dataa: any = localStorage.getItem("user_data")
    const authState = dataa?.authenticated

    if (localData === undefined) {
        return ''
    } else if (localData.tokenData?.access?.token === null) {
        return ''
    }
    else if (localData?.authenticated === false) {
        return localData.tokenData?.refresh?.token
    }
    else {
        return localData.tokenData?.access?.token
    }
}

const URL = axios.create({
    baseURL:process.env.REACT_APP_API_URL,
})

URL.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': `Bearer ${getToken()}`
        }
        return config
    },
    error => {

        Promise.reject(error)
    }
)

URL.interceptors.response.use(
    (response) => { 
      // Do something with response data
      if(response.status === 401) {
       
        authActionCreators.initRefreshToken()
      } else if(response.status === 402) {
        authActionCreators.initAuthLogout()
        window.location.href = '/';
      }
      return response;
    },
    (error) => { 
      // Do something with response error
      if(error.response.status === 401) {
       
        authActionCreators.initRefreshToken()
      } else if(error.response.status === 402) {
        authActionCreators.initAuthLogout()
        window.location.href = '/';
      }
      return Promise.reject(error);
    });

export default URL
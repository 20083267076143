import React from 'react'
import {
    Grid, Button, Typography, Divider,
    Table, TableHead, TableCell, TableRow,
    Card,
    TextField,
    TableBody,
    CardContent,
    TableContainer,
} from '@material-ui/core';
import { TableHeaderComponent } from '../../../components/Table/Table';
import { useStyles } from './styles';
import TableConatainer from './TableBody'
import { useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function BusinessTable(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const tableHeader = ['ClientId', 'Name', 'Email', 'Package', 'Account Status', 'Status'];


    return (
        <div>
            <div className={classes.dflex} >
                <Typography className={classes.accountHeading}>
                    Total Records:{props.paginate.totalDocs}
                </Typography>
                <div>
                    <Button
                        className={classes.addbutton}
                        variant="outlined"
                        size="small"
                        onClick={() => history.push('/addbusinessAccounts')}
                    >
                        Add New
                    </Button>
                </div>
            </div>
            <Divider className={classes.divider} style={{ marginTop: "10px" }} />

            <Card  style={{ marginTop: '20px' }}>
                <TableContainer>
                <Table >
                    <TableHead className={classes.tablecolor}>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                Name
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Email
                            </TableCell>
                            {/* <TableCell className={classes.tableHead}>
                                Plan
                            </TableCell> */}
                            <TableCell className={classes.tableHead}>
                                Created Date
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Account Status
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Action
                            </TableCell>

                        </TableRow>
                    </TableHead>
                     <TableBody>
                        {props.loading ?
                            <Box>
                                <div style={{ width: "100%",marginLeft: '400px', textAlign: "center" }}>
                                    <CircularProgress />
                                </div>
                            </Box>
                            :  
                            props.data.length > 0 && (
                                props.data?.map((Data: any, i: any) => <TableConatainer
                                    fetchBusinessUsers={props.fetchBusinessUsers}
                                    PlanData={Data}
                                    paginate={props.paginate}
                                    loading={props.loading}
                                    key={i}
                                />
                                )
                            )
                        }
                    </TableBody>
                </Table>
                {props.data.length <= 0 && !props.loading &&
                    <p style={{ textAlign: "center", fontSize: 18, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                }
                 </TableContainer>
                 
                
            </Card>
            {props.data.length <= 0 ? "" :
                <Stack spacing={2} style={{ marginTop: 15, float: "right", marginBottom: 15 }}>
                    <Pagination count={props.paginate.totalPages} page={props.paginate.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />
                </Stack>}

            {/* <CardContent>
                <TableConatainer
                    paginate={props.paginate}
                    loading={props.loading}
                    fetchBusinessUsers={props.fetchBusinessUsers}
                    data={props.data} />

            </CardContent> */}
        </div>
    )
}



import React from 'react'
import {
    Card,
    CardContent,
    Divider,
    TextField,
    Typography,
    Accordion,
    AccordionSummary, AccordionDetails,
    Grid,
} from '@material-ui/core';
import { useStyles } from './styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import SearchFilter from './SearchFilter';
import Table from './Table';
import BusinessServices from "./BusinessAccountsServices/services"
import axiosInstance from '../../../environment/axiosInstance'
function Index() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [search, setsearch] = React.useState(false);
    const [tableData, setTableData] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10,
        totalPages: 0
    })
    const [searchData, setSearchData] = React.useState<any>({
        id: '',
        // firstName: '',
        // lastName: '',
        // email: '',
        searchValue: '',
        statusKey: '',
        statusValue: ''
    })
    // const [searchData, setSearchData] = React.useState<any>({
    //     firstNameKey: '',
    //     firstNameValue: '',
    //     lastNameKey: '',
    //     lastNameValue: '',
    //     emailKey: '',
    //     emailValue: '',
    //     planIdKey: '',
    //     planIdValue: '',
    //     statusKey: '',
    //     statusValue: '',

    // })

    const [searching, setSearching] = React.useState<any>(false)
    const onChangeSearchData = (name: any, value: any) => {
        if (name === "status") {
            setSearchData((prev: any) => {
                return {
                    ...prev,
                    statusKey: name,
                    statusValue: value
                }
            })
        }
        setSearchData((prev: any) => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }

    // const onChangeSearchData = (name: any, value: any) => {
    //     if (name === "firstName") {
    //         if (value === "") {
    //             setSearchData((prev: any) => {
    //                 return {
    //                     ...prev,
    //                     firstNameKey: "",
    //                     firstNameValue: ""
    //                 }
    //             })
    //         } else
    //             setSearchData((prev: any) => {
    //                 return {
    //                     ...prev,
    //                     firstNameKey: name,
    //                     firstNameValue: value
    //                 }
    //             })


    //     }
    //     else if (name === "lastName") {
    //         if (value === "") {
    //             setSearchData((prev: any) => {
    //                 return {
    //                     ...prev,
    //                     lastNameKey: "",
    //                     lastNameValue: ""
    //                 }
    //             })
    //         } else
    //             setSearchData((prev: any) => {
    //                 return {
    //                     ...prev,
    //                     lastNameKey: name,
    //                     lastNameValue: value
    //                 }
    //             })
    //     }
    //     else if (name === "email") {
    //         if (value === "") {
    //             setSearchData((prev: any) => {
    //                 return {
    //                     ...prev,
    //                     emailKey: "",
    //                     emailValue: ""
    //                 }
    //             })
    //         } else
    //             setSearchData((prev: any) => {
    //                 return {
    //                     ...prev,
    //                     emailKey: name,
    //                     emailValue: value
    //                 }
    //             })
    //     }
    //     else if (name === "planId") {
    //         setSearchData((prev: any) => {
    //             return {
    //                 ...prev,
    //                 planIdKey: name,
    //                 planIdValue: value
    //             }
    //         })
    //     }
    //     else if (name === "status") {
    //         setSearchData((prev: any) => {
    //             return {
    //                 ...prev,
    //                 statusKey: name,
    //                 statusValue: value
    //             }
    //         })
    //     }

    // }
    const handleOpen = () => {
        setOpen(!open)
    }

    React.useEffect(() => {
        if (searchData.id !== '' || searchData.firstName !== '' || searchData.lastName !== '' || searchData.email !== '' || searchData.searchValue !== '' || searchData.statusKey !== '') {
            setSearching(true)
        } else {
            setSearching(false)
        }
    }, [searchData])

    const clearFilter = () => {
        fetchBusinessUsers(paginate.limit, 1)
        setSearchData((prev: any) => {
            return {
                ...prev,
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                status: '',
                searchValue: '',
                statusKey: '',
                statusValue: '',

            }
        })
        setSearching(false)
    }


    const searchedData = (limit: any, page: any) => {
        if (searchData.id === 'firstName') {
            setLoading(true)
            axiosInstance.get(`/admin/business-accounts?limit=${limit}&page=${page}&firstName=${searchData.searchValue}&${searchData.statusKey}=${searchData.statusValue}`).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setTableData(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })
        } else if (searchData.id === 'lastName') {
            setLoading(true)
            axiosInstance.get(`/admin/business-accounts?limit=${limit}&page=${page}&lastName=${searchData.searchValue}&${searchData.statusKey}=${searchData.statusValue}`).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setTableData(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })

        } else if (searchData.id === 'email') {
            setLoading(true)
            axiosInstance.get(`/admin/business-accounts?limit=${limit}&page=${page}&email=${searchData.searchValue}&${searchData.statusKey}=${searchData.statusValue}`).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setTableData(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })

        } else if (searchData.statusKey !== '') {
            setLoading(true)
            axiosInstance.get(`/admin/business-accounts?limit=${limit}&page=${page}&${searchData.statusKey}=${searchData.statusValue}`).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setTableData(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })

        }
        else {
            fetchBusinessUsers(paginate.limit, paginate.page)
        }

    }


    const fetchBusinessUsers = (limit: any, page: any) => {
        setLoading(true)
        BusinessServices.getBusinessUsers(limit, page).then((res) => {
            if (res.data) {
                setLoading(false)
                setTableData(res.data.data)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit,
                        totalPages: res.data.totalPages
                    }
                })
                setSearching(false)

            }
            else {
                setSearching(false)
                setLoading(false)
            }
        }).catch((err: any) => {
            setSearching(false)
            alert(err.message)
            setLoading(false)

        })
    }

    const handleChangePage = (data: any, value: any) => {

        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })
        if (searchData.id !== '' || searchData.statusKey !== '') {
            searchedData(paginate.limit, value)
        } else {
            fetchBusinessUsers(paginate.limit, value)
        }
    }

    React.useEffect(() => {
        fetchBusinessUsers(paginate.limit, paginate.page)
    }, [])

    return (
        <div>
            <Card className={classes.card}>
                <Accordion expanded={open}
                    onChange={handleOpen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Business Account
                        </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    {/* <CardHeader name={'Name'} /> */}
                    <Typography className={classes.searchText}>
                        Search Filters
                    </Typography>
                    <AccordionDetails className={classes.accordianNoFlex}>

                        <SearchFilter
                            onChangeSearchData={onChangeSearchData}
                            paginate={paginate}
                            searchedData={searchedData}
                            data={searchData}
                            clearFilter={clearFilter} />
                    </AccordionDetails>
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <Table
                            data={tableData}
                            handleChangePage={handleChangePage}
                            paginate={paginate}
                            searching={searching}
                            loading={loading}
                            fetchBusinessUsers={fetchBusinessUsers}
                        />
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    )
}
export default Index
import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";

import TabContext from "@material-ui/lab/TabContext";
import { BiRefresh } from 'react-icons/bi'
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import { useStyles } from "../styles";
import UserNumberServices from '../../../../UsersNumbers/userNumbersServices/usersNumbersServices'
import CountryStates from "../CountryStates/CountryStates";
import logo from '../../../../../assets/images/Lodingg.gif'
const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];
function Repeating(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [index, setIndex] = React.useState("0")

  const [addData, setAddData] = React.useState<any>({

    "phoneNumber": "",

  })


  const handleSelectedNumbers = () => {

    UserNumberServices.PostNumbers(addData).then((res: any) => {

      if (res.success) {
        props.handlePopupModal()
        props.fetchNumberLists()
      } else {

      }
    }).catch((err: any) => {
      // send error
    })

  }
  const handleChangeNumbers = () => {
    setIndex("6")
  }

  // console.log("cities",props.cities)
  return (
    <div>
      <TabPanel value="2" classes={{ root: classes.padding }}>
        <CountryStates 
          countryOptions={props.countryOptions}
          onChangeStateType={props.onChangeStateType} 
          states={props.states} state={props.state}
          onChangeCountryType={props.onChangeCountryType} 
          country={props.country} 
          cities={props.cities}
          onChangeCity={props.onChangeCity}
          onchangingcityPremium={props.onchangingcityPremium}
          />
        <Grid container spacing={2}>
          {/* <Grid item md={4} className={classes.gridItem}>

            <Typography className={classes.text} style={{ marginTop: 15 }}>
              Area Code
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                placeholder="Area Code"
                value={props.city}
                inputProps={{ "aria-label": "Without label" }}
                //onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid> */}
          <Grid item md={6} className={classes.gridItem}>

          </Grid>
        </Grid>
        <Card className={classes.availableCard}>
          <CardContent>
            <div className={classes.bFlex} >
              <div>
                <Typography className={classes.text}>Available Numbers</Typography>
              </div>
              <div>
                <Button className={classes.availableButton}
                  // style={{ marginLeft: "128px" }}
                  onClick={props.handleChangeNumbers}
                  startIcon={<BiRefresh />}>Refresh Numbers</Button>
              </div>
            </div>
            <Divider className={classes.divider} />
            <CardContent>
              {props.loading ?
                <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", textAlign: 'center', width: "100%", height: "80px" }}>
                  {/* <CircularProgress disableShrink /> */}
                  <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
                </div> :
                <div className={classes.Numbers} >
                  {props.localNUmbers.length > 0 ? (
                    props.localNUmbers.slice(props.index, props.index + 4).map((loc: any, i: any) => (
                      <Grid container spacing={2} style={{}}>
                        <Grid item md={2}></Grid>
                        <Grid item md={10} >
                          <Button
                            key={i}
                            className={addData?.phoneNumber === loc?.number.toString() ? classes.numberButtonActive : classes.numberButton}
                            variant="outlined"
                            size="small"
                            fullWidth
                            startIcon={<PhoneIcon />}
                            onClick={() => setAddData((prev: any) => {
                              return {
                                ...prev,
                                phoneNumber: loc?.id.toString()
                              }
                            })}
                          >
                            {loc?.id}
                          </Button>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <p style={{textAlign: 'center',margin: '10px auto'}}>No phone Numbers found</p>
                  )}
                </div>
              }


            </CardContent>
          </CardContent>
        </Card>
        <div style={{ marginLeft: "-51px" }}>
          <IconButton className={addData?.phoneNumber !== '' ? classes.nextIconActive : classes.nextIcon} onClick={handleSelectedNumbers}>
            <GrFormNextLink className={classes.icon} />
          </IconButton>
        </div>
      </TabPanel>
    </div>
  );
}
export default Repeating;

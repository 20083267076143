import React from 'react'
import { PlusOneOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { useStyles } from './styles'
import { useHistory } from 'react-router-dom';
import { DashboardStatCard } from '../../components/DashboardStatCard';
import { CallStats } from './WeeklyMonthlyCallStats/WeeklyMonthlyCallStats';
import { HourCallStats } from './HourCallStats/HourCallStats';
import { DepartmentStats } from './DepartmentStats/DepartmentStats';
import { Grid } from '@material-ui/core';
import UserStats from './UserStats/UserStats';
import Service from './Service'
import Backdrop from '../../components/Modal/Backdrop'
import Modal from '../../components/Modal/Modal'
import History from '../History/History';
import AddUser from '../UsersDeskphones/Users/AddUser/AddUser';
import AddEfoneNumber from '../UsersDeskphones/PhoneNumbers/AllNumbers/AddNumber/AddEfoneNumber';
import CallCountDown from './CallCountDown';
import CallVolumneOverTime from './CallVolumeOverTime';
function Dashboard(props: any) {
    const classes = useStyles();
    const history = useHistory()
    const [data, setData] = React.useState<any>({})
    const [openModalAdduser, setopenModalAdduser] = React.useState(false);
    const [openModalAddNumber, setOpenModalAddNumber] = React.useState(false);
    const companyId = props.match.params?.id

    const handlePopupModalAddUser = () => {
        setopenModalAdduser(!openModalAdduser)
    }
    const handlePopupModalAddNumber = () => {
        setOpenModalAddNumber(!openModalAddNumber)
    }
    const getStats = () => {
        Service.getStats().then((res) => {
            if (res.success) {
                setData(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        getStats()
    }, [])


    

    return (
        <>

            {
                openModalAdduser && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAddUser} />
                        <Modal title="Add Users" handlePopupModal={handlePopupModalAddUser} >
                            <div className="add_user_popup" style={{ width: '900px' }}>
                                <AddUser fetchUserLists={getStats} handlePopupModal={handlePopupModalAddUser} />
                                {/* <AddUser fetchUserLists={getStats} handlePopupModal={handlePopupModalAddUser} /> */}
                            </div>
                        </Modal>
                    </div>
                )
            }
            {
                openModalAddNumber && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAddNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalAddNumber}>
                            <div className="add_user_popup">
                                <AddEfoneNumber fetchNumberLists={getStats} handlePopupModal={handlePopupModalAddNumber} />
                            </div>
                            {/* <AddEfoneNumber fetchNumberLists={getStats} handlePopupModal={handlePopupModalAddNumber} /> */}
                        </Modal>
                    </div>
                )
            }

            <div className={classes.container}>
                <div className={classes.container1}>
                    <div className={classes.statsContainer}>

                        <DashboardStatCard
                            btnIcon={<PlusOneOutlined />}
                            btnLabel="ADD USERS"
                            label="Total Users"
                            count={data.totalUsers ? data.totalUsers : 0}
                            path={handlePopupModalAddUser}
                        />
                        <DashboardStatCard
                            btnIcon={<PlusOneOutlined />}
                            btnLabel="ADD NUMBERS"
                            label="Total Numbers"
                            count={data.totalNumbers ? data.totalNumbers : 0}
                            path={handlePopupModalAddNumber}
                        />
                        <DashboardStatCard
                            btnIcon={<></>}
                            btnLabel=""
                            label="Live Calls"
                            count={data.liveCalls ? data.liveCalls : 0}
                        />
                        <DashboardStatCard
                            btnIcon={<></>}
                            btnLabel=""
                            label="Calls in Queue"
                            count={data.callsInQueue ? data.callsInQueue : 0}

                        />
                    </div>
                    {/* <Grid container spacing={2} className={`classes.display`}>
                        <Grid item md={12}>
                            <CallStats />
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={2} className={classes.display}>
                        <Grid item md={12}>
                            <History  />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} className={classes.display}>
                        <Grid item md={12}>
                            <HourCallStats />
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={2} className={classes.display}>
                        <Grid item md={12}>
                            <DepartmentStats  />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.display}>
                        <Grid item md={12}>
                            <UserStats />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.display}>
                        <Grid item md={12}>
                            <CallCountDown />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.display}>
                        <Grid item md={12}>
                            <CallVolumneOverTime />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default Dashboard

import React from "react";
import {
    Card,
    Grid,
    Button,
    CardContent,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import Departmentservices from "../DepartmentServices/DepartmentServices";
import moment from 'moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function UpdateNumber(props: any) {
    const classes = useStyles();
    const [selectedNumber, setSelectedNumber] = React.useState<any>(null);
    const [startDate, setStartDate] = React.useState<any>(new Date());
    const [error, setError] = React.useState("");
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, loading } = departmentStateData;
    const { _id, phoneNumber, blockDate, lastCallReceived, blockSms, blockCalls } = props.NumberData
    const [addBlockNumber, setAddBlockNumber] = React.useState({
        blockCalls: blockCalls,
        blockSms: blockSms,
        lastCallReceived: lastCallReceived,
        phoneNumber: phoneNumber,
    });



    const handleChangeCalls = (event: any) => {
        setAddBlockNumber((prev) => {
            return {
                ...prev,
                blockCalls: !addBlockNumber.blockCalls
            }
        })

    };
    const handleChangeSms = (event: any) => {
        setAddBlockNumber((prev) => {
            return {
                ...prev,
                blockSms: !addBlockNumber.blockSms
            }
        })

    };
    const onChangeBlockNumber = (data: any) => {

        setSelectedNumber(data.target.value)
        setAddBlockNumber((prev: any) => {
            return {
                ...prev,
                "phoneNumber": data.target.value,
            }
        })
    }
    const UpdateBlockNumberData = () => {
        
        const validation = addBlockNumber.phoneNumber === "" || addBlockNumber.phoneNumber.length > 15 || addBlockNumber.phoneNumber.length < 10
      
        if (validation) {
            toast.error("Enter valid phone number", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return
        }
        else if (!addBlockNumber.blockCalls && !addBlockNumber.blockSms) {
           
            console.log("addBlockNumber.blockCalls", addBlockNumber.blockCalls || addBlockNumber.blockSms)
            toast.error("Enter check to block calls or sms", { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return
        }
       
        Departmentservices.updateDepartmentBlockedNumber(props.deptId, _id, addBlockNumber)
            .then((res: any) => {
                if (res.success) {
                    props.fetchAdvancedSettingData();
                    props.handlePopupModalName();
                } else {
                }
            })
            .catch((err: any) => {
                console.log(err);
                // send error
            });
    };
    const onChangeDateandTime = (date: any) => {
        const Dateandtime = moment(date, 'YYYY.MM.DD HH:MM:SS').unix();
        const dt = Dateandtime * 1000
        // updateUserProfile(dndDatetime);
        setStartDate(dt);
        setAddBlockNumber((prev: any) => {
            return {
                ...prev,
                lastCallReceived: dt,
            }
        })
    };

    return (
        <div style={{ padding: 10 }} >
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography className={classes.textColorOne}>Add Number</Typography>
                    <TextField
                        className={classes.input}
                        placeholder="Enter Number"
                        size="small"
                        variant="outlined"
                        type="number"
                        inputProps={{ min: 0, inputmode: 'numeric', pattern: '[0-9]*' }}
                        value={addBlockNumber?.phoneNumber}
                        fullWidth
                        onChange={onChangeBlockNumber}
                    />
                    <Typography className={classes.textColorOne}>Last Call Time</Typography>
                    {/* <TextField type="date" size="small" variant="outlined" /> */}
                    <div className="date_and_time_picker_section">
                        <DatePicker
                            selected={addBlockNumber?.lastCallReceived}
                            onChange={onChangeDateandTime}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            value={addBlockNumber?.lastCallReceived}
                            showTimeInput
                            maxDate={new Date()}
                            className={classes.inputdate}
                        />
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            className={classes.formPadding}
                            control={
                                <Checkbox
                                    checked={addBlockNumber?.blockCalls}
                                    onChange={handleChangeCalls}
                                    name="blockCalls"
                                    color="primary"
                                />
                            }
                            label={<span className={classes.labelText}>Block Calls</span>}
                        />
                        <FormControlLabel
                            className={classes.formPadding}
                            control={
                                <Checkbox
                                    checked={addBlockNumber?.blockSms}
                                    onChange={handleChangeSms}
                                    name="blockSms"
                                    color="primary"
                                />
                            }
                            label={<span className={classes.labelText}>Block SMS</span>}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2} style={{ marginTop: 15, textAlign: 'center' }}>
                <Grid item md={12}>
                    <div>
                        <Button size="medium" className={classes.submitButton} onClick={UpdateBlockNumberData}>Submit</Button>
                        <Button size="medium" onClick={props.handlePopupModalName} className={classes.cancelButton}>Cancel</Button>
                    </div>
                </Grid>

            </Grid>




        </div>
    );
}

export default UpdateNumber;

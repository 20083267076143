import { makeStyles } from "@material-ui/core/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles((theme:any) => ({
    card:{
        boxShadow:'0 12.5214px 16.9039px rgb(0 0 0 / 5%)',
        borderRadius:8,
        background:'#ffffff'
    },
    dFlex:{
        display:'flex',
        padding:20,
        alignItems:'center',
        justifyContent:'space-between'
    },
    title:{
        color:'#4D72F8',
        fontSize:20,
        fontWeight:600
    },
    addBtn:{
        border:'1px solid #4D72F8',
        fontSize:14,
        color:'#4D72F8',
        background:'#ffffff',
        textTransform:'capitalize',
        padding:'6px 16px'
    },
    divWdth:{
        width:600
    },
    tableCard:{
        
    },
    deleteButton: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        background: '#3949ab',
        color: '#FFFFFF',
        borderRadius: 5,
        padding: '6px 22px',
        fontSize: 14,
        '&:hover': {
            color: '#FFFFFF',
            background: '#3949ab',
        }
    },
    cancelButton: {
        marginLeft: 20,
        borderRadius: 5,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontSize: 14,
        fontWeight: 500,
        color: '#2196f3',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        background: '#FFFFFF'
    },
}))
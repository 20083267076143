import { put, delay } from 'redux-saga/effects'
import * as authActionCreators from '../Actions/AuthActionCreator'
import * as globalpopupAlert from '../Actions/globalpopupAlert'
import localStorageContent from '../../services/localstorage/localstorage'
import axiosInstance from '../../environment/axiosInstance'
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

export function* init_auth_saga(action: any): Generator {
    try {
        yield put(authActionCreators.AuthLoading(true))
        const authResponse: any = yield axiosInstance.post('/auth/login', action.data);
        if (authResponse.data) {
            //sessionStorage.setItem('user', JSON.stringify(authResponse.data.data))
            //localStorage.setItem('user', JSON.stringify(authResponse.data.data))
            const { tokens } = authResponse.data;
            Cookies.set('access', JSON.stringify(tokens.access.token), { expires: new Date(tokens.access.expires) })
            Cookies.set('refresh', JSON.stringify(tokens.refresh.token), { expires: new Date(tokens.refresh.expires) })
            const userDataDecode = jwt_decode(tokens.access.token);
            const userData = { userData: userDataDecode, tokenData: tokens }
            localStorageContent.setLocalUserData({ authenticated: true, ...userData })
            yield put(authActionCreators.authSuccess(userData))
            toast.success("Login successfully", {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid green"
                }, autoClose: 1000
            })
            yield put(globalpopupAlert.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
            yield put(authActionCreators.authTimeout(tokens.access))
            yield put(authActionCreators.AuthLoading(false))
        } else {

            yield put(authActionCreators.AuthLoading(false))
            yield put(globalpopupAlert.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
        }
    } catch (error: any) {
      
        toast.error("Incorrect mail or passowrd", {
            position: toast.POSITION.TOP_RIGHT,
            style: {
                borderRadius: "2px",
                border: "1px solid red"
            }, autoClose: 1000
        })

        yield put(authActionCreators.AuthLoading(false))
        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data?.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_auth_saga_LoginAs(action: any): Generator{
    try {
        yield put(authActionCreators.AuthLoading(true))
        console.log("action",action)
        const authResponse: any = yield axiosInstance.get(`/auth/loginAs?tokenId=${action.data.token}`);
        console.log("authResponse",authResponse)
        if (authResponse.data) {
            //sessionStorage.setItem('user', JSON.stringify(authResponse.data.data))
            //localStorage.setItem('user', JSON.stringify(authResponse.data.data))
            const { tokens } = authResponse.data;
            Cookies.set('access', JSON.stringify(tokens.access.token), { expires: new Date(tokens.access.expires) })
            Cookies.set('refresh', JSON.stringify(tokens.refresh.token), { expires: new Date(tokens.refresh.expires) })
            const userDataDecode = jwt_decode(tokens.access.token);
            const userData = { userData: userDataDecode, tokenData: tokens }
            localStorageContent.setLocalUserData({ authenticated: true, ...userData })
            yield put(authActionCreators.authSuccess(userData))
            toast.success("Login successfully", {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid green"
                }, autoClose: 1000
            })
            yield put(globalpopupAlert.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
            yield put(authActionCreators.authTimeout(tokens.access))
            yield put(authActionCreators.AuthLoading(false))
        } else {

            yield put(authActionCreators.AuthLoading(false))
            yield put(globalpopupAlert.initglobalPopup(authResponse.data.message, 'LOGIN_ROUTE', 'success'))
        }
    } catch (error: any) {
      
        toast.error("Incorrect mail or passowrd", {
            position: toast.POSITION.TOP_RIGHT,
            style: {
                borderRadius: "2px",
                border: "1px solid red"
            }, autoClose: 1000
        })

        yield put(authActionCreators.AuthLoading(false))
        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_auth_timeout(action: any): Generator {
    try {

        const rememberMe: any = yield localStorage.getItem('rememberme');
        const ParseRememeber: any = rememberMe !== undefined ? JSON.parse(rememberMe) : null
        const { expires }: any = action.tokenData;
        const timeOut: any = Math.ceil(new Date(expires).getTime() - new Date().getTime());
        yield delay(timeOut)

        if (ParseRememeber) {
            yield put(authActionCreators.initRefreshToken())
        } else {
            yield put(authActionCreators.initAuthLogout())
        }

    } catch (error) {
        console.log(error)
    }
}

export function* init_auth_logout(action: any): Generator {

    try {
        yield localStorageContent.setLocalUserData({ authenticated: false, userData: null, tokenData: null })
        const refreshToken: any = yield Cookies.get('refresh')
        const parseRefresToken = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined;
        if (parseRefresToken !== undefined && parseRefresToken?.token) yield axiosInstance.post('/', { refreshToken: parseRefresToken?.token })
        yield Cookies.remove('access')
        yield Cookies.remove('refresh')
        yield put(authActionCreators.initAuthLogoutSuccess())
    } catch (error) {

    }
}

export function* init_auth_status_check(): Generator {

    const accessToken: any = Cookies.get('access');
    const refreshToken: any = Cookies.get('refresh');
    const rememberMe: any = localStorage.getItem('rememberme');

    const accessTokenParse = accessToken !== undefined ? JSON.parse(accessToken) : undefined
    const refreshTokenParse = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined
    const parseRemember = rememberMe !== undefined ? JSON.parse(rememberMe) : undefined;

    if (accessTokenParse !== undefined && refreshTokenParse !== undefined) {
        const userLocalData = localStorageContent.getLocalUserData()
        yield put(authActionCreators.authSuccess(userLocalData))
        yield put(authActionCreators.authTimeout(userLocalData?.tokenData?.access))
    } else if (accessTokenParse === undefined && parseRemember !== undefined && parseRemember === true) {
        yield put(authActionCreators.initRefreshToken())
    }
    else if (accessTokenParse === undefined) {
        yield put(authActionCreators.initRefreshToken())

    } else {
        yield put(authActionCreators.initAuthLogout())
    }
}


export function* init_refresh_token(): Generator {
    try {
        const refreshToken: any = yield Cookies.get('refresh')
        const parseRefreshData: any = refreshToken !== undefined ? JSON.parse(refreshToken) : undefined;

        if (refreshToken) {
            const responseTokenData: any = yield axiosInstance.post('/auth/refresh-tokens', { refreshToken: refreshToken.token ? JSON.stringify(refreshToken.token) : JSON.parse(refreshToken) });

            if (responseTokenData.data) {
                const { access, refresh } = responseTokenData.data;
                yield Cookies.remove('access')
                yield Cookies.remove('refresh')
                yield Cookies.set('access', JSON.stringify(access.token))
                yield Cookies.set('refresh', JSON.stringify(refresh.token))

                const userDataDecode = jwt_decode(access.token);
                const userData = { userData: userDataDecode, tokenData: responseTokenData.data }
                //sessionStorage.setItem('user', JSON.stringify(responseTokenData.data.data))
                //localStorage.setItem('user_data', JSON.stringify(responseTokenData.data.data))
                yield localStorageContent.setLocalUserData({ authenticated: true, ...userData })
                yield put(authActionCreators.authSuccess(userData))
                yield put(authActionCreators.authTimeout(access))
            } else {
                yield put(authActionCreators.initAuthLogout())
            }
        } else {
            yield put(authActionCreators.initAuthLogout())

        }
    } catch (error) {
        yield put(authActionCreators.initAuthLogout())

    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dispatch(globalpopupAlert.initglobalPopup(err, instance, type))
    }
}

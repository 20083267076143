import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { TableCell, TableRow, Menu, MenuItem, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import ProfileServices from "../ProfileServices/ProfileServices";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { BsThreeDots } from "react-icons/bs";
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";


function BlockNumberTable(props: any) {
  const classes = useStyles();
  const { _id, phoneNumber, blockDate, lastCallReceived, blockSms, blockCalls } = props.row;
  const [blockcalls, setBlockcalls] = React.useState<any>(blockCalls);
  const [BlockSms, setBlockSms] = React.useState<any>(blockSms);
  const [open, setOpen] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false)
  const anchorRef: any = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handlePopupDeleteModalNumber = () => {
    setDeleteModal(!deleteModal)
  }

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);



  const onChangeNumber = () => {
    setBlockcalls(!blockcalls);
    updateNumber({ "phoneNumber": phoneNumber, "lastCallReceived": lastCallReceived, "blockCalls": !blockCalls, "blockSms": blockSms });
  };
  const onChangeNumberSms = () => {
    // console.log("---sms--",BlockSms)
    setBlockSms(!BlockSms);
    updateNumber({ "phoneNumber": phoneNumber, "lastCallReceived": lastCallReceived, "blockCalls": blockCalls, "blockSms": !blockSms });
  };

  React.useEffect(() => {
    setBlockcalls(blockcalls);
    setBlockSms(BlockSms);
  }, []);

  const updateNumber = (data: any) => {
    ProfileServices.updateBlockNumber(_id, data)
      .then((res: any) => {

        if (res.success) {
          // props.getMyDeviceLists()
        } else {
        }
      })
      .catch((err) => { });
  };

  const deleteNumber = () => {
    //   console.log("dele")
    ProfileServices.deleteBlockNumber(_id)
      .then((res: any) => {
        console.log("dele", res);
        if (res.success) {
          props.getBlockedNumberData();
          setOpen(false);
        } else {
        }
      })
      .catch((err) => { });
  };
  // console.log("----id", props.row._id);
  return (
    <>
      <div>
        {
          deleteModal && (
            <div>
              <Backdrop handlePopupModal={handlePopupDeleteModalNumber} />
              <Modal title="Are you sure to delete this Number ?" handlePopupModal={handlePopupDeleteModalNumber} >
                <div className="add_user_popup" style={{ width: "500px" }}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                      <div>
                        <Button size="medium" className={classes.deleteButton} onClick={deleteNumber}>Delete</Button>
                        <Button size="medium" onClick={handlePopupDeleteModalNumber} className={classes.cancelButton}>Cancel</Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>

              </Modal>
            </div>
          )
        }
      </div>
      <TableRow>
        <TableCell className={classes.tableBody}>{phoneNumber}</TableCell>
        <TableCell className={classes.tableBody}>
          {new Date(Number(blockDate)).toLocaleString()}
        </TableCell>
        <TableCell className={classes.tableBody}>
          {new Date(Number(lastCallReceived)).toLocaleString()}
        </TableCell>
        <TableCell>
          <Checkbox
            checked={blockcalls}
            onChange={onChangeNumber}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={BlockSms}
            onChange={onChangeNumberSms}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>

        <TableCell className={classes.tableBody}>
          <div>
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <BsThreeDots />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handlePopupDeleteModalNumber}>Delete</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BlockNumberTable;

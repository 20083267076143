import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import { BiChevronRight } from 'react-icons/bi'
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import { BiRefresh } from 'react-icons/bi'
import { useStyles } from "../styles";
// import UserNumberServices from '../../../userNumbersServices/usersNumbersServices'
import UserNumberServices from '../../../../../UsersNumbers/userNumbersServices/usersNumbersServices'
import CountryStates from "../CountryStates/CountryStates";
import { useSelector } from 'react-redux'
import { $CombinedState } from "redux";
import logo from '../../../../../../assets/images/Lodingg.gif'
import AddUser from '../../../AddUser/AddUser';

const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];
function Default(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [index, setIndex] = React.useState("0")
  const [addData, setAddData] = React.useState<any>({

    "phoneNumber": "",

  })


  const handleChangeNumbers = () => {
    setIndex("4")
  }
  const handleSelectedNumbers = (data:any) => {
    // console.log("dataaaa",data)
    props.handlePopupModal(data)
    // UserNumberServices.PostNumbers(addData).then((res: any) => {
    //   if (res.success) {
    //    
    //     props.fetchNumberLists()
    //   }
    //   else {

    //   }
    // }).catch((err: any) => {
    //   // send error
    // })

  }



  return (
    <div>
      <TabPanel value="1" classes={{ root: classes.padding }}>
        <CountryStates countryOptions={props.countryOptions}
          country={props.country}
          onChangeStateType={props.onChangeStateType}
          states={props.states}
          state={props.state}
          onChangeCountryType={props.onChangeCountryType}
          onChangeCity={props.onChangeCity}
          city={props.city}
          cities={props.citiesOptions}
        />
        {/* <Grid container spacing={2}>
          <Grid item md={4} className={classes.gridItem}>

            <Typography className={classes.text} style={{ marginTop: 15 }}>
              Cities
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                value={props.city}
                placeholder="Select City"
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} className={classes.gridItem}>
          </Grid>
        </Grid> */}
        <Card className={classes.availableCard}>
          <CardContent>
            <div className={classes.bFlex} >
              <div>
                <Typography className={classes.text}>Available Numbers</Typography>
              </div>
              {/* <div>
                <Button className={classes.availableButton}
                  // style={{ marginLeft: "128px" }}
                  onClick={props.handleChangeNumbers}
                  startIcon={<BiRefresh />}
                >Refresh Numbers</Button>
              </div> */}
            </div>
            {/* <Typography className={classes.text} style={{ marginBottom: "15px" }}>Available Numbers</Typography> */}
            <Divider className={classes.divider} />
            {/* <CardContent> */}

            {props.loading ?

              <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", textAlign: 'center', width: "100%", height: "80px" }}>
                {/* <CircularProgress disableShrink /> */}
                <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
              </div> :
              <div className={classes.Numbers}>
                {props.localNUmbers.length > 0 ? (
                  props.localNUmbers.slice(props.index, props.index + 4).map((loc: any, i: any) => (

                    <Grid container spacing={2} style={{}}>
                      <Grid item md={2}></Grid>
                      <Grid item md={10} >
                        <Button
                          key={i}
                          className={addData?.phoneNumber === loc?.id ? classes.numberButtonActive : classes.numberButton}
                          variant="outlined"
                          size="small"
                          fullWidth
                          onClick={() => setAddData((prev: any) => {
                            return {
                              ...prev,
                              phoneNumber: loc?.id
                            }
                          })}
                        // startIcon={<PhoneIcon />}

                        >
                          {loc?.id}
                        </Button>
                      </Grid>

                    </Grid>


                  ))
                ) : (
                  <p style={{ textAlign: 'center', margin: '10px auto' }}>No phone Numbers found</p>
                )}
                <div onClick={props.handleChangeNumbers} style={{
                  marginTop: 40,
                  width: 25, height: 25, background: "#4D72F8",
                  display: 'grid', justifyContent: 'center', borderRadius: 20,color:'white',
                  alignItems: 'center'
                }}><BiChevronRight /></div>
              </div>


            }



          </CardContent>
          {/* </CardContent> */}
        </Card>
        <div style={{ textAlign: 'center', marginTop: 25 }}>
          <Button size="small" variant="outlined" className={classes.cancelNumber} onClick={props.handlePopupModal}>Cancel</Button>
          <Button size="small" variant="outlined" className={classes.addNumber} onClick={()=>handleSelectedNumbers(addData.phoneNumber)}>Add Number</Button>
        </div>
        {/* <div>
          <IconButton className={addData?.phoneNumber !== '' ? classes.nextIconActive : classes.nextIcon} onClick={handleSelectedNumbers}>
            <GrFormNextLink className={classes.icon} />
          </IconButton>
        </div> */}
      </TabPanel>
    </div>
  );
}

export default Default;

import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux'

import { useStyles } from './style';
import DepartmentServices from '../../../DepartmentServices/DepartmentServices'
import callhandlingimg from '../../../../../assets/images/Vector.png'
import useraccount from '../../../../../assets/images/useraccount.png'
import forwardnumber from '../../../../../assets/images/forwardnum.png'
import voicemail from '../../../../../assets/images/voicemail.png'
import recnum from '../../../../../assets/images/recmsg.png'
import namedir from '../../../../../assets/images/namedirector.png'
import TransferAudio from '../TransferAudio'
import OpenHourAgentsAudioTransfer from '../OpenHourAgentsAudioTransfer'
import AgentsAudioTransfer from '../TransferAudio'
import { useParams } from 'react-router-dom';
import UnAnsweredUserDepartments from './UnAnsweredUserDepartments/UnAnsweredUserDepartments';
import UnAnsweredRecMessage from './UnAnsweredRecMessage/UnAnsweredRecMessage';
import UnAnsweredVoiceMail from './UnAnsweredVoiceMail/UnAnsweredVoiceMail';
import UnAnsweredForwardNumbers from './UnAnsweredForwardNumbers/UnAnsweredForwardNumbers'

const openhoursOptions = [{ key: 1, value: "Automated Menu" },
{ key: 3, value: "departments" },
{ key: 5, value: "forwardNumber" }, { key: 6, value: "voicemail" }, { key: 7, value: "recmessages" }, { key: 8, value: "nameDirector" }]


function UnAnswerCallNew(props: any) {
    const classes = useStyles()
    const params = useParams()
    const [tabname, setTabName] = React.useState('');
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, loading } = departmentStateData;
    const [openHoursData, setOpenHoursData] = React.useState<any>({})
    const [selectedOption, setselectedOption] = React.useState<any>({
        currentSelectedOption: null
    })
    const [deptPath, setDeptPath] = React.useState<any>('')
    const [deptFileName, setDeptFileName] = React.useState<any>('')
    const [recPath, setRectPath] = React.useState<any>('')
    const [recFileName, setRecFileName] = React.useState<any>('')
    const [voicePath, setVoicePath] = React.useState<any>('')
    const [voiceFileName, setVoiceFileName] = React.useState<any>('')
    const [forwardPath, setForwardPath] = React.useState('')
    const [forwardFileName, setForwardFileName] = React.useState('')
    const [newdata, setNewData] = React.useState<any>({
        departments: {
            transferingAudio: null,
            currentSelectedValue: null
        },
        teamMember: {
            transferingAudio: null,
            currentSelectedValue: null
        },
        phoneNumber: {
            transferingAudio: null,
            currentSelectedValue: null
        },
        voicemail: {
            currentSelectedValue: null
        },
        recording: {
            currentSelectedValue: null
        },
        currentSelectedOption: null,
        currentSelectedValue: null,
        transferingAudio: null
    })


    const getOpenHours = () => {
        DepartmentServices.getOpenHours(props.depId).then((res: any) => {
            if (res.success) {
                setOpenHoursData(res.data)
                // setselectedOption((prev: any) => {
                //     return {
                //         ...prev,
                //         currentSelectedOption: res.data.currentSelectedOption
                //     }
                // })
                if (res.data.unAnsweredCalls.currentSelectedOption === 3) {
                    const deptData = res.data.unAnsweredCalls.userAndDepartments.transferingAudio
                    deptData.map((item: any) => {
                        if (item.isSelected === true) {
                            setDeptPath(item.path)
                            setDeptFileName(item.fileName)
                        }
                    })
                    if (deptData.length === 0) {
                        setDeptPath("")
                        setDeptFileName("")
                    }

                }
                if (res.data.unAnsweredCalls.currentSelectedOption === 7) {
                    const recData = res.data.unAnsweredCalls.recording.recordingAudio
                    recData.map((item: any) => {
                        setRectPath(item.path)
                        setRecFileName(item.fileName)
                    })
                }
                if (res.data.unAnsweredCalls.currentSelectedOption === 6) {
                    const voiceData = res.data.unAnsweredCalls.voicemail.voicemailAudio
                    voiceData.map((item: any) => {
                        setVoicePath(item.path)
                        setVoiceFileName(item.fileName)
                    })

                }
                if (res.data.unAnsweredCalls.currentSelectedOption === 5) {
                    const forwardData = res.data.unAnsweredCalls.forwardNumbers.transferingAudio
                    forwardData.map((item: any) => {
                        if (item.isSelected === true) {
                            setForwardPath(item.path)
                            setForwardFileName(item.fileName)
                        }
                    })
                    if(forwardData.length === 0){
                        setForwardPath("")
                        setForwardFileName("")
                    }

                }
            }
        })
    }




    const handleTabChange = (tabname: any) => {
        const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
        if (tabname === 'nameDirector') {
            var myData = selectedOption
            myData.currentSelectedOption = 8
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.depId, selectedOption).then((res: any) => {
                getOpenHours()
                //send success message
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'departments') {
            var myData = selectedOption
            myData.currentSelectedOption = 3
            setselectedOption(myData)
            DepartmentServices.updateOpenHoursUnansweredCalls(props.depId, selectedOption).then((res: any) => {
                getOpenHours()
                //send success message
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'forwardNumber') {
            var myData = selectedOption
            myData.currentSelectedOption = 5
            setselectedOption(myData)
            DepartmentServices.updateOpenHoursUnansweredCalls(props.depId, selectedOption).then((res: any) => {
                getOpenHours()
                //send success message
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'voicemail') {
            var myData = selectedOption
            myData.currentSelectedOption = 6
            setselectedOption(myData)
            DepartmentServices.updateOpenHoursUnansweredCalls(props.depId, selectedOption).then((res: any) => {
                getOpenHours()
                //send success message
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'recmessages') {
            var myData = selectedOption
            myData.currentSelectedOption = 7
            setselectedOption(myData)
            DepartmentServices.updateOpenHoursUnansweredCalls(props.depId, selectedOption).then((res: any) => {
                getOpenHours()
                //send success message
            }).catch((err: any) => {
                //send error message
            })
        }
        setTabName(tabname)
    }



    React.useEffect(() => {
        if (props.depId) {
            const unAnsweredData = openHoursData;
            openhoursOptions.forEach((option: any) => {
                if (unAnsweredData?.unAnsweredCalls) {
                    if (option.key === unAnsweredData.unAnsweredCalls.currentSelectedOption) {
                        setTabName(option.value)
                    }
                }
            })
        }
    }, [props.depId, openHoursData])


    const fetchSelectedOption = async () => {
        await DepartmentServices.getOpenHours(props.depId).then((res: any) => {
            if (res.success) {
                openhoursOptions.forEach((option: any) => {
                    if (option.key === res.data?.currentSelectedOption) {
                        setTabName(option.value)
                    }
                })
                getOpenHours()
            }
        })
    }


    React.useLayoutEffect(() => {
        getOpenHours()
        fetchSelectedOption()
    }, [])



    return (
        <div>

            {/* <Typography className={classes.greetText}>Calls Missed by Agents</Typography>
            <Divider className={classes.divider} /> */}
            <Typography className={classes.departmentNameText}>Calls Missed by Operator And Backup Operator</Typography>
            <Typography className={classes.texttypo}>If no agents are available this  will be applied</Typography>

            <div className={classes.gridJustify}>
                {/* <Card
                    className={tabname === 'Automated Menu' ? classes.callhandlingcardActive : classes.callhandlingcard}
                    onClick={() => handleTabChange('Automated Menu')}>
                    <CardContent className={classes.align}>
                        <CgAttribution
                                        className={
                                            tabname === "Automated Menu"
                                                ? classes.deviceWidth1
                                                : classes.deviceWidth
                                        } />
                        <div className={tabname === 'Automated Menu' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                            <img src={callhandlingimg} alt='callhandling' color='#828282' width='15px' height='15px' />
                        </div>
                        <Typography className={tabname === 'Automated Menu' ? classes.carddevicesText : classes.carddevicesText1}>
                            Automated Menu
                        </Typography>
                    </CardContent>
                </Card> */}
                <Card
                    className={tabname === 'departments' ? classes.callhandlingcardActive : classes.callhandlingcard}
                    onClick={() => handleTabChange('departments')}
                >
                    <CardContent className={classes.align}>
                        {/* <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} /> */}
                        {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}
                        <div className={tabname === 'departments' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                            <img src={useraccount} alt='departments' color='#828282' width='15px' height='15px' />
                        </div>
                        <Typography
                            className={tabname === 'departments' ? classes.carddevicesText : classes.carddevicesText1}>
                            Users & Dept
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    className={tabname === 'forwardNumber' ? classes.callhandlingcardActive : classes.callhandlingcard}
                    onClick={() => handleTabChange('forwardNumber')}>
                    <CardContent className={classes.align}>
                        <div className={tabname === 'forwardNumber' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                            <img src={forwardnumber} alt='forwardNumber' color='#828282' width='15px' height='15px' />
                        </div>
                        <Typography className={tabname === 'forwardNumber' ? classes.carddevicesText : classes.carddevicesText1}>
                            Forward Number
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    className={tabname === 'voicemail' ? classes.callhandlingcardActive : classes.callhandlingcard}
                    onClick={() => handleTabChange('voicemail')}>
                    <CardContent className={classes.align}>
                        <div className={tabname === 'voicemail' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                            <img src={voicemail} alt='voicemail' color='#828282' width='15px' height='15px' />
                        </div>
                        <Typography className={tabname === 'voicemail' ? classes.carddevicesText : classes.carddevicesText1}>
                            Voice Mail
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    className={tabname === 'recmessages' ? classes.callhandlingcardActive : classes.callhandlingcard}
                    onClick={() => handleTabChange('recmessages')}>
                    <CardContent className={classes.align}>
                        <div className={tabname === 'recmessages' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                            <img src={recnum} alt='recmessages' color='#828282' width='15px' height='15px' />
                        </div>
                        <Typography
                            className={tabname === 'recmessages' ? classes.carddevicesText : classes.carddevicesText1}>
                            Recorded Msg
                        </Typography>
                    </CardContent>
                </Card>
                <Card
                    className={tabname === 'nameDirector' ? classes.callhandlingcardActive : classes.callhandlingcard}
                    onClick={() => handleTabChange('nameDirector')}>
                    <CardContent className={classes.align}>
                        <div className={tabname === 'nameDirector' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                            <img src={namedir} alt='nameDirector' color='#828282' width='15px' height='15px' />
                        </div>
                        <Typography
                            className={tabname === 'nameDirector' ? classes.carddevicesText : classes.carddevicesText1}>
                            Name Director
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            {/* {tabname === 'Automated Menu' && (
                <>
                    <AgentsAudioTransfer />
                </>
            )} */}
            {tabname === 'departments' && (
                <>
                    <UnAnsweredUserDepartments
                        deptPath={deptPath}
                        deptFileName={deptFileName}
                        depId={props.depId}
                        openHoursData={openHoursData}
                        getOpenHours={getOpenHours}
                    />
                    {/* <AgentsAudioTransfer /> */}
                </>
            )}
            {tabname === 'forwardNumber' && (
                <>
                    <UnAnsweredForwardNumbers
                        forwardPath={forwardPath}
                        forwardFileName={forwardFileName}
                        openHoursData={openHoursData}
                        getOpenHours={getOpenHours}
                        depId={props.depId}
                    />
                </>
            )}
            {tabname === 'voicemail' && (
                <>
                    <UnAnsweredVoiceMail
                        depId={props.depId}
                        openHoursData={openHoursData}
                        getOpenHours={getOpenHours}
                        voicePath={voicePath}
                        voiceFileName={voiceFileName}
                    />
                </>
            )}
            {tabname === 'recmessages' && (
                <>
                    <UnAnsweredRecMessage
                        recPath={recPath}
                        recFileName={recFileName}
                        depId={props.depId}
                        openHoursData={openHoursData}
                        getOpenHours={getOpenHours}
                    />
                </>
            )}

            {tabname === 'nameDirector' && (
                <>

                </>
            )}
        </div>

    )
}

export default UnAnswerCallNew
import React from 'react'
import {
    Card, CardContent,
    Typography, Grid, TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, Box, CircularProgress, Divider, ListItemText, Popper, Button,
    TextField, FormControlLabel, Radio
}
    from '@material-ui/core';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import sortDown from '../../../../assets/images/Sort Down.png'
import { AiFillInfoCircle } from 'react-icons/ai';
import Select from '@mui/material/Select';
import { useStyles } from '../style'
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { addDays, endOfDay, getUnixTime, startOfDay, subDays } from 'date-fns';
import axiosInstance from '../../../../environment/axiosInstance'

function AllCalls(props:any) {
    const classes = useStyles()
    const history = useHistory()
    const [anchorEll, setAnchorEll] = React.useState<any>(null);
    const [anchorEldt, setAnchorEldt] = React.useState<any>(null);
    const [startDate, setstartDate] = React.useState<any>()
    const [endDate, setendDate] = React.useState<any>()
    const [searchData, setSearchData] = React.useState('')
    const [groupUserData, setGroupUserData] = React.useState<any>({});
    const [selectOffice, setselectOffice] = React.useState<any>()
    const [callFormData, setCallFormData] = React.useState<any>({
        statusKey: "",
        callStatus: "",
        startDate: new Date().setHours(0, 0, 0, 0),
        callFromDateData: "startDate",
        endDate: new Date().setHours(23, 59, 59, 59),
        remainderDateData: "endDate"
    })


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEll(anchorEll ? null : event.currentTarget);
        setAnchorEldt(null)
        setstartDate('')
        setendDate('')
    };
    const handleClickAway = () => {
        setAnchorEldt(null)
        setAnchorEll(null)
    }

    
    const open = Boolean(anchorEll);
    const id = open ? "department-popper" : undefined;

    const opendt = Boolean(anchorEldt);
    const iddt = open ? "date-popper" : undefined;

    const handleClickdt = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEldt(anchorEldt ? null : event.currentTarget);
        setAnchorEll(null)
        setselectOffice('')
    };


    // all users
    const globalUsers = (e: any) => {
        setSearchData(e.target.value);
    }

    const Ranges: any = [
        {
            label: 'today',
            value: [startOfDay(new Date()), endOfDay(new Date())]
        },
        {
            label: 'yesterday',
            value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
        },
        {
            label: 'last7Days',
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
        },
        {
            label: 'last30Days',
            value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]
        }
    ];

    // const handleDateRange = (e: any) => {
    //     console.log(e)
    //     const startDt = e[0]
    //     const endDt = e[1]
    //     setCallFormData((prev: any) => {
    //         return {
    //             ...prev,
    //             startDate: getUnixTime(startDt).toString(),
    //             endDate: getUnixTime(endDt).toString()
    //         }

    //     })
       
    // }

    //all users data
    const allUsersData = () => {
        axiosInstance.get('dashboard/teams-departments-companies').then((res: any) => {
            if (res.status === 200) {
                setGroupUserData(res.data.Data);
            }
        })
    }
    

    const handleClickNextPage = () => {
        let queryFilter = '';
        if (searchData) {
            let userD = searchData.split('_');
            queryFilter = userD[0] + '=' + userD[1] + '&'
        }
        if (callFormData.startDate && callFormData.endDate) {
            queryFilter += `startTime=${callFormData.startDate}&endTime=${callFormData.endDate}`;
        }
        history.push(`/dashboard/analytics?${queryFilter}`)
    }

    React.useEffect(() => {
        allUsersData()
    }, [props.location?.search])



    return (
        <div>
            <Card className={classes.card} style={{ padding: 20 }}>
                <div>
                    <h1 className='headerStyle'>All Calls</h1>
                    <Typography>Explore what's happening on calls with</Typography>
                </div>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <Button variant='contained' aria-describedby={id} onClick={handleClick}
                                style={{ marginTop: "10px", marginRight: '15px', backgroundColor: '#ffffff' }} endIcon={<img src={sortDown} alt="company select" />}>
                                This Office
                            </Button>
                            <Popper id={id} open={open} anchorEl={anchorEll} placement={'bottom-start'} >
                                <Box sx={{
                                    border: "1px solid blueviolet", p: 2, bgcolor: "background.paper", width: "330px", height: "180px",
                                    borderRadius: "8px"
                                }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }}>
                                        <Typography className={classes.usersorgroups}>Users or Groups</Typography>
                                        <AiFillInfoCircle />
                                    </div>
                                    <Select
                                        size='small'
                                        onChange={props.globalUsers} native
                                        defaultValue="" id="grouped-native-select"
                                        label="Grouping"
                                    >
                                        <option aria-label="User & Offices" value="" />
                                        <optgroup label="Users">
                                            {
                                                groupUserData.Teams?.map((item: any) => {
                                                    return <option value={'userId_' + item._id}>{item.email}</option>
                                                })
                                            }
                                        </optgroup>
                                        <optgroup label="Departments">
                                            {
                                                groupUserData.Departments?.map((item: any) => {
                                                    return <option value={'departmentId_' + item._id}>{item.name}</option>
                                                })
                                            }
                                        </optgroup>
                                        <optgroup label="Offices">
                                            {
                                                groupUserData.companies?.map((item: any) => {
                                                    return <option value={'officeId_' + item._id}>{item.name}</option>
                                                })
                                            }
                                        </optgroup>
                                        <optgroup label="Room Phones">
                                            {
                                                groupUserData.roomPhones?.map((item: any) => {
                                                    return <option value={'roomPhoneId_' + item._id}>{item.name}</option>
                                                })
                                            }
                                        </optgroup>
                                    </Select>
                                    <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                        <Button variant='outlined'
                                            style={{ marginTop: "10px", marginRight: '10px' }} onClick={handleClickNextPage}>
                                            Apply
                                        </Button>
                                    </div>
                                </Box>
                            </Popper>
                        </div>
                        <div>
                            <Button style={{ marginTop: "10px", backgroundColor: '#ffffff' }} variant='contained' aria-describedby={iddt}
                                endIcon={<img src={sortDown} alt="company select" />} onClick={handleClickdt}>
                                Past 30days
                            </Button>
                            <Popper id={iddt} open={opendt} anchorEl={anchorEldt} placement={'bottom-start'} >
                                <Box sx={{
                                    border: "1px solid blueviolet", p: 1, bgcolor: "background.paper", width: "330px",
                                    borderRadius: "8px"
                                }}>
                                    <Divider className={classes.divider} />
                                    <div style={{ width: "100%" }}>
                                        <Grid>
                                            <DateRangePicker onChange={props.handleDateRange} placeholder="Select Date Range" ranges={Ranges} />
                                        </Grid>
                                        <Grid container xl={4} lg={12} md={12} sm={12} xs={12}></Grid>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                                            <Button variant='contained'
                                                style={{ marginTop: "10px", marginRight: '10px' }}
                                                onClick={handleClickNextPage}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            </Popper>
                        </div>
                    </div>
                </ClickAwayListener>
            </Card>
        </div>
    )
}

export default AllCalls
import { makeStyles, withStyles, Slider } from '@material-ui/core';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles((theme) => ({
    departmentNameText: {
        fontSize: 18,
        // lineheight: 27,
        fontFamily: 'Calibri',
        fontStyle: "normal",
        fontWeight: 600,
        color: "#A0A0A0",
        marginTop: '0px',
        marginLeft: '15px',
        [theme.breakpoints.down("xs")]: {
            fontSize: 16, marginLeft: '8px',
        }
    },
    reloagImg:{
        width:30,
        height:30,
        color:'#3498db',
        cursor:'pointer',
    },
    texttypo: {
        fontSize: 13,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#8392AB',
        margin: '10px 20px',
    },
    departmentText: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: "Work Sans",
        color: "#8392AB",
    },

    align: {
        textAlign: 'center',
    },
    deviceWidth: {
        width: 40,
        height: 40,
        borderRadius: 50,
        color: '#8392AB',
        backgroundColor: '#fff',
        padding: '10px',
        border: '1px solid #b8b8b8',
        boxShadow: '0px 2px 5px rgb(0 0 0 / 10%)',
        [theme.breakpoints.down("xs")]: {
            width: 25,
            height: 25,
            padding: '5px',
        }
    },
    deviceWidth1: {
        width: 40,
        height: 40,
        borderRadius: 50,
        color: '#4D72F8',
        backgroundColor: '#fbfbfb',
        padding: '10px',
        border: '1px solid #ebebeb',
        [theme.breakpoints.down("xs")]: {
            width: 25,
            height: 25,
            padding: '5px',
        }
    },
    cardWidth: {
        display: 'flex',
        justifyContent: 'center',
        height: '130px',
        width: '135px',
        backgroundColor: '#F0F3F5',
        // boxShadow: '0px 2px 5px rgba(0,0,0,0.3)'
        boxShadow: 'none',
        borderRadius: '10px',
        borderRight: "1px solid #ECECEC",
        [theme.breakpoints.down("xs")]: {
            height: '85px',
            width: '95px',
        }
    },
    cardWidth1: {
        display: 'flex',
        justifyContent: 'center',
        height: '130px',
        width: '135px',
        backgroundColor: '#F0F3F5',
        // boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
        borderRadius: '10px 0px 0px 10px',
        boxShadow: 'none',

    },
    cardWidth2: {
        display: 'flex',
        justifyContent: 'center',
        height: '130px',
        width: '135px',
        backgroundColor: '#F0F3F5',
        // boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
        borderRadius: '0px 10px 10px 0px',
        boxShadow: 'none'
    },

    devicesText: {
        fontSize: '14px',
        fontFamily: 'Work Sans',
        fontWeight: 400,
        color: '#626262',
        marginTop: 6,
        [theme.breakpoints.down("xs")]: {
            fontSize: '10px', marginTop: 6,
        }
    },
    dFlex:
    {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        "@media (max-width: 1024px)": {
            display: 'grid',
            gridGap: 14,
            felxWrap: 'wrap',
            gridTemplateColumns: 'auto auto auto auto',
        },
        [theme.breakpoints.down("xs")]: {
            dispay: "inline-grid",
            gridGap: 10,
            gridTemplateColumns: 'auto auto auto',
        }
    },
    grid: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',

    },
    divider: {
        margin: 5,
        backgroundColor: '#E2E2E2',
    },

    devicesTextActive: {
        fontSize: '12px',
        textAlign: 'center',
        fontFamily: 'Work Sans',
        fontWeight: 600,
        marginTop: 6,
        color: 'white',
        [theme.breakpoints.down("xs")]: {
            fontSize: '10px', marginTop: 6,
        }
    },
    devicesTextActive2: {
        fontSize: '12px',
        textAlign: 'center',
        fontFamily: 'Work Sans',
        fontWeight: 600,
        marginTop: 6,
        color: 'white',
        [theme.breakpoints.down("xs")]: {
            fontSize: '8px', marginTop: 3,
           
        }
    },
    devicesText2: {
        fontSize: '14px',
        fontFamily: 'Work Sans',
        fontWeight: 400,
        color: '#626262',
        marginTop: 6,
        [theme.breakpoints.down("xs")]: {
            fontSize: '8px', marginTop: 3,
        }
    },


}))
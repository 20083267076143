import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    newcall: {
        fontFamily: 'Work Sans',
        color: '#838383',
        fontWeight: 500,
        fontSize: 15,
    },
    textfield: {
        marginTop: 50
    },
    iconNumbers: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        color: '#838383',
        fontSize: 18,
    },
    iconNumbersOne: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        color: '#838383',
        width: '40px',
        height: '40px',
        cursor:'pointer',
        borderRadius: '50px',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 20,
        padding: 2,
        '&:hover': {
            textDecoration: "none",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    number: {
        display: 'grid',
        /* align-items: center; */
        justifyContent: 'center',
        fontSize: '15px',
        marginTop: '2px',
        fontFamily: 'Work Sans',
        color: '#545454',
        fontWeight: 600
    },
    text: {
        marginTop: -8, fontSize: 10,
        fontFamily: 'Work Sans',
        color: '#999999'
    },
    profile: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        gap:8
    },
    userName: {
        marginLeft: 8,
        fontFamily: 'Work Sans',
        color: '#838383',
        fontSize: 14
    },
    callerId: {
        fontSize: 12,
        marginLeft: 40,
        fontFamily: 'Work Sans',
    },
    changeId: {
        fontSize: 13,
        marginLeft: 15,
        fontFamily: 'Work Sans',
        color: '#2645AD'
    }
}))
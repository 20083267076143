import { Card, CardContent, Typography, TextareaAutosize, Grid, Button, TextField, } from '@material-ui/core';
import { Headset, Block, AddBox } from '@material-ui/icons';
import React from 'react';
import { useStyles } from './styles';
import ReactSelet from 'react-select'
import Port from '../../../../assets/images/Port.png';
import usersNumbersServices from '../../userNumbersServices/usersNumbersServices';
import { useHistory } from 'react-router';
import moment from 'moment'
import DatePicker from "react-datepicker";
const serviceOptions = [
  { label: "D", value: 1 },
  { label: "B", value: 2 },
]

const options = [
  { label: "wireline", value: 1 },
  { label: "wireless", value: 2 }
]

function AddNewPortRequest(props: any) {
  const classes = useStyles();
  const [submitted, setSubmitted] = React.useState<any>(false);
  const [startDate, setStartDate] = React.useState<any>(new Date());
  const history = useHistory()
  const [portingData, setPortingData] = React.useState<any>(
    {
      phoneNumber: "",
      currentProviderName: "",
      serviceType: "",
      desireDueDate: Date.parse(startDate).toString(),
      autorizationDate: Date.parse(startDate).toString(),
      endUserName: "",
      existingAccountNumber: "",
      StreetNumber: "",
      streetName: "",
      city: "",
      province: "",
      zipCode: "",
      loosingCarrierComments: "",
      comments: ""
    }
  )

  const onChangeDateandTime = (date: any) => {
    // console.log(date)
    const Dateandtime = moment(date, 'YYYY.MM.DD').unix();
    const dt = Dateandtime * 1000
    setStartDate(dt);
    // setPortingData((prev: any) => {
    //   return {
    //     ...prev,
    //     autorizationDate: dt,
    //   }
    // })
  };

  const onChangeTextfield = (e: any) => {
    setPortingData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const onChangeServiceType = (data: any) => {
    console.log("-----service", data)
    setPortingData((prev: any) => {
      return {
        ...prev,
        serviceType: data.label,

      }
    })
  }
  const AddPortingDataData = () => {
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      usersNumbersServices.postPortingData(portingData)
        .then((res: any) => {

          if (res.success) {
            props.handlePopupModal();
            props.fetchDeskphoneList();
          } else {

          }
        })
        .catch((err: any) => {
          console.log(err);
          // send error
        });
    }
  }

  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      phoneNumber: { ...validData },
      currentProviderName: { ...validData },
      serviceType: { ...validData },
      endUserName: { ...validData },
      existingAccountNumber: { ...validData },
      StreetNumber: { ...validData },
      streetName: { ...validData },
      city: { ...validData },
      province: { ...validData },
      zipCode: { ...validData },
      loosingCarrierComments: { ...validData },
      comments: { ...validData }
    };
    let isValid = true;
    if (submitted) {
      if (!portingData.phoneNumber) {
        retData.phoneNumber = {
          isValid: false,
          message: "Please enter phone number"
        };
        isValid = false;
      }
      if (portingData.phoneNumber.length < 10 || portingData.phoneNumber.length > 15) {
        retData.phoneNumber = {
          isValid: false,
          message: "Please enter 10 to 15 digit phone number "
        };
        isValid = false;
      }
      if (!portingData.currentProviderName) {
        retData.currentProviderName = {
          isValid: false,
          message: "Please enter provider name"
        };
        isValid = false;
      }
      if (!portingData.serviceType) {
        retData.serviceType = {
          isValid: false,
          message: "Please select service type"
        };
        isValid = false;
      }
      if (!portingData.serviceType) {
        retData.serviceType = {
          isValid: false,
          message: "Please select service type"
        };
        isValid = false;
      }
      if (!portingData.endUserName) {
        retData.endUserName = {
          isValid: false,
          message: "Please enter end user name "
        };
        isValid = false;
      }
      if (!portingData.existingAccountNumber) {
        retData.existingAccountNumber = {
          isValid: false,
          message: "Please enter account number"
        };
        isValid = false;
      }
      if (!portingData.StreetNumber) {
        retData.StreetNumber = {
          isValid: false,
          message: "Please enter street number"
        };
        isValid = false;
      }
      if (!portingData.streetName) {
        retData.streetName = {
          isValid: false,
          message: "Please enter street name"
        };
        isValid = false;
      }
      if (!portingData.city) {
        retData.city = {
          isValid: false,
          message: "Please enter city "
        };
        isValid = false;
      }
      if (!portingData.province) {
        retData.province = {
          isValid: false,
          message: "Please enter province "
        };
        isValid = false;
      }
      if (!portingData.zipCode) {
        retData.zipCode = {
          isValid: false,
          message: "Please enter zip code"
        };
        isValid = false;
      }
      if (!portingData.loosingCarrierComments) {
        retData.loosingCarrierComments = {
          isValid: false,
          message: "Please enter carrier comments"
        };
        isValid = false;
      }
      if (!portingData.comments) {
        retData.comments = {
          isValid: false,
          message: "Please enter comments"
        };
        isValid = false;
      }
    }
    return {
      ...retData,
      isValid
    };
  };


  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const errors = validate(submitted);
  return (
    <div>
      <CardContent>
        <Card className={classes.modalCard}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                <Card className={classes.textCard}>
                  <img src={Port} alt="port" />
                  <div className={classes.portContent}>
                    <div>
                      <Typography className={classes.portText}>
                        Self-serve port requests are only available for US local number
                      </Typography>
                      <Typography className={classes.portText1}>
                        Contact support for Canadian,Hawalian and international numbers
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
            <CardContent>
              <Grid container spacing={5} style={{ marginTop: "10px" }}>
                <Grid item md={6} sm={6} xs={12}>
                  <Typography className={classes.textColorOne}>Phone Number</Typography>
                  <TextField
                    variant="outlined"
                    name="phoneNumber"
                    fullWidth size="small"
                    placeholder="Enter phone number"
                    onChange={onChangeTextfield}
                    error={!errors.phoneNumber.isValid}
                  />
                  {
                    !errors.phoneNumber.isValid &&
                    <p className={classes.errorMessage}>{errors.phoneNumber.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Current provider name</Typography>
                  <TextField
                    variant="outlined"
                    name="currentProviderName"
                    fullWidth size="small"
                    placeholder="Enter current provider name"
                    onChange={onChangeTextfield}
                    error={!errors.currentProviderName.isValid}
                  />
                  {
                    !errors.currentProviderName.isValid &&
                    <p className={classes.errorMessage}>{errors.currentProviderName.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Service Type</Typography>
                  <ReactSelet
                    placeholder="Select service type"
                    name="serviceType"
                    options={serviceOptions}
                    onChange={onChangeServiceType}
                    error={!errors.serviceType.isValid}
                  />
                  {
                    !errors.serviceType.isValid &&
                    <p className={classes.errorMessage}>{errors.serviceType.message}</p>
                  }
                  {/* <TextField
                    placeholder="Select service type"
                    name="serviceType"
                    onChange={onChangeTextfield} /> */}
                  <Typography className={classes.textColorOne}>Province/State</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth size="small"
                    name="province"
                    placeholder="Enter current province"
                    onChange={onChangeTextfield}
                    error={!errors.province.isValid}
                  />
                  {
                    !errors.province.isValid &&
                    <p className={classes.errorMessage}>{errors.province.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Desired Due Date</Typography>
                  <DatePicker
                    selected={startDate}
                    onChange={onChangeDateandTime}
                    //timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy"
                    showTimeInput
                    className={classes.inputdate}
                  />
                  {/* <TextField
                    variant="outlined"
                    name="desireDueDate"
                    fullWidth size="small"
                    type="date"
                    placeholder="Enter current province"
                    onChange={onChangeTextfield}
                  /> */}
                  <Typography className={classes.textColorOne}>Enter User Name</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth size="small"
                    name="endUserName"
                    placeholder="Enter user name"
                    onChange={onChangeTextfield}
                    error={!errors.endUserName.isValid}
                  />
                  {
                    !errors.endUserName.isValid &&
                    <p className={classes.errorMessage}>{errors.endUserName.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Losing Carrier Comments</Typography>
                  <TextareaAutosize
                    className={classes.textArea}
                    placeholder="Enter losing carrier comments"
                    name="loosingCarrierComments"
                    rowsMin={4}
                    rowsMax={5}
                    onChange={onChangeTextfield}
                  />
                  {
                    !errors.loosingCarrierComments.isValid &&
                    <p className={classes.errorMessage}>{errors.loosingCarrierComments.message}</p>
                  }
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Typography className={classes.textColorOne}>Street Number</Typography>
                  <TextField
                    variant="outlined"
                    name="StreetNumber"
                    fullWidth size="small"
                    placeholder="Enter street number"
                    onChange={onChangeTextfield}
                    error={!errors.StreetNumber.isValid}
                  />
                  {
                    !errors.StreetNumber.isValid &&
                    <p className={classes.errorMessage}>{errors.StreetNumber.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Street Name</Typography>
                  <TextField
                    variant="outlined"
                    name="streetName"
                    fullWidth size="small"
                    placeholder="Enter street name"
                    onChange={onChangeTextfield}
                    error={!errors.streetName.isValid}
                  />
                  {
                    !errors.streetName.isValid &&
                    <p className={classes.errorMessage}>{errors.streetName.message}</p>
                  }
                  <Typography className={classes.textColorOne}>City</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth size="small"
                    name="city"
                    placeholder="Enter city"
                    onChange={onChangeTextfield}
                    error={!errors.city.isValid}
                  />
                  {
                    !errors.city.isValid &&
                    <p className={classes.errorMessage}>{errors.city.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Postal Code</Typography>
                  <TextField
                    variant="outlined"
                    name="zipCode"
                    fullWidth size="small"
                    placeholder="Enter postal code"
                    onChange={onChangeTextfield}
                    error={!errors.zipCode.isValid}
                  />
                  {
                    !errors.zipCode.isValid &&
                    <p className={classes.errorMessage}>{errors.zipCode.message}</p>
                  }
                  <Typography className={classes.textColorOne}>Authorization Date</Typography>
                  <DatePicker
                    selected={startDate}
                    onChange={onChangeDateandTime}
                    //timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy"
                    showTimeInput
                    className={classes.inputdate}
                  />
                  {/* <TextField
                    variant="outlined"
                    fullWidth size="small"
                    name="autorizationDate"
                    type="date"
                    placeholder="Enter current province"
                    onChange={onChangeTextfield}
                  /> */}
                  <Typography className={classes.textColorOne}>Existing Account Number</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth size="small"
                    placeholder="Select an option"
                    name="existingAccountNumber"
                    onChange={onChangeTextfield}
                    error={!errors.existingAccountNumber.isValid}
                  />
                  {
                    !errors.existingAccountNumber.isValid &&
                    <p className={classes.errorMessage}>{errors.existingAccountNumber.message}</p>
                  }

                  <Typography className={classes.textColorOne}>Comments for iristel</Typography>
                  <TextareaAutosize
                    className={classes.textArea}
                    placeholder="Enter comments"
                    name="comments"
                    rowsMin={4}
                    rowsMax={5}
                    onChange={onChangeTextfield}
                  />
                  {
                    !errors.comments.isValid &&
                    <p className={classes.errorMessage}>{errors.comments.message}</p>
                  }
                </Grid>
              </Grid>
            </CardContent>
            <Grid item md={12} style={{ textAlign: 'center', margin: 15 }}>
              <Button
                variant="outlined"
                className={classes.createRequest}
                onClick={AddPortingDataData}
                startIcon={<AddBox color={'inherit'} />}>
                Create Request
              </Button>
              <Button
                variant="outlined"
                className={classes.cancelButton}
                startIcon={<Block />}
                onClick={() => history.push('/users')}
              >
                Cancel
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </div>
  )
}

export default AddNewPortRequest
import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Card, Divider, Grid, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useStyles } from './styles'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { BiEdit, } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ReactSelect from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal'
import TimeZone from './TimeZone'
import axiosInstance from '../../../environment/axiosInstance';
import { useSelector, useDispatch } from 'react-redux'
import departmentServices from '../DepartmentServices/DepartmentServices'
import * as DepartmentActionCreators from '../../../store/Actions/DepartmentsActionXCreators'
import { useParams } from "react-router-dom";
import './style.css'
import TimeZoneNew from './TimeZoneNew'

const weekDays = [
    {
        day: 'Sunday'
    },
    {
        day: 'Monday'
    },
    {
        day: 'Tuesday'
    },
    {
        day: 'Wednesday'
    },
    {
        day: 'Thursday'
    },
    {
        day: 'Friday'
    },
    {
        day: 'Saturday'
    },
]

const weekDaystimingsData = [
    {
        "close": "",
        "day": "Monday",
        "open": ""
    },
    {
        "close": "",
        "day": "Tuesday",
        "open": ""
    },
    {
        "close": "",
        "day": "Wednesday",
        "open": ""
    },
    {
        "close": "",
        "day": "Thursday",
        "open": ""
    },
    {
        "close": "",
        "day": "Friday",
        "open": ""
    },
    {
        "close": "",
        "day": "Saturday",
        "open": ""
    },
    {
        "close": "",
        "day": "Sunday",
        "open": ""
    }
]

const weekDaystimingsDataNew = [
    {
        "0": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    },
    {
        "1": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    },
    {
        "2": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    },
    {
        "3": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    },
    {
        "4": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    },
    {
        "5": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    },
    {
        "6": [
            {
                "startTime": "",
                "endTime": ""
            }
        ]
    }
]

function Schedule(props: any) {
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState<any>(true);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [Loading, setloading] = React.useState(false);
    const [citiess, setCitiess] = React.useState<any>([]);
    const [timezoneValue, setTimeZoneValue] = React.useState<any>('');
    const [location, setLocation] = React.useState<any>({ label: '', value: '' });
    const departmetStateData = useSelector((state: any) => state?.departmentModule);
    const { selectedDepartment, loading } = departmetStateData;
    const [selectAlwaysOpen, setselectAlwaysOpen] = React.useState(false);
    const [specificHours, setSpecificHours] = React.useState(false);
    const [weekDaysArray, setWeekDaysArray] = React.useState<any>([]);
    const [weekDaysBackup, setWeekDaysBackup] = React.useState<any>([]);
    const [weekTImingSaved, setWeekTImeSaved] = React.useState(false)
    // const [weekdaysTimings1, setWeekDaysTimings1] = React.useState<any>(weekDaystimingsData)
    const [weekdaysTimings1, setWeekDaysTimings1] = React.useState<any>(weekDaystimingsDataNew)
    const [scheduleData, setScheduleData] = React.useState<any>([])

    const expandOpen = () => {
        setOpen(!open);
        setExpanded(!expanded);
    }
    const handlePopupModal = () => {
        if (!openModal == false) {
            getShedule();
        }
        setOpenModal(!openModal);
        // alert(`yyy${weekTImingSaved}`)
        if (weekTImingSaved === false) {
            // setWeekDaysTimings1(weekDaysBackup)
            //console.log('ttt', weekTImingSaved, weekDaysBackup)
        }
    };
    const onchangeDataSaved = (status: any) => {
        setWeekTImeSaved(status)
    }


    const onChangeWeekDaysTimingsData = (data: any) => {
        setWeekDaysTimings1(data)
    }
    const getShedule = () => {
        axiosInstance.get(`departments/${props.match.params.id}/schedule`).then((res: any) => {
            if (res.status === 200) {
                setselectAlwaysOpen(res.data.data[0].alwaysOpen)
                setScheduleData(res.data.data)
                if (res.data.data[0].days !== null && Array.isArray(res.data.data[0].days)) {
                    // const backUp: any = res.data.data[0].days.length > 0 ? res.data.data[0].days : weekDaystimingsData
                    const backUp: any = res.data.data[0].slots?.length > 0 ? res.data.data[0].slots : weekDaystimingsDataNew
                    setWeekDaysBackup([...backUp])
                    // setWeekDaysTimings1(res.data.data[0].days.length > 0 ? res.data.data[0].days : weekDaystimingsData)
                    setWeekDaysTimings1(res.data.data[0].slots?.length > 0 ? res.data.data[0].slots : weekDaystimingsDataNew)
                }
                //setselectAlwaysOpen(res.data.data[0].alwaysOpen)
                //setSpecificHours(res.data.data[0].alwaysOpen)
                setTimeZoneValue(res.data.data[0].timezone)

            }
        })
    }

    React.useEffect(() => {
        getShedule()
    }, [])

    // const onChangeWeekdayTimings = (data: any, event: any) => {
    //     const dayData: any = event.name.split("_");

    //     setWeekDaysTimings((prev: any) => {

    //         let oldState: any = prev;
    //         oldState[dayData[0]][dayData[1]] = data
    //         return {
    //             ...prev,
    //             ...oldState
    //         };
    //     })
    //     let copyWeekData: any = weekDaysArray;
    //     copyWeekData.forEach((Daydata: any) => {
    //         if (Daydata.day === dayData[0]) {
    //             Daydata[dayData[1]] = data.value;
    //         }
    //     });


    //     setWeekDaysArray(copyWeekData);
    //     updatedDepartmentSchedule(props.match.params.id, { days: copyWeekData });
    // };




    //this api call for updating schedule switch
    const updatedDepartmentSchedule = (id: any, data: any, statuskey: any) => {
        departmentServices.updateDepartmentsData(id, { ...data })
            .then((res: any) => {
                if (res.success) {
                    onchangeDataSaved(true)
                    getShedule()
                    if (statuskey === 'days') {
                        handlePopupModal()

                    }
                } else {
                    //send error
                }
            })
            .catch((err: any) => {
                //send error
            });
    };

    const updateSlotsData = (id: any, data: any, statuskey: any) => {
        departmentServices.updateSlotsData(id, { ...data })
            .then((res: any) => {
                if (res.success) {
                    onchangeDataSaved(true)
                    getShedule()
                    if (statuskey === 'days') {
                        handlePopupModal()

                    }
                } else {
                    //send error
                }
            })
            .catch((err: any) => {
                //send error
            });
    };

    const alwaysOpenChange = () => {
        updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen, }, 'alwaysopen')
        setselectAlwaysOpen(!selectAlwaysOpen);
        setSpecificHours(!specificHours);
        //updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen })
        //props.parentCallback(!selectAlwaysOpen)
    };

    const specificHoursChange = () => {
        updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen }, 'alwaysopen')
        setselectAlwaysOpen(!selectAlwaysOpen);
        setSpecificHours(!specificHours);
    }

    // const specificHoursChange = () => {
    //     setSpecificHours(!specificHours);
    //     setselectAlwaysOpen(!selectAlwaysOpen);
    //     // updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen,days:weekdaysTimings1 }, 'alwaysopen')

    //     // props.parentCallback(selectAlwaysOpen)
    //     //updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen })
    //     // props.parentCallback(!selectAlwaysOpen)
    // };

    const handleInputlocationOnchange = (data: any) => {
        // setLocation(data);
        setTimeZoneValue(data.value)
        updatedDepartmentSchedule(props.match.params.id, { timezone: data?.value }, 'location')
    };

    React.useEffect(() => {
        if (props.match.params.id) {
            //setselectAlwaysOpen(props.match.params.id?.alwaysOpen)
            //   props.parentCallback(selectedDepartment?.alwaysOpen)
            // setSpecificHours(!selectedDepartment?.alwaysOpen)
            // const citesFilter = citiess.find((cit: any) => cit.value === props.match.params.id?.timezone)
            // setLocation(citesFilter)
            // setTimeZoneValue(props.match.params.id?.timezone)



            //   const daysData: any = selectedDepartment?.days;
            //   if (daysData.length > 0) {
            //     setWeekDaysArray(daysData);
            //     let stateWeekDay: any = weekDaysTimings;

            //     daysData.forEach((dayData: any) => {
            //       stateWeekDay[dayData.day].open = {
            //         value: dayData.open,
            //         label: dayData.open,
            //       };
            //       stateWeekDay[dayData.day].close = {
            //         value: dayData.close,
            //         label: dayData.close,
            //       };
            //     });

            //     setWeekDaysTimings(stateWeekDay);

            //   } else {
            //     setWeekDaysArray(daysDataHardcoded);

            //     let stateWeekDay: any = weekDaysTimings;

            //     daysDataHardcoded.forEach((dayData: any) => {
            //       stateWeekDay[dayData.day].open = {
            //         value: dayData.open,
            //         label: dayData.open,
            //       };
            //       stateWeekDay[dayData.day].close = {
            //         value: dayData.close,
            //         label: dayData.close,
            //       };
            //     });

            //     setWeekDaysTimings(stateWeekDay);
            //   }

        }
    }, [props.match.params.id])

    //timeZone
    const timezoneCities = React.useCallback(() => {
        setloading(true);
        axiosInstance.get('/timezones').then((res: any) => {
            if (res.data.data) {
                const data = res.data.data
                var List: any = [];
                data.map((item: any) => {
                    var object = {
                        "value": item.timezone,
                        "label": item.timezone
                    }
                    List.push(object)
                })
                setCitiess(List)
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            setloading(false)
        })
    }, [])

    React.useEffect(() => {
        timezoneCities()
    }, [])





    const rangeCalculater = (date: any, index: any) => {
        // console.log('first', date, index)
        if (date === undefined || date === null) {
            return false;
        }

        console.log("date.startTime here && date.endTime", date.startTime + " - " + date.endTime)
        const startRange =
            date.startTime !== "" ?
                Number((date?.startTime !== undefined ?
                    ("0" + Math.floor(date?.startTime / 100)).slice(-2) + ":" + ("0" + (date?.startTime % 100)).slice(-2)
                    :
                    "")?.split(':')[0])
                :
                null;

        const endRange =
            date.endTime !== "" ?
                Number((date?.endTime !== undefined ?
                    ("0" + Math.floor(date?.endTime / 100)).slice(-2) + ":" + ("0" + (date?.endTime % 100)).slice(-2)
                    :
                    "")?.split(':')[0])
                :
                null;

        if (startRange === null || endRange === null) {
            return false;
        }
        const inbtwnRange = (index >= startRange && index <= endRange) ? true : false;

        // if (date?.startTime == "0000") {
        //     console.log("slot here", date);
        //     console.log("startRange - endRange", date?.startRange + " - " + date?.endRange)
        // }
        return {
            inbtwnRange: inbtwnRange,
            startIndex: {
                index: startRange,
                value: date?.startTime
            },
            endIndex: {
                index: endRange,
                value: date?.endTime
            }
        };
    };


    return (
        <div>
            <div className={classes.padding}>
                {
                    openModal && (
                        <div >
                            <Backdrop handlePopupModal={handlePopupModal} />
                            <Modal title="TimeZone" handlePopupModal={handlePopupModal} >
                                <div style={{ width: 850 }}>
                                    <TimeZone
                                        selectAlwaysOpen={selectAlwaysOpen}
                                        getShedule={getShedule}
                                        scheduleData={scheduleData}
                                        deptId={props.match.params.id}
                                        handlePopupModal={handlePopupModal}
                                        onchangeDataSaved={onchangeDataSaved}
                                        onChangeWeekDaysTimingsData={onChangeWeekDaysTimingsData}
                                        daysData={weekdaysTimings1}
                                        updateSlotsData={updateSlotsData}
                                    />
                                    {/* <TimeZoneNew/> */}
                                </div>
                            </Modal>
                        </div>
                    )
                }
            </div>

            <Card className={classes.card}>
                <Accordion
                    expanded={expanded}
                    onChange={expandOpen}
                >
                    <AccordionSummary
                        expandIcon={expanded ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.dflex}>
                            <Typography className={classes.heading}>eFone Number Schedule</Typography>
                        </div>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <div className={classes.dflex}>
                        <Typography className={classes.callFlow}>Select Business Hours</Typography>
                    </div>
                    <div className={classes.dflexCheck} style={{ marginLeft: 16, marginBottom: 20 }}>
                        <FormControlLabel
                            control={<Checkbox
                                //name="selectAlwaysOpen"
                                checked={selectAlwaysOpen}
                                size='small'
                                //onChange={alwaysOpenChange}
                                onChange={specificHoursChange}
                            />
                            }
                            label="24/7"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={!selectAlwaysOpen}
                                size='small'
                                onChange={alwaysOpenChange}
                            />
                            }
                            label="Specific working hours" />
                    </div>
                    <div style={{ display: 'flex', gap: 15, alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                        <Typography className={classes.headings}>Select TimeZone</Typography>
                        <Grid item md={4} >
                            <ReactSelect
                                options={citiess}
                                name="timezone"
                                value={
                                    citiess.filter((option: any) =>
                                        option.value === timezoneValue)
                                }
                                onChange={handleInputlocationOnchange}
                            />
                        </Grid>
                    </div>
                    <Divider style={{ marginBottom: 30 }} />
                    {/* {!selectAlwaysOpen &&
                        <>
                            <Divider style={{ marginTop: 20 }} />
                            <div style={{ padding: 16 }}>
                                <Card className={classes.scheduleCard}>
                                    {
                                        weekDays.map((weekDay: any, index: any) => (
                                            <div onClick={handlePopupModal} key={index}>
                                                <Typography>{weekDay.day}</Typography>
                                                <div className={classes.divWdth}>
                                                    {
                                                        weekdaysTimings1[index][index]?.map((slot: any, j: any) => {
                                                            return new Array(24).fill('').map((item: any, i: any) => {
                                                                const inbtwn: any = rangeCalculater(
                                                                    slot, i);
                                                                const cls = "hour" + " " + `${inbtwn.inbtwnRange ? `active ${(i === 0) && 'start_ele'} ${i === 23 && 'end_ele'}` : ""}`;
                                                                return (
                                                                    <>
                                                                        <div className={cls} style={{ margin: 0, height: 10 }}>
                                                                            {inbtwn?.startIndex?.index === i &&
                                                                                <span style={{
                                                                                    color: 'white', fontSize: 11, display: 'grid', justifyContent: 'center'
                                                                                }}>
                                                                                    {(inbtwn?.startIndex.value !== undefined ? ("0" + Math.floor(inbtwn?.startIndex.value / 100)).slice(-2) + ":" + ("0" + (inbtwn?.startIndex.value % 100)).slice(-2) : "")}{i <= 12 ? ' AM' : ' PM'}
                                                                                </span>
                                                                            }
                                                                            {inbtwn?.endIndex?.index === i &&
                                                                                <span style={{
                                                                                    color: 'white',
                                                                                    fontSize: 11,
                                                                                    display: 'grid',
                                                                                    justifyContent: 'center'
                                                                                }}>
                                                                                    {(inbtwn?.endIndex.value !== undefined ? ("0" + Math.floor(inbtwn?.endIndex.value / 100)).slice(-2) + ":" + ("0" + (inbtwn?.endIndex.value % 100)).slice(-2) : "")}{i <= 12 ? ' AM' : ' PM'}
                                                                                </span>}
                                                                        </div>


                                                                    </>
                                                                )
                                                            })

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Card>
                            </div>
                        </>
                    } */}
                    {!selectAlwaysOpen &&
                        <TimeZone
                            selectAlwaysOpen={selectAlwaysOpen}
                            getShedule={getShedule}
                            scheduleData={scheduleData}
                            deptId={props.match.params.id}
                            handlePopupModal={handlePopupModal}
                            onchangeDataSaved={onchangeDataSaved}
                            onChangeWeekDaysTimingsData={onChangeWeekDaysTimingsData}
                            daysData={weekdaysTimings1}
                            updateSlotsData={updateSlotsData}
                        />
                    }
                </Accordion>
            </Card>
        </div>
    )
}

export default Schedule
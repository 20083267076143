import React from 'react'
import { useStyles } from './styles'
import microphone from '../../../../assets/images/mic.png'
import edit from '../../../../assets/images/scissors 2.png'
import deleteicon from '../../../../assets/images/deleteicon.png'
import uploadimg from '../../../../assets/images/uploadimg.png';
import { Box, Divider, Grid, TextField } from '@material-ui/core'
import Button from '@mui/material/Button';
import ReactSelect from 'react-select'
import CircularProgress from '@mui/material/CircularProgress';
import { width } from '@mui/system'
import axiosInstance from '../../../../environment/axiosInstance'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'

let gumStream: any = null;
let recorder: any = null;
let audioContext: any = null;

function RecordAudio(props: any) {
  const classes = useStyles()
  const [fileBuffer, setFileBuffer] = React.useState('')
  const [source, setSource] = React.useState<any>('')
  const [fileName, setFileName] = React.useState('')
  const audioFileInput: any = React.createRef();
  const [filesList, setFilesList] = React.useState<any>();
  const [loading, SetLoading] = React.useState<any>(false)
  const [deleteModal, setdeleteModal] = React.useState<any>(false);
  const [audioId, setAudioId] = React.useState<any>()

  const handleDeleteAudio = (id: any) => {
    setAudioId(id)
    setdeleteModal(!deleteModal);
  };
  const handleupdateAudio = (id: any) => {
    console.log(id)
    setAudioId(id)
  }
  const onChangeAudioFile = (e: any) => {
    const fileData = e.target.files;
    var file: any = fileData[0];
    console.log('file', file)

    // setFile(file)
    setFileBuffer(file)
    let reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setSource(reader.result)
    };

    setFileName(fileData[0]?.name)
    // onPostDataToServer(file, fileData[0]?.name)

  }

  const uploadFileClick = () => {
    // setUploadingFile(true)
    if (audioFileInput.current) {
      audioFileInput.current.click()
    }
  }

  const onchangeFilename = ((e: any) => {
    setFileName(e.target.value)
  })



  const onClickSaveFile = async () => {
    let formData = new FormData()
    formData.append('fileName', fileName)
    formData.append('file', fileBuffer)
    formData.append('fileType', '1')
    // formData.append('soundType', 'IvrGreeting')
    // formData.append('hoursType', '1')
    // formData.append('departmentId', depId)
    SetLoading(true)
    await axiosInstance.post('/profile/settings/un-answered-call/sound/voice-mail-greeting', formData).then((res: any) => {
      if (res.status === 200) {
        setFileName('')
        setSource('')
        SetLoading(false)
        props.getUnanswereddata()
      }
    }).catch((err: any) => {
      SetLoading(false)
    })
  }

  const onClickdeleteAudio = (id: any) => {
    const depId = props.depId
    SetLoading(true)
    axiosInstance.delete(`/profile/settings/un-answered-call/sound/voice-mail-greeting?id=${id}`).then((res: any) => {
      console.log(res, "res")
      if (res.status === 200) {
        setdeleteModal(!deleteModal);
        SetLoading(false)
        props.getUnanswereddata()
      }
    })
  }

  const updateSelectedAudio = (id:any) => {
    const obj: any = {
      id: id
    }
    SetLoading(true)
    axiosInstance.patch('/profile/settings/un-answered-call/sound/voice-mail-greeting', obj).then((res: any) => {
      if (res.status === 200) {
        props.getUnanswereddata()
        SetLoading(false)
      }
    })
  }

  React.useEffect(() => {
    setFilesList(props.unansweredData?.unAnsweredCalls?.voicemail?.voicemailAudio)
  }, [props.unansweredData])


  //----------- Trail 1 --------------- //
  const [stream, setStream] = React.useState<any>({
    access: false,
    recorder: null,
    error: ""
  });

  const [recording, setRecording] = React.useState<any>({
    active: false,
    available: false,
    url: "",
    name: ""
  });

  const recordFile = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const [recordingTimer, SetRecordingTimer] = React.useState<any>(0)
  let timerIntervelRef: any = null
  const chunks: any = React.useRef([]);

  const onclickSaveRecording = async () => {
    let file: any = new File([recording.url], 'test.mp3', {
      type: "audio/mp3",
    });
    const formData = new FormData();
    formData.append('fileName', 'Test 06-11-22 7:23')
    formData.append('file', file)
    formData.append('fileType', '1')

    SetLoading(true)
    await axiosInstance.post('/profile/settings/un-answered-call/sound/voice-mail-greeting', formData).then((res: any) => {
      if (res.status === 200) {
        setFileName('')
        setSource('')
        SetLoading(false)
        props.getUnanswereddata()
      }
    }).catch((err: any) => {
      SetLoading(false)
    })


  }

  function getAccess() {
    // alert('testS')
    // timerIntervelRef = setInterval(() => {
    //   SetRecordingTimer(recordingTimer+1)
    //   console.log('timertim')
    // },1000)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mic: any) => {
        let mediaRecorder: any;

        try {
          mediaRecorder = new MediaRecorder(mic, {
            mimeType: "audio/webm"
          });
        } catch (err) {
          console.log(err);
        }

        const track = mediaRecorder.stream.getTracks()[0];
        track.onended = () => console.log("ended");

        mediaRecorder.onstart = function () {
          console.log('start recording')
          setRecording({
            active: true,
            available: false,
            url: ""
          });
        };
        mediaRecorder.ondataavailable = function (e1: any) {
          console.log("data available");
          chunks.current.push(e1.data);
        };
        mediaRecorder.onstop = async function () {
          console.log("stopped", recording);
          // clearInterval(timerIntervelRef)
          const url = URL.createObjectURL(chunks.current[0]);
          chunks.current = [];


          setRecording({
            active: false,
            available: true,
            url
          });
        };

        setStream({
          ...stream,
          access: true,
          recorder: mediaRecorder
        });

        // console.log('tttt__',  mediaRecorder.requestData())
      })
      .catch((error) => {
        console.log(error);
        setStream({ ...stream, error });
      });
  }




  React.useEffect(() => {
    getAccess()
  }, [])


  return (

    <div>
      {
        deleteModal && (
          <div>

            <Backdrop handlePopupModal={handleDeleteAudio} />
            <Modal title="Are you sure want to Delete this Audio ?" handlePopupModal={handleDeleteAudio} >
              <div className="add_user_popup" style={{ width: "500px" }}>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                    <div style={{ margin: '10px' }}>
                      <Button
                        style={{ background: '#4D72F8', padding: '6px 22px', color: 'white', borderRadius: '5px', fontSize: '14px' }}
                        onClick={() => onClickdeleteAudio(audioId)} >Delete</Button>
                      <Button size="small"
                        style={{ fontSize: 14, color: '#787878', padding: '6px 22px', borderRadius: '5px', border: '1px solid #AEAEAE', marginLeft: 15 }}
                        onClick={handleDeleteAudio} >Cancel
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </div>
        )
      }

      <div>
        {/* <button
            // className={recording.active ? "active" : null}
            onClick={startRecording}
          >
            Start Recording
          </button>
          <button onClick={stopRecording}>Stop Recording</button> */}
        <div className={classes.dFlex}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)', width: '48px', height: '48px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {recording.active === false && <img onClick={() => !recording.active && stream.recorder.start()} src={microphone} alt="microphone" width="25px" height="35px" style={{ color: '#FFFFFF' }} />}
              {recording.active && <button onClick={() => stream.recorder.stop()}>Stop Recording</button>}

            </div>
            <p className={classes.minutes}>{recordingTimer}</p>
          </div>
        </div>
      </div>


      <div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className={classes.uploadContainer}
            onClick={uploadFileClick}>
            <div>
              <img src={uploadimg} alt="microphone" style={{ color: '#FFFFFF' }} />
            </div>
            <p className={classes.minutes}>
              <span style={{ color: '#4D72F8', fontWeight: 700 }}>Browse</span> your file
            </p>
            <div style={{ display: 'none' }}>
              <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <audio src={recording.url} controls style={{ width: '432px' }}></audio>
            <img src={edit} alt="editaudio" style={{ marginLeft: '15px' }} />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '50px' }}>
          <div>
            <TextField
              value={fileName}
              onChange={onchangeFilename}
              placeholder='Enter File Name'
              size="small"
              variant="outlined"
              style={{ width: '237px', marginRight: '10px', color: '#787878' }}
            />
            <Button variant="contained" className={classes.saveBtn} onClick={onclickSaveRecording}>Save</Button>
          </div>
        </div>
      </div>
      <Divider style={{ border: '1px solid #E2E2E2', width: '100%' }} />
      <div style={{ marginTop: '35px', marginLeft: '15px' }}>
        {
          loading ?
            <Box className={classes.dFlex}>
              <CircularProgress />
            </Box>
            :
            <div>
              {
                filesList?.map((item: any, id: any) =>
                (
                  <>
                    {
                      item.fileType === 1 &&
                      <div style={{ display: 'flex' }}>
                        <div onClick={() => updateSelectedAudio(item._id)}>
                          <div style={{ marginBottom: '15px', cursor: 'pointer' }}>
                            <div className={item.isSelected === true ? classes.selected : classes.unSelected} >
                              <audio style={{ marginLeft: 20, marginRight: 20, }} src={item.path} controls
                                className={item.isSelected === true ? classes.audioTrackActive : classes.audioTrack}></audio>
                            </div>
                          </div>
                          <span className={classes.filename}>{item.fileName}</span>
                        </div>
                        <div
                          className={item.isSelected === true ? classes.selectedd : classes.unSelectedd}
                          style={{ display: 'grid', alignItems: 'center' }}>
                          <img src={deleteicon} alt="deleteaudio" style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteAudio(item._id)} />
                        </div>

                      </div>
                    }
                  </>

                ))
              }
              {/* <div className={classes.dFlex}>
                <Button variant='contained' onClick={updateSelectedAudio}
                  className={classes.saveAsdefaultBtn}>Save as Default</Button>
              </div> */}
            </div>
        }

      </div>
    </div>
  )
}

export default RecordAudio
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        deltecancelButton: {
            // margin: 10,
            padding: '6px 22px',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            color: '#787878',
            fontSize: 14,
        },
        deleteButton: {
            // margin: 10,
            padding: '6px 22px',
            background: '#4D72F8',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#FFFFFF',
            fontSize: 14,
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            }
        },
        bflex: {
        
             "@media (max-width: 768px)":{
                marginTop: 8
            }
        },
        asigngridItem: {
            //display: 'flex',
            alignItems: 'center',
            width: '-webkit-fill-available',
            justifyContent: 'flex-start',
        },
        mtop: {
            "@media (max-width: 768px)":{
                marginTop: 8
            }
        },
        divider: {
            // margin:10,
            backgroundColor: '#E2E2E2',
        },

        button: {
            margin: 20,
            marginLeft: '310px',
            // padding:"6px 25px",
            fontSize: 15.3861,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            letterSpacing: 0.208955,
            textTransform: "none",
            textAlign: "center",
            borderRadius: 25,
            color: "#4D72F8",
            backgroundColor: "#FFFFFF",
            borderColor: "#4D72F8",
            "&:hover": {
                backgroundColor: "#FFFFFF",
            },
            "&:active": {
                backgroundColor: "#FFFFFF",
            },
        },
        buttonsdiv: {
            marginTop: '21px',
            marginLeft: '15px',

        },

        SaveButton: {
            margin: 10,
            width: "114px",
            background: '#4D72F8',
            border: '1px solid #999999',
            boxSizing: 'border-box',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            color: "#FFFFFF",

            fontSize: 14,
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            },
        },
        text: {
            marginTop: "10px",
        },
        textDropdown:{

        },
        user:{

        },
        departmentNameText: {
            fontSize: 16,
            //marginRight: 20,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            color: "#464646",
            letterSpacing: 0.1,
            textTransform: "none",
            // marginTop: 5,
            // marginLeft: 7,
        },
        cancelButton: {
            margin: 10,
            width: '114px',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            color: '#787878',
            fontSize: 14,
        },




    }))
import React from 'react'
import { Accordion, Button, Tooltip, Typography } from '@material-ui/core'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Link, useHistory, withRouter, NavLink, useParams } from 'react-router-dom'
import { MdOutlineExpandMore, MdOutlineExpandLess } from 'react-icons/md'
import DepartmentServices from '../../Departments/DepartmentServices/DepartmentServices'
import * as DepartmentActionCreators from '../../../store/Actions/DepartmentsActionXCreators'
import './sidebarelement.css'
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { BiMessageSquareAdd } from 'react-icons/bi'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import { useStyles } from './style'

import {
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import SidebarChildren from '../../SidebarChildren/SidebarChildren';
import { display } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux'
import { GiConsoleController } from 'react-icons/gi'
import AddDepartment from './AddDepartment/AddDepartment'

const departmentTabs: any = ["Schedule", "Holidays", "Open Hours", "Close Hours", "Advanced Setting", "Call History"]

function SidebarElementNew(props: any) {
    const { title, path, Icon, color, IconSize, childTitle, routes } = props;
    const [addDepartment, setAddDepartment] = React.useState(false);
    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment, departmentsData, loading } = departmentStateData;
    const [deptName, setDeptName] = React.useState<any>('')
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = React.useState(false)
    const [childOpen, setChildOpen] = React.useState(false);
    const [childOpen1, setChildOpen1] = React.useState('');
    const [id, setId] = React.useState('');
    // const [childActive,setChildActive] = React.useState()
    const [department, setDepartment] = React.useState<any>({
        name: " "
    })
    const history = useHistory()
    const { location, push } = useHistory();
    const currentLocationName = location.pathname.split('/')[1]
    //console.log('location__', currentLocationName)
    const params = useParams();
    // console.log('params__', params, location, history, useParams())
    const handleClick = () => {
        setOpen(!open);
        push(path)
        // props.handlePopupModal()
    };

    const handleChildClick = (path: any, id: any) => {
        setChildOpen(!childOpen)
        push(path)
        setId(id)

    }
    const handleChildClick1 = (id: string, path: any) => {
        // console.log('id___', id)
        setChildOpen1(id)
        localStorage.setItem('activeChildId', id)
        push(path)
        // props.handlePopupModal()

    }
    React.useEffect(() => {
        let value: any = localStorage.getItem('activeChildId')
        // console.log("activeChildId", value)
        setChildOpen1(value)
    }, [])
    // React.useEffect(() => {
    //     if(routes.length > 0){
    //         setChildOpen1(routes[0].id)
    //     }
    // },[routes])

    const handlesubChildClick = (path: any) => {
        // console.log(path)
        push(path)
    }
    const classes = useStyles()
    const historyPath = () => {
        if (title === 'Department') {
            // if (selectedDepartment?.name === undefined) {
            //     history.push(`/department/${deptName}`)
            // }
            if (selectedDepartment) {
                history.push(`/department/${selectedDepartment?.name}`)
            }
            history.push(`/department`)
        }
        else {
            // history.push(path)
        }
    }

    // console.log("childOpen", childOpen)
    // React.useEffect(() => {
    //     // var pathname = JSON.stringify(location.pathname)
    //     // var data = pathname.replace('/department/', '');
    //     // var path = data.replace('"', '')
    //     // var restaurant_id = path.replace('"', '')
    //     if (location.pathname.includes('/department')) {
    //         history.push(`/department`)
    //     }
    // }, [])

    React.useEffect(() => {
        if (selectedDepartment) {
            setDepartment({ name: selectedDepartment?.name ? selectedDepartment?.name : "" })
        }
    }, [selectedDepartment])

    // const paths = path === location.pathname ? "sidebar_element_section_active" : "sidebar_element_section"

    const clickEvent = (path: any) => {
        history.push(path)
        // setChildOpen(!childOpen)

    }

    React.useEffect(() => {
        // console.log('loc__', currentLocationName,title, path)
        const matchPath = currentLocationName.toLowerCase().trim() === path.split('/')[1].toLowerCase().trim();
        setOpen(matchPath)
        //console.log('gggg__',matchPath,currentLocationName.toLowerCase().trim(),path.split('/')[1].toLowerCase().trim())
    }, [currentLocationName])

    const tabName = path.split("/")[1];

    const handleAddDepartmeny = () => {
        setAddDepartment(!addDepartment);
    }

    const depList = props.fetchDepartments

    return (
        <div>
            {
                addDepartment && (
                    <div>
                        <Backdrop handlePopupModal={handleAddDepartmeny} />
                        <Modal title="Add Department" handlePopupModal={handleAddDepartmeny} >
                            <div className="add_user_popup" style={{ width: 680, height: 220 }}>
                                <AddDepartment
                                    handlePopupModal={handleAddDepartmeny}
                                    fetchDepartments={depList}
                                // selecteduserId={selecteduserId}
                                // selectedDeparmentList={selectedDeparmentList}
                                // fetchUserLists={fetchUsersList}
                                />
                            </div>
                        </Modal>
                    </div>
                )

            }
            <div className={classes.sidebarItemContainer}>
                <ListItem button onClick={handleClick} >
                    <div style={{ display: 'grid', placeItems: 'center', marginRight: 10 }}>
                        {/* <Icon style={{ width: "21px", height: "21px" }} /> */}
                        <img src={Icon} style={{ width: "16px", height: "16px" }} />
                    </div>
                    <ListItemText primary={title} className='sidebar_list_item' />
                    {(open && routes.length > 0) ? <ExpandMoreIcon style={{ color: '#848282', width: '18px', height: '18px' }} /> : <ExpandLessIcon style={{ color: '#848282', width: '18px', height: '18px' }} />}
                </ListItem>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 0 }}>

                    <div className='sidebar_collapse_empty'>
                    </div>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div>
                            {
                                routes.map((route: any, i:any) => {
                                    let pathId = location.pathname.split('/')[2];
                                    let tabNAme = location.pathname.split('/')[1];
                                    let childActive: any = '';
                                    if (tabNAme === 'departments') {
                                        childActive = route.id === pathId
                                    } else {
                                        childActive = route.path == location.pathname;
                                    }
                                    // let childActive = (route.id && route.id !== undefined) === (pathId && pathId !== undefined)
                                    // let childActive = route.path == location.pathname;
                                    //console.log('test___', route.id ,location.pathname.split('/')[1], location.pathname.split('/')[2] )
                                    return (
                                        <div key={i}>
                                            <ListItem
                                                onClick={() => handleChildClick1(route.id, route.path)}
                                                className={`sidebar_submenu_text_container ${childActive && "sidebarActive"}`}
                                                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10 }}
                                            >
                                                <ListItemText style={{ cursor: "pointer" }} primary={route.title} />

                                            </ListItem>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div className='sidebar_collapse_empty_2'>
                                                </div>
                                                {/* <Collapse in={childOpen} timeout="auto" unmountOnExit>
                                                <div>
                                                    {
                                                        route.childRoutes?.map((route: any) => {
                                                            const childActive = location.pathname === route.path;

                                                            return (
                                                                <ListItem onClick={()=>handlesubChildClick(route.path)}className={`sub_text_container ${childActive && "sidebarActive"}`}>
                                                                    <ListItemText primary={route.title} className='submenutext' />
                                                                </ListItem>
                                                            )
                                                        })

                                                    }
                                                </div>
                                            </Collapse> */}
                                                {/* {childOpen && childActive &&  */}
                                                {/* <Collapse in={open} timeout="auto" unmountOnExit>
                                                    {route.childRoutes?.length > 0 &&
                                                        <ul style={{ listStyleType: 'none', marginLeft: -20, lineHeight: 2, marginTop: 0 }}>
                                                            {
                                                                route.childRoutes.map((item: any) => {
                                                                    console.log('item', item)
                                                                    return (
                                                                        <li>
                                                                            <Link to={item.path} style={{ textDecoration: 'none' }}>
                                                                                {item.title}
                                                                            </Link>

                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </Collapse> */}
                                                {/* } */}
                                                {/* {childOpen && route.id === id && */}
                                                <Collapse in={childOpen1 === route.id} timeout="auto" unmountOnExit style={{ width: '70%' }}
                                                //expanded={location.pathname  === `/department/${route?.title}` ? true : false}
                                                //onChange={() => onChangeDeaptemntTab(route?.title)}
                                                >
                                                    <div>
                                                        {route.childRoutes?.length > 0 &&
                                                            <div className='department_subitem_container'>

                                                                {
                                                                    route.childRoutes?.map((childroute: any,i:any, id: any) => {
                                                                        // console.log(childroute)
                                                                        const subchildActive = childroute.path === location.pathname
                                                                        // console.log("subchildActive",subchildActive)
                                                                        return (
                                                                            <NavLink key={i} to={childroute.path} onClick={props.handlePopupModal} className='department_subitem' activeClassName='subchild_Active'>
                                                                                {/* <p style={{margin: '0px'}} className={`department_subitem ${subchildActive && "subchild_Active"}`}  key={id}> */}
                                                                                {childroute.title}
                                                                                {/* </p> */}
                                                                            </NavLink>

                                                                            // <li className={`department_subitem ${subchildActive && "subchild_Active"}`} onClick={() => clickEvent(childroute.path)} key={id}>
                                                                            //     {/* {childroute.title} */}test
                                                                            // </li>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </Collapse>

                                                {/* } */}
                                            </div>

                                        </div>
                                    )
                                })

                            }
                        </div>
                        {
                            (tabName === "departments") ?
                                <div>
                                    <Button
                                        className={classes.addDept}
                                        size="small"
                                        variant='outlined'
                                        startIcon={<BiMessageSquareAdd style={{ fontSize: '12px' }} />}
                                        onClick={handleAddDepartmeny}
                                    >
                                        Add Department</Button>
                                </div>
                                : ""
                        }
                    </Collapse>

                </div >

            </div >
        </div>
    )
}

export default withRouter(SidebarElementNew)

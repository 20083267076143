import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CardContent,
  Divider,
  Button,
  IconButton,
  Radio,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import TabPanel from "@material-ui/lab/TabPanel";
import ReactSelect from "react-select";
import PhoneIcon from "@material-ui/icons/Phone";
import { GrFormNextLink } from "react-icons/gr";
import { BiRefresh } from 'react-icons/bi'
import { useStyles } from "../styles";
// import UserNumberServices from '../../../userNumbersServices/usersNumbersServices'
import UserNumberServices from '../../../../UsersNumbers/userNumbersServices/usersNumbersServices'
import CountryStates from "../CountryStates/CountryStates";
import { useSelector } from 'react-redux'
import { $CombinedState } from "redux";
import logo from '../../../../../assets/images/Lodingg.gif'
const countryOptions = [
  { label: "canada", value: 1 },
  { label: "united states", value: 2 },
];
function Default(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [index, setIndex] = React.useState("0");
  const [addData, setAddData] = React.useState<any>({
    phoneNumber: "",
  })
 
  const [currentData, setCurrentdata] = React.useState([])
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState<any>(null)
  const noofElements = 12;
  const paginate = (page: number) => {
    const startIndex = Math.floor(page * noofElements);
    const endindex = (page + 1) * noofElements;
    const splitData = currentData.slice(startIndex, endindex)
    setData([...splitData])
  }

 

  const handleChangeNumbers = () => {
    setIndex("4")
  }
  const handleSelectedNumbers = () => {
    const sendData: any = {
      phoneNumber: addData.phoneNumber
    }
    UserNumberServices.PostNumbers(sendData).then((res: any) => {
      if (res.success) {
        props.handlePopupModal()
        const data = res.data;
        props.selectedNumber(data)
      }
      else {

      }
    }).catch((err: any) => {
      // send error
    })
    
  }

  React.useEffect(() => {
    paginate(currentPage)
  }, [currentPage, currentData])

  React.useEffect(() => {
    setCurrentdata(props.localNUmbers)
    setCurrentPage(0)
    //setData([])
  }, [props.localNUmbers, props.localNUmbers.length])

  // console.log('data splitted', currentPage, Math.ceil(currentData.length/noofElements), currentData)


  return (
    <div>

      <TabPanel value="1" classes={{ root: classes.padding }}>
        <CountryStates countryOptions={props.countryOptions}
          country={props.country}
          onChangeStateType={props.onChangeStateType}
          states={props.states}
          state={props.state}
          onChangeCountryType={props.onChangeCountryType}
          onChangeCity={props.onChangeCity}
          city={props.city}
          cities={props.citiesOptions}
        />
        {/* <Grid container spacing={2}>
          <Grid item md={4} className={classes.gridItem}>

            <Typography className={classes.text} style={{ marginTop: 15 }}>
              Cities
            </Typography>
            <FormControl fullWidth size="small" variant="outlined">
              <ReactSelect
                fullWidth
                displayEmpty
                options={props.citiesOptions}
                value={props.city}
                placeholder="Select City"
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChangeCity}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} className={classes.gridItem}>
          </Grid>
        </Grid> */}
        <Card className={classes.availableCard} style={{ padding: 16 }}>
          {/* <CardContent> */}
          <div className={classes.bFlex} >
            <div>
              <Typography className={classes.text}>Available Numbers</Typography>
            </div>
            {/* <div>
                <Button className={classes.availableButton}
                  // style={{ marginLeft: "128px" }}
                  onClick={props.handleChangeNumbers}
                  startIcon={<BiRefresh />}
                >Refresh Numbers</Button>
              </div> */}
          </div>
          {/* <Typography className={classes.text} style={{ marginBottom: "15px" }}>Available Numbers</Typography> */}
          <Divider className={classes.divider} />
          {/* <CardContent> */}

          {props.loading ?
            <div style={{ margin: 'auto', alignItems: "center", color: "lightgray", textAlign: 'center', width: "100%", height: "80px" }}>
              {/* <CircularProgress disableShrink /> */}
              <img src={logo} alt="loading..." style={{ height: "50px", marginTop: "7px" }} />
            </div> :
            <div className={classes.Numbers}>
              <div className={classes.Numbersgrid}>
                {
                  data.length > 0 ? (
                    data.map((loc: any, i: any) => {
                      return (
                        <div className={classes.NumberData}>
                          <Button
                            key={i}
                            className={addData?.phoneNumber === loc?.id.toString() ? classes.numberButtonActive : classes.numberButton}
                            variant="outlined"
                            size="small"
                            fullWidth
                            onClick={() => setAddData((prev: any) => {
                              return {
                                ...prev,
                                phoneNumber: loc?.id.toString()
                              }
                            })}
                          //startIcon={<Radio />}
                          >
                            {loc?.id}
                          </Button>
                        </div>
                      )
                    })
                  ) : (
                    <p style={{ textAlign: 'center', margin: '10px auto' }}>No Phone numbers</p>
                  )
                }
              </div>
              {/* {data.length > 0 ? (
                  data.slice(props.index, props.index + 8).map((loc: any, i: any) => (

                    <Grid container spacing={2} style={{}}>
                      <Grid item md={2}></Grid>
                      <Grid item md={10} >
                        <Button
                          key={i}
                          className={addData?.phoneNumber === loc?.id ? classes.numberButtonActive : classes.numberButton}
                          variant="outlined"
                          size="small"
                          fullWidth
                          onClick={() => setAddData((prev: any) => {
                            return {
                              ...prev,
                              phoneNumber: loc?.id
                            }
                          })}
                        // startIcon={<PhoneIcon />}

                        >
                          {loc?.id}
                        </Button>
                      </Grid>

                    </Grid>


                  ))
                ) : (
                  <p style={{ textAlign: 'center', margin: '10px auto' }}>No phone Numbers found</p>
                )} */}
              {/* {props.localNUmbers.length > 0 &&
                <Button
                >                
                  <div onClick={props.handleChangeNumbers} style={{
                  marginTop: 40,
                  width: 25, height: 25, background: "#4D72F8",
                  display: 'grid', justifyContent: 'center', borderRadius: 20,color:'white',
                  alignItems: 'center'
                }}><BiChevronRight /></div>
                </Button>
} */}
              {
                (currentPage > 0) && (
                  <Button size="small" style={{}}
                  >
                    <div onClick={() => {
                      setCurrentPage(currentPage - 1)
                    }} style={{

                      width: 25, height: 25, background: "#4D72F8",
                      display: 'grid', justifyContent: 'center', borderRadius: 20, color: 'white',
                      alignItems: 'center'
                    }}><BiChevronLeft /></div>
                  </Button>
                )
              }
              {
                !(Math.floor(currentData.length / noofElements) === currentPage) && (
                  <Button size="small" style={{ float: 'right' }}
                  >
                    <div onClick={() => {
                      setCurrentPage(currentPage + 1)
                    }} style={{
                      width: 25, height: 25, background: "#4D72F8",
                      display: 'grid', justifyContent: 'center', borderRadius: 20, color: 'white',
                      alignItems: 'center'
                    }}><BiChevronRight /></div>
                  </Button>
                )
              }
              {
                currentData.length > 0 && (
                  (currentPage + 1) + "/" + Math.ceil(currentData.length / noofElements)
                )
              }
            </div>

          }


          {/* </CardContent> */}
          {/* </CardContent> */}
        </Card>
        <div style={{ textAlign: 'center', marginTop: 25 }}>
          <Button size="small" variant="outlined" className={classes.cancelNumber} onClick={props.handlePopupModal}>Cancel</Button>
          <Button size="small" variant="outlined" className={classes.addNumber} onClick={handleSelectedNumbers}>Add Number</Button>
        </div>
        {/* <div>
          <IconButton className={addData?.phoneNumber !== '' ? classes.nextIconActive : classes.nextIcon} onClick={handleSelectedNumbers}>
            <GrFormNextLink className={classes.icon} />
          </IconButton>
        </div> */}
      </TabPanel>
    </div>
  );
}

export default Default;

import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as authActionCreators from '../../../store/Actions/AuthActionCreator';
toast.configure()

class HistoryServices {
    getTodayStats = async (data: any) => {
        try {
            const officeId = localStorage.getItem('SelectedCompanyId')
            let url = `/dashboard/todayStats?type=${data}`
            if (officeId){
                url = `/dashboard/todayStats?type=${data}&officeId=${officeId}`
            }
            let queryURL='';
            // if (localStorage.getItem('SelectedCompanyId')){
            //      queryURL = `&officeId=${localStorage.getItem('SelectedCompanyId') }`;
            // }
            const response = await axiosInstance.get(url);
            if (response.status === 200 && response.data) {
                const data = response.data;
                return { success: true, data: data };
            }
            else if (response.status === 401) {
                if (response.status === 401) {
                    authActionCreators.initRefreshToken()
                } else {
                    authActionCreators.initAuthLogout()
                }

            }
            else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getReports = async (type: any, page: any, limit: any, filter: any) => {
        try {
            const officeId = localStorage.getItem('SelectedCompanyId')
            let url = `/dashboard/reports?type=${type}&limit=${limit}&page=${page}${filter}`
            if(officeId){
                url = `/dashboard/reports?type=${type}&limit=${limit}&page=${page}${filter}&officeId=${officeId}`
            }
            if(filter){
                url = `/dashboard/reports?&limit=${limit}&page=${page}${filter}`
            }
            const response = await axiosInstance.get(url);
            if (response.status === 200 && response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getAllReports = async (page: any, limit: any, startDate: any, endDate: any, agentId: any, departmentId: any, callDirection: any, roomPhoneId: any,type:any,connected:any,total:any,missed:any) => {
        try {
            const officeId = localStorage.getItem('SelectedCompanyId')
            let url = `/report/getAllReports?limit=${limit}&page=${page}`
            if(officeId){
                url += `&officeId=${officeId}`
            }
            if (startDate) {
                url += `&startDate=${startDate}`;
              }
              if(endDate){
                url += `&endDate=${endDate}`;
              }
              if (agentId) {
                url += `&agentId=${agentId}`;
              }
              if (departmentId) {
                url += `&departmentId=${departmentId}`;
              }
              if (callDirection) {
                url += `&callDirection=${callDirection}`;
              }
              if (roomPhoneId) {
                url += `&roomPhoneId=${roomPhoneId}`;
              }
              if (type) {
                url += `&type=${type}`;
              }
              if (connected) {
                url += `&connected=${connected}`;
              }
              if (total) {
                url += `&total=${total}`;
              }
              if (missed) {
                url += `&missed=${missed}`;
              }
            console.log("url here ", url);
            const response = await axiosInstance.get(url);
            if (response.status === 200 && response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }
    getlistOfcalls = async (page: any, limit: any) => {
        try {
            const response = await axiosInstance.get(`/report/queue?limit=${limit}&page=${page}`);
            if (response.status === 200 && response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {
            // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response?.data?.message,
            };
        }
    }


}
const history = new HistoryServices()
export default history

import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import ProfileServices from "../../ProfileServices/ProfileServices";
import ReactSelect from 'react-select'
import axiosInstance from '../../../../environment/axiosInstance'

function AddDepartmentNumber(props: any) {
  const classes = useStyles();
  const [selectedDepartment, setSelectedDepartment] = React.useState<any>(null);
  const [clientList, setClientList] = React.useState<any>([]);
  const [loading, setloading] = React.useState(false)
  const [addName, setAddName] = React.useState({ 
    name: "", 
    // companyId: "" 
  });
  

  const addDepartmentName = () => {
    // const sendData : any = {
    //   if(addName.companyId === ""){
    //     name : addName.name
    //   }
    // }
    ProfileServices.postDepartmentName(addName)
      .then((res: any) => {
        if (res.success) {
          props.handlePopupModal();
          props.getDepartment();
        } else {
        }
      })
      .catch((err: any) => {
        // send error
      });
  };

  const getcompanyList = React.useCallback(() => {
    setloading(true);
    axiosInstance.get('/company').then((res: any) => {
        if (res.data.data) {
          const data = res.data.data
          var List: any = [];
            data.map((item: any) => {
              var object = {
                "value": item.id,
                "label": item.name
              }
              List.push(object)
            })
          setClientList(List)
        } else {
          setloading(false)
        }
      }).catch((err: any) => {
        setloading(false)
      })
    }, [])

  React.useEffect(() => {
    getcompanyList()
  }, [])

  const onChangeDepartment = (data: any) => {
    setSelectedDepartment(data);
    setAddName((prev: any) => {
      return {
        ...prev,
        name: data.target.value,
      };
    });
  };

  const onChangeCompany = (e:any) => {
    setAddName((prev:any) =>{
      return{
        ...prev,
        companyId: e.value
      }
    })
  }

  return (
    <div>
      <CardContent>
        <Card className={classes.modalCard}>
          <CardContent>
            <CardContent>
              <Typography className={classes.userText}>
                Add Department Name
              </Typography>
              <Grid container spacing={2} className={classes.gridItem}>
                <Grid item md={12} className={classes.gridItem}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter Department Name"
                    //value={name}
                    variant="outlined"
                    onChange={onChangeDepartment}
                  />
                  <Typography className={classes.userText}>Company Name</Typography>
                  <ReactSelect 
                    // options={clientList}
                    // name="companyId"
                    // value={
                    //   clientList.filter((option: any) =>
                    //     option.value === addName.companyId)
                    // }
                    onChange={onChangeCompany}
                    className={classes.selectField}
                    placeholder="Select Comapany"
                  />
                  <div style={{display:'flex',gap:15}}>
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.submitButtonn}
                      type="submit"
                      onClick={addDepartmentName}
                    >
                      Add
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.canceltButton}
                      type="submit"
                      onClick={props.handlePopupModal}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {/* <Typography className={classes.errorText}>error</Typography> */}
            </CardContent>
          </CardContent>
        </Card>
      </CardContent>
    </div>
  );
}

export default AddDepartmentNumber;

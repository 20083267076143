import React from 'react';
import {
    Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, Typography
} from '@material-ui/core';
import Recording from '../../../../assets/icons/recording.png';
import { useStyles } from './styles';
import { Icon, InlineIcon } from "@iconify/react";
import arrowEnterUp20Filled from "@iconify-icons/fluent/arrow-enter-up-20-filled";
import { AiOutlineArrowDown } from "react-icons/ai";
import Moment from 'moment'




function TableBodyData(props: any) {
    const classes = useStyles();
    const { agentId, toNumber, deptNumber, deptHourType, callHandType, callDirection, callerNumber, startStamp, createdDate, duration, recordingPath, fromNumber, id, phoneNumber, eFoneNumber, agentName, startTime } = props.row
    console.log('props.callHistoryData', props.callHistoryData);

    // var data = parseInt(createdDate)
    // var dataa = Moment(data).format('YYYY-MM-DD HH:mm:ss')
    var data = parseInt(startStamp)
    var dataa = Moment(data).format('YYYY-MM-DD HH:mm:ss')


    //duration
    function tooTime(d: any) {
        d = Number(d);
        var s = (d).toString().length > 1 ? d : '0' + d;
        //var s = (Math.floor(d % 3600 % 60)).toString().length > 1 ? Math.floor(d % 3600 % 60) : '0' + Math.floor(d % 3600 % 60);
        return s
    }

    function toTime(d: any) {
        d = Number(d);

        var h = (Math.floor(d / 3600)).toString().length > 1 ? Math.floor(d / 3600) : '0' + Math.floor(d / 3600);
        var m = (Math.floor(d % 3600 / 60)).toString().length > 1 ? Math.floor(d % 3600 / 60) : '0' + Math.floor(d % 3600 / 60);
        var s = (Math.floor(d % 3600 % 60)).toString().length > 1 ? Math.floor(d % 3600 % 60) : '0' + Math.floor(d % 3600 % 60);
        return h + ":" + m + ":" + s
    }
    return (
        <>
            <TableRow style={{ border: '1px solid #E2E2E2' }}>
                {/* {agentId ?
                    <TableCell className={classes.tableBody}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                      
                            <Typography>{agentId && agentId.firstName}  {agentId && agentId.lastName}</Typography>
                        </div>
                    </TableCell>
                    :
                    <TableCell className={classes.tableBody}>
                        _
                    </TableCell>
                } */}
                <TableCell className={classes.tableBody}>_</TableCell>
                <TableCell className={classes.tableBody}>_</TableCell>
                <TableCell className={classes.tableBody}>
                    <Typography>{deptNumber}</Typography>
                    <Typography style={{ fontSize: '14px',fontFamily:'Work Sans' }}>{deptHourType === '1' ? "Open Hours" : "close Hours"}</Typography>
                    <Typography style={{ fontSize: 15,fontFamily:'Work Sans' }}>(call Handling Type : {callHandType === '7' ? "recording" : ''})</Typography>

                </TableCell>
                <TableCell className={classes.tableBody}>
                    {callDirection === "1" ?
                        <>
                            <Icon
                                icon={arrowEnterUp20Filled}
                                style={{ color: "#04B400", marginLeft: 3 }}
                            />
                        </>
                        :
                        <AiOutlineArrowDown style={{ color: "#0316BF" }} />
                    }{callerNumber}
                </TableCell>
                <TableCell className={classes.tableBody}>{startStamp ? dataa : ""}</TableCell>

                <TableCell className={classes.tableBody}>{toTime(duration)}</TableCell>
                <TableCell className={classes.tableBody}>{recordingPath ? recordingPath : "_"}</TableCell>
                {/* <TableCell className={classes.tableBody}>
                    <img className={classes.recording} alt="playbutton" src={Recording} />
                </TableCell> */}
            </TableRow>
        </>
    )
}
export default TableBodyData
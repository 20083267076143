import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
class ReportServices {
    getBusiness = async () => {
        try {
            const response = await axiosInstance.get(`/admin/business-accounts?limit=100`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getReports = async (limit: any, page: any) => {
        try {
            const response = await axiosInstance.get(`/admin/reports/cdr?limit=${limit}&page=${page}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }
    getReportsearch = async (limit: any, page: any, data: any) => {
        try {
            const response = await axiosInstance.get(`/admin/reports/cdr?limit=${limit}&page=${page}&${data.typeKey}=${data.typeValue}&${data.accountKey}=${data.accountValue}&${data.directionKey}=${data.directionValue}&${data.callStatusKey}=${data.callStatusValue}`);
            if (response.data) {
                const data = response.data;
                return { success: true, data: data };
            } else {
                return { success: false, message: response?.data?.message, data: [] };
            }
        } catch (error: any) {

            return {

                success: false,

                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    }


}
const Reports = new ReportServices()
export default Reports

import { Avatar, Card, Divider, Typography } from '@material-ui/core'
import React, { useRef, } from 'react';
import { BiUserPlus } from 'react-icons/bi';
import { MdGroups } from 'react-icons/md';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useStyles } from '../style'
import '../style.css'
import { useParams } from 'react-router-dom';
import { LteMobiledata } from '@mui/icons-material';

function GroupInfo(props: any) {
    const classes = useStyles()
    const chatBodyRef = useRef(null)
    const { list, searchResult } = props
    const params: any = useParams()



    const memberNames: any = [];
    const rr: any = list.filter((convList: any) => convList._id === params.id)
    rr[0].groupMembers?.forEach((memberr: any) => {
        searchResult.forEach((member: any) => {
            if (member.efoneId == memberr) {
                memberNames.push(member)
            }
        })
    })


    return (
        <div>

            {
                list.map((item: any) => {
                    return (
                        <>
                            <div>
                                {
                                    item._id === params.id &&
                                    <div >
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                            <div >
                                                <Avatar className={classes.pink}>
                                                    {item.groupName?.slice(0, 1)}
                                                </Avatar>
                                            </div>
                                            <div>
                                                <Typography>{item.groupName}</Typography>
                                                <Typography>{item?.groupMembers?.length - 1} Members</Typography>
                                            </div>
                                        </div>
                                        <Card style={{ padding: 8, marginTop: 20 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                    <MdGroups style={{ fontSize: 18 }} />
                                                    <Typography>{item?.groupMembers?.length - 1} Members</Typography>
                                                </div>
                                                <BiUserPlus style={{ fontSize: 18 }} />
                                            </div>
                                            <div>
                                                <Typography>
                                                    {
                                                        memberNames.map((dt: any) => {
                                                            return (
                                                                <>
                                                                    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                        <div >
                                                                            <Avatar className={classes.pink}>
                                                                                {dt.firstName?.slice(0, 1)} {dt.lastName?.slice(0, 1)}
                                                                            </Avatar>
                                                                        </div>
                                                                        <div>
                                                                            <Typography>{dt.firstName} {dt.lastName}</Typography>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Typography>
                                            </div>
                                        </Card>

                                    </div>
                                }
                            </div>

                        </>
                    )
                })
            }
            {/* <Card style={{ padding: 8, marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <MdGroups />
                        <Typography>4 Members</Typography>
                    </div>
                    <BiUserPlus />
                </div>
            </Card> */}
        </div>
    )
}

export default GroupInfo
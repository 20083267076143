import React from 'react'
import { OpenHours } from '../UserCallHandling/MyDeviceOpenHours/OpenHours/OpenHours'


function MyDevice() {
  return (
    <div>
        <OpenHours />
    </div>
  )
}

export default MyDevice
import React from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    Divider,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    makeStyles,
    InputLabel, FormControl, Tooltip
} from '@material-ui/core';
import { Delete, AddBox } from '@material-ui/icons';
import { GoDiffAdded } from 'react-icons/go'
import { RiUpload2Fill } from 'react-icons/ri'
import {
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableHead, TableBody
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ReactSelect from 'react-select';
// import Select from '@material-ui/core';
//import Playbutton from 'assets/icons/playIcon.png';
import { useStyles } from './menuStyles';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'

const departmentsOptions = [
    {
        label: 'Departments',
        value: 3
    },
    {
        label: 'Team Member',
        value: 4
    },
    {
        label: 'Phone Numbers',
        value: 5
    },
    {
        label: 'Voice Mail',
        value: 6
    },
    {
        label: 'Recorded Messages',
        value: 7
    },
    {
        label: 'Name Directory',
        value: 8
    },
]

function IVRMenuBody(props: any) {
    const classes = useStyles();
    const [selectedDepartment, setSelectedDepartment] = React.useState<any>({})
    const [selectedVal, setSelected] = React.useState<any>({})
    const [ivrCopiedData, setIvrCopiedData] = React.useState<any>([])
    const [addNumberModal, setAddNumberModal] = React.useState(false);
    const [voiceModal, setVoiceModal] = React.useState(false)
    const [voiceFile, setVoiceFile] = React.useState({})
    const [voice, setVoice] = React.useState(false)
    const [record, setRecord] = React.useState(false)
    const [recordModal, setRecordModal] = React.useState(false)
    const [loader, setLoader] = React.useState(false)
    const [selectedDropDown, setSelectedDropDown] = React.useState<any>([])
    const [option, setOption] = React.useState<any>("")
    const [selectedOption, setselectedOption] = React.useState<any>("")
    const [ivrValue, setIvrValue] = React.useState<any>("")
    const [currentSelectedOption, setcurrentSelectedOption] = React.useState<any>()
    const [id, setId] = React.useState<any>()
    const [deleteModal, setdeleteModal] = React.useState<any>(false);
    const [optionId, setoptionId] = React.useState<any>()


    React.useEffect(() => {
        setOption(props.iverData?.option)
        setselectedOption(props.iverData.selectedOption)
        setcurrentSelectedOption(props.iverData.selectedOption)
        setId(props?.iverData?._id)

        const filterOption = props?.ivrActions.find((deps: any) => deps.value == props?.iverData?.selectedOption);
        // console.log("filterDepart",filterDepart)
        setSelectedDepartment(filterOption)

        if (props?.iverData?.selectedOption === '3') {
            // setcurrentSelectedOption(props.iverData?.selectedOption)
            const filterDepartmentSelected: any = props.deptOptions?.find((depart: any) => depart?.value === props?.iverData?.selectedValue)
            // console.log("filterDepartmentSelected", filterDepartmentSelected)
            setSelected(filterDepartmentSelected)
        }
        else if (props?.iverData?.selectedOption === '5') {
            const filterNum: any = props.fwdNumOptions?.find((obj: any) => obj?.value === props?.iverData?.selectedValue)
            // console.log("filterDepartmentSelected", filterDepartmentSelected)
            setSelected(filterNum)
        }
        else if (props?.iverData?.selectedOption === '6') {
            const filteredVoiceData: any = props.voiceMailOptions?.find((obj: any) => obj?.value === props?.iverData?.selectedValue)
            // console.log("filterDepartmentSelected", filterDepartmentSelected)
            setSelected(filteredVoiceData)
        }
        else if (props?.iverData?.selectedOption === '7') {
            const filteredrecmsg: any = props.recMsgOptions?.find((obj: any) => obj?.value === props?.iverData?.selectedValue)
            // console.log("filterDepartmentSelected", filterDepartmentSelected)
            setSelected(filteredrecmsg)
        }

    }, [props?.iverData])

    const handleIvrChange = (e: any) => {
        // console.log('e', e)
        setcurrentSelectedOption(e.value)
        setselectedOption(e.value)
        const data: any = {
            option: option,
            selectedOption: `${e.value}`,

        }
        if (id === undefined) {
            props.insertIvrOption(data)
        } else {
            props.updateIvrMenuList(data, id)
        }
    }

    const handelivrOptionChange = (e: any) => {
        //  console.log('e', e.target.value)
        setOption(e.target.value)
        const value = e.target.value
        const data: any = {
            option: e.target.value,
            selectedOption: props?.iverData?.selectedOption,
            selectedValue: props?.iverData?.selectedValue
        }
        if (id === undefined && value !== '') {
            props.insertIvrOption(data)
        } else {
            props.updateIvrMenuList(data, id)
        }
    }


    const handleActionList = (e: any) => {
        console.log('e', e.value)
        // setIvrValue(e.value)

        const data: any = {
            option: option,
            selectedOption: `${selectedOption}`,
            selectedValue: e.value
        }
        const ivrActions: any = [data]
        // console.log("data", ivrActions)
        // console.log(id)
        if (id === undefined) {
            props.insertIvrOption(data)
        } else {
            props.updateIvrMenuList(data, id)
        }

    }

    // console.log("iverdata", props.iverData)
    const handleDeleteOption = () => {
        setdeleteModal(!deleteModal);
    };

    const RemoveOption = (id: any) => {
        setoptionId(id)
        handleDeleteOption()

    }

    return (
        <>
            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeleteOption} />
                        <Modal title="Are you sure want to Delete this Option ?" handlePopupModal={handleDeleteOption} >
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div style={{ margin: '10px' }}>
                                            <Button size="medium" onClick={() => props.handleDeleteIvrMenu(optionId)} variant='contained' style={{ marginRight: '5px', background: '#4D72F8', color: 'white' }}>Delete</Button>
                                            <Button size="medium" onClick={handleDeleteOption} variant='contained' style={{ marginLeft: '5px', background: '#4D72F8', color: 'white' }}>Cancel</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div >
                )
            }

            <TableRow className={classes.tableCellRow} >

                <TableCell className={classes.tableCellRow}>
                    {/* <h3 className={classes.tableCellDiv}>{props.iverData?.option}</h3> */}
                    <input value={option} type='text' className={classes.ivrOptions}
                        onChange={handelivrOptionChange}
                        name='option' />
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    <FormControl size="small" fullWidth variant="outlined">
                        <div className={classes.firstdropdown}>
                            <ReactSelect
                                autoWidth={true}
                                placeholder="To a department"
                                native
                                fullWidth
                                value={selectedDepartment ? selectedDepartment : null}
                                options={props?.ivrActions}
                                // onChange={(data) => onChangeAction(data, props?.iverData?.option)}
                                onChange={handleIvrChange}
                                inputProps={{ 'aria-label': 'Without label' }}

                            />
                        </div>
                    </FormControl>
                </TableCell>
                <TableCell className={classes.tableCellBody}>
                    <FormControl size="small" fullWidth variant="outlined">
                        {currentSelectedOption === '3' ?
                            <div className={classes.dflex}>
                                <ReactSelect
                                    autoWidth={true}
                                    placeholder="Select"
                                    native
                                    value={selectedVal ? selectedVal : null}
                                    fullWidth
                                    options={props?.deptOptions}
                                    // onChange={(data) => onChangeSelectOption(data, props?.iverData?.option)}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className={classes.width90}
                                    onChange={handleActionList}
                                />
                                {/* <TextField
                                    variant="outlined"
                                    name="phoneNumber"
                                    fullWidth size="small"
                                    placeholder="Enter phone number"
                                /> */}

                            </div>
                            :
                            currentSelectedOption === '6' ?
                                <div className={classes.dflex}>
                                    <ReactSelect
                                        autoWidth={true}
                                        placeholder="Select"
                                        native
                                        value={selectedVal ? selectedVal : null}
                                        fullWidth
                                        className={classes.width90}
                                        options={props?.voiceMailOptions}
                                        // onChange={(data) => onChangeSelectOption(data, props?.iverData?.option)}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handleActionList}
                                    />
                                </div> :
                                currentSelectedOption === '7' ?
                                    <div className={classes.dflex}>
                                        <ReactSelect
                                            autoWidth={true}
                                            placeholder="Select"
                                            native
                                            value={selectedVal ? selectedVal : null}
                                            fullWidth
                                            className={classes.width90}
                                            options={props?.recMsgOptions}
                                            // onChange={(data) => onChangeSelectOption(data, props?.iverData?.option)}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={handleActionList}
                                        />
                                    </div>
                                    :

                                    currentSelectedOption === '8' ?
                                        null
                                        :
                                        currentSelectedOption === '5' ?
                                            <ReactSelect
                                                autoWidth={true}
                                                placeholder="Select"
                                                native
                                                value={selectedVal ? selectedVal : null}
                                                fullWidth
                                                options={props?.fwdNumOptions}
                                                onChange={handleActionList}
                                                // onChange={(data) => onChangeSelectOption(data, props?.iverData?.option)}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            />
                                            :
                                            ""
                        }
                    </FormControl>

                </TableCell>
                <TableCell className={classes.tableCellBody1}>
                    <Tooltip title="Delete Ivr Option">
                        <Delete onClick={() => RemoveOption(props?.iverData?._id)}
                            className={classes.tableDeleteIcon}
                        />
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    )
}

export default IVRMenuBody

import { AnyIfEmpty } from 'react-redux';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

class MyAccountServices {
    updateMyAccountSchedule = async (data: any) => {
        try {
            const response = await axiosInstance.patch('/profile/settings/schedule', data);
            if (response.data) {
                const data = response.data;
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                // console.log("----updated switch", response.data)
                return { success: true, data: data };
            } else {
                return { success: false, message: response.data.message };
            }
        } catch (error: any) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
            return {
                success: false,
                message:
                    error.message === "Network Error"
                        ? "Network Error"
                        : error.response.data.message,
            };
        }
    };


}
const myAccounts = new MyAccountServices()
export default myAccounts
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modalCard: {
      margin: '10px 0px 10px 0px',
      borderRadius: 10,
      background: '#FFFFFF',
      boxShadow: '0 0 0.5em #E2E2E2',
    },
    textCard: {
      margin: '10px',
      padding: '20px 15px',
      display: 'flex',
      //justifyContent: 'space-evenly',
      alignItems: 'center',
      borderRadius: 10,
      background: '#fbfbfb',
      boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
      width: '90%',
       "@media (max-width: 768px)":{
        width : '100%'
      }
    },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },
    portContent: {
      // padding: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    portText: {
      fontSize: 16,
      //margin: "20px 0px 0px 0px",
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      color: '#828282',
      textAlign: 'center',
      textTransform: 'none',
      marginLeft: 30,
    },
    portText1: {
      fontSize: 14,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      // fontWeight: 500,
      color: '#8392AB',
      textAlign: 'center',
      textTransform: 'none',
      marginLeft: 20,
    },
    padding: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    portText2: {
      marginBottom: 10,
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      color: '#464646',
      letterSpacing: 0.1,
      textAlign: 'left',
      textTransform: 'none',
    },
    textArea: {
      width: '100%',
      border: '1px solid #BCBCBC',
      background: '#FFFFFF',
      boxSizing: 'border-box',
      borderRadius: 5,
      padding: '10px',
    },
    gridItem: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    w80: {
      display: 'flex',
      alignItems: 'center',
      width: '70%',
      marginLeft: 'auto'
    },
    createRequest: {
      borderRadius: 5,
      color: '#FFFFFF',
      border: '1px solid #b5b5b5',
      fontWeight: 500,
      fontSize: 13,
      padding: '6px 20px',
      fontFamily: 'Work Sans',
      backgroundColor: '#4D72F8',
      '&:hover': {
        backgroundColor: '#4D72F8',
        color: '#FFFFFF',
      },
      '&:active': {
        backgroundColor: '#F3F5FF',
      },
    },
    cancelButton: {
      marginLeft: 15,
      borderRadius: 5,
      color: '#8F8F8F',
      fontWeight: 500,
      fontSize: 13,
      fontFamily: 'Work Sans',
      backgroundColor: '#FFFFFF',
      padding: '6px 20px',
      border: '1px solid #b5b5b5',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      '&:active': {
        backgroundColor: '#FFFFFF',
      },
    },
    textColorOne: {
      margin: 7,
      color: '#707070',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 17,
    },
    inputdate: {
      width: "100%",
      height: "38px",
      padding: "8px",
      color: "black",
      borderRadius: "5px",
      border: "1px solid grey",
      fontSize: 15,

    },
  }))
import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(() => ({
  indexConatiner:{
    margin: '0 20px'
  },
  headTexts: {
    fontSize: 20,
    marginLeft: 20,
    marginTop: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#8392AB',
  },
  headColor: {
    background: '#fbfbfb',
  },
  tableHead: {
    fontSize: 16,
    color: '#464646',     
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily:'Work Sans'
  },
  tableBody: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color:'#939292',
    fontFamily:'Work Sans'
  },
  color: {
    backgroundColor: '#F1F1F1',
  },
  padding: {
    padding: 15,
    margin: '0 20px'
  },
  grid: {
    width: '100%'
  },
  cardlength: {
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  CardContent:{
    height: 300,
    overflowY: 'auto',
  },
  textName: {
    fontSize: 18,
    
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#2645ad',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  divider: {
    marginTop: -10,
    backgroundColor: '#E2E2E2',
  },
  vector: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
   
  },
  iconWidth: {
    margin: 10,
    height: '20px',
    width: '20px',
  },
  textWidth: {
    fontSize: 16,
    
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#8392AB',
    letterSpacing: 0.208955,
    textTransform: 'none',
    margin: 10,
  },
  vectorButtonWidth: {
    height: '20px',
    width: '20px',
    color: '#C9C9C9',
    marginLeft: '20px',
  },
  text: {
    fontSize: 16,
    
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#464646',
    letterSpacing: 0.208955,
    textTransform: 'none',
    margin: '10',
  },
}));

import React from 'react'
import { Grid, TextField, Typography, makeStyles, Button, Tabs, Tab } from '@material-ui/core'
import ReactSelect from "react-select";
import ReactAudioPlayer from 'react-audio-player';
import { TabContext, TabPanel } from '@material-ui/lab';
import AudioCard from '../OpenHourGreeting/OpenHourGreetingCard'
import { useStyles } from './styles'
import { BiMessageSquareAdd } from 'react-icons/bi'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import AddNumber from './AddNumberModal/AddNumberModal'
import axiosInstance from '../../../../environment/axiosInstance'
import Fileupload from './Fileupload';
import RecordAudio from './RecordAudio';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
const muiTheme = createMuiTheme({});


const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });


function ForwardNumber(props: any) {
    const classes = useStyles()
    const [openModalAdd, setopenModalAdd] = React.useState(false);
    const [addNumberModal, setAddNumberModal] = React.useState(false);
    const [numList, setNumList] = React.useState<any>([])
    const [source, setSource] = React.useState<any>('')
    // const [selectedNum, setselectedNum] = React.useState<any>([])
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [value, setValue] = React.useState("2");

    const handlePopupModalNumber = () => {
        setAddNumberModal(!addNumberModal)
    }

    const fetchPhoneNumbers = () => {
        axiosInstance.get(`/departments/${props.depId}/open-hours`).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                // console.log(res.data.data.voicemail.voicemailAudio)
                setNumList(res.data.data?.ForwardNumbers)
                // const data = res.data.data.ForwardNumbers
                setSource(res.data.data?.ForwardNumberTransferringAudio)

            }
        })
    }

    const newList: any = []
    numList?.map((item: any) => {
        const obj: any = {
            value: item._id,
            label: item.phoneNumber,
            isSelected: item.isSelected
        }
        newList.push(obj)
        // if(item.isSelected === true){
        //     setselectedNum(item)
        // }
    })



    const selectedNum = numList?.findIndex((option: any) => {

        if (option?.isSelected === true) {
            return option
        }
    })
    console.log(selectedNum)

    const onChangePhoneNumberDropDown = (e: any) => {
        // console.log("e", e)
        const id = e.value
        const data = {
            forwardNumberId: id
        }

        axiosInstance.patch(`/departments/${props.depId}/open-hours/forward-number`, data).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                fetchPhoneNumbers()
            }

        })

    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }

    // console.log("selectedNum", selectedNum)
    React.useEffect(() => {
        // console.log(props.data)
        fetchPhoneNumbers()
    }, [])

    return (
        <>
            {
                addNumberModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalNumber} >
                            <div className="add_user_popup">
                                <AddNumber fetchPhoneNumbers={fetchPhoneNumbers} depId={props.depId} handlePopupModal={handlePopupModalNumber} />
                            </div>
                        </Modal>
                    </div>
                )
            }
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title='Edit Forward Number Audio' handlePopupModal={handlePopupModalAdd} >
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        {/* <p>record file</p> */}
                                        <RecordAudio openHrFiles={props.soundFiles} />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/* <p>File Upload</p> */}
                                        <Fileupload depId={props.depId}
                                            getOpenHours={props.getOpenHours}
                                            data={props.callHandlingData?.ForwardNumberTransferringAudio} />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div className={classes.audioTransferContainer}>
                <div>
                    <Grid container style={{ marginBottom: '50px' }}>
                        {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Typography className={classes.audioTransferHeading}>
                                Transfering Audio
                            </Typography>
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                            <Select placeholder='Default' width="330px" height='50px'/>
                        </Grid>
                    </Grid> */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Typography className={classes.audioTransferHeading}>
                                Enter Number
                            </Typography>
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                           <TextField placeholder='Enter Phone Number' variant='outlined' style={{width: 330, height: 50}}/>
                        </Grid> */}
                            <div className={classes.dflex}>
                                <div style={{ width: 320 }}>
                                    <ReactSelect
                                        // placeholder="select phone number"
                                        options={newList}
                                        value={newList[selectedNum]}
                                        onChange={onChangePhoneNumberDropDown}
                                    />
                                </div>
                                <BiMessageSquareAdd className={classes.deviceWidth} onClick={handlePopupModalNumber} />
                            </div>

                        </Grid>
                    </Grid>
                </div>
                <div>
                    {/* <AudioCard name={props.phoneVoiceName} data={props.phoneVoiceData}/> */}
                    <div className={classes.audioContainer}>
                        <ReactAudioPlayer
                            controls
                            src={props.phoneVoiceData}
                            className={classes.audioPlayers}
                        />
                        <Button className={classes.editbtn} onClick={handlePopupModalAdd}>
                            Edit
                        </Button>
                    </div>
                    <div style={{ textAlign: 'left', marginTop: '0px' }}>
                        {/* <p className={classes.filename}>Filename.mp3</p> */}
                        <span className={classes.filename}>{props.phoneVoiceName}</span>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ForwardNumber
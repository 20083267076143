

import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
  InputLabel, FormControl
} from '@material-ui/core';

import { BorderColor, Delete, Save } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import ReactSelect from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Select from 'react-select';
import { useStyles } from './styles';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi'
import AudioPlayer from 'material-ui-audio-player'
import ProfileServices from '../../ProfileServices/ProfileServices'
import { useSelector, useDispatch } from 'react-redux';
import * as userActionCreators from '../../../../store/Actions/userActionCreator'
import ReactAudioPlayer from 'react-audio-player';

const useStyleses = makeStyles(
  (theme: any) => {
    return {
      root: {
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
      },
      loopIcon: {
        color: '#4D72F8',
        '&.selected': {
          color: '#0921a9'
        },
        '&:hover': {
          color: '#4D72F8'
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      playIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      pauseIcon: {
        color: '#4D72F8',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeSlider: {
        color: '#4D72F8'
      },
      progressTime: {
        color: '#6b6b6d'
      },
      mainSlider: {
        color: '#4D72F8',
        '& .MuiSlider-rail': {
          color: '#4D72F8'
        },
        '& .MuiSlider-track': {
          color: '#4D72F8'
        },
        '& .MuiSlider-thumb': {
          color: '#4D72F8'
        }
      }
    }
  });

function UnAnsweredTeammembers(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [shouldRecord, setShouldRecord] = React.useState(false)
  const [fileName, setFileName] = React.useState('')
  const [srcss, setSrcss] = React.useState<any>('')
  const [fileBuffer, setFileBuffer] = React.useState('')
  const [dropDownValue, setDropDownValue] = React.useState<any>({ lable: '', value: '' });
  const [dropDownValues, setDropDown] = React.useState<any>([]);
  const audioFileInput: any = React.createRef();
  const [Loading, setLoading] = React.useState<any>(false)
  const [transferDropDownData, settransferDropDownData] = React.useState<any>([]);
  const [teamMembersDropDownData, setteamMembersDropDownData] = React.useState<any>([]);
  const [transferArray, settransferArray] = React.useState<any>([])
  const [teamMembersArray, setteamMembersArray] = React.useState<any>([])
  const [dropDownState, setDropDownState] = React.useState<any>({
    callHandlingValue: '',
    musicGreeting: '',
    callHandlingOption: props?.callHandlingOption
  })
  const [dropDownStateValue, setDropDownStateValue] = React.useState<any>({
    callHandlingValue: '',
    musicGreeting: ''
  })

  const [uploadingFile, setUploadingFile] = React.useState<any>(false)
  const [selectedAudioFile, setSelectedAudioFile] = React.useState<any>({})
  const profileStoreData = useSelector((state: any) => state.profileData);
  const { profileData, loading } = profileStoreData;

  const hoursDataState: any = useSelector((state: any) => state?.hoursData);

  const shouldRecordChange = () => {
    setShouldRecord(!shouldRecord)
  }

  const uploadFileClick = () => {
    setUploadingFile(true)
    if (audioFileInput.current) {
      audioFileInput.current.click()
    }


  }

  const onChangeAudioFile = (e: any) => {

    const fileData = e.target.files;
    var file: any = fileData[0]
    setFileBuffer(file)
    let reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setSrcss(reader.result)
    };

    setFileName(fileData[0]?.name)
    onPostDataToServer(file, fileData[0]?.name)
  }

  const updateUnansweredHoursData = (data: any) => {
    setLoading(true)
    ProfileServices.updateUnAnsweredCalls(data).then((res: any) => {
      if (res.success) {
        props.getUnanswereddata()
        dispatch(userActionCreators.initUserProfile())
        setLoading(false)
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }


  const onChangeTransferDropDown = (data: any) => {
    setDropDownStateValue((prev: any) => {
      return {
        ...prev,
        musicGreeting: data.value
      }
    })

    if (transferArray.length > 0) {
      const findtrans = transferArray.find((recod: any) => recod.id === data.value);

      if (findtrans) {
        setSelectedAudioFile(findtrans)
        setFileName(findtrans?.fileName)
        setSrcss(findtrans?.filePath)
        setDropDownState((prev: any) => {
          return {
            ...prev,
            musicGreeting: findtrans?.id
          }
        })
        setTimeout(() => {
          if (dropDownState?.callHandlingValue) {
            updateUnansweredHoursData({ ...dropDownState, musicGreeting: findtrans?.id })
          }
        }, 1500)
      }
    }

  }

  const onChangeTeamDropDown = (data: any) => {
    setDropDownStateValue((prev: any) => {
      return {
        ...prev,
        callHandlingValue: data.value
      }
    })

    if (teamMembersArray.length > 0) {
      const findRecord = teamMembersArray.find((phone: any) => phone.id === data.value);

      if (findRecord) {
        setSelectedAudioFile(findRecord)
        setFileName(findRecord?.fileName)
        setSrcss(findRecord?.filePath)
        setDropDownState((prev: any) => {
          return {
            ...prev,
            callHandlingValue: findRecord?.id
          }
        })

        updateUnansweredHoursData({ ...dropDownState, callHandlingValue: findRecord?.id })

      }
    }
  }
  React.useEffect(() => {
    if (props.unanswered) {
      const unanswered = props.unanswered
      const transfersArrays: any = unanswered?.transfer;
      const teamMemberArrays: any = unanswered?.teamMembers;

      if (transfersArrays && transfersArrays.length > 0) {
        settransferArray(transfersArrays);
        const filterTransfer: any = transfersArrays.map((transfer: any) => {
          return {
            label: transfer?.fileName,
            value: transfer?.id
          }
        })
        settransferDropDownData(filterTransfer)
      }
      if (teamMemberArrays && teamMemberArrays.length > 0) {
        setteamMembersArray(teamMemberArrays);

        const filterTeam: any = teamMemberArrays.map((deprtment: any) => {
          return {
            label: deprtment.firstName,
            value: deprtment.id
          }
        })
        setteamMembersDropDownData(filterTeam)
      }
      if (props.data) {
        const defaultData = props.data
        const filterDepartmentVal = teamMemberArrays?.find((dept: any) => dept.id === defaultData.teamMember.currentSelectedValue)
        console.log("----data", defaultData.teamMember.currentSelectedValue, teamMemberArrays)
        if (filterDepartmentVal) {
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: filterDepartmentVal.id
            }

          })
          setDropDownState((prev: any) => {
            return {
              ...prev,
              callHandlingValue: filterDepartmentVal.id
            }
          })
        }
        const filterTransferDropdown = transfersArrays?.find((item: any) => item.id === defaultData.teamMember.transferingAudio)
        console.log("---data", filterTransferDropdown, transfersArrays, defaultData.teamMember.transferingAudio)
        if (filterTransferDropdown) {
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              musicGreeting: filterTransferDropdown.id
            }
          })
          setSrcss(filterTransferDropdown.filePath)
          setDropDownState((prev: any) => {
            return {
              ...prev,
              musicGreeting: filterTransferDropdown.id
            }
          })

        }

      }
    }


  }, [props.unanswered, props.data, profileData])


  const deleteAudioFile = () => {
    if (selectedAudioFile) {
      setLoading(true)
      ProfileServices.deleteFileData(selectedAudioFile?.id).then((res) => {
        if (res.success) {
          //success fully uploaded

          updateUnansweredHoursData({ ...dropDownState })
          let tempDropDownData = transferDropDownData.filter((dpvals: any) => dpvals.value !== selectedAudioFile?.id)
          settransferDropDownData(tempDropDownData)
          setLoading(false)
          setDropDown(dropDownValues.filter((dpvals: any) => dpvals.value !== selectedAudioFile?.id))
          settransferArray(transferArray.filter((dpFil: any) => dpFil.id === selectedAudioFile?.id))
          setUploadingFile(false)
          setFileName("")
          setSelectedAudioFile(null)
          setSrcss('')
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              musicGreeting: null
            }
          })

        } else {
          setLoading(false)
        }
      }).catch((err) => {
        //send err
        console.log(err)
        setLoading(false)
      })
    }
  }


  const onPostDataToServer = (fileBuffer: any, fileName: any) => {
    let formData = new FormData()
    formData.append('fileName', fileName)
    formData.append('type', '1')
    formData.append('hoursType', '3')

    if (uploadingFile) {
      formData.append('file', fileBuffer)
      formData.append('soundType', 'Transfer')
      setLoading(true)
      ProfileServices.uploadFileData(formData).then((res) => {
        if (res.success) {
          //success fully uploaded

          setLoading(false)
          onChangeTransferDropDown({ label: res.data?.data.fileName, value: res?.data.data.id })
          updateUnansweredHoursData({ ...dropDownState, musicGreeting: res?.data?.data.id })
          setDropDown([...transferDropDownData, { label: res.data?.data.fileName, value: res?.data?.data.id }])
          settransferArray([...transferArray, res.data.data])
          setUploadingFile(false)
          props.getUnanswereddata()
          setSelectedAudioFile(res?.data?.data)
          settransferDropDownData([...transferDropDownData, { label: res?.data.data.fileName, value: res?.data.data.id }])
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              musicGreeting: res?.data?.data.id
            }
          })

        } else {
          setLoading(false)
        }
      }).catch((err) => {
        //send err
        setLoading(false)
      })
    } else {
      if (selectedAudioFile) {
        ProfileServices.patchFileData(selectedAudioFile?.id, formData).then((res) => {
          if (res.success) {
            //success fully uploaded
            var tempDropDownData = [...transferDropDownData, { label: res.data?.fileName, value: res?.data?.id }]
            setDropDown([...transferDropDownData, { label: res.data?.fileName, value: res?.data.id }])
            settransferDropDownData(tempDropDownData)
            settransferArray([...transferArray, res.data])
            setUploadingFile(false)
            setLoading(false)
            setSelectedAudioFile(res?.data)
            props.getUnanswereddata()
            setDropDownStateValue((prev: any) => {
              return {
                ...prev,
                musicGreeting: res?.data?.id
              }
            })

          } else {
            setLoading(false)
          }
        }).catch((err) => {
          //send err
          console.log(err)
          setLoading(false)
        })
      }
    }
  }

  console.log("---data", dropDownStateValue)
  return (
    <>
      {Loading || loading ?
        <Box sx={{ display: 'flex', marginTop: "25px", marginLeft: "520px" }}>
          <CircularProgress />
        </Box> : (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.typographywidth}>To Team member</Typography>
              <FormControl variant="outlined" className={classes.dropdownwidth} size="small">

                {/* <ReactSelect
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              placeholder={"select depart,ent"}
              options={teamMembersDropDownData}
              value={dropDownStateValue?.callHandlingValue}
              onChange={onChangeTeamDropDown}
            /> */}
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder={"select team member"}
                  options={teamMembersDropDownData}
                  value={
                    teamMembersDropDownData.filter((option: any) =>
                      option.value === dropDownStateValue.callHandlingValue)
                  }
                  onChange={onChangeTeamDropDown}
                />
              </FormControl>

              <Typography className={classes.greetText}>Transfering Audio</Typography>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item md={5} sm={12} xs={12} className={classes.grid}>
                  {/* <ReactSelect
                menuPortalTarget={document.body}///
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                placeholder={props.placeholder}
                options={transferDropDownData}
                value={dropDownStateValue.musicGreeting}
                onChange={onChangeTransferDropDown}
              /> */}
                  <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    placeholder={props.placeholder}
                    options={transferDropDownData}
                    value={
                      transferDropDownData.filter((option: any) =>
                        option.value === dropDownStateValue.musicGreeting)
                    }
                    onChange={onChangeTransferDropDown}
                  />
                  <div className={classes.radioBtnsContainer}>
                    <IconButton className={classes.uploadButton} onClick={shouldRecordChange}>
                      {
                        shouldRecord ? (
                          <BiRadioCircleMarked size={15} color="#787878" />
                        ) : (
                          <BiRadioCircle size={15} color="#787878" />
                        )
                      }
                      <Typography className={classes.deleteIconText}>Record</Typography>
                    </IconButton>
                    <IconButton className={classes.uploadButton} onClick={uploadFileClick}>
                      <PublishIcon className={classes.deleteIconColor} />
                      <Typography className={classes.deleteIconText} >Upload</Typography>
                      <div style={{ display: 'none' }}>
                        <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
                      </div>
                    </IconButton>
                  </div>
                </Grid>

                <Grid item md={7} sm={12} xs={12}>
                  <Card className={classes.card}>

                    <CardContent>
                      <Grid container spacing={5} className={classes.gridItemCard}>
                        <Grid item md={9} sm={8} xs={12} className={classes.gridItemCard}>
                          <TextField
                            variant={'outlined'}
                            fullWidth
                            size="small"
                            label={fileName}
                            value={fileName}
                            onChange={(e: any) => setFileName(e.target.value)}
                            placeholder="No File selected"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  variant={'filled'}
                                  className={classes.deleteButton}>
                                  <BorderColor className={classes.borderColorIcon} />
                                  <Typography className={classes.borderColorIconText}>
                                    Rename
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} className={classes.gridItemCard}>
                          <IconButton className={classes.iconButton} onClick={deleteAudioFile}>
                            <Delete className={classes.deleteIconColor} />
                            <Typography className={classes.deleteIconText}>Delete</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={5} className={classes.gridItemCard}>
                        <Grid item md={9} sm={8} xs={12} className={classes.vollen1}>
                          {/* <AudioPlayer
                        // elevation={1}
                        width="100%"
                        // variation="default"
                        // spacing={3}
                        // download={true}
                        // autoplay={true}
                        // order="standart"
                        // preload="auto"
                        // loop={true}
                        useStyles={useStyleses}
                        src={srcss}
                      /> */}
                          <ReactAudioPlayer
                            controls
                            className={classes.audioPlayer}
                            // onLoadedMetadata={handleLoadMetadata}
                            src={srcss}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} className={classes.vollen}>
                          <IconButton className={classes.iconButton} onClick={() => onPostDataToServer(fileBuffer, fileName)}>
                            <Save className={classes.deleteIconColor} />
                            <Typography className={classes.deleteIconText}>Save</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>


            </CardContent>
          </ Card>
        )}
    </>

  );
}
export default UnAnsweredTeammembers

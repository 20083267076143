import React from "react";
import Backdrop from "../../components/Modal/Backdrop";
import Modal from "../../components/Modal/Modal";
import {
  Button,
  Card,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import EditContact from "./EditContact/EditContact";
import axiosInstance from "../../environment/axiosInstance";
import { useStyles } from "./style";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
function TableBodyData(props: any) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  const [editId, setEditId] = React.useState();
  const [editContact, setEditContact] = React.useState<any>(false);
  const [deleteId, setDeleteId] = React.useState<any>();
  const [deleteModal, setdeleteModal] = React.useState(false);
  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const contactDelete = (id: any) => {
    axiosInstance
      .delete(`/contacts/${id}`)
      .then((res: any) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setdeleteModal(!deleteModal);
          props.fetchContacts();
        }
      })
      .catch((err: any) => {});
  };

  const handleContactDelete = (id: any) => {
    setDeleteId(id);
    setdeleteModal(!deleteModal);
  };
  const handlePopupModalEditcontact = (id: any) => {
    setEditContact(!editContact);
    setEditId(id);
  };

  return (
    <>
      <div>
        {deleteModal && (
          <div>
            <Backdrop handlePopupModal={handleContactDelete} />
            <Modal
              title="Are you sure want to Delete this Contact "
              handlePopupModal={handleContactDelete}
            >
              <div className="add_user_popup" style={{ width: "500px" }}>
                <Grid container spacing={2} style={{ margin: 10 }}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      size="medium"
                      onClick={() => contactDelete(deleteId)}
                      className={classes.deleteButton}
                    >
                      Delete
                    </Button>
                    <Button
                      size="medium"
                      className={classes.cancelButton}
                      onClick={handleContactDelete}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </div>
        )}
      </div>
      <div>
        {editContact && (
          <div>
            <Backdrop handlePopupModal={handlePopupModalEditcontact} />
            <Modal
              title="Edit Contact"
              handlePopupModal={handlePopupModalEditcontact}
            >
              <div style={{ width: "500px", height: "300px" }}>
                <EditContact
                  fetchContacts={props.fetchContacts}
                  editId={editId}
                  handlePopupModalEditcontact={handlePopupModalEditcontact}
                />
              </div>
            </Modal>
          </div>
        )}
      </div>

      <TableRow>
        <TableCell>{props.item.email}</TableCell>
        <TableCell>
          {props.item.firstName} {props.item.lastName}
        </TableCell>

        <TableCell>{props.item.contactNumber}</TableCell>
        <TableCell>
          <div>
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <BsThreeDots style={{ color: "#4D72F8" }} />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        //onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() =>
                            handlePopupModalEditcontact(props.item?._id)
                          }
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleContactDelete(props.item._id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default TableBodyData;

import {
    Grid, Typography, TextField, Button, Accordion,
    AccordionSummary, Card, Divider,
    AccordionDetails,
} from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import PlanServices from './PlanServices'
import { useHistory } from "react-router-dom";
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'


export default function EditPlan(props: any) {
    const history = useHistory();
    const classes = useStyles()
    const [open, setOpen] = React.useState(true);
    const [id, setId] = React.useState<any>('')
    const [data, setData] = React.useState<any>({
        planName: "",
        cost: "",

        userCount: "",
        phoneNumberCount: ""
    })
    const handleChangePlan = (e: any) => {
        setData((prev: any) => {
            return {
                ...data,
                [e.target.name]: e.target.value
            }
        })
    }
    const SubmitPlans = () => {
        PlanServices.patchPlans(id, data).then((res) => {
            if (res.success) {
                history.push('/plans')
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleOpen = () => {
        setOpen(!open)
    }
    const getPlansById = (id: any) => {
        PlanServices.getPlansByID(id).then((res) => {
            if (res.success) {
                const data = res.data.data
                setData({
                    planName: data.planName,
                    cost: data.cost,

                    userCount: data.userCount,
                    phoneNumberCount: data.phoneNumberCount
                })
                setId(data.id)
            }
        })
    }
    React.useEffect(() => {
        getPlansById(props.match.params.id)
    }, [props.match.params.id])
    return (
        <div>
            <Card className={classes.card}>
                <Accordion expanded={open} onChange={handleOpen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Plans-Update
                        </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <AccordionDetails>
                        <Grid container spacing={8}>
                            <Grid item md={6}>
                                <Typography className={classes.addName}>Plan Name</Typography>
                                <TextField
                                    className={classes.textfild}
                                    size="small"
                                    placeholder="Enter Plan Name"
                                    value={data.planName}
                                    name='planName'
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangePlan}
                                />

                                <Typography className={classes.addName11}>Phone Number Count</Typography>

                                <TextField
                                    className={classes.textfild}
                                    size="small"
                                    placeholder="Enter phoneNumberCount"
                                    value={data.phoneNumberCount}
                                    name="phoneNumberCount"

                                    onChange={handleChangePlan}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <Typography className={classes.addName}>Cost ($)</Typography>
                                <TextField
                                    className={classes.textfild}
                                    size="small"

                                    placeholder="Enter Cost"
                                    value={data.cost}
                                    name="cost"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChangePlan}
                                />
                                <Typography className={classes.addName11}>User Count</Typography>
                                <TextField
                                    className={classes.textfild}
                                    size="small"
                                    placeholder="Enter userCount"
                                    value={data.userCount}
                                    name="userCount"
                                    variant="outlined"
                                    onChange={handleChangePlan}
                                    fullWidth

                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <div style={{ textAlign: "center", marginTop: "12px" }}>

                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.saveButton}
                            onClick={SubmitPlans}
                        >
                            Update
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.cancelButton}
                            //onClick={props.handlePopupModal}
                            onClick={() => history.push('/plans')}
                        >
                            Back
                        </Button>
                    </div>

                </Accordion>
            </Card>
        </div>
    )
}

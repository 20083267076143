import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '30px 0px 30px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
  },
  divider: {
    backgroundColor: '#E2E2E2',
    marginTop:-10
  },
  
  margin: {
    padding: 15,
    paddingBottom: 0,
  },
  tab: {
    background: '#F1F1F1',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 18,
    color: '#8392AB',
  },
  tabActive: {
    background: '#FFFFFF',
    border: '1px solid #dbdbdb',
    borderBottomColor: 'transparent',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: '#4D72F8',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: 10,
    width: '150px',
    height: '45px',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
    borderColor: '#4D72F8',
    borderRadius: 5,
    backgroundColor: '#4D72F8',
    '&:hover': {
      backgroundColor: '#4D72F8',
    },
    '&:active': {
      backgroundColor: '#4D72F8',
    },
  },
  buttonactive: {
    margin: 10,
    width: '150px',
    height: '45px',
    color: '#787878',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'none',
    borderColor: '#F3F5FF',
    borderRadius: 5,
    backgroundColor: '#F3F5FF',
    '&:hover': {
      backgroundColor: '#F3F5FF',
    },
    '&:active': {
      backgroundColor: '#F3F5FF',
    },
  },
  bilCards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  card1: {
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    width: '350px',
  },
  users: {
    padding: 10,
    color: '#4D72F8',
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'work sans',
    fontStyle: 'normal'
  },
  number: {
    fontSize: 40,
    fontWeight: 600,
    color: '#4D72F8',
  },
  div: {
    background: '#F3F5FF',
    textAlign: 'right',
    fontWeight: 500,

  },
  // seats: {
  //   padding: 10,
  //   fontSize: 15,
  //   color: '#787878',
  // },
  numbers: {
    padding: 10,
    fontSize: 16,
    color: '#8392AB',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    marginRight: 10
  },
  addText: {
    marginBottom: 20,
    fontSize: 20,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
  },
  card2: {
    borderRadius: 0,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    padding: '10px 0px',
    marginBottom: 10,
  },
  padding: {
    padding: 10,
  },
  typographydisplay: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  billdisplay: {
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
  },
  billdisplayText: {
    fontSize: 12,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
  },
  updateButton: {
    //textTransform: 'capitalize'
    background: '#3949ab',
    fontSize: 14,
    padding: '6px 22px',
    color: "#FFFFFF",
    borderRadius: 5,
    '&:hover': {
      background: '#3949ab',
      color: "#FFFFFF",
    }
  },
  cancelButton: {
    margin: "0px 15px",
    color: '#2196f3',
    padding: '6px 22px',
    background: '#FFFFFF',
    fontSize: 14,
    marginTop: 25,
    marginLeft: 25
  },
  yesButton: {
    //textTransform: 'capitalize'
    background: '#3949ab',
    fontSize: 14,
    //padding: '6px 22px',
    color: "#FFFFFF",
    borderRadius: 5,
    '&:hover': {
      background: '#3949ab',
      color: "#FFFFFF",
    }
  },
  noButton: {
    margin: "0px 15px",
    color: '#2196f3',
    //padding: '6px 22px',
    background: '#FFFFFF',
    fontSize: 14,
  },
  upgrageButton: {
    background: " #4D72F8",
    marginLeft: "300px",
    marginTop: " 25px",
    fontSize: 14,
    padding: '6px 22px',
    color: "#FFFFFF",
    borderRadius: 5,
    '&:hover': {
      background: '#4D72F8',
      color: "#FFFFFF",
    }
  },
  subscribeButton: {
    textTransform: 'capitalize',
    backgroundColor: '#ECECEC',
    fontFamily: 'work sans',
    fontSize: '16px',
    background: '#ECECEC',
    marginLeft: "370px",
    marginTop: " 25px",
    color: 'white',
    '&:hover': {
      background: '#ECECEC',
      color: 'white',
    },
    "&:active": {
      backgroundColor: "blue",
    },

   

    // background: " #4D72F8",
    // marginLeft: "370px",
    // marginTop: " 25px",
    // fontSize: 14,
    // padding: '6px 22px',
    // color: "#FFFFFF",
    // borderRadius: 5,
    // '&:hover': {
    //   background: '#4D72F8',
    //   color: "#FFFFFF",
    // }
  },
    cardstyles: {
            background: "#FFFFFF",
            borderRadius: "8px",
            //boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
        },
        clientUpdater: {
            color: "#3A416F",
            fontWeight: 500,
            fontFamily: "work Sans",
            fontStyle: "normal",
            fontSize: 18,
        },
       
         filters: {
            color: "#4d6289",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 17,
        },
        selectBox: {
            // marginTop: 6,
            fontSize: 14,
            color: '#8392AB',
        },
        marginBottom20: {
            marginBottom: '20px',
        },
        searchButton: {
            marginTop: 5,
            marginRight: 12,
            background: " #3A416F",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: 12,
            padding: "5px 20px",
            borderRadius: "4.7478px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
        },
        totalAmout: {
            color: "#4d6289",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 20,
            marginTop:-12
        },
        clearButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: 12,
            color: "#8392AB",
            border: "0.791301px solid #8392AB",
            background: "#F5F5F5",
            padding: "6px 20px",
            borderRadius: "4.7478px",
            marginLeft: 10,
            marginTop: 5,
        },
        tableHead: {
            color: "#8392AB",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: 16,
            lineHeight: "16px",
            // letterSpacing: -0.332482
        },
        tableBody: {
            color: "#3A416F",
            fontFamily: "Work sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 13.7377,
            lineHeight: "19px",
            letterSpacing: -0.379979
        },


}))
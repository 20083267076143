import React from 'react';
import { useStyles } from './style';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    Avatar,
    Typography,
    TextField,
    Tooltip,
    TextareaAutosize,
    Table,

    Button,
    Switch,
    IconButton,
    withStyles,

} from '@material-ui/core/';
import Slider from '@material-ui/core/Slider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PhoneIcon from '@material-ui/icons/Phone';
import { AiOutlineMinus, AiOutlineClose } from 'react-icons/ai'
import { FaUserPlus } from 'react-icons/fa';
import { MdSend, MdScheduleSend, MdOutlineDialpad, MdCall, MdOutlineExpandMore, MdPhonePaused, MdCallEnd, MdOutlineMessage } from 'react-icons/md';
import { BiCheckDouble, BiPlay, BiMessageSquareAdd, BiDialpadAlt, BiChevronDown, BiRectangle } from 'react-icons/bi';
import { RiEditBoxFill, RiUser3Fill, RiPauseCircleFill } from 'react-icons/ri';
import Ellipse from '../../assets/icons/Ellipse.png';
import { AiFillCloseCircle, AiFillStar, AiOutlineEdit, AiOutlinePause } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr'
import { InputAdornment } from '@material-ui/core';
import { ImBlocked, ImPhoneHangUp } from 'react-icons/im';
import { IoIosClose, IoMdCall, IoMdKeypad } from 'react-icons/io'
import { BsFillCaretDownFill, BsChatRightText, BsDownload, BsFillMicMuteFill, BsPlusSquareFill, BsWifi, BsFillMicFill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { CgUserAdd } from 'react-icons/cg';
import CallParkIcon from '../../assets/icons/call-park.png'
import { FcCallTransfer } from 'react-icons/fc';
import { GrAdd } from 'react-icons/gr';
import { VscChromeMaximize } from 'react-icons/vsc'
import Modal from '@material-ui/core/Modal';
// import { CardHeader } from '../Header/CardHeader';
import { ImRedo2 } from 'react-icons/im'
import SaveIcon from '@material-ui/icons/Save';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { TiArrowForward } from 'react-icons/ti';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Popup from "reactjs-popup";
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
// import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
import './style.css'
import profile from "../../assets/images/avtar.jpg";
import callTransfer from '../../assets/images/callTransfer.jpg'

export default function IncomingCall(props: any) {
    const classes = useStyles();
    let start = 0
    let intrvl: any
    const [dtmfKeypadIsVisible, setDtmfKeypadIsVisible] = React.useState<boolean>(false);
    const [dtmfNumber, setDtmfNumber] = React.useState<any>("");
    const [open, setOpen] = React.useState<boolean>(false);
    const [answered, setAnswered] = React.useState<boolean>(false);
    const [mute, setMute] = React.useState<boolean>(false);
    const [hold, setHold] = React.useState<boolean>(false);
    const [rotation, setRotation] = React.useState(0)
    const [transferOpen, setTransferOpen] = React.useState<boolean>(false);
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");
    const [transferModalStyle] = React.useState(getTransferModalStyle);
    const [timer, setTimer] = React.useState({
        s: "00",
        m: "00",
        h: "00"
    })
    const handleTranferOpen = () => {
        setTransferOpen(true);
    }
    const handleTransferClose = () => {
        setTransferOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }
    const handleMute = () => {
        setMute(!mute)
        if (mute) {
            props.session.unmute()
        } else {
            props.session.mute()
        }
    }
    function setZeros(i: any) {
        if (i < 10) return "0" + i;
        return i;
    }

    //start timer
    const startTimer = () => {
        // if startTimer is already running
        if (start == 1) return;

        start = 1; // set startTimer is running
        let ss = 0,
            mm = 0,
            hh = 0;
        intrvl = setInterval(() => {
            ss++;
            if (ss == 60) {
                ss = 0;
                mm++;
            }
            if (mm == 60) {
                mm = 0;
                hh++;
            }
            setTimer({
                s: setZeros(ss),
                m: setZeros(mm),
                h: setZeros(hh)
            })

        }, 1000);
    }; // start timer ends
    const stopTimer = () => {
        if (start == 0) return;
        start = 0;
        setTimer({
            s: "00",
            m: "00",
            h: "00"
        });
        clearInterval(intrvl);
    }; // stop timer ends
    const handleHold = () => {
        setHold(!hold)
        if (hold) {
            props.session.unhold()
        } else {
            props.session.hold()
        }
    }
    function getTransferModalStyle() {
        const top = 15;
        const left = 30;
        const right = 30;

        return {
            top: `${top}%`,
            left: `${left}%`,
            right: `${right}%`,
            transform: `translate(-${top}%, -${left}%, -${right}%)`,
        };
    }
    const handleAnswerCall = () => {
        props.session.answer(props.options);
        setAnswered(true)
        startTimer()
    }
    const handleEndCall = () => {
        props.session.terminate();
        props.callModal()
        props.parentMinimise(false)
        props.parentEndCall(false)
        props.end()
    }
    const handleMinimise = () => {
        props.parentMinimise(false)
    }

    const handleDtmf = () => {
        setDtmfKeypadIsVisible(!dtmfKeypadIsVisible);
    }

    const sendDTMF = (digit: any) => {
        setDtmfNumber(dtmfNumber.concat(digit));
        props.session.sendDTMF(digit);
    }

    return (
        <Card style={{ background: 'white' }}>
            <div className={classes.smallDiv}>
                <Typography style={{ fontFamily: 'Work Sans', color: 'green', fontSize: 14 }}>Incoming...</Typography>
                <div className={classes.divCard}>
                    <Typography>{props.CallRouteFrom}</Typography>
                    <Typography>{props.CallRouteFromPhoneNumber}</Typography>
                </div>
            </div>
            <div className={classes.newJhon}>
                <Typography className={classes.wick}>{props.session?._remote_identity?._display_name}</Typography>
            </div>
            <div className={classes.numberOne}>
                <Typography className={classes.numberTwo}>{props.session?._remote_identity?._uri?._user}</Typography>
            </div>
            {props.callStatus && <div className={classes.timeDivTwo}>
                <Typography className={classes.timeContentTwo} >{props.timer?.m + " : " + props.timer?.s}</Typography>
            </div>
            }

            {!answered ?
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: 8, padding: 10 }}>
                        <div style={{}}>
                            <div className={classes.callEnd} onClick={handleEndCall} >
                                <IconButton className={classes.filledIcon}>
                                    <MdCallEnd />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className={classes.callEnd} id="ringing" onClick={handleAnswerCall}>
                                <IconButton className={classes.iconSizeanswer}>
                                    {/* <Icon icon={callEnd16Filled} /> */}
                                    <IoMdCall />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className={classes.callEnd} id="ringing" onClick={handleAnswerCall}>
                                <IconButton className={classes.iconSizeanswerr}>
                                    {/* <Icon icon={callEnd16Filled} /> */}
                                    <MdOutlineMessage />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div style={{}}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: 8, padding: 10, position: "relative" }}>
                            <div style={{}}>
                                <div className={classes.callEnd} onClick={handleEndCall} >
                                    <IconButton className={classes.filledIcon}>
                                        <MdCallEnd />
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div onClick={handleDtmf}>
                                    <IconButton className={classes.filledIcon} style={{ backgroundColor: '#2645AD', color: 'white' }}>
                                        <IoMdKeypad />
                                    </IconButton>
                                </div>
                                {
                                    dtmfKeypadIsVisible && (
                                        <div className={classes.dtmf}>
                                            <div className={classes.dtmfRow}>
                                               <p className={classes.dtmfNumber}>{dtmfNumber}</p>
                                            </div>
                                            <div className={classes.dtmfRow}>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="1"
                                                    onClick={() => sendDTMF('1')}
                                                >1</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="2"
                                                    onClick={() => sendDTMF('2')}
                                                >2</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="3"
                                                    onClick={() => sendDTMF('3')}
                                                >3</button>
                                            </div>
                                            <div className={classes.dtmfRow}>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="4"
                                                    onClick={() => sendDTMF('4')}
                                                >4</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="5"
                                                    onClick={() => sendDTMF('5')}
                                                >5</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="6"
                                                    onClick={() => sendDTMF('6')}
                                                >6</button>
                                            </div>
                                            <div className={classes.dtmfRow}>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="7"
                                                    onClick={() => sendDTMF('7')}
                                                >7</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="8"
                                                    onClick={() => sendDTMF('8')}
                                                >8</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="9"
                                                    onClick={() => sendDTMF('9')}
                                                >9</button>
                                            </div>
                                            <div className={classes.dtmfRow}>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="*"
                                                    onClick={() => sendDTMF('*')}
                                                >*</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="0"
                                                    onClick={() => sendDTMF('0')}
                                                >0</button>
                                                <button
                                                    className={classes.dtmfBtn}
                                                    value="#"
                                                    onClick={() => sendDTMF('#')}
                                                >#</button>
                                            </div>
                                        </div>
                                    )
                                }
                                {mute ?
                                    <div onClick={handleMute} style={{ marginLeft: 6 }}>
                                        <IconButton className={classes.filledIcon} style={{ backgroundColor: '#2645AD', color: 'white' }}>
                                            <BsFillMicMuteFill />
                                        </IconButton>
                                    </div> :
                                    <div onClick={handleMute} style={{ marginLeft: 6 }}>
                                        <IconButton className={classes.filledIcon} style={{ backgroundColor: '#2645AD', color: 'white' }}>
                                            <BsFillMicFill />
                                        </IconButton>
                                    </div>
                                }
                                {hold ?
                                    <div onClick={handleHold}>
                                        <IconButton className={classes.filledIcon} style={{ marginLeft: 6 }}>
                                            <BiPlay />
                                        </IconButton>
                                    </div> :
                                    <div onClick={handleHold} style={{ marginLeft: 6 }}>
                                        <IconButton className={classes.filledIcon}>
                                            <AiOutlinePause />
                                        </IconButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Card>
    )
}

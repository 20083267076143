import { departmentModle } from '../Actions/Actions';
const departmentStates = {
    departmentsData: '',

}

export const sidebarReducer = (state: any = departmentStates, action: any) => {
    switch (action.type) {
        case departmentModle.SIDEBAR_DEPARTMENT:
            return {
                ...state,
                departmentsData: action.payload
            }
        default:
            return state
    }
}
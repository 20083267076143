import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
     modalCard: {
      margin: '10px 0px 10px 0px',
      borderRadius: 10,
      background: '#FFFFFF',
      boxShadow: '0 0 0.5em #E2E2E2',
    },
    userText: {
    margin: '10px 0px 10px 0px',
    fontSize: 18,
    fontFamily: 'Work Sans',
    fontStyle: 'Regular',
    textAlign: 'left',
    fontWeight: 500,
    color: '#828282',
    letterSpacing: 0.208955,
    textTransform: 'none',
    },
    selectField:{
      marginTop:10
    },
    submitButtonn: {
      marginTop:20,
      height: 35,
      borderRadius: '6px',
      padding:'6px 22px',
      color: '#FFFFFF',
      backgroundColor: '#4D72F8',
      borderColor: '#4D72F8',
      marginLeft:'-4px',
      '&:hover': {
        backgroundColor: '#4D72F8',
      },
      '&:active': {
        backgroundColor: '#4D72F8',
      },
    },
    canceltButton:{
      marginTop:20,
      height: 35,
      borderRadius: '6px',
      padding:'6px 22px',
      color: '#4D72F8',
      background: '#fff',
      borderColor: '#4D72F8',
      marginLeft:'-4px',
      '&:hover': {
        background: '#fff',
      },
      '&:active': {
        background: '#4D72F8',
      },
    },
    gridItem: {
      // display: 'flex',
      alignItems: 'center',
      width: '-webkit-fill-available',
      justifyContent: 'flex-start',
    },
    submitButton: {
      height: 42,
      borderTopLeftRadius: 1,
      borderBottomLeftRadius: 1,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      transform: 'translate(-4px, 0px)',
      color: '#FFFFFF',
      backgroundColor: '#4D72F8',
      borderColor: '#4D72F8',
      '&:hover': {
        backgroundColor: '#4D72F8',
      },
      '&:active': {
        backgroundColor: '#4D72F8',
      },
    },
}))
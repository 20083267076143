import { Card, Divider, Typography, Button, Grid, TextField } from '@material-ui/core'
import profileImg from '../../../assets/images/profileImg.png'
import { useStyles } from '../style'
import axiosInstance from '../../../environment/axiosInstance'
import { ImAttachment } from 'react-icons/im'
import { FiSend } from 'react-icons/fi'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useRef, } from 'react';
import socket from '../../Authentication/Config/socket'
import { IoMdCall, IoMdSend } from 'react-icons/io'
import '../style.css'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as CallActionCreator from '../../../store/Actions/CallActionCreator'
import Moment from 'moment'
import { MdMissedVideoCall, MdPhoneCallback } from 'react-icons/md'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GroupInfo from '../GroupInfo/GroupInfo'
import Backdrop from '../../../components/Modal/Backdrop'
import Modal from '../../../components/Modal/Modal'
toast.configure()


function Chat(props: any) {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const [msgList, setMsgList] = React.useState([])
    const { chatId, userId, chatName, listConversations, list, searchResult } = props
    const chatFieldRef: any = useRef(null);
    const chatBodyRef = useRef(null)
    const params: any = useParams()
    const [call, setCall] = React.useState<any>(false)
    const [contactsData, setContactsData] = React.useState<any>([])
    const [outboundVal, setOutboundVal] = React.useState<any>({})
    const callingData: any = useSelector((state: any) => state.CallingData);
    const [number, setNumber] = React.useState<any>('')
    const [option, setOption] = React.useState<any>(null)
    const [cusorposition, setCursorPosition] = React.useState<any>(null)
    const [data, setData] = React.useState({ phoneNumber: '' })
    const [calling, setCalling] = React.useState(false)
    const [openGroupInfoModal, seTOpenGroupInfoModal] = React.useState(false)




    const fetchMessages = () => {
        // console.log('123', id);
        axiosInstance.get(`/chat/get-messages?cid=${params.id}`).then((res: any) => {
            if (res.status === 200) {
                const data = res.data.messageList
                setMsgList(data)
            }
        }).catch((res: any) => {

        })
    }



    React.useEffect(() => {
        fetchMessages()
    }, [params.id])

    //sendMessa
    const sendMessage = () => {
        const content = chatFieldRef.current.value;
        chatFieldRef.current.value = '';
        if (!content || content === '') return;
        const sendData: any = {
            cid: chatId,
            uid: userId,
            content: content,
            username: userId
        }
        axiosInstance.post(`/chat/send-message`, sendData).then((res: any) => {
            if (res.status === 200) {
                fetchMessages()
                listConversations()
                socket.emit('user-send-message', {
                    conversation: res.data.conversation,
                    newMessage: res.data.newMessage
                });
            }
        }).catch((err: any) => {

        })
    }


    //receive conversation

    React.useEffect(() => {
        socket.on('receive-message', (data) => {
            fetchMessages()
            listConversations()
            // let datalist: any = data
            // let listMsgsData: any = [...msgList]
            // listMsgsData.push(datalist)
            // setMsgList(listMsgsData)
        });

        return () => {
            socket.removeAllListeners('receive-message')
        }
    }, [params.id]);




    React.useEffect(() => {
        if (chatBodyRef && chatBodyRef.current) {
            const element: any = chatBodyRef.current;
            element.scroll({
                top: element.scrollHeight,
                left: 0,
                // behavior: "smooth"
            })
        }

    }, [chatBodyRef, msgList])







    //calling 
    const parentCall = (child: any) => {
        console.log('child', child);

        setData(child)
        setNumber(child.phoneNumber)
        // console.log("----------numbetr")
        // dispatch(CallActionCreator.fetchStatus(data))
        // dispatch(CallActionCreator.fetchData(true))
    }

    // const parentCalling = (child: any) => {
    //     setCalling(child)
    // }

    const callingFunction = () => {

        setCall((current: any) => !current)
        parentCall({
            phoneNumber: params.id,
            name: params.id
        })
        if (callingData?.callData && typeof (callingData?.callData?.phoneNumber) !== 'undefined' && callingData?.callData?.phoneNumber !== "") {
            // console.log('callingData321000', callingData?.callData)
            //toast.error("Already you are in call", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
        }

        else {

            var Options: any = props?.callOptions
            Options.extraHeaders = [`X-userId:${userId}`]
            setOption(Options)
            const data = {
                phoneNumber: params.id,
                name: `${chatName}`,
                options: Options
            }
            dispatch(CallActionCreator.fetchStatus(data))
            dispatch(CallActionCreator.fetchData(true))
            setCursorPosition(null)

        }
    }


    const handleChangeOutbound = (e: any) => {
        setOutboundVal(e)
    }


    const groupMembersInfo = () => {
        seTOpenGroupInfoModal(!openGroupInfoModal)
    }





    return (
        <>
            {
                openGroupInfoModal && (
                    <div>
                        <Backdrop handlePopupModal={groupMembersInfo} />
                        <Modal title="Group Info" handlePopupModal={groupMembersInfo}>
                            <div className="add_user_popup" style={{ width: '300px', }}>
                                <GroupInfo
                                    groupMembersInfo={groupMembersInfo}
                                    list={list}
                                    searchResult={searchResult}
                                />
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div className={classes.rightCard}

            >
                <Card className={classes.rightCardbody}>
                    <div className={classes.headerFlex} >
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginLeft: 14 }}>
                            <AccountCircleIcon />
                            <Typography>{chatName}</Typography>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginRight: 18, cursor: 'pointer' }}>
                            {
                                !chatName &&
                                <Button
                                    onClick={groupMembersInfo}
                                    className={classes.groupBtn}
                                    size="small"
                                    variant='outlined'>
                                    Group Info
                                </Button>
                            }
                            {/* <Button
                                onClick={groupMembersInfo}
                                className={classes.groupBtn}
                                size="small"
                                variant='outlined'>
                                Group Info
                            </Button> */}
                            <IoMdCall className={classes.callicon} onClick={callingFunction} />
                        </div>
                    </div>
                    <Divider />


                    <div id="scrollbarChat" ref={chatBodyRef} style={{ overflowY: 'auto', overflowX: 'hidden', height: '70vh' }}>
                        {
                            msgList.map((msg: any) => {
                                var data = parseInt(msg.time)
                                var dataa = Moment(msg.time).format('HH:mm A')
                                return (
                                    <div>
                                        <Grid container spacing={2}>
                                            {
                                                msg.ofUser !== userId ?

                                                    <Grid item md={6} >
                                                        <div className={classes.leftMsg}>
                                                            <div style={{ display: 'flex', gap: 8 }}>
                                                                <AccountCircleIcon />
                                                                <Typography>{msg.content}</Typography>
                                                            </div>
                                                            <div style={{ marginTop: 20 }}>
                                                                <Typography style={{ fontSize: 12, float: 'right' }}>{dataa}</Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    :
                                                    <Grid item md={6} style={{ marginLeft: 415 }}>
                                                        <div className={classes.rightMsg}>
                                                            <div style={{ display: 'flex', gap: 8 }}>
                                                                <AccountCircleIcon />
                                                                <Typography>{msg.content}</Typography>
                                                            </div>
                                                            <div style={{ marginTop: 20 }}>
                                                                <Typography style={{ fontSize: 12, }}>{dataa}</Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </div>
                                )
                            })
                        }
                        <div className={classes.voiceCall} style={{ marginTop: 10 }}>
                            <div className={classes.voiceCalls}>
                                <MdPhoneCallback color='red' />
                                <Typography className={classes.missesdCalls}>Missed Voice Call at 11:22 AM</Typography>
                            </div>
                        </div>
                        <div className={classes.voiceCall} style={{ marginTop: 10, marginBottom: 20 }}>
                            <div className={classes.voiceCalls}>
                                <MdMissedVideoCall color='red' />
                                <Typography className={classes.missesdCalls}>Missed Video Call at 11:22 AM</Typography>
                            </div>
                        </div>

                    </div>


                    {/* footer */}

                    <div>
                        <form style={{ marginRight: 28, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            onSubmit={(e: any) => {
                                e.preventDefault()
                                sendMessage()
                            }}>
                            <input
                                ref={chatFieldRef}
                                className={classes.inputField + ' enter_message_input'}
                                placeholder="Input your message..."
                            />
                            <div>
                                {/* <ImAttachment /> */}
                                <Button
                                    type='submit'
                                    className={classes.sendBtn}
                                    size='small' variant='outlined'
                                    endIcon={<IoMdSend />}>Send
                                </Button>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default Chat
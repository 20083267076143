import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CheckBox,
  MoreHoriz,
  MoreHorizOutlined,
  MoreHorizRounded,
  RecentActorsRounded,
  Search,
  SearchOutlined,
} from "@material-ui/icons";
import React from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { IoMdAnalytics, IoMdContact } from "react-icons/io";
import { RiBillLine } from "react-icons/ri";
import { useStyles } from "./styles";
import ReactSelect from "react-select";
import { MdAddBox } from "react-icons/md";
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
import EditUserModal from "../../../components/Modal/EditUserModal";
import AddUserModal from "../../../components/Modal/UserModal";
import AddDepartment from "./AddDepartment/AddDepartment";
import AddUser from "./AddUser/AddUser";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import UsersTableBody from "./UsersTableBody";
import userServices from "./userServices";
import { Multiselect } from "multiselect-react-dropdown";
import UpdateUserData from "./EditUser/UpdateUserData";

const options = [
  {
    value: 1,
    key: "Admin",
  },
  {
    value: 2,
    key: "Billing",
  },
  {
    value: 3,
    key: "Analytics",
  },
  {
    value: 4,
    key: "Recordings",
  },
];

function Users(props: any) {
  const classes = useStyles();
  const [loading, setloading] = React.useState(false);
  const [permissions, setPermissions] = React.useState<any>([]);
  const [usersList, setUsersList] = React.useState<any>([]);
  const [addDepartment, setAddDepartment] = React.useState(false);
  const [updateUser, setUpdateUser] = React.useState(false);
  const [addNewUser, setAddNewUser] = React.useState(false);
  const anchorRef: any = React.useRef<any>(null);
  // const [open, setOpen] = React.useState<any>(true);
  const [openUsers, setOpenUsers] = React.useState<any>(true);
  const [updateData, setUpdateData] = React.useState<any>([]);
  const [updateUserId, setupdateUserId] = React.useState<any>([]);
  const [selecteduserId, setsSelecteduserId] = React.useState<any>();
  const [selectedDeparmentList, setSelectedDeparmentList] = React.useState<any>(
    []
  );
  const [userListdepartmentNumbers, setUserListdepartmentNumbers] =
    React.useState<any>([]);

  const editUserPermissions = (userId: any, data: any) => {
    // console.log("data", data)
    setupdateUserId(userId);
    setUpdateData(data);
    setUpdateUser(!updateUser);
  };
  const handleEditUser = () => {
    setUpdateUser(!updateUser);
  };
  const expandOpen = () => {
    setOpenUsers(!openUsers);
  };
  const handlePopupModalAddUser = () => {
    setAddNewUser(!addNewUser);
  };
  const handlePopUpAddDepartmentModal = (uerId: any, deparmentList: any) => {
    setsSelecteduserId(uerId);
    setAddDepartment(!addDepartment);
    setSelectedDeparmentList(deparmentList);
  };

  const fetchUsersList = () => {
    setloading(true);
    userServices
      .fetchUsers()
      .then((res: any) => {
        // console.log("usersdata", res)
        if (res.success) {
          setUsersList(res.data.data);
          setUserListdepartmentNumbers(res.data.departmentNumbers);
          setloading(false);
        }
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    fetchUsersList();
  }, []);

  const onChaneSelect = (e: any) => {
    // console.log(e)
    const arr: any = [];
    e.map((item: any) => {
      arr.push(item.value);
    });
    // console.log(arr)
    const filteredList: any = [];
    usersList.map((user: any) => {
      const permiss = user.permissions;
      arr.map((item: any) => {
        if (permiss?.includes(item)) {
          filteredList.push(user);
        }
      });
    });
    setUsersList(filteredList);
    // console.log("filteredList",filteredList)
    // setPermissions(arr)
  };

  const onRemoveSelect = (e: any) => {
    // console.log(e)
    const arr: any = [];
    e.map((item: any) => {
      arr.push(item.value);
    });
    // console.log(arr)
    if (arr.length === 0) {
      fetchUsersList();
    } else {
      const filteredList: any = [];
      usersList.map((user: any) => {
        const permiss = user.permissions;
        arr.map((item: any) => {
          if (permiss?.includes(item)) {
            filteredList.push(user);
          }
        });
      });
      setUsersList(filteredList);
      // console.log("filteredList",filteredList)
    }
  };
  const [search, setSearch] = React.useState("");
  const hanldeUsersSearch = (e: any) => {
    // console.log(e.target.value)
    if (e.target.value === "") {
      fetchUsersList();
    } else {
      setSearch(e.target.value);
      const results = usersList
        ?.filter((itm: any) =>
          itm.firstName?.toLowerCase().includes(search.toLowerCase())
        )
        .map((item: any, id: any) => {
          return item;
        });
      // console.log(results)
      setUsersList(results);
    }
  };

  return (
    <div className={classes.usersContainer}>
      {addDepartment && (
        <div>
          <Backdrop handlePopupModal={handlePopUpAddDepartmentModal} />
          <Modal
            title="Add Department"
            handlePopupModal={handlePopUpAddDepartmentModal}
          >
            <div className={classes.add_user_popup}>
              <AddDepartment
                handlePopupModal={handlePopUpAddDepartmentModal}
                selecteduserId={selecteduserId} 
                selectedDeparmentList={selectedDeparmentList}
                fetchUserLists={fetchUsersList}
              />
            </div>
          </Modal>
        </div>
      )}

      {updateUser && (
        <div>
          <Backdrop handlePopupModal={handleEditUser} />
          <EditUserModal handlePopupModal={handleEditUser}>
            <div className={classes.updatemodalpopup}>
              <UpdateUserData
                handlePopupModal={handleEditUser}
                data={updateData}
                id={updateUserId}
                fetchUsersList={fetchUsersList}
                userListdepartmentNumbers={userListdepartmentNumbers}
              />
            </div>
          </EditUserModal>
        </div>
      )}
      {addNewUser && (
        <div>
          <Backdrop handlePopupModal={handlePopupModalAddUser} />
          <AddUserModal
            title="Add User"
            handlePopupModal={handlePopupModalAddUser}
          >
            <div className={classes.adduserModal}>
              <AddUser
                handlePopupModal={handlePopupModalAddUser}
                fetchUserLists={fetchUsersList}
              />
            </div>
          </AddUserModal>
        </div>
      )}

      {/* <Card className={classes.card} > */}
      <Accordion
        expanded={openUsers}
        className={classes.card}
        onChange={expandOpen}
      >
        <AccordionSummary
          expandIcon={
            openUsers ? (
              <FiMinusCircle color="lightgrey" />
            ) : (
              <FiPlusCircle color="lightgrey" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.users}>Users</Typography>
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails
          style={{ display: "block", padding: "2px 30px 30px" }}
        >
          <Button
            className={classes.add}
            style={{ marginTop: "20px" }}
            onClick={handlePopupModalAddUser}
          >
            Add User
          </Button>
          <div>
            <Grid
              container
              spacing={10}
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Grid item md={4}>
                <TextField
                  className={classes.textfield}
                  InputProps={{
                    startAdornment: (
                      <IconButton className={classes.search}>
                        <Search />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                  size="small"
                  placeholder="Search users"
                  variant="outlined"
                  onChange={hanldeUsersSearch}
                />
              </Grid>
              <Grid item md={3}>
                <Multiselect
                  placeholder="Select Permissions"
                  options={options}
                  showCheckbox
                  displayValue="key"
                  onSelect={onChaneSelect}
                  onRemove={onRemoveSelect}
                  className={classes.selectperm}
                />
              </Grid>
            </Grid>
          </div>
          <Card className={classes.table}>
            <TableContainer style={{ overflowY: "auto", overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.text}>User</TableCell>
                    <TableCell className={classes.text}>Email</TableCell>
                    <TableCell className={classes.text}>Number</TableCell>
                    <TableCell className={classes.text}>Directory</TableCell>
                    <TableCell className={classes.text}>Permission</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 480,
                        marginTop: 80,
                        marginBottom: 40,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    usersList.map((item: any, i: any) => (
                      <UsersTableBody
                        handlePopUpAddDepartmentModal={
                          handlePopUpAddDepartmentModal
                        }
                        editUserPermissions={editUserPermissions}
                        data={item}
                        fetchUsersList={fetchUsersList}
                        loading={loading}
                        key={i}
                        // userListdepartmentNumbers={userListdepartmentNumbers}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </AccordionDetails>
      </Accordion>
      {/* </Card> */}
    </div>
  );
}

export default Users;

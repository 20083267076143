import React from 'react'
import { Button, Checkbox, ClickAwayListener, Divider, Grid, Grow, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { Description, MoreHorizRounded, Person, RecordVoiceOver, Timeline } from '@material-ui/icons';
import { AiTwotoneDelete, AiTwotoneEdit } from 'react-icons/ai';
import { MdAddBox } from 'react-icons/md';
import { useSelector } from 'react-redux'
// import WebAnalytics from '../../../assets/images/WebAnalytics.png';
// import ReceiptandChange from '../../../assets/images/ReceiptandChange.png';
// import MaleUser from '../../../assets/images/MaleUser.png';
import axiosInstance from '../../../environment/axiosInstance';
import userServices from './userServices'
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function UsersTableBody(props: any) {
    const [permisions, setPermissions] = React.useState<any>([])
    const [userData, setuserData] = React.useState<any>([])
    const [directory, setDirectory] = React.useState<any>(false)
    const [deleteModal, setdeleteModal] = React.useState<any>(false);

    const { _id, firstName, lastName, email, phonenumbers, deparmentList, count, directoryEnabled, permissions } = props.data

    const classes = useStyles()
    const anchorRef: any = React.useRef<any>(null);
    const [openOptions, setOpenOptions] = React.useState<any>(false);

    const profileDataStore = useSelector((state: any) => state.profileData)
    const { profileData } = profileDataStore;
    const usertype: any = profileData.userType

    React.useEffect(() => {
        setPermissions(props.data.permissions)
        setDirectory(props.data.directoryEnabled)
        setuserData(props.data)
    }, [props.data])


    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenOptions(false);
    };
    const handleToggle = () => {
        setOpenOptions((prevOpen: any) => !prevOpen);
    };
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenOptions(false);
        }
    }

    const onChangeOperations = (id: any) => {
        if (permissions?.includes(id)) {
            const index = permissions.findIndex((item: any) => {
                if (item === id) {
                    return item
                }
            })
            // console.log(index)
            // console.log("permisions", permisions)
            permisions.splice(index, 1)
            const data = {
                directoryEnabled: directory,
                permissions: permisions
            }
            // console.log(data)
            userServices.updateUserPermissions(data, _id).then((res: any) => {
                // console.log("patchresponse", res)
                if (res.success) {
                    props.fetchUsersList()
                }
            }).catch((err: any) => {

            })

        } else {
            permisions.push(id)
            const data = {
                directoryEnabled: directory,
                permissions: permisions
            }
            // console.log(data)
            userServices.updateUserPermissions(data, _id).then((res: any) => {
                // console.log("patchresponse", res)
                if (res.success) {
                    props.fetchUsersList()
                }
            }).catch((err: any) => {

            })
        }
    }

    // console.log("permisions", permisions)

    const updateDirectoryEnable = (value: any) => {
        const data = {
            directoryEnabled: value,
            permissions: permisions
        }
        // console.log(data)
        userServices.updateUserPermissions(data, _id).then((res: any) => {
            // console.log("patchresponse", res)
            if (res.success) {
                props.fetchUsersList()
            }
        }).catch((err: any) => {

        })
    }

    const onchangeDirectoryEnable = (e: any) => {
        // console.log(e.target.checked)
        setDirectory(e.target.checked)
        updateDirectoryEnable(e.target.checked)
    }
    // console.log("directory",directory)
    const Permissions = () => {

        return (
            <div style={{ display: 'flex' }}>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(1)}
                            className={`${permissions?.includes(1) ? classes.activeButton : classes.button}`}
                        >
                            {/* <Person className={`${operations?.isAdmin === true ? classes.userIcon : classes.adminIcon}`} /> */}
                            <Person
                                className={`${permissions?.includes(1) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Admin</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(2)}
                            className={`${permissions?.includes(2) ? classes.activeButton : classes.button}`}
                        >
                            <Description className={`${permissions?.includes(2) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Billing</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(3)}
                            className={`${permissions?.includes(3) ? classes.activeButton : classes.button}`}
                        >
                            <Timeline className={`${permissions?.includes(3) ? classes.activeIcon : classes.inActiveIcon}`} />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Analytics</Typography>
                </div>
                <div className={classes.tooltip} style={{ bottom: 10 }}>
                    <Tooltip title='Update Permissions'>
                        <div
                            onClick={() => onChangeOperations(4)}
                            className={`${permissions?.includes(4) ? classes.activeButton : classes.button}`}
                        >
                            <RecordVoiceOver
                                className={`${permissions?.includes(4) ? classes.activeIcon : classes.inActiveIcon}`}
                            />
                        </div>
                    </Tooltip>
                    <Typography className={classes.permissName}>Recordings</Typography>
                </div>

                {/* <Tooltip title={"Analytics"} placement="bottom" className="tooltip" style={{ bottom: 10 }}>
                    <IconButton
                        className={`${permissions?.includes(2) ? classes.activeButton : classes.button}`}
                    >
                        <img src={WebAnalytics} alt="WebAnalytics" className={`${permissions?.includes(2) ? classes.userIcon : classes.adminIcon}`} />
                    </IconButton>
                </Tooltip> */}

                {/* <Tooltip title={"Billings"} placement="bottom" className="tooltip" style={{ bottom: 10 }}>
                    <IconButton
                        className={`${operations?.billingEnbale === true ? classes.button : classes.adminButton}`}
                        onClick={() => onChangeOperations('billingEnbale')}>
                        <Description className={`${operations?.billingEnbale === true ? classes.userIcon : classes.adminIcon}`} />
                    </IconButton>
                </Tooltip> */}


            </div>
        );
    };
    const handleDeleteOption = () => {
        setdeleteModal(!deleteModal);
    };

    const handleDeleteUser = () => {
        userServices.deleteUser(_id).then((res: any) => {
            // console.log("patchresponse", res)
            if (res.success) {
                props.fetchUsersList()
            }
        }).catch((err: any) => {

        })
    }

    const onClickLoginAs = async () => {
        await axiosInstance.post(`/users/loginAsUser?userId=${_id}`).then((res: any) => {
            if (res.status === 200) {
                const responseToken = res.data.data.token;
                // console.log(process.env.REACT_APP_USER_PORTAL_URL)
                // console.log(process.env.REACT_APP_BUSINESS_PORTAL_URL)
                // console.log("responseToken",responseToken)
                const userPortalUrl = `${process.env.REACT_APP_BUSINESS_PORTAL_URL}/login-as?token=${responseToken}`
                window.open(userPortalUrl, "_blank")

                // window.open(`https://app.efone.ca/login-as?token=${responseToken}`, "_blank")
                // const userPortalUrl = `${process.env.REACT_APP_USER_PORTAL_URL}/login-as?token=${responseToken}`
                // window.open(userPortalUrl, "_blank")
                //  window.open(`http://localhost:3001/login-as?token=${responseToken}`, "_blank")

            }
            else if (res.status === 401) {

            }
        })
            .catch((err: any) => {
            });

    };


    return (
        <>
            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeleteOption} />
                        <Modal title="Are you sure want to Delete User ?" handlePopupModal={handleDeleteOption} >
                            <div className={classes.delete_user_popup} >
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div style={{ margin: '10px' }}>
                                            <Button size="medium" onClick={handleDeleteUser} variant='contained' className={classes.deleteModalBtn} >Delete</Button>
                                            <Button size="medium" onClick={handleDeleteOption} variant='contained' className={classes.deleteModalBtn} >Cancel</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div >
                )
            }
            <TableRow key={props.key}>
                <TableCell className={classes.body}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {
                            firstName === undefined ? <span>{email}</span> : <span>{firstName + ' ' + lastName}</span>
                        }
                        <Tooltip title='Edit User'>
                            <span className={classes.userDeptCount}
                                onClick={() => props.editUserPermissions(userData._id, userData)}>
                                {count}
                            </span>
                        </Tooltip>

                    </div>
                </TableCell>
                <TableCell className={classes.body}>{email}</TableCell>
                <TableCell className={classes.body}>
                    {phonenumbers.map((item: any) => {
                        return (
                            <span>{item}<br /></span>
                        )
                    })}
                </TableCell>
                <TableCell className={classes.body}>
                    <Checkbox
                        onChange={onchangeDirectoryEnable}
                        checked={directoryEnabled}
                        color="primary"
                        className={classes.permCheckbox} />
                </TableCell>
                <TableCell className={classes.body} >

                    {/* <div className={classes.avatarflex}>
                    <Avatar className={classes.avatar}><IoMdContact />
                    </Avatar>
                    <Typography>isadamin</Typography>
                    <Avatar className={classes.avatar}><RiBillLine />
                    </Avatar>
                    <Avatar className={classes.avatar}><IoMdAnalytics />
                    </Avatar>
                </div>  */}
                    <Permissions />
                </TableCell>
                <TableCell className={classes.body}>
                    <Button
                        ref={anchorRef}
                        aria-controls={openOptions ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        <MoreHorizRounded />
                    </Button>
                    <Popper
                        open={openOptions}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "" : "",
                                }}>
                                <Paper className={classes.actionOptions} >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={openOptions}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem className={classes.options} onClick={() => props.handlePopUpAddDepartmentModal(_id, deparmentList)} >
                                                <ListItemIcon style={{ alignItems: 'center' }}>
                                                    <MdAddBox className={classes.iconClor} />
                                                    <ListItemText className={classes.textColor}>Add department</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem>
                                            <MenuItem >
                                                <ListItemIcon style={{ alignItems: 'center' }}>
                                                    <AiTwotoneEdit style={{ color: '#4D72F8' }} className={classes.iconClor} />
                                                    <ListItemText className={classes.textColor}>Edit</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem>
                                            <MenuItem >
                                                <ListItemIcon style={{ alignItems: 'center' }}
                                                    onClick={() => handleDeleteOption()}>
                                                    <AiTwotoneDelete style={{ color: '#BB271A' }} className={classes.iconClor} />
                                                    <ListItemText className={classes.textColor} >UnAssign</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem>
                                            {
                                                usertype === 2 &&
                                                (
                                                    <MenuItem >
                                                        <ListItemIcon style={{ alignItems: 'center' }}
                                                            onClick={onClickLoginAs}>
                                                            <Person color='inherit' style={{ color: '#4D72F8' }} className={classes.iconClor} />
                                                            <ListItemText className={classes.textColor} >LoginAs <span>{firstName + ' ' + lastName}</span></ListItemText>
                                                        </ListItemIcon>
                                                    </MenuItem>
                                                )
                                            }

                                        </MenuList>

                                    </ClickAwayListener>
                                </Paper>
                            </Grow>

                        )}

                    </Popper>
                </TableCell>
            </TableRow>
        </>

    )
}

export default UsersTableBody
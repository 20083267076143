import { Button, Card, Grid, Tab, Tabs, TextField, Typography } from '@material-ui/core'
import React from 'react'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import { TabContext, TabPanel } from '@material-ui/lab';
import { BiPlusCircle } from 'react-icons/bi'
import { useStyles } from './styles';
import axiosInstance from '../../../../environment/axiosInstance'
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RecordAudio from './RecordAudio';
import UploadFile from './UploadFile';
import AddNumber from './AddNumber';
toast.configure()





const muiTheme = createMuiTheme({});
const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });


function CloseHoursPhoneNumber(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [addNumberModal, setAddNumberModal] = React.useState(false);
    const [frwNumber, setFrwNumber] = React.useState([])
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }

    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }
    const handlePopupModalNumber = () => {
        setAddNumberModal(!addNumberModal)
    }

    React.useEffect(() => {
        const numbers: any = []
        if (props.closedData) {
            props.closedData.forwardNumbers?.phoneNumbers?.map((item: any) => {
                var myObj = {
                    label: item.phoneNumber,
                    value: item._id,
                    isSelected: item.isSelected
                }
                numbers.push(myObj)
            })
            setFrwNumber(numbers)
        }
    }, [props.closedData])

    const selectedNum = frwNumber.findIndex((option: any) => {
        if (option.isSelected === true) {
            return option
        }
    })

    //update number
    const onChangePhoneNumberDropDown = (e: any) => {
        const id = e.value
        const data = {
            forwardNumberId: id
        }
        axiosInstance.patch('/profile/settings/closed-hours/forward-number', data).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getCloseHours()
            }

        }).catch((err) => {
            //send err
        })

    }

    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div style={{ marginBottom: 17, height: 600, }}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            getCloseHours={props.getCloseHours}
                                            closedData={props.closedData}
                                        />

                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadFile
                                            getCloseHours={props.getCloseHours}
                                            closedData={props.closedData}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }

            {
                addNumberModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Add Number" handlePopupModal={handlePopupModalNumber}>
                            <div className="add_user_popup" style={{ width: "500px", padding: '10px 40px 10px 30px' }}>
                                <AddNumber
                                    handlePopupModal={handlePopupModalNumber}
                                    getCloseHours={props.getCloseHours}
                                />
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={8} sm={6}>
                            <Typography className={classes.title}>Enter Number</Typography>
                            <ReactSelect
                                options={frwNumber}
                                value={frwNumber[selectedNum]}
                                onChange={onChangePhoneNumberDropDown}
                                placeholder="Select Number"
                            />
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title}>.</Typography>
                            <div
                                className={classes.button}
                                style={{ border: '1px solid lightgrey' }}
                                onClick={handlePopupModalNumber}
                            >
                                <BiPlusCircle onClick={handlePopupModalNumber} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{marginTop:'30px'}}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ReactAudioPlayer
                                controls
                                className={classes.audioPlayer}
                                onLoadedMetadata={handleLoadMetadata}
                                src={props.forwardPath}
                            />
                            <div style={{ textAlign: 'left', marginTop: '5px' }}>
                                <span className={classes.filename}>{props.forwardFileName}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>Edit</Button>
                        </Grid>
                    </Grid>

                </Card>
            </div>
        </>
    )
}

export default CloseHoursPhoneNumber
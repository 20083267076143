import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  {
    sidebarItemContainer: {
      fontFamily: "Calibri",
      fontStyle: "normal",

    },
    addDept:{
      textTransform:'capitalize',
      fontSize:12,
      marginLeft:'20px',
      padding:'3px 20px',
      color:'#4D72F8',
      background:'rgba(77, 114, 248, 0.24)',
      marginTop:10,
    },
    sidebarItemText: {
      fontWeight: 700,
      fontSize: 18,
      color: "#848282",
      lineHeight: 22
    },
    selectBox: {
      // marginTop: 6,
      fontSize: 14,
      color: '#8392AB',
    },
    routeNumber: {
      color: '#8392AB',
    },
    countryName: {
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      color: "#3A416F",
    },
    active: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      fontSize: 10,
      color: "#FFFFFF",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: "bold",
      borderRadius: 5,
    },
    route: {
      color: "#00407B",
      fontSize: 14,
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
    },
    cardstyles2: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      maxHeight: 164,
      overflowY: 'auto'
    },
    freeMinutes: {
      textAlign: "center",
      padding: 12,
      color: "#00407B",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
    },
    tableCard3: {
      background: "#FFFFFF",
      borderRadius: "10.3033px",
      boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
      overflow: "visible",
      maxHeight: '400px',
      overflowY: 'auto',
      marginTop: 10
    },
    space: {
      // marginTop: 28,
      color: '#3A416F',
    },
    calender2: {
      background: '#F5F5F5',
      color: '#3A416F',
    },
    tooltip: {
      // fontSsize: "13px",
      color: "white",
      fontFamily: "open Sans",
      // fontWeight: 400,
      // //padding: '6px 16px',
      // borderRadius: "0px 10px 0px 0px",
      // //backgroundColor: "#2645ad",
      // top: 6,
      // position: 'relative',
      // left: -10,
      // lineHeight: 'auto',
      // maxWidth: 'auto'
    },
    mT10: { marginTop: 10, },
    marginBottom20: {
      marginBottom: 20,
    },
    clientUpdater: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontSize: 20,
    },
    cardstyles: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    accordion: {
      background: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
      //boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    lastName: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      // marginTop: 8,
      marginRight: 15,
    },

    webLogin: {
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    inputFields: {
      margin: '10px 0px 20px',
      display: 'flex',
      alignItems: 'center'
    },
    userName: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      // marginTop: '10px',
    },
    saveButton: {
      color: 'white',
      border: "1px solid darkgreen",
      borderRadius: 8,
      paddingTop: 8,
      paddingBottom: 8,
      width: "40%",
      boxSizing: "border-box",
      marginBottom: 20,
      backgroundColor: "darkgreen",
      "&:hover": {
        color: "white",
        background: "darkgreen",
      },
    },
    errorMessage: {
      color: 'red',
      fontSize: '0.8rem',
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },

    userName2: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      marginTop: '6px',
    },
    marginTop10: {
      marginTop: '10px',
    },
    tableCard: {
      background: "#FFFFFF",
      borderRadius: "10.3033px",
      // border: "1px solid #F5F5F5",
      boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
      // marginTop: 10,
      overflow: "visible",
    },
    clientDetails: {
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    card: {
      margin: '15px 0px 0px 0px',
      borderRadius: 10,
      background: '#FFFFFF',
      boxShadow: '0 0 0.5em #E2E2E2',
    },
    heading: {
      color: "#2645AD",
      fontSize: 26,
      fontWeight: 600,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      "@media (max-width: 426px)": {
        fontSize: '16px'
      }
    },
    accordianNoFlex: {
      display: 'block'
    },
    tabPad: {
      padding: 0,
      marginTop: 30,
    },
    tab: {
      textTransform: "capitalize",
      background: "#FFFFFF",
      boxSizing: "border-box",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 18,
      color: "#8392AB",

    },
    tabActive: {
      background: "#FFFFFF",
      boxSizing: "border-box",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 18,
      // padding:"16px 0",
      //color: "#00407B",
      textTransform: "capitalize",
    },
    tabs: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
    },
    submitButton: {
      padding: '4px 50px',
      border: '1px solid #00407B',
      borderRadius: '8px',
      fontWeight: 'bold',
      color: '#fff',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      marginRight: 15,
      background: '#00407B',
      "&:hover": {
        color: '#00407B',
      },

    },
    cancelButton: {
      padding: '4px 34px',
      border: '1px solid #7b7b7c',
      borderRadius: '8px',
      fontWeight: 'bold',
      color: '#7b7b7c',
      fontFamily: 'Work Sans',
      fontStyle: 'normal',
      background: '#E7E7E7',
    },
    saveSec: {
      textAlign: "center",
      background: "#fff",
      padding: "20px 0px",
      margin: "20px 0px 0px",
      boxShadow: "0px 2px 10px rgb(0 0 0 / 20%)",
      borderRadius: "5px",
    },
    searchFilter: {
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' },
    addticket: {
      marginTop: 5,
      marginRight: 19,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },

    AgentList: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: 20,
      left: "337px"
    },
    TotalUsers: {
      color: "#3A416F",
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontSize: 16,
    },
    PortalUsers: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: 20,
    },
    divider: {
      background: "#E2E2E2",
    },
    alignCenter: {
      textAlign: "center",
      display: "block",
    },
    phoneNumber: {
      marginTop: 25,
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
    },
    textfield: {
      marginTop: 10,
    },
    divButtons: {
      marginTop: 12,
      // marginLeft: 15,
    },
    searchButton: {
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      borderRadius: "5px",
      padding: "6px 20px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    clearButton: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      color: "#8392AB",
      border: "0.791301px solid #8392AB",
      background: "#F5F5F5",
      borderRadius: "5px",
      padding: "6px 20px",
      marginLeft: 15,
    },
    addButton: {
      marginTop: 10,
      marginleft: 129,
      background: " #3A416F",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " #3A416F",
      },
    },
    portalUsers: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: 16,
      marginTop: 20,
    },
    tableHead: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
    },
    tableBody: {
      color: "#8392AB",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      alignItems: "center",
    },
    tableBodyy: {
      color: "#3A416F",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      alignItems: "center",
      width: 200
    },
    tableCardOne: {
      background: "#FFFFFF",
      borderRadius: "7.51286px",
      boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
      marginTop: 15,
      width: "100%",
    },
    actionButton: {
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      textAlign: "center",
      color: "#3A416F",
      height: 20,
      width: 70,
    },
    editButton: {
      fontSize: 13,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 700,
      textAlign: "center",
      color: "#3A416F",
      height: 20,
      width: 70,
      textDecoration: "none"
    },
    grid: {
      marginTop: 25,
    },

    profile: {
      color: "#8392AB",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
    },
    select: {
      marginTop: 8,
    },
    formControl: {
      margin: "theme.spacing(1)",
      minWidth: 340,
      top: 17,

    },
    textArea: {
      width: "100%",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      color: "black",
    },
    filters: {
      color: "#4d6289",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 19,
    },
    activeButton: {
      background: "linear-gradient(135deg, #98EC2D 0%, #17AD37 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position: "absolute",
      padding: 5,
      marginTop: -11
    },
    pendingButton: {
      background: "linear-gradient(135deg, #ff4500 0%, #bf4f51 100%)",
      color: "#FFFFFF",
      borderRadius: 5,
      fontSize: 10,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      height: 15,
      position: "sticky",
      padding: '5px',
      marginTop: -5,
      width: 45
    },
    enabled11: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
      fontSize: 14,
    },
    disabled: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
      fontSize: 14,
      marginLeft: 15,
    },
    badge: {
      background: 'red'
    },
    clientPrimary: {
      color: "black",
      fontWeight: 500,
      fontFamily: "work Sans",
      fontStyle: "normal",
      fontSize: 16,
      paddingRight: 16,
      paddingLeft: 16
    },
    resetticket: {
      marginTop: 5,
      marginRight: 19,
      background: "red",
      border: "0.791301px solid #3A416F",
      color: "#FFFFFF",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px 20px",
      borderRadius: "4.7478px",
      "&:hover": {
        color: "#FFFFFF",
        background: " red",
      },
    },
    tableCard2: {
      background: "#FFFFFF",
      borderRadius: "10.3033px",
      boxShadow: "0px 17.1721px 23.1824px rgba(0, 0, 0, 0.05)",
      overflow: "visible",
      height: '220px',
      overflowY: 'auto',
      marginTop: 10
    },
    addonsTableHeading: {
      fontSize: 20,
      fontWeight: 500,
      color: "#3A416F",
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 12,
      paddingBottom: 9,
      borderBottom: "1.31653px solid #F5F5F5",
      fontFamily: "work Sans",
    },
    bill: {
      display: "flex",
      alignItems: "start",
      justifyContent: "end",
      marginTop: 15,
    },
    billCycling: {
      color: "#8392AB",
      fontSize: 18,
      lineHeight: "28px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      display: "inline-block"
    },
    number: {
      marginLeft: 8,
      display: "inline-block"
    },
    cadNumber: {
      color: "#3A416F",
      fontSize: 24,
      lineHeight: "28px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      display: "inline-block"
    },
    totalAmout: {
      color: "#4d6289",
      fontFamily: "Work sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
    },
    idAddress: {
      marginTop: 10,
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: 20,
    },
    outbondCalls: {
      color: "#3A416F",
      fontWeight: 500,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontSize: 20,
    },
    depaertmentName: {
      // marginTop: 20,
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontStyle: "normal",
      fontWeight: 500,
      color: "#8392AB",
    },
  },

  { index: 1 }
);

import { makeStyles } from '@material-ui/core/styles';
import { padding } from '@mui/system';

export const useStyles = makeStyles((theme) => ({
    editmodal: {
        height: 500,
        [theme.breakpoints.down('xs')]: {
            height: 500,
            width: 380,
            marginTop: -10
        }
    },
    playaudioTrack: {
        width: '432px',
        [theme.breakpoints.down('xs')]: {
            width: '250px', height: 35
        }
    },
    microphone: {
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
            width: 25, height: 25,
        }
    },
    inputFieldfilename: {
        width: '237px',
        marginRight: '10px', color: '#787878',
        [theme.breakpoints.down('xs')]: {
            width: '190px',
            marginRight: '10px'
        }
    },
    tabActive: {
        background: '#4D72F8 ! important',
        color: '#FFFFFF ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        textTransform: "capitalize",
        // width: '315px',
        // height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 25,
            fontSize: 13,
        }
    },
    tab: {
        textTransform: "capitalize",
        background: '#E4E4E4 ! important',
        color: '#A1A1A1 ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        // width: '315px',
        // height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 25,
            fontSize: 13,
        }
    },
    tabs: {
        background: "#FFFFFF",
        // borderRadius: "7.51286px",
        // boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '0px'
    },
    dFlex: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    minutes: {
        fontFamily: 'Work Sans ! important',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        color: '#8F8E8E',
        marginTop: '10px',
        lineHeight: '19px'
    },
    saveBtn: {
        background: "#4D72F8",
        borderRadius: "3px",
        color: 'white',
        textTransform: 'capitalize',
        marginLeft: 10
    },
    uploadContainer: {
        cursor: 'pointer',
        padding: '10px',
        borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
    },
    selected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        // borderRadius: '4px'
        borderTopLeftRadius: '4px',
        borderTopRightRadius: 'none',
        borderBottomLeftRadius: 4,
        // borderBottomRightRadius:4
    },
    unSelected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5'
    },
    selectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        borderTopLeftRadius: 'none',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        width: '40px', height: '58px',
        [theme.breakpoints.down('xs')]: {
            height: 39
        }
        //borderRadius: '4px'
    },
    unSelectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5',
        width: '40px', height: '56px',
        [theme.breakpoints.down('xs')]: {
            height: 39
        }
    },
    audioTrack: {
        width: '500px', background: '#E9ECED',
        [theme.breakpoints.down('xs')]: {
            width: '220px',
            height: 35
        }
    },
    audioTrackActive: {
        width: '500px',
        [theme.breakpoints.down('xs')]: {
            width: '220px',
            height: 35
        }
        //background: 'rgba(118, 144, 238, 0.5)',
    },
    filename: {
        fontFamily: 'Calibri !important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "12px",
        color: '#848282',
        marginTop: '15px'

    },
    saveAsdefaultBtn: {
        width: '170px',
        height: '42px',
        background: '#4D72F8',
        borderRadius: '3px',
        color: "#FFFFFF",
        textTransform: 'capitalize',
        textAlign: 'center',
        marginLeft: '35%'
    },
    audioPlayers: {
        width: '597px ! important',
        height: '44px',
        backgroundColor: "#F0F3F5",
        border: '1px solid #F0F3F5',
        borderRadius: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '220px ! important',
            height: '30px',
        }
    },
    editBtn: {
        marginRight: '250px',
        height: '40px',
        background: '#4D72F8',
        marginLeft: '0',
        marginTop: '-5px',
        borderRadius: 5,
        color: '#fff',
        padding: '6px 25px',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#fff',
            background: '#4D72F8',
        },
        [theme.breakpoints.down('xs')]: {
            height: '25px',
            marginRight: '140px',
            marginTop: '5px',
            padding: 5,
        }
    },
    audioContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    greetText: {
        // marginLeft: 20,
        fontSize: "18px",
        fontFamily: 'Calibri !important',
        fontWeight: 600,
        fontStyle: 'normal',
        color: '#000000',
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
            marginRight: '0px',
            marginLeft: -140
        }
    },
    greetText1: {
        // marginLeft: 20,
        fontSize: "18px",
        fontFamily: 'Calibri !important',
        fontWeight: 600,
        fontStyle: 'normal',
        color: '#000000',
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
            marginRight: '0px',
            marginLeft: -90
        }
    },
    actionText: {
        fontSize: "16px",
        fontFamily: 'Arial !important',
        fontWeight: 400,
        fontStyle: 'normal',
        color: 'rgba(107, 107, 107, 0.86)',
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px",
            marginRight: '0px',
            marginLeft: 0
        }
    },
    flexStyles: {
        display: 'flex',
        justifyContent: 'flexStart',
        paddingBottom: '25px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
}))
import React from 'react'
import {
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    makeStyles,
    FormControl, Avatar, Button, Tabs, Tab
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

import Select from 'react-select'
import AudioCard from '../OpenHourGreeting/OpenHourGreetingCard'

import Fileupload from './Fileupload'
import RecordAudio from './RecordAudio'
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';

import { useStyles } from './styles'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'

const muiTheme = createMuiTheme({});


const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });

function RecordedMessages(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [editRecordedMessage, setEditRecordedMessage] = React.useState(false)
    const [srcss, setSrcss] = React.useState<any>('')
    const [data, setData] = React.useState<any>([])

    // console.log("data",props.Data)
    const handlePopupModalAdd = () => {
        setEditRecordedMessage(!editRecordedMessage)
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
       
    }
    //console.log('kjkjsdcsdf',props.callHandlingData);
    return (
        <>
            {
                editRecordedMessage && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title='Edit Recorded Messages' handlePopupModal={handlePopupModalAdd} >
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editVoiceMailTab"
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        {/* <p>record file</p> */}
                                        <RecordAudio openHrFiles={props.soundFiles} />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/* <p>File Upload</p> */}
                                        <Fileupload
                                            depId={props.depId}
                                            getOpenHours={props.getOpenHours}
                                            callHandlingData={props.callHandlingData}
                                            data={props.callHandlingData?.recording} />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Modal>
                    </div>
                )
            }

            <div className={classes.greetMainSection}>
                {/* <div style={{ textAlign: 'left' }}>
                    <Typography className={classes.heading}>{props.title}</Typography>
                    <Typography className={classes.actionline}>{props.actionline}</Typography>
                </div> */}
                <div>
                    <div className={classes.audioContainer}>
                        <ThemeProvider theme={muiTheme}>
                            <ReactAudioPlayer
                                controls
                                // className={classes.audioPlayer}
                                onLoadedMetadata={handleLoadMetadata}
                                src={props.selectedRecordedmsgData}
                                className={classes.audioPlayers}
                            />

                        </ThemeProvider>
                        <Button className={classes.editbtn} onClick={handlePopupModalAdd}>
                            Edit
                        </Button>
                    </div>
                    <div style={{ textAlign: 'left', marginTop: '0px' }}>
                        {/* <p className={classes.filename}>Filename.mp3</p> */}
                        <span className={classes.filename}>{props.selectedRecordedmsgname}</span>
                    </div>
                </div>
            </div>
        </>

    )
}

export default RecordedMessages
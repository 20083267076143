import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  divider: {
    marginTop: -10,
    backgroundColor: '#E2E2E2',
  },
  paddingGrid: {
    paddingTop: 0,
  },
  departmentNameText: {
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: 0.1,
    textTransform: 'none',
    marginTop: 14,
  },
  switchCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 0,
  },
  margin: {
    marginBottom: 10,
  },
  card: {
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  width: {
    width: '75%',
  },
  specificContent: {
    display: 'content',
  },
  specificText: {
    marginBottom: 18,
    fontSize: 20,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: 0.01,
    textTransform: 'none',
    marginTop:-14
  },
  tableContainer1: {
    width: '100%',
    boxShadow: '0px 0px 0px',
    borderRadius: 0,
    borderColor: '#E2E2E2',
  },
  tableCellBody1: {
    padding: 10,
    border: '0px',
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.21553,
    textAlign: 'center',
    color: '#464646',
  },
  heading:{
    color: "#2645ad",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  accordianNoFlex:{
    display: 'block'
  },
  tableCellBody2: {
    padding: 10,
    border: '0px',
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.21553,
    color: '#464646',
  },
  hoursText: {
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    color: '#787878',
    letterSpacing: 0.1,
    textAlign: 'center',
    textTransform: 'none',
  },
  specifichoursText:{
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    color: '#787878',
    letterSpacing: 0.1,
    textAlign: 'center',
    textTransform: 'none',
  }
}));

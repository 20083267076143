import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';

import { BorderColor, Delete, Save } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import ReactSelect from 'react-select';
import Select from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi'
import AudioPlayer from 'material-ui-audio-player'
import ProfileServices from '../../ProfileServices/ProfileServices'
import { useSelector, useDispatch } from 'react-redux';
import * as userActionCreators from '../../../../store/Actions/userActionCreator'
import ReactAudioPlayer from 'react-audio-player';

const useStyleses = makeStyles(
  (theme: any) => {
    return {
      root: {
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
      },
      loopIcon: {
        color: '#4D72F8',
        '&.selected': {
          color: '#0921a9'
        },
        '&:hover': {
          color: '#4D72F8'
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      playIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      pauseIcon: {
        color: '#4D72F8',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeSlider: {
        color: '#4D72F8'
      },
      progressTime: {
        color: '#6b6b6d'
      },
      mainSlider: {
        color: '#4D72F8',
        '& .MuiSlider-rail': {
          color: '#4D72F8'
        },
        '& .MuiSlider-track': {
          color: '#4D72F8'
        },
        '& .MuiSlider-thumb': {
          color: '#4D72F8'
        }
      }
    }
  });

function UnAnsweredVoicemail(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [shouldRecord, setShouldRecord] = React.useState(false)
  const [fileName, setFileName] = React.useState('')
  const [selectedAudio, setselectedAudio] = React.useState<any>('')
  const [fileBuffer, setFileBuffer] = React.useState('')
  const [srcss, setSrcss] = React.useState<any>("");
  const audioFileInput: any = React.createRef();
  const [Loading, setLoading] = React.useState<any>(false)
  const [upperDropDownData, setupperDropDownData] = React.useState<any>(null)
  const [voicemailArray, setVoicemailArray] = React.useState<any>([])
  const [dropDownValues, setDropDown] = React.useState<any>([]);
  const hoursDataState: any = useSelector((state: any) => state?.hoursData);
  const [dropDownState, setDropDownState] = React.useState<any>({
    callHandlingValue: '',
    callHandlingOption: props?.callHandlingOption
  })
  const [dropDownStateValue, setDropDownStateValue] = React.useState<any>({
    callHandlingValue: ''
  })
  const [uploadingFile, setUploadingFile] = React.useState<any>(false)
  const [selectedAudioFile, setSelectedAudioFile] = React.useState<any>({})
  const profileStoreData = useSelector((state: any) => state.profileData);
  const { profileData, loading } = profileStoreData;


  const shouldRecordChange = () => {
    setShouldRecord(!shouldRecord)
  }

  const uploadFileClick = () => {
    setUploadingFile(true)
    if (audioFileInput.current) {
      audioFileInput.current.click()
    }

  }

  const onChangeAudioFile = (e: any) => {

    const fileData = e.target.files;
    var file: any = fileData[0]
    setFileBuffer(file)
    let reader: any = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setSrcss(reader.result)
    };

    setFileName(fileData[0]?.name)
    onPostDataToServer(file, fileData[0]?.name)
  }

  const updateUnansweredHoursData = (data: any) => {
    setLoading(true)
    ProfileServices.updateUnAnsweredCalls(data).then((res: any) => {
      if (res.success) {
        props.getUnanswereddata()
        dispatch(userActionCreators.initUserProfile())
        setLoading(false)
      } else {
        setLoading(false)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }


  const onChangeVoicemail = (data: any) => {
    setDropDownStateValue((prev: any) => {
      return {
        ...prev,
        callHandlingValue: data.value
      }
    })

    if (upperDropDownData) {
      const filterFile = voicemailArray.find((voice: any) => voice.id === data.value)

      if (filterFile) {
        setSelectedAudioFile(filterFile)
        setFileName(filterFile.fileName)
        setSrcss(filterFile.filePath)
        setDropDownState((prev: any) => {
          return {
            ...prev,
            callHandlingValue: filterFile?.id
          }
        })
        updateUnansweredHoursData({ ...dropDownState, callHandlingValue: filterFile?.id })

      }
    }
  }
  React.useEffect(() => {
    if (props.unanswered) {
      const unanswered = props.unanswered
      const voicemailsArray: any = unanswered?.voicemails;

      if (voicemailsArray && voicemailsArray.length > 0) {
        setVoicemailArray(voicemailsArray)
      }
      const filterVoiceMail = voicemailsArray?.map((voice: any) => {
        return {
          label: voice.fileName,
          value: voice.id
        }
      })
      setupperDropDownData(filterVoiceMail)
      console.log("-----data", props.data)
      if (props.data) {
        const defaultData = props.data
        const filterDepartmentVal = voicemailsArray?.find((dept: any) => dept.id === props.data?.voicemail?.currentSelectedValue)
        if (filterDepartmentVal) {
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: filterDepartmentVal.id
            }

          })
          setselectedAudio(filterDepartmentVal.filePath)
          setDropDownState((prev: any) => {
            return {
              ...prev,
              callHandlingValue: filterDepartmentVal.id
            }
          })

        }


      }
    }


  }, [props.unanswered, props.data])

  // React.useEffect(() => {
  //   if (hoursDataState) {
  //     if (hoursDataState.unanswered) {
  //       const voicemailsArray: any = hoursDataState.unanswered?.voicemails;
  //       if (voicemailsArray.length > 0) {
  //         setVoicemailArray(voicemailsArray)
  //       }
  //       const filterVoiceMail = voicemailsArray.map((voice: any) => {
  //         return {
  //           label: voice.fileName,
  //           value: voice.id
  //         }
  //       })
  //       setupperDropDownData(filterVoiceMail)
  //     }
  //   }

  // }, [hoursDataState])

  const onPostDataToServer = (fileBuffer: any, fileName: any) => {
    let formData = new FormData()
    formData.append('fileName', fileName)
    formData.append('type', '1')
    formData.append('hoursType', '3')

    if (uploadingFile) {
      formData.append('file', fileBuffer)
      formData.append('soundType', 'Voicemail')
      setLoading(true)
      ProfileServices.uploadFileData(formData).then((res) => {
        if (res.success) {
          //success fully uploaded
          onChangeVoicemail({ label: res.data?.fileName, value: res?.data.data.id })
          setupperDropDownData([...upperDropDownData, { label: res.data?.data.fileName, value: res?.data?.data.id }])
          updateUnansweredHoursData({ ...dropDownState, callHandlingValue: res?.data?.data.id })
          setUploadingFile(false)
          setLoading(false)
          setVoicemailArray((prev: any) => {
            return [...prev, res.data]
          })
          setSelectedAudioFile(res?.data?.data)
          setFileBuffer(res?.data)
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: res?.data?.data.id
            }
          })


        } else {
          setLoading(false)
        }
      }).catch((err) => {
        //send err
        console.log(err)
        setLoading(false)
      })
    } else {
      if (selectedAudioFile) {
        setLoading(true)
        ProfileServices.patchFileData(selectedAudioFile?.id, formData).then((res) => {
          if (res.success) {
            //success fully uploaded
            setupperDropDownData([...upperDropDownData, { label: res.data?.fileName, value: res?.data.id }])
            setUploadingFile(false)
            setVoicemailArray([...voicemailArray, res.data])
            setSelectedAudioFile(res?.data)
            setLoading(false)
            setFileBuffer(res?.data)
            props.getUnanswereddata()
            setDropDownStateValue((prev: any) => {
              return {
                ...prev,

                callHandlingValue: res?.data?.id
              }
            })
          } else {
            setLoading(false)
          }
        }).catch((err) => {
          //send err
          console.log(err)
          setLoading(false)
        })
      }

    }
  }
  const deleteAudioFile = () => {
    if (selectedAudioFile) {
      setLoading(true)
      ProfileServices.deleteFileData(selectedAudioFile?.id).then((res) => {
        if (res.success) {
          //success fully uploaded
          updateUnansweredHoursData({ ...dropDownState })
          setupperDropDownData(upperDropDownData.filter((dpvals: any) => dpvals.value !== selectedAudioFile?.id))
          setVoicemailArray(voicemailArray.filter((dpFil: any) => dpFil.id === selectedAudioFile?.id))
          setUploadingFile(false)
          setFileName("")
          setSelectedAudioFile(null)
          setLoading(false)
          setselectedAudio('')
          setDropDownStateValue((prev: any) => {
            return {
              ...prev,
              callHandlingValue: null
            }
          })


        } else {
          setLoading(false)
        }
      }).catch((err) => {
        //send err
        console.log(err)
        setLoading(false)
      })
    }
  }

  const handleLoadMetadata = (meta: any) => {
    const { duration } = meta.target;
    const styles = makeStyles({

    })
    console.log(duration);
  }




  return (
    <>
      {Loading || loading ?
        <Box sx={{ display: 'flex', marginTop: "25px", marginLeft: "520px" }}>
          <CircularProgress />
        </Box> : (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.greetText}>To VoiceMail Greeting</Typography>
              <Grid container spacing={5} className={classes.gridContainer}>
                <Grid item md={5} sm={12} xs={12} className={classes.grid}>
                  {/* <ReactSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                placeholder={props.placeholder}
                options={upperDropDownData}
                value={dropDownStateValue?.callHandlingValue}
                onChange={onChangeVoicemail}
              /> */}
                  <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    placeholder={"select voicemail"}
                    options={upperDropDownData}
                    value={
                      upperDropDownData?.filter((option: any) =>
                        option.value === dropDownStateValue.callHandlingValue)
                    }
                    onChange={onChangeVoicemail}
                  />
                  <div className={classes.radioBtnsContainer}>
                    <IconButton className={classes.uploadButton} onClick={shouldRecordChange}>
                      {
                        shouldRecord ? (
                          <BiRadioCircleMarked size={15} color="#787878" />
                        ) : (
                          <BiRadioCircle size={15} color="#787878" />
                        )
                      }
                      <Typography className={classes.deleteIconText}>Record</Typography>
                    </IconButton>
                    <IconButton className={classes.uploadButton} onClick={uploadFileClick}>
                      <PublishIcon className={classes.deleteIconColor} />
                      <Typography className={classes.deleteIconText} >Upload</Typography>
                      <div style={{ display: 'none' }}>
                        <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
                      </div>
                    </IconButton>
                  </div>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Card className={classes.card}>

                    <CardContent>
                      <Grid container spacing={5} className={classes.gridItemCard}>
                        <Grid item md={9} sm={8} xs={12} className={classes.gridItemCard}>
                          <TextField
                            variant={'outlined'}
                            fullWidth
                            size="small"
                            label={fileName}
                            value={fileName}
                            onChange={(e: any) => setFileName(e.target.value)}
                            placeholder="No File selected"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  variant={'filled'}
                                  className={classes.deleteButton}>
                                  <BorderColor className={classes.borderColorIcon} />
                                  <Typography className={classes.borderColorIconText}>
                                    Rename
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} className={classes.gridItemCard}>
                          <IconButton className={classes.iconButton} onClick={deleteAudioFile}>
                            <Delete className={classes.deleteIconColor} />
                            <Typography className={classes.deleteIconText}>Delete</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container spacing={5} className={classes.gridItemCard}>
                        <Grid item md={9} sm={8} xs={12} className={classes.vollen1}>
                          {/* <AudioPlayer
                        // elevation={1}
                        width="100%"
                        // variation="default"
                        // spacing={3}
                        // download={true}
                        // autoplay={true}
                        // order="standart"
                        // preload="auto"
                        // loop={true}
                        useStyles={useStyleses}
                        src={selectedAudio}
                      /> */}
                          <ReactAudioPlayer
                            controls
                            className={classes.audioPlayer}
                            onLoadedMetadata={handleLoadMetadata}
                            src={selectedAudio}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} className={classes.vollen}>
                          <IconButton className={classes.iconButton} onClick={() => onPostDataToServer(fileBuffer, fileName)}>
                            <Save className={classes.deleteIconColor} />
                            <Typography className={classes.deleteIconText}>Save</Typography>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
    </>
  );
}
export default UnAnsweredVoicemail
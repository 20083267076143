import { Button, Card, Grid, Typography, Tabs, Tab } from '@material-ui/core'
import React from 'react'
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import { TabContext, TabPanel } from '@material-ui/lab';

import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import Fileupload from './Fileupload';
import { useStyles } from './styles';
import RecordAudio from './RecordAudio';

function OpenHoursRecordMessages(props: any) {
    const classes = useStyles()
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [value, setValue] = React.useState("2");
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title={"Edit Recorded Messages"} handlePopupModal={handlePopupModalAdd} >
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            openHours={props.openHours}
                                            getOpenHours={props.getOpenHours}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        {/* <p>File Upload</p> */}
                                        <Fileupload
                                            openHours={props.openHours}
                                            getOpenHours={props.getOpenHours}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={3} style={{ marginTop: '30px' }}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ReactAudioPlayer
                                controls
                                className={classes.audioPlayer}
                                //onLoadedMetadata={handleLoadMetadata}
                                src={props.recPath}
                            />
                            <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                <span className={classes.filename}>{props.recFileName}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>Edit</Button>
                        </Grid>
                    </Grid>
                </Card>
            </div>

        </>
    )
}

export default OpenHoursRecordMessages
import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// import MySignal from 'assets/icons/Signal.png';
import MySignal from "../../../../assets/icons/Signal.png";
import MyDepts from "../../../../assets/icons/Dept.png";
import GroupUsers from "../../../../assets/icons/groupUsers.png";
import MyTelephone from "../../../../assets/icons/MyTelephone.png";
import MyRecords from "../../../../assets/icons/Record.png";
import { useStyles } from "./styles";
import OpenHoursVoicemail from "../OpenHoursVoicemail/OpenHoursVoicemail";
import OpenHoursRecMessages from "../OpenHoursRecMessages/OpenHoursRecMessages";
import OpenHoursPhoneNumbers from "../OpenHoursPhoneNumbers/OpenHoursPhoneNumbers";
import OpenHoursDepartments from "../OpenHoursDepartments/OpenHoursDepartments";
import OpenHoursTeammembers from "../OpenHoursTeammembers/OpenHoursTeammembers";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { MdPhonelink, MdSettingsPhone } from "react-icons/md";
import { RiUserVoiceFill, RiTeamFill } from "react-icons/ri";
import * as profileActionCreator from "../../../../store/Actions/userActionCreator";
import { HiOfficeBuilding } from "react-icons/hi";
import { BiMessageDetail } from "react-icons/bi";
import profileServices from "../../ProfileServices/ProfileServices";
import { useSelector, useDispatch } from "react-redux";
import UnAnsweredVoicemail from "../UnAnsweredVoicemail/UnAnsweredVoicemail";
import UnAnsweredDepartments from '../UnAnsweredDepartments/UnansweredDepartments';
import UnAnsweredTeammembers from "../UnAnsweredTeammembers/UnAnsweredTeammembers";
import UnAnsweredPhoneNumners from "../UnAnsweredPhoneNumners/UnAnsweredPhoneNumners";
import UnAnsweredRecMessages from "../UnAnsweredRecMessages/UnAnsweredRecMessages";
const openhoursOptions = [
  { key: 2, value: "voicemail" },
  { key: 3, value: "departments" },
  { key: 4, value: "teammembers" },
  { key: 5, value: "phonenumbers" },
  { key: 6, value: "recmessages" },
];

function UnAnsweredCalls() {
  const classes = useStyles();
  const profileStoreData = useSelector((state: any) => state.profileData);
  const { profileData, loading } = profileStoreData;
  const [tabname, setTabName] = React.useState("voicemail");
  const [unanswer, setUnanswer] = React.useState<any>({})
  const [unansweredData, setUnansweredData] = React.useState<any>({})
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const expandOpen = () => {
    setOpen(!open);
  };

  const handleTabChange = (tabname: any) => {
    const findKeyValue: any = openhoursOptions.find(
      (openOption: any) => openOption.value === tabname
    );
    if (findKeyValue?.key === 1) {
      profileServices
        .updateUnAnsweredCalls({ callHandlingOption: findKeyValue?.key })
        .then((res: any) => {
          //send success message
        })
        .catch((err: any) => {
          //send error message
        });
    }
    setTabName(tabname);
  };
  React.useEffect(() => {
    if (profileData) {
      setUnanswer(profileData.unAnsweredCalls)
    }

  }, [profileData])
  const getUnanswereddata = () => {
    profileServices.getUnAnsweredCalls().then((res: any) => {
      if (res.success) {
        setUnansweredData(res.data)
      } else {

      }
    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    if (profileData) {
      const { unAnsweredCalls } = profileData;
      openhoursOptions.forEach((option: any) => {
        if (option.key === unAnsweredCalls?.callHandlingOption) {
          setTabName(option.value);
        }
      });
    }
  }, [profileData]);
  React.useEffect(() => {
    dispatch(profileActionCreator.initUnanswred());
  }, []);

  React.useEffect(() => {
    getUnanswereddata()
  }, [])

  return (
    <>
      <div className={classes.padding}>
        <Card className={classes.card}>
          {/* <CardHeader
            name={'Un Answered Calls'}
            textfield={false}
            label={'Un Answered Calls'}
            addbox={true}
          /> */}
          <Accordion onChange={expandOpen}>
            <AccordionSummary
              expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Un Answered Calls
              </Typography>
            </AccordionSummary>
              <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
              <CardContent>
                <Grid container spacing={5} className={classes.grid}>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={
                        tabname === "voicemail"
                          ? classes.cardWidth
                          : classes.cardWidth1
                      }
                      onClick={() => handleTabChange("voicemail")}
                    >
                      <CardContent className={classes.align}>
                        <RiUserVoiceFill
                          className={
                            tabname === "voicemail"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          } />

                        <Typography
                          className={
                            tabname === "voicemail"
                              ? classes.devicesText
                              : classes.devicesText1
                          }
                        >
                          Voice Mail
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={
                        tabname === "departments"
                          ? classes.cardWidth
                          : classes.cardWidth1
                      }
                      onClick={() => handleTabChange("departments")}
                    >
                      <CardContent className={classes.align}>
                        <HiOfficeBuilding
                          className={
                            tabname === "departments"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />

                        {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}
                        <Typography
                          className={
                            tabname === "departments"
                              ? classes.devicesText
                              : classes.devicesText1
                          }
                        >
                          Departments
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={
                        tabname === "teammembers"
                          ? classes.cardWidth
                          : classes.cardWidth1
                      }
                      onClick={() => handleTabChange("teammembers")}
                    >
                      <CardContent className={classes.align}>
                        <RiTeamFill
                          className={
                            tabname === "teammembers"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />

                        {/* <img className={classes.deviceWidth} alt="GroupUsers" src={GroupUsers} /> */}
                        <Typography
                          className={
                            tabname === "teammembers"
                              ? classes.devicesText
                              : classes.devicesText1
                          }
                        >
                          Team Members
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2}sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={
                        tabname === "phonenumbers"
                          ? classes.cardWidth
                          : classes.cardWidth1
                      }
                      onClick={() => handleTabChange("phonenumbers")}
                    >
                      <CardContent className={classes.align}>
                        <MdSettingsPhone
                          className={
                            tabname === "phonenumbers"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />
                        {/* <img className={classes.deviceWidth} alt="MyTelephone" src={MyTelephone} /> */}
                        <Typography
                          className={
                            tabname === "phonenumbers"
                              ? classes.devicesText
                              : classes.devicesText1
                          }
                        >
                          Phone Numbers
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={
                        tabname === "recmessages"
                          ? classes.cardWidth
                          : classes.cardWidth1
                      }
                      onClick={() => handleTabChange("recmessages")}
                    >
                      <CardContent className={classes.align}>
                        <BiMessageDetail
                          className={
                            tabname === "recmessages"
                              ? classes.deviceWidth1
                              : classes.deviceWidth
                          }
                        />

                        {/* <img className={classes.deviceWidth} alt="MyRecords" src={MyRecords} /> */}
                        <Typography
                          className={
                            tabname === "recmessages"
                              ? classes.devicesText
                              : classes.devicesText1
                          }
                        >
                          Recorded Messages
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}></Grid>
                </Grid>
              </CardContent>
              {tabname === "voicemail" && (
                <>
                  <UnAnsweredVoicemail
                    callHandlingOption={2}
                    compType={'voicemails'}
                    data={unanswer}
                    unanswered={unansweredData}
                    getUnanswereddata={getUnanswereddata}
                    getURL={''} />
                </>
              )}
              {tabname === "departments" && (
                <>
                  <UnAnsweredDepartments callHandlingOption={3}
                    data={unanswer}
                    unanswered={unansweredData}
                    getUnanswereddata={getUnanswereddata} compType={'departments'} patchURL={''} getURL={''} />

                </>
              )}
              {tabname === "teammembers" && (
                <>
                  <UnAnsweredTeammembers callHandlingOption={4}
                    data={unanswer}
                    unanswered={unansweredData}
                    getUnanswereddata={getUnanswereddata} compType={'teammembers'} patchURL={''} getURL={''} />
                </>
              )}
              {tabname === "phonenumbers" && (
                <>
                  <UnAnsweredPhoneNumners callHandlingOption={5}
                    data={unanswer}
                    unanswered={unansweredData}
                    getUnanswereddata={getUnanswereddata} compType={'phonenumbers'} patchURL={''} getURL={''} />
                </>
              )}
              {tabname === "recmessages" && (
                <>
                  <UnAnsweredRecMessages callHandlingOption={6}
                    data={unanswer}
                    unanswered={unansweredData}
                    getUnanswereddata={getUnanswereddata} compType={'recmessages'} patchURL={''} getURL={''} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Card>
      </div>
    </>
  );
}
export default UnAnsweredCalls;

import { TableCell, TableRow,Button, Popper, Grow,MenuList, Paper, ClickAwayListener, MenuItem, Grid } from '@material-ui/core'
import { BsThreeDots } from "react-icons/bs";
import React from 'react'
import { useStyles } from '../style'
import Moment from 'moment'
import { AiFillEdit } from 'react-icons/ai';
import { useHistory } from "react-router-dom"
import { MdDelete } from 'react-icons/md';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import axiosInstance from '../../../../environment/axiosInstance'
import AssignPhoneNumber from './AssignPhoneNumber';

function TableBodyData(props:any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    const [DeleteModal, setDeleteModal] = React.useState(false)
    const [delId, setDelId] = React.useState<any>('')
    const history = useHistory()
    const [assignModal, setAssignModal] = React.useState(false)

     const deletePhonenumber = () => {
        axiosInstance.delete(`/admin/phone-numbers/${delId}`).then((res: any) => {
            if (res.data) {
                props.getPhoneNumbers(props.paginate.limit,props.paginate.page)
                setDeleteModal(!DeleteModal)
            } else {
            }
        }).catch((err: any) => {
        })
    }

    const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
   const handlePopupDeletePhoneNumber = (id: any) => {
        setDeleteModal(!DeleteModal)
        setDelId(id)
    }
     const handleAssignNumberModal = () => {
        setAssignModal(!assignModal)
    }
    var datadate = parseInt(props.phoneNumbersData.createdDate)
    var dataa = Moment(datadate).format('YYYY-MM-DD HH:mm:ss')

  return (
       <>
            {
                DeleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupDeletePhoneNumber} />
                        <Modal title="Are you sure to delete this Business account ?" handlePopupModal={handlePopupDeletePhoneNumber} >
                            <Grid container spacing={2} style={{ margin: 15 }}>
                                <div className="add_user_popup" style={{ width: "500px" }}>
                                    <Grid item md={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.deleteButton} onClick={deletePhonenumber}>Delete</Button>
                                            <Button size="medium" onClick={handlePopupDeletePhoneNumber} className={classes.CancelButton}>Cancel</Button>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                        </Modal>
                    </div>
                )
            }
            {
                assignModal && (
                    <div  >
                        <Backdrop handlePopupModal={handleAssignNumberModal} />
                        <Modal title="Assign Number" handlePopupModal={handleAssignNumberModal} >
                            <div className="add_user_popup" style={{ width: "600px", padding: '0px 15px' }}>
                                
                                <AssignPhoneNumber
                                    handlePopupModal={handleAssignNumberModal}
                                    id={props.phoneNumbersData?.id}
                                    getPhoneNumbers={props.getPhoneNumbers}
                                    paginate={props.pageLimit}
                                    phoneNumbersData={props.phoneNumbersData}
                                />
                                {/* <AssignPhone 
                                NumberData={props.numberData}
                                    
                                    fetchNumberLists={props.fetchNumberLists} 
                                    handlePopupModal={handleAssignNumberModal} /> */}

                            </div>
                        </Modal>
                    </div>
                )

            }
            
            <TableRow >
                <TableCell className={classes.tableCellBody}>{props.phoneNumbersData?.phone}</TableCell>
                {/* <TableCell className={classes.tableCellBody}>{props.phoneNumbersData?.parentId?.firstName} {props.phoneNumbersData?.parentId?.lastName}</TableCell> */}
                 <TableCell className={classes.tableCellBody}>
                    {!props.phoneNumbersData?.parentId ? 
                        <div style={{display:'flex',gap:5}}>
                            <Button size="small" variant='outlined' onClick={handleAssignNumberModal}>Assign</Button>
                            <Button size='small' variant="outlined">Move to V4 Personel</Button>
                        </div>
                        :
                        <div>
                            {props.phoneNumbersData?.parentId?.firstName} {props.phoneNumbersData?.parentId?.lastName}
                        </div>
                    }
                 </TableCell>
                <TableCell className={classes.tableCellBody}>
                    {
                        props.phoneNumbersData?.status === 1 ? <div style={{ color: "green" }}>Active</div>: <div style={{ color: "red" }}>InActive</div>
                    }
                </TableCell>
                <TableCell className={classes.tableCellBody}>{props.phoneNumbersData.createdDate ? dataa : ""}</TableCell>
                <TableCell className={classes.tableCellBody}>
                            <div>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    <BsThreeDots />
                                </Button>
                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === "bottom" ? "center top" : "center bottom",
                                            }}
                                        >
                                            <Paper style={{ borderRadius: "0px 10px 10px 10px", background: '#F3F5FF' }}>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="menu-list-grow"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem 
                                                            onClick={() => history.push(`/editPhonenumber/${props.phoneNumbersData?.id}`)}
                                                        >
                                                            <AiFillEdit 
                                                            onClick={() => history.push(`/editPhonenumber/${props.phoneNumbersData?.id}`)}
                                                            className={classes.deviceWidth} />Edit</MenuItem>

                                                        <MenuItem onClick={() => handlePopupDeletePhoneNumber(props.phoneNumbersData?.id)}>
                                                            <MdDelete
                                                            className={classes.deviceWidth}
                                                            onClick={() => handlePopupDeletePhoneNumber(props.phoneNumbersData?.id)} />
                                                            Delete
                                                        </MenuItem>
                                                        {/* {props.phoneNumbersData?.parentId ?  
                                                            <MenuItem>UnAssign</MenuItem>
                                                            :
                                                            ""
                                                        } */}
                                                  

                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </TableCell>
            </TableRow>
    </>
  )
}

export default TableBodyData
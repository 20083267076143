export const AuthActions = {
    INIT_AUTH: 'INIT_AUTH',
    INIT_AUTH_LOGIN_AS: 'INIT_AUTH_LOGIN_AS',
    INIT_AUTH_STATUS: 'INIT_AUTH_STATUS',
    INIT_AUTH_SUCCESS: 'INIT_AUTH_SUCCESS',
    INIT_AUTH_FAIL: 'INIT_AUTH_FAIL',
    INIT_AUTH_LOADING: 'INIT_AUTH_LOADING',
    INIT_AUTH_LOGOUT: 'INIT_AUTH_LOGOUT',
    INIT_AUTH_LOGOUT_SUCCESS: 'INIT_AUTH_LOGOUT_SUCCESS',
    INIT_AUTH_TIMEOUT: 'INIT_AUTH_TIMEOUT',
    INIT_REFRESH_TOKEN: 'INIT_REFRESH_TOKEN'
}

export const globalPopupActions = {
    INIT_GLOBAL_ERROR: 'INIT_GLOBAL_ERROR',
    CLEAR_GLOBAL_ERROR: 'CLEAR_GLOBAL_ERROR'
}

export const initProfileData = {
    INIT_PROFILE_DATA: 'INIT_PROFILE_DATA',
    INIT_PROFILE_DATA_SUCCESS: 'INIT_PROFILE_DATA_SUCCESS',
    PROFILE_DATA_LOADING: 'PROFILE_DATA_LOADING'
}

export const userHoursAction = {
    INIT_OPEN_HOURS: 'INIT_OPEN_HOURS',
    OPEN_HOURS_SUCCESS: 'OPEN_HOURS_SUCCESS',
    CLOSED_HOURS_SUCCESS: 'CLOSED_HOURS_SUCCESS',
    INIT_CLOSED_HOURS: 'INIT_CLOSED_HOURS',
    INIT_UNANSWRED_HOURS: 'INIT_UNANSWRED_HOURS',
    UNANSWRED_HOURS_SUCCESS: 'UNANSWRED_HOURS_SUCCESS'
}

export const userDepartmentsAction = {
    USER_DEPARTMENT: 'USER_DEPARTMENT',
    REQUEST: 'USER_DEPARTMENT_REQUEST',
    SUCCESS: 'USER_DEPARTMENT_SUCCESS',
}

export const departmentModle = {
    FETCH_LOADING_DEPARTMENTS: 'FETCH_LOADING_DEPARTMENTS',
    FETCH_DEPARTMENTS_SUCCESS: 'FETCH_DEPARTMENTS_SUCCESS',
    ONCHANGE_SELECT_DEPARTMENT: 'ONCHANGE_SELECT_DEPARTMENT',
    SIDEBAR_DEPARTMENT: 'SIDEBAR_DEPARTMENT'
}
export const companyModule = {
    FETCH_COMPANY_SUCCESS: 'FETCH_COMPANY_SUCCESS',
    SET_SELECTED_COMPANY:'SET_SELECTED_COMPANY',
}

export const CallingModule = {
    GET_STATUS: 'GET_STATUS',
    GET_CALL_STATUS_REQUEST: 'GET_CALL_STATUS_REQUEST'
}
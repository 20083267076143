import { Button, Card, Grid, Typography, Tabs, Tab } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import ReactSelect from 'react-select';
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import { TabContext, TabPanel } from '@material-ui/lab';
import axiosInstance from '../../../../environment/axiosInstance'
import RecordAudio from './RecordAudio';
import UploadFile from './UploadFile';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const muiTheme = createMuiTheme({});

const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });

function UnansweredDepartments(props: any) {
    const classes = useStyles()
    const [value, setValue] = React.useState("2");
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [deptList, setDeptList] = React.useState([]);
    const [depts, setDepts] = React.useState([])
    const [teamMembers, setTeamMembers] = React.useState([])
    const [teamValue, setTeamValue] = React.useState<any>('');
    const [deptValue, setDeptValue] = React.useState<any>('');
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }

    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }

    //departments
    React.useEffect(() => {

        // console.log(props.unansweredData.departments)
        if (props.unansweredData) {
            setDeptList(props?.unansweredData?.departments)

        }
    }, [props.unansweredData])

    const departments: any = []
    props.unansweredData.departments?.map((item: any) => {
        var myObj = {
            value: item._id,
            label: item.name

        }
        departments.push(myObj)
    })
    // console.log(departments)

    const handleDeptOnchange = (e: any) => {
        setDeptValue(e.value)
        const sendData = {
            departmentId: e.value
        }
        axiosInstance.patch(`/profile/settings/un-answered-call/user-department`, sendData).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getUnanswereddata()
            }
        }).catch((err: any) => {
            //err
        })
    };


    //TeamMembers
    React.useEffect(() => {
        const teamMembers: any = []
        if (props.unansweredData) {
            props.unansweredData.Teams?.map((item: any) => {
                var myObj = {
                    label: item.firstName + ' ' + item.lastName,
                    value: item.firstName + ' ' + item.lastName,
                    id: item._id
                }
                teamMembers.push(myObj)
            })
            setTeamMembers(teamMembers)
        }
    }, [props.closeHoursData])

    const handleTeamMembersOnchange = (data: any) => {
        setTeamValue(data.value)
        const sendData = {
            teamMemberId: data.id
        }
        axiosInstance.patch('/profile/settings/un-answered-call/user-department', sendData).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getUnanswereddata()
            }
        }).catch((err: any) => {
            //err
        })
    };

    console.log('teamMembers', teamMembers);


    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            getUnanswereddata={props.getUnanswereddata}
                                            unansweredData={props.unansweredData}
                                        />

                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadFile
                                            getUnanswereddata={props.getUnanswereddata}
                                            unansweredData={props.unansweredData}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }
            <div className={classes.divPadding}>
                <Card className={classes.cardStyle}>
                    <Grid container spacing={6}>
                        <Grid item md={6} sm={6} lg={4} xs={12}>
                            <Typography className={classes.title}>To Team Member</Typography>
                            <ReactSelect
                                placeholder="Select team Member"
                                name="firstName"
                                options={teamMembers}
                                value={teamMembers.filter((option: any) =>
                                    option.id === props.unansweredData.unAnsweredCalls?.userAndDepartments?.teamMemberId
                                )}
                                onChange={handleTeamMembersOnchange}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} lg={4} xs={12} className={classes.gridMargins}>
                            <Typography className={classes.title}>To Department</Typography>
                            <ReactSelect
                                options={departments}
                                name="name"
                                placeholder="Select department"
                                value={departments.filter((option: any) =>
                                    option.value = props.unansweredData.unAnsweredCalls?.userAndDepartments?.departmentId
                                )}
                                onChange={handleDeptOnchange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{marginTop:'30px'}}>
                        <Grid item md={8} sm={9} xs={12}>
                            <ReactAudioPlayer
                                controls
                                className={classes.audioPlayer}
                                onLoadedMetadata={handleLoadMetadata}
                                src={props.deptPath}
                            />

                            <div style={{ textAlign: 'left', marginTop: '5px' }}>
                                <span className={classes.filename}>{props.deptFileName}</span>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12}>
                            <Button className={classes.editBtn} onClick={handlePopupModalAdd}>Edit</Button>
                    </Grid>
                </Grid>

            </Card>
        </div>
        </>


    )
}

export default UnansweredDepartments
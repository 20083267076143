import React from 'react'
import { PlusOneOutlined, RemoveRedEyeOutlined } from '@material-ui/icons';
import { useStyles } from './styles'
import { DashboardStatCard } from '../../../components/DashboardStatCard';
import { CallStats } from './WeeklyMonthlyCallStats/WeeklyMonthlyCallStats';
import { Grid } from '@material-ui/core';
import Service from './Service'


function Dashboard() {
    const classes = useStyles();
    const [data, setData] = React.useState<any>({})
    const getStats = () => {
        Service.getStats().then((res) => {
            if (res.success) {
                setData(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        getStats()
    }, [])
    return (
        <div className={classes.container}>
            <div className={classes.container1}>
                <div className={classes.statsContainer}>
                    <DashboardStatCard
                        btnIcon={<PlusOneOutlined />}
                        btnLabel="ADD USERS"
                        label="Total Users"
                        count={data.users ? data.users : 0}
                    />
                    <DashboardStatCard
                        btnIcon={<PlusOneOutlined />}
                        btnLabel="ADD NUMBERS"
                        label="Total Numbers"
                        count={data.numbers ? data.numbers : 0}
                    />
                    <DashboardStatCard
                        btnIcon={<RemoveRedEyeOutlined />}
                        btnLabel="VIEW"
                        label="Live Calls"
                        count={data.liveCalls ? data.liveCalls : 0}
                    />
                    <DashboardStatCard
                        btnIcon={<RemoveRedEyeOutlined />}
                        btnLabel="VIEW"
                        label="Calls in Queue"
                        count={data.callsInQueue ? data.callsInQueue : 0}
                    />
                </div>
                <Grid container spacing={2} className={`classes.display`}>
                    <Grid item md={12}>
                        <CallStats />
                    </Grid>
                </Grid>


            </div>
        </div>
    )
}

export default Dashboard

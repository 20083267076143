import React from 'react'
import {
    Card,
    CardContent,
    Divider,
    TextField,
    Typography,
    Accordion,
    AccordionSummary, AccordionDetails,
    Grid,
} from '@material-ui/core';
import { useStyles } from './styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import SearchFilter from './SearchFilter';
import Table from './Table';
import Service from './Services'
function Index() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [tableData, setTableData] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<any>(false);
    const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 10,
        totalPages: 0
    })
    const [searchData, setSearchData] = React.useState<any>({
        type: '',
        account: '',
        direction: '',
        callStatus: '',
        typeKey: '',
        typeValue: '',
        accountKey: '',
        accountValue: '',
        directionKey: '',
        directionValue: '',
        callStatusKey: '',
        callStatusValue: ''
        
    })

    const [searching, setSearching] = React.useState<any>(false)
    const onChangeSearchData = (name: any, value: any) => {

        if (name === "type") {
            if (value !== "") {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        typeKey: name,
                        typeValue: value,
                        type: value
                    }
                })
            } else {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        typeKey: '',
                        typeValue: '',
                        type: ''
                    }
                })
            }
        }
        else if (name === "account") {
            if (value !== "") {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        accountKey: name,
                        accountValue: value,
                        account: value
                    }
                })
            } else {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        accountKey: '',
                        accountValue: '',
                        account: ''
                    }
                })
            }
        }
        else if (name === "direction") {
            if (value !== "") {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        directionKey: name,
                        directionValue: value,
                        direction: value
                    }
                })
            } else {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        directionKey: '',
                        directionValue: '',
                        direction: ''
                    }
                })
            }
        }
        else if (name === "callStatus") {
            if (value !== "") {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        callStatusKey: name,
                        callStatusValue: value,
                        callStatus: value
                    }
                })
            } else {
                setSearchData((prev: any) => {
                    return {
                        ...prev,
                        callStatusKey: '',
                        callStatusValue: '',
                        callStatus: ''
                    }
                })
            }
        }

    }
    React.useEffect(() => {

        if (searchData.type !== '' || searchData.typeKey !== '' || searchData.typeValue !== '' || searchData.account !== '' || searchData.accountKey !== '' || searchData.accountValue !== '' || searchData.direction !== '' || searchData.directionKey !== '' || searchData.directionValue !== '' || searchData.callStatus !== '' || searchData.callStatusKey !== '' || searchData.callStatusValue !== '') {
            setSearching(true)

        } else {

            setSearching(false)
        }
    }, [searchData, paginate.page])

    const clearFilter = () => {
        fetchReports(paginate.limit, 1)
        setSearchData((prev: any) => {
            return {
                ...prev,
                typeKey: '',
                typeValue: '',
                accountKey: '',
                accountValue: '',
                directionKey: '',
                directionValue: '',
                callStatusKey: '',
                callStatusValue: ''

            }
        })
        setSearching(false)
    }
    const searchedData = (limit: any, page: any) => {
        if (searchData) {
            setLoading(true)
            Service.getReportsearch(limit, page, searchData).then((res) => {
                if (res.data) {
                    setLoading(false)
                    setTableData(res.data.data)
                    setPaginate((prev: any) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })
                    setSearching(false)
                }
                else {
                    setSearching(false)
                    setLoading(false)
                }
            }).catch((err: any) => {
                setSearching(false)
                alert(err.message)
                setLoading(false)

            })
        }
        else {
            fetchReports(paginate.limit, paginate.page)
        }
    }

    const fetchReports = (limit: any, page: any) => {
        setLoading(true)
        Service.getReports(limit, page).then((res) => {
            if (res.data) {
                setLoading(false)
                setTableData(res.data.data)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit,
                        totalPages: res.data.totalPages
                    }
                })
                setSearching(false)
            }
            else {
                setSearching(false)
                setLoading(false)
            }
        }).catch((err: any) => {
            setSearching(false)
            alert(err.message)
            setLoading(false)
        })
    }

    const handleChange = () => {
        setOpen(!open);
    };

    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })
        // searchedData(paginate.limit, value)
        if (searchData.type !== '' || searchData.typeKey !== '' || searchData.typeValue !== '' || searchData.account !== '' || searchData.accountKey !== '' || searchData.accountValue !== '' || searchData.direction !== '' || searchData.directionKey !== '' || searchData.directionValue !== '' || searchData.callStatus !== '' || searchData.callStatusKey !== '' || searchData.callStatusValue !== '') {
            searchedData(paginate.limit, value)
        } else {
            fetchReports(paginate.limit, value)
        }
    }

    React.useEffect(() => {
        fetchReports(paginate.limit, paginate.page)
    }, [])
    const handleOpen = () => {
        setOpen(!open)
    }
    return (
        <div>
            <Card className={classes.card}>
                <Accordion expanded={open}
                    onChange={handleOpen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Reports
                        </Typography>

                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    {/* <CardHeader name={'Name'} /> */}
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <Typography className={classes.searchText}>
                            Search Filters
                        </Typography>
                        <SearchFilter
                            onChangeSearchData={onChangeSearchData}
                            paginate={paginate}
                            searchedData={searchedData}
                            data={searchData}
                            clearFilter={clearFilter} />
                    </AccordionDetails>
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <Table
                            data={tableData}
                            handleChangePage={handleChangePage}
                            paginate={paginate}
                            searching={searching}
                            loading={loading}
                            fetchReports={fetchReports} />
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    )
}
export default Index
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { borderRadius } from 'react-select/src/theme';

export const useStyles = makeStyles((theme) =>
    createStyles({
        deleteButton: {
            margin: 10,
            padding: '6px 22px',
            background: '#4D72F8',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#FFFFFF',
            fontSize: 14,
            '&:hover': {
              background: '#4D72F8',
              color: '#FFFFFF',
            }
          },
          deltecancelButton: {
            margin: 10,
            padding: '6px 22px',
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            marginLeft: 15,
            background: '#EFEFEF',
            border: '1px solid #AEAEAE',
            boxSizing: 'border-box',
            borderRadius: 5,
            color: '#787878',
            fontSize: 14,
          },
        modalLdr: {
            display: 'flex', margin: "80px", marginLeft: "520px",
            "@media (max-width: 768px)": {
              marginLeft: 350
            }
          },
        padding: {
            padding: 10,
        },
        card: {
            //   margin: '15px 0px 0px 0px',
            borderRadius: 8,
            background: '#FFFFFF',
            //   boxShadow: '0 0 0.5em #E2E2E2',
            boxShadow: '0 12.5214px 16.9039px rgb(0 0 0 / 5%)'
        },
        heading: {
            color: "#4D72F8",
            fontSize: 20,
            fontWeight: 600,
        },
        departmentheading: {
            color: '#4D72F8',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            textTransform: 'none',
            marginLeft: 16
        },
        accordianNoFlex: {
            display: 'block'
        },
        callFlow: {
            color: '#646A70',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 500,
            textTransform: 'none',
            marginLeft: 16,
            marginTop: 30
        },
        dflex: {
            display: 'flex',
            alignItems: 'center',
            gap: 8
        },
        dflexs: {
            display: 'flex',
            alignItems: 'center',
        },
        tRow: {
            background: '#F1F1F1',
            borderRadius: 6
        },
        tCell: {
            color: '#464646',
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Work Sans'
        },
        tbody: {
            color: '#787878',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Work Sans'
        },
        mWdth: {
            width: '650px', 
            padding: '0px 0px 10px 20px',
            // "@media (max-width: 768px)":{
            //  width: 400,
            //  padding: '0px 5px'
            // },
            [theme.breakpoints.down('xs')]: {
                width: '350px', 
                padding: "10px",
            }
        },
        addnewCont:{
          width: '260px', height: '44px',
          [theme.breakpoints.down('xs')]: {
            width: '130px', height: '35px',
          }
        },
        deleteholidaypopup:{
           width: "500px",
           [theme.breakpoints.down('xs')]: {
            width: "300px",
           }
        },
        text: {
            marginTop: "10px",
            width: '300px',
            height: '44px',
        },
        paper: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            outline: 0, // Disable browser on-focus borders
            boxShadow: theme.shadows[5],
        },
        contentCard: {
            margin: 10,
            background: '#F3F5FF',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: 10,
        },
        divider2: {
            marginTop: "30px",
            backgroundColor: '#E2E2E2',
        },
        cardContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        media: {
            margin: 10,
            height: 125,
            width: '25%',
        },
        bttns: { textAlign: 'center', marginTop: 10 },
        SaveButton: {
            background: '#4D72F8',
            border: '1px solid #ccc',
            borderRadius: 5,
            fontFamily: 'Work Sans',
            fontWeight: 500,
            color: '#FFFFFF',
            fontSize: 15,
            padding: '6px 30px',
            textTransform: 'capitalize',
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
            },
        },
        cancelButton: {
            margin: 10,
            fontFamily: 'Work Sans',
            fontWeight: 500,
            marginLeft: 15,
            background: '#fbfbfb',
            border: '1px solid #ebebeb',
            borderRadius: 5,
            color: '#8392AB',
            fontSize: 15,
            padding: '6px 22px'
        },
        addButton: {
            marginLeft: 915,
            border: '1px solid #4D72F8',
            background: '#ffffff',
            color: '#4D72F8',
            textTransform: 'capitalize',
            fontSize: 14,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 160,
            }
        },
        tablecontainer:{ 
            borderRadius: 5, border: '1px solid rgba(194, 194, 194, 0.47)', 
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            overflowX:"auto",
            overflowY:"auto",
            [theme.breakpoints.down('xs')]: {
                width:"350px"
            }
        },
        divider: {
            //margin: 5,
            marginTop: -12,
            backgroundColor: '#E2E2E2',
        },
        dndCardContent: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "16px 14px 5px",
            "@media (max-width: 768px)": {
                marginTop: -15,
            }
        },
        mt8: {
            marginTop: '-8px'
        },
        outBondCard: {
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
            borderRadius: 10,
            background: "#FFFFFF",
            marginTop: 25,
            //marginBottom: 60,
        },
        editIcon: {
            color: "#4D72F8",
            marginLeft: 5,
            marginTop: -12,
        },
        departmentNameText: {
            fontSize: 15,
            marginTop: -12,
            fontFamily: "Calibri",
            fontStyle: "normal",
            fontWeight: 600,
            color: "#505050",
            letterSpacing: 0.1,
            textTransform: "none",
            "@media (max-width: 768px)": {
                marginTop: -5,
            }
            // marginTop: 5,
            // marginLeft: 7,
        },
        header: {
            color: '#4D72F8',

        },

    })
);

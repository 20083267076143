import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ReactSelect from "react-select";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { TableHeaderComponent } from "../../../../components/Table/Table";
import ProfileServices from "../../ProfileServices/ProfileServices";
import { useState } from "react";
import axiosInstance from '../../../../environment/axiosInstance';

const daysDataHardcoded = [
  {
    day: 'Monday',
    open: "00:00",
    close: "00:00"
  },
  {
    day: 'Tuesday',
    open: "00:00",
    close: "00:00"
  },
  {
    day: 'Wednesday',
    open: "00:00",
    close: "00:00"
  },
  {
    day: 'Thursday',
    open: "00:00",
    close: "00:00"
  },
  {
    day: 'Friday',
    open: "00:00",
    close: "00:00"
  },
  {
    day: 'Saturday',
    open: "00:00",
    close: "00:00"
  },
  {
    day: 'Sunday',
    open: "00:00",
    close: "00:00"
  }
]

const generatedHours: any = [];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60 / 15; j++) {
    const leftTimer = i.toString().length === 1 ? `${"0" + i}` : i;
    const rightTimer = (j * 15).toString().length === 1 ? `${j + "0"}` : (j * 15);
    let hourData = { value: `${leftTimer + ":" + rightTimer}`, label: `${leftTimer + ":" + rightTimer}` }
    generatedHours.push(hourData)
  }
}

const cities = [
  { label: "America", value: 'America/Fortaleza' },
  { label: "Dubai", value: 'Asia/Dubai' },
  { label: "India", value: 'Asia/Kolkata' },
];

function YourEfoneNumbersSchedule(props: any) {
  const classes = useStyles();
  const [specificHours, setSpecificHours] = React.useState(false);
  const [selectAlwaysOpen, setselectAlwaysOpen] = React.useState(true);
  const [location, setLocation] = React.useState<any>({ label: '', value: '' });
  const [week, setsameAsWeek] = React.useState(false);
  const [open, setOpen] = useState(false);

  const [weekDaysArray, setWeekDaysArray] = React.useState<any>([]);
  const [weekDaysTimings, setWeekDaysTimings] = React.useState<any>({
    Monday: {
      open: "",
      close: "",
    },
    Tuesday: {
      open: "",
      close: "",
    },
    Wednesday: {
      open: "",
      close: "",
    },
    Thursday: {
      open: "",
      close: "",
    },
    Saturday: {
      open: "",
      close: "",
    },
    Friday: {
      open: "",
      close: "",
    },
    Sunday: {
      open: "",
      close: "",
    },
  });



  const updatedEfoneNumberSchedule = (data: any) => {
    ProfileServices.updateEfoneNumberSchedule(data)
      .then((res: any) => {
        if (res.success) {
          console.log(res);

        } else {
          //send error
        }
      })
      .catch((err: any) => {
        //send error
      });
  };

  React.useEffect(() => {

    if (props.profileData) {
      setselectAlwaysOpen(props.profileData?.alwaysOpen)
      setSpecificHours(props.profileData?.alwaysOpen ? false : true)
      const daysData: any = props.profileData?.days;
      // setWeekDaysArray(daysData);
      const citesFilter = citiess.find((cit:any) => cit.value === props.profileData?.timezone)
      setTimeZoneValue(props.profileData?.timezone)

      setLocation(citesFilter)
      if (daysData.length > 0) {
        setWeekDaysArray(daysData);
        let stateWeekDay: any = weekDaysTimings;

        daysData.forEach((dayData: any) => {
          stateWeekDay[dayData.day].open = {
            value: dayData.open,
            label: dayData.open,
          };
          stateWeekDay[dayData.day].close = {
            value: dayData.close,
            label: dayData.close,
          };
        });
        setWeekDaysTimings(stateWeekDay);
      }


      else {
        setWeekDaysArray(daysDataHardcoded);

        let stateWeekDay: any = weekDaysTimings;

        daysDataHardcoded.forEach((dayData: any) => {
          stateWeekDay[dayData.day].open = {
            value: dayData.open,
            label: dayData.open,
          };
          stateWeekDay[dayData.day].close = {
            value: dayData.close,
            label: dayData.close,
          };
        });

        setWeekDaysTimings(stateWeekDay);
      }
    }

  }, [props.profileData]);


  const onChangeWeekdayTimings = (data: any, event: any) => {
    console.log("---change week timing", data, event)
    const dayData: any = event.name.split("_");
    setWeekDaysTimings((prev: any) => {

      let oldState: any = prev;
      oldState[dayData[0]][dayData[1]] = data

      return {
        ...prev,
        ...oldState
      };
    })

    let copyWeekData: any = weekDaysArray;
    copyWeekData.forEach((Daydata: any) => {
      if (Daydata.day === dayData[0]) {
        Daydata[dayData[1]] = data.value;
      }
    });
    setWeekDaysArray(copyWeekData);
    // console.log("---week days array", weekDaysArray)
    updatedEfoneNumberSchedule({ days: copyWeekData });
  };

  const specificHoursChange = () => {
    setSpecificHours(!specificHours);
    setselectAlwaysOpen(!selectAlwaysOpen);
    updatedEfoneNumberSchedule({ alwaysOpen: !selectAlwaysOpen })
    props.parentCallback(!selectAlwaysOpen)
  };

  const alwaysOpenChange = () => {
    setselectAlwaysOpen(!selectAlwaysOpen);
    setSpecificHours(!specificHours);
    updatedEfoneNumberSchedule({ alwaysOpen: !selectAlwaysOpen })
    props.parentCallback(!selectAlwaysOpen)
  };

  const handleInputlocationOnchange = (data: any) => {
    // setLocation(data)
    setTimeZoneValue(data.value)
    updatedEfoneNumberSchedule({ timezone: data?.value })
  };

  const handleWeekChange = () => {

    setsameAsWeek(!week);

    if (!week) {
      const { open, close } = weekDaysTimings.Monday;

      let stateWeekDay: any = weekDaysTimings;
      let weekkDayArrayKeys: any = Object.keys(stateWeekDay);
      let copyWeekData: any = weekDaysArray;

      if (open && close) {
        weekkDayArrayKeys.forEach((weekDay: any) => {
          stateWeekDay[weekDay].open = open;
          stateWeekDay[weekDay].close = close;
        })

        copyWeekData.forEach((copweek: any) => {
          copweek.open = open.value;
          copweek.close = close.value;
        })

        setWeekDaysTimings(stateWeekDay)
        updatedEfoneNumberSchedule({ days: copyWeekData });
      }

    }

  };


  const hoursHeader = ["Day", "Opens At", "Closes At", "Same as all Week"];

  const hours = [
    {
      id: 1,
      day: "Monday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Monday.open}
          onChange={onChangeWeekdayTimings}
          name="Monday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Closes At"}
          options={generatedHours}
          value={weekDaysTimings.Monday.close}
          onChange={onChangeWeekdayTimings}
          name="Monday_close"
        />
      ),
      switch: <Switch color="primary" onChange={handleWeekChange} />,
    },
    {
      id: 2,
      day: "Tuesday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Tuesday.open}
          onChange={onChangeWeekdayTimings}
          name="Tuesday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Closes At"}
          options={generatedHours}
          value={weekDaysTimings.Tuesday.close}
          onChange={onChangeWeekdayTimings}
          name="Tuesday_close"
        />
      ),
    },
    {
      id: 3,
      day: "Wednesday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Wednesday.open}
          onChange={onChangeWeekdayTimings}
          name="Wednesday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={'Closes At'}
          options={generatedHours}
          value={weekDaysTimings.Wednesday.close}
          onChange={onChangeWeekdayTimings}
          name="Wednesday_close"
        />
      ),
    },
    {
      id: 4,
      day: "Thursday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Thursday.open}
          onChange={onChangeWeekdayTimings}
          name="Thursday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Closes At"}
          options={generatedHours}
          value={weekDaysTimings.Thursday.close}
          onChange={onChangeWeekdayTimings}
          name="Thursday_close"
        />
      ),
    },
    {
      id: 5,
      day: "Friday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Friday.open}
          onChange={onChangeWeekdayTimings}
          name="Friday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Closes At"}
          options={generatedHours}
          value={weekDaysTimings.Friday.close}
          onChange={onChangeWeekdayTimings}
          name="Friday_close"
        />
      ),
    },
    {
      id: 6,
      day: "Saturday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Saturday.open}
          onChange={onChangeWeekdayTimings}
          name="Saturday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Closes At"}
          options={generatedHours}
          value={weekDaysTimings.Saturday.close}
          onChange={onChangeWeekdayTimings}
          name="Saturday_close"
        />
      ),
    },
    {
      id: 7,
      day: "Sunday",
      opensAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Opens At"}
          options={generatedHours}
          value={weekDaysTimings.Sunday.open}
          onChange={onChangeWeekdayTimings}
          name="Sunday_open"
        />
      ),
      closesAt: (
        <ReactSelect
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // isDisabled={week}
          placeholder={"Closes At"}
          options={generatedHours}
          value={weekDaysTimings.Sunday.close}
          onChange={onChangeWeekdayTimings}
          name="Sunday_close"
        />
      ),
    },
  ];

  const handleChange = () => {
    setOpen(!open);
  };

   //timeZone
   const [Loading, setloading] = React.useState(false);
   const [citiess, setCitiess] = React.useState<any>([]);
   const [timezoneValue,setTimeZoneValue] = React.useState<any>('');
 
   const timezoneCities = React.useCallback(() => {
     setloading(true);
     axiosInstance.get('/timezones').then((res: any) => {
         if (res.data.data) {
           const data = res.data.data
           var List: any = [];
             data.map((item: any) => {
               var object = {
                 "value": item.timezone,
                 "label": item.timezone
               }
               List.push(object)
             })
             setCitiess(List)
         } else {
           setloading(false)
         }
       }).catch((err: any) => {
         setloading(false)
       })
   }, [])
 
   React.useEffect(() => {
     timezoneCities()
   }, [])

  return (
    <div >
      <CardContent>
        <Card className={classes.card}>
          <Accordion onChange={handleChange}>
            <AccordionSummary
              expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Your eFone Number(s) Schedule
              </Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
              <CardContent className={classes.paddingGrid}>
                <Grid container spacing={5}>
                  <Grid item lg={6} md={6} sm={6} xl={6} xs={6}>
                    <Typography className={classes.departmentNameText}>
                      Select Hours
                    </Typography>
                    <CardContent className={classes.switchCard}>
                      <Grid container spacing={5}>
                        <Grid
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xl={6}
                          className={classes.switchCard}
                        >
                          <Switch
                            checked={selectAlwaysOpen}
                            color="primary"
                            onChange={alwaysOpenChange}
                          />
                          <Typography className={classes.hoursText}>
                            24/7
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xl={6}
                          className={classes.switchCard}
                        >
                          <Switch
                            checked={specificHours}
                            color="primary"
                            onChange={specificHoursChange}
                          />
                          <Typography className={classes.hoursText}>
                            Specific Hours
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                  <Grid item xs={6} lg={6} md={6} sm={6} xl={6}>
                    <Typography
                      className={`${classes.margin} ${classes.departmentNameText}`}
                    >
                      Select Time Zone
                    </Typography>
                    <ReactSelect
                      native
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      className={classes.width}
                      placeholder={"Select Your Time Zone"}
                      options={citiess}
                      name="timezone"
                      value={
                        citiess.filter((option: any) =>
                          option.value === timezoneValue)
                      }
                      // value={location}
                      onChange={handleInputlocationOnchange}
                    />
                  </Grid>
                </Grid>
                {specificHours ? (
                  <CardContent className={classes.specificContent}>
                    <Typography className={classes.specificText}>
                      Specific Hours
                    </Typography>
                    <TableContainer
                      component={Card}
                      className={classes.tableContainer1}
                    >
                      <Table>
                        <TableHeaderComponent theadData={hoursHeader} />
                        <TableBody>
                          {hours.map((detail: any) => {
                            return (
                              <>
                                <TableRow key={detail.id}>
                                  <TableCell className={classes.tableCellBody1}>
                                    {detail.day}
                                  </TableCell>
                                  <TableCell className={classes.tableCellBody2}>
                                    {detail.opensAt}
                                  </TableCell>
                                  <TableCell className={classes.tableCellBody2}>
                                    {detail.closesAt}
                                  </TableCell>
                                  <TableCell className={classes.tableCellBody1}>
                                    {detail.switch}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                ) : null}
              </CardContent>
            </AccordionDetails>
          </Accordion>

          {/* <CardHeader
            name={'Your Efone Numbers Schedule'}
            textfield={false}
            label={'Your Efone Numbers Schedule'}
            addbox={false}
          />
          <Divider className={classes.divider} />
           */}
        </Card>
      </CardContent>
    </div>
  );
}

export default YourEfoneNumbersSchedule;

import { Button, Card, CardContent, Divider, Grid, IconButton, Accordion, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
// import Pagination from '@material-ui/lab/Pagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useStyles } from '../styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { BsArrow90DegDown, BsArrow90DegUp } from 'react-icons/bs'
import ReactSelect from 'react-select';
import Service from '../HistoryServices/Services'
import Moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation, useParams } from 'react-router-dom';
import { AiFillInfoCircle, AiFillPlayCircle } from 'react-icons/ai';
import AllCalls from '../../Dashboard/Analytics/AllCalls/AllCalls';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';


function Reports(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [userId, setUserId] = React.useState<any>();
    const [type, setype] = React.useState<any>(1)
    const [loading, setLoading] = React.useState(false);
    const [recordingModal, setRecordingModal] = React.useState(false)

    const [reports, setReports] = React.useState<any>([])
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)

    let coutomFilters: any = '';
    if (queryParams.get('departmentId')) {
        coutomFilters += '&departmentId=' + queryParams.get('departmentId');
    }
    if (queryParams.get('type')) {
        coutomFilters += '&type=' + queryParams.get('type');
    }
    if (queryParams.get('callDirection')) {
        coutomFilters += "&callDirection=" + queryParams.get('callDirection');
    }
    if (queryParams.get('connected')) {
        coutomFilters += "&connected=" + queryParams.get('connected');
    }
    if (queryParams.get('userId')) {
        coutomFilters += "&userId=" + queryParams.get('userId');
    }
    if (queryParams.get('roomPhoneId')) {
        coutomFilters += "&roomPhoneId=" + queryParams.get('roomPhoneId');
    }

    const [state, setState] = React.useState({
        page: 1,
        totalDocs: 0,
        limit: 25,
        totalPages: 0
    })


    const expandOpen = () => {
        setOpen(!open);
    };
    const keyOptions = [
        { label: "Today", value: 1 },
        { label: "Yesterday", value: 2 },
        { label: "Last 7 days", value: 3 },
        { label: "Last 30 days", value: 4 },
        { label: "This Month", value: 5 },
        { label: "Last Month", value: 6 },
        { label: "Custom", value: 7 },
    ]
    const handleOptions = (e: any) => {
        localStorage.setItem('dbReportsStats', e.value)
        setype(e.value)
        getReports(e.value, state.page, state.limit)
    }
    const getReports = (type: any, page: any, limit: any) => {
        setLoading(true);
        Service.getReports(type, page, limit, coutomFilters).then((res) => {
            if (res.success) {
                setLoading(false);
                setReports(res.data.data.docs)
                setState((prev) => {
                    return {
                        ...prev,
                        totalDocs: res.data.data.totalDocs,
                        page: res.data.data.page,
                        limit: res.data.data.limit,
                        totalPages: res.data.data.totalPages
                    }
                })
            }
            else {
                setLoading(false);
            }
        }).catch((err) => {
            setLoading(false);
        })
    }
    const handleChangePage = (event: any, value: any) => {
        setState((prev) => {
            return {
                ...prev,
                page: value
            }
        });
    }

    React.useEffect(() => {
        let lType: any = 1;
        if (props.match?.path == '/dashboard/allreports') {
            if (queryParams.get('type')) {
                setype(queryParams.get('type'));
                lType = queryParams.get('type');
            }
        } else {
            //console.log('im here in else');
            if (localStorage.getItem('dbReportsStats')) {
                const getValues: any = localStorage.getItem('dbReportsStats');
                setype((getValues));
                lType = parseInt(getValues)
            }
        }
        getReports(lType, state.page, state.limit)
    }, [state.page])


    // const reportsType: any = localStorage.getItem('dbReportsStats')
    // const rt = parseInt(reportsType)

    // React.useEffect(() => {
    //     if (props.match?.path == '/dashboard/allreports') {
    //         if (queryParams.get('type')) {
    //             setype(queryParams.get('type'));
    //         }
    //     }
    //     if (rt) {
    //         getReports(rt, state.page, state.limit)
    //         setype(rt)
    //     }
    //     if (!rt) {
    //         getReports(type, state.page, state.limit)
    //     }
    // }, [state.page, rt])



    function toTime(d: any) {
        d = Number(d);
        var h = (Math.floor(d / 3600)).toString().length > 1 ? Math.floor(d / 3600) : '0' + Math.floor(d / 3600);
        var m = (Math.floor(d % 3600 / 60)).toString().length > 1 ? Math.floor(d % 3600 / 60) : '0' + Math.floor(d % 3600 / 60);
        var s = (Math.floor(d % 3600 % 60)).toString().length > 1 ? Math.floor(d % 3600 % 60) : '0' + Math.floor(d % 3600 % 60);
        return h + ":" + m + ":" + s
    }

    //playaudio
    const [filePath, setFilePath] = React.useState("")
    const handlechangeRecModal = (path: any) => {
        setRecordingModal(!recordingModal)
        // setRecording(rec)
        setFilePath(path)
    }

    return (
        <>
            {
                (props.location?.pathname == '/dashboard/allreports') &&
                <>
                    <AllCalls />
                </>
            }
            {
                recordingModal && (
                    <div style={{ width: "500px" }}>
                        <Backdrop handlePopupModal={handlechangeRecModal} />
                        <Modal
                            //title={recording ? "VoiceMail" : "Recording"}\
                            title="Play Audio"
                            handlePopupModal={handlechangeRecModal}

                        // breakLine={false}
                        >
                            <div style={{ width: "500px", height: "31%",padding:'10px' }}>
                                <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                                    <ReactAudioPlayer
                                        controls
                                        className={classes.audioPlayers}
                                        src={filePath}
                                    // playing={srcss}
                                    />
                                </Grid>
                            </div>
                        </Modal>
                    </div>
                )
            }
            <div id='reports'>
                <Card className={classes.card}>
                    <Accordion
                        expanded={open}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="#2645ad" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container className={classes.grid}>
                                <Grid item xs={4} lg={10} md={8} sm={8} xl={10}>
                                    <Typography className={classes.heading}>Reports</Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} md={4} sm={4} xl={2}>
                                    <ReactSelect
                                        placeholder="Select key"
                                        options={keyOptions}
                                        onChange={handleOptions}
                                        value={keyOptions.filter((opt: any) => opt.value == type)}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <CardContent className={classes.tableSpace} style={{ padding: "14px" }}>
                            <Typography className={classes.headingOne} style={{ marginTop: -15, marginBottom: 12, fontSize: 15 }}>Total Reports: {state.totalDocs}</Typography>
                            <Card>
                                <TableContainer className={classes.tablewidth}>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{ background: '#fbfbfb' }}>
                                                <TableCell className={classes.tableHead}>Department</TableCell>
                                                <TableCell className={classes.tableHead}>User</TableCell>
                                                <TableCell className={classes.tableHead}>Agent</TableCell>
                                                <TableCell className={classes.tableHead}>FromNumber</TableCell>
                                                <TableCell className={classes.tableHead}>ToNumber</TableCell>
                                                <TableCell className={classes.tableHead}>Duration(Secs)</TableCell>
                                                <TableCell className={classes.tableHead}>Start Time</TableCell>
                                                <TableCell align={'center'} className={classes.tableHead}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ border: '1px solid #E2E2E2' }}>
                                            {loading ?
                                                <Box>
                                                    <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                                                        <CircularProgress />
                                                    </div>
                                                </Box>
                                                :
                                                reports?.length > 0 && reports.map((item: any, i: any) => {
                                                    var data = parseInt(item?.startTime)
                                                    var dataa = Moment(data).format('YYYY-MM-DD hh:m:s')
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell className={classes.tableBody}>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                                    {item.callDirection === '1' ?
                                                                        <><BsArrow90DegDown color='blue' /></> :
                                                                        <><BsArrow90DegUp color='green' /></>
                                                                    }
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.departmentName ? item.departmentName : '_'}</Typography>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            {item.userId ?
                                                                <TableCell className={classes.tableBody}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.userName}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            {item.agentId ?
                                                                <TableCell className={classes.tableBody}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.agentName}</Typography>
                                                                        {/* <Typography>{item.agentId && item.agentId.firstName}  {item.agentId && item.agentId.lastName}</Typography> */}
                                                                    </div>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            <TableCell className={classes.tableBody}>{item?.fromNumber}</TableCell>
                                                            <TableCell className={classes.tableBody}>{item?.toNumber}</TableCell>
                                                            <TableCell className={classes.tableBody}>{toTime(item?.duration)}</TableCell>
                                                            <TableCell className={classes.tableBody}>{dataa}</TableCell>
                                                            <TableCell className={classes.tableBody}>
                                                                {item.status}
                                                                {/* <AiFillPlayCircle onClick={() => handlechangeRecModal(item.recordFile)}/> */}
                                                                {
                                                                    item.recordFile && item.recordFile !== null ?
                                                                        <>
                                                                          <AiFillPlayCircle style={{cursor:'pointer'}} onClick={() => handlechangeRecModal(item.recordFile)}/>
                                                                        </>
                                                                        :
                                                                        "_"
                                                                }

                                                            </TableCell>
                                                        </TableRow>

                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                    {
                                        reports.length <= 0 &&
                                        <p className={classes.pText}>
                                            No Data found
                                        </p>
                                    }

                                </TableContainer>
                            </Card>
                            {reports.length > 0 &&
                                <Stack spacing={2} style={{ marginTop: 15, float: "right", marginBottom: "20px" }}>
                                    <Pagination
                                        count={state.totalPages}
                                        //page={state.page} 
                                        onChange={handleChangePage}
                                        variant="outlined" color="secondary" />
                                </Stack>}
                        </CardContent>
                    </Accordion>
                </Card>
            </div>
        </>
    )
}
export default Reports;

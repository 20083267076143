import { Button, Card, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { IoIosSearch } from 'react-icons/io'
import { CiSettings } from 'react-icons/ci'
import profileImg from '../../assets/images/profileImg.png'
import { useStyles } from './style'
import { ImAttachment } from 'react-icons/im'
import { FiSend } from 'react-icons/fi'
import LeftConversation from './LeftConversation'
import { useSelector } from 'react-redux'
import io from 'socket.io-client'
import axiosInstance from '../../environment/axiosInstance'
import Chat from './Chat/Chat'
import socket from '../Authentication/Config/socket'
import { useParams } from 'react-router-dom'







function Messages(props: any) {
    const classes = useStyles()
    const userStateData = useSelector((state: any) => state.authState)
    const [socketConnection, setSocketConnection] = React.useState('')
    const [isSearchFocusing, updateSearchFocusing] = React.useState(false);
    const [isMouseOverResult, updateMouseOverResult] = React.useState(false);
    const [searchResult, setSearchResult] = React.useState<any>([]);
    const [chats, setchats] = React.useState<any>([])
    const [list, setList] = React.useState([])
    // const [searchData,setSearchdata] = React.useState<any>('')
    const userId = userStateData.userData?.id
    const chatId = props.match.params.id
    const [chatName, setChatName] = React.useState('')
    const [groupName, setGroupName] = React.useState()

    const params: any = useParams()
    const { callOptions } = props

    // console.log('params', params)
    // React.useEffect(() => {
    //     const socket = io("http://callapi.efone.ca:7792", {
    //         "rejectUnauthorized": false,
    //         "timeout": 20000,
    //         "reconnection": false,
    //         "reconnectionAttempts": 3,
    //         "reconnectionDelay": 1000,
    //         "reconnectionDelayMax": 5000
    //     });
    //     socket.on("connect", () => {
    //         console.log("Socket Connected", socket)


    //     })
    //     socket.on('disconnect', () => {
    //         console.log("not Connected", socket)
    //     })


    // }, [])


    const fetchPeople = () => {
        axiosInstance.get(`/contacts?isEfoneNumber=1`).then((res: any) => {
            //  console.log('yyy',res);
            const data: any = res.data.Data
            if (res.status !== 200) {
                searchResult.length = 0;
            }
            else {
                // setSearchResult([...data])
                setSearchResult(data)
            }
        }).catch((res: any) => {

        })
    }




    const searchOnFocus = () => {
        updateSearchFocusing(true);
    };

    const searchOutFocus = ((e: any) => {
        e.target.value = '';
        updateSearchFocusing(false);
    });

    React.useEffect(() => {
        fetchPeople()
    }, [])

    React.useEffect(() => {
        if (list.length > 0) {
            const findUser: any = list.find((item: any) => item._id === params.id)
            // console.log('findUser', findUser, params)
            if (findUser !== undefined) {
                setChatName(findUser?.secondUserName)
            }

        }
    }, [params.id, list])

    // const setMouseOverResult = ((isOver:any) => {
    //     updateMouseOverResult(isOver);
    // });

   
    
    const listConversations = () => {
        axiosInstance.get(`/chat/conversation-list`).then((res: any) => {
            if (res.status === 200) {
                const data = res.data.list;
                // data.map((itm:any) => {
                //     return(
                //         setGroupName(itm?.isGroup)
                //     )
                // })
                setList(data)
            }

        }).catch((err: any) => {

        })
    }


    React.useEffect(() => {
        listConversations()
    }, [])

    // console.log('group', groupName);


    
    return (
        <div>
            <Grid container spacing={2} style={{ position: 'relative', }}>
                {/* left */}
                <Grid item md={3}>
                    <LeftConversation
                        userId={userId}
                        searchOnFocus={searchOnFocus}
                        listConversations={listConversations}
                        // searchOutFocus={searchOutFocus}
                        searchResult={searchResult}
                        chats={chats}
                        chatId={chatId}
                        list={list}
                    />
                </Grid>

                {/* right chat */}

                <Grid item md={9}>
                    <Chat
                        chatName={chatName}
                        list={list} 
                        chatId={chatId}
                        userId={userId}
                        listConversations={listConversations}
                        searchResult={searchResult}
                        callOptions={callOptions}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

export default Messages
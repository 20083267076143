import React from 'react'
import ReactDom from 'react-dom'
import './style.css'

function SidebarBackdrop(props: any) {

    
    const modalBody: any = (
        <div className="backdrop_container_sidebar" onClick={props.handlePopupModal}>

        </div>
    )

    const domElement: any = document.getElementById('modal_popup_root2');

    return ReactDom.createPortal(modalBody, domElement)
}

export default SidebarBackdrop


import io from 'socket.io-client';
import { SOCKET_URL } from "./default";


// const socket = io('http://134.122.36.172:7780', {
//     reconnection: true,
//     reconnectionDelay: 1000,
//     reconnectionDelayMax: 5000,
//     reconnectionAttempts: Infinity
// });


let socket = io("https://callapi.efone.ca:7780", {
    "secure": true,
    "autoConnect": true,
    "transports": ["websocket", "polling"],
    "rejectUnauthorized": false,
    "timeout": 20000,
    "reconnection": false,
    "reconnectionAttempts": 3,
    "reconnectionDelay": 1000,
    "reconnectionDelayMax": 5000
});



socket.on('disconnect', () => {
    console.log('5000', 'disconnect')
   
});

export default socket;

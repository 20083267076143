import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Switch,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    makeStyles,
    InputLabel, FormControl, Avatar, Button, Tabs, Tab,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import Itag from '../../../../assets/icons/italiantag.png'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { BorderColor, Delete, Save } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import ReactSelect from 'react-select';
import Select from 'react-select';
// import Select from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
// import './style.css'
import { useStyles } from './styles';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi'
import AudioPlayer from 'material-ui-audio-player'
// import DepartmentServices from '../../DepartmentServices/DepartmentServices'
import { useSelector } from 'react-redux';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
// import RecordAudioModal from '../../RecordAudioModal/RecordAudioModal';
import ProfileServices from '../../../Profile/ProfileServices/ProfileServices'
import microphone from '../../../../assets/images/Microphone.png'
import upload from '../../../../assets/images/Upload.png'
import RecordAudio from './RecordAudio';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import UploadAudio from './UploadAudio';

const muiTheme = createMuiTheme({});


const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });


function CloseHoursGreeting(props: any) {
    const classes = useStyles();
    const [srcss, setSrcss] = React.useState<any>('')
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [value, setValue] = React.useState("2");
    const [filesList, setFilesList] = React.useState<any>();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    // const [openModalAdd, setopenModalAdd] = React.useState(true);
    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        console.log(duration);
    }

    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }


    React.useEffect(() => {
        setFilesList(props.closeHoursData?.closeHours?.closeHrGreetingFiles)
    }, [props.closeHoursData])

    // console.log('greerrrrrr__',props.closeHoursData)

    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div className={classes.divSpacing}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    style={{}}
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            closeHoursData={props.closeHoursData}
                                            getClosedHours={props.getClosedHours}
                                            deptId={props.deptId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadAudio
                                            closeHoursData={props.closeHoursData}
                                            getClosedHours={props.getClosedHours}
                                            deptId={props.deptId}
                                            loading={props.loading}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }
            <Card className={classes.greetingCard}>
                <div className={classes.greetMainSection}>
                    <Typography className={classes.subheading}>Close Hour Greeting</Typography>
                    <div className={classes.greetMainSection}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography className={classes.heading}>{props.title}</Typography>
                            <Typography className={classes.actionline}>{props.actionline}</Typography>
                        </div>
                        <div className={classes.audioContainer}>
                            <Grid container spacing={2}>
                                <Grid item md={8} sm={9} xs={12}> 
                                    <ThemeProvider theme={muiTheme}>
                                        <ReactAudioPlayer
                                            controls
                                            // className={classes.audioPlayer}
                                            onLoadedMetadata={handleLoadMetadata}
                                            src={props.closeHoursGreeting?.path !== "" ? props.closeHoursGreeting?.path : ''}
                                            //src={props.recPath !== "" ? props.recPath : ""}

                                            className={classes.audioPlayers}
                                        />
                                        <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                            <span className={classes.filename}>{props?.closeHoursGreeting?.fileName}</span>
                                        </div>

                                    </ThemeProvider>
                                </Grid>
                                <Grid item md={4} sm={3} xs={12}>
                                    <Button size='small' className={classes.editbtn} onClick={handlePopupModalAdd}>
                                        Edit
                                    </Button>
                                </Grid>
                            </Grid>


                        </div>

                        {/* <div>
                            <div className={classes.audioContainer}>

                                <ThemeProvider theme={muiTheme}>
                                    <ReactAudioPlayer
                                        controls
                                        // className={classes.audioPlayer}
                                        onLoadedMetadata={handleLoadMetadata}
                                        src={srcss}
                                        className={classes.audioPlayers}
                                    />

                                </ThemeProvider>
                                <Button size='small' className={classes.editbtn} onClick={handlePopupModalAdd}>
                                    Edit
                                </Button>
                            </div>
                            <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                <span className={classes.filename}>Filename.mp3</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Card>
        </>

    )

}
export default CloseHoursGreeting
import React from 'react'
import { useStyles } from './styles'
import microphone from '../../../../assets/images/mic.png'
import edit from '../../../../assets/images/scissors 2.png'
import deleteicon from '../../../../assets/images/deleteicon.png'
import { Divider, Grid, TextField } from '@material-ui/core'
import Button from '@mui/material/Button';

import ReactSelect from 'react-select'
import { width } from '@mui/system'

const arr = [0, 1, 2, 3]
function RecordAudio(props: any) {

    const classes = useStyles()
    return (
        <div>
            <div>
                <div className={classes.dFlex}>
                    <div style={{ textAlign: 'center' }}>
                        <div 
                        style={{ background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)', width: '85px', height: '85px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={microphone} alt="microphone" style={{ color: '#FFFFFF' }} />
                        </div>
                        <Button variant="contained">stop</Button>
                        <p className={classes.minutes}>01:00</p>
                    </div>
                </div>
                <div className={classes.dFlex}>
                    <div className={classes.dFlex}>
                        <audio controls   className={classes.playaudioTrack}  ></audio>
                        <img src={edit} alt="editaudio" style={{ marginLeft: '15px' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '50px' }}>
                    <div>
                        <TextField
                            placeholder='Enter File Name'
                            size="small"
                            variant="outlined"
                            className={classes.inputFieldfilename}                         />
                        <Button variant="contained" className={classes.saveBtn}>Save</Button>
                    </div>
                </div>
            </div>
            <Divider style={{ border: '1px solid #E2E2E2', width: '100%' }} />
            <div style={{ marginTop: '35px', marginLeft: '15px' }}>
                {/* <div style={{ width: '150px', height: '30px', marginBottom: '20px' }}>
                    <ReactSelect />
                </div> */}
                {arr.map(() =>
                (
                    <div style={{ marginBottom: '15px' }}>
                        <div style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
                            border: '1px solid #F0F3F5'
                        }}>
                            <audio controls style={{ width: '500px', background: '#E9ECED' }}></audio>
                            <img src={deleteicon} alt="editaudio" style={{ marginLeft: '15px' }} />
                        </div>
                        <span className={classes.filename}>filename.mp3</span>
                    </div>
                )
                )}
                <div className={classes.dFlex}>
                    <Button variant='contained' className={classes.saveAsdefaultBtn}>Save as Default</Button>
                </div>
            </div>
        </div>
    )
}

export default RecordAudio
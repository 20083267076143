import React from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import ReactSelect from 'react-select';
import { useStyles } from './styles';
import ProfileServices from '../../../../ProfileServices/ProfileServices'
const devicetypeoptions = [
    {
        label: 'Andriod', value: 1,
    },
    {
        label: 'IOS', value: 2,
    },
    {
        label: 'Home Phone', value: 3,
    }
]

function AddNewDevice(props: any) {
    const classes = useStyles();
    const [selectedDevice, setSelectedDevice] = React.useState<any>(null)
    const [sipDetails, setSipDetails] = React.useState<any>({});
    const [addData, setAddData] = React.useState({
        "deviceType": '',
        "deviceName": '',
        "sipUsername": '',
        "sipPassword": '',
        "macAddress": '',
        "ipAddress": '',
        "port": ''
    })
    const onChangeDeviceType = (data: any) => {
        setSelectedDevice(data)
        setAddData((prev: any) => {
            return {
                ...prev,
                "deviceType": data?.value,
            }
        })
    }

    const onChangeDeviceName = (e: any) => {
        setAddData((prev: any) => {
            return {
                ...prev,
                "deviceName": e.target?.value,
            }
        })
    }

    const onChangeMacAddress = (e: any) => {
        setAddData((prev: any) => {
            return {
                ...prev,
                "macAddress": e.target?.value,
            }
        })
    }

    const getSipDetails = () => {
        ProfileServices.fetchSipDetails().then((res: any) => {

            if (res.success) {
                setSipDetails(res.data)
                if (res.data) {
                    const { sipUsername, sipPassword, ipAddress, port,macAddress } = res.data
                    setAddData((prev: any) => {
                        return {
                            ...prev,
                            "sipUsername": sipUsername,
                            "sipPassword": sipPassword,
                            "ipAddress": ipAddress,
                            "port": port,
                            "macAddress":macAddress
                        }
                    })
                }
            } else {
                // sne derror
            }
        }).catch((err: any) => {
            // send error
        })
    }

    const addDataDeviceData = () => {
        ProfileServices.postDeviceData(addData,'').then((res: any) => {
            if (res.success) {
                props.handlePopupModal()
                props.getMyDeviceLists()
            } else {

            }
        }).catch((err: any) => {
            // send error
        })
    }

    React.useEffect(() => {
        getSipDetails()
    }, [])



    return (
        <div>
            <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item md={6} sm={6} xs={12}>
                    <ReactSelect
                        placeholder="Device Type"
                        value={selectedDevice}
                        options={devicetypeoptions}
                        onChange={onChangeDeviceType}
                    />
                    {
                        selectedDevice !== null && selectedDevice.value === 3 && (
                            <TextField
                                className={classes.textField}
                                placeholder="Mac Address"
                                size="small"
                                variant="outlined"
                                fullWidth
                                value={addData?.macAddress}
                                onChange={onChangeMacAddress}
                            />
                        )
                    }
                </Grid>
                <Grid item md={6}>
                    <TextField
                        placeholder="Device Name"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={addData?.deviceName}
                        onChange={onChangeDeviceName}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item md={12} sm={12}>
                    <Typography className={classes.textColorOne}>SIP User Name:</Typography>
                    <Typography className={classes.textColor}>{sipDetails?.sipUsername}</Typography>
                    <Typography className={classes.textColorOne}>SIP IP:</Typography>
                    <Typography className={classes.textColor}>{sipDetails?.ipAddress}</Typography>

                    <Typography className={classes.textColorOne}>SIP Password:</Typography>
                    <Typography className={classes.textColor}>{sipDetails?.sipPassword}</Typography>
                    <Typography className={classes.textColorOne}>SIP Port:</Typography>
                    <Typography className={classes.textColor}>{sipDetails?.port}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 15, textAlign: "center", borderTop: '1px solid #ccc', }}>
                <Grid item md={12} sm={12}>
                    <div>
                        <Button size="medium" className={classes.submitButton} onClick={addDataDeviceData}>Submit</Button>
                        <Button size="medium" onClick={() => props.handlePopupModal()} className={classes.cancelButton}>Cancel</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default AddNewDevice;
import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Card, Divider, Grid, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useStyles } from './styles'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { BiEdit, } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ReactSelect from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal'
import TimeZone from './TimeZone'
import axiosInstance from '../../../environment/axiosInstance';
import { useSelector, useDispatch } from 'react-redux'
//import departmentServices from '../DepartmentServices/DepartmentServices'
import myAccountServices from '../MyAccountServices/MyAccountServices'
import * as DepartmentActionCreators from '../../../store/Actions/DepartmentsActionXCreators'
import { useParams } from "react-router-dom";
import './style.css'
//import TimeZoneNew from './TimeZoneNew'

const weekDays = [
    {
        day: 'Monday'
    },
    {
        day: 'Tuesday'
    },
    {
        day: 'Wednesday'
    },
    {
        day: 'Thursday'
    },
    {
        day: 'Friday'
    },
    {
        day: 'Saturday'
    },
    {
        day: 'Sunday'
    },
]

const weekDaystimingsData = [
    {
        "close": "",
        "day": "Monday",
        "open": ""
    },
    {
        "close": "",
        "day": "Tuesday",
        "open": ""
    },
    {
        "close": "",
        "day": "Wednesday",
        "open": ""
    },
    {
        "close": "",
        "day": "Thursday",
        "open": ""
    },
    {
        "close": "",
        "day": "Friday",
        "open": ""
    },
    {
        "close": "",
        "day": "Saturday",
        "open": ""
    },
    {
        "close": "",
        "day": "Sunday",
        "open": ""
    }
]

function Schedule(props: any) {
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState<any>(true);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [Loading, setloading] = React.useState(false);
    const [citiess, setCitiess] = React.useState<any>([]);
    const [timezoneValue, setTimeZoneValue] = React.useState<any>('');
    const [location, setLocation] = React.useState<any>({ label: '', value: '' });
    const departmetStateData = useSelector((state: any) => state?.departmentModule);
    const { selectedDepartment, loading } = departmetStateData;
    const [selectAlwaysOpen, setselectAlwaysOpen] = React.useState(false);
    const [specificHours, setSpecificHours] = React.useState(false);
    const [weekDaysArray, setWeekDaysArray] = React.useState<any>([]);
    const [weekDaysBackup, setWeekDaysBackup] = React.useState<any>([]);
    const [weekTImingSaved, setWeekTImeSaved] = React.useState(false)
    const [weekdaysTimings1, setWeekDaysTimings1] = React.useState<any>(weekDaystimingsData)
    const [scheduleData, setScheduleData] = React.useState<any>([])
    const [alwaysOpenCheck, setAlwaysOpenCheckbox] = React.useState('')

    const expandOpen = () => {
        setOpen(!open);
        setExpanded(!expanded);
    }
    const handlePopupModal = () => {
        setOpenModal(!openModal);
        // alert(`yyy${weekTImingSaved}`)
        if (weekTImingSaved === false) {
            // setWeekDaysTimings1(weekDaysBackup)
            //console.log('ttt', weekTImingSaved, weekDaysBackup)
        }
    };
    const onchangeDataSaved = (status: any) => {
        setWeekTImeSaved(status)
    }


    const onChangeWeekDaysTimingsData = (data: any) => {
        setWeekDaysTimings1(data)
    }
    const getShedule = () => {
        axiosInstance.get('/profile/settings/schedule').then((res: any) => {
            if (res.status === 200) {
                const resdata = res.data.Data
                setScheduleData(resdata)
                if (res.data.Data[0].days !== null && Array.isArray(res.data.Data[0].days)) {
                    const backUp: any = res.data.Data[0].days.length > 0 ? res.data.Data[0].days : weekDaystimingsData
                    setWeekDaysBackup([...backUp])
                    setWeekDaysTimings1(res.data.Data[0].days.length > 0 ? res.data.Data[0].days : weekDaystimingsData)
                }
                setAlwaysOpenCheckbox(resdata[0].alwaysOpen === true ? '24*7' : 'specificHours')
                setTimeZoneValue(resdata[0].timezone)

            }
        })
    }

    React.useEffect(() => {
        getShedule()
    }, [])


    // const onChangeWeekdayTimings = (data: any, event: any) => {
    //     const dayData: any = event.name.split("_");

    //     setWeekDaysTimings((prev: any) => {

    //         let oldState: any = prev;
    //         oldState[dayData[0]][dayData[1]] = data
    //         return {
    //             ...prev,
    //             ...oldState
    //         };
    //     })
    //     let copyWeekData: any = weekDaysArray;
    //     copyWeekData.forEach((Daydata: any) => {
    //         if (Daydata.day === dayData[0]) {
    //             Daydata[dayData[1]] = data.value;
    //         }
    //     });


    //     setWeekDaysArray(copyWeekData);
    //     updatedDepartmentSchedule(props.match.params.id, { days: copyWeekData });
    // };




    //this api call for updating schedule switch
    const updatedMyAccountSchedule = (data: any, statuskey: any) => {
        myAccountServices.updateMyAccountSchedule({ ...data })
            .then((res: any) => {
                if (res.success) {
                    onchangeDataSaved(true)
                    getShedule()
                    if (statuskey === 'days') {
                        handlePopupModal()

                    }
                } else {
                    //send error
                }
            })
            .catch((err: any) => {
                //send error
            });
    };

    const alwaysOpenChange = (status: any) => {
        setAlwaysOpenCheckbox(status)
        updatedMyAccountSchedule({ alwaysOpen: status === '24*7' ? true : false, days: weekdaysTimings1 }, 'alwaysopen')
        // setselectAlwaysOpen(!selectAlwaysOpen);
        // setSpecificHours(!specificHours);
        //updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen })
        //props.parentCallback(!selectAlwaysOpen)
    };

    // const specificHoursChange = () => {
    //     setSpecificHours(!specificHours);
    //     setselectAlwaysOpen(!selectAlwaysOpen);
    //     // updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen,days:weekdaysTimings1 }, 'alwaysopen')

    //     // props.parentCallback(selectAlwaysOpen)
    //     //updatedDepartmentSchedule(props.match.params.id, { alwaysOpen: !selectAlwaysOpen })
    //     // props.parentCallback(!selectAlwaysOpen)
    // };

    const handleInputlocationOnchange = (data: any) => {
        // setLocation(data);
        setTimeZoneValue(data.value)
        updatedMyAccountSchedule({ timezone: data?.value }, 'location')
    };

    // React.useEffect(() => {
    //     if (props.match.params.id) {
    //         setselectAlwaysOpen(props.match.params.id?.alwaysOpen)
    //         //   props.parentCallback(selectedDepartment?.alwaysOpen)
    //         setSpecificHours(!selectedDepartment?.alwaysOpen)
    //         const citesFilter = citiess.find((cit: any) => cit.value === props.match.params.id?.timezone)
    //         setLocation(citesFilter)
    //         setTimeZoneValue(props.match.params.id?.timezone)



    //         //   const daysData: any = selectedDepartment?.days;
    //         //   if (daysData.length > 0) {
    //         //     setWeekDaysArray(daysData);
    //         //     let stateWeekDay: any = weekDaysTimings;

    //         //     daysData.forEach((dayData: any) => {
    //         //       stateWeekDay[dayData.day].open = {
    //         //         value: dayData.open,
    //         //         label: dayData.open,
    //         //       };
    //         //       stateWeekDay[dayData.day].close = {
    //         //         value: dayData.close,
    //         //         label: dayData.close,
    //         //       };
    //         //     });

    //         //     setWeekDaysTimings(stateWeekDay);

    //         //   } else {
    //         //     setWeekDaysArray(daysDataHardcoded);

    //         //     let stateWeekDay: any = weekDaysTimings;

    //         //     daysDataHardcoded.forEach((dayData: any) => {
    //         //       stateWeekDay[dayData.day].open = {
    //         //         value: dayData.open,
    //         //         label: dayData.open,
    //         //       };
    //         //       stateWeekDay[dayData.day].close = {
    //         //         value: dayData.close,
    //         //         label: dayData.close,
    //         //       };
    //         //     });

    //         //     setWeekDaysTimings(stateWeekDay);
    //         //   }

    //     }
    // }, [props.match.params.id])

    //timeZone
    const timezoneCities = React.useCallback(() => {
        setloading(true);
        axiosInstance.get('/timezones').then((res: any) => {
            if (res.data.data) {
                const data = res.data.data
                var List: any = [];
                data.map((item: any) => {
                    var object = {
                        "value": item.timezone,
                        "label": item.timezone
                    }
                    List.push(object)
                })
                setCitiess(List)
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            setloading(false)
        })
    }, [])

    React.useEffect(() => {
        timezoneCities()
    }, [])





    const rangeCalculater = (date: any, index: any) => {
        // console.log('first', date, index)
        if (date === undefined || date === null) {
            return false;
        }
        const startRange =
            date.open !== "" ? Number(date.open.split(':')[0]) : null;

        const endRange =
            date.close !== "" ? Number(date.close.split(':')[0]) : null;

        if (startRange === null || endRange === null) {
            return false;
        }
        const inbtwnRange = (index >= startRange && index <= endRange) ? true : false;

        return {
            inbtwnRange: inbtwnRange,
            startIndex: {
                index: startRange,
                value: date.open
            },
            endIndex: {
                index: endRange,
                value: date.close
            }
        };
    };


    return (
        <div>
            <div className={classes.padding}>
                {
                    openModal && (
                        <div >
                            <Backdrop handlePopupModal={handlePopupModal} />
                            <Modal title="TimeZone" handlePopupModal={handlePopupModal} >
                                <div style={{ width: 850 }}>
                                    <TimeZone
                                        selectAlwaysOpen={selectAlwaysOpen}
                                        getShedule={getShedule}
                                        scheduleData={scheduleData}
                                        deptId={props.match.params.id}
                                        handlePopupModal={handlePopupModal}
                                        onchangeDataSaved={onchangeDataSaved}
                                        onChangeWeekDaysTimingsData={onChangeWeekDaysTimingsData}
                                        daysData={weekdaysTimings1}
                                        updatedMyAccountSchedule={updatedMyAccountSchedule}
                                    />
                                    {/* <TimeZoneNew/> */}
                                </div>
                            </Modal>
                        </div>
                    )
                }
            </div>

            <Card className={classes.card}>
                <Accordion
                    expanded={expanded}
                    onChange={expandOpen}
                >
                    <AccordionSummary
                        expandIcon={expanded ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.dflex}>
                            <Typography className={classes.heading}>eFone Number Schedule</Typography>
                        </div>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <div className={classes.dflex}>
                        <Typography className={classes.callFlow}>Select Business Hours</Typography>
                    </div>
                    <div className={classes.dflexCheck} style={{ marginLeft: 16, marginBottom: 20 }}>
                        <FormControlLabel
                            control={<Checkbox
                                //name="selectAlwaysOpen"
                                checked={alwaysOpenCheck === '24*7' ? true : false}
                                size='small'
                                // onChange={alwaysOpenChange}
                                onClick={() => {
                                    alwaysOpenChange('24*7')
                                }}
                            />
                            }
                            label="24/7"
                        />
                        <FormControlLabel
                            control={<Checkbox 
                                checked={alwaysOpenCheck === 'specificHours' ? true : false}
                                size='small'
                                // onChange={alwaysOpenChange}
                                onClick={() => {
                                    alwaysOpenChange('specificHours')
                                }}
                            />
                            }
                            label="Specific working hours" />
                    </div>
                    <div style={{ display: 'flex', gap: 15, alignItems: 'center', marginTop: 20 }}>
                        <Typography className={classes.headings}>Select TimeZone</Typography>
                        <Grid item md={4} sm={4} xs={6}>
                            <ReactSelect
                                options={citiess}
                                name="timezone"
                                value={
                                    citiess.filter((option: any) =>
                                        option.value === timezoneValue)
                                }
                                onChange={handleInputlocationOnchange}
                            />
                        </Grid>
                    </div>
                    <Divider style={{ marginTop: 20 }} />
                    {alwaysOpenCheck === 'specificHours' &&
                        <>
                            <div style={{ padding: 16 }}>
                                <Card className={classes.scheduleCard}>
                                    {
                                        weekDays.map((weekDay: any, index: any) => (
                                            <div onClick={handlePopupModal} key={index}>
                                                <Typography>{weekDay.day}</Typography>
                                                <div className={classes.divWdth}>
                                                    {
                                                        new Array(24).fill('').map((item: any, i: any) => {
                                                            const inbtwn: any = rangeCalculater(
                                                                weekdaysTimings1[index], i);
                                                            const cls = "hour" + " " + `${inbtwn.inbtwnRange ? `active ${(i === 0) && 'start_ele'} ${i === 23 && 'end_ele'}` : ""}`;
                                                            return (
                                                                <>

                                                                    <div className={cls} style={{ margin: 0, height: 10 }}>
                                                                        {inbtwn?.startIndex?.index === i &&
                                                                            <span style={{
                                                                                color: 'white', fontSize: 11, display: 'grid', justifyContent: 'center'
                                                                            }}>
                                                                                {inbtwn?.startIndex.value}{i <= 12 ? ' AM' : ' PM'}
                                                                            </span>
                                                                        }
                                                                        {inbtwn?.endIndex?.index === i &&
                                                                            <span style={{
                                                                                color: 'white',
                                                                                fontSize: 11,
                                                                                display: 'grid',
                                                                                justifyContent: 'center'
                                                                            }}>{inbtwn?.endIndex.value}{i > 12 ? ' PM' : ' AM'}</span>}
                                                                    </div>


                                                                </>
                                                            )

                                                        })
                                                    }

                                                    {/* <div className={classes.divWdths}>
                                                <Typography>10:00 am</Typography>
                                                <Typography className={classes.time}>04:00 pm</Typography>
                                            </div> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Card>
                            </div>
                        </>
                    }
                </Accordion>
            </Card>
        </div>
    )
}

export default Schedule
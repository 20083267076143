import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useParams } from 'react-router-dom';
import Greeting from './OpenHourGreeting/OpenHoursGreetingNew'
import '.././openhours.css'
import { useStyles } from './styles'
import callhandlingimg from '../../../assets/images/Vector.png'
import agents from '../../../assets/images/agents.png'
import useraccount from '../../../assets/images/useraccount.png'
import forwardnumber from '../../../assets/images/forwardnum.png'
import voicemail from '../../../assets/images/voicemail.png'
import recnum from '../../../assets/images/recmsg.png'
import namedir from '../../../assets/images/namedirector.png'
import OpenHourIVR from './OpenHourAutomatedMenu/OpenHourIVR'
import OpenHoursAgents from './OpenHoursAgents/OpenHoursAgents'
import OpenHoursDepartmentsNew from './OpenHoursDepartments/OpenHoursDepartmentsNew'
import ForwardNumber from './OpenHoursPhoneNumbers/ForwardNumber'
import OpenhoursVoicemailNew from './OpenHoursVoicemail/OpenhoursVoicemailNew'
import OpenhoursTeamMembersNew from './OpenHoursTeammembers/RecordedMessages'

import DepartmentServices from '../DepartmentServices/DepartmentServices'
import { useSelector } from 'react-redux'

const openhoursOptions = [{ key: 1, value: "Automated Menu" }, { key: 2, value: "agents" },
{ key: 3, value: "departments" },
{ key: 5, value: "forwardNumber" }, { key: 6, value: "voicemail" }, { key: 7, value: "recmessages" }, { key: 8, value: "nameDirector" }]



function Openhours(props: any) {
    const departmentStateData = useSelector((state: any) => state.departmentModule);

    const classes = useStyles()
    const { selectedDepartment, loading } = departmentStateData;
    const [openHoursGreeting, setopenHoursGreeting] = React.useState<any>({})
    const [openHoursGrData, setopenHoursGrData] = React.useState<any>({})
    const [IVRGreeting, setIVRGreeting] = React.useState<any>()
    const [callHandlingData, setCallHandlingData] = React.useState<any>()
    const [ivrVoiceData, setivrVoiceData] = React.useState<any>('')
    const [ivrVoiceName, setivrVoiceName] = React.useState<any>('')
    const [tabValue, setTabValue] = React.useState<any>('')
    const [ivrOptions, setIvrOptions] = React.useState<any>([])
    const [UdsVoiceData, setUdsVoiceData] = React.useState<any>('')
    const [UdsVoiceName, setUdsVoiceName] = React.useState<any>('')
    const [udsDept, setUdsDept] = React.useState<any>([])
    const [udsTeamMembers, setudsTeamMembers] = React.useState<any>([])
    const [selectedDeptId, setSelectedDeptId] = React.useState<any>('')
    const [selectedteamMemberId, setSelectedteamMemberId] = React.useState<any>('')
    const [phoneVoiceData, setphoneVoiceData] = React.useState<any>('')
    const [phoneVoiceName, setphoneVoiceName] = React.useState<any>('')
    const [selectedVoiceData, setSelectedVoiceData] = React.useState<any>('')
    const [selectedVoiceDataname, setSelectedVoiceDataname] = React.useState<any>('')
    const [selectedRecordedmsgData, setSelectedRecordedmsgData] = React.useState<any>('')
    const [selectedRecordedmsgname, setSelectedRecordedmsgname] = React.useState<any>('')
    const [holdMusicPath, setHoldMusicPath] = React.useState<any>('')
    const [holdMusicFileName, setHoldMusicFileName] = React.useState<any>('')
    const [holdGreetingPath, setHoldGreetingPath] = React.useState<any>('')
    const [holdGreetingFileName, setHoldGreetingFileName] = React.useState<any>('')
    const [interrptPath, setInterrptPath] = React.useState<any>('')
    const [interrptFileName, setInterrptFileName] = React.useState<any>('')

    const [open, setOpen] = React.useState(true);
    const [tabname, setTabName] = React.useState<any>('');
    const [openHoursData, setOpenHoursData] = React.useState<any>({})

    const [departmentId, setDepartmentId] = React.useState<any>()
    const [selectedOption, setselectedOption] = React.useState<any>({
        currentSelectedOption: null
    })
    const [agentsData, setagentsData] = React.useState<any>([])
    const [operatorsData, setoperatorsData] = React.useState<any>([])

    const expandOpen = () => {
        setOpen(!open);
    }


    const handleTabChange = (tabname: any) => {
        const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
        if (tabname === 'Automated Menu') {
            var myData: any = selectedOption
            myData.currentSelectedOption = 1
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'agents') {
            var myData = selectedOption
            myData.currentSelectedOption = 2
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'departments') {
            var myData = selectedOption
            myData.currentSelectedOption = 3
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'forwardNumber') {
            var myData = selectedOption
            myData.currentSelectedOption = 5
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'voicemail') {
            var myData = selectedOption
            myData.currentSelectedOption = 6
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'recmessages') {
            var myData = selectedOption
            myData.currentSelectedOption = 7
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        if (tabname === 'nameDirector') {
            var myData = selectedOption
            myData.currentSelectedOption = 8
            setselectedOption(myData)
            DepartmentServices.updateOpenHours(props.match.params.id, selectedOption).then((res: any) => {
                //send success message
                getOpenHours()
            }).catch((err: any) => {
                //send error message
            })
        }
        setTabName(tabname)
    }
    const getOpenHours = () => {
        DepartmentServices.getOpenHours(props.match.params.id).then((res: any) => {
            console.log('openres__', res.data)
            if (res.success) {
                setCallHandlingData(res.data)
                const openHrGreets = res.data.openHrGreetingFiles
                setopenHoursGrData(openHrGreets)
                const selectedOpHFile = openHrGreets.find((item: any) => {
                    if (item.isSelected === true) {
                        return item
                    }
                })
                // console.log(selectedOpHFile)
                setopenHoursGreeting(selectedOpHFile)
                setselectedOption((prev: any) => {
                    return {
                        ...prev,
                        currentSelectedOption: res.data.currentSelectedOption
                    }
                })
                if (res.data.currentSelectedOption === 1) {
                    setIvrOptions(res.data.ivrOptions)
                    const data = res.data.ivrGreeting
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setivrVoiceData(item.path)
                            setivrVoiceName(item.fileName)
                            setTabValue(item.fileType)
                        }
                    })
                }
                if (res.data.currentSelectedOption === 2) {
                    const data = res.data.HoldMusicGreeting
                    const holdGreetingData = res.data.HoldGreeting
                    const interruptData = res.data.InterruptHoldMusicGreeting
                    setagentsData(res.data.Agents)
                    setoperatorsData(res.data.Operators)
                    interruptData.map((item: any) => {
                        if (item.isSelected === true) {
                            setInterrptPath(item.path)
                            setInterrptFileName(item.fileName)
                        }
                    })
                    holdGreetingData.map((item: any) => {
                        if (item.isSelected === true) {
                            setHoldGreetingPath(item.path)
                            setHoldGreetingFileName(item.fileName)
                        }
                    })
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setHoldMusicPath(item.path)
                            setHoldMusicFileName(item.fileName)
                        }
                    })
                }

                if (res.data.currentSelectedOption === 2) {
                    const data = res.data.HoldMusicGreeting
                    const holdGreetingData = res.data.HoldGreeting
                    const interruptData = res.data.InterruptHoldMusicGreeting
                    interruptData.map((item: any) => {
                        if (item.isSelected === true) {
                            setInterrptPath(item.path)
                            setInterrptFileName(item.fileName)
                        }
                    })
                    holdGreetingData.map((item: any) => {
                        if (item.isSelected === true) {
                            setHoldGreetingPath(item.path)
                            setHoldGreetingFileName(item.fileName)
                        }
                    })
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setHoldMusicPath(item.path)
                            setHoldMusicFileName(item.fileName)
                        }
                    })
                }

                if (res.data.currentSelectedOption === 3) {
                    const data = res.data.transferingAudio
                    setUdsDept(res.data.Departments)
                    setudsTeamMembers(res.data.TeamMembers)
                    setSelectedDeptId(res.data.departmentId)
                    setSelectedteamMemberId(res.data.teamMemberId)
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setUdsVoiceData(item.path)
                            setUdsVoiceName(item.fileName)
                        }
                    })
                }
                if (res.data.currentSelectedOption === 5) {
                    // setVoicemaildata(res.data.voicemail.voicemailAudio)
                    const data = res.data.ForwardNumberTransferringAudio
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setphoneVoiceData(item.path)
                            setphoneVoiceName(item.fileName)
                        }
                    })
                }
                if (res.data.currentSelectedOption === 6) {
                    const voiceData = res.data
                    console.log('voiceData',voiceData);
                    voiceData.map((item: any) => {
                        if (item.isSelected === true) {
                            setSelectedVoiceData(item.path)
                            setSelectedVoiceDataname(item.fileName)
                        }
                    })
                }
                if (res.data.currentSelectedOption === 7) {
                    const data = res.data.recording
                    console.log('dataggg', data)
                    data.map((item: any) => {
                        if (item.isSelected === true) {
                            setSelectedRecordedmsgData(item.path)
                            setSelectedRecordedmsgname(item.fileName)
                        }
                    })
                }
                // setOpenHoursData(res.data)
            }
        }).catch((err: any) => {

        })
    }


    const fetchSelectedOption = async () => {
        await DepartmentServices.getOpenHours(props.match.params.id).then((res: any) => {
            if (res.success) {
                openhoursOptions.forEach((option: any) => {
                    if (option?.key === res.data?.currentSelectedOption) {
                        setTabName(option?.value)
                    }
                })
                getOpenHours()
            }
        })
    }


    React.useEffect(() => {
        fetchSelectedOption()
    }, [])



    //   console.log("currentSelectedOption",selectedOption.currentSelectedOption)
    // console.log("callHandlingData",callHandlingData)


    // const id = useParams()
    // const paramObj: any = id

    // console.log(paramObj.id)



    return (
        <div className='openhours_MainSection'>
            <Card className={classes.card}>
                <Accordion
                    expanded={open}
                    onChange={expandOpen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={{ fontFamily: 'Calibri !important', fontStyle: 'normal', fontWeight: 700, fontSize: "20px", color: "#4D72F8", marginLeft: "30px" }}>Open Hours </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <Greeting title="Open Hours Greeting" actionline="Set rules for open hour greetings"
                            filename={openHoursGreeting?.fileName} path={openHoursGreeting?.path} depId={props.match.params.id}
                            openHours={getOpenHours} openHoursGrData={openHoursGrData}
                        />
                    </AccordionDetails>
                    <Divider style={{ marginTop: '50px' }} />
                    <AccordionSummary>
                        <Typography className={classes.callhandling}>Call Handling</Typography>
                    </AccordionSummary>
                    {/* <Divider className={classes.divider} /> */}
                    <CardContent>
                        <div className={classes.gridJustify}>
                            <Card
                                className={tabname === 'Automated Menu' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('Automated Menu')}>
                                <CardContent className={classes.align}>
                                    {/* <CgAttribution
                                        className={
                                            tabname === "Automated Menu"
                                                ? classes.deviceWidth1
                                                : classes.deviceWidth
                                        } /> */}
                                    <div className={tabname === 'Automated Menu' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={callhandlingimg} alt='callhandling' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography className={tabname === 'Automated Menu' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Automated Menu
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card
                                className={tabname === 'agents' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('agents')}>
                                <CardContent className={classes.align}>
                                    {/* <RiCustomerService2Line
                                        className={
                                            tabname === "agents"
                                                ? classes.deviceWidth1
                                                : classes.deviceWidth
                                        } /> */}
                                    <div className={tabname === 'agents' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={agents} alt='agents' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography className={tabname === 'agents' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Agents
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card
                                className={tabname === 'departments' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('departments')}
                            >
                                <CardContent className={classes.align}>
                                    {/* <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} /> */}
                                    {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}
                                    <div className={tabname === 'departments' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={useraccount} alt='departments' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography
                                        className={tabname === 'departments' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Users & Dept
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card
                                className={tabname === 'forwardNumber' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('forwardNumber')}>
                                <CardContent className={classes.align}>
                                    <div className={tabname === 'forwardNumber' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={forwardnumber} alt='forwardNumber' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography className={tabname === 'forwardNumber' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Forward Number
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card
                                className={tabname === 'voicemail' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('voicemail')}>
                                <CardContent className={classes.align}>
                                    <div className={tabname === 'voicemail' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={voicemail} alt='voicemail' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography className={tabname === 'voicemail' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Voice Mail
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card
                                className={tabname === 'recmessages' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('recmessages')}>
                                <CardContent className={classes.align}>
                                    <div className={tabname === 'recmessages' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={recnum} alt='recmessages' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography
                                        className={tabname === 'recmessages' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Recorded Msg
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card
                                className={tabname === 'nameDirector' ? classes.callhandlingcardActive : classes.callhandlingcard}
                                onClick={() => handleTabChange('nameDirector')}>
                                <CardContent className={classes.align}>
                                    <div className={tabname === 'nameDirector' ? classes.imgcontainerActive : classes.imgcontainerinActive}>
                                        <img src={namedir} alt='nameDirector' color='#828282' width='15px' height='15px' />
                                    </div>
                                    <Typography
                                        className={tabname === 'nameDirector' ? classes.carddevicesText : classes.carddevicesText1}>
                                        Name Director
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </CardContent>

                    {tabname === 'Automated Menu' && (
                        <>
                            <OpenHourIVR
                                callHandlingOption={1}
                                depId={props.match.params.id}
                                ivrVoiceData={ivrVoiceData}
                                ivrVoiceName={ivrVoiceName}
                                callHandlingData={callHandlingData}
                                getOpenHours={getOpenHours}
                                tabValue={tabValue}
                            />
                        </>
                    )}
                    {tabname === 'agents' && (
                        <>
                            <OpenHoursAgents callHandlingOption={2}
                                openHours={callHandlingData}
                                getOpenHours={getOpenHours}
                                depId={props.match.params.id}
                                holdMusicPath={holdMusicPath}
                                holdMusicFileName={holdMusicFileName}
                                holdGreetingPath={holdGreetingPath}
                                holdGreetingFileName={holdGreetingFileName}
                                interrptPath={interrptPath}
                                interrptFileName={interrptFileName}
                                agentsData={agentsData}
                                operatorsData={operatorsData}
                            />
                        </>
                    )}
                    {tabname === 'departments' && (
                        <>
                            <OpenHoursDepartmentsNew callHandlingOption={3}
                                depId={props.match.params.id}
                                UdsVoiceData={UdsVoiceData}
                                UdsVoiceName={UdsVoiceName}
                                udsDept={udsDept}
                                teamMembers={udsTeamMembers}
                                getOpenHours={getOpenHours}
                                callHandlingData={callHandlingData}
                                selectedDepId={selectedDeptId}
                                selectedteamMemberId={selectedteamMemberId}
                            />
                        </>
                    )}
                    {tabname === 'forwardNumber' && (
                        <>
                            <ForwardNumber callHandlingOption={5}
                                depId={props.match.params.id}
                                phoneVoiceData={phoneVoiceData}
                                phoneVoiceName={phoneVoiceName}
                                getOpenHours={getOpenHours}
                                callHandlingData={callHandlingData}
                            />
                        </>
                    )}

                    {tabname === 'voicemail' && (
                        <>
                            <OpenhoursVoicemailNew callHandlingOption={6}
                                // Data={voicemailData}
                                getOpenHours={getOpenHours}
                                depId={props.match.params.id}
                                selectedVoiceDataname={selectedVoiceDataname}
                                selectedVoiceData={selectedVoiceData}
                                callHandlingData={callHandlingData}
                            />
                        </>
                    )}
                    {tabname === 'recmessages' && (
                        <>
                            <OpenhoursTeamMembersNew callHandlingOption={7}
                                selectedRecordedmsgData={selectedRecordedmsgData}
                                selectedRecordedmsgname={selectedRecordedmsgname}
                                depId={props.match.params.id}
                                callHandlingData={callHandlingData}
                                getOpenHours={getOpenHours}
                            />
                        </>
                    )}
                    {tabname === 'nameDirector' && (
                        <>

                        </>
                    )}
                </Accordion>
            </Card>
        </div>
    )
}

export default Openhours
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "4px 12px",
    },
    gridItem: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    textDanger: {
        color: "red"
    },
    connectedbutton: {
        borderRadius: '50px',
        width: '167px',
        height: '34px',
        marginRight:5,
        backgroundColor: '#F6F6F6',
        textTransform: 'none',
        fontSize: '16px',
        color: '#00A707',
        fontFamily: 'Work Sans',
        [theme.breakpoints.down('sm')]: {
            // width: '35px',
            height: '35px',
            // marginLeft: '10px',
            fontSize: '15px',
        }
    },
    Notconnectedbutton: {
        borderRadius: '50px',
        width: '167px',
        height: '34px',
        marginRight:5,
        fontFamily: 'Work Sans',
        backgroundColor: '#F6F6F6',
        textTransform: 'none',
        fontSize: '13px',
        color: 'red',
        [theme.breakpoints.down('sm')]: {
            // width: '35px',
            height: '35px',
            // marginLeft: '10px',
            fontSize: '15px',
        }
    },
    warningbutton: {
        borderRadius: '50px',
        width: '167px',
        height: '34px',
        marginRight:5,
        backgroundColor: '#F6F6F6',
        textTransform: 'none',
        fontFamily: 'Work Sans',
        fontSize: '16px',
        color: 'yellow',
        [theme.breakpoints.down('sm')]: {
            // width: '35px',
            height: '35px',
            // marginLeft: '10px',
            fontSize: '15px',
        }
    },
    cardContent1: {
        display: "flex",
        alignItems: "center",
    },
    profile: {
        width: 150,
        height: 150,
    },
    NameText1: {
        color: "#6B778C",
        fontSize: 18,
        fontWeight: 500,
        fontFamily: "Work Sans",
        letterSpacing: 0.1,
        textTransform: 'none',
        marginTop: "5px"
    },
    NameText: {
        color: "#6B778C",
        fontSize: 18,
        fontWeight: 500,
        fontFamily: "Work Sans",
        letterSpacing: 0.1,
        textTransform: 'none',
        marginTop: "39px"
    },
    Textfield: {
        marginTop: "22px"
    },
    head1: {
        fontSize: "24px",
        color: "#565656",
        fontWeight: 500,
    },
    headSmall: {
        fontSize: "14px",
        margin: 5,
        fontWeight: 600,
        color: "#565656",

    },
    align: {
        textAlign: "center",
    },
    dFlex: {
        display: "flex", justifyContent: "center", alignItems: "center"
    },

    gmail: {
        fontSize: "14px",
        margin: 5,
        fontWeight: 600,
        color: "rgb(177 177 177)",
    },
    userEdit: {
        width: 25,
        marginLeft: 20,
    },
    button: {
        margin: 10,
        fontSize: 14,
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        letterSpacing: 0.208955,
        textTransform: "none",
        textAlign: "center",
        borderRadius: 25,
        padding: "4px 20px",
        color: "#4D72F8",
        backgroundColor: "#FFFFFF",
        borderColor: "#4D72F8",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
        "&:active": {
            backgroundColor: "#FFFFFF",
        },
    },
    button1: {
        margin: 10,
        fontSize: 14,
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        letterSpacing: 0.208955,
        textTransform: "none",
        textAlign: "center",
        borderRadius: 25,
        padding: "4px 20px",
        color: "#E91C1C",
        backgroundColor: "#FFFFFF",
        borderColor: "#E91C1C",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
        "&:active": {
            backgroundColor: "#FFFFFF",
        },
    },
    grid: {
        width: "100%",
    },

    textfield: {
        marginTop: 15,
        color: '#9A9A9A',
        fontFamily: "Work Sans",
        fontStyle: "normal",
    },
    SaveButton: {
        background: '#4D72F8',
        border: '1px solid #ccc',
        borderRadius: 5,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 15,
        padding: '6px 22px',
        "&:hover": {
            color: "#FFFFFF",
            background: "#4D72F8",
        },
    },

    submitbutton: {

        margin: 15,
        width: "150px",
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        border: "1px solid #4D72F8",
        textAlign: "center",
        borderRadius: 5,
        color: "#fff",
        background: "#4D72F8",
        "&:hover": {
            backgroundColor: "#4D72F1",
            color: "black"
        },

    },
    cancelButton: {
        margin: 10,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        marginLeft: 15,
        background: '#fbfbfb',
        border: '1px solid #ebebeb',
        borderRadius: 5,
        color: '#8392AB',
        fontSize: 15,
        padding: '6px 22px'
    },
    buttons: {
        marginLeft: "18px",
    },
    passwordcancelButton: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        textTransform: "capitalize",
        fontSize: 14,
        color: "#8392AB",
        // width: "40%",
        borderRadius: 5,
        marginLeft: 30,
        padding: "6px 20px",
        marginBottom: 20,
    },
    passwordSaveButton: {
        border: "0.898113px solid #3A416F",
        borderRadius: 5,
        background: "#3A416F",
        color: " white",
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "bold",
        marginBottom: 20,
        textTransform: "capitalize",
        fontSize: 14,
        padding: "6px 20px",
        // width: "40%",
        "&:hover": {
            background: "#3A416F",
            color: "white",
        },
    },


    cardColor: {
        color: "#2645ad",
        fontFamily: "work Sans",
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 600,
    },

    divider: {
        backgroundColor: "#E2E2E2",
    },
    logoutbutton: {
        backgroundColor: "#4D72F8",
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 4,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        padding: '6px 22px',
        color: '#FFFFFF',
        fontSize: 14,
    },
    mainHeader: {
        color: '#2546AE',
        //fontWeight: 'bold',
        fontFamily: 'work sans',
        fontWeight: 500,
        fontSize: 18,
        textAlign: 'center'
    },

    editIcon: {
        color: "#4D72F8",
        marginLeft: 5,
    },
    cancelLogButton: {
        marginLeft: 20,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        backgroundColor: '#EFEFEF',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 4,
        color: '#787878',
        fontSize: 14,
    },
    
}));

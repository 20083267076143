import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({

  marginBottom20: {
    marginBottom: '20px',
},
  divider: {
    marginTop: 10,
    backgroundColor: '#E2E2E2',
},
  usersorgroups:{
    fontSize:19,
    fontWeight:800
  },
  userText: {
    fontSize: 18,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#2645ad',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  width: {
    width: '100%',
  },
  button: {
    border: '1px solid lightgrey',
    borderRadius: '50px',
    boxSizing: 'border-box',
    padding: '6px 22px',
  },
  card: {
    margin: '0px 0px 10px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    // overflow: 'unset',
    // width:'180%',
    // marginLeft:-250
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  select: {
    // width: '100%',
  },
  gridItem: {
    // display: 'flex',
    width: '100%',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  barCard: {
    padding: 5,
    border: '0px',
    borderRadius: '10px',
    boxShadow: '0 0 0.5em #E2E2E2',
    background: '#FFFFFF',
  },
  areaText: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: '0.1px',
    textTransform: 'none',
  },
  outgoingText: {
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
    margin: '0px 20px 0px 10px',
  },
  incomingText: {
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
    margin: '0px 20px 0px 10px',
  },
  CardStyles: {
    marginTop: 30,
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    // padding: 20,
  },
  tRow: {
    background: '#F1F1F1',
    borderRadius: 6
  },
  tCell: {
    color: '#464646',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Work Sans'
  },
  modalLdr: {
    display: 'flex', margin: "80px", marginLeft: "520px",
    "@media (max-width: 768px)": {
      marginLeft: 350
    }
  },
  tbody: {
    color: '#787878',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Work Sans'
  },
  header: {
    color: '#2645ad !important',
    fontSize:18,
    fontWeight:600,
    padding:20
  },
  leaderBoards:{
    color:"#2645ad",
    fontWeight:600,
    fontSize:18
  }

}));

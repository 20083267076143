import React from "react";

import {
    Card,
    Grid,
    Button,
    CardContent,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Table,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Box,
    CircularProgress,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { AiOutlinePlus } from 'react-icons/ai'
import { HiPlay } from 'react-icons/hi'
import DepartmentServices from '../DepartmentServices/DepartmentServices'
import TableBodyData from "./TableBodyData";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function CallHistory(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true);
    const [dataDate, setDataDate] = React.useState<any>({
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 59),
    })
    const dateSelected = new Date();
    const getYear = dateSelected.getFullYear()
    const getMonth = dateSelected.getMonth()
    const getDate = dateSelected.getDate()
    const [loading, setLoading] = React.useState(false);
    const [serachData, setSearchData] = React.useState([]);
    const [callHistoryData, setCallHistoryData] = React.useState<any>([])
    const startDateTime = new Date(Date.UTC(getYear, getMonth, getDate, 0, 0, 0))
    // const endDate = new Date(Date.UTC(getYear, getMonth, getDate, 23, 59, 59));
    const [state, setState] = React.useState({
        // page: 1,
        // totalCount: 0,
        // limit: 10
        page: 1,
        totalDocs: 0,
        limit: 50,
        totalPages: 0
    })
    const expandOpen = () => {
        setOpen(!open);
    }


    const getCallHistoryData = (page: any, limit: any) => {
        setLoading(true);
        DepartmentServices.getCallHistoryList(props.match.params.id, page, limit).then((res: any) => {
            if (res.success) {
                setLoading(false);
                setCallHistoryData(res?.data?.data);
                setState((prev) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit,
                        totalPages: res.data.totalPages
                    }
                })
            } else {
                setLoading(false);
            }


        }).catch((err) => {
            setLoading(false);
        })
    }


    const searchDateData = () => {
        if (dataDate.startDate && dataDate.endDate) {
            setLoading(true);
            DepartmentServices.getCallHistorySearchList(props.match.params.id, state.limit, dataDate.startDate, dataDate.endDate).then((res: any) => {
                if (res.success) {
                    setLoading(false);
                    setCallHistoryData(res?.data?.data);
                    setState((prev) => {
                        return {
                            ...prev,
                            page: res.data.page,
                            totalDocs: res.data.totalDocs,
                            limit: res.data.limit,
                            totalPages: res.data.totalPages
                        }
                    })

                } else {
                    setLoading(false);
                }


            }).catch((err) => {
                setLoading(false);
            })
        }
        else {
            getCallHistoryData(state.page, state.limit);
        }
    }

    const handleChangePage = (event: any, value: any) => {
        setState((prev) => {
            return {
                ...prev,
                page: value
            }
        });
    }

    const handleClear = () => {
        setDataDate({
            startDate: Date.parse(startDateTime.toISOString()),
            endDate: Date.parse(startDateTime.toISOString()),

        })
        getCallHistoryData(state.page, state.limit);
    }

    React.useEffect(() => {
        getCallHistoryData(state.page, state.limit);
    }, [])

    React.useEffect(() => {
        getCallHistoryData(state.page, state.limit);
    }, [state.page])

    const onChangeStartDate = (e: any) => {
        var date = e.target.value
        var datea = Date.parse(date);
        const dateSelected = new Date(date).setHours(0, 0, 0, 0);

        setDataDate((prev: any) => {
            return {
                ...dataDate,
                startDate: dateSelected,
            }
        })
    }

    const onChangeEndDate = (e: any) => {
        var date = e.target.value
        var datea = Date.parse(date);
        const dateSelected = new Date(date).setHours(23, 59, 59, 59);

        setDataDate((prev: any) => {
            return {
                ...dataDate,
                endDate: dateSelected,

            }
        })
    }

    const displayDates = (dateString: any) => {
        const dateNow = new Date(dateString);
        const year = dateNow.getFullYear();
        const monthWithOffset = dateNow.getMonth() + 1;
        const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
        const date = dateNow.getDate().toString().length < 2 ? `0${dateNow.getDate()}` : dateNow.getDate();
        const materialDateInput = `${year}-${month}-${date}`;
        return materialDateInput
    }

    const dateNow = new Date();
    const year = dateNow.getFullYear();
    const monthWithOffset = dateNow.getUTCMonth() + 1;
    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
    const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
    const materialDateInput = `${year}-${month}-${date}`;

    return (
        <div>
            <div id='Advanced Setting'>
                <Card className={classes.card}>
                    <Accordion expanded={open} onChange={expandOpen}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {/* <CardHeader name={'Advanced Setting'} /> */}
                            <Typography className={classes.heading}>
                                Call History
                            </Typography>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <AccordionDetails className={classes.accordianNoFlex}>

                            <CardContent style={{ marginTop: 20 }}>
                                <div className={classes.divWdth}>
                                    <Grid container spacing={3}>
                                        <Grid item md={5} xs={12} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                type="date"
                                                fullWidth
                                                placeholder="Select start date"
                                                onChange={onChangeStartDate}
                                                value={displayDates(dataDate.startDate)}
                                            />
                                        </Grid>
                                        <Grid item md={5} xs={12} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                type="date"
                                                fullWidth
                                                placeholder="Select start date"
                                                onChange={onChangeEndDate}
                                                value={displayDates(dataDate.endDate)}
                                            />
                                        </Grid>
                                        <Grid item md={2} sm={4}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                onClick={searchDateData}
                                                className={classes.searchBtn}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>

                            <CardContent>
                                <Card className='marginTable' style={{ marginTop: '-10px' }}>
                                    <Table style={{ border: '1px solid rgba(194, 194, 194, 0.47)', background: '#FFFFFF', borderRadius: '15px' }}>
                                        <TableHead className={classes.tablecolor}>
                                            <TableRow>
                                                <TableCell className={classes.tableHead}>
                                                    ID
                                                </TableCell>
                                                <TableCell className={classes.tableHead}>
                                                    Agent
                                                </TableCell>
                                                <TableCell className={classes.tableHead}>
                                                    Department  Number
                                                </TableCell>
                                                <TableCell className={classes.tableHead}>
                                                    Caller Number
                                                </TableCell>

                                                <TableCell className={classes.tableHead}>
                                                    Time
                                                </TableCell>
                                                {/* <TableCell className={classes.tableHead}>
                                                    Answer Type
                                                </TableCell> */}
                                                <TableCell className={classes.tableHead}>
                                                    Duration
                                                </TableCell>
                                                <TableCell className={classes.tableHead}>Recording</TableCell>
                                                <TableCell className={classes.tableHead}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {callHistoryData.map((row: any, i: any) => (
                                                <TableBodyData
                                                    //getCallHistoryData={getCallHistoryData}
                                                    row={row}
                                                    callHistoryData={callHistoryData}
                                                    key={i}
                                                    state={state}
                                                //handleChangePage={handleChangePage}

                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {loading ?
                                        <Box>
                                            <div style={{ display: 'grid', alignItems: 'center', margin: '30px 500px' }}>
                                                <CircularProgress />
                                            </div>
                                        </Box>
                                        :
                                        callHistoryData.length <= 0 && <p style={{ textAlign: 'center' }}>No Number found</p>
                                    }
                                </Card>
                                <Stack spacing={2} style={{ marginTop: 15, float: "right" }}>
                                    <Pagination
                                        // count={Math.floor(state.totalDocs / state.limit)}
                                        // page={state.page}
                                        count={state.totalPages}
                                        page={state?.page}
                                        onChange={handleChangePage}
                                        variant="outlined"
                                        color="secondary"
                                    />
                                </Stack>
                            </CardContent>
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </div>
        </div>
    )
}

export default CallHistory
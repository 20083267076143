import {
    Card,
    CardContent,
    Divider,
    TextField,
    Typography,
    Accordion,
    AccordionSummary, AccordionDetails,
    Grid,
} from '@material-ui/core';
import React from 'react'
import { useStyles } from '../styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Form from './Form';

import { useHistory } from "react-router-dom";
import BusinessServices from "../BusinessAccountsServices/services"


function EditBusinessAccount(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const [updatedData, setUpdatedData] = React.useState<any>({});
    const [editId, setEditId] = React.useState<any>('')
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => {
        setOpen(!open)
    }
    const getAccountData = (id: any) => {
        BusinessServices.getBusinessAccount(id).then((res) => {
            setUpdatedData(res.data.data)
        })
    }
    React.useEffect(() => {
        getAccountData(props.match.params.id)
        setEditId(props.match.params.id)
    }, [props.match.params.id])


    return (
        <div>
            <Card className={classes.card}>
                <Accordion expanded={open}
                    onChange={handleOpen} style={{ padding: "5px" }}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Business Account-Update
                        </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <Form data={updatedData} id={editId} />
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    )
}
export default EditBusinessAccount
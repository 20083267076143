import { Avatar, Button, Grid, Typography, TextField, IconButton } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import { FiPhoneCall } from 'react-icons/fi'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { MdCancelPresentation } from 'react-icons/md'
import Rectangle from '../../../assets/images/Rectangle.png';
import Ellipse from '../../../../assets/icons/Ellipse.png';
import { BiCaretDown } from 'react-icons/bi'
import useSound from 'use-sound';
//import { Window, WindowActionsEvent } from "@progress/kendo-react-dialogs";
//import OutgoingCall from './CallsModal/OutgoingCall'
import axiosInstance from '../../../environment/axiosInstance'
import Select from 'react-select'
import { StylesConfig } from 'react-select'
import ReactSelect from 'react-select'
import profile from "../../../assets/images/avtar.jpg";
import * as authActionCreators from '../../../store/Actions/AuthActionCreator'
import { useDispatch, useSelector } from "react-redux";
//import * as CallActionCreator from '../../../../Store/Actions/CallActionCreator'
import * as CallActionCreator from '../../../store/Actions/CallActionCreator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function DialPad(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [number, setNumber] = React.useState<any>('')
    const [cusorposition, setCursorPosition] = React.useState<any>(null)
    const [option, setOption] = React.useState<any>(null)
    const callingData: any = useSelector((state: any) => state.CallingData);
    const [submitted, setSubmitted] = React.useState(false);
    const [outboundData, setOutBoundData] = React.useState<any>([])
    const [outboundVal, setOutboundVal] = React.useState<any>({})
    const [call, setCall] = React.useState<any>(false)
    const [windowStage, setWindowStage] = React.useState("MAXIMISED");
    const soundUrl = './dialPad.mp3';
    const pokemon = require('../../../assets/sounds/diaal.mp3');
    const audio = new Audio(pokemon)
    // audio.loop = true;
    const [playback, setPlayBack] = React.useState(0.75)
    const [play, { stop }] = useSound(soundUrl, { playback, volume: 1.5 });

    // const customStyles: StylesConfig = {
    //     control: (provided: Record<string, unknown>, state: any) => ({
    //         ...provided,
    //         // height: 52,
    //         marginLeft: "18px",
    //         // border: "none",
    //         // boxShadow: "none",
    //         // "&:hover": {
    //         //     border: "1px solid #ff8b67",
    //         //     boxShadow: "0px 0px 6px #ff8b67"
    //         // }
    //     })
    // };
    const handleNumber = (num: any) => {
        // audio.load()
        // audio.muted = false;
        var promise = audio.play();
        if (promise) {
            //Older browsers may not return a promise, according to the MDN website
            promise.catch(function (error) { console.error(error); });
        }

        // if (cusorposition !== null) {
        //     const newNumber: any = number.split('')
        //     newNumber.splice(cusorposition, 0, num.toString())
        //     const newNum = newNumber.join('')
        //     setNumber(newNum)


        // }
        // const newNumber: any = number.split('')
        // newNumber.splice(cusorposition, 0, num.toString())

        // setNumber(newNumber.reverse().join(''))
        // else if (cusorposition == null) {

        const addNumber = number.concat(num)
        setNumber(addNumber)
        // }

    }

    const handleEnterNumber = (e: any) => {
        const val = e.target.value.replace(/[^0-9+]+/g, '');
        setNumber(val)

    }
    const removeNum = () => {
        const index = number.length - 1
        const newNum = number.slice(0, -1);
        setNumber(newNum)
    }
    const handleCallModal = () => {
        setCall(!call)
    }
    const handleStageChange = (e: any) => {
        setWindowStage(e.state);

    };
    const callingFunction = () => {
        props.modalClose(true)
        // handleCallModal()      
        setCall((current: any) => !current)

        props.parentCall({
            phoneNumber: "+" + number,
            name: "+" + number
        })
        if (callingData?.callData && typeof(callingData?.callData?.phoneNumber) !== 'undefined' && callingData?.callData?.phoneNumber !== "") {
            console.log('callingData321000', callingData?.callData)
            toast.error("Already you are in call", { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 })
        }

        else {
            const oNumber = outboundVal.value ? outboundVal.value : ""
            const oName = outboundVal.name ? outboundVal.name : ""
            var Options: any = props?.callOptions
            Options.extraHeaders = [`oNumber:${oNumber}`, `oName: ${oName} `]

            console.log('Options', Options)
            setOption(Options)
            const data = {
                phoneNumber: "+" + number,
                name: "+" + number,
                options: Options
            }
            dispatch(CallActionCreator.fetchStatus(data))
            dispatch(CallActionCreator.fetchData(true))
            setCursorPosition(null)

        }
    }

    // console.log('dtaaa',callingData?.callData)

    const phoneNumber = {
        phoneNumber: number
    }
    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            phoneNumber: { ...validData },
        };
        let isValid = true;
        if (submitted) {
            if (number === "") {
                retData.phoneNumber = {
                    isValid: false,
                    message: "Please enter phoneNumber"
                };
                isValid = false;
            }
            if (number.length > 15) {
                retData.phoneNumber = {
                    isValid: false,
                    message: "Please enter valid phoneNumber"
                };
                isValid = false;
            }


        }
        return {
            ...retData,
            isValid
        };
    };
    const errors = validate(submitted);
    const getOutboundCallers = () => {
        axiosInstance.get('/phoneNumbers/outbound-caller-id').then((res: any) => {
            if (res.status === 200) {
                const data = res.data.data
                setOutBoundData(data)
                setOutboundVal({
                    "value": data[0].id,
                    "label": data[0].value,
                    "name": data[0].name
                })
            }
        }).catch((err: any) => {
            if (err.response?.status == 402) {
                // window.location.href = "http://bp.efone.ca/"
                // sessionStorage.removeItem("user")

            }
            if (err.response?.status == 401) {
                dispatch(authActionCreators.initRefreshToken())
            }
        })
    }
    React.useEffect(() => {
        getOutboundCallers()
    }, [])
    var OutboundCallersList: any = [];
    outboundData?.map((item: any) => {
        var object = {
            "value": item?.id,
            "label": item?.value + "  (" + item?.name + ")",
            "name": item?.name
        }
        OutboundCallersList.push(object)
    })

    const handleChangeOutbound = (e: any) => {
        setOutboundVal(e)
    }


    // const [staticData,setStaticData] = React.useState({})
    // const options : any =[ 
    //     {value:'+1647560023',lebel:'Andrew'},
    // ]


    return (
        <div style={{ padding: '20px 80px' }}>
            {/* {
                call && (
                    <div style={{ padding: 0 }}>
                        <Window
                            title={""}
                            stage={windowStage}
                            onStageChange={handleStageChange}
                        >
                            <OutgoingCall callModal={handleCallModal}
                                data={phoneNumber} coolPhone={props.coolPhone}
                                callOptions={option}

                            />
                        </Window>
                    </div>

                )
            } */}
            <Grid container spacing={2}>
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                    <Typography className={classes.newcall}>New Call from</Typography>
                    <div className={classes.profile}>
                        <img src={profile} alt="user" style={{ width: 35, height: 35 }} />
                        <div style={{ width: 250 }}>
                            <ReactSelect
                                //styles={customStyles}
                                value={OutboundCallersList.filter((opt: any) => {
                                    return opt.value === outboundVal?.value
                                })}
                                onChange={(e: any) => handleChangeOutbound(e)}
                                options={OutboundCallersList}
                            />
                        </div>
                        {/* <Typography className={classes.userName}>Firozkhan</Typography>
                        <BiCaretDown style={{ marginLeft: 10 }} /> */}
                    </div>
                    {outboundVal.value &&
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography className={classes.callerId}>Your caller id displays as {outboundVal.value} </Typography>
                            <Typography className={classes.changeId}>Change</Typography>
                        </div>
                    }
                    <TextField fullWidth placeholder='Type name or number'
                        className={classes.textfield} value={number}
                        onClick={(e: any) => setCursorPosition(e.target.selectionStart)}
                        onChange={handleEnterNumber}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start" onClick={removeNum}>
                                    <MdCancelPresentation style={{ cursor: 'pointer' }} />
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ cursor: 'pointer' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        !errors.phoneNumber.isValid &&
                        <p className={classes.errorMessage}>{errors.phoneNumber.message}</p>
                    }
                    <div style={{ marginTop: 25 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(1)}
                            >
                                <Typography className={classes.number}>1</Typography>

                            </div>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(2)}>
                                <Typography className={classes.number}>2</Typography>
                                <Typography className={classes.text}>abc</Typography>
                            </div>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(3)}>
                                <Typography className={classes.number}>3</Typography>
                                <Typography className={classes.text} >def</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(4)}>
                                <Typography className={classes.number}>4</Typography>
                                <Typography className={classes.text}>ghi</Typography>
                            </div>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(5)}>
                                <Typography className={classes.number}>5</Typography>
                                <Typography className={classes.text}>jkl</Typography>
                            </div>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(6)}>
                                <Typography className={classes.number}>6</Typography>
                                <Typography className={classes.text} >mno</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(7)}>
                                <Typography className={classes.number}>7</Typography>
                                <Typography className={classes.text}>pqrs</Typography>
                            </div>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(8)}>
                                <Typography className={classes.number}>8</Typography>
                                <Typography className={classes.text}>tuv</Typography>
                            </div>
                            <div className={classes.iconNumbersOne} onClick={() => handleNumber(9)}
                            >
                                <Typography className={classes.number}>9</Typography>
                                <Typography className={classes.text} >wxyz</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                            <div className={classes.iconNumbersOne}>
                                <Typography className={classes.number}>*</Typography>
                            </div>
                            <div className={classes.iconNumbersOne}
                                onClick={() => handleNumber(0)}>
                                <Typography className={classes.number}>0</Typography>
                                <Typography className={classes.text}>+</Typography>
                            </div>
                            <div className={classes.iconNumbersOne}>
                                <Typography className={classes.number}>#</Typography>
                            </div>
                        </div>
                        {number && number.length >= 10 && number.length < 15 ?
                            <div style={{ paddingLeft: 85, paddingRight: 80, marginTop: 50 }}>
                                <Button fullWidth style={{ background: 'rgba(32, 111, 211, 0.1)', }}
                                    onClick={() => callingFunction()}>

                                    <IconButton style={{ width: 50, height: 50, background: '#F4F5F6', marginTop: -30, marginLeft: 15 }}>
                                        <IconButton >
                                            <img src={Rectangle} alt="dail" style={{ width: 35, height: 35 }} />
                                        </IconButton>
                                    </IconButton>
                                </Button>
                            </div>
                            :
                            <div style={{ paddingLeft: 85, paddingRight: 80, marginTop: 50 }}>
                                <Button fullWidth style={{ background: 'rgba(32, 111, 211, 0.1)' }}
                                    disabled={true}>

                                    <IconButton style={{ width: 50, height: 50, background: '#F4F5F6', marginTop: -30, marginLeft: 15 }}>
                                        <IconButton >
                                            <img src={Rectangle} alt="dail" style={{ width: 35, height: 35 }} />
                                        </IconButton>
                                    </IconButton>
                                </Button>
                            </div>}
                    </div>
                </Grid>
                <Grid item md={1}></Grid>

            </Grid>
        </div >
    )
}

export default DialPad
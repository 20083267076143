import React from 'react'
import {
  Grid, Button, Typography, Divider,
  Table,
  Card,
  TextField,
  TableBody,
  TableCell,
  TableRow,
  TableContainer, TableHead,
} from '@material-ui/core';
import { TableHeaderComponent } from '../../../components/Table/Table';
import { useStyles } from './styles';
import TableConatainer from './TableBody'
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
export default function BusinessTable(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const tableHeader = ['ClientId', 'Name', 'Email', 'Package', 'Account Status', 'Status'];
  return (
    <div>
      <Typography className={classes.accountHeading}>
        Total Records:{props.paginate.totalDocs}
      </Typography>

      {/* <Card style={{ width: "80%", marginTop: 10, padding: 8, boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)', margin: "auto" }}>
          <div className={classes.mainDiv}>
            <div className={classes.subDiv}>
              <BsArrow90DegUp style={{ color: "#04B400" }} />
              <Typography className={classes.callsApp}>
                Calls from app
              </Typography>
              <Typography className={classes.barLine}>|</Typography>
            </div>
            <div className={classes.subDiv}>

              <BsArrowReturnLeft style={{ color: "#AF0000" }} />
              <Typography className={classes.callsApp}>
                Redirect Calls
              </Typography>
              <Typography className={classes.barLine}>|</Typography>
            </div>
            <div className={classes.subDiv}>
              <AiOutlineArrowDown style={{ color: "#0316BF" }} />
              <Typography className={classes.callsApp}>
                Calls to app
              </Typography>
              <Typography className={classes.barLine}>|</Typography>
            </div>
            <div className={classes.subDiv}>
              <BsArrowDownUp style={{ color: "#AF0000" }} />
              <Typography className={classes.callsApp}>
                Voice mode calls
              </Typography>
              <Typography className={classes.barLine}>|</Typography>
            </div>
            <div className={classes.subDiv}>
              <BsArrow90DegDown style={{ color: "#04B400" }} />
              <Typography className={classes.callsApp}>
                FWD calls
              </Typography>
            </div>
          </div>
        </Card> */}

      <Grid style={{ marginTop: 15 }}>
        <Card className='marginTable' style={{ marginTop: '15px' }}>
          <TableContainer >
            <Table>
              <TableHead className={classes.tablecolor}>
                <TableRow>
                  <TableCell className={classes.tableHead}>
                    Business A/C
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    User
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Department
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Agent
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Type
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    From Number
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    To Number
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Date/Time
                  </TableCell>
                  
                  <TableCell className={classes.tableHead}>
                    Duration
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Call recording
                  </TableCell>
                  {/* <TableCell className={classes.tableHead}>
                            Actions
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            Result
                        </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.data.length > 0 && (
                    props.data?.map((Data: any, i: any) => <TableConatainer
                      fetchReports={props.fetchReports}
                      reportData={Data}
                      paginate={props.paginate}
                      loading={props.loading}
                      key={i}
                    />
                    )
                  )


                }
              </TableBody>
            </Table>

            {props.data.length <= 0 && !props.loading &&
              <p style={{ textAlign: "center", fontSize: 18, fontWeight: 500, marginTop: 10 }}> No Data found</p>
            }
          </TableContainer>
          {props.loading &&
            <Box>
              <div style={{
                textAlign: "center"
              }}>
                <CircularProgress />
              </div>
            </Box>
          }
        </Card>
        {props.data.length <= 0 ? "" :
          <Stack spacing={2} style={{ marginTop: 15, float: "right", marginBottom: 15 }}>
            <Pagination count={props.paginate.totalPages} page={props.paginate.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />
          </Stack>}
      </Grid>

    </div>
  )
}



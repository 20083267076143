import axiosInstance from "../../../environment/axiosInstance";
import { toast } from 'react-toastify'
import axios from 'axios';
class ProfileServices {
  getProfileData = async () => {
    try {
      const response = await axiosInstance.get("/profile");
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {

        return { success: false, message: response.data.message };
      }
    } catch (error: any) {

      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateprofileData = async (updateData: any) => {
    try {
      const response = await axiosInstance.patch("/profile", updateData);
      if (response.data.data) {
        const data = response.data.data;
        toast.success("Updated profile successfully", {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  getDepartmentData = async () => {
    try {
      const response = await axiosInstance.get("/profile/departments");
      if (response.data.data) {
        const data = response.data.data;
        // console.log('--hi department---',data);
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  getoutBoundCallerIdNumbers = async () => {
    try {
      const response = await axiosInstance.get(
        "/profile/outbound-callerid-numbers"
      );
      if (response.data.data) {
        const data = response.data.data;
        //console.log('--hi---',data);
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  getopenHours = async () => {
    try {
      const response = await axiosInstance.get(
        "/profile/settings/open-hours"
      );
      if (response.data.data) {
        const data = response.data.data;
        //console.log('--hi---',data);
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateEfoneNumberSchedule = async (data: any) => {
    try {
      const response = await axiosInstance.patch("/profile/schedule", data);
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        //console.log('--hi---',data);
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  geteFoneNumbers = async () => {
    try {
      const response = await axiosInstance.get("/profile/my-numbers");
      if (response.data.data) {
        const data = response.data.data;

        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  getBlockedNumber = async () => {
    try {
      const response = await axiosInstance.get("/profile/settings/adavenced-settings/blocked-numbers");
      if (response.data.data) {
        const data = response.data.data;

        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateOpenHours = async (updatedData: any) => {
    try {
      const response = await axiosInstance.patch(
        "/profile/settings/open-hours",
        updatedData
      );
      if (response.data.data) {
        const data = response.data.data;
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateClosedHours = async (updatedData: any) => {
    try {
      const response = await axiosInstance.patch(
        "/profile/settings/closed-hours",
        updatedData
      );
      if (response.data.data) {
        const data = response.data.data;
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  getClosedHours = async () => {
    try {
      const response = await axiosInstance.get(
        "/profile/settings/closed-hours");
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  getUnAnsweredCalls = async () => {
    try {
      const response = await axiosInstance.get(
        "/profile/settings/un-answered-calls");
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateUnAnsweredCalls = async (updatedData: any) => {
    try {
      const response = await axiosInstance.patch("/profile/settings/un-answered-calls", updatedData);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  fetchDropDownData = async (URL: any, data: any) => {
    try {
      const response = await axiosInstance.get(URL);
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  dropDownDataUpdate = async (URL: any, data: any) => {
    try {
      const response = await axiosInstance.patch(URL, data);
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateAdvancedSettings = async (data: any) => {
    try {
      const response = await axiosInstance.patch(
        "/profile/settings/advance-setting",
        data
      );
      if (response.data.data) {
        const data = response.data.data;
        toast.success("Updated Advanced Settings successfully", {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  fetchDeviceLists = async () => {
    try {
      const response = await axiosInstance.get("/profile/settings/my-devices");
      if (response.data.data) {
        const data = response.data.data;
        // toast.success("Device added successfully", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   style: {
        //       borderRadius: "2px",
        //       border: "1px solid green"
        //   }, autoClose: 1000
        // })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  //Add ne device services

  fetchSipDetails = async () => {
    try {
      const response = await axiosInstance.get("/profile/sip-credentials");
      if (response.data.data) {
        const data = response.data.data;

        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  postDeviceData = async (data: any, userid: any) => {
    try {
      let url = `/profile/settings/my-devices`
      if (userid !== '') {
        url += `?userId=${userid}`
      }
      const response = await axiosInstance.post(url, data);
      if (response.data.data) {
        const data = response.data.data;
        toast.success("Device Added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };


  postNumberData = async (data: any) => {
    try {
      const response = await axiosInstance.post("/phoneNumbers", data);
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  updateDeviceRing = async (id: any, data: any) => {
    try {
      const response = await axiosInstance.patch(
        `/profile/settings/my-devices/${id}`,
        data
      );
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  deleteMydevice = async (id: any) => {
    try {
      const response = await axiosInstance.delete(`/profile/settings/my-devices/${id}`);
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  postBloackNumberData = async (data: any) => {
    try {
      const response = await axiosInstance.post(
        "profile/settings/adavenced-settings/blocked-numbers",
        data
      );
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  updateBlockNumber = async (id: any, data: any) => {
    try {
      const response = await axiosInstance.patch(
        `/profile/settings/adavenced-settings/blocked-numbers?_id=${id}`,
        data
      );
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  deleteBlockNumber = async (id: any) => {
    try {
      const response = await axiosInstance.delete(
        `/profile/settings/adavenced-settings/blocked-numbers?_id=${id}`
      );
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  postEmergencyAddressData = async (data: any) => {
    try {
      const response = await axiosInstance.post("/profile/e911", data);
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  postDepartmentName = async (data: any) => {
    try {
      const response = await axiosInstance.post("/departments", data);
      if (response.data.data) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  fetchStates = async (value: any) => {
    try {
      const response = await axiosInstance.get("/teli/states?country=" + value);
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  fetchCities = async (id: any) => {
    try {
      const response = await axiosInstance.get(
        "/teli/rate-centers?state=" + id
      );
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  deleteFileData = async (id: any) => {
    try {
      const response = await axiosInstance.delete(`/files/${id}`);

      if (response.data) {
        const data = response.data;
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }

  }

  fetchLocalNumbers = async (data: any) => {
    const SearchType = data.searchType

    try {
      var response
      if (SearchType === "1") {
        response = await axiosInstance.get(
          `/teli/local-numbers?state=${data.stateValue}&ratecenter=${data.cityValue}&npa=${data.npa}&searchType=${data.searchType}`
        );
      }
      else {
        response = await axiosInstance.get(
          `/teli/local-numbers?state=${data.stateValue}&npa=${data.npa}&searchType=${data.searchType}`
        );
      }
      // try {
      //   const response = await axiosInstance.get(
      //     `/teli/local-numbers?state=${data.stateValue}&ratecenter=${data.cityValue}&npa=${data.npa}`
      //   );
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  fetchAreas = async (id: any) => {
    try {
      const response = await axiosInstance.get(
        "/teli/area-codes?state=" + id
      );
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      // toast.error("Error", { position: toast.POSITION.TOP_RIGHT})
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  uploadFileData = async (data: any) => {
    try {
      const response = await axiosInstance.post("/files", data);
      if (response.data) {
        const data = response.data;
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };

  fetchVanityNumbers = async (data: any) => {
    try {
      const response = await axiosInstance.get(
        `/teli/local-numbers?state=${data.stateValue}&npa=${data.npa}&search=${data.number}&searchType=${data.searchType}`
      );
      if (response.data.data) {
        const data = response.data.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  patchFileData = async (id: any, fileData: any) => {
    try {
      const response = await axiosInstance.patch(`/files/${id}`, fileData);
      if (response.data) {
        toast.success("Updated file successfully", {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        const data = response.data;
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  }
  postuserNamePW = async (token: any, data: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/set-details?token=${token}`, data);
      if (response.data.data) {
        const data = response.data.data;
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {

        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
  createNewPassword = async (data: any) => {
    try {
      const response = await axiosInstance.post('/users/change-password', data);
      if (response.data.data) {
        const data = response.data.data;
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          style: {
            borderRadius: "2px",
            border: "1px solid green"
          }, autoClose: 1000
        })
        return { success: true, data: data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 1000 })
      return {
        success: false,
        message:
          error.message === "Network Error"
            ? "Network Error"
            : error.response.data.message,
      };
    }
  };
}

const services = new ProfileServices();
export default services;

import React from "react";
import {
  Card,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useStyles } from "./styles";
import TabContext from "@material-ui/lab/TabContext";

import Default from "./Default/Default";
import Repeating from "./Repeating/Repeating";
import Vanity from "./Vanity/Vaniity";
import ProfileServices from "../../ProfileServices/ProfileServices";
const countryOptions = [
  { label: "Canada", value: 1 },
  { label: "United States", value: 2 },
];
function AddEfoneNumber(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [city, setCity] = React.useState<any>([]);
  const [country, setCountry] = React.useState<any>({ label: "Canada", value: 1 });
  const [cities, setCities] = React.useState([]);
  const [area, setArea] = React.useState<any>([]);
  const [allAreas, setAreas] = React.useState<any>([]);
  const [areaOptions, setAreaOptions] = React.useState([]);
  const [citiesOptions, setCityOptions] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [state, setState] = React.useState<any>([]);
  const [localNUmbers, setLocalNUmbers] = React.useState<any>([]);
  const [repeatNumber, setRepaetNumber] = React.useState<any>([]);
  const [index, setIndex] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [Repaetindex, setRepeatIndex] = React.useState(0)
  const handleTabs = (e: any, newValue: any) => {
    setValue(newValue);
  };
  const onChangeCountryType = (data: any) => {
    setState([]);
    setCity([]);
    setArea([])
    setCountry(data);
    setLoading(true)
    ProfileServices.fetchStates(data.value)
      .then((res: any) => {
        if (res.success) {
          const mappedStateData: any = res.data.map((item: any) => {
            return { label: item.value, value: item.id };
          });
          setStates(mappedStateData);
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => {
        // send error
        setLoading(false)
      });
  };
  const onChangeAreaCodetype = (data: any) => {
    setAreaOptions([]);
    setArea([])
    setState(data);
    setLoading(true)
    ProfileServices.fetchAreas(data.value)
      .then((res: any) => {
        if (res.success) {
          const mapCitiesDropdown = res.data.map((dt: any) => {
            return { label: dt.npa, value: dt.value, id: dt.id };
          });
          setAreas(res.data);
          setAreaOptions(mapCitiesDropdown);

          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => {
        setLoading(false)
      });
  };
  const onChangeStateType = (data: any) => {
    setCity([]);
    setState(data);
    setLoading(true)
    ProfileServices.fetchCities(data.value)
      .then((res: any) => {
        if (res.success) {
          const mapCitiesDropdown = res.data.map((dt: any) => {
            return { label: dt.value, value: dt.id };
          });
          setCities(res.data);
          setCityOptions(mapCitiesDropdown);
          setLoading(false)

          // console.log(res.data);
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => {
        setLoading(false)
      });
  };
  const onChangeCity = (data: any) => {
    setCity(data);
    setLoading(true)
    // console.log("---city",data)
    const selectedCity: any = cities.find((cty: any) => cty.value === data.label);
    if (country && state && selectedCity) {
      const filterObject: any = {
        stateValue: state?.value,
        cityValue: selectedCity.id,
        npa: selectedCity?.npa,
        searchType: value,
      };

      ProfileServices.fetchLocalNumbers(filterObject)
        .then((res: any) => {
          if (res.success) {
            setLocalNUmbers(res.data);
            setLoading(false)
          } else {
            setLoading(false)
          }
        })
        .catch((err: any) => {
          setLoading(false)
        });
    }
    // console.log("selected city", selectedCity);
  };
  const onchangingcity = (data: any) => {
    setArea(data)
    setLoading(true)
    const selectedCity: any = allAreas.find((cty: any) => cty.value === data.value);
    if (country && state && selectedCity) {

      const filterObject: any = {
        stateValue: state?.value,
        // cityValue: selectedCity.id,
        npa: selectedCity?.npa,
        searchType: value,

      };

      ProfileServices.fetchLocalNumbers(filterObject)
        .then((res: any) => {
          if (res.success) {
            setRepaetNumber(res.data);
            setLoading(false)
          } else {
            setLoading(false)
          }
        })
        .catch((err: any) => {
          setLoading(false)
        });
    }

  };
  React.useEffect(() => {
    onChangeCountryType(country)
  }, [])
  React.useEffect(() => {
    if (city) {
      setIndex(0)
    }
  }, [city])
  React.useEffect(() => {
    if (area) {
      setRepeatIndex(0)
    }
  }, [area])
  const handleChangeNumbers = () => {
    setLoading(true)
    var myIndex = localNUmbers.length > index + 5 ? index + 5 : 0
    setIndex(myIndex)
    setTimeout(() => {
      setLoading(false)
    }, 1000);

  }


  const handleChangeRepaetNumbers = () => {
    setLoading(true)
    var myIndexR = repeatNumber.length > Repaetindex + 5 ? Repaetindex + 5 : 0
    setRepeatIndex(myIndexR)
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }

  return (
    <div style={{ width: "750px" }}>
      <div className={classes.padding}>
        <TabContext value={value}>
          <Card className={classes.tabCard}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleTabs}
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  height: "0px",
                },
              }}
            >
              <Tab
                label="Default"
                value="1"
                className={value === "1" ? classes.tab : classes.tabActive}
              />
              <Tab
                label="Repeating"
                value="2"
                className={value === "2" ? classes.tab : classes.tabActive}
              />
              <Tab
                label="Vanity"
                value="3"
                className={value === "3" ? classes.tab : classes.tabActive}
              />
            </Tabs>
          </Card>

          <Default
            countryOptions={countryOptions} onChangeStateType={onChangeStateType}
            states={states} state={state}
            country={country}
            citiesOptions={citiesOptions}
            onChangeCountryType={onChangeCountryType} onChangeCity={onChangeCity}
            city={city} localNUmbers={localNUmbers}
            handlePopupModal={props.handlePopupModal}
            fetchNumberLists={props.fetchNumberLists}
            loading={loading}
            handleChangeNumbers={handleChangeNumbers}
            index={index} />

          <Repeating countryOptions={countryOptions} onChangeStateType={onChangeAreaCodetype}
            states={states} state={state}
            country={country}
            citiesOptions={areaOptions}
            onChangeCountryType={onChangeCountryType} onChangeCity={onchangingcity}
            city={area} localNUmbers={repeatNumber}
            handleChangeNumbers={handleChangeRepaetNumbers}
            index={Repaetindex}
            loading={loading}
            handlePopupModal={props.handlePopupModal}
            fetchNumberLists={props.fetchNumberLists} />

          <Vanity countryOptions={countryOptions} onChangeStateType={onChangeAreaCodetype}
            states={states} state={state} country={country}
            citiesOptions={areaOptions} cities={allAreas}
            onChangeCountryType={onChangeCountryType} onChangeCity={onchangingcity}
            city={area} localNUmbers={repeatNumber}
            handleChangeNumbers={handleChangeRepaetNumbers}
            index={Repaetindex}
            loading={loading}
            handlePopupModal={props.handlePopupModal}
            fetchNumberLists={props.fetchNumberLists} />
        </TabContext>
      </div>
    </div>
  );
}

export default AddEfoneNumber;

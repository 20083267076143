import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    card: {
        margin: '19px 0px 0px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
    },
    cardStyles:{
        marginTop:25
    },
    heading: {
        color: '#4D72F8',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: "Calibri"
    },
    divider: {
        color: '#C2C2C2',
        marginTop: '-14'
    },
    accordianNoFlex: {
        display: 'block'
    },
    roomCard: {
        display: 'flex',
        width: '70%',
        marginBottom: '10px',
        background: '#FFFFFF',
        borderRadius: 8,
        padding: '18px',
        border: '1px solid #E4E4E4',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    landIcon: {
        [theme.breakpoints.down('xs')]: {
            width: '50px',
            height:'50px'
        },

    },
    phonesText: {
        fontSize: 14,
        paddingLeft: 10,
        paddingRight: 20,
        fontWeight: 400,
        color: '#929292',


    },
    modalStyles:{

    },
    mdGroup: {
        fontSize: '50px',
        color: 'blue',
        [theme.breakpoints.down('xs')]: {
            fontSize: '100px',

        },
    },
    roomPhoneContent: {
        marginLeft: 10,
        padding: 0,
        paddingLeft: 10,
        paddingRight: 20,
        display: 'block',
        alignItems: 'center',
    },
    newcard: {
        marginTop: '40px',
        background:'#FFFFFF',
        border:'1px solid #FFFFFF',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.25)',
        borderRadius:'8px'
    
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontFamily:'Calibri',
        color: 'white',
        float: 'right',
        fontSize:14,
        padding:'6px 20px',
        background: '#4D72F8',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#4D72F8',
            color: 'white'
        }
    },
    table: {
        marginTop: '30px'
    },
    tableName: {
        fontFamily: 'Arial',
        fontWeight: 500,
        fontSize: '14px',
        color: ' #747474'
    },
    tableCell: {
        fontFamily: 'Arial',
        fontWeight: 400,
        fontSize: 13,
        color: '#A5A5A5'
    },
    actionButton: {
        borderWidth: 1,
        minWidth: 0,
        padding: '2px 20px',
    },
    adminIcon: {
        width: 25,
        height: 25,
        color: '#464646',
    },
    delete: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
    },
    textColor: {
        color: '#464646',
        marginLeft: 10,
        fontWeight: 'bold',
    },
    modalWdth: {
        width: "650px",
        "@media (max-width: 768px)": {
            width: 480
        }
    },
    modalWdth1: {
        width: "500px",
    },
    tableBdy: {
        color: '#828282',
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '14px'
    },
    tableBdy1: {
        color: '#4D72F8',
        fontWeight: 400,
        fontFamily: 'Inter',
        fontSize: '14px'
    },
    addNumber: {
        color: '#4D72F8',
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Inter',
        marginTop:20,
        textTransform: 'capitalize',
        marginBottom:20,
    },
    deleteButton: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        background: '#3949ab',
        color: '#FFFFFF',
        borderRadius: 5,
        padding: '6px 22px',
        fontSize: 14,
        '&:hover': {
            color: '#FFFFFF',
            background: '#3949ab',
        }
    },
    cancelButton: {
        marginLeft: 20,
        borderRadius: 5,
        padding: '6px 22px',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontSize: 14,
        fontWeight: 500,
        color: '#2196f3',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        background: '#FFFFFF'
    },
    card1: {
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
      },
      width: {
        width: '100%',
      },
      text: {
        color: '#464646',
        fontWeight: 500,
        fontSize: 15,
        marginBottom: 10
      },
      errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
      },
      saveButton: {
        borderRadius: 8,
        marginRight: 10,
        backgroundColor: '#3949ab',
        padding: '6px 22px',
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#3949ab',
        },
      },

}))
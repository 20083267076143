import React from "react";
import {
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
// import React from 'react'
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useStyles } from "./style";
import axiosInstance from "../../../environment/axiosInstance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function EditContact(props: any) {
  const classes = useStyles();
  const [data, setData] = React.useState<any>({
    firstName: "",
    lastName: "",
    contactNumber: "",
  });

  const onChangeTextfield = (e: any) => {
    setData((prev: any) => {
      return {
        ...data,
        [e.target.name]: e.target.value,
      };
    });
  };
  const onSubmitForm = () => {
    axiosInstance.patch(`/contacts/`).then((res: any) => {
      if (res.status === 200) {
        props.fetchContact();
        props.handlePopupModalEditContact();
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    });
  };
  React.useEffect(() => {
    if (props.editId) {
      axiosInstance.get(`/contacts?id=${props.editId}`).then((res: any) => {
        if (res.status === 200) {
          const data = res.data.Data;
          const filterData = data.filter(
            (item: any) => item._id === props.editId
          );
          setData((prev: any) => {
            return {
              firstName: filterData[0]?.firstName,
              lastName: filterData[0]?.lastName,
              email: filterData[0]?.email,
              contactNumber: filterData[0]?.contactNumber,
            };
          });
        }
      });
    }
  }, [props.editId]);
  const updateSubmit = () => {
    axiosInstance.patch(`/contacts/${props.editId}`, data).then((res: any) => {
      if (res.status === 200) {
        props.fetchContacts();
        props.handlePopupModalEditcontact();
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    });
  };
  return (
    <div>
      <Grid container className={classes.mt10}>
        <Grid item xl={5} lg={8} md={5} sm={5} xs={12}>
          <Grid container className={classes.inputFields}>
            <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
              <Typography
                className={classes.names}
                style={{ textAlign: "center" }}
              >
                First name
              </Typography>
            </Grid>
            <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Enter firstname"
                // className={classes.textField}
                onChange={onChangeTextfield}
                value={data.firstName}
                name="firstName"
              // error={!errors.email.isValid}
              />
              {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
            </Grid>
          </Grid>
          <Grid container className={classes.inputFields}>
            <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
              <Typography
                className={classes.names}
                style={{ textAlign: "center" }}
              >
                Last Name
              </Typography>
            </Grid>
            <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Enter lastname"
                // className={classes.textField}
                onChange={onChangeTextfield}
                value={data.lastName}
                name="lastName"
              // error={!errors.email.isValid}
              />
              {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
            </Grid>
          </Grid>
          <Grid container className={classes.inputFields}>
            <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
              <Typography
                className={classes.names}
                style={{ textAlign: "center" }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Enter email"
                // className={classes.textField}
                onChange={onChangeTextfield}
                value={data.email}
                name="email"
              // error={!errors.email.isValid}
              />
              {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
            </Grid>
          </Grid>
          <Grid container className={classes.inputFields}>
            <Grid item xl={3} lg={6} md={12} sm={12} xs={12}>
              <Typography
                className={classes.names}
                style={{ textAlign: "center" }}
              >
                Phone Number
              </Typography>
            </Grid>
            <Grid item xl={9} lg={6} md={12} sm={12} xs={12}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Enter Phone number"
                // className={classes.textField}
                onChange={onChangeTextfield}
                value={data.contactNumber}
                name="contactNumber"
              // error={!errors.email.isValid}
              />
              {/* {
                                !errors.email.isValid &&
                                <p className={classes.errorMessages}>{errors.email.message}</p>
                            } */}
            </Grid>
          </Grid>
        </Grid>

        {/* end first col-6 */}
        <Grid item xl={1} lg={1} md={12} sm={12} xs={12}></Grid>

        {/* end last col-6 */}
      </Grid>
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginLeft: "150px",
          marginTop: "35px",
        }}
      >
        <Button
          variant="outlined"
          style={{ background: "gray", color: "white", padding: "7px 20px" }}
          onClick={props.handlePopupModalEditcontact}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={{ background: "blue", color: "white", padding: "7px 20px" }}
          onClick={updateSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
}

export default EditContact;

import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    editmodal: {
        height: 500, 
        [theme.breakpoints.down('xs')]: {
          height: 500,
          width: 380,
          marginTop: -10
        }
      },
      microphone: {
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
          width: 25, height: 25,
        }
      },
      playaudioTrack: {
        width: '432px',
        [theme.breakpoints.down('xs')]: {
          width: '250px', height: 35
        }
      },
      inputFieldfilename: {
        width: '237px',
        marginRight: '10px', color: '#787878',
        [theme.breakpoints.down('xs')]: {
          width: '190px',
          marginRight: '10px'
        }
      },
    filename: {
        fontFamily: 'Calibri ! important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "14px",
        color: '#848282',

    },
    saveAsdefaultBtn: {
        width: '170px',
        height: '42px',
        background: '#4D72F8',
        borderRadius: '3px',
        color: "#FFFFFF",
        textTransform: 'capitalize'
    },
    dFlex: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    minutes: {
        fontFamily: 'Work Sans ! important',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        color: '#8F8E8E',
        marginTop: '10px',
        lineHeight: '19px'
    },
    audioTrack: {
        width: '500px', background: '#E9ECED',
        [theme.breakpoints.down('xs')]: {
            width: '220px', height: 35
          }
    },
    audioTrackActive: {
        width: '500px',
         background: 'rgba(118, 144, 238, 0.5)',
         [theme.breakpoints.down('xs')]: {
            width: '220px', height: 35
          }
    },
    selectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        borderTopLeftRadius:'none',
        borderTopRightRadius:4,
        borderBottomRightRadius:4,
        width:'40px',height:'58px',
        [theme.breakpoints.down('xs')]: {
            height: 39
          }
        //borderRadius: '4px'
    },
    unSelectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5',
        width:'40px',height:'56px',
        [theme.breakpoints.down('xs')]: {
            height: 39
          }
    },
    selected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        // borderRadius: '4px'
        borderTopLeftRadius:'4px',
        borderTopRightRadius:'none',
        borderBottomLeftRadius:4,
        // borderBottomRightRadius:4
    },
    unSelected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5'
    },
    saveBtn: {
        width: '93px',
        height: '35px',
        background: '#4D72F8',
        borderRadius: '3px',
        color: "#FFFFFF",
        textTransform: 'capitalize'
    },
    uploadContainer: {
        cursor: 'pointer',
        padding: '10px',
        borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
    },
    tabs: {
        background: "#FFFFFF",
        // borderRadius: "7.51286px",
        // boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
       // width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '0px'
    },
    tab: {
        textTransform: "capitalize",
        background: '#E4E4E4 ! important',
        color: '#A1A1A1 ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 20,
        // width: '315px',
        // height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px'
    },
    tabActive: {
        background: '#4D72F8 ! important',
        color: '#FFFFFF ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 20,
        textTransform: "capitalize",
        letterSpacing: '0.02em',
        borderRadius: '2px'
    },
    cardStyle: {
        padding: 30,
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        borderRadius: 10
    },
    title: {
        color: '#A0A0A0',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Calibri',
        marginBottom: 15
    },
    divPadding:{
        margin: '20px 60px 30px 20px',
        [theme.breakpoints.down('xs')]:{
            margin:0
        }   
    },
    audioPlayer: {
        display: "block",
        width: "100%",
        height: 40,
        borderRadius: 'none',
        '&.selected': {
            color: '#0921a9'
        },
        '&:hover': {
            color: '#4D72F8'
        },
    },
    editBtn:{
        background:'#4D72F8',
        borderRadius:5,
        color:'#fff',
        padding:'6px 25px',
        marginRight:'15%',
        textTransform:'capitalize',
        '&:hover':{
            color:'#fff',
            background:'#4D72F8',
        }
      }
}))
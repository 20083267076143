import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => (
    {
        card: {
            borderRadius: "6px",
            "@media (max-width: 426px)": {
                width: 350,

            }
        },
        users: {
            borderRadius: "nullpx",
            fontFamily: "Calibri",
            fontSize: "20px",
            color: '#4D72F8',
            [theme.breakpoints.down("xs")]: {
                fontSize: "16px",
            }
        },
        divider: {
            backgroundColor: '#D2D2D2',
            marginTop: -10,
            border: "1px solid #D2D2D2"
        },
        deletepopup:{
            width: "500px" ,
            [theme.breakpoints.down("xs")]: {
                width: "300px" ,
            }
        },
        officeName:{
            color: '#000000' 
        },
        inputcompanyFiled:{
            [theme.breakpoints.down("xs")]: {
                width:150
            }
        },
        addBtnsContainer:{
            display: 'flex', textAlign: 'center', justifyContent: 'center', gap: 20,
            [theme.breakpoints.down("xs")]: {
                marginLeft:50
            }
        },
        addpopup:{
            width: "500px" ,padding: 10,
            [theme.breakpoints.down("xs")]: {
                width: "300px" ,
            }
        },
        tRow: {
            background: '#F1F1F1',
            borderRadius: 6
        },
        tCell: {
            color: '#464646',
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Work Sans'
        },
        tablebodyContainer: {
            // marginLeft: 16,
            marginTop: 30,
            marginBottom: 30,
            border: '1px solid #F1F1F1',
             borderRadius: 5, width: '90%',
             margin:'auto',
             [theme.breakpoints.down("xs")]: {
                width:320,
                marginLeft:10,
                marginRight:10,
                
            }
        },
        tbody: {
            color: '#787878',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Work Sans'
        },
        textColor: {
            color: '#464646',
            marginLeft: 10,
            fontWeight: 'bold',
        },
        addBtnContainer: {
            width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
            [theme.breakpoints.down("xs")]: {
                width:380
            }
        },
        add: {
            background: "#4D72F8",
            border: " 1px solid #F0EEEE",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            color: '#FFFFFF',
            fontSize: '15px',
            padding: '8px 20px',
            textTransform: 'capitalize',
            float: 'right',
            '&:hover': {
                background: '#4D72F8',
                color: '#FFFFFF',
                alignItems: 'center'
            },
            marginTop: '20px',
            marginRight: 50,
            // marginLeft:'500px',
            [theme.breakpoints.down("xs")]: {
                width: 55,
                height: 25,
                fontSize: 8,
                padding: '0px 0px',
                marginLeft:250
            }
           
        },
    }
))
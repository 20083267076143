import { Button, CardContent, Grid, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import axiosInstance from '../../../../environment/axiosInstance'
import DatePicker from "react-datepicker";
import moment from 'moment'


const serviceOptions = [
    { label: "D", value: 1 },
    { label: "B", value: 2 },
]

function Newporting(props: any) {
    const classes = useStyles()
    const [submitted, setSubmitted] = React.useState<any>(false);
    const [startDate, setStartDate] = React.useState<any>(new Date());
    const [portingData, setPortingData] = React.useState<any>({
        phoneNumber: "",
        currentProviderName: "",
        serviceType: "",
        desireDueDate: Date.parse(startDate).toString(),
        autorizationDate: Date.parse(startDate).toString(),
        endUserName: "",
        existingAccountNumber: "",
        StreetNumber: "",
        streetName: "",
        city: "",
        province: "",
        zipCode: "",
        loosingCarrierComments: "",
        comments: ""
    })

    const onChangeTextfield = (e: any) => {
        setPortingData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeServiceType = (data: any) => {
        console.log("-----service", data)
        setPortingData((prev: any) => {
            return {
                ...prev,
                serviceType: data.label,

            }
        })
    }

    const onChangeDateandTime = (date: any) => {
        // console.log(date)
        const Dateandtime = moment(date, 'YYYY.MM.DD').unix();
        const dt = Dateandtime * 1000
        setStartDate(dt);
        // setPortingData((prev: any) => {
        //   return {
        //     ...prev,
        //     autorizationDate: dt,
        //   }
        // })
    };

    const AddPortingDataData = () => {
        setSubmitted(true);
        const errorData = validate(true);
        if (errorData.isValid) {
            axiosInstance.post('/iristel/create-port', portingData).then((res: any) => {
                if (res.status === 200) {
                    props.handlePopupModal();
                    //props.fetchDeskphoneList();
                } else {

                }
            }).catch((err: any) => {
                console.log(err);
                // send error
            });
        }
    }

    const validate = (submitted: any) => {
        const validData = {
            isValid: true,
            message: ""
        };
        const retData = {
            phoneNumber: { ...validData },
            currentProviderName: { ...validData },
            serviceType: { ...validData },
            endUserName: { ...validData },
            existingAccountNumber: { ...validData },
            StreetNumber: { ...validData },
            streetName: { ...validData },
            city: { ...validData },
            province: { ...validData },
            zipCode: { ...validData },
            loosingCarrierComments: { ...validData },
            comments: { ...validData }
        };
        let isValid = true;
        if (submitted) {
            if (!portingData.phoneNumber) {
                retData.phoneNumber = {
                    isValid: false,
                    message: "Please enter phone number"
                };
                isValid = false;
            }
            if (portingData.phoneNumber.length < 10 || portingData.phoneNumber.length > 15) {
                retData.phoneNumber = {
                    isValid: false,
                    message: "Please enter 10 to 15 digit phone number "
                };
                isValid = false;
            }
            if (!portingData.currentProviderName) {
                retData.currentProviderName = {
                    isValid: false,
                    message: "Please enter provider name"
                };
                isValid = false;
            }
            if (!portingData.serviceType) {
                retData.serviceType = {
                    isValid: false,
                    message: "Please select service type"
                };
                isValid = false;
            }
            if (!portingData.serviceType) {
                retData.serviceType = {
                    isValid: false,
                    message: "Please select service type"
                };
                isValid = false;
            }
            if (!portingData.endUserName) {
                retData.endUserName = {
                    isValid: false,
                    message: "Please enter end user name "
                };
                isValid = false;
            }
            if (!portingData.existingAccountNumber) {
                retData.existingAccountNumber = {
                    isValid: false,
                    message: "Please enter account number"
                };
                isValid = false;
            }
            if (!portingData.StreetNumber) {
                retData.StreetNumber = {
                    isValid: false,
                    message: "Please enter street number"
                };
                isValid = false;
            }
            if (!portingData.streetName) {
                retData.streetName = {
                    isValid: false,
                    message: "Please enter street name"
                };
                isValid = false;
            }
            if (!portingData.city) {
                retData.city = {
                    isValid: false,
                    message: "Please enter city "
                };
                isValid = false;
            }
            if (!portingData.province) {
                retData.province = {
                    isValid: false,
                    message: "Please enter province "
                };
                isValid = false;
            }
            if (!portingData.zipCode) {
                retData.zipCode = {
                    isValid: false,
                    message: "Please enter zip code"
                };
                isValid = false;
            }
            if (!portingData.loosingCarrierComments) {
                retData.loosingCarrierComments = {
                    isValid: false,
                    message: "Please enter carrier comments"
                };
                isValid = false;
            }
            if (!portingData.comments) {
                retData.comments = {
                    isValid: false,
                    message: "Please enter comments"
                };
                isValid = false;
            }
        }
        return {
            ...retData,
            isValid
        };
    };

    const errors = validate(submitted);

    return (
        <div>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <Typography className={classes.typography}>Phone Number</Typography>
                        <TextField className={classes.textPlanceholder}
                            variant='outlined'
                            size='small'
                            fullWidth
                            placeholder='Enter phone number'
                            name='phoneNumber'
                            value={portingData.phoneNumber}
                            onChange={onChangeTextfield}
                            error={!errors.phoneNumber.isValid}
                        />
                        {
                            !errors.phoneNumber.isValid &&
                            <p className={classes.errorMessage}>{errors.phoneNumber.message}</p>
                        }
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Current Provider Name</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder='Enter provider name'
                                value={portingData.currentProviderName}
                                onChange={onChangeTextfield}

                                error={!errors.currentProviderName.isValid}
                            />
                            {
                                !errors.currentProviderName.isValid &&
                                <p className={classes.errorMessage}>{errors.currentProviderName.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Service type</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder='Enter service type'
                                value={portingData.serviceType}
                                //options={serviceOptions}
                                onChange={onChangeServiceType}
                                error={!errors.serviceType.isValid}
                            />
                            {
                                !errors.serviceType.isValid &&
                                <p className={classes.errorMessage}>{errors.serviceType.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Province/State</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                name="province"
                                placeholder="Enter current province"
                                onChange={onChangeTextfield}
                                error={!errors.province.isValid}
                            />
                            {
                                !errors.province.isValid &&
                                <p className={classes.errorMessage}>{errors.province.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Desired Due Date </Typography>
                            {/* <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder='Canada'
                            /> */}
                            <DatePicker
                                selected={startDate}
                                onChange={onChangeDateandTime}
                                //timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy"
                                showTimeInput
                                className={classes.inputdate}
                            />
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>End user name</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                name="endUserName"
                                placeholder="Enter user name"
                                onChange={onChangeTextfield}
                                error={!errors.endUserName.isValid}
                            />
                            {
                                !errors.endUserName.isValid &&
                                <p className={classes.errorMessage}>{errors.endUserName.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Losing Carrier comments</Typography>
                            <TextareaAutosize style={{ width: '100%', border: '1px solid block' }}
                                placeholder="Enter losing carrier comments"
                                name="loosingCarrierComments"
                                rowsMin={4}
                                rowsMax={5}
                                onChange={onChangeTextfield}
                            />
                            {
                                !errors.loosingCarrierComments.isValid &&
                                <p className={classes.errorMessage}>{errors.loosingCarrierComments.message}</p>
                            }
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <Typography className={classes.typography}>Street Number </Typography>
                        <TextField className={classes.textPlanceholder}
                            variant='outlined'
                            size='small'
                            name="StreetNumber"
                            fullWidth
                            placeholder="Enter street number"
                            onChange={onChangeTextfield}
                            error={!errors.StreetNumber.isValid}
                        />
                        {
                            !errors.StreetNumber.isValid &&
                            <p className={classes.errorMessage}>{errors.StreetNumber.message}</p>
                        }
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Street Name</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                name="streetName"
                                fullWidth size="small"
                                placeholder="Enter street name"
                                onChange={onChangeTextfield}
                                error={!errors.streetName.isValid}
                            />
                            {
                                !errors.streetName.isValid &&
                                <p className={classes.errorMessage}>{errors.streetName.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>City</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                name="city"
                                fullWidth
                                placeholder="Enter city"
                                onChange={onChangeTextfield}
                                error={!errors.city.isValid}
                            />
                            {
                                !errors.city.isValid &&
                                <p className={classes.errorMessage}>{errors.city.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Postal Code</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                name="zipCode"
                                placeholder="Enter postal code"
                                onChange={onChangeTextfield}
                                error={!errors.zipCode.isValid}
                            />
                            {
                                !errors.zipCode.isValid &&
                                <p className={classes.errorMessage}>{errors.zipCode.message}</p>
                            }
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Autherised Due Date </Typography>
                            {/* <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder='Canada'
                            /> */}
                            <DatePicker
                                selected={startDate}
                                onChange={onChangeDateandTime}
                                //timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy"
                                showTimeInput
                                className={classes.inputdate}
                            />
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Typography className={classes.typography}>Existing Account Number</Typography>
                            <TextField className={classes.textPlanceholder}
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder="Select an option"
                                name="existingAccountNumber"
                                onChange={onChangeTextfield}
                                error={!errors.existingAccountNumber.isValid}
                            />
                            {
                                !errors.existingAccountNumber.isValid &&
                                <p className={classes.errorMessage}>{errors.existingAccountNumber.message}</p>
                            }

                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <Typography className={classes.typography}>Comments for iristel</Typography>
                            <TextareaAutosize style={{ width: '100%', border: '1px solid block' }}
                                placeholder="Enter losing carrier comments"
                                name="comments"
                                rowsMin={6}
                                rowsMax={7}
                                className={classes.textPlanceholder}
                                onChange={onChangeTextfield}
                            />
                            {
                                !errors.comments.isValid &&
                                <p className={classes.errorMessage}>{errors.comments.message}</p>
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.divbuttons} >
                    <Button
                        onClick={props.handlePopupModal}
                        className={classes.buttoncancel} size='small' variant='outlined'>
                        Cancel
                    </Button>
                    <Button
                        className={classes.buttonRequest}
                        size='small' variant='outlined'
                        onClick={AddPortingDataData}
                    >
                        Create Request
                    </Button>
                </div>
            </CardContent>
        </div>
    )
}

export default Newporting
import React from 'react'
import {
    Button,
    Grid,
    TextField,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import { useStyles } from './styles';
import ReactSelect from 'react-select'
import axiosInstance from '../../../../../environment/axiosInstance'
//import UserNumbersServices from '../../../../../../../userNumbersServices/usersNumbersServices'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function AddOperator(props: any) {
    const classes = useStyles();
    const [userListData, setUserListData] = React.useState<any>([])
    const [userid, setUserId] = React.useState<any>()
    const [agnetId, setAgentId] = React.useState<any>()
    const [agents, setAgents] = React.useState([])
    const [agentName, setAgentAddName] = React.useState({
        id: ""
    });


    React.useEffect(() => {
        const agentss: any = [];
        if (props.openHoursData) {
            props.openHoursData.Agents?.map((item: any) => {
                var obj = {
                    label: item.firstName + ' ' + item.lastName,
                    value: item.firstName + ' ' + item.lastName,
                    id: item._id
                }
                agentss.push(obj)
            })
            setAgents(agentss)
        }
    }, [props.openHoursData])


    const onSubmitAddAgent = () => {
        axiosInstance.post(`departments/${props.depId}/open-hours/agents/operator`,agentName).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.handleModalOperator()
                props.getOpenHours()

            }
        })
        // UserNumbersServices.postUserDepartment(userid, addName)
        //     .then((res: any) => {
        //         if (res.success) {
        //             props.handlePopupModal();
        //             props.fetchDepartmentUserLists();
        //         } else {
        //         }
        //     })
        //     .catch((err: any) => {
        //         // send error
        //     });
    };
    const onChangeUser = (data: any) => {
        const sendId : any = data.id
        setAgentAddName((prev: any) => {
            return {
                ...prev,
                id: sendId
            };
        });
    };
    return (
        <>
            <CardContent>
                <Card style={{ padding: '10px' }}>
                    <Grid container spacing={2} style={{ padding: 18 }}>
                        <Grid item md={7} sm={7} xs={6} style={{ marginBottom: 30 }}>
                            <Typography className={classes.departmentNameText}>Select Agent</Typography>
                            <ReactSelect
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                placeholder={"Select User"}
                                options={agents}
                                className={classes.mtop}
                                // value={
                                //     agents.filter((option: any) =>
                                //         option.value === props.closeHoursData.closeHours?.teamMemberId)
                                // }

                                onChange={onChangeUser}
                            />
                        </Grid>

                        <Grid item md={5} sm={5} xs={6} style={{ marginTop: 22 }}>
                            <div className={classes.bflex}>
                                <Button
                                    variant="outlined"
                                    className={classes.deleteButton}
                                    onClick={onSubmitAddAgent}

                                >
                                    Add
                                </Button>
                                <Button
                                    variant="outlined"
                                    className={classes.deltecancelButton}
                                    onClick={props.handleModalOperator}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </CardContent>
        </>
    )
}
export default AddOperator

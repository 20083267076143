import React from 'react'
import './style.css'
import {
    Button,
    Grid,
    Typography,
    TextField,
    Card,
    CardContent,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import axiosInstance from '../../../environment/axiosInstance'
import * as popUpActionCreator from "../../../store/Actions/globalpopupAlert";
import { connect } from "react-redux";
import { fontStyle, padding } from '@mui/system';
import { useStyles } from './styles';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
const formGroup: any = [
    {
        field: 'email',
        valid: true,
        message: ''
    }

]

function ForgotPassword(props: any) {
    const history: any = useHistory();
    const classes = useStyles()
    const [email, setEmail] = React.useState<any>('')
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];

        function validateRegexEmail(email: any) {
            // eslint-disable-next-line
            const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        if (dataToValidate.property === 'email') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Email should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide valid email address'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }



    }

    const handleEmail = (e: any) => {
        e.preventDefault();
        setEmail(e.target.value)
        validateForm({ property: 'email', value: e.target.value })
    }
    const onSubmitForm = (e: any) => {
        e.preventDefault()
        validateForm({ property: 'email', value: email })
        const data = {
            "email": email
        }
        if (email) {
            axiosInstance.post('/auth/forgot-password', data).then((res) => {
                if (res.data) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        style: {
                            borderRadius: "2px",
                            border: "1px solid green"
                        }, autoClose: 1000
                    })
                    history.push('/')

                }
                else {
                    console.log("hghjgj2", res)
                    toast.error(res.data.message, { position: toast.POSITION.TOP_RIGHT })
                    props.popUpAlert(res.data.message, "UPDATE_AGENT_FAIL", "error");
                }
            }).catch((err) => {

                console.log(err)
                toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT })
                //props.popUpAlert(err.message, "UPDATE_AGENT_FAIL", "error");
                props.popUpAlert('Plese enter registered email', "UPDATE_AGENT_FAIL", "error");
            })
        }
        // else if(email !== email ){
        //     props.popUpAlert('Plese enter registered email', "UPDATE_AGENT_FAIL", "error");
        // }
    }
    return (

        <div className="sign_wrapper" style={{ marginTop: "85px" }}>
            <div >
                <Typography className={classes.mainHeader}>Forget Password ?</Typography>
            </div>
            <Typography className={classes.reset}>You can Reset your Password here</Typography>
            <div className="sign_form_container">
                <form>
                    <div className={classes.divFlex}>
                        <Typography className={classes.email}>Email:</Typography>
                        <input type="text" name="email" value={email} onChange={handleEmail} placeholder="Enter your Email"
                            style={{
                                padding: '11px 20px', width: '90%', margin: '2px 10px',
                                borderRadius: '20px', outline: 0, border: '1px solid #c7c7c7'
                            }}
                        />
                        {/* <TextField
                            type="text"
                            variant="outlined"
                            name="email"
                            id="email"
                            fullWidth
                            size="small"
                            className={classes.emailText}
                            placeholder="Enter your Email"
                            value={email}
                            onChange={handleEmail}
                        /> */}
                    </div>
                    <div style={{ marginLeft: "52px", fontSize: 16, marginTop: 6 }}>
                        <p className={classes.error}>{formGroupData.map((fileds: any) => {
                            if (fileds.valid === false && fileds.field === 'email') {
                                return fileds.message
                            } else {
                                return ''
                            }
                        })}
                        </p>
                    </div>


                    <div className={classes.buttons}>
                        <Button type="submit" size="small"
                            className={classes.submitButton} onClick={onSubmitForm} >
                            Submit</Button>
                        <Button type="submit" size="small" variant="outlined" className={classes.backbutton}
                            onClick={() => history.push('/')}>Back</Button>
                    </div>
                </form>
            </div>
        </div>



    )
}
const mapDispatchToProps = (dipatches: any) => {
    return {
        popUpAlert: (err: any, instance: any, type: any) => dipatches(popUpActionCreator.initglobalPopup(err, instance, type))
    }
}
export default connect(null, mapDispatchToProps)(ForgotPassword)

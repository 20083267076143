import React from 'react'
import { Card, Tabs, Tab, Grid, Typography, FormControl, Select, Switch, CardContent, Divider, Button, IconButton, TextField, } from '@material-ui/core';
import { useStyles } from './styles'
import ReactSelect from 'react-select';
import axiosInstance from '../../../environment/axiosInstance'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function UpdateHoliday(props: any) {
    const [holidaysData, setHolidaysData] = React.useState<any>([])
    const history = useHistory()
    const holidayRepeatOptions = [
        {
            id: 1,
            label: 'Monthly'
        },
        {
            id: 2,
            label: 'Yearly'
        }

    ]
    const holidayStatusOptions = [
        {
            id: true,
            label: 'Active'
        },
        {
            id: false,
            label: 'InActive'
        },
    ]
    const [holidayId, setHolidayId] = React.useState<any>()

    const [data, setData] = React.useState<any>({
        "name": "",
        "date": "",
        "repeat": 1,
        "status": false
    })

    const repeatIndex = holidayRepeatOptions.findIndex((item: any) => {
        if (item.id === data.repeat) {
            return item
        }
    })
    const holidayIndex = holidayStatusOptions.findIndex((item: any) => {
        if (item.id === data.status) {
            return item
        }
    })
    const classes = useStyles()

    React.useEffect(() => {
        const obj = props.data
        // console.log(obj)
        setData((prev: any) => {
            return {
                ...prev,
                name: obj?.name,
                date: obj?.date,
                repeat: obj?.repeat,
                status: obj?.status
            }
        })
        setHolidayId(obj._id)
    }, [])


    const onChangeTextfield = (e: any) => {
        setData((prev: any) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
        
    }
    const onChangeRepeat = (e: any) => {
        console.log(e.id)
        const value = e.id
        setData((prev: any) => {
            return {
                ...prev,
                repeat: value
            }
        })
    }
    const onChangeStatus = (e: any) => {
        console.log(e.id)
        const value = e.id
        setData((prev: any) => {
            return {
                ...prev,
                status: value
            }
        })
    }
    const AddHolidaysData = async () => {
        const id = props.departmentId
        await axiosInstance.patch(`/departments/${id}/holidays/${holidayId}`, data).then((response: any) => {
            setHolidaysData(response.data.Data)
            // console.log("response", response)
            if (response.status === 200) {
                props.handleupdateModal()
                props.fetchHolidays()
                // toast.success(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     style: {
                //         borderRadius: "2px",
                //         border: "1px solid red"
                //     }, autoClose: 1000
                // })
                // history.push(`/departments/${props.departmentId}/holidays`)
            }
        }).catch((response: any) => {

            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid red"
                }, autoClose: 1000
            })
        })


    }
    // console.log('data',data)
    return (
        <div className={classes.mWdth} style={{}}>
            <Grid container spacing={4} style={{ marginTop: "20px" }}>
                <Grid item md={6} sm={6} xs={6}>
                    <Typography className={classes.departmentNameText}>Title</Typography>
                    <div className={classes.addnewCont}>
                        <TextField
                            size="small"
                            fullWidth
                            value={data.name}
                            name="name"
                            variant="outlined"
                            onChange={onChangeTextfield}
                        //error={!errors.newnumber.isValid}
                        />
                        {/* {
                    !errors.newnumber.isValid &&
                    <p className={classes.errorMessage}>{errors.newnumber.message}</p>
                } */}
                    </div>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <Typography className={classes.departmentNameText}>Date</Typography>
                    <div className={classes.addnewCont}>
                        <TextField
                            size="small"
                            type="date"
                            name="date"
                            fullWidth
                            variant="outlined"
                            placeholder="Holiday Date"
                            onChange={onChangeTextfield}
                            value={data.date}
                        // error={!errors.newName.isValid}
                        />
                        {/* {
                    !errors.newName.isValid &&
                    <p className={classes.errorMessage}>{errors.newName.message}</p>
                } */}

                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item md={6} sm={6} xs={6}>
                    <Typography className={classes.departmentNameText}>Repeat</Typography>
                    <div className={classes.addnewCont}>
                        <ReactSelect
                            fullWidth
                            displayEmpty
                            name="repeat"
                            options={holidayRepeatOptions}
                            // inputProps={{ "aria-label": "Without label" }}
                            onChange={onChangeRepeat}
                            value={holidayRepeatOptions[repeatIndex]}
                        />
                    </div>

                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <Typography className={classes.departmentNameText}>Status</Typography>
                    <div className={classes.addnewCont}>
                        <ReactSelect
                            fullWidth
                            displayEmpty
                            name="status"
                            options={holidayStatusOptions}
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={onChangeStatus}
                            value={holidayStatusOptions[holidayIndex]}
                        />
                    </div>


                    {/* <Switch
                className={classes.text}
                //checked={selectAlwaysOpen}
                color="primary"
                name="status"
                //onChange={alwaysOpenChange}

            /> */}
                </Grid>
            </Grid>

            <Divider className={classes.divider2} />
            <Grid container spacing={2} >
                <Grid item md={12} sm={12} xs={12}>
                    <div className={classes.bttns}>
                        <Button size="medium"
                            className={classes.SaveButton}
                            onClick={AddHolidaysData}
                        >
                            Update
                        </Button>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(UpdateHoliday)
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    usersorgroups:{
        fontSize:19,
        fontWeight:800
      },
    inboundCalls: {
        color: '#8392AB',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 10,
    },
    audioPlayers: {
        width: '597px ! important',
        height: '44px',
        backgroundColor: "#E9ECED ! important",
        border: '1px solid #F0F3F5',
        borderRadius: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '240px ! important',
            height: '34px'
        }
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        justifyContent: 'flex-start',
        // zIndex: 20
    },
    heading: {
        color: "#2645ad",
        fontSize: 18,
        fontWeight: 600,
        letterSpacing: 0.208955,
        textTransform: 'none',
    },
    headingOne: {
        color: "#464646",
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0.208955,
        textTransform: 'none',
    },
    paper: {
        width: '275px',
        padding: '14px',
        borderRadius: 10,
        boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.14)',
    },
    buttondisplaytype: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    iconButton: {
        background: '#F3F5FF',
        border: '1px solid #e1e1e1',
    },
    phoneCall: {
        color: '#4D72F8',
    },
    number: {
        textAlign: 'center',
        color: '#4D72F8',
        fontSize: 26,
        fontWeight: 600,
        marginLeft: '56px',
    },
    content: {
        fontSize: '15px',
        color: '#8392AB',
        display: 'flex',
        justifyContent: 'flex-end',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        marginRight: 10,
    },
    card: {
        margin: '30px 0px 30px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
    },
    divider: {
        marginTop: -10,
        backgroundColor: '#E2E2E2',
    },
    tableSpace: {
        marginTop: 10,
    },
    tablewidth: {
        // width: '100%',
        overflowX: 'hidden'
    },
    tableHead: {
        color: '#464646',
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontSize: 14,
    },
    divFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    tableBody: {
        color: '#939292',
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Work Sans',
        // borderSpacing: -1,
        // borderCollapse: "inherit"
    },
    pText: {
        textAlign: 'center', color: "#787878", fontWeight: 500, padding: '10px 2px'
    },
    button: {
        display: 'center',
        fontSize: 10,
        background: 'linear-gradient(180deg, #26AD2B 0%, #39E91C 100%)',
        color: 'white',
        borderRadius: 20,
        padding: 3,
        justifyContent: 'center',
        width: 80,
        fontWeight: 'bold',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        textTransform: 'capitalize',
    },
    callEnd2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)',
        border: '1px solid #EBEBEB',
        '&:active': {
            backgroundColor: '#D8D8D8',
        },
    },
    userIcon1: {
        width: 25,
        height: 25,
        color: '#FFFFFF',
    },
    people2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#12DC00',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#12DC00',
        },
        '&:active': {
            backgroundColor: '#12DC00',
        },
    },
    volumeIcon2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#0C2DD1',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#0C2DD1',
        },
    },
    recordVoice2: {
        borderWidth: 1,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        background: '#DE00BB',
        border: '1px solid #EBEBEB',
        '&:hover': {
            background: '#DE00BB',
        },
    },
    ringing: {
        display: 'center',
        fontSize: 10,
        background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)',
        color: 'white',
        borderRadius: 20,
        padding: 3,
        justifyContent: 'center',
        width: 80,
        fontWeight: 'bold',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        textTransform: 'capitalize',
    },
    inQueue: {
        display: 'center',
        fontSize: 10,
        background: 'linear-gradient(180deg, #268DAD 0%, #1C62E9 100%)',
        color: 'white',
        borderRadius: 20,
        padding: 3,
        justifyContent: 'center',
        width: 80,
        fontWeight: 'bold',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        textTransform: 'capitalize',
    },
    voiceMail: {
        display: 'center',
        fontSize: 10,
        background: '#00AC64',
        color: 'white',
        borderRadius: 20,
        padding: 3,
        justifyContent: 'center',
        width: 90,
        fontWeight: 'bold',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#00AC64',
        },
    },
    ldrWidth: {
        display: 'flex', margin: "25px 0px", marginLeft: "520px",
        "@media (max-width: 768px)":{
            marginLeft: "50%"
        }
    },
    welcome: {
        display: 'center',
        fontSize: 10,
        background: '#AB6A08',
        color: 'white',
        borderRadius: 20,
        padding: 3,
        justifyContent: 'center',
        width: 100,
        fontWeight: 'bold',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            background: '#AB6A08',
        },
    },
    pagination: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}))
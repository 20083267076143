import { authReducer } from './Reducers/AuthReducer'
import { globalPopupReducerData } from './Reducers/GlobalPopUpReducer'
import { userProfileDataFun, userHoursData, userDepartment } from './Reducers/userProfile'
import { departmentsReducerFunction } from './Reducers/DepartmentsReducer'
import { sidebarReducer } from './Reducers/SidebarReducer'
import {callingReducer} from './Reducers/CallingReducer'

const combineReducers = {
    authState: authReducer,
    errorState: globalPopupReducerData,
    profileData: userProfileDataFun,
    hoursData: userHoursData,
    userDepartment: userDepartment,
    departmentModule: departmentsReducerFunction,
    sidebarDepart: sidebarReducer,
    CallingData: callingReducer
}

export default combineReducers;
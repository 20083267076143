import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    modalCard: {
        margin: '10px 0px 10px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
    },
    userText: {
        margin: '10px 0px 10px 0px',
        fontSize: 18,
        fontFamily: 'Work Sans',
        textAlign: 'left',
        fontWeight: 500,
        color: '#8392AB',
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        justifyContent: 'flex-start',
    },
    submitButton: {
        height: 42,
        borderRadius: '0px 6px 6px 0',
        padding: '6px 22px',
        color: '#FFFFFF',
        backgroundColor: '#4D72F8',
        borderColor: '#4D72F8',
        marginLeft: '-4px',
        '&:hover': {
            backgroundColor: '#4D72F8',
        },
        '&:active': {
            backgroundColor: '#4D72F8',
        },
    },
    SaveButton: {
        background: '#4D72F8',
        border: '1px solid #ccc',
        borderRadius: 5,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 15,
        padding: '6px 22px',
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
        },
    },
    bttns: { display:'flex',flexDirection:'row',justifyContent:'center', marginTop: 10 },
    cancelButton: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        borderRadius: 5,
        color: '#8392AB',
        fontSize: 14,
        padding: '6px 22px',
    },
}))
import { Button, Divider, Typography } from '@material-ui/core'
import React, { useRef, } from 'react';
import { useStyles } from '../style'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import axiosInstance from '../../../environment/axiosInstance'
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import '../style.css'

function CreateGroup(props: any) {
    const classes = useStyles()
    const { searchResult } = props;
    const chatBodyRef = useRef(null)
    const [search, setSearch] = React.useState('')
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [groupData, setGroupData] = React.useState({
        name: props.groupName,
        groupMembers: []
    })

    // console.log('name',props.groupName);


    const onSubmit = () => {
        axiosInstance.post('/chat/group-conversation', groupData).then((res: any) => {
            if (res.status === 200) {
                props.listConversations()
                props.handleContactsmodal()
                props.groupNameEmpty()
                // props.handlePopupAddGroup()
            }
        }).catch((err: any) => {

        })
    }

    const addUsersToList = (userData: any) => {
        let copyUserIds: any = [...selectedUsers]
        const checkUserALreadySelcted: any = copyUserIds.some((user: any) => user.efoneId === userData.efoneId)
        // console.log('checkUserALreadySelcted', userData, checkUserALreadySelcted)
        if (checkUserALreadySelcted) {
            return;
        }
        copyUserIds.push(userData)
        setSelectedUsers(copyUserIds)
        setGroupData((prev: any) => {
            return {
                ...prev,
                groupMembers: copyUserIds.map((user: any) => user.efoneId)
            }
        })
    }

    const handleDelete = (chipToDelete: any) => () => {
        let updatedArr = selectedUsers.filter((chip: any) => chip.efoneId !== chipToDelete.efoneId);
        setSelectedUsers(updatedArr);
        setGroupData((prev: any) => {
            return {
                ...prev,
                groupMembers: updatedArr.map((user: any) => user.efoneId)
            }
        })
    };
    // console.log('selectedUsers', selectedUsers, groupData)
    return (
        <>
            <div className={classes.rightCardbodyy} id="paper-card">
                <div>
                    <Paper
                        style={{ display: 'flex',alignItems:'center' }}
                    >
                        {selectedUsers.map((data: any) => {
                            let icon;
                            return (
                                <p key={data.key}>
                                    <Chip
                                        icon={icon}
                                        label={data.firstName}
                                        onDelete={handleDelete(data)}
                                    // className={classes.chip}
                                    />
                                </p>
                            );
                        })}
                    </Paper>
                </div>
                <div>
                    <input
                        type='search'
                        className='w-full px-12 py-2  text-gray-700 rounded-full outline-none'
                        placeholder='Search...'
                        // value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <Divider />
                <div id="scrollbarChat" ref={chatBodyRef} style={{ overflowY: 'auto', overflowX: 'hidden', height: '70vh', marginTop: 10 }}>
                    {
                        searchResult.filter((itm: any) => itm.firstName?.toLowerCase().includes(search.toLowerCase())).map((item: any) => {
                            const checkUserSelected: any = selectedUsers.some((user: any) => user.efoneId === item.efoneId)

                            return (
                                <div onClick={() => addUsersToList(item)}
                                    className={checkUserSelected ? `userSelected` : "notSelectedUser"}
                                    // style={{
                                    //     background: `${checkUserSelected ? '#4a5568' : ''}`,
                                    //     color: `${checkUserSelected ? 'white' : ''}`
                                    // }}
                                >
                                    <>
                                        <div className={classes.leftDivv}>
                                            <AccountCircleIcon style={{ fontSize: 30 }} />
                                            <div>
                                                {/* {checkUserSelected && 'selcted'} */}
                                                <Typography>{item?.firstName ? <p>{item?.firstName}</p> : <p>{item?.contactNumber}</p>}</Typography>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )
                        })
                    }
                </div>

                <Divider style={{ marginTop: 10 }} />


                {/* footer */}
                <div>
                    <form style={{marginTop:10,float:'right' }}

                    >
                        <Button size='small' className={classes.cancelBtn} onClick={props.handleContactsmodal}>Cancel</Button>
                        <Button onClick={onSubmit} size='small' className={classes.cancelBtn}>Create</Button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateGroup
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '30px 0px 30px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  select: {
    width: '100%',
  },
  gridItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  barCard: {
    padding: 5,
    border: '0px',
    borderRadius: '10px',
    boxShadow: '0 0 0.5em #E2E2E2',
    background: '#FFFFFF',
  },
  areaText: {
    marginLeft: 20,
    marginBottom: 5,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: '0.1px',
    textTransform: 'none',
  },
  outgoingText: {
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
    margin:'0px 20px 0px 10px',
  },
  incomingText: {
    fontSize: 13,
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#8392AB',
    margin:'0px 20px 0px 10px',
  },
}));

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CircularProgress, ClickAwayListener, Divider, Grid, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import AddDeskphone from './AddDeshphone'
import { useStyles } from './styles'
import landPhoneIcon from '../../../assets/icons/landPhone.png';

import { MdGroup } from 'react-icons/md';
import { AnyARecord } from 'dns';
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import ManageNumbers from './ManageNumbers/ManageNumbers';
import EditDeskphone from './EditDeskphone';
import axiosInstance from '../../../environment/axiosInstance'
import RoomPhomeTableBody from './RoomPhomeTableBody';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

function Deskphones() {
  const classes = useStyles();
  const [openModalDeskphone, setopenModalDeskphone] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openExpand, setOpenExpand] = React.useState(true);
  const anchorRef: any = React.useRef(null);
  const [value, setValue] = React.useState<any>('1');
  const [deskName, setDeskName] = React.useState<any>('')
  const [editDeskPhone, setEditDeskPhone] = React.useState(false)
  const tableHeader = ['Name', 'phoneNumber', 'phoneName', 'Mac Address', 'staus',];
  const [loading, setLoading] = React.useState(false);
  const [roomPhoneData, setRoomPhoneData] = React.useState([]);
  const [search, setSearch] = React.useState('')

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };


  const handlePopupModalDeskphone = () => {
    setopenModalDeskphone(!openModalDeskphone);
  };
  const expandOpen = () => {
    setOpenExpand(!openExpand)
  }
  const handleDeskphoneName = (e: any) => {
    e.preventDefault();
    setDeskName(e.target.value)
    setSearch(e.target.value)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const expanding = () => {
    setOpen(!open)
  }




  const handlePopupModalEditdeskphone = () => {
    setEditDeskPhone(!editDeskPhone)
  }



  //api integration
  const fetchRoomPhones = () => {
    setLoading(true)
    axiosInstance.get('/deskphone/roomPhone').then((res: any) => {
      // console.log('desk', res)
      if (res.status === 200) {
        setLoading(false)
        setRoomPhoneData(res.data.data)
      }

    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    fetchRoomPhones()
  }, [])


  return (
    <>
      {
        openModalDeskphone && (
          <div>
            <Backdrop handlePopupModal={handlePopupModalDeskphone} />
            <Modal title="Add Room Phones" handlePopupModal={handlePopupModalDeskphone}>
              <div  style={{ height: 750}}>
                <AddDeskphone handlePopupModal={handlePopupModalDeskphone} fetchRoomPhones={fetchRoomPhones} />
                {/* <AddDeskphone handlePopupModal={handlePopupModalDeskphone} fetchDeskphoneList={fetchDeskphoneList}
                  state={state} > */}
              </div>
            </Modal>
          </div>
        )
      }



      <div>
        <Card className={classes.card}>
          <Accordion expanded={openExpand}
            onChange={expandOpen}>
            <AccordionSummary
              expandIcon={openExpand ? <FiMinusCircle color="#2645ad" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Deskphone</Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
              <Card className={classes.roomCard}>
                <img className={classes.landIcon} src={landPhoneIcon} alt='land' />
                <Typography className={classes.phonesText}>
                  Room Phones
                  are perfect for shared company specs susch as conference room , reception area,
                  or phone booths.They are associated with a user account
                  or phone booths.They are associated with a user account
                </Typography>
              </Card>
              <Card className={classes.roomCard} style={{ marginTop: 20 }}>
                <MdGroup className={classes.mdGroup} />
                <Typography className={classes.phonesText}>
                  Users phone
                  are desktop phones assigned to one of your team members When their Dialpad number is
                  is called, that user phone will ring.
                </Typography>
              </Card>

              <Card className={classes.newcard}>
                <CardContent>
                  <TabContext value={value}>
                    <TabList
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label='basic tabs example'
                    >
                      <Tab label="Room Phone" value="1" />
                      {/* <Tab label="Room Phone" value="2" /> */}
                    </TabList>
                    <Divider className={classes.divider} />
                    <TabPanel value='1'>
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <TextField
                            size='small'
                            fullWidth
                            placeholder='search'
                            value={search}
                            name='search'
                            //onChange={(e: any) => setSearch(e.target.value)}
                            onChange={handleDeskphoneName}
                            variant='outlined'

                          />
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={6}>
                          <Button className={classes.gridItem}
                            size="small"
                            variant='outlined'
                            color='primary'
                            onClick={handlePopupModalDeskphone}
                          >
                            Add Room Phone
                          </Button>
                        </Grid>
                      </Grid>
                      <Card className={classes.cardStyles}>
                        <TableContainer>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableName}>Name</TableCell>
                                <TableCell className={classes.tableName}>Outbound Caller Number</TableCell>
                                <TableCell className={classes.tableName}>Phone Number</TableCell>
                                <TableCell className={classes.tableName}>Mac Address</TableCell>
                                <TableCell className={classes.tableName}>Last Login</TableCell>
                                <TableCell className={classes.tableName}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loading ?
                                <Box>
                                  <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                                    <CircularProgress />
                                  </div>
                                </Box> :
                                roomPhoneData.length > 0 && (
                                  roomPhoneData.filter((itm: any) => itm.name.toLowerCase().includes(search.toLowerCase()))
                                    .map((roomData: any, i: any) => <RoomPhomeTableBody
                                      roomData={roomData}
                                      roomPhoneData={roomPhoneData}
                                      key={i}
                                      fetchRoomPhones={fetchRoomPhones}
                                    />
                                    )
                                )
                              }
                            </TableBody>
                          </Table>
                          {
                            roomPhoneData?.length <= 0 && <p style={{ textAlign: 'center', padding: 10 }}>No users found</p>
                          }
                        </TableContainer>
                      </Card>
                    </TabPanel>
                  </TabContext>
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        </Card>
      </div>
    </>
  )
}

export default Deskphones
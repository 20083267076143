import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { withStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, ButtonBase, Grid, IconButton, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import axiosInstance from '../../environment/axiosInstance'
import './header.css'
import Select from 'react-select';
import JsSIP from 'jssip'

import { MdDialpad } from 'react-icons/md'
import { useHistory } from 'react-router';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import profileimg from '../../assets/images/image 1.png'
import sortDown from '../../assets/images/Sort Down.png'
import Backdrop from '../../components/Modal/Backdrop';
import Modall from "../../components/Modal/Modal";
import DepartmentServices from '../Departments/DepartmentServices/DepartmentServices'
import * as DepartmentActionCreators from '../../store/Actions/DepartmentsActionXCreators'
import * as CompanyActionsCreator from '../../store/Actions/CompanyActionsCreator'

import { Link } from 'react-router-dom'
import { HiOutlineLogout } from 'react-icons/hi'
import * as userAuthActionCreators from '../../store/Actions/AuthActionCreator'
import { useStyles } from './styles'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../../components/Modal/Modal';
import { FaPhoneAlt } from 'react-icons/fa';
import { AiFillCaretDown } from 'react-icons/ai';
import DialPad from './DialPad/DialPad';
import { GiHamburgerMenu } from 'react-icons/gi';
toast.configure()

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: 'rgb(77, 114, 248);',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);



const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    },
})((props: any) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? 'red' : 'blue',
        border: 0,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

function NewHeader(props: any) {
    const classes = useStyles()
    const userStateData = useSelector((state: any) => state.authState)
    const history = useHistory()
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [addcompanyName, setAddCompanyName] = React.useState({
        name: '',
        cid: ''
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newCompanyName, setNewCompanyName] = React.useState({
        name: ''
    });
    // const [status, setStatus] = React.useState(false)
    const [calling, setCalling] = React.useState(false)
    const [addCompanyModal, setAddCompanyModal] = React.useState(false);
    const [clientList, setClientList] = React.useState<any>([]);
    const [loading, setloading] = React.useState(false);
    const dispatch = useDispatch();
    const [openModal, setModalOpen] = React.useState(false)
    const profileDataStore: any = useSelector((state: any) => state.profileData);
    const { profileData } = profileDataStore;
    const [callModal, setCallModal] = React.useState(false)
    const [number, setNumber] = React.useState('')
    const [data, setData] = React.useState({ phoneNumber: '' })

    const departmentStateData = useSelector((state: any) => state.departmentModule);
    const { companyData , selectedCompany} = departmentStateData;


    const cdata = companyData
    var List: any = [];
    cdata?.map((item: any) => {
        var object = {
            "value": item._id,
            "label": item.name
        }
        List.push(object)
    })
    // setClientList(List)

    // console.log("clientList",List)

    const handleAddCompanyPopupModal = () => {
        setAnchorEl(null);
        setNewCompanyName({ name: '' });
        setAddCompanyModal(!addCompanyModal);
    }

    const handleOpenModal = () => {
        setModalOpen(!openModal);


    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getcompanyList = React.useCallback(() => {
        setloading(true);
        axiosInstance.get('/company').then((res: any) => {
            if (res.data.data) {
                const data = res.data.data
                dispatch(CompanyActionsCreator.fetchCompanySuccess(data))
            } else {
                setloading(false)
            }
        }).catch((err: any) => {
            setloading(false)
        })
    }, [])


    const fetchDepartments = () => {
        DepartmentServices.getDepartments().then((res) => {
            if (res.success) {
                const departments: any = res.data.data;
                dispatch(DepartmentActionCreators.fetchDepartmentsSuccess(departments))
                // if (departments.length > 0) {
                //   let department = departments[0];
                //   DepartmentServices.getDepartmentsData(department.id).then((ress) => {
                //     if (ress.success) {
                //       setExpanded(true);
                //       dispatch(DepartmentActionCreators.onChangeDepartments(ress.data.data))
                //     } else {
                //       //err
                //     }
                //   }).catch((err) => {
                //     //err
                //   })

                // }

            } else {
                dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
            }
        }).catch((err) => {
            dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
        })
    }

    const getalloffices = () =>{
        fetchDepartments()
    }
    React.useEffect(() => {
        getcompanyList()
        fetchDepartments()
    }, [])

    const onSubmitAddCompany = () => {
        axiosInstance.post('/company', newCompanyName).then((res: any) => {
            if (res.data) {
                getcompanyList();
                handleAddCompanyPopupModal()
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                }
                )
            } else {
            }
        })
            .catch((err: any) => {
                toast.error("Error", { position: toast.POSITION.TOP_RIGHT })
                // send error
            });
    };

    const getCompanyDepartments = async (id: any) => {
        await axiosInstance.get('/company/' + id + '/departments').then((res: any) => {
            if (res.status === 200) {
                const departments: any = res.data.data;
                // console.log("selected company departments",departments)
                dispatch(DepartmentActionCreators.fetchDepartmentsSuccess(departments))
                // history.push(`/company/:${id}/dashboard`)
            } else {
                dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
            }
        }).catch((err) => {
            dispatch(DepartmentActionCreators.fetchDepartmentsLoding(false))
        })
    };

    const onContentClientChangeEvent = (e: any) => {
        dispatch(CompanyActionsCreator.setSelctedCompany(e))
        // console.log(e)
        setAddCompanyName((prev: any) => {
            return {
                ...addcompanyName,
                name: e.label,
                cid: e.value
            }
        })
        getCompanyDepartments(e.value);
        setAnchorEl(null);
        localStorage.setItem("SelectedCompanyId", e.value);
        localStorage.setItem("SelectedCompanyName", e.label);
    }

    React.useEffect(() => {
        const cId = localStorage.getItem("SelectedCompanyId")
        const cName = localStorage.getItem("SelectedCompanyName")
        if (cId === null) {
            setAddCompanyName((prev: any) => {
                return {
                    ...prev,
                    name: '',
                    cid: ''
                }
            })
            fetchDepartments()
        } else {
            setAddCompanyName((prev: any) => {
                return {
                    ...prev,
                    name: cName,
                    cid: cId
                }
            })
            getCompanyDepartments(cId);
        }
    }, [])
    const [options] = React.useState<any>([
        { value: 1, label: 'usdofsol' },
        { value: 2, label: 'usdofsol' },

    ]);

    var userData: any = localStorage.getItem('user_data')

    if (JSON.parse(userData).tokenData === null || JSON.parse(userData).tokenData === undefined) {
        history.push('/');
    }

    const handleDialerClick = () => {

        if (JSON.parse(userData).tokenData === null || JSON.parse(userData).tokenData === undefined) {
            history.push('/')

        } else {
            var tokenData = JSON.parse(userData).tokenData.refresh
            var token = tokenData.token
            window.open(`https://wphone.efone.ca/?token=${token}`, "_blank")
            // console.log("---process.env.REACT_APP_OPEN_DAILER", process.env.REACT_APP_OPEN_DAILER)

        }
        // window.location.href = `http://134.122.36.172:7784/?token=${token}`
        // window.open(`http://134.122.36.172:7784?token=${token}`, "_blank");
        // window.open(`https://wphone.efone.ca/?token=${token}`, "_blank")
        // let openDailer = process.env.REACT_APP_OPEN_DAILER
        // window.open( openDailer +`?token=${token}`, "_blank");
    }

    const onChangeCompany = (e: any) => {
        setNewCompanyName((prev: any) => {
            return {
                ...prev,
                name: e.target.value
            }
        })
    }

    const handlePopupModalNumber = () => {
        setDeleteModal(!deleteModal)
    }

    const handleLogout = (e: any) => {
        e.preventDefault()
        dispatch(userAuthActionCreators.initAuthLogout())
        history.push('/')
        props.handleLogout()
        localStorage.removeItem("SelectedCompanyId")
        localStorage.removeItem("SelectedCompanyName")
    }

    //jssip

    const handleOpen1Modal = (child: any) => {
        setModalOpen(!openModal);
        setCalling(child)
        if (child) {
            setCallModal(true)
        }

    };

    const parentCall = (child: any) => {
        setData(child)
        setNumber(child.phoneNumber)
        // console.log("----------numbetr")
        // dispatch(CallActionCreator.fetchStatus(data))
        // dispatch(CallActionCreator.fetchData(true))
    }
    const parentCalling = (child: any) => {
        setCalling(child)
    }

    const status = props.socket

    return (
        <>
            {
                openModal && (
                    <div>
                        {/* <Backdrop handlePopupModal={handleOpenModal} /> */}
                        <Modal title="Dialpad" handlePopupModal={handleOpenModal} >
                            <div className="add_user_popup" style={{ width: "700px" }}>
                                <DialPad
                                    modalClose={handleOpen1Modal}
                                    callOptions={props.callOptions}
                                    parentCall={parentCall}
                                    coolPhone={props.coolPhone}
                                    openModal={openModal}
                                    parentCalling={parentCalling}
                                />
                                {/* <Testing CallTesting={CallTesting} /> */}
                            </div>

                        </Modal>

                    </div>
                )
            }
            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalNumber} />
                        <Modal title="Are you sure you want to logout?" handlePopupModal={handlePopupModalNumber} >
                            <Grid container spacing={2} style={{ margin: '15px 0px 6px', width: '500px', textAlign: 'center', }}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div>
                                        <Button size="medium" style={{
                                            backgroundColor: "#4D72F8",
                                            border: '1px solid #AEAEAE',
                                            boxSizing: 'border-box',
                                            borderRadius: 4,
                                            fontFamily: 'Work Sans',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            padding: '6px 22px',
                                            color: '#FFFFFF',
                                            fontSize: 14
                                        }}
                                            onClick={handleLogout}>
                                            Logout</Button>
                                        <Button size="medium" onClick={handlePopupModalNumber}
                                            style={{
                                                marginLeft: 20,
                                                padding: '6px 22px',
                                                fontFamily: 'Work Sans',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                backgroundColor: '#EFEFEF',
                                                border: '1px solid #AEAEAE',
                                                boxSizing: 'border-box',
                                                borderRadius: 4,
                                                color: '#787878',
                                                fontSize: 14
                                            }}
                                        //className={classes.cancelLogButton}
                                        >Cancel</Button>
                                    </div>
                                </Grid>

                            </Grid>
                        </Modal>
                    </div>
                )
            }
            {
                addCompanyModal && (
                    <div>
                        <Backdrop handlePopupModal={handleAddCompanyPopupModal} />
                        <Modall title="Add Company Name" handlePopupModal={handleAddCompanyPopupModal}>
                            <div className="add_user_popup" style={{ width: "500px", padding: 10 }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                            <Typography style={{ color: '#000000' }}>Company Name</Typography>
                                            {/* <input type="text" name="name" value={addcompanyName.name}  onChange={onChangeCompanyHandlr}/> */}
                                            <TextField
                                                variant="outlined"
                                                placeholder='Enter Company Name'
                                                size='small'
                                                value={newCompanyName?.name}
                                                name="name"
                                                onChange={onChangeCompany}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: 20 }}>
                                            <Button
                                                variant="outlined"
                                                size="small" onClick={onSubmitAddCompany}
                                                style={{ background: '#4D72F8', color: 'white', textTransform: 'capitalize' }}
                                            >
                                                Add
                                            </Button>
                                            <Button
                                                style={{ background: '#FFFFFF', color: '#4D72F8', textTransform: 'capitalize' }}
                                                variant="outlined"
                                                size="small"
                                                onClick={handleAddCompanyPopupModal}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modall>
                    </div>
                )
            }

            <div className='header_container_section'>
                <div className='header_menus'>
                    <div onClick={props.handleSidebarPopupModal}>
                        <GiHamburgerMenu className='menu_button' />
                    </div>
                    <div
                        className="select_bar_section"
                        onClick={handleClick}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
                    >
                        <div className="menubar_profile_name" >
                            <p className="user_name">
                                {addcompanyName.name === '' ? "Choose Office" : addcompanyName.name}
                            </p>
                        </div>
                        <div className="menubar_arrows">
                            {/* {
                                anchorEl !== null ? (
                                    <TiArrowSortedUp size={20} />
                                ) : (
                                    <TiArrowSortedDown size={20} />
                                )
                            } */}
                            <img src={sortDown} alt="company select" />
                        </div>
                    </div>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}

                    >
                        <StyledMenuItem>
                            <ListItemText primary="All Offices" onClick={getalloffices} />
                        </StyledMenuItem>
                        {
                            List?.map((item: any, i: any) => {
                                const active = item.label === addcompanyName.name

                                return <StyledMenuItem key={i} onClick={() => onContentClientChangeEvent(item)}>
                                    <Link to={`/company/${item.value}/dashboard`} style={{ textDecoration: 'none' }}
                                        className={active ? 'activeCompany' : 'inActiveCompany'}>
                                        <ListItemText >{item.label}</ListItemText>
                                    </Link>

                                </StyledMenuItem>
                            })
                        }
                    </StyledMenu>

                </div>

                <div className='user_details'>
                    <div>
                        <Button
                            size="small"
                            variant='outlined'
                            style={{
                                marginRight: '15px',
                                // width: 30, 
                                height: 30,
                                display: 'grid',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10%',
                                backgroundColor: '#F3F5FF',
                                fontFamily: 'Work Sans',
                                color: '#2645AD',
                            }}
                            //className={classes.makecallbutton}
                            startIcon={<FaPhoneAlt />}
                            //endIcon={<AiFillCaretDown />}
                            onClick={handleOpenModal}

                        ></Button>
                    </div>
                    <div style={{ cursor: 'pointer' }}>

                        {props.status === "Connected" ?
                            <Tooltip title={props.status}>
                                <div><Badge variant='dot' color="success" /></div>
                            </Tooltip>

                            :
                            props.status === "Registering" ?
                                <Tooltip title={props.status}>
                                    <div><Badge variant='dot' color='warning' /></div>
                                </Tooltip>
                                :
                                <Tooltip title={props.status}>
                                    <div><Badge variant='dot' color="error" /></div>
                                </Tooltip>
                        }

                    </div>
                    {/* <div style={{ width: "50%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}> */}
                    {/* <div>
                            {userStateData?.success?.userData?.userType === 2 || userStateData?.success?.userData?.userType === 3 ?
                                <div className="dialer_pad_section" onClick={handleDialerClick}>
                                    <div className="dailer_cion">
                                        <MdDialpad size={15} color="white" />
                                    </div>
                                    <div style={{ padding: '5px', paddingLeft: '12px' }}>
                                        <Typography style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 700, fontSize: 12, lineHeight: 14, color: '#4D72F8' }}>Open Dialer</Typography>
                                    </div>

                                </div>
                                : ""
                            }
                        </div> */}
                    {/* <div style={{ marginLeft:20,display: 'flex', gap: 10 }}>
                            <SettingsIcon height='24px' width='24px' style={{ color: '#787878' }} />
                            <Badge badgeContent={4} color="primary">
                                <NotificationsNoneIcon height='24px' width='24px' style={{ color: '#787878' }} />
                            </Badge>
                        </div> */}
                    {/* </div> */}
                    <div style={{ display: 'flex', flexDirection: 'row' }} className='profile_container'>
                        {/* <Avatar
                            alt="profile picture"
                            src={profileimg}
                            sx={{ width: 40, height: 40 }}
                        /> */}
                        <div
                            style={{ width: 35, height: 35, backgroundColor: 'blueviolet', borderRadius: "50%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <p style={{ fontSize: 18, color: 'white' }}>{profileData?.firstName?.slice(0, 1)}{profileData?.lastName?.slice(0, 1)}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                            <Typography style={{ fontWeight: 700, fontFamily: 'Work Sans', fontStyle: 'normal', fontSize: "14px", lineHeight: "24px", letterSpacing: "0.208955px", color: "#787878" }}>{profileData?.firstName} {profileData?.lastName}</Typography>
                            <Typography style={{ fontWeight: 400, fontFamily: 'Inter', fontStyle: 'normal', fontSize: "12px", lineHeight: "15px", color: "#787878" }}>{profileData?.email}</Typography>
                        </div>
                    </div>
                    <div style={{ marginRight: 10, marginTop: 10, fontSize: 20, cursor: 'pointer' }} onClick={handlePopupModalNumber}>
                        <HiOutlineLogout style={{}} />
                    </div>
                </div>
            </div>
        </>

    )
}

export default NewHeader
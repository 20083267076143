import { Button, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Divider, Grid, IconButton, Accordion, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
// import Pagination from '@material-ui/lab/Pagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useStyles } from '../styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { BsArrow90DegDown, BsArrow90DegUp } from 'react-icons/bs'
import ReactSelect from 'react-select';
import Service from '../HistoryServices/Services'
import Moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation, useParams } from 'react-router-dom';
import { AiFillInfoCircle, AiFillPlayCircle } from 'react-icons/ai';
import AllCalls from '../../Dashboard/Analytics/AllCalls/AllCalls';
import { FaEye } from "react-icons/fa";
import VoicemailIcon from '@mui/icons-material/Voicemail';
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import DateRangePicker from 'rsuite/DateRangePicker';
import axiosInstance from '../../../environment/axiosInstance';
import DepartmentServices from '../../Departments/DepartmentServices/DepartmentServices';
import UserServices from '../../UsersDeskphones/Users/userServices';


function AllReports(props: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [userId, setUserId] = React.useState<any>();
    const [type, setype] = React.useState<any>(1)
    const [loading, setLoading] = React.useState(false);
    const [modalloading, setModalLoading] = React.useState(false);
    const [recordingModal, setRecordingModal] = React.useState(false);
    const history = useHistory();
    const [reports, setReports] = React.useState<any>([])
    var d = new Date();
    d.setDate(d.getDate());
    const [startDate, setStartDate] = React.useState<any>(new Date());
    const [endDate, setEndDate] = React.useState<any>(new Date());
    // const [startDate, setStartDate] = React.useState<any>("");
    // const [endDate, setEndDate] = React.useState<any>("");
    const [departments, setDepartments] = React.useState<any>([]);
    const [users, setUsers] = React.useState<any>([]);
    const [roomphones, setRoomphones] = React.useState<any>([]);
    const [reportsViewData, setReportsViewData] = React.useState<any>([]);
    const [departmentId, setDepartmentId] = React.useState<any>("");
    const [callDirection, setCallDirection] = React.useState<any>("");
    const [viewModal, setViewEyeModal] = React.useState<any>(false);
    const [agentId, setAgentId] = React.useState<any>("");
    const [roomPhoneId, setRoomPhoneId] = React.useState<any>("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)

    let coutomFilters: any = '';
    if (queryParams.get('departmentId')) {
        coutomFilters += '&departmentId=' + queryParams.get('departmentId');
    }
    if (queryParams.get('type')) {
        coutomFilters += '&type=' + queryParams.get('type');
    }
    if (queryParams.get('callDirection')) {
        coutomFilters += "&callDirection=" + queryParams.get('callDirection');
    }
    if (queryParams.get('connected')) {
        coutomFilters += "&connected=" + queryParams.get('connected');
    }
    if (queryParams.get('userId')) {
        coutomFilters += "&userId=" + queryParams.get('userId');
    }
    if (queryParams.get('roomPhoneId')) {
        coutomFilters += "&roomPhoneId=" + queryParams.get('roomPhoneId');
    }

    const [state, setState] = React.useState({
        page: 1,
        totalDocs: 0,
        limit: 25,
        totalPages: 0
    })


    const expandOpen = () => {
        setOpen(!open);
    };
    const keyOptions = [
        { label: "Today", value: 1 },
        { label: "Yesterday", value: 2 },
        { label: "Last 7 days", value: 3 },
        { label: "Last 30 days", value: 4 },
    ]
    const handleOptions = (e: any) => {
        localStorage.setItem('dbReportsStats', e.value)
        setype(e.value)
        // getReports(state.page, state.limit)
    }
    const fetchDepartments = () => {
        DepartmentServices.getDepartments().then((res) => {
            if (res.success) {
                const departments: any = res.data.data;
                setDepartments(departments);
            }
        }).catch((err) => {
        })
    }
    const fetchUsers = () => {
        UserServices.getAllUsers().then((res) => {
            if (res.success) {
                const users: any = res.data.data;
                setUsers(users);
            }
        }).catch((err) => {
        })
    }
    const fetchRoomphones = () => {
        axiosInstance.get('/deskphone/roomPhone').then((res: any) => {
            // console.log('desk', res)
            if (res.status === 200) {
                setLoading(false)
                setRoomphones(res.data.data)
            }

        }).catch((err: any) => {

        })
    }
    const getReports = (page: any, limit: any, startDate: any, endDate: any, agentId: any, departmentId: any, callDirection: any, roomPhoneId: any,type:any,connected:any,total:any,missed:any) => {
        setLoading(true);
        Service.getAllReports(page, limit, params.get("startDate") === null ? "" : startDate, params.get("endDate") === null ? "" : endDate, agentId, departmentId, callDirection, roomPhoneId,type,connected,total,missed).then((res) => {
            if (res.success) {
                setLoading(false);
                setReports(res.data.data)
                setState((prev) => {
                    return {
                        ...prev,
                        totalDocs: res.data.totalDocs,
                        page: res.data.page,
                        limit: res.data.limit,
                        totalPages: res.data.totalPages
                    }
                })
            }
            else {
                setLoading(false);
            }
        }).catch((err) => {
            setLoading(false);
        });
    }
    const handleChangePage = (event: any, value: any) => {
        let url = "?page=" + value;
        if (startDate) {
            url = url + "&startDate=" + convert(startDate) + "&endDate=" + convert(endDate);
        }
        if (agentId) {
            url = url + "&agentId=" + agentId;
        }
        if (departmentId) {
            url = url + "&departmentId=" + departmentId;
        }
        if (callDirection) {
            url = url + "&callDirection=" + callDirection;
        }
        if (roomPhoneId) {
            url = url + "&roomPhoneId=" + roomPhoneId;
        }
        history.push("/dashboard/reports" + url);
         getReportData()
        // setState((prev) => {
        //     return {
        //         ...prev,
        //         page: value
        //     }
        // });
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);

    React.useEffect(() => {
        getReportData()
        fetchDepartments();
        fetchUsers();
        fetchRoomphones();
    }, [search]);

    const getReportData = () => {
        let lType: any = 1;
        if (props.match?.path == '/dashboard/allreports') {
            if (queryParams.get('type')) {
                setype(queryParams.get('type'));
                lType = queryParams.get('type');
            }
        } else {
            //console.log('im here in else');
            if (localStorage.getItem('dbReportsStats')) {
                const getValues: any = localStorage.getItem('dbReportsStats');
                setype((getValues));
                lType = parseInt(getValues)
            }
        }

        let page: any = 1;
        if (params.get("page")) {
            page = params.get("page");
        }
        let start_date: any = startDate;
        if (params.get("startDate")) {
            const startDateObj = Moment(params.get("startDate")).toDate();
            setStartDate(startDateObj);
            start_date = params.get("startDate");
        }

        let end_date: any = endDate;
        if (params.get("endDate")) {
            const endDateObj = Moment(params.get("endDate")).toDate();
            setEndDate(endDateObj);
            // setEndDate(params.get("endDate"));
            end_date = params.get("endDate");
        }

        let type: any = "";
        if (params.get("type")) {
            setype(params.get("type"));
            type = params.get("type");
        }

        let agent_id: any = "";
        if (params.get("agentId")) {
            setAgentId(params.get("agentId"));
            agent_id = params.get("agentId");
        }

        let dept_id: any = "";
        if (params.get("departmentId")) {
            setDepartmentId(params.get("departmentId"));
            dept_id = params.get("departmentId");
        }

        let call_direction: any = "";
        if (params.get("callDirection")) {
            setCallDirection(params.get("callDirection"));
            call_direction = params.get("callDirection");
        }

        let room_phone_id: any = "";
        if (params.get("roomPhoneId")) {
            setRoomPhoneId(params.get("roomPhoneId"));
            room_phone_id = params.get("roomPhoneId");
        }
        let connected: any = "";
        if (params.get("connected")) {
            connected = params.get("connected");
        }
        let total: any = "";
        if (params.get("total")) {
            total = params.get("total");
        }
        let missed: any = "";
        if (params.get("missed")) {
            missed = params.get("missed");
        }
        getReports(page, state.limit, convert(start_date), convert(end_date), agent_id, dept_id, call_direction, room_phone_id,type,connected,total,missed);
    }


    // const reportsType: any = localStorage.getItem('dbReportsStats')
    // const rt = parseInt(reportsType)

    // React.useEffect(() => {
    //     if (props.match?.path == '/dashboard/allreports') {
    //         if (queryParams.get('type')) {
    //             setype(queryParams.get('type'));
    //         }
    //     }
    //     if (rt) {
    //         getReports(rt, state.page, state.limit)
    //         setype(rt)
    //     }
    //     if (!rt) {
    //         getReports(type, state.page, state.limit)
    //     }
    // }, [state.page, rt])



    function toTime(d: any) {
        d = Number(d);
        var h = (Math.floor(d / 3600)).toString().length > 1 ? Math.floor(d / 3600) : '0' + Math.floor(d / 3600);
        var m = (Math.floor(d % 3600 / 60)).toString().length > 1 ? Math.floor(d % 3600 / 60) : '0' + Math.floor(d % 3600 / 60);
        var s = (Math.floor(d % 3600 % 60)).toString().length > 1 ? Math.floor(d % 3600 % 60) : '0' + Math.floor(d % 3600 % 60);
        return h + ":" + m + ":" + s
    }

    //playaudio
    const [filePath, setFilePath] = React.useState("")
    const handlechangeRecModal = (path: any) => {
        setRecordingModal(!recordingModal)
        // setRecording(rec)
        setFilePath(path)
    }

    const handlechangeViewModal = (id: any) => {
        setModalLoading(true)
        setViewEyeModal(!viewModal)
        axiosInstance.get(`/report/callCCbyUUID?uuid=${id}`).then((res: any) => {
            // console.log('desk', res)
            if (res.status === 200) {
                setModalLoading(false)
                setReportsViewData(res.data.data)
            }

        }).catch((err: any) => {

        })
    }

    const onDateRangeChange = (range: any) => {
        setStartDate(range[0]);
        setEndDate(range[1]);
    }

    function convert(str: any) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-") + "T" + (date.getHours().toString().length == 1 ? "0" + date.getHours().toString() : date.getHours().toString()) + ":" + (date.getMinutes().toString().length == 1 ? "0" + date.getMinutes().toString() : date.getMinutes().toString()) + ":" + (date.getSeconds().toString().length == 1 ? "0" + date.getSeconds().toString() : date.getSeconds().toString());
    }

    const handleDepartmentChange = (e: any) => {
        setDepartmentId(e.target.value);
    }

    const handleCallTypeChange = (e: any) => {
        setCallDirection(e.target.value);
    }

    const handleAgentChange = (e: any) => {
        setAgentId(e.target.value);
    }

    const handleRoomPhoneChange = (e: any) => {
        setRoomPhoneId(e.target.value);
    }

    const onSearchBtnClicked = () => {
        let url = "";
        if (params.get("page")) {
            url += "?page=" + params.get("page");
        }
        else {
            url +="?page=1";
        }

        if (startDate) {
            url += "&startDate=" + convert(startDate) + "&endDate=" + convert(endDate);
        }
        if (agentId) {
            url += "&agentId=" + agentId;
        }
        if (departmentId) {
            url += "&departmentId=" + departmentId;
        }
        if (callDirection) {
            url += "&callDirection=" + callDirection;
        }
        if (roomPhoneId) {
            url += "&roomPhoneId=" + roomPhoneId;
        }
        history.push("/dashboard/reports" + url);
        if(!search){
            getReportData();   
        }
       
    }
    console.log("vhfjvhfkjvfkj",startDate,endDate)

    return (
        <>
            {
                (props.location?.pathname == '/dashboard/allreports') &&
                <>
                    <AllCalls />
                </>
            }
            {
                recordingModal && (
                    <div style={{ width: "500px" }}>
                        <Backdrop handlePopupModal={handlechangeRecModal} />
                        <Modal
                            //title={recording ? "VoiceMail" : "Recording"}\
                            title="Play Audio"
                            handlePopupModal={handlechangeRecModal}

                        // breakLine={false}
                        >
                            <div style={{ width: "500px", height: "31%", padding: '10px' }}>
                                <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                                    <ReactAudioPlayer
                                        controls
                                        className={classes.audioPlayers}
                                        src={filePath}
                                    // playing={srcss}
                                    />
                                </Grid>
                            </div>
                        </Modal>
                    </div>
                )
            }
            {
                viewModal && (
                    <div style={{ width: "500px" }}>
                        <Backdrop handlePopupModal={handlechangeViewModal} />
                        <Modal
                            //title={recording ? "VoiceMail" : "Recording"}\
                            title="Reports Data"
                            handlePopupModal={handlechangeViewModal}

                        // breakLine={false}
                        >
                            <Grid item md={12} >
                                <TableContainer className={classes.tablewidth}>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{ background: '#fbfbfb' }}>
                                                <TableCell className={classes.tableHead}>Caller Name</TableCell>
                                                <TableCell className={classes.tableHead}>Caller Number</TableCell>
                                                <TableCell className={classes.tableHead}>Joined Time</TableCell>
                                                {/* <TableCell className={classes.tableHead}>Called Time</TableCell> */}
                                                <TableCell className={classes.tableHead}>Answered Time</TableCell>
                                                <TableCell className={classes.tableHead}>Agent</TableCell>
                                                <TableCell className={classes.tableHead}>Action</TableCell>
                                                <TableCell className={classes.tableHead}>Cause</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody style={{ border: '1px solid #E2E2E2' }}>
                                            {modalloading ?
                                                <Box>
                                                    <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                                                        <CircularProgress />
                                                    </div>
                                                </Box>
                                                :
                                                reportsViewData?.length > 0 && reportsViewData.map((item: any, i: any) => {

                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell className={classes.tableBody}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography>{item.callerName}</Typography>
                                                                </div>
                                                            </TableCell>
                                                            {item.callerNumber ?
                                                                <TableCell className={classes.tableBody}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.callerNumber}</Typography>
                                                                        {/* <Typography>{item.agentId && item.agentId.firstName}  {item.agentId && item.agentId.lastName}</Typography> */}
                                                                    </div>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            {item.joinedTime ?
                                                                <TableCell className={classes.tableBody}>{item?.joinedTime}</TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            {/* {item.calledTime ?
                                                                <TableCell className={classes.tableBody}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.calledTime}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            } */}
                                                            {item.answeredTime ?
                                                                <TableCell className={classes.tableBody}>{item?.answeredTime}</TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }

                                                            {
                                                                item.name ?
                                                                    <TableCell className={classes.tableBody}>{item?.name}</TableCell>
                                                                    :
                                                                    <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            {
                                                                item.action ?
                                                                    <TableCell className={classes.tableBody}>{item?.action}</TableCell>
                                                                    :
                                                                    <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            {
                                                                item.cause ?
                                                                    <TableCell className={classes.tableBody}>{item?.cause}</TableCell>
                                                                    :
                                                                    <TableCell className={classes.tableBody}>_</TableCell>
                                                            }

                                                        </TableRow>

                                                    )
                                                })}
                                        </TableBody>




                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Modal>
                    </div>
                )
            }
            <div id='reports'>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexDirection: 'row',
                        borderRadius: 1,
                        padding: '10px',
                        paddingBottom: '0px'
                    }}
                >
                    <DateRangePicker
                        format="yyyy-MM-dd HH:mm:ss"
                        value={startDate && endDate ? [new Date(startDate), new Date(endDate)] : null}
                        defaultCalendarValue={[new Date(startDate), new Date(endDate)]}
                        style={{
                            margin: "0px 10px"
                        }}
                        onChange={onDateRangeChange}
                    />
                    <FormControl
                        style={{
                            margin: "0px 10px"
                        }}
                        fullWidth>
                        <InputLabel id="department-select-label">--- Department ---</InputLabel>
                        <Select
                            labelId="department-select-label"
                            id="department-select"
                            value={departmentId}
                            defaultValue={departmentId}
                            label="Department"
                            onChange={handleDepartmentChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            {
                                departments?.map((department: any) => {
                                    return (
                                        <MenuItem value={department.id}>{department.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{
                            margin: "0px 10px"
                        }}
                        fullWidth>
                        <InputLabel id="call-type-select-label">--- Call Type ---</InputLabel>
                        <Select
                            labelId="call-type-select-label"
                            id="call-type-select"
                            value={callDirection}
                            defaultValue={callDirection}
                            label="Call Type"
                            onChange={handleCallTypeChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="1">Incoming</MenuItem>
                            <MenuItem value="2">Outgoing</MenuItem>
                            <MenuItem value="3">Missed Calls</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{
                            margin: "0px 10px"
                        }}
                        fullWidth>
                        <InputLabel id="agent-select-label">--- Agent ---</InputLabel>
                        <Select
                            labelId="agent-select-label"
                            id="agent-select"
                            value={agentId}
                            defaultValue={agentId}
                            label="Agent"
                            onChange={handleAgentChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            {
                                users?.map((user: any) => {
                                    return (
                                        <MenuItem value={user.id}>
                                            {
                                                user.email
                                            }
                                            {
                                                user.firstName ?
                                                    "(" + user.firstName + " " + user.lastName + ")"
                                                    :
                                                    ""
                                            }
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{
                            margin: "0px 10px"
                        }}
                        fullWidth>
                        <InputLabel id="room-phone-select-label">--- Room Phone ---</InputLabel>
                        <Select
                            labelId="room-phone-select-label"
                            id="room-phone-select"
                            value={roomPhoneId}
                            defaultValue={roomPhoneId}
                            label="Room Phone"
                            onChange={handleRoomPhoneChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            {
                                roomphones?.map((roomphone: any) => {
                                    return (
                                        <MenuItem value={roomphone._id}>{roomphone.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <IconButton
                        style={{
                            padding: "5px 7px",
                            background: "rgb(38, 69, 173)",
                            color: "white",
                            borderRadius: "5px"
                        }}
                        onClick={onSearchBtnClicked}
                        aria-label="search">
                        <SearchIcon style={{ fill: "white" }} />
                    </IconButton>
                </Box>
                <Card className={classes.card}>
                    <Accordion
                        expanded={open}>
                        <AccordionSummary
                            expandIcon={open ? <FiMinusCircle color="#2645ad" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container className={classes.grid}>
                                <Grid item xs={4} lg={4} md={4} sm={4} xl={4}>
                                    <Typography className={classes.heading}>Reports</Typography>
                                </Grid>
                                <Grid container xs={8} lg={8} md={8} sm={8} xl={8}>
                                    {/* <ReactSelect
                                        placeholder="Select key"
                                        options={keyOptions}
                                        onChange={handleOptions}
                                        value={keyOptions.filter((opt: any) => opt.value == type)}
                                    /> */}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider className={classes.divider} />
                        <CardContent className={classes.tableSpace} style={{ padding: "14px" }}>
                            <Typography className={classes.headingOne} style={{ marginTop: -15, marginBottom: 12, fontSize: 15 }}>Total Reports: {state.totalDocs}</Typography>
                            <Card>
                                <TableContainer className={classes.tablewidth}>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{ background: '#fbfbfb' }}>
                                                <TableCell className={classes.tableHead}>Department</TableCell>
                                                <TableCell className={classes.tableHead}>User</TableCell>
                                                <TableCell className={classes.tableHead}>Agent</TableCell>
                                                <TableCell className={classes.tableHead}>FromNumber</TableCell>
                                                <TableCell className={classes.tableHead}>ToNumber</TableCell>
                                                <TableCell className={classes.tableHead}>Room Phone</TableCell>
                                                <TableCell className={classes.tableHead}>Duration(Secs)</TableCell>
                                                <TableCell className={classes.tableHead}>Start Time</TableCell>
                                                <TableCell align={'center'} className={classes.tableHead}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ border: '1px solid #E2E2E2' }}>
                                            {loading ?
                                                <Box>
                                                    <div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
                                                        <CircularProgress />
                                                    </div>
                                                </Box>
                                                :
                                                reports?.length > 0 && reports.map((item: any, i: any) => {
                                                    var data = parseInt(item?.startStamp)
                                                    // var dataa = Moment(data).format('YYYY-MM-DD hh:m:s')

                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell className={classes.tableBody}>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                                    {item.callDirection === '1' ?
                                                                        <><BsArrow90DegDown color='blue' /></> :
                                                                        <><BsArrow90DegUp color='green' /></>
                                                                    }
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.deptName ? item.deptName : '_'}</Typography>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            {item.userId ?
                                                                <TableCell className={classes.tableBody}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.userName}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            {item.agentId ?
                                                                <TableCell className={classes.tableBody}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography>{item.agentEmail}</Typography>
                                                                        {/* <Typography>{item.agentId && item.agentId.firstName}  {item.agentId && item.agentId.lastName}</Typography> */}
                                                                    </div>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            <TableCell className={classes.tableBody}>{item?.callerNumber}</TableCell>
                                                            <TableCell className={classes.tableBody}>{item?.deptNumber}</TableCell>
                                                            {/* <TableCell className={classes.tableBody}>{item?.deptNumber}</TableCell> */}
                                                            {
                                                                item.roomPhoneName ?
                                                                    <TableCell className={classes.tableBody}>{item?.roomPhoneName}</TableCell>
                                                                    :
                                                                    <TableCell className={classes.tableBody}>_</TableCell>
                                                            }
                                                            <TableCell className={classes.tableBody}>{toTime(item?.duration)}</TableCell>
                                                            <TableCell className={classes.tableBody}>{item?.startStamp}</TableCell>
                                                            <TableCell className={classes.tableBody}>{item?.status}</TableCell>
                                                            <TableCell className={classes.tableBody}>

                                                                {/* <AiFillPlayCircle onClick={() => handlechangeRecModal(item.recordFile)}/> */}

                                                                <FaEye style={{ cursor: 'pointer' }} onClick={() => handlechangeViewModal(item?.uuid)} />

                                                            </TableCell>
                                                        </TableRow>

                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                    {
                                        reports?.length <= 0 &&
                                        <p className={classes.pText}>
                                            No Data found
                                        </p>
                                    }

                                </TableContainer>
                            </Card>
                            {reports?.length > 0 &&
                                <Stack spacing={2} style={{ marginTop: 15, float: "right", marginBottom: "20px" }}>
                                    <Pagination
                                        count={state.totalPages}
                                        //page={state.page} 
                                        onChange={handleChangePage}
                                        variant="outlined" color="secondary" />
                                </Stack>}
                        </CardContent>
                    </Accordion>
                </Card>
            </div>
        </>
    )
}
export default AllReports;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
  Box,
  CircularProgress,
  Popover,

} from '@material-ui/core';
import {
  Details,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import moment from "moment";
import dayjs from 'dayjs';
import DateRangePicker from "react-daterange-picker";
import 'react-daterange-picker/dist/css/react-calendar.css'; // Import CSS file
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ReactSelect from 'react-select';
import { useStyles } from './styles';
import "./style.css"
import ArrowIcon from '../../../assets/icons/arrow.png';
import Service from '../Service'
import { isYieldExpression } from 'typescript';
import axiosInstance from '../../../environment/axiosInstance'
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';
import { connected } from 'process';

export function DepartmentStats(props: any): JSX.Element {

  //   const Moment = require("moment");
  // const MomentRange = require("moment-range");
  // const moment = MomentRange.extendMoment(Moment);
  const classes = useStyles();
  const history = useHistory()
  const [alignment, setAlignment] = React.useState<string | null>('left');
  const [value, setValue] = React.useState(null);
  const [childRow, setChildRow] = React.useState(false);
  const [childOpenRow, setChildOpenRow] = React.useState(false);
  const [childCloseRow, setChildCloseRow] = React.useState(false);
  const [allData, setAllData] = React.useState<any>([])
  const [allUserData, setAllUserData] = React.useState<any>([])
  const [allSummary, setAllSummary] = React.useState<any>({})
  const [openSummary, setOpenSummary] = React.useState<any>({})
  const [closeSummary, setCloseSummary] = React.useState<any>({})
  const [openData, setOpenData] = React.useState<any>([])
  const [openUserData, setOpenUserData] = React.useState<any>([])
  const [closeData, setCloseData] = React.useState<any>([])
  const [closeUserData, setCloseUserData] = React.useState<any>([])
  const [tabVal, setTabVal] = React.useState("1")
  const [type, setype] = React.useState<any>(1)
  const [id, setId] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  // const [custom_startDate,setcustom_startDate]= React.useState<any>(null)
  // const [custom_endDate , setcustom_endDate]= React.useState<any>(null)
  const [openCustomPicker, setopenCustomPicker] = React.useState(false)
  const dateRangePickerRef = React.useRef<any>(null);
  var d = new Date();
  d.setDate(d.getDate() - 1);
  // const [startDate, setStartDate] = React.useState<any>((d.toISOString().split("T")[0]) + "T00:00:00");
  // const [endDate, setEndDate] = React.useState<any>((d.toISOString().split("T")[0]) + "T23:59:59");
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
  };

  const custom_startDate = dayjs('2024-05-01');
  const custom_endDate = dayjs('2024-05-10');
  const startDate = moment(custom_startDate.toDate());
  const endDate = moment(custom_endDate.toDate());
  const [dateRange, setDateRange] = React.useState<any>({ startDate, endDate, });

  // Handler for changing date range
  const handleSelect = (range: any) => {
    const startDate = range?.start?.format("YYYY-MM-DD");
    const endDate = range?.end?.format("YYYY-MM-DD");
    localStorage.setItem('dbDeptStatsStartDate', startDate);
    localStorage.setItem('dbDeptStatsEndDate', endDate);
    setDateRange(range);
  };

  const applyPayPeriod = () => {
    alldepartmentsStatsData(type)
  }

  // Convert Day.js objects to Moment.js objects

  const keyOptions = [
    { label: "Today", value: 1 },
    { label: "Yesterday", value: 2 },
    { label: "Last 7 days", value: 3 },
    { label: "Last 30 days", value: 4 },
    { label: "This month ", value: 5 },
    { label: "Last month", value: 6 },
    { label: "Custom", value: 7 },
  ]
  const anchorRef = React.useRef(null);
  const handleOptions = (e: any) => {

    localStorage.setItem('dbDeptStats', e.value)
    setype(e.value)
    if (e.value === 7) {
      setopenCustomPicker(true)
    }
    else {
      if (tabVal === "1") {
        alldepartmentsStatsData(e.value)
      }
      else if (tabVal === "2") {
        fetchopenHoursData(e.value, 1)
      }
      else if (tabVal === "3") {
        fetchCloseHoursData(e.value, 2)
      }
    }
  }



  const handleCustomPickerClose = () => {
    setopenCustomPicker(false);
  };

  const handleChildRow = (id: any) => {
    setId(id)
    setChildRow(!childRow)
    // alldepartmentsStatsData(type)
  }
  const handleOpenChildRow = (id: any) => {
    setId(id)
    setChildOpenRow(!childOpenRow)
    // fetchopenHoursData(type, 1)
  }
  const handleCloseChildRow = (id: any) => {
    setId(id)
    setChildCloseRow(!childCloseRow)
    // fetchCloseHoursData(type, 2)
    // getDepartmentUsercloseStats(type, 2, id)
  }

  //   const onDateRangeChange = (range: any) => {
  //     setStartDate(convert(range[0]));
  //     setEndDate(convert(range[1]));
  // }

  function convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-") + "T" + (date.getHours().toString().length == 1 ? "0" + date.getHours().toString() : date.getHours().toString()) + ":" + (date.getMinutes().toString().length == 1 ? "0" + date.getMinutes().toString() : date.getMinutes().toString()) + ":" + (date.getSeconds().toString().length == 1 ? "0" + date.getSeconds().toString() : date.getSeconds().toString());
  }


  //newapi integrations
  const handleTabValue = (val: any) => {
    setTabVal(val)
    if (val === "1") {
      alldepartmentsStatsData(type)
    }
    else if (val === "2") {
      fetchopenHoursData(type, 1)
    }
    else if (val === "3") {
      fetchCloseHoursData(type, 2)
    }
  }
  const handleChange = (value: any) => {
    setValue(value);
  };

  const _ = require("lodash");

  const officeId = localStorage.getItem('SelectedCompanyId')
  const [deptName, setDeptName] = React.useState<any>([])
  const [sortBy, setSortBy] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [isPerEnabled, setIsPerEnabled] = React.useState<any>(0)
  const [sortedDeptName, setSortedDeptName] = React.useState([]);
  const [openDepts, setOpenDepts] = React.useState([])
  const [clsDeptName, setClsDeptName] = React.useState([])
  const handlePercentageChange = (event: any) => {
    setIsPerEnabled(event.target.checked ? 1 : 0);

  }
  const handleSort = (column: any) => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(column);
  };

  React.useEffect(() => {
    if (tabVal === "1") {
      var sorted;
      if (sortBy === "outtotal") {
        sorted = _(deptName).orderBy((item: any) => item.outgoingDeptStats.total, sortOrder).value();
      } else if (sortBy === "outConnected") {
        sorted = _(deptName).orderBy((item: any) => item.outgoingDeptStats.connected, sortOrder).value();
      }
      else if (sortBy === "OutTotalOutDuration") {
        sorted = _(deptName).orderBy((item: any) => item.outgoingDeptStats.totalOutDuration, sortOrder).value();
      }
      else if (sortBy === "Intotal") {
        sorted = _(deptName).orderBy((item: any) => item.incomingDeptStats.total, sortOrder).value();
      }
      else if (sortBy === "InConnected") {
        sorted = _(deptName).orderBy((item: any) => item.incomingDeptStats.connected, sortOrder).value();
      }
      else if (sortBy === "InMissed") {
        sorted = _(deptName).orderBy((item: any) => item.incomingDeptStats.missed, sortOrder).value();
      } else {
        sorted = deptName
      }
      setSortedDeptName(sorted);
    } else if (tabVal === "2") {
      var sorted;
      if (sortBy === "outtotal") {
        sorted = _(openDepts).orderBy((item: any) => item.outgoingDeptStats.total, sortOrder).value();
      } else if (sortBy === "outConnected") {
        sorted = _(openDepts).orderBy((item: any) => item.outgoingDeptStats.connected, sortOrder).value();
      }
      else if (sortBy === "OutTotalOutDuration") {
        sorted = _(openDepts).orderBy((item: any) => item.outgoingDeptStats.totalOutDuration, sortOrder).value();
      }
      else if (sortBy === "Intotal") {
        sorted = _(openDepts).orderBy((item: any) => item.incomingDeptStats.total, sortOrder).value();
      }
      else if (sortBy === "InConnected") {
        sorted = _(openDepts).orderBy((item: any) => item.incomingDeptStats.connected, sortOrder).value();
      }
      else if (sortBy === "InMissed") {
        sorted = _(openDepts).orderBy((item: any) => item.incomingDeptStats.missed, sortOrder).value();
      } else {
        sorted = openDepts
      }
      setSortedDeptName(sorted);
    } else if (tabVal === "3") {
      var sorted;
      if (sortBy === "outtotal") {
        sorted = _(clsDeptName).orderBy((item: any) => item.outgoingDeptStats.total, sortOrder).value();
      } else if (sortBy === "outConnected") {
        sorted = _(clsDeptName).orderBy((item: any) => item.outgoingDeptStats.connected, sortOrder).value();
      }
      else if (sortBy === "OutTotalOutDuration") {
        sorted = _(clsDeptName).orderBy((item: any) => item.outgoingDeptStats.totalOutDuration, sortOrder).value();
      }
      else if (sortBy === "Intotal") {
        sorted = _(clsDeptName).orderBy((item: any) => item.incomingDeptStats.total, sortOrder).value();
      }
      else if (sortBy === "InConnected") {
        sorted = _(clsDeptName).orderBy((item: any) => item.incomingDeptStats.connected, sortOrder).value();
      }
      else if (sortBy === "InMissed") {
        sorted = _(clsDeptName).orderBy((item: any) => item.incomingDeptStats.missed, sortOrder).value();
      } else {
        sorted = clsDeptName
      }
      setSortedDeptName(sorted);
    }

  }, [deptName, sortBy, sortOrder, openDepts, clsDeptName]);



  React.useEffect(() => {
    if (tabVal === "1") {
      alldepartmentsStatsData(type)
    }
    else if (tabVal === "2") {
      fetchopenHoursData(type, 1)
    }
    else if (tabVal === "3") {
      fetchCloseHoursData(type, 2)
    }
  }, [isPerEnabled])

  const alldepartmentsStatsData = (type: any) => {
    setLoading(true);
    // Convert Day.js objects to Moment.js objects directly in the URL construction
    // const startDate = dateRange?.start?.format("YYYY-MM-DD");
    // const endDate = dateRange?.end?.format("YYYY-MM-DD");

    let url = `/dashboard/department-stats?type=${type}&isPerEnabled=${isPerEnabled === 0 ? 0 : 1}`;
  
    // if (dateRange.start && dateRange.end) {
    //   url += `&startDate=${startDate}&endDate=${endDate}`;
    // }
   if (type == 7 && localStorage.getItem("dbDeptStatsStartDate") && localStorage.getItem("dbDeptStatsEndDate")) {
      url += `${url}&startDate=${localStorage.getItem("dbDeptStatsStartDate")}&endDate=${localStorage.getItem("dbDeptStatsEndDate")}`;
    }

    if (officeId) {
      url += `&officeId=${officeId}`;
    }

    axiosInstance.get(url)
      .then((res: any) => {
        if (res.status === 200) {
          setLoading(false);
          const data = res.data.data;
          setAllSummary(res.data.summary);
          setDeptName(data);
          // const usersDataa = res.data.data[0].usersStats
          // setUsersData(usersDataa)
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };




  //openhours data



  const fetchopenHoursData = (type: any, val: any) => {
    setLoading(true)
 
    // const startDate = dateRange?.start?.format("YYYY-MM-DD");
    // const endDate = dateRange?.end?.format("YYYY-MM-DD");

    let url = `/dashboard/department-stats?type=${type}&hourType=${val}&isPerEnabled=${isPerEnabled === 0 ? 0 : 1}`
  
    // if (dateRange.start && dateRange.end) {
    //   url += `&startDate=${startDate}&endDate=${endDate}`;
    // }
   if (type == 7 && localStorage.getItem("dbDeptStatsStartDate") && localStorage.getItem("dbDeptStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbDeptStatsStartDate")}&endDate=${localStorage.getItem("dbDeptStatsEndDate")}`;
    }

    if (officeId) {
      url += `&officeId=${officeId}`;
    }
    axiosInstance.get(url).then((res: any) => {
      if (res.status === 200) {
        setLoading(false)
        const dataa = res.data.data
        setOpenDepts(dataa)
        // const data = res.data.data[0].usersStats
        // console.log('open', data)
        // setOpenHoursData(data)
      }
    }).catch((err: any) => {
      setLoading(false)
    })
  }

  //closehours Data


  const fetchCloseHoursData = (type: any, val: any) => {
    setLoading(true)
    // const startDate = dateRange?.start?.format("YYYY-MM-DD");
    // const endDate = dateRange?.end?.format("YYYY-MM-DD");

    let url = `/dashboard/department-stats?type=${type}&hourType=${val}&isPerEnabled=${isPerEnabled === 0 ? 0 : 1}`
  
    // if (dateRange.start && dateRange.end) {
    //   url += `&startDate=${startDate}&endDate=${endDate}`;
    // }
   if (type == 7 && localStorage.getItem("dbDeptStatsStartDate") && localStorage.getItem("dbDeptStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbDeptStatsStartDate")}&endDate=${localStorage.getItem("dbDeptStatsEndDate")}`;
    }

    if (officeId) {
      url += `&officeId=${officeId}`;
    }
    axiosInstance.get(url).then((res: any) => {
      if (res.status === 200) {
        setLoading(false)
        const data = res.data.data
        setClsDeptName(data)
      }

    }).catch((err: any) => {
      setLoading(false)
    })
  }


  // React.useEffect(() => {
  //   alldepartmentsStatsData(type)
  //   fetchopenHoursData(type, 1)
  //   fetchCloseHoursData(type, 2)
  // }, [])

  //all departmenData
  React.useEffect(() => {
    let lType: any = 1;

    if (localStorage.getItem('dbDeptStats')) {
      const getValues: any = localStorage.getItem('dbDeptStats');
      setype(parseInt(getValues));
      lType = parseInt(getValues)
    }
    alldepartmentsStatsData(lType)
    if (tabVal === "2") {
      fetchopenHoursData(type, 1)
    }
    if (tabVal === "3") {
      fetchCloseHoursData(type, 2)
    }
  }, [])

  // React.useEffect(() => {
  //   if (tabVal === "1") {
  //     alldepartmentsStatsData(type)
  //   }
  //   else if (tabVal === "2") {
  //     fetchopenHoursData(type, 1)
  //   }
  //   else if (tabVal === "3") {
  //     fetchCloseHoursData(type, 2)
  //   }
  // }, [])



  const postData = (id: any, incoming: any, outgoing: any) => {
    const url = `/dashboard/reports?departmentId=${id}&type=${type}`
    window.open(url, "_blank")
  }

  //all
  //outgoing
  const outgoingRedirectNextPage = (id: any, outgoing: any, total: any, connected: any) => {
    let url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${outgoing?.callDirection}`;
    if (total === 1) {
      url += `&total=1`
    } 
    if(connected === 1){
      url += `&connected=1`
    }
    if (type == 7 && localStorage.getItem("dbDeptStatsStartDate") && localStorage.getItem("dbDeptStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbDeptStatsStartDate")}&endDate=${localStorage.getItem("dbDeptStatsEndDate")}`;
    }
    window.open(url, "_blank")

  }

  //incoming
  const incomingRedirectNextPage = (id: any, incoming: any, total: any, connected: any, missed: any) => {
    let url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incoming?.callDirection}`;
    if (total === 1) {
      url += `&total=1`
    } 
    if(connected === 1){
      url += `&connected=1`
    }
    if(missed === 1){
      url += `&missed=1`
    }
    if (type == 7 && localStorage.getItem("dbDeptStatsStartDate") && localStorage.getItem("dbDeptStatsEndDate")) {
      url += `&startDate=${localStorage.getItem("dbDeptStatsStartDate")}&endDate=${localStorage.getItem("dbDeptStatsEndDate")}`;
    }
    window.open(url, "_blank");

  }

  //end

  // openhours
  //outgoing
  const openHoursOutgoingNextPage = (id: any, outgoing: any, total: any, connected: any) => {
    if (total === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${outgoing?.callDirection}&total=1`
      window.open(url, "_blank")
    } else if (connected === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${outgoing?.callDirection}&connected=1`
      window.open(url, "_blank")
    }
  }

  //incomig
  const openHoursIncomingRedirectNextPage = (id: any, incomig: any, total: any, connected: any,missed:any) => {
    if (total === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incomig?.callDirection}&total=1`
      window.open(url, "_blank")
    } else if (connected === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incomig?.callDirection}&connected=1`
      window.open(url, "_blank")
    }
    else if (missed === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incomig?.callDirection}&missed=1`
      window.open(url, "_blank")
    }
  }

  //end

  //closehours
  //outgoing
  const closeHoursOutgoingNextPage = (id: any, outgoing: any, total: any, connected: any) => {
    if (total === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${outgoing?.callDirection}&total=1`
      window.open(url, "_blank")
    } else if (connected === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${outgoing?.callDirection}&connected=1`
      window.open(url, "_blank")
    }
  }
  //incomig
  const closeHoursIncomingRedirectNextPage = (id: any, incomig: any, total: any, connected: any,missed:any) => {
    if (total === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incomig?.callDirection}&total=1`
      window.open(url, "_blank")
    } else if (connected === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incomig?.callDirection}&connected=1`
      window.open(url, "_blank")
    }
    else if (missed === 1) {
      const url = `/dashboard/reports?departmentId=${id}&type=${type}&callDirection=${incomig?.callDirection}&missed=1`
      window.open(url, "_blank")
    }
  }

  //   const onSelectStartEndDate = (value:any) => {
  //     setcustom_startDate(moment(value.start).format("YYYY-MM-DD"))
  //     setcustom_endDate(moment(value.end).format("YYYY-MM-DD"))
  //     // this.setState({ value, states });
  // };

  React.useEffect(() => {
    const handleScroll = () => {
      // Close the menu when the page scrolls
      handleCustomPickerClose();
    };

    // Add event listener for scroll events
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function getDateRange(startDate: any, endDate: any) {
    const range = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      range.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }

    return range;
  }

  //end
  const open = Boolean(openCustomPicker);
  const IDBUT = open ? 'simple-popover' : undefined;

  // const dateRange = getDateRange(custom_startDate, custom_endDate);
  // const momentDateRange = dateRange.map(date => date.toDate());
  // var value = moment.range(custom_startDate, custom_endDate)
  // console.log("cfvhbfvjfvjfv",custom_startDate,custom_endDate)
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5} className={classes.cardContent}>
            <Grid item md={2} sm={2} className={classes.grid}>
              <Typography className={classes.userText}>Department Stats</Typography>
            </Grid>
            <Grid item md={5} sm={5} xs={5} className={classes.gridItemFilter}>
              <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
                <ToggleButton className={classes.button} value="left" onClick={() => handleTabValue("1")}>
                  <Typography className={classes.toggleText}>All</Typography>
                </ToggleButton>
                <ToggleButton className={classes.button} value="center" onClick={() => handleTabValue("2")}>
                  <Typography className={classes.toggleText}>Open Hours</Typography>
                </ToggleButton>
                <ToggleButton className={classes.button} value="right" onClick={() => handleTabValue("3")}>
                  <Typography className={classes.toggleText}>Close Hours</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item md={2} sm={2} xs={12} className={`classes.gridItem ${classes.width}`}>
              <FormControlLabel
                value="isPerEnabled"
                control={<Switch color="primary" onChange={(e) => handlePercentageChange(e)} />}
                label="isPercentage"
                labelPlacement="start"
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12} className={`classes.gridItem ${classes.width}`} aria-describedby={IDBUT}>
              <ReactSelect
                placeholder={'Today'} options={keyOptions}
                onChange={handleOptions}
                ref={anchorRef}
                value={keyOptions.filter((opt: any) => opt.value === type)} />

            </Grid>

            <Popover
              //   className={classes.Popover}
              id={IDBUT}
              open={open}
              anchorEl={anchorRef.current}
              onClose={handleCustomPickerClose}
              anchorOrigin={{
                vertical: 'top', // Align to the top of the anchor element
                horizontal: 'right', // Align to the left side of the anchor element
              }}
              transformOrigin={{
                vertical: 'bottom', // Align to the top of the popover content
                horizontal: 'right', // Align to the left side of the popover content
              }}
            >
              <Grid container >
                <Grid
                  style={{ borderLeft: "1px solid lightgray" }}
                  item sm={8} xs={8}
                >
                  {/* <DateRangePicker  
                         value={value}                        
                           locale="en-US"                                              
                            onSelect={onSelectStartEndDate}
                            singleDateRange={true}
                            /> */}
                  <div className="date-range-picker-container">
                    <DateRangePicker
                      onSelect={handleSelect}
                      value={dateRange}
                      singleDateRange={true}
                    />
                  </div>

                  <Grid
                    sm={12}
                    style={{ textAlign: "center", margin: "5px" }}
                  >
                    <button
                      className={classes.button}
                      onClick={applyPayPeriod}
                    >
                      Apply
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </CardContent>
        <Divider style={{ marginTop: -5 }} />
        {loading ?
          <Box>
            <div style={{ width: "100%", textAlign: "center" }}>
              <CircularProgress />
            </div>
          </Box>
          :
          <>
            {tabVal === "1" ?
              <CardContent>
                <TableContainer component={Card} className={classes.tableContainer} id="tableWidthDashboard">
                  <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell className={classes.tableCell} rowSpan={2} align="center">
                          Departments
                        </TableCell>
                        <TableCell className={classes.tableCell} rowSpan={2} align="center">
                          Duration
                        </TableCell>
                        <TableCell className={classes.tableCell} colSpan={3} align="center">
                          Outgoing
                        </TableCell>
                        <TableCell className={classes.tableCell} colSpan={6} align="center">
                          Incoming
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell} align="center">
                          Total {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon}
                            onClick={() => handleSort("outtotal")}
                            src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Connected {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon}
                            onClick={() => handleSort("outConnected")}
                            alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("OutTotalOutDuration")}
                          className={classes.tableCell} align="center">
                          Total Out Duration
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("Intotal")}
                          className={classes.tableCell} align="center">
                          Total {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("InConnected")}
                          className={classes.tableCell} align="center">
                          Connected {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell
                          onClick={() => handleSort("InMissed")}
                          className={classes.tableCell} align="center">
                          Missed {isPerEnabled ? "%" : ""}
                          <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Queue Wait Time

                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Avg Inc Duration

                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          Total Inc Duration

                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      sortedDeptName && sortedDeptName?.map((item: any) => {
                        return (
                          <>
                            <TableHead className={classes.tableHeader}>
                              <TableRow>
                                <TableCell className={classes.tableCellRow} >
                                  <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <HiArrowNarrowRight style={{ color: '#4D72F8', cursor: 'pointer' }}
                                      onClick={() => postData(item._id, item.incomingDeptStats, item.outgoingDeptStats)}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                      {item.name}
                                      {childRow && id === item.name ? (
                                        <KeyboardArrowUp onClick={() => handleChildRow(item.name)} />
                                      ) : (
                                        <KeyboardArrowDown onClick={() => handleChildRow(item.name)} />
                                      )}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"

                                >
                                  {item.duration}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => outgoingRedirectNextPage(item._id, item.outgoingDeptStats, 1, 0)}
                                >
                                  {item.outgoingDeptStats?.total}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => outgoingRedirectNextPage(item._id, item.outgoingDeptStats, 0, 1)}
                                >
                                  {item.outgoingDeptStats?.connected}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  // onClick={() => outgoingRedirectNextPage(item._id, item.outgoingDeptStats, 1, 0)}
                                >
                                  {item.outgoingDeptStats?.totalOutDuration}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => incomingRedirectNextPage(item._id, item.incomingDeptStats, 1, 0, 0)}
                                >
                                  {item.incomingDeptStats?.total}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => incomingRedirectNextPage(item._id, item.incomingDeptStats, 0, 1, 0)}
                                >
                                  {item.incomingDeptStats?.connected}

                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => incomingRedirectNextPage(item._id, item.incomingDeptStats, 0, 0, 1)}>
                                  {item.incomingDeptStats?.missed}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingDeptStats?.queueWaitTime}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingDeptStats?.avgIncDuration}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingDeptStats?.totalIncDuration}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                              </TableRow>

                            </TableHead>
                            {childRow && (id === item.name) &&
                              < TableBody >
                                {item.userStats && item.userStats?.map((detail: any) => {
                                  return (
                                    <>
                                      <TableRow key={detail.name}>
                                        <TableCell className={classes.tableCellBody}>{detail?.firstName} {detail?.lastName}</TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.duration}</TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.outgoingStats?.total}</TableCell>
                                        <TableCell className={classes.tableCellBody} >
                                          <div>

                                            {detail.outgoingStats?.connected}
                                            <div>
                                              <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outbound?.outboundDuration}</Typography>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          {detail.outgoingStats?.totalOutDuration}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          <div>
                                            {detail.incomingStats?.connected}

                                            <div>
                                              <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.inbound?.inboundDuration}</Typography>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          {detail.incomingStats?.missed}
                                          {/* ({detail.inbound?.missedCallPercentage}%) */}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.queueWaitTime}</TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.avgIncDuration}</TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.totalIncDuration}</TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })
                                }
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                  {sortedDeptName?.length <= 0 &&
                                    <p style={{ textAlign: "center", marginRight: '90px', fontSize: 14, fontWeight: 500, marginTop: 2, marginBottom: 0 }}> No Data found</p>}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableBody>
                            }
                          </>
                        )
                      })
                    }
                    {deptName?.length > 0 && <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell className={classes.tableCellRow} align="center">
                          Summary
                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">

                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">
                          {allSummary?.outgoingStats?.total}
                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">
                          {allSummary?.outgoingStats?.connected}
                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">

                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">
                          {allSummary?.incomingStats?.total}
                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">
                          {allSummary?.incomingStats?.connected}
                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">
                          {allSummary?.incomingStats?.missed}
                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">

                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">

                        </TableCell>
                        <TableCell className={classes.tableCellRow} align="center">

                        </TableCell>
                      </TableRow>

                    </TableHead>
                    }
                  </Table>
                  {deptName?.length <= 0 &&
                    <p style={{ textAlign: "center", fontSize: 18, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                  }
                </TableContainer>
              </CardContent>
              :
              tabVal === "2" ?
                <CardContent>
                  <TableContainer component={Card} className={classes.tableContainer} id="tableWidthDashboard">
                    <Table className={classes.table}>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={classes.tableCell} rowSpan={2} align="center">
                            Departments
                          </TableCell>
                          <TableCell className={classes.tableCell} rowSpan={2} align="center">
                            Duration
                          </TableCell>
                          <TableCell className={classes.tableCell} colSpan={3} align="center">
                            Outgoing
                          </TableCell>
                          <TableCell className={classes.tableCell} colSpan={6} align="center">
                            Incoming
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.tableCell} align="center">
                            Total {isPerEnabled ? "%" : ""}
                            <img className={classes.addIcon}
                              onClick={() => handleSort("outtotal")}
                              src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Connected {isPerEnabled ? "%" : ""}
                            <img className={classes.addIcon} src={ArrowIcon}
                              onClick={() => handleSort("outConnected")}
                              alt="arrow" />
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort("OutTotalOutDuration")}
                            className={classes.tableCell} align="center">
                            Total Out Duration
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort("Intotal")}
                            className={classes.tableCell} align="center">
                            Total {isPerEnabled ? "%" : ""}
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort("InConnected")}
                            className={classes.tableCell} align="center">
                            Connected {isPerEnabled ? "%" : ""}
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort("InMissed")}
                            className={classes.tableCell} align="center">
                            Missed {isPerEnabled ? "%" : ""}
                            <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Queue Wait Time

                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Avg Inc Duration

                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            Total Inc Duration

                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {sortedDeptName && sortedDeptName?.map((item: any) => {
                        return (
                          <>
                            <TableHead className={classes.tableHeader}>
                              <TableRow>
                                <TableCell className={classes.tableCellRow}>
                                  <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <HiArrowNarrowRight style={{ color: '#4D72F8', cursor: 'pointer' }}
                                      onClick={() => postData(item._id, item.incomingDeptStats, item.outgoingDeptStats)}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                      {item.name}
                                      {childOpenRow && (item.name === id) ? (
                                        <div><KeyboardArrowDown onClick={() => handleOpenChildRow(item.name)} /></div>
                                      ) : (
                                        <KeyboardArrowUp onClick={() => handleOpenChildRow(item.name)} />
                                      )}
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center" >
                                  {item.duration}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => openHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 1, 0)}
                                >
                                  {item.outgoingDeptStats?.total}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => openHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 0, 1)}
                                >
                                  {item.outgoingDeptStats?.connected}

                                </TableCell>
                                <TableCell className={classes.tableCellRow}
                                  // onClick={() => openHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 0, 1)}
                                  align="center">
                                  {item.outgoingDeptStats?.totalOutDuration}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => openHoursIncomingRedirectNextPage(item._id, item.incomingDeptStats, 1, 0,0)}
                                >
                                  {item.incomingDeptStats?.total}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => openHoursIncomingRedirectNextPage(item._id, item.incomingDeptStats, 0, 1,0)}
                                >
                                  {item.incomingDeptStats?.connected}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center"
                                       onClick={() => openHoursIncomingRedirectNextPage(item._id, item.incomingDeptStats, 0, 0,1)}
                                >
                                  {item.incomingDeptStats?.missed}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingDeptStats?.queueWaitTime}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingDeptStats?.avgIncDuration}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                                <TableCell className={classes.tableCellRow} align="center">
                                  {item.incomingDeptStats?.totalIncDuration}
                                  {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {childOpenRow && (id === item.name) &&
                              < TableBody >
                                {item.userStats && item.userStats?.map((detail: any) => {
                                  return (
                                    <>
                                      <TableRow key={detail.name}>
                                        <TableCell className={classes.tableCellBody}>{detail.firstName} {detail.lastName}</TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.duration}</TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.outgoingStats?.total}</TableCell>
                                        <TableCell className={classes.tableCellBody} >
                                          <div>
                                            {detail.outgoingStats?.connected}
                                            <div>
                                              <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outbound?.outboundDuration}</Typography>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody} align="center">
                                          {detail.outgoingStats?.totalOutDuration}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          <div>
                                            {detail.incomingStats?.connected}

                                            <div>
                                              <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.inbound?.inboundDuration}</Typography>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>
                                          {detail.incomingStats?.missed}
                                          {/* ({detail.inbound?.missedCallPercentage}%) */}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.queueWaitTime}</TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.avgIncDuration}</TableCell>
                                        <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.totalIncDuration}</TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })
                                }
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                  {sortedDeptName?.length <= 0 &&
                                    <p style={{ textAlign: "center", marginRight: '90px', fontSize: 14, fontWeight: 500, marginTop: 2, marginBottom: 0 }}> No Data found</p>}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableBody>
                            }
                          </>
                        )
                      })}
                      {openDepts?.length > 0 && <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={classes.tableCellRow} align="center">
                            Summary
                          </TableCell>
                          <TableCell className={classes.tableCellRow} align="center">
                            {openSummary?.totalOutbound}
                          </TableCell>
                          <TableCell className={classes.tableCellRow} align="center">
                            {openSummary?.totalOutboundConnected}
                          </TableCell>
                          <TableCell className={classes.tableCellRow} align="center">
                            {openSummary?.totalInbound}
                          </TableCell>
                          <TableCell className={classes.tableCellRow} align="center">
                            {openSummary?.totalInboundConnected}
                          </TableCell>
                          <TableCell className={classes.tableCellRow} align="center">
                            {openSummary?.totalMissedCalls}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>

                      </TableHead>
                      }


                    </Table>
                    {openDepts?.length <= 0 &&
                      <p style={{ textAlign: "center", fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                    }
                  </TableContainer>
                </CardContent>
                :
                tabVal === "3" ?
                  <CardContent>
                    <TableContainer component={Card} className={classes.tableContainer} id="tableWidthDashboard">
                      <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell className={classes.tableCell} rowSpan={2} align="center">
                              Departments
                            </TableCell>
                            <TableCell className={classes.tableCell} rowSpan={2} align="center">
                              Duration
                            </TableCell>
                            <TableCell className={classes.tableCell} colSpan={3} align="center">
                              Outgoing
                            </TableCell>
                            <TableCell className={classes.tableCell} colSpan={6} align="center">
                              Incoming
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableCell} align="center">
                              Total {isPerEnabled ? "%" : ""}
                              <img className={classes.addIcon}
                                onClick={() => handleSort("outtotal")}
                                src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Connected {isPerEnabled ? "%" : ""}
                              <img className={classes.addIcon} src={ArrowIcon}
                                onClick={() => handleSort("outConnected")}
                                alt="arrow" />
                            </TableCell>
                            <TableCell
                              onClick={() => handleSort("OutTotalOutDuration")}
                              className={classes.tableCell} align="center">
                              Total Out Duration
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell
                              onClick={() => handleSort("Intotal")}
                              className={classes.tableCell} align="center">
                              Total {isPerEnabled ? "%" : ""}
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell
                              onClick={() => handleSort("InConnected")}
                              className={classes.tableCell} align="center">
                              Connected {isPerEnabled ? "%" : ""}
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell
                              onClick={() => handleSort("InMissed")}
                              className={classes.tableCell} align="center">
                              Missed {isPerEnabled ? "%" : ""}
                              <img className={classes.addIcon} src={ArrowIcon} alt="arrow" />
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Queue Wait Time

                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Avg Inc Duration

                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Total Inc Duration

                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {sortedDeptName && sortedDeptName.map((item: any) => {
                          return (
                            <>
                              <TableHead className={classes.tableHeader}>
                                <TableRow>
                                  <TableCell className={classes.tableCellRow}>
                                    <div style={{ display: "flex", alignItems: 'center', cursor: "pointer", gap: 5 }}>
                                      <HiArrowNarrowRight style={{ color: '#4D72F8', cursor: 'pointer' }}
                                        onClick={() => postData(item._id, item.incomingDeptStats, item.outgoingDeptStats)}
                                      />
                                      <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                        {item.name}

                                        {childCloseRow && (item.name === id) ? (
                                          <KeyboardArrowUp onClick={() => handleCloseChildRow(item.name)} />
                                        ) : (
                                          <KeyboardArrowDown onClick={() => handleCloseChildRow(item.name)} />
                                        )}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center"
                                    onClick={() => closeHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 1, 0)}
                                  >
                                    {item.duration}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center"
                                    onClick={() => closeHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 1, 0)}
                                  >
                                    {item.outgoingDeptStats?.total}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center"
                                    onClick={() => closeHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 0, 1)}
                                  >
                                    {item.outgoingDeptStats?.connected}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow}
                                    // onClick={() => closeHoursOutgoingNextPage(item._id, item.outgoingDeptStats, 0, 1)}
                                    align="center">
                                    {item.outgoingDeptStats?.totalOutDuration}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center"
                                    onClick={() => closeHoursIncomingRedirectNextPage(item._id, item.incomingDeptStats, 1, 0,0)}
                                  >
                                    {item.incomingDeptStats?.total}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center"
                                    onClick={() => closeHoursIncomingRedirectNextPage(item._id, item.incomingDeptStats, 0, 1,0)}
                                  >
                                    {item.incomingDeptStats?.connected}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center"
                                  onClick={() => closeHoursIncomingRedirectNextPage(item._id, item.incomingDeptStats, 0, 0,1)}
                                  >
                                    {item.incomingDeptStats?.missed}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    {item.incomingDeptStats?.queueWaitTime}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    {item.incomingDeptStats?.avgIncDuration}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                  <TableCell className={classes.tableCellRow} align="center">
                                    {item.incomingDeptStats?.totalIncDuration}
                                    {/* <img className={classes.addIcon} src={ArrowIcon} alt="arrow" /> */}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {childCloseRow && (id === item.name) &&
                                // <TableBody>
                                //   {item.userStats && item.userStats.map((detail: any) => {
                                //     return (
                                //       <>
                                //         <TableRow key={detail.name}>
                                //           <TableCell className={classes.tableCellBody}>{detail.firstName} {detail.lastName}</TableCell>
                                //           <TableCell className={classes.tableCellBody}>{detail.duration}</TableCell>
                                //           <TableCell className={classes.tableCellBody}>{detail?.outgoingStats?.total}</TableCell>
                                //           <TableCell className={classes.tableCellBody}>
                                //             <div>
                                //               {detail?.outgoingStats?.connected}

                                //               <div>
                                //                 <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outgoingDeptStats?.outboundDuration}</Typography>
                                //               </div>
                                //             </div>
                                //           </TableCell>
                                //           <TableCell className={classes.tableCellBody}>{detail?.outgoingStats?.totalOutDuration}</TableCell>
                                //           <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                //           <TableCell className={classes.tableCellBody}>
                                //             <div>
                                //               {detail.incomingStats?.connected}

                                //               <div>
                                //                 <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.incomingStats?.inboundDuration}</Typography>
                                //               </div>
                                //             </div>
                                //           </TableCell>
                                //           <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.missed}</TableCell>
                                //           <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.queueWaitTime}</TableCell>
                                //       <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.avgIncDuration}</TableCell>
                                //       <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.totalIncDuration}</TableCell>
                                //         </TableRow>


                                //       </>
                                //     );
                                //   })}
                                //   {sortedDeptName?.length <= 0 &&
                                //     <p style={{ textAlign: "center", fontSize: 14, fontWeight: 400, marginTop: 10 }}> No Data found</p>}
                                // </TableBody>
                                <TableBody>
                                  {item.userStats && item.userStats.map((detail: any) => {
                                    return (
                                      <>
                                        <TableRow key={detail.name}>
                                          <TableCell className={classes.tableCellBody}>{detail.name}</TableCell>
                                          <TableCell className={classes.tableCellBody}>{detail.duration}</TableCell>
                                          <TableCell className={classes.tableCellBody}>{detail.outgoingStats?.total}</TableCell>
                                          <TableCell className={classes.tableCellBody}>
                                            <div>
                                              {detail.outgoingStats?.connected}

                                              <div>
                                                <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.outgoingStats?.outboundDuration}</Typography>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell className={classes.tableCellBody}>
                                            {detail.outgoingStats?.totalOutDuration}
                                          </TableCell>
                                          <TableCell className={classes.tableCellBody}>{detail.incomingStats?.total}</TableCell>
                                          <TableCell className={classes.tableCellBody}>
                                            <div>
                                              {detail.incomingStats?.connected}

                                              <div>
                                                <Typography style={{ color: "gray", fontSize: "9px" }}>{detail.incomingStats?.inboundDuration}</Typography>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.missed}</TableCell>
                                          <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.queueWaitTime}</TableCell>
                                          <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.avgIncDuration}</TableCell>
                                          <TableCell className={classes.tableCellBody}>  {detail.incomingStats?.totalIncDuration}</TableCell>
                                        </TableRow>


                                      </>
                                    );
                                  })}
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>

                                  <TableCell>
                                    {sortedDeptName.length <= 0 &&
                                      <p style={{ textAlign: "center", marginRight: '90px', fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>}
                                  </TableCell>

                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableBody>
                              }
                            </>
                          )
                        })}
                        {clsDeptName?.length > 0 && <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell className={classes.tableCellRow} align="center">
                              Summary
                            </TableCell>
                            <TableCell className={classes.tableCellRow} align="center">
                              {closeSummary?.totalOutbound}
                            </TableCell>
                            <TableCell className={classes.tableCellRow} align="center">
                              {closeSummary?.totalOutboundConnected}
                            </TableCell>
                            <TableCell className={classes.tableCellRow} align="center">
                              {closeSummary?.totalInbound}
                            </TableCell>
                            <TableCell className={classes.tableCellRow} align="center">
                              {closeSummary?.totalInboundConnected}
                            </TableCell>
                            <TableCell className={classes.tableCellRow} align="center">
                              {closeSummary?.totalMissedCalls}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>

                        </TableHead>
                        }

                      </Table>
                      {clsDeptName?.length <= 0 &&
                        <p style={{ textAlign: "center", fontSize: 14, fontWeight: 500, marginTop: 10 }}> No Data found</p>
                      }
                    </TableContainer>
                  </CardContent> : ""}
          </>
        }
      </Card>
    </>
  );
}

import React from 'react'
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    SmoothStepEdge,
} from 'reactflow';
import { MarkerType, Position } from 'reactflow';

import { useCallback } from 'react';
import axiosInstance from '../../../../environment/axiosInstance';

import 'reactflow/dist/style.css';
import './styles.css'


const initialEdges = [
    { id: 'e1-1', source: '1', target: '2', },
    { id: 'e1-2', source: '1', target: '3', },
    { id: 'e2-1', source: '2', target: '4', },
    { id: 'e2-2', source: '2', target: '5', },
    // { id: 'e2-3', source: '2', target: '6', },
    { id: 'e2-4', source: '3', target: '7', },
    // { id: 'e2-5', source: '3', target: '8', },
    { id: 'e3-1', source: '5', target: '9', },
    { id: 'e3-1', source: '5', target: '10', },
    // { id: 'e3-2', source: '5', target: '11', },
    // { id: 'e3-3', source: '5', target: '12', },
    // { id: 'e3-4', source: '5', target: '13', },
    // { id: 'e3-5', source: '5', target: '14', },
    { id: 'e3-6', source: '7', target: '15', },
    { id: 'e3-7', source: '7', target: '16', },
    // { id: 'e3-8', source: '7', target: '17', },

];
const connectionLineStyle = { stroke: '#fff' };
const snapGrid = ["20", "20"];

function Callbrakdown(props:any) {

    const [data, setData] = React.useState<any>();
   
    React.useEffect(() => {
        setData(props.calldata)
    },[props.calldata])
   

    // console.log("responseData", data)
    const initialNodes: any = [
        {
            id: '1',
            connectionLineType: { SmoothStepEdge },
            position: { x: 0, y: 0 },
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 23, fontFamily: 'roboto' }}>{data?.totalCalls}</span><br />
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Total Calls
                        </span><br />
                        {/* <span style={{ fontSize: 23, fontFamily: 'roboto' }}>0</span><br />
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>Handled Calls</span> */}
                    </div>
                )
                // label: 'Total calls',
                // selects: {
                //     "handle-0": "straight",
                //     "handle-1": "smoothstep"
                // }
            },
            type: 'input',
            sourcePosition: Position.Right,
            draggable: false,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #c9c9c9',
                color: 'black',
                height: "450px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },

        },
        {
            id: '2',
            position: { x: 180, y: 10 },
            type: 'Custom',
            draggable: false,
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 23, fontFamily: 'roboto' }}>{data?.InboundCalls?.totalInboundCalls}</span><br />
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Inbound Calls
                        </span>
                    </div>
                ),
                selects: {
                    "handle-0": "smoothstep"
                }
            },
            // data: { label: 'Inbound calls' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #ab7eff',
                color: 'black',
                height: "200px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
        },
        {
            id: '3',
            position: { x: 180, y: 230 },
            draggable: false,
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.OnboundCalls?.totalOutboundCalls}</span><br />
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Outbound Calls
                        </span>
                    </div>
                )
            },
            // data: { label: 'Outbound calls' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #815df4',
                color: 'black',
                height: "200px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
        },
        {
            id: '4',
            position: { x: 400, y: 10 },
            draggable: false,
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.InboundCalls?.AnsweredCalls}</span>
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Answered Calls
                        </span>
                    </div>
                )
            },
            // data: { label: 'Answered' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #ab7eff',
                color: 'black',
                height: "60px"
            },
        },
        {
            id: '5',
            position: { x: 400, y: 90 },
            draggable: false,
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.InboundCalls?.UnansweredCalls}</span>
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            UnAnswered Calls
                        </span>
                    </div>
                )
            },
            // data: { label: 'UnAnswered' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #ab7eff',
                color: 'black',
                height: "60px"
            },
        },
        // {
        //     id: '6',
        //     position: { x: 400, y: 170 },
        //     draggable: false,
        //     data: {
        //         label: (
        //             <div>
        //                 <span style={{ fontSize: 20, fontFamily: 'roboto' }}>0</span>
        //                 <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
        //                     Callbacks Requested
        //                 </span>
        //             </div>
        //         )
        //     },
        //     // data: { label: 'Callbacks Requested' },
        //     sourcePosition: 'right',
        //     targetPosition: Position.Left,
        //     style: {
        //         background: '#fffff',
        //         borderLeft: '5px solid #ab7eff',
        //         color: 'black',
        //         height: "50px"
        //     },
        // },
        {
            id: '7',
            position: { x: 400, y: 305 },
            draggable: false,
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.OnboundCalls?.UserInitiatedCalls}</span><br />
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            UserInitiated Calls
                        </span>
                    </div>
                )
            },
            // data: { label: 'User Initiated' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #815df4',
                color: 'black',
                height: "60px"
            },
        },
        // {
        //     id: '8',
        //     draggable: false,
        //     position: { x: 400, y: 340 },
        //     data: {
        //         label: (
        //             <div>
        //                 <span style={{ fontSize: 20, fontFamily: 'roboto' }}>0</span><br />
        //                 <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
        //                     SysytemInitiated Calls
        //                 </span>
        //             </div>
        //         )
        //     },
        //     // data: { label: 'System Initated Callbacks' },
        //     sourcePosition: 'right',
        //     targetPosition: Position.Left,
        //     style: {
        //         background: '#fffff',
        //         borderLeft: '5px solid #815df4',
        //         color: 'black',
        //         height: "50px"
        //     },
        // },
        {
            id: '9',
            draggable: false,
            position: { x: 650, y: 80 },
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.InboundCalls?.Unanswered?.Missed}</span>
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Missed
                        </span>
                    </div>
                )
            },
            // data: { label: 'Missed' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #ab7eff',
                color: 'black',
                height: "35px"
            },
        },
        {
            id: '10',
            draggable: false,
            position: { x: 650, y: 150 },
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.InboundCalls?.Unanswered?.Abandoned}</span>
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Abondoned
                        </span>
                    </div>
                )
            },
            // data: { label: 'Abondoned' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #ab7eff',
                color: 'black',
                height: "35px"
            },
        },
        // {
        //     id: '11',
        //     draggable: false,
        //     position: { x: 650, y: 90 },
        //     data: {
        //         label: (
        //             <div>
        //                 <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.InboundCalls?.Unanswered?.UnansweredTransfer}</span>
        //                 <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
        //                     Abondoned
        //                 </span>
        //             </div>
        //         )
        //     },
        //     // data: { label: 'Unanswred Transfer' },
        //     sourcePosition: 'right',
        //     targetPosition: Position.Left,
        //     style: {
        //         background: '#fffff',
        //         borderLeft: '5px solid #ab7eff',
        //         color: 'black',
        //         height: "35px"
        //     },
        // },
        // {
        //     id: '12',
        //     draggable: false,
        //     position: { x: 650, y: 140 },
        //     data: {
        //         label: (
        //             <div>
        //                 <span style={{ fontSize: 20, fontFamily: 'roboto' }}>0</span>
        //                 <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
        //                     Other Voicemail
        //                 </span>
        //             </div>
        //         )
        //     },
        //     // data: { label: 'Other Voicemail' },
        //     sourcePosition: 'right',
        //     targetPosition: Position.Left,
        //     style: {
        //         background: '#fffff',
        //         borderLeft: '5px solid #ab7eff',
        //         color: 'black',
        //         height: "35px"
        //     },
        // },
        // {
        //     id: '13',
        //     draggable: false,
        //     position: { x: 650, y: 190 },
        //     data: {
        //         label: (
        //             <div>
        //                 <span style={{ fontSize: 20, fontFamily: 'roboto' }}>0</span>
        //                 <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
        //                     Message
        //                 </span>
        //             </div>
        //         )
        //     },
        //     // data: { label: 'Message' },
        //     sourcePosition: 'right',
        //     targetPosition: Position.Left,
        //     style: {
        //         background: '#fffff',
        //         borderLeft: '5px solid #ab7eff',
        //         color: 'black',
        //         height: "35px"
        //     },
        // },
        // {
        //     id: '14',
        //     draggable: false,
        //     position: { x: 650, y: 240 },
        //     data: {
        //         label: (
        //             <div>
        //                 <span style={{ fontSize: 20, fontFamily: 'roboto' }}>0</span>
        //                 <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
        //                     Spam
        //                 </span>
        //             </div>
        //         )
        //     },
        //     // data: { label: 'Spam' },
        //     sourcePosition: 'right',
        //     targetPosition: Position.Left,
        //     style: {
        //         background: '#fffff',
        //         borderLeft: '5px solid #ab7eff',
        //         color: 'black',
        //         height: "35px"
        //     },
        // },
        {
            id: '15',
            draggable: false,
            position: { x: 650, y: 290 },
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.OnboundCalls?.UserInitiated?.Connected}</span>
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                            Connected Calls
                        </span>
                    </div>
                )
            },
            // data: { label: 'Connected' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #815df4',
                color: 'black',
                // height: "30px"
            },
        },
        {
            id: '16',
            position: { x: 650, y: 360 },
            draggable: false,
            selectable: false,
            data: {
                label: (
                    <div>
                        <span style={{ fontSize: 20, fontFamily: 'roboto' }}>{data?.OnboundCalls?.UserInitiated?.Cancelled}</span>
                        <span style={{ fontSize: 13, fontFamily: 'roboto' }}>
                        Cancelled Calls
                        </span>
                    </div>
                )
            },
            // data: { label: 'Cancelled' },
            sourcePosition: 'right',
            targetPosition: Position.Left,
            style: {
                background: '#fffff',
                borderLeft: '5px solid #815df4',
                color: 'black',
                // height: "30px"
            },
        },


    ];

    const [nodes, setNodes, onNodesChange] = useNodesState<any>(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);




    React.useEffect(() => {
        // const onChange = (event: any) => {
        //     setNodes((nds) =>
        //         nds.map((node) => {
        //             if (node.id !== '2') {
        //                 return node;
        //             }

        //             const color = event.target.value;

        //             return {
        //                 ...node,
        //                 data: {
        //                     ...node.data,
        //                     color,
        //                 },
        //             };
        //         })
        //     );
        // };

        setNodes(initialNodes);

        setEdges(initialEdges);
    }, [data]);

    const reactFlowStyle = {
        background: '',
        // width: '100%',
        // height: 500,
    };

    return (
        <div style={{ width: "100%", height: 500 }}>

            <ReactFlow style={reactFlowStyle}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                fitView
                zoomOnScroll={false}
                zoomOnPinch={false}
                preventScrolling
                draggable={false}
                // onConnect={onConnect}
                connectionLineStyle={connectionLineStyle}
            // snapToGrid={true}

            >

            </ReactFlow>

        </div>
    )
}

export default Callbrakdown
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '0px 0px 10px 0px',
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    // overflow: 'unset',
    // width:'180%',
    // marginLeft:-250
  },
  addBtn: {
    backgroundColor: "#4D72F8",
    color: "white",
    fontWeight: "bold"
  },
  topContainer: {
    padding: 0,
    textAlign: "right"
  },
  trashIcon: {
    color: "red"
  },
  editIcon: {
    color: "#4D72F8"
  },
  editBtn: {
    border: "1px solid #4D72F8",
    minWidth: "45px !important"
  },
  testBtn: {
    marginLeft: "10px",
    border: "1px solid gray",
    minWidth: "45px !important"
  },
  sendBtn: {
    backgroundColor: "#4D72F8",
    color: "white"
  },
  deleteBtn: {
    marginLeft: "10px",
    border: "1px solid red",
    minWidth: "45px !important"
  },
  error: {
    fontSize: 14,
    color: "red"
  }
}));

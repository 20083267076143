
import React from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Switch,
    Divider,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { TableHeaderComponent } from "../../../components/Table/Table";
import ReactSelect from "react-select";
import { CheckBox, Details } from "@material-ui/icons";
import { useSelector, useDispatch } from 'react-redux'
//import departmentServices from '../DepartmentServices/DepartmentServices'
import * as DepartmentActionCreators from '../../../store/Actions/DepartmentsActionXCreators'
import { useParams } from "react-router-dom";
import axiosInstance from '../../../environment/axiosInstance'


const generatedHours: any = [];
for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60 / 15; j++) {
        const leftTimer = i.toString().length === 1 ? `${"0" + i}` : i;
        const rightTimer = (j * 15).toString().length === 1 ? `${j + "0"}` : (j * 15);
        let hourData = { value: `${leftTimer + ":" + rightTimer}`, label: `${leftTimer + ":" + rightTimer}` }
        generatedHours.push(hourData)

    }

}

function TimeZone(props: any) {
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch();
    const hoursHeader = ["Day", "Opens At", "Closes At", ""];
    const [weekDaysArray, setWeekDaysArray] = React.useState<any>([]);
    const [week, setsameAsWeek] = React.useState(false);
    const [weekDaysTimings, setWeekDaysTimings] = React.useState<any>({
        Monday: {
            open: "",
            close: "",
        },
        Tuesday: {
            open: "",
            close: "",
        },
        Wednesday: {
            open: "",
            close: "",
        },
        Thursday: {
            open: "",
            close: "",
        },
        Saturday: {
            open: "",
            close: "",
        },
        Friday: {
            open: "",
            close: "",
        },
        Sunday: {
            open: "",
            close: "",
        },
    });


    const onChangeWeekdayTimings1 = (e: any, key: any, index: any) => {
       
        if(week){
            let copyWeeksData = [...props.daysData]
            copyWeeksData.forEach((weeks: any) => {
                weeks[key] = e.value;
            })
            props.onChangeWeekDaysTimingsData([...copyWeeksData])
        }else{
            let copyWeeksData = [...props.daysData]
            copyWeeksData[index][key] = e.value;
            props.onChangeWeekDaysTimingsData([...copyWeeksData])
        }
     
        
    }

    const onChangeSetasallweeks = (e: any) => {
        setsameAsWeek(!week);
        
        if (e.target.checked) {
            let copyWeeksData: any = [...props.daysData];
            const openValue = copyWeeksData[0]['open']
            const closeValue = copyWeeksData[0]['close']
            copyWeeksData.forEach((week: any) => {
                week['open'] = openValue
                week['close'] = closeValue
            })
            props.onChangeWeekDaysTimingsData(copyWeeksData)
        } else if (e.target.checked === false) {
            let copyWeeksData: any = [...props.daysData];

            copyWeeksData.slice(1).forEach((week: any) => {
                week['open'] = ""
                week['close'] = ""
            })
            props.onChangeWeekDaysTimingsData(copyWeeksData)

        }
    }



    const updatedMyAccountSchedule = (data: any) => {
        props.updatedMyAccountSchedule({ alwaysOpen: props.selectAlwaysOpen,days: props.daysData },'days')
    };

    // React.useEffect(() => {
    //     const { days } = props?.scheduleData[0];
    //     if(days !== undefined && days.length > 0){
    //         props.onChangeWeekDaysTimingsData(days)
    //     }
    // }, [props.scheduleData])
    return (
        <div>
            {/* <CardContent className={classes.specificContent} style={{marginTop:-20}}> */}
            <TableContainer
                // component={Card}
                className={classes.tableContainer1}
            >
                <Table style={{ width: '100%' }}>
                    <TableHeaderComponent theadData={hoursHeader} />
                    <TableBody>
                        {props.daysData.map((detail: any, index: any) => {
                            return (
                                <>
                                    <TableRow key={detail.day}>
                                        <TableCell className={classes.tableCellBody1}>
                                            {detail.day}
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody2}>
                                            {/* {detail.open} */}
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                isDisabled={week && index >=1}
                                                // placeholder={detail.open === "" ? 'Open At' : detail.open}
                                                value={{
                                                    value: detail?.open !== undefined ? detail?.open : "",
                                                    label: detail?.open !== undefined ? detail?.open : "",
                                                }}

                                                options={generatedHours}
                                                onChange={(e: any) => onChangeWeekdayTimings1(e, 'open', index)}
                                                name="open"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody2}>
                                            {/* {detail.close} */}
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                isDisabled={week && index >=1}
                                                // placeholder={detail.close === "" ? 'Close At' : detail.close}
                                                options={generatedHours}
                                                value={{ 
                                                    value: detail?.close !== undefined ? detail?.close : "", 
                                                    label: detail?.close !== undefined ? detail?.close : "",
                                                }}
                                                // onChange={onChangeWeekdayTimings}
                                                onChange={(e: any) => onChangeWeekdayTimings1(e, 'close', index)}
                                                name="close"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCellBody1}>
                                            {
                                                index === 0 && (
                                                    <FormControlLabel
                                                        control={<Checkbox name="checkedC" size='small' />}
                                                        label="Same as all week"
                                                        onChange={onChangeSetasallweeks}
                                                    />
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                <Button className={classes.saveBtn} onClick={updatedMyAccountSchedule} >Save</Button>
            </div>
            {/* </CardContent> */}
        </div>
    )
}

export default TimeZone
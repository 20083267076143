import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useStyles } from "./styles";
import EditIcon from "../../assets/icons/editIcon.png";
import { TripOrigin } from "@material-ui/icons";
import { DropDown } from "../../components/DropDown/DropDown";
import ReactSelect from "react-select";
import MyLogins from "./MyLogins/MyLogins";
import { AdvancedSettings } from "./AdvancedSettings/AdvancedSettings";
import YourEfoneNumbersSchedule from "./UserCallHandling/YourEfoneNumbersSchedule/YourEfoneNumbersSchedule";
import UnAnsweredCalls from "./UserCallHandling/UnAnsweredCalls/UnAnsweredCalls";
import CloseHours from "./UserCallHandling/CloseHours/CloseHours";
import ProfileData from "./ProfileData/ProfileData";
import profileServices from "./ProfileServices/ProfileServices";
import EfoneNumberDepartments from "./efoneNumber&departments/EfoneNumber&Departments";
import DatePicker from "react-datepicker";
import * as userProfileActionCreator from "../../store/Actions/userActionCreator";
import { OpenHours } from "./UserCallHandling/OpenHours/OpenHours";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "./profile.css";
import moment from 'moment'
import MyDevice from "./MyDevice/MyDevice";
const generatHours: any = [];
for (let i = 0; i < 24; i++) {
  let hourData = { value: i + 1, label: `${i + 1} Hour` };
  generatHours.push(hourData);
}

function Profile(props: any) {
  const classes = useStyles();
  const [closed, setClosed] = React.useState(false)
  const [dndselect, setdndSelect] = React.useState(false);
  const [selectedHour, setSelectedHour] = React.useState<any>("");
  const [value, setValue] = React.useState("");
  const [outboundCallerIdNumbers, setOutBoundCllerIdNumbers] =
    React.useState<any>([]);
  const [outboundCallerIdNumbervalue, setOutboundCallerIdNumbervalue] =
    React.useState<any>(null);
  const [startDate, setStartDate] = React.useState<any>();
  const [newdata, setNewData] = React.useState<any>({})
  const dispatch = useDispatch();

  const dndhandleChange = () => {
    setdndSelect(!dndselect);
    const userDatadndChange = { dndStatus: !dndselect };
    if (dndselect) {
      updateUserProfile(userDatadndChange);
    }

  };

  const profileDataStore: any = useSelector((state: any) => state.profileData);
  const { profileData, loading } = profileDataStore;
  const fetchOutBoudCallerIdNumbers = () => {
    profileServices
      .geteFoneNumbers()
      .then((res) => {
        if (res.data) {
          const outBoundData = res.data;
          const outBoundDataToSet = outBoundData.map((data: any) => {
            return { value: data.id, label: data.phoneNumber };
          });
          setOutBoundCllerIdNumbers(outBoundDataToSet);
        } else {
          //send error
        }
      })
      .catch((err) => {
        //send error
      });
  };


  const updateUserProfile = (updateData: any) => {
    profileServices
      .updateprofileData(updateData)
      .then((res: any) => {
        //to close input element
      })
      .catch((err) => { });
  };

  const handleChangeRadioGroup = (event: any) => {
    setValue(event.target.value);
    if (event.target.value === "3") {
      const untillTurnOn = {
        dndDate: "null",
        dndType: Number(event.target.value),
        dndStatus: dndselect
      };
      updateUserProfile(untillTurnOn);
    } else {
      // updateUserProfile({ dndType: Number(event.target.value), dndStatus: dndselect });
    }
  };

  const onhandleChangeHour = (selectedHour: any) => {
    const updatedHour = new Date().setTime(
      new Date().getTime() + selectedHour.value * 60 * 60 * 1000
    );
    const updatedDNDHourtime = { dndDate: `${Date.parse(new Date(updatedHour).toISOString())}`, dndType: '2', dndStatus: dndselect };
    updateUserProfile(updatedDNDHourtime);
    setSelectedHour(selectedHour);
  }
  const onChangeDateandTime = (date: any) => {
    // console.log(date)
    const dndDatetime = { dndDate: new Date(date).toISOString(), dndStatus: dndselect };
    updateUserProfile(dndDatetime);
    setStartDate(date);
  };

  const outboundCallerIdNumbershandleChange = (data: any) => {
    setOutboundCallerIdNumbervalue(data.label);
    updateUserProfile({ outboundCallerIdNumber: data.label });
  };

  React.useEffect(() => {
    // fetchUserDetails()
    // dispatch(userProfileActionCreator.initUserProfile());
    fetchOutBoudCallerIdNumbers();
  }, []);



  React.useEffect(() => {
    if (outboundCallerIdNumbers.length > 0) {
      setOutboundCallerIdNumbervalue(profileData?.outboundCallerIdNumber)
      // const filterOutBoundValue = outboundCallerIdNumbers.find(
      //   (data: any) => data.value === profileData?.outboundCallerIdNumber
      // );
      // console.log("hdbchbchj",profileData?.outboundCallerIdNumber)
      // if (filterOutBoundValue) {
      //   setOutboundCallerIdNumbervalue(filterOutBoundValue);
      // }
      // if (profileData?.dndType === 2) {
      //   setStartDate(profileData.dndDate);
      // }
    }
    if (profileData) {
      setdndSelect(profileData?.dndStatus);
      setValue(`${profileData?.dndType}`);
      if (profileData.dndDate !== 'null') {
        if (new Date(Number(profileData.dndDate)) > new Date()) {
          setStartDate(new Date(Number(profileData.dndDate)))
        }

      }
    }

  }, [outboundCallerIdNumbers, profileData]);

  const handleCallback = (childData: any) => {
    setClosed(childData)
  }
  React.useEffect(() => {
    setClosed(profileData?.alwaysOpen)
  }, [profileData?.alwaysOpen])
  return (
    <div>
      <Card>
        <div className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12} className={classes.prelative}>
              <ProfileData
                profileData={profileData}
                loading={loading}
                updateUserData={updateUserProfile}
              />
            </Grid>
            {/* <Grid item md={2} className={classes.grid}></Grid> */}
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12} >
              <Card className={classes.doNotDisturbCard} style={{ marginTop: 20 }}>
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.cardColor}>
                    Do Not Disturb
                  </Typography>
                  <Switch
                    className={classes.switchColor}
                    checked={dndselect}
                    onChange={dndhandleChange}
                  />
                </CardContent>
                <Divider className={classes.divider} />
                {dndselect && (
                  <CardContent className={classes.dndCardContent}>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={value}
                      onChange={handleChangeRadioGroup}
                    >
                      <div className={classes.dnd_flex}>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <span style={{ marginLeft: "20px" }}>
                              Dnd Time and Date
                            </span>
                          }
                        />
                        <div className="date_and_time_picker_section">
                          {value === "1" && (
                            <DatePicker
                              selected={startDate}
                              onChange={onChangeDateandTime}
                              timeInputLabel="Time:"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              showTimeInput
                              className="date_and_time_pickers"
                            />
                          )}
                        </div>
                      </div>
                      <div className={classes.dnd_flex}>
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <span style={{ marginLeft: "20px" }}>
                              DND for{" "}
                              {selectedHour
                                ? `${selectedHour.label}s`
                                : `__ hours`}
                            </span>
                          }
                        />

                        {value === "2" && (

                          <div className="select_hours_section_s" >
                            <div className={classes.dnd_flex}>
                              <ReactSelect
                                placeholder={"Select Hours"}
                                menuPortalTarget={document.body}
                                className={classes.select}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    width: "125px"
                                  }),
                                }}
                                value={selectedHour}
                                onChange={onhandleChangeHour}
                                options={generatHours}
                              />

                              <div style={{ width: "180px" }}>
                                {startDate ?
                                  // <div className={classes.timeDate}>
                                  startDate && new Date(Number(startDate)).toLocaleString()
                                  : ""}
                              </div>
                            </div>
                          </div>


                        )}

                        {/* {value === "2" && (
                          <div className="select_hours_section_s">
                            {profileData.dndDate !== "" ? <Typography>{new Date(profileData.dndDate)}</Typography> : ""}
                            <div style={{ width: "180px" }}></div>
                          </div>
                        )} */}
                      </div>
                      <div className={classes.dnd_flex}>
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label={
                            <span style={{ marginLeft: "20px" }}>
                              DND Untill trun on
                            </span>
                          }
                        />
                      </div>
                    </RadioGroup>
                  </CardContent>
                )}
              </Card>
              <Card className={classes.outBondCard}>
                <CardContent className={classes.dndCardContent}>
                  <Typography className={classes.departmentNameText}>
                    Outbound CallerId Number
                  </Typography>
                  <img className={classes.editIcon} src={EditIcon} alt="edit" />
                </CardContent>
                <CardContent>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    options={outboundCallerIdNumbers}
                    value={
                      outboundCallerIdNumbers.filter((option: any) =>
                        option.label === outboundCallerIdNumbervalue)
                    }
                    onChange={outboundCallerIdNumbershandleChange}
                  // value={outboundCallerIdNumbervalue}
                  //label={'Outbound CallerId Name'}
                  //name={'Outbound CallerId Name'}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item md={5} className={classes.grid}></Grid>
            <Grid item md={2} className={classes.grid}></Grid>
            <Grid item md={5} className={classes.grid}></Grid>
          </Grid>
        </div>
        <Divider className={classes.divider} />

        {/* <MyLogins /> */}

        <EfoneNumberDepartments />

        <MyDevice />

        <YourEfoneNumbersSchedule profileData={profileData}
          parentCallback={handleCallback} />
        <OpenHours />

        {closed ? "" : <CloseHours />}

        <UnAnsweredCalls />

        <AdvancedSettings />
      </Card>
    </div>
  );
}
export default Profile;

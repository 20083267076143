import { Button, Card, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from '../../style'
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select'
import axiosInstance from '../../../../../environment/axiosInstance'
import PurchaseTableBody from './PurchaseTableBody';
import AvailableNumbersTable from '../AvailableNumbersTable/AvailableNumbersTable';

function PurchaseNumbersTable(props:any) {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = React.useState(false)
    const [listofStates, setListOfStatesData] = React.useState([]);
    const [state, setState] = React.useState<any>({});
    const [cities, setCities] = React.useState<any>([]);
    const [city, setCity] = React.useState<any>([]);
    const [index, setIndex] = React.useState(0)
    const [isCitiesTableShown, setIsCitiesTableShown] = React.useState(true);
    const [availablenoIndex, setAvailableNumIndex] = React.useState(0)
    const [AvailableNumbers, setAvailableNumbers] = React.useState<any>([]);
    const [numbersavil, setNumbersAvail] = React.useState<any>([])
    const [searchkey, setSearchKey] = React.useState<any>("");

    const onClickViewNumber = (data: any) => {
        setIsCitiesTableShown(false);
        setLoading(true)
        setAvailableNumIndex(0)
        axiosInstance.get(`/teli/search-numbers?state=${data.state}&ratecenter=${data.ratecenter}`).then((res: any) => {
            if (res.data) {
            setAvailableNumbers(res.data.data)
            let sliceNo = res.data.data.slice(availablenoIndex, availablenoIndex + 10)
            setNumbersAvail(sliceNo)
            setLoading(false)
        } else {
            setLoading(false)
        }
        }).catch((err: any) => {
        setLoading(false)
        })
    }

    const getListofStates = () => {
        setLoading(true);
        axiosInstance.get('/teli/states-list').then((res: any) => {
            if (res.data) {
                setLoading(false);
                setListOfStatesData(res.data.data);
            } else {
                setLoading(false);
            }
        })
        .catch((err: any) => {
            setLoading(false);
        });
    }

    React.useEffect(() => {
        getListofStates();
    }, [])

    const handleChangeStateList = (e: any) => {
    var data = e.value
        setState(data)
        setLoading(true)
        axiosInstance.get(`/teli/rateCenters?state=${data}`).then((res: any) => {
            if (res.data) {
                setCities(res.data.data)
                let slicecity = res.data.data.slice(index, index + 10)
                setCity(slicecity)
                setLoading(false)
                setIndex(0)

            } else {
                setLoading(false)
            }
        }).catch((err: any) => {
        setLoading(false)
        })
    }

    var stateList: any = [];
    listofStates.map((item: any) => {
        var object = {
        "value": item.id,
        "label": item.value
        }
        stateList.push(object)
    })

    const handleChangeCities = () => {
        var myIndex = cities.length > index + 10 ? index + 10 : 0
        setIndex(myIndex)
        let slicecity = cities.slice(myIndex, myIndex + 10)
        setCity(slicecity)
    }

    const onClickBackButton = () => {
        setIsCitiesTableShown(true);
        setSearchKey("");
        setAvailableNumbers([]);
    }
     const handleChangeNumbers = () => {
        var myNoIndex = AvailableNumbers.length > availablenoIndex + 10 ? availablenoIndex + 10 : 0
        setAvailableNumIndex(myNoIndex)
        let sliceNo = AvailableNumbers.slice(myNoIndex, myNoIndex + 10)
        setNumbersAvail(sliceNo)

    }

  return (
    <div>
        <Grid container spacing={2} style={{marginTop:"20px",marginBottom:20}}>
            <Grid item md={3}>
                <ReactSelect  
                    placeholder="Select Field"
                    maxMenuHeight={200}
                    options={stateList}
                    onChange={handleChangeStateList}
                />
            </Grid>
        </Grid>
            {!isCitiesTableShown &&
                <>
                    <div>
                        <Typography style={{marginBottom:10}}>Total Records:{AvailableNumbers.length}</Typography>
                        <div>
                            <Button
                                size="small"
                                variant="outlined"
                                style={{float:'right',margin: '20px 30px'}}
                                onClick={onClickBackButton}>
                                Back
                            </Button>
                        </div>
                        <div>
                            <Card>
                                <AvailableNumbersTable 
                                    tableData={numbersavil}
                                    loading={loading}
                                    handleChangeNumbers={handleChangeNumbers}
                                />
                            </Card>
                        </div>
                    </div>
                </>
            }
           {isCitiesTableShown &&
            <>
                <Card  style={{ marginTop: '20px' }}>
                    <PurchaseTableBody
                        tableData={city}
                        loading={loading}
                        handleChangeCities={handleChangeCities}
                        onClickViewNumber={onClickViewNumber}
                    />
                </Card>
            </>
      }
        
    </div>
  )
}

export default PurchaseNumbersTable
import { makeStyles } from "@material-ui/core/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles(() => ({
  padding: {
    padding: 15,
  },
  card: {
    borderRadius: 10,
    background: "#FFFFFF",
    boxShadow: "0 0 0.5em #E2E2E2",
    overflow: "unset",
  },
  labelText: {
    fontSize: '14px',
    fontFamily: 'Work Sans',
    color: '#828282',
    fontWeight: 500,
  },
  radio: {
    '&$checked': {
      color: '#4D72F8'
    }
  },
  formPadding: {
    padding: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.8rem",
  },
  divider: {
    marginTop: -10,
    backgroundColor: "#E2E2E2",
  },
  heading: {
    color: "#2645ad",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.208955,
    textTransform: "none",
  },
  accordianNoFlex: {
    display: "block",
  },
  tablecolor: {
    backgroundColor: "#F1F1F1",
  },
  form: {
    padding: '10px 0px',
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
  },
  fontPadding: {
    padding: 5,
    fontStyle: "normal",
    fontWeight: 500,
    color: "#828282",
    letterSpacing: 0.208955,
    textTransform: "none",
    fontSize: 18,
  },
  fontPadding1: {
    padding: 5,
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#828282",
    letterSpacing: 0.208955,
    textTransform: "none",
  },
  fontPadding2: {
    padding: 5,
    fontSize: "0.8rem",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#828282",
    letterSpacing: 0.208955,
    textTransform: "none",
  },
  button: {
    margin: 10,
    fontSize: 12,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 0.208955,
    textTransform: "none",
    textAlign: "center",
    borderRadius: 25,
    color: "#4D72F8",
    padding: '6px 22px',
    backgroundColor: "#FFFFFF",
    borderColor: "#4D72F8",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
    "@media (max-width: 426px)":{
      height: 35,
      padding: '15px 60px 15px 60px',
      fontSize: 10
    }
  },
  blockedbutton: {
    display: "flex",
    alignItems: "center",
  },
  tableHead: {
    fontSize: "15px",
    color: '#7a7a7a',
    fontStyle: "normal",
    fontWeight: 500,
    fontFamily:'Work Sans'
  },
  blocknumber: {
    width: "25ch",
  },
  tableBody: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    color: "#787878",
    fontFamily:'Work Sans',
  },
  input: {
    width: "100%",
    height: "47px",
    background: " #FFFFFF",
    marginBottom: 15,
    borderRadius: "5px",
  },
  inputdate: {
    width: "100%",
    height: "47px",
    padding: "8px",
    color: "rgb(164, 165, 165)",
    borderRadius: "5px",
    border: "1px solid grey",

  },
  actionButton: {
    borderWidth: 1,
    minWidth: 0,
    padding: 10,
    borderRadius: 50,
  },
  adminIcon: {
    width: 25,
    height: 25,
    color: '#979797',
  },
  textColorOne: {
    margin: 7,
    color: '#707070',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 17,
  },
  tableCellBody: {
    padding: 5,
    fontSize: 16,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 0.21553,
    textAlign: 'center',
    color: '#464646',
  },
  submitButton: {
    margin: 10,
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '6px 22px',
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  deleteButton: {
    margin: 10,
    padding: '6px 22px',
    background: '#4D72F8',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': {
      background: '#4D72F8',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    margin: 10,
    padding: '6px 22px',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    marginLeft: 15,
    background: '#EFEFEF',
    border: '1px solid #AEAEAE',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#787878',
    fontSize: 14,
  },

  textAbsolute: {
    marginLeft: 10,
    fontStyle: "normal",
    letterSpacing: 0.208955,
    textTransform: "none",
    fontSize: '14px',
    fontFamily: 'Work Sans',
    color: '#828282',
    fontWeight: 500,
  },
  modalWidth: {
    width: "500px", padding: "0px 10px",
    "@media (max-width: 426px)":{
      width: "300px"
    }
  }
}));

import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { useStyles } from './styles';
// import usersAndNumbersServices from '../../userNumbersServices/usersNumbersServices';
import ReactSelect from 'react-select'
import axiosInstance from '../../../../environment/axiosInstance'
import { Multiselect } from "multiselect-react-dropdown";
import userservices from '../userServices';


function AddDepartment(props: any) {
  const classes = useStyles();
  const [loading, setloading] = React.useState(false)
  const [clientList, setClientList] = React.useState<any>([]);
  const [deptList, setDeptList] = React.useState<any>([]);
  const [selectedDeptList, setSelectedDeptList] = React.useState<any>([]);
  const [prefilledDeptList, setPrefilledDeptList] = React.useState<any>([]);
  const [departmentName, setDepartmentName] = React.useState({
    name: "",
    // companyId:"" 
  })

  // const onDepartmentChange = (e: any) => {
  //     setDepartmentName(e.target.value)
  // }

  const onDepartmentChange = (e: any) => {
    setDepartmentName((prev: any) => {
      return {
        ...prev,
        name: e.target.value,
      };
    });
  }

  const addUsertoDepartment = () => {

    userservices.addUsertoDepartment(selectedDeptList, props.selecteduserId).then((res) => {
      if (res.success === true) {
        props.handlePopupModal(props.selecteduserId)
        props.fetchList()
        props.fetchUsers()
      //  props.fetchUserLists()
        // setLoading(false)
        // setSubmit(false)
      } else {
        // setLoading(false)
        // setSubmit(false)
      }
    }).catch((err) => {
      // setLoading(false)
      // setSubmit(false)
    })
  }

  const createDepartment = () => {
    addUsertoDepartment()
  }


  const getDepartmentList = () => {
    axiosInstance.get(`/departments?userId=${props.selecteduserId}`).then((res: any) => {
      if (res.data.data) {
        const list = res.data.data[0].UnAssignedDepartments
        // console.log("list", list)
        const arr: any = []
        list.map((item: any) => {
          const obj: any = {
            value: item._id,
            label: item.name,
          }
          // console.log("obj", obj)
          arr.push(obj)
        })

        setDeptList(arr)

      }
    }).catch((err: any) => {

    })
  }
  // console.log("deptList",deptList)

  React.useEffect(() => {
    getDepartmentList()
    setPrefilledDeptList(props.selectedDeparmentList)
  }, [])



  const onChangeSelect = (e: any) => {
    // console.log(e)
    const selecteddeparr: any = []
    // e.map((item: any) => {
    //   selecteddeparr.push(item.value)
    // })
    setSelectedDeptList(e.value)
    // console.log(selecteddeparr)
  }


  return (
    <div>
      <CardContent>
        <Card className={classes.modalCard}>
          <CardContent>
            {/* <Typography className={classes.userText}>Add Department Name</Typography> */}
            <Grid container spacing={2} className={classes.gridItem}>
              <Grid item md={10} sm={12} className={classes.gridItem}>
                <div style={{ width: 280, height: 45 }}>
                  <ReactSelect placeholder='Select Department'
                    style={{ width: 280, height: 38 }}
                    options={deptList}
                    onChange={onChangeSelect}
                  // value={selectedteamMember}
                  />
                </div>
                {/* <Multiselect
                    options={deptList}
                    selectedValues={preSelectedValues}
                    showCheckbox
                    displayValue="key"
                    onSelect={onChangeSelect}
                    placeholder="Select Department"
                    onRemove={onClickRemoveSelectedvalue}
                  /> */}
                {/* <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter Department Name"
                  variant="outlined"
                  //value={departmentName}
                  onChange={onDepartmentChange}
                /> */}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={createDepartment}
                    className={classes.addDeptBtn}
                    style={{ height: 38 }}
                  >
                    Add
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </div>
  )
}

export default AddDepartment
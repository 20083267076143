import { Button, TableCell, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { GrNext } from 'react-icons/gr'
import { useStyles } from '../../style'

function TableBdy(props:any) {
  const classes = useStyles()
  return (
    <>
     <TableRow  key={props.item.id}>
        <TableCell className={classes.tableCellBody}>{props.item.state}</TableCell>
        <TableCell className={classes.tableCellBody}>{props.item.ratecenter}</TableCell>
        <TableCell className={classes.tableCellBody}>{props.item.areacodes}</TableCell>
        <TableCell className={classes.tableCellBody}>{props.item.available}</TableCell>
        <TableCell className={classes.tableCellBody}>
          <Button 
            style={{color:'#8392AB',border:'1px solid rgba(0, 0, 0, 0.23)',fontSize:14,fontWeight:500}} 
            size="small" variant='outlined' 
            onClick={props.onClickViewNumber}
          >
            View Numbers
          </Button>
        </TableCell>
    </TableRow>
   
    </>
  )
}

export default TableBdy
import {
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    makeStyles,
    FormControl, Avatar, Button, Tabs, Tab, AccordionDetails, Divider
} from '@material-ui/core';
import React from 'react'
import { useStyles } from './menuStyles';
import './style.css'
import Greeting from './IvrGreeting'
import IVRMenu from './IVRMenu'
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal'
import RecordAudioModal from '../../RecordAudioModal/RecordAudioModalNew';
import UploadAudioModal from '../../RecordAudioModal/UploadAudioFileModal';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
const muiTheme = createMuiTheme({});

const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });

function OpenHourIVR(props: any) {
    // const classes1 = useStyles()
    const classes = useStyles()
    const [srcss, setSrcss] = React.useState<any>('')
    const [filname, setfilname] = React.useState<any>('')
    const [shouldRecord, setShouldRecord] = React.useState(false)
    // const [openModalAdd, setopenModalAdd] = React.useState(true);
    const [value, setValue] = React.useState("2");



    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }

    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        // console.log(props?.tabValue)
        setValue(`${props?.tabValue}`)
    }, [props.tabValue])

    return (
        <div>
            <AccordionDetails className={classes.accordianNoFlex}>
                <div>
                    {
                        shouldRecord && (
                            <div className={classes.editmodal}>
                                <Backdrop handlePopupModal={handlePopupModalAdd} />
                                <Modal title='Edit IVR Greetings' handlePopupModal={handlePopupModalAdd} >
                                    <div>
                                        <Tabs
                                            value={value}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            className={classes.tabs}
                                            onChange={handleChange}
                                            id="editOpenHourGreetingsTab"
                                        >
                                            {/* <Tab
                                                label="Record Audio"
                                                value="1"
                                                className={value === "1" ? classes.tabActive : classes.tab}
                                            /> */}
                                            <Tab
                                                label="Upload Audio"
                                                value="2"
                                                className={value === "2" ? classes.tabActive : classes.tab}
                                            />

                                        </Tabs>
                                        <TabContext value={value}>
                                            <TabPanel value="1">
                                                <RecordAudioModal openHrFiles={props.soundFiles} />
                                            </TabPanel>
                                            <TabPanel value="2">
                                                <UploadAudioModal depId={props.depId} 
                                                data={props.callHandlingData.ivrGreeting}
                                                getOpenHours={props.getOpenHours}/>
                                            </TabPanel>
                                        </TabContext>
                                    </div>
                                </Modal>
                            </div>
                        )
                    }

                    <div className={classes.greetMainSection}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography className={classes.heading}>IVR Greetings</Typography>
                            <Typography className={classes.actionline}>Set rules for handling call for IVR Greetings</Typography>
                        </div>
                        <div>
                            <div className={classes.audioContainer}>
                                <ThemeProvider theme={muiTheme}>
                                    <ReactAudioPlayer
                                        controls
                                        // className={classes.audioPlayer}
                                        onLoadedMetadata={handleLoadMetadata}
                                        src={props.ivrVoiceData}
                                        className={classes.audioPlayers}
                                    />

                                </ThemeProvider>
                                <Button className={classes.editbtn} onClick={handlePopupModalAdd}>
                                    Edit
                                </Button>
                            </div>
                            <div style={{ textAlign: 'left', marginTop: '0px' }}>
                                {/* <p className={classes.filename}>Filename.mp3</p> */}
                                <span className={classes.filename}>{props.ivrVoiceName}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
            <Divider className={classes.divider} />
            <IVRMenu
                callHandlingData={props.callHandlingData}
                depId={props.depId}
                callHandlingOption={props.callHandlingOption}
                getOpenHours={props.getOpenHours}
            />
        </div>
    )
}

export default OpenHourIVR
import {companyModule} from './Actions'

export const fetchCompanySuccess = (data: any) => {
    return {
        type: companyModule.FETCH_COMPANY_SUCCESS,
        companyData: data
    }
}

export const setSelctedCompany = (data: any) => {
    return {
        type: companyModule.SET_SELECTED_COMPANY,
        selectedCompany: data
    }
}
import React from 'react'
import {
    Card,
    CardContent,
    Divider,
    TextField,
    Typography,
    Accordion,
    AccordionSummary, AccordionDetails,
    Grid,
} from '@material-ui/core';
import { useStyles } from './styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import SearchFilter from './SearchFilter';
import Table from './Table';
function Index() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => {
        setOpen(!open)
    }
    return (
        <div>
            <Card className={classes.card}>
                <Accordion expanded={open}
                    onChange={handleOpen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Addons
                        </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    {/* <CardHeader name={'Name'} /> */}

                    <AccordionDetails className={classes.accordianNoFlex}>
                        <Table />
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    )
}
export default Index
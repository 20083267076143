import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    textField: {
        marginTop: 25,
    },
    textColor: {
        color: '#9A9A9A',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        margin: '0 8px'
    },
    textColorOne: {
        margin: 7,
        color: '#707070',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 17,
    },
    submitButton: {
        margin: 10,
        background: '#4D72F8',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 4,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        padding: "6px 22px",
        color: '#FFFFFF',
        fontSize: 14,
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
        }   
    },
    cancelButton: {
        margin: 10,
        padding: "6px 22px",
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        boxSizing: 'border-box',
        borderRadius: 4,
        color: '#787878',
        fontSize: 14,
    }

}))
import React from "react";

import {
  Card,
  Grid,
  Button,
  CardContent,
  Typography,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { TripOrigin, MoreHoriz } from "@material-ui/icons";
import { useStyles } from "./styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { CardHeader } from "../../../components/CardHeader/CardHeader";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import addIcon from "../../../assets/icons/addIcon.png";
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
import TextField from "@material-ui/core/TextField";
import ProfileServices from "../ProfileServices/ProfileServices";
import AddNumberModel from "./AddNumberModel";
import BlockNumberTable from "./BlockNumberTable";
import { useSelector } from "react-redux";

export function AdvancedSettings(): JSX.Element {
  const classes = useStyles();
  const profileStoreData = useSelector((state: any) => state.profileData);
  const { profileData } = profileStoreData;

  const [advancedSettings, settAdvancedSettings] = React.useState<any>({
    recordAllCallsForDirectNumbers: false,
    receiveEmailNotifications: false,
    announceUser: false,
    directCalls: false,
    departmentCalls: false,
    smsAutoReplay: "1",
    anonymousCallers: false,
    callersNotInContactsList: false,
  });
  function createData(
    number: any,
    blockdate: any,
    lastcall: any,
    blockcall: any,
    blocksms: any
  ) {
    return { number, blockdate, lastcall, blockcall, blocksms };
  }

  const [blockedNumberData, setBlockedNumberData] = React.useState<any>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);


  const expandopen = () => {
    setOpen(!open)
  }

  const getBlockedNumberData = () => {
    setLoading(true);
    ProfileServices.getBlockedNumber()
      .then((res: any) => {
        if (res.data) {
          setBlockedNumberData(res.data);

        }
      })
      .catch((err: any) => {
        setLoading(false);
        //send err message
      });
  };


  const handleChangeOption = (event: any) => {
    settAdvancedSettings((prev: any) => {
      return {
        ...prev,
        [event.target.name]: !advancedSettings[event.target.name]
      }
    })
    updatedAdvancedSetting({ ...advancedSettings, [event.target.name]: !advancedSettings[event.target.name] })
    // console.log(advancedSettings)
  };
  const handleChangesms = (event: any) => {
    console.log("------event", event.target.value)
    settAdvancedSettings((prev: any) => {
      return {
        ...prev,
        smsAutoReplay: event.target.value
      }
    })
    updatedAdvancedSetting({ ...advancedSettings, smsAutoReplay: event.target.value })
    // console.log(advancedSettings)
  };


  const handlePopupModal = () => {
    setOpenModal(!openModal);
  };
  // const onChangeDateandTime = (date: any) => {
  //   // console.log(date)
  //   const dndDatetime = { dndDate: new Date(date).toISOString() };
  //   // updateUserProfile(dndDatetime);
  //   setStartDate(date);
  // };

  const updatedAdvancedSetting = (data: any) => {
    ProfileServices.updateAdvancedSettings(data).then((res: any) => {

    }).catch((err: any) => {

    })
  }


  React.useEffect(() => {
    getBlockedNumberData();
  }, []);

  React.useEffect(() => {

    if (profileData) {
      const {
        directCalls,
        departmentCalls,
        smsAutoReplay,
        anonymousCallers,
        callersNotInContactsList,
        receiveEmailNotifications,
        recordAllCallsForDirectNumbers,
        announceUser } = profileData;

      settAdvancedSettings((prev: any) => {
        return {
          ...prev,
          "recordAllCallsForDirectNumbers": recordAllCallsForDirectNumbers,
          "receiveEmailNotifications": receiveEmailNotifications,
          "announceUser": announceUser,
          "directCalls": directCalls,
          "departmentCalls": departmentCalls,
          "smsAutoReplay": smsAutoReplay ? smsAutoReplay === 1 ? "1" : smsAutoReplay === 2 ? "2" : "" : "",
          "anonymousCallers": anonymousCallers,
          "callersNotInContactsList": callersNotInContactsList
        }
      })
    }

  }, [profileData])
  // console.log("------profiledata", profileData)
  return (
    <>
      <div className={classes.padding}>
        {openModal && (
          <div>
            <Backdrop handlePopupModal={handlePopupModal} />
            <Modal title="Block Number" handlePopupModal={handlePopupModal}>
              <div  className={classes.modalWidth} style={{  }}>
                <AddNumberModel
                  getBlockedNumberData={getBlockedNumberData}
                  handlePopupModal={handlePopupModal}
                />
              </div>
            </Modal>
          </div>
        )}
        <Card className={classes.card}>
          <Accordion onChange={expandopen}>
            <AccordionSummary
              expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Advanced Settings
              </Typography>
            </AccordionSummary>
             <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
              <CardContent style={{marginTop:-6}}>
                <FormGroup>
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.recordAllCallsForDirectNumbers}
                        onChange={handleChangeOption}
                        name="recordAllCallsForDirectNumbers"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Record all calls to/from my direct eFone numbers
                      </span>
                    }
                  />
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.receiveEmailNotifications}
                        onChange={handleChangeOption}
                        name="receiveEmailNotifications"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Receive emails notifications for new voice mails
                      </span>
                    }
                  />
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.announceUser}
                        onChange={handleChangeOption}
                        name="announceUser"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Announce User or Department Name upon the answering the
                        call
                      </span>
                    }
                  />
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.directCalls}
                        onChange={handleChangeOption}
                        name="directCalls"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Direct calls - Prompt Press1 to Accept or Press2 to
                        Reject the call
                      </span>
                    }
                  />
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.departmentCalls}
                        onChange={handleChangeOption}
                        name="departmentCalls"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Departments calls - prompt Press1 to Accept or Press2 to
                        Reject the call{" "}
                      </span>
                    }
                  />
                </FormGroup>
              </CardContent>

              <CardContent style={{marginTop:-10}}>
                <Typography className={classes.fontPadding}>
                  SMS AUTO - REPLY
                </Typography>
                <Typography gutterBottom className={classes.fontPadding1}>
                  When Enabled,this automatically reply to any text messages you
                  receive
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue=""
                    aria-label="gender"
                    style={{ marginLeft: "-16px" }}
                    value={advancedSettings.smsAutoReplay}
                    onChange={handleChangesms}
                    name="smsAutoReplay"
                  >

                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Automatically reply to message when do not disturb mode"
                      color="primary"
                      classes={{ root: classes.radio }}
                      className={classes.textAbsolute}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label=" Automatically reply to message outside received of my
                        personal working hours"
                      color="primary"
                      className={classes.textAbsolute}
                    />

                  </RadioGroup>
                </FormControl>

                {/* <div className={classes.form}>
                  <TripOrigin fontSize={"small"} />
                  <Typography className={classes.textAbsolute}>
                    Automatically reply to message when do not disturb mode
                  </Typography>
                </div>
                <div className={classes.form}>
                  <TripOrigin fontSize={"small"} />
                  <Typography className={classes.textAbsolute}>
                    Automatically reply to message outside received of my
                    personal working hours
                  </Typography>
                </div> */}
              </CardContent>
              <CardContent style={{marginTop:-10}}>
                <div className={classes.blockedbutton}>
                  <Typography className={classes.fontPadding}>
                    Call Blocking & Spam Prevention
                  </Typography>

                  <Button
                    onClick={handlePopupModal}
                    variant="outlined"
                    // size="small"
                    className={classes.button}
                    startIcon={<img src={addIcon} alt="add" />}
                  >
                    Add Number
                  </Button>
                </div>
                <Typography className={classes.fontPadding2} gutterBottom>
                  Prevent Certain type of callers from ringing you directely.
                  Calls and messages from these callers will go to the spam tab
                  of your inbox. send the following to voice mail and directely
                  to the spam folder without(sending you) a notification
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.anonymousCallers}
                        onChange={handleChangeOption}
                        name="anonymousCallers"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Anonymous Callers
                      </span>
                    }
                  />
                  <FormControlLabel
                    className={classes.formPadding}
                    control={
                      <Checkbox
                        checked={advancedSettings.callersNotInContactsList}
                        onChange={handleChangeOption}
                        name="callersNotInContactsList"
                        color="primary"
                      />
                    }
                    label={
                      <span className={classes.labelText}>
                        Callers not already in my contacts list
                      </span>
                    }
                  />
                </FormGroup>
              </CardContent>
              <CardContent style={{marginTop:-10}}>
                <Card className='marginTable'>
                  <Table>
                    <TableHead className={classes.tablecolor}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Number
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Block Date
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Last Call
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Block Calls
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Block SMS
                        </TableCell>
                        <TableCell className={classes.tableHead}></TableCell>
                        <TableCell className={classes.tableHead}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {blockedNumberData.map((row: any, i: any) => (
                        <BlockNumberTable
                          getBlockedNumberData={getBlockedNumberData}
                          row={row}
                          key={i}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </CardContent>
            </AccordionDetails>
          </Accordion>
        </Card>
      </div>
    </>
  );
}

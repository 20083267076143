import { put } from 'redux-saga/effects'
import * as globalpopupAlert from '../Actions/globalpopupAlert'
import * as profileActionCreators from '../Actions/userActionCreator'
import axiosInstance from '../../environment/axiosInstance'
import { userDepartmentsAction } from '../Actions/Actions'
// import { push } from 'react-router-redux';
// import { browserHistory } from 'react-router'
// import { history } from '../../App'
export function* init_profile_data(action: any): Generator {
    try {
        yield put(profileActionCreators.profileDataLoading(true))
        const response: any = yield axiosInstance.get("/profile");
        if (response.data.data) {
            const profileData: any = response.data.data;
            yield put(profileActionCreators.userProfileDataSuccess(profileData))
            yield put(profileActionCreators.profileDataLoading(false))
        } else {

            yield put(profileActionCreators.userProfileDataSuccess(null))
            yield put(profileActionCreators.profileDataLoading(false))
        }
    } catch (error: any) {
        if (error.response.status === 402) {
            // routerRedux.push({
            //     pathname: '/',
            //     search: JSON.stringify({
            //      redirect: window.location.href,
            //     })
            // })
            // yield put(push('/'));
            // history.push('/')
            // browserHistory.push('/')
        }
        yield put(profileActionCreators.profileDataLoading(false))
        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}


export function* init_openhours_data(action: any): Generator {
    try {

        const response: any = yield axiosInstance.get("/profile/open-hours");

        if (response.data.data) {
            const openhoursdata: any = response.data.data;
            yield put(profileActionCreators.initOpenhourSuccess(openhoursdata))

        } else {
            yield put(profileActionCreators.initOpenhourSuccess(null))

        }
    } catch (error: any) {

        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_closed_data(action: any): Generator {
    try {

        const response: any = yield axiosInstance.get("/profile/closed-hours");

        if (response.data.data) {
            const closedhours: any = response.data.data;
            yield put(profileActionCreators.initClosedhourSuccess(closedhours))

        } else {
            yield put(profileActionCreators.initClosedhourSuccess(null))

        }
    } catch (error: any) {

        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_unanswered_data(action: any): Generator {
    try {

        const response: any = yield axiosInstance.get("/profile/un-answered-calls");

        if (response.data.data) {
            const unanswred: any = response.data.data;
            yield put(profileActionCreators.initunansweredhourSuccess(unanswred))

        } else {
            yield put(profileActionCreators.initunansweredhourSuccess(null))

        }
    } catch (error: any) {

        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}

export function* init_users_departments(action: any): Generator {
    try {
        yield put(profileActionCreators.getUserDepartmentsRequest(true))

        const response: any = yield axiosInstance.get(`/users/${action.payload}`);

        if (response.data.data) {
            const userDepartments: any = response.data.data;
            yield put(profileActionCreators.getUserDepartmentsSuccess(userDepartments))
        } else {
            yield put(profileActionCreators.getUserDepartmentsSuccess(null))
        }

    } catch (error: any) {

        yield put(globalpopupAlert.initglobalPopup(error.message === 'Network Error' ? 'Network Error' : error.response.data.message, 'LOGIN_ROUTE_FAIL', 'error'))
    }
}
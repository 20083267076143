import React from 'react'
import {
    Card,
    CardContent,
    Divider,
    TextField,
    Typography,
    Accordion,
    AccordionSummary, AccordionDetails,
    Grid,
} from '@material-ui/core';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import axiosInstance from '../../../environment/axiosInstance'
import { useStyles } from './style';
import PhoneNumbersTable from './PhoneNumbersTable.tsx/PhoneNumbersTable';
import { useHistory } from 'react-router-dom';
import SearchFilter from './SearchFilter';

function PhoneNumbers() {
    const classes = useStyles()
    const history = useHistory()
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState<any>(false);
    const [phoneNumbersData,setPhoneNumbersData] =React.useState({})
    const handleOpen = () => {
        setOpen(!open)
    }
     const [paginate, setPaginate] = React.useState<any>({
        page: 1,
        totalDocs: 0,
        limit: 50,
        totalPages: 0
    })
    const [searchData, setSearchData] = React.useState<any>({
        phone:'',
        status:'',
        assignedTo:''
    })
    const [searching, setSearching] = React.useState<any>(false)

    const searchPhoneNumber = (limit: any, page: any) => {
        if (searchData.phone !== '' && searchData.status !== '' && searchData.assignedTo !== '') {
        setLoading(true)
        axiosInstance.get(`/admin/phone-numbers?limit=50&phone=${searchData.phone}&status=${searchData.status}&assignedTo=${searchData.assignedTo}`).then((res) => {
            if (res.status === 200) {
            setLoading(false)
            setPhoneNumbersData(res.data.data)
            setPaginate((prev: any) => {
                return {
                ...prev,
                    page: res.data.page,
                    totalDocs: res.data.totalDocs,
                    limit: res.data.limit,
                    totalPages: res.data.totalPages
                }
            })
            setSearching(true)
            } else {
            setSearching(true)
            setLoading(false)
            }

        }).catch((err) => {
            setSearching(false)
            setLoading(false)
        })
        }
        else if (searchData.phone !== '') {
        setLoading(true)
        axiosInstance.get(`/admin/phone-numbers?limit=50&phone=${searchData.phone}`).then((res) => {
            if (res.status === 200) {
            setLoading(false)
            setPhoneNumbersData(res.data.data)
            setPaginate((prev: any) => {
                return {
                    ...prev,
                    page: res.data.page,
                    totalDocs: res.data.totalDocs,
                    limit: res.data.limit,
                    totalPages: res.data.totalPages
                }
            })
            setSearching(true)
            } else {
            setSearching(true)
            setLoading(false)
            }

        }).catch((err) => {
            setSearching(false)
            setLoading(false)
        })
        }
        else if (searchData.status !== '') {
        setLoading(true)
        axiosInstance.get(`/admin/phone-numbers?limit=50&status=${searchData.status}`).then((res) => {
            if (res.status === 200) {
            setLoading(false)
            setPhoneNumbersData(res.data.data)
            setPaginate((prev: any) => {
                return {
                    ...prev,
                    page: res.data.page,
                    totalDocs: res.data.totalDocs,
                    limit: res.data.limit,
                    totalPages: res.data.totalPages
                }
            })
            setSearching(true)
            } else {
            setSearching(true)
            setLoading(false)
            }

        }).catch((err) => {
            setSearching(false)
            setLoading(false)
        })
        }
        else if (searchData.assignedTo !== '') {
            setLoading(true)
            axiosInstance.get(`/admin/phone-numbers?limit=50&assignedTo=${searchData.assignedTo}`).then((res) => {
                if (res.status === 200) {
                setLoading(false)
                setPhoneNumbersData(res.data.data)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit,
                        totalPages: res.data.totalPages
                    }
                })
                setSearching(true)
                } else {
                setSearching(true)
                setLoading(false)
                }

            }).catch((err) => {
                setSearching(false)
                setLoading(false)
            })
        }
    }



     const getPhoneNumbers = (limit: any, page: any,) => {
        setLoading(true)
        axiosInstance.get(`/admin/phone-numbers?limit=${limit}&page=${page}`).then((res: any) => {
            // console.log("phoneNumvers____",res)
            if (res.data) {
                setPhoneNumbersData(res.data.data)
                setLoading(false)
                //setPhoneNumbersData(res.data.data)
                setPaginate((prev: any) => {
                    return {
                        ...prev,
                        page: res.data.page,
                        totalDocs: res.data.totalDocs,
                        limit: res.data.limit,
                        totalPages: res.data.totalPages
                    }
                })
                //setSearching(false)
            }
            else {
                //setSearching(false)
                setLoading(false)
            }
        }).catch((err: any) => {
            //setSearching(false)
            setLoading(false)


        })
    }

    const onChangeSearchData = (name: any, value: any) => {
        setSearchData((prev: any) => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }

    const handleChangePage = (data: any, value: any) => {
        setPaginate((prev: any) => {
            return {
                ...prev,
                page: value
            }
        })
        getPhoneNumbers(paginate.limit, value)
    }

     const clearFilter = () => {
        getPhoneNumbers(paginate.limit, 1)
        setSearchData((prev: any) => {
        return {
            ...prev,
            phone:'',
            status:'',
            assignedTo:''
        }
        })
        setSearching(false)
    }

    React.useEffect(() => {
        getPhoneNumbers(paginate.limit,paginate.page)
    },[])

  return (
    <Card className={classes.card}>
        <Accordion expanded={open}
            onChange={handleOpen}>
            <AccordionSummary
                expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading} >
                    Phone Numbers
                </Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <Typography className={classes.searchText} style={{marginTop:10}}>
                Search Filters
            </Typography>
            <AccordionDetails className={classes.accordianNoFlex}>
                <SearchFilter 
                    searchData={searchData}
                    onChangeSearchData={onChangeSearchData}
                    clearFilter={clearFilter}
                    searchPhoneNumber={searchPhoneNumber}  
                />
            </AccordionDetails>
            <AccordionDetails className={classes.accordianNoFlex}>
                <PhoneNumbersTable 
                    phoneNumbersData={phoneNumbersData}
                    paginate={paginate}
                    getPhoneNumbers={getPhoneNumbers}
                    loading={loading}
                    handleChangePage={handleChangePage}
                />
            </AccordionDetails>
        </Accordion>
    </Card>
  )
}

export default PhoneNumbers
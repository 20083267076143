import React from 'react';
import { Card, CardContent, Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
//import MySignal from 'assets/icons/Signal.png';
import MySignal from '../../../../assets/icons/Signal.png'
import MyDepts from '../../../../assets/icons/Dept.png';
import GroupUsers from '../../../../assets/icons/groupUsers.png';
import MyTelephone from '../../../../assets/icons/MyTelephone.png';
import MyRecords from '../../../../assets/icons/Record.png';
import { useStyles } from './styles';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { MdPhonelink, MdSettingsPhone } from 'react-icons/md'
import { RiUserVoiceFill, RiTeamFill } from 'react-icons/ri'
import { HiOfficeBuilding } from 'react-icons/hi'
import { BiMessageDetail } from 'react-icons/bi'
import profileServices from '../ProfileServices/ProfileServices'
import { useSelector, useDispatch } from 'react-redux'
import * as profileActionCreator from '../../../store/Actions/userActionCreator'
import CloseHoursVoiceMail from './CloseHoursVoiceMail/CloseHoursVoiceMail';
import CloseHoursDepartments from './CloseHoursDepartments/CloseHoursDepartments';
import CloseHoursRecMessages from './CloseHoursRecMessages/CloseHoursRecMessages';
import CloseHoursPhoneNumber from './CloseHoursPhoneNumber/CloseHoursPhoneNumber';




const openhoursOptions = [
  { key: 6, value: "voicemail" },
  { key: 3, value: "departments" },
  // { key: 4, value: "teammembers" }, 
  { key: 5, value: "phonenumbers" },
  { key: 7, value: "recmessages" }]

function CloseHours() {
  const classes = useStyles();
  const profileStoreData = useSelector((state: any) => state.profileData)
  const { profileData, loading } = profileStoreData;
  const [tabname, setTabName] = React.useState('6');
  const [open, setOpen] = React.useState(true);
  const [closedData, setClosedData] = React.useState<any>({})
  const [deptPath, setDeptPath] = React.useState('');
  const [deptFileName, setDeptFileName] = React.useState('');
  const [forwardPath, setForwardPath] = React.useState('');
  const [forwardFileName, setForwardFileName] = React.useState('');
  const [voicePath, setVoicePath] = React.useState('');
  const [voiceFileName, setVoiceFileName] = React.useState('');
  const [recPath, setRecPath] = React.useState('')
  const [recFileName, setRecFileName] = React.useState('')
  const dispatch = useDispatch();
  const [selectedOption, setselectedOption] = React.useState<any>({
    currentSelectedOption: null
  })

  const expandOpen = () => {
    setOpen(!open);
  }

  const handleTabChange = (tabname: any) => {
    const findKeyValue: any = openhoursOptions.find((openOption: any) => openOption.value === tabname)
    if (findKeyValue?.key === 3) {
      profileServices.updateClosedHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
        getCloseHours()
        //send success message
      }).catch((err: any) => {
        //send error message
      })
    }
    if (findKeyValue?.key === 5) {
      profileServices.updateClosedHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
        getCloseHours()
        //send success message
      }).catch((err: any) => {
        //send error message
      })
    }
    if (findKeyValue?.key === 6) {
      profileServices.updateClosedHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
        getCloseHours()
        //send success message
      }).catch((err: any) => {
        //send error message
      })
    }
    if (findKeyValue?.key === 7) {
      profileServices.updateClosedHours({ currentSelectedOption: findKeyValue?.key }).then((res: any) => {
        getCloseHours()
        //send success message
      }).catch((err: any) => {
        //send error message
      })
    }
    setTabName(tabname)
  }
  const getCloseHours = () => {
    profileServices.getClosedHours().then((res) => {
      // console.log('closeddata', res);
      if (res.success) {
        const data = res.data
        setClosedData(data)
        if (data.currentSelectedOption === 3) {
          const deptData = data.userAndDepartments.transferingAudio
          deptData.map((item: any) => {
            if (item.isSelected === true) {
              setDeptPath(item.path)
              setDeptFileName(item.fileName)
            }
          })
          if (deptData.length === 0) {
            setDeptPath("")
            setDeptFileName("")
          }
        }
        if (data.currentSelectedOption === 5) {
          const forwardData = data.forwardNumbers.transferingAudio
          forwardData.map((item: any) => {
            if (item.isSelected === true) {
              setForwardPath(item.path)
              setForwardFileName(item.fileName)
            }
          })
          if (forwardData.length === 0) {
            setForwardPath("")
            setForwardFileName("")
          }
        }
        if (data.currentSelectedOption === 6) {
          const voiceData = data.voicemail.voicemailAudio
          voiceData.map((item: any) => {
            if (item.isSelected === true) {
              setVoicePath(item.path)
              setVoiceFileName(item.fileName)
            }
          })
          if (voiceData.length === 0) {
            setVoicePath("")
            setVoiceFileName("")
          }
        }
        if (data.currentSelectedOption === 7) {
          const recData = data.recording.recordingAudio
          recData.map((item: any) => {
            if (item.isSelected === true) {
              setRecPath(item.path)
              setRecFileName(item.fileName)
            }
          })
          if (recData.length === 0) {
            setRecPath("")
            setRecFileName("")
          }
        }
      }
    }).catch(err => {

    })
  }
  React.useEffect(() => {
    if (profileData) {
      setClosedData(profileData.closedHours)
    }

  }, [profileData])

  React.useEffect(() => {
    if (closedData) {
      const closedHours = closedData;
      // console.log('dd',closedHours);
      openhoursOptions.forEach((option: any) => {
        // console.log('option',option,closedHours);

        if (option.key === closedHours?.currentSelectedOption) {
          setTabName(option.value)
        }
      })
    }
  }, [closedData])
  React.useEffect(() => {
    getCloseHours()
  }, [])

  React.useEffect(() => {
    dispatch(profileActionCreator.initClosedhour())
  }, [])



  return (
    <>
      <div className={classes.padding}>
        <Card className={classes.card}>
          <Accordion expanded={open} onChange={expandOpen}>
            <AccordionSummary
              //expandIcon={open ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Close Hours Call Handling</Typography>
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails className={classes.accordianNoFlex}>
              <CardContent style={{}}>
                <div className={classes.gridJustify}>
                  <Card
                    className={tabname === 'departments' ? classes.cardWidth : classes.cardWidth1}
                    onClick={() => handleTabChange("departments")}>
                    <CardContent className={classes.align}>
                      <HiOfficeBuilding className={tabname === 'departments' ? classes.deviceWidth1 : classes.deviceWidth} />
                      {/* <img className={classes.deviceWidth} alt="vector" src={MyDepts} /> */}

                      <Typography
                        className={tabname === 'departments' ? classes.devicesText : classes.devicesText1}>
                        User & Dept
                      </Typography>
                    </CardContent>
                  </Card>


                  <Card
                    className={tabname === 'phonenumbers' ? classes.cardWidth : classes.cardWidth1}
                    onClick={() => handleTabChange("phonenumbers")}>
                    <CardContent className={classes.align}>
                      <MdSettingsPhone className={tabname === 'phonenumbers' ? classes.deviceWidth1 : classes.deviceWidth} />
                      {/* <img className={classes.deviceWidth} alt="MyTelephone" src={MyTelephone} /> */}

                      <Typography
                        className={tabname === 'phonenumbers' ? classes.devicesText : classes.devicesText1}>
                        Forward Numbers
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card
                    className={tabname === 'voicemail' ? classes.cardWidth : classes.cardWidth1}
                    onClick={() => handleTabChange("voicemail")}>
                    <CardContent className={classes.align}>
                      <RiUserVoiceFill
                        className={
                          tabname === "voicemail"
                            ? classes.deviceWidth1
                            : classes.deviceWidth
                        } />
                      <Typography className={tabname === 'voicemail' ? classes.devicesText : classes.devicesText1}>
                        Voice Mail
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card
                    className={tabname === 'recmessages' ? classes.cardWidth : classes.cardWidth1}
                    onClick={() => handleTabChange("recmessages")}>
                    <CardContent className={classes.align}>
                      <BiMessageDetail className={tabname === 'recmessages' ? classes.deviceWidth1 : classes.deviceWidth} />
                      {/* <img className={classes.deviceWidth} alt="MyRecords" src={MyRecords} /> */}
                      <Typography
                        className={tabname === 'recmessages' ? classes.devicesText : classes.devicesText1}>
                        Recorded Messages
                      </Typography>
                    </CardContent>
                  </Card>

                  {/* <Grid item md={2} sm={6} xs={12} className={classes.grid}>
                    <Card
                      className={tabname === 'teammembers' ? classes.cardWidth : classes.cardWidth1}
                      onClick={() => handleTabChange("teammembers")}>
                      <CardContent className={classes.align}>
                        <RiTeamFill className={tabname === 'teammembers' ? classes.deviceWidth1 : classes.deviceWidth} />


                        <Typography
                          className={tabname === 'teammembers' ? classes.devicesText : classes.devicesText1}>
                          Team Members
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}

                </div>
              </CardContent>

              {tabname === 'voicemail' && (
                <>
                  <CloseHoursVoiceMail
                    closedData={closedData}
                    getCloseHours={getCloseHours}
                    voicePath={voicePath}
                    voiceFileName={voiceFileName}
                  />
                </>
              )}
              {tabname === 'departments' && (
                <>
                  <CloseHoursDepartments
                    closedData={closedData}
                    getCloseHours={getCloseHours}
                    deptPath={deptPath}
                    deptFileName={deptFileName}
                  />
                  {/* <CloseHoursDepartments callHandlingOption={3} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} /> */}
                  {/* <OpenHoursDepartments /> */}
                  {/* <Departments /> */}
                </>
              )}
              {tabname === 'teammembers' && (
                <>
                  {/* <CloseHoursTeamMembers callHandlingOption={4} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} /> */}
                </>
              )}
              {tabname === 'phonenumbers' && (
                <>
                  <CloseHoursPhoneNumber
                    closedData={closedData}
                    getCloseHours={getCloseHours}
                    forwardPath={forwardPath}
                    forwardFileName={forwardFileName}
                  />
                  {/* <CloseHourPhoneNumbers callHandlingOption={5} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} /> */}
                </>
              )}
              {tabname === 'recmessages' && (
                <>
                  <CloseHoursRecMessages
                    closedData={closedData}
                    getCloseHours={getCloseHours}
                    recPath={recPath}
                    recFileName={recFileName}
                  />
                  {/* <CloseHoursRecMessages callHandlingOption={6} closeHour={closeHour} data={closedData} getCloseHours={getCloseHours} /> */}
                </>
              )}

            </AccordionDetails>
          </Accordion>


          {/* <CardHeader
            name={'Close Hours Call Handling'}
            textfield={false}
            label={'Close Hours Call Handling'}
            addbox={true}
          /> */}



        </Card>
      </div>
    </>
  );
}
export default CloseHours;
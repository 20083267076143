import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Card, Divider, Grid, Button, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Box } from '@material-ui/core'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Buttn from '@mui/material/Button'
import { useStyles } from './styles'
import { MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import Switch from '@material-ui/core/Switch';
import { GoSearch } from 'react-icons/go'
import { InputAdornment } from '@material-ui/core';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal'
import AddHoliday from './AddHoliday'
import UpdateHoliday from './UpdateHoliday'
import { useHistory, withRouter, useParams } from 'react-router-dom'
import Services from '../Holidays/Services/Services'
import axiosInstance from '../../../environment/axiosInstance'
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function Holidays() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState<any>(true);
    const [open, setOpen] = React.useState(false);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [openModal, setOpenModal] = React.useState<any>(false);
    const [deleteModal, setdeleteModal] = React.useState<any>(false);
    const [holidaysData, setHolidaysData] = React.useState<any>([])
    const [loading, setloading] = React.useState<any>(false)
    const [holidayId, setHolidayId] = React.useState<any>()
    const [updateHoliday, setUpdateHoliday] = React.useState<any>()
    const [allHolidaysData, setAllHolidaysData] = React.useState([])
    const [holodayName, setHolidayName] = React.useState<any>(" ")
    const [searchData, setSearchData] = React.useState<any>({})
    const [paginate, setPaginate] = React.useState({
        page: 1,
        totalCount: 0,
        limit: 10,
        totalPages: 0
    })
    const handlePopupModal = () => {
        setOpenModal(!openModal);
    };
    const expandOpen = () => {
        setExpanded(!expanded);
    }

    const id = useParams()
    const obj: any = id
    // console.log(obj.id)
    const fetchHolidays = async () => {
        setloading(true)
        await axiosInstance.get(`/departments/${obj.id}/holidays`)
        .then((response: any) => {
            if (response.data.statusCode === 200) {
                setHolidaysData(response.data.Data)
                setloading(false)
                console.log(response.data)
            }

        }).catch((response: any) => {
            setloading(false)
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid red"
                }, autoClose: 1000
            })
        })
    }

    React.useEffect(() => {
        fetchHolidays()
    }, [])

    const onClickdeleteHoliday = (id: any) => {
        axiosInstance.delete(`/departments/${obj.id}/holidays/${id}`)
        .then((response: any) => {

            // console.log(response)
            if (response.status === 200) {

                setdeleteModal(!deleteModal)
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid red"
                    }, autoClose: 1000
                })
                setloading(false)
                fetchHolidays()
            }

        }).catch((response: any) => {
            setloading(!loading)
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid red"
                }, autoClose: 1000
            })
        })
    }

    const updateStatus =  (item: any, id: any) => {        
         axiosInstance.patch(`/departments/${obj.id}/holidays/${id}`, item)
         .then((response: any) => {
            // setHolidaysData(response.data.Data)
            // console.log("response", response)
            if (response.status === 200) {
                fetchHolidays()
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    style: {
                        borderRadius: "2px",
                        border: "1px solid red"
                    }, autoClose: 1000
                })
                // history.push(`/departments/${props.departmentId}/holidays`)
            }
        }).catch((response: any) => {

            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                style: {
                    borderRadius: "2px",
                    border: "1px solid red"
                }, autoClose: 1000
            })
        })
    }

    const handleChange = (item: any, id: any) => {
        const obj: any = {
            name: item?.name,
            date: item?.date,
            repeat: item?.repeat,
            status: !item.status
        }
        // console.log(obj)
        // console.log(id)
        updateStatus(obj, id)
    }

    const handleDeleteHoliday = (id: any) => {
        setHolidayId(id)
        setdeleteModal(!deleteModal)
    }
    const handleupdateModal = (item: any) => {
        setUpdateHoliday(item)
        setOpenUpdate(!openUpdate)
    }
    // const handleDeskphoneName = (e: any) => {
    //     const enreredText = e.target.value
    //     // console.log(enreredText)
    //     // const letrersArr = []
    //     // const searcheData = holidaysData.find((item:any)=>{
    //     //     const arr = item.name.split(" ")
    //     //     if(item.name.split(" ").includes(enreredText)){
    //     //         return item;
    //     //     }
    //     // })
    //     // // console.log(searcheData)
    //     // setHolidaysData(searcheData)
    // }

    const handleDeskphoneName = (e: any) => {
        e.preventDefault();
        setHolidayName(e.target.value)

    }


    //saerch holidy
    const searchHolidayName = () => {
        if (holodayName !== '') {
            axiosInstance.get(`/departments/${obj.id}/holidays`).then((res:any) => {
                if (res.status === 200) {
                    setAllHolidaysData(res.data.Data)
                    Services.getholidayNameList(obj.id,holodayName).then((res:any) => {
                        if (res.success) {
                            setSearchData(res.data)
                            // let result = allDeskPhoneData.filter((data: any) => data.id === searchData?.id);
                            // setDeskphoneData(result);
                        }
                        else {
                            //fetchDeskphoneList(state.page, state.limit)
                        }
                    }).catch((err:any) => {
                        //fetchDeskphoneList(state.page, state.limit)
                    })
                }
            }).catch((err) => {
                //fetchDeskphoneList(state.page, state.limit)
            })

        }
        else {
            //fetchDeskphoneList(state.page, state.limit)
        }

    }

    const [search,setSearch] =React.useState('')

    return (
        <div>
            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeleteHoliday} />
                        <Modal title="Are you sure want to Delete this Holiday ?" handlePopupModal={handleDeleteHoliday} >
                            <div className={classes.deleteholidaypopup} >
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div>
                                            <Button size="medium" className={classes.deleteButton} onClick={()=>onClickdeleteHoliday(holidayId)}>Delete</Button>
                                            <Button size="medium" onClick={handleDeleteHoliday} className={classes.deltecancelButton}>Cancel</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div>
                )
            }


            {
                openModal && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModal} />
                        <Modal title="Add New Holiday" handlePopupModal={handlePopupModal} >
                            <AddHoliday departmentId={obj.id} fetchHolidays={fetchHolidays} handlePopupModal={handlePopupModal} />
                        </Modal>
                    </div>
                )
            }
            {
                openUpdate && (
                    <div>
                        <Backdrop handlePopupModal={handleupdateModal} />
                        <Modal title="Update Holiday" handlePopupModal={handleupdateModal} >
                            <UpdateHoliday data={updateHoliday} departmentId={obj.id} fetchHolidays={fetchHolidays} handleupdateModal={handleupdateModal} />
                        </Modal>
                    </div>
                )
            }
            <div className={classes.card}>
                <Accordion
                    expanded={expanded}
                //onChange={expandOpen}
                >
                    <AccordionSummary
                        expandIcon={expanded ? <FiMinusCircle color="lightgrey" onClick={expandOpen} /> : <FiPlusCircle color="#2645ad" onClick={expandOpen} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.dflexs}>
                            <Typography className={classes.heading}>Holidays</Typography>
                            <Button variant='outlined' className={classes.addButton} size="small" onClick={handlePopupModal}>Add New</Button>
                        </div>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <div className={classes.dflex}>

                        <Typography className={classes.callFlow}>Search</Typography>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <TextField fullWidth
                                    style={{ marginTop: 30, marginLeft: 20 }}
                                    size="small"
                                    variant="outlined"
                                    placeholder='search'
                                    value={search}
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment 
                                    //             //onClick={searchHolidayName}
                                    //             position="start">
                                    //             <GoSearch style={{ cursor: "pointer" }} />
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    onChange={(e) =>setSearch(e.target.value)}
                                    //onChange={handleDeskphoneName}
                                />
                            </Grid>
                        </Grid>

                    </div>
                    <div>
                        <Grid container spacing={2}>

                            <Grid item md={12} style={{ padding: '25px', marginTop: 30 }}>
                                <TableContainer className={classes.tablecontainer} >
                                    <Table style={{ overflowX:"auto",overflowY:"auto",}}>
                                        <TableHead>
                                            <TableRow className={classes.tRow}>
                                                <TableCell className={classes.tCell}>Title</TableCell>
                                                <TableCell className={classes.tCell}>Date</TableCell>
                                                <TableCell className={classes.tCell}>Repeat</TableCell>
                                                <TableCell className={classes.tCell}>Status</TableCell>
                                                <TableCell className={classes.tCell}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                loading ?
                                                    <Box className={classes.modalLdr}>
                                                        <CircularProgress />
                                                    </Box> :

                                                    holidaysData?.filter((itm:any) =>itm.name.toLowerCase().includes(search.toLowerCase())).map((item: any, id: any) => {

                                                        //  console.log(item)
                                                        const value = id % 2
                                                        //  console.log(value)
                                                        return (
                                                            <TableRow className={value === 1 ? classes.tRow : ''} key={id}>
                                                                <TableCell className={classes.tbody}>{item.name}</TableCell>
                                                                <TableCell>
                                                                    {item.date}
                                                                </TableCell>
                                                                <TableCell className={classes.tbody}>
                                                                    {item.repeat === 1 ? 'Daily' : item.repeat === 2 ? 'Weekly' : item.repeat === 3 ? 'Monthly' : item.repeat === 4 ? 'Yearly' : ''}
                                                                </TableCell>
                                                                <TableCell className={classes.tbody}>
                                                                    <Switch
                                                                        checked={item.status}
                                                                        onChange={() => handleChange(item, item._id)}
                                                                        color="primary"
                                                                        name="checkedB"
                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <MdOutlineEdit className={classes.tbody} style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => handleupdateModal(item)} />
                                                                    <RiDeleteBinLine className={classes.tbody} style={{ marginLeft: 13, fontSize: 18, cursor: 'pointer' }} onClick={() => handleDeleteHoliday(item._id)} />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    )

                                            }

                                        </TableBody>
                                    </Table>
                                    {
                                        holidaysData?.length <= 0 && <p style={{ textAlign: 'center', padding: 10 }}>No users found</p>
                                    }
                                </TableContainer>
                            </Grid>

                        </Grid>
                    </div>
                </Accordion>
            </div>
        </div>
    )
}

export default withRouter(Holidays)
import React from 'react'
import {
    CardContent,
    Button,
    Divider,
    AccordionDetails,
    Typography,
} from '@material-ui/core';
import {
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableHead, TableBody
} from '@material-ui/core';
import { useStyles } from './menuStyles'
import IVRMenuBody from './IVRMenuBody'
import { Delete, AddBox } from '@material-ui/icons';
import axiosInstance from '../../../../environment/axiosInstance';
import DepartmentServices from '../../DepartmentServices/DepartmentServices'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function IVRMenu(props: any) {
    const classes = useStyles();
    const [iverMenuList, setIvrMenuList] = React.useState<any>([]);
    const [actionList, setActionsList] = React.useState<any>([]);
    const [deptList, setdeptList] = React.useState<any>([]);
    const [fwdNumList, setfwdNumList] = React.useState<any>([]);
    const [voiceMailList, setvoiceMailList] = React.useState<any>([]);
    const [recMsgList, setrecMsgList] = React.useState<any>([]);
    const [ivrOptions, setIvrOptions] = React.useState<any>([])
    const [selectedOption, setselectedOption] = React.useState<any>({
        currentSelectedOption: null
    })

    const onAddIvrMenuList = () => {
        if (iverMenuList.length <= 9) {
            let IVRMenuData: any = {};
            IVRMenuData.option = iverMenuList.length;
            IVRMenuData.selectedOption = ''
            IVRMenuData.selectedValue = ''
            let ivrMenuArray = iverMenuList;
            let dataToBeUpdated = [...ivrMenuArray, IVRMenuData]
            console.log("IVRMenuData",IVRMenuData)
            setIvrMenuList(IVRMenuData)
            // var myData = newdata
            // myData.ivrOptions = dataToBeUpdated
            // myData.currentSelectedOption = props?.callHandlingOption
            // setNewData(myData)
            // updateDepartmentData()
            insertNewRecord(IVRMenuData)
        }
    }

    const insertNewRecord = (IVRMenuData:any) => {
        insertIvrOption(IVRMenuData)
    }

    const updateDepartmentData = () => {

        DepartmentServices.updateOpenHours(props.depId, iverMenuList).then((res: any) => {
            //send success message
            props.getOpenHours()
        }).catch((err: any) => {
            //send error message
        })
    }



    React.useEffect(() => {
        const ivrMenu: any = props.callHandlingData?.ivrOptions
        // console.log("callHandlingOption", props.callHandlingOption)
        setIvrMenuList(ivrMenu)

    }, [props.callHandlingData])

    React.useEffect(() => {
        const actionlist: any = props.callHandlingData?.ivrActions
        const deplist: any = props.callHandlingData?.Departments
        const fwdnumlist: any = props.callHandlingData?.ForwardNumbers
        const recmsglist: any = props.callHandlingData?.RecordedMessages
        const voicemlist: any = props.callHandlingData?.voiceMails
        setActionsList(actionlist)
        setdeptList(deplist)
        setfwdNumList(fwdnumlist)
        setvoiceMailList(voicemlist)
        setrecMsgList(recmsglist)

    }, [props.callHandlingData])




    const ivrActions: any = []
    actionList?.map((item: any) => {
        const actionItem: any = {
            value: `${item.actionId}`,
            label: item?.actionName
        }
        ivrActions.push(actionItem)
    })

    const departmentOptions: any = []
    deptList?.map((item: any) => {
        const Item: any = {
            value: item?._id,
            label: item?.name
        }
        departmentOptions.push(Item)
    })

    // console.log("departmentOptions", departmentOptions)

    const fwdNumOptions: any = []
    fwdNumList?.map((item: any) => {
        const Item: any = {
            value: item?._id,
            label: item?.phoneNumber,
            isSelected: item?.isSelected
        }
        fwdNumOptions.push(Item)
    })

    const recMsgOptions: any = []
    recMsgList?.map((item: any) => {
        const Item: any = {
            value: item?._id,
            label: item?.fileName,
            isSelected: item?.isSelected
        }
        recMsgOptions.push(Item)
    })

    const voiceMailOptions: any = []
    voiceMailList?.map((item: any) => {
        const Item: any = {
            value: item?._id,
            label: item?.fileName,
            isSelected: item?.isSelected
        }
        voiceMailOptions.push(Item)
    })

    const handleDeleteIvrMenu = async (id: any) => {
        // console.log("menuid:",id)
        await axiosInstance.delete(`/departments/${props.depId}/open-hours/automated-menu?id=${id}`).then((res: any) => {
            console.log(res)
            if (res.status === 200) {
                setIvrMenuList(res.data.data)
            }
            // props.getOpenHours()
        })
    }
    const updateIvrMenuList = async (data: any, id: any) => {
        await axiosInstance.patch(`/departments/${props.depId}/open-hours/automated-menu?id=${id}`, { "ivrOptions": [data] }).then((res: any) => {
            // console.log(res)
            if (res.status === 200) {
                setIvrMenuList(res.data.data)
            }
            // console.log(res.data.data)
            // props.getOpenHours()
        }).catch((res: any) => {
            toast.error("This Option is Already Present", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 1000
            })
        })
    }

    const insertIvrOption = async (data: any) => {

        // const ivrOptions: any = [data]
        // console.log("data", data)
        await axiosInstance.post(`/departments/${props.depId}/open-hours/automated-menu`, { "ivrOptions": [data] }).then((res: any) => {
            if (res.status === 200) {
                setIvrMenuList(res.data.data)
            }
        })
    }


    // console.log(props.ivrOptions)
    // console.log("iverMenuList",iverMenuList)

    // console.log("ivrOptions", ivrOptions, departmentOptions, fwdNumOptions, recMsgOptions, voiceMailOptions)
    return (
        <div style={{ marginTop: '35px' }}>
            <Typography className={classes.ivrmenu} style={{ color: '#4D72F8' }}>IVR Menu</Typography>
            <CardContent>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell>Menu</TableCell>
                                <TableCell >Action</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {
                                iverMenuList?.length > 0 && (

                                    iverMenuList.map((iverData: any, i: any) => (
                                        <IVRMenuBody
                                            iverData={iverData}
                                            ivrActions={ivrActions}
                                            deptOptions={departmentOptions}
                                            fwdNumOptions={fwdNumOptions}
                                            recMsgOptions={recMsgOptions}
                                            voiceMailOptions={voiceMailOptions}
                                            depId={props.depId}
                                            ivrOptions={props.ivrOptions}
                                            iverMenuList={iverMenuList}
                                            updateDepartmentData={updateDepartmentData}
                                            getOpenHours={props.getOpenHours}
                                            handleDeleteIvrMenu={handleDeleteIvrMenu}
                                            updateIvrMenuList={updateIvrMenuList}
                                            insertIvrOption={insertIvrOption}
                                        />
                                    ))
                                )
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    {
                        iverMenuList?.length <= 9 && (
                            <Button
                                className={classes.addButton}
                                variant="outlined"
                                startIcon={<AddBox />}
                                onClick={onAddIvrMenuList}>
                                Add
                            </Button>
                        )
                    }

                </div>
            </CardContent>

        </div>

    )
}

export default IVRMenu
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 31,
        height: 31,
        borderWidth: 2,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
        border: '2px solid #ebebeb',
        color: '#ebebeb',
        // borderColor: '#ebebeb',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
        [theme.breakpoints.down('md')]: {
            width: 25,
            height: 25,
          },
    },
    activeButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 31,
        height: 31,
        borderWidth: 2,
        borderRadius: 50,
        padding: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
        border: '2px solid #4D72F8',
        color: '#4D72F8',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
        [theme.breakpoints.down('md')]: {
            width: 25,
            height: 25,
          },
    },
    activeIcon: {
        width: 20,
        height: 21,
        color: '#4D72F8',
        [theme.breakpoints.down('md')]: {
            width: 15,
            height: 16,
          },
    },
    inActiveIcon: {
        width: 20,
        height: 21,
        color: '#8392AB',
        [theme.breakpoints.down('md')]: {
            width: 15,
            height: 16,
          },
    },
    updateuserAction:{
        [theme.breakpoints.down('md')]: {
            width: 15,
            height: 16,
          },
    },
    editUserMainContainer: {
        paddingRight: 60,
        paddingLeft: 60,
        paddingTop: 20,
        overflow: "auto"
    },
    addDeptBtn: {
        height: 51,
        background: '#4D72F8',
        border: '1px solid #F0EEEE',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 5,
        textTransform: 'none',
        color: '#FFFFFF',
        fontWeight: 700,
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#4D72F8',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)'
        },
        '&:active': {
            backgroundColor: '#4D72F8',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            height: 25,

        },
    },
    userName: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 19,
        letterSpacing: '0.1px',
        color: '#929292',
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
    userEmail: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '0.1px',
        color: '#929292',
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
    permissName: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        color: '#929292',
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    userIcon: {
        width: 20,
        height: 21,
        color: '#4D72F8',
    },
    adminIcon: {
        width: 20,
        height: 21,
        color: '#ebebeb',
    },
    userDeptCount: {
        width: 25, height: 25, border: '2px solid #4D72F8', background: '#FFFFFF', borderRadius: 50,
        textAlign: 'center', marginLeft: '10px',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        color: '#4D72F8'
    },
    card: {
        borderRadius: "6px"
    },
    users: {
        borderRadius: "nullpx",
        fontFamily: "Calibri",
        fontSize: "20px",
        color: '#4D72F8'
    },
    divider: {
        backgroundColor: '#D2D2D2',
        marginTop: -10,
        border: "1px solid #D2D2D2"
    },
    add: {
        background: "#4D72F8",
        border: " 1px solid #F0EEEE",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        color: '#FFFFFF',
        fontSize: '15px',
        padding: '8px 20px',
        textTransform: 'capitalize',
        float: 'right',
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
            alignItems: 'center',



        }

    },
    //     addcard: {
    //         background: "#F7F7F7",
    //         borderRadius: "5px",
    //         height: "85px",
    //         width: "1027px",
    //         left: "332px",
    //         top: "253px",


    // }
    textfield: {
        width: 380,
        height: 45,
        color: '#B4B3B3',
        marginRight: 50
        // background:'#B4B3B3'
    },
    permissionSelect: {
        width: '150px',
        padding: "0px 0px",
        marging: '0px 0px',
        background: '#FFFFFF',
        border: "1px solid",
        borderRadius: 4,
    },
    search: {
        color: '#B4B3B3',
        // 
    },
    permission: {
        background: '#F7F7F7',
        borderRadius: '5px',
        color: '#FFFEFE',
        marginTop: 80,
        marginBottom: 45,
        padding: 10,
        paddingLeft: 15,
        height: 85,
    },
    table: {
        background: '#FFFFFF',
        // border: '0px solid rgba(194, 194, 194, 0.47)',
        // borderRadius: '5px',
        marginTop: 40,
        overflowY: 'auto',
        overflowX: "auto"
    },
    text: {
        padding: 15,
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: 0.21553,
        color: '#000000BF',
        
    },
    body: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        color: '#929292',
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        }
    },
    avatar: {
        color: '#4D72F8',
        background: '#FFFFFF',
        border: '1px solid #4D72F8'
    },
    avatarflex: {
        display: 'flex',
        gap: 15
    },
    iconClor: {
        color: '#787878',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
           width:20,
           height:20
        }
    },
    textColor: {
        color: '#464646',
        marginLeft: 10,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: 8,
        }
    }
}))
import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    IconButton,
    Slider,
    withStyles,
} from '@material-ui/core';
import { useStyles } from '../Styles';
import { TableHeaderComponent } from '../../../../../components/Table/Table';
import { HeadsetMic, Person, Timeline, MoreHoriz } from '@material-ui/icons';
import TableBodyData from './TableBody'
import DepartmentServices from '../../../DepartmentServices/DepartmentServices'
import { useSelector } from 'react-redux';

function DepartmentAgents(props: any) {
    const classes = useStyles();
    const tableHeader = ['Name', 'Email', 'Department Permission', 'Action'];
    const [openHoursCopy, setOPenHoursCopy] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(false)
    const [userList, setUserList] = React.useState([]);
    const [openHoursAgents, setOpnehoursAgents] = React.useState([]);
    const departmentDataState = useSelector((state: any) => state.departmentModule);
    const { selectedDepartment } = departmentDataState;

    React.useEffect(() => {
        setOpnehoursAgents(props.assignedOperatorDt)
    }, [props.assignedOperatorDt, props.openHoursData])
    
    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.tablecont}>
                <TableHeaderComponent theadData={tableHeader} />
                {
                    props.openHoursData.Operators?.map((operator: any, i: any) => <TableBodyData
                        operator={operator}
                        loading={loading}
                        openHoursAgents={openHoursAgents}
                        depId={props.depId}
                        openHoursData={props.openHoursData}
                        getOpenHours={props.getOpenHours}
                    />
                    )
                }
            </Table>
            {props.openHoursData.Operators?.length <= 0 &&
                <p className={classes.pText}>
                    No Agents found
                </p>
            }
        </TableContainer>
    )
}

export default DepartmentAgents

import React from 'react'
import { Button, Grid, Typography, makeStyles, Tabs, Tab, } from '@material-ui/core'
import { useStyles } from '../style'
import Select from 'react-select'
import Backdrop from '../../../../../../components/Modal/Backdrop';
import Modal from '../../../../../../components/Modal/Modal'
import { TabContext, TabPanel } from '@material-ui/lab';
import axiosInstance from '../../../../../../environment/axiosInstance'
import ReactAudioPlayer from 'react-audio-player';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactSelect from 'react-select';
import UploadAudio from './UploadAudio';
import RecordAudio from './RecordAudio';

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const muiTheme = createMuiTheme({});

// import AudioCard from '../OpenHourGreeting/OpenHourGreetingCard'
const useStyleses = makeStyles(
  (theme: any) => {
    return {
      root: {
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
      },
      loopIcon: {
        color: '#4D72F8',
        '&.selected': {
          color: '#0921a9'
        },
        '&:hover': {
          color: '#4D72F8'
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      playIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      pauseIcon: {
        color: '#4D72F8',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeIcon: {
        color: '#4D72F8',
        fontSize: '28px',
        '&:hover': {
          color: '#4D72F8'
        }
      },
      volumeSlider: {
        color: '#4D72F8'
      },
      progressTime: {
        color: '#6b6b6d'
      },
      mainSlider: {
        color: '#4D72F8',
        '& .MuiSlider-rail': {
          color: '#4D72F8'
        },
        '& .MuiSlider-track': {
          color: '#4D72F8'
        },
        '& .MuiSlider-thumb': {
          color: '#4D72F8'
        }
      }
    }
  });
function UnAnsweredUserDepartments(props: any) {
  const classes = useStyles()
  const [value, setValue] = React.useState("2");
  const [shouldRecord, setShouldRecord] = React.useState(false)
  const [depts, setDepts] = React.useState([])
  const [deptValue, setDeptValue] = React.useState<any>('');
  const [teamValue, setTeamValue] = React.useState<any>('');
  const [teamMembers, setTeamMembers] = React.useState([])
  const handlePopupModalAdd = () => {
    setShouldRecord(!shouldRecord)
    // setopenModalAdd(!openModalAdd)
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };
  const handleLoadMetadata = (meta: any) => {
    const { duration } = meta.target;
    const styles = makeStyles({

    })
    // console.log(duration);
  }

  //departments
  React.useEffect(() => {
    const departments: any = []
    if (props.openHoursData) {
      props.openHoursData.Departments?.map((item: any) => {
        var myObj = {
          label: item.name,
          value: item._id
        }
        departments.push(myObj)
      })
      setDepts(departments)
    }
  }, [props.openHoursData])



  const handleDeptOnchange = (data: any) => {
    setDeptValue(data.value)
    const sendData = {
      departmentId: data.value
    }
    axiosInstance.patch(`/departments/${props.depId}/open-hours/agents/unanswered-calls/user-department`, sendData).then((res: any) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1000
        })
        props.getOpenHours()
      }
    })
  };

  //Team members
  React.useEffect(() => {
    const teamMembers: any = []
    if (props.openHoursData) {
      props.openHoursData.Agents?.map((item: any) => {
        var myObj = {
          label: item.firstName + ' ' + item.lastName,
          value: item.firstName + ' ' + item.lastName,
          id: item._id
        }
        teamMembers.push(myObj)
      })
      setTeamMembers(teamMembers)
    }
  }, [props.openHoursData])


  const handleTeamMembersOnchange = (data: any) => {
    setTeamValue(data.value)
    const sendData = {
      teamMemberId: data.id
    }
    axiosInstance.patch(`/departments/${props.depId}/open-hours/agents/unanswered-calls/user-department`, sendData).then((res: any) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1000
        })
        props.getOpenHours()
      }
    }).catch((err: any) => {
      //err
    })
  };


  return (
    <>
      {
        shouldRecord && (
          <div>
            <Backdrop handlePopupModal={handlePopupModalAdd} />
            <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
              <div className={classes.editmodal}>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                  onChange={handleChange}
                  id="editOpenHourGreetingsTab"
                  style={{}}
                >
                  {/* <Tab
                    label="Record Audio"
                    value="1"
                    className={value === "1" ? classes.tabActive : classes.tab}
                  /> */}
                  <Tab
                    label="Upload Audio"
                    value="2"
                    className={value === "2" ? classes.tabActive : classes.tab}
                  />

                </Tabs>
                <TabContext value={value}>
                  <TabPanel value="1">
                    <RecordAudio
                      openHoursData={props.openHoursData}
                      getOpenHours={props.getOpenHours}
                      depId={props.depId}
                      loading={props.loading}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <UploadAudio
                      openHoursData={props.openHoursData}
                      getOpenHours={props.getOpenHours}
                      depId={props.depId}
                      loading={props.loading}
                    />
                  </TabPanel>
                </TabContext>

              </div>
            </Modal>
          </div>
        )
      }
      <div style={{ marginLeft: '26px' }}>
        <div className={classes.audiocard}>
          <div className={classes.audioContainer}>
            <Grid container spacing={6} className={classes.usersandDeptCont}>
              <Grid item xs={12} sm={12} md={4}>
                <Typography className={classes.title}>To Team Member</Typography>
                <ReactSelect
                  placeholder="Select team Member"
                  options={teamMembers}
                  value={
                    teamMembers.filter((option: any) =>
                          option.id ===  props.openHoursData.unAnsweredCalls.userAndDepartments.teamMemberId)
                  }
                  onChange={handleTeamMembersOnchange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography className={classes.title}>To Department</Typography>
                <ReactSelect placeholder="Select department"
                  options={depts}
                  name="name"
                  value={
                    depts.filter((option: any) =>
                      option.value === props.openHoursData.unAnsweredCalls.userAndDepartments.departmentId)
                  }
                  onChange={handleDeptOnchange}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: 35 }}>
              <ThemeProvider theme={muiTheme}>
                <ReactAudioPlayer
                  controls
                  // className={classes.audioPlayer}
                  onLoadedMetadata={handleLoadMetadata}
                  src={props.deptPath}
                  className={classes.audioPlayers}
                />

              </ThemeProvider>
              <Button className={classes.editbtnn} onClick={handlePopupModalAdd}>
                Edit
              </Button>

            </div>

          </div>
          <div style={{ textAlign: 'left', marginTop: '0px' }}>
            {/* <p className={classes.filename}>Filename.mp3</p> */}
            <span className={classes.filename}>{props.deptFileName}</span>
          </div>
          {/* <AudioCard /> */}
        </div>
      </div>
    </>
  )
}

export default UnAnsweredUserDepartments
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from '../../style'
import TableBdy from './TableBdy'
import { GrNext } from 'react-icons/gr'

function AvailableNumbersTable(props:any) {
    const classes = useStyles()
  return (
    <div>
        <TableContainer>
                <Table>
                    <TableHead className={classes.tablecolor}>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                Phone Number
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                State
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Rate center
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Tier
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Vendor Id
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Vendor Name
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Action
                            </TableCell>

                        </TableRow>
                    </TableHead>
                  <TableBody>
                    {
                        props.loading === false && props.tableData.map((item: any) => {
                            return (
                                <TableBdy 
                                    item={item}
                                    key={item.id}
                                    tableData={props.numbersavil}
                                    loading={props.loading}
                                    handleChangeNumbers={props.handleChangeNumbers}
                                />
                            )
                        })
                    }
                           
                    </TableBody> 
                        
                </Table>
                {
                    props.loading ? <div className='loader_class'>
                        <div style={{ width: "100%",marginLeft: '130px', textAlign: "center" }}>
                                <CircularProgress />
                            </div>
                    </div> : props.tableData.length <= 0 && <p style={{ textAlign: 'center' }}>No Data</p>
                }
            </TableContainer>
            {props.tableData.length > 0 ?
            <div style={{ float: "right", margin: "15px", cursor: "pointer", display: "flex" }} onClick={props.handleChangeNumbers}>
            <Typography style={{ marginTop: "-4px" }}>Next</Typography> <GrNext onClick={props.handleChangeNumbers} />
            </div> : ""}
    </div>
  )
}

export default AvailableNumbersTable
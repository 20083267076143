import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: '19px 0px 0px 0px',
            borderRadius: 10,
            background: '#FFFFFF',
            boxShadow: '0 0 0.5em #E2E2E2',
        },
        heading: {
            color: "#2645ad",
            fontSize: 18,
            fontWeight: 600,
            letterSpacing: 0.208955,
            textTransform: 'none',
        },
        divider: {
            marginTop: -14,
            backgroundColor: '#E2E2E2',
        },

        accordianNoFlex: {
            display: 'block'
        },
        contentCard: {
            margin: 10,
            background: '#fbfbfb',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            borderRadius: 10,
        },
        cardContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        media: {
            margin: 10,
            height: 110,
            width: '30%',
            marginTop: 20,
            textAlign: 'center'
        },
        requestText: {
            fontSize: 24,
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: 700,
            color: '#595959',
            textAlign: 'center',
            letterSpacing: 0.21,
        },
        cardContentPort: {
            display: 'flex',
            marginLeft: 125,
            marginRight: 125,
            justifyContent: 'center',
            alignItems: 'center',
        },
        selfRequestText: {
            fontSize: 20,
            marginBottom: 10,
            padding: '5px 80px',
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: 400,
            color: ' #595959',
            textAlign: 'center',
        },
        portRequestText: {
            fontSize: 20,
            margin: 5,
            fontFamily: 'Arial',
            fontStyle: 'normal',
            fontWeight: 400,
            color: '#4D72F8',
            textAlign: 'center',
            letterSpacing: 0.1,

        },
        buttonContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            fontSize: 16,
            fontFamily: 'Arial',
            fontWeight: 700,
            textAlign: 'center',
            color: '#4D72F8',
            background: '#fbfbfb',
            borderRadius: 6,
            border: '1px solid #377DEF',
            padding: '6px 22px',
            '&:hover': {
                backgroundColor: '#FFFFFF', color: '#4D72F8',
            },
        },
        buttonHeadset: {
            marginLeft: 40,
            fontSize: 15,
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            textAlign: 'center',
            padding: '6px 22px',
            color: '#FFFFFF',
            background: '#4D72F8',
            borderRadius: 5,
            border: '1px solid #377DEF',
            '&:hover': {
                backgroundColor: '#4D72F8', color: '#FFFFFF',
            },
        },
        headset: {
            color: '#FFFFFF',
        },
        typography: {
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '15px',
            color: 'rgba(38, 55, 70, 0.8)'
        },
        textPlanceholder: {
            fontFamily: 'Arial',
            fontWeight: 400,
            fontSize: '10px',
            color: '#B6B5B5',
            // border: '1px solid #E7E7E7;',
            borderRadius: '3px',
        },
        errorMessage: {
            color: 'red',
            fontSize: '0.8rem',
            fontWeight: 500,
            marginTop: 0,
            marginBottom: 0,
        },
        inputdate: {
            width: "100%",
            height: "38px",
            padding: "8px",
            color: "black",
            borderRadius: "5px",
            border: "1px solid grey",
            fontSize: 15,
      
          },
        buttoncancel: {
            color: '#585858',
            background: '#C8C6C6',
            padding: '8px 20px',
            borderRadius: '5px',
            border: '1px solid #C8C6C6',
            '&:hover': {
                color: '#585858',
                background: '#C8C6C6',
            },
        },
        buttonRequest: {
            color: "#FFFFFF",
            background: '#4D72F8',
            padding: '8px 20px',
            border: '1px solid #377DEF',
            borderRadius: '5px',
            marginLeft: '20px',
            '&:hover': {
                backgroundColor: '#4D72F8', color: "#FFFFFF",
            },
        },
        divbuttons: {
            dispaly: 'flex',
            textAlign: 'center',
            marginTop: '30px',
        }
    }))
import React from "react";

import {
    Card,
    Grid,
    Button,
    CardContent,
    Typography,
    Divider,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormControl,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Table,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { TripOrigin, MoreHoriz } from "@material-ui/icons";
import { useStyles } from "./styles";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { BsThreeDots } from 'react-icons/bs';
import { BiMessageSquareAdd } from 'react-icons/bi'
import { useSelector } from "react-redux";
import ProfileServices from "../ProfileServices/ProfileServices";
import BlockNumbersTableData from "./BlockNumbersTableData";
import axiosInstance from '../../../environment/axiosInstance'
import Backdrop from "../../../components/Modal/Backdrop";
import Modal from "../../../components/Modal/Modal";
import AddNumberModel from "./AddNumberModel";

function AdvancedSettings() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true);
    const expandopen = () => {
        setOpen(!open)
    }
    const profileStoreData = useSelector((state: any) => state.profileData);
    const { profileData } = profileStoreData;
    const [blockedNumberData, setBlockedNumberData] = React.useState<any>([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);
    const [advancedSettingData, setAdvancedSettingData] = React.useState<any>([]);
    const [advancedSettings, settAdvancedSettings] = React.useState<any>({
        // recordAllCallsForDirectNumbers: false,
        // receiveEmailNotifications: false,
        // announceUser: false,
        // directCalls: false,
        // departmentCalls: false,
        // smsAutoReplay: "1",
        // anonymousCallers: false,
        // anounceUserOrDepartment:false,
        // callersNotInContactsList: false,

        recordCalls: false,
        receiveEmails:false,
        departmentCalls: false,
        forwardCalls:false,
        anounceUserOrDepartment:false,
        smsAutoReply:false,
        recordAllCallsForDirectNumbers: false,
        receiveEmailNotifications:false,
        anonymousCallers: false,


    });
    function createData(
        number: any,
        blockdate: any,
        lastcall: any,
        blockcall: any,
        blocksms: any
    ) {
        return { number, blockdate, lastcall, blockcall, blocksms };
    }


    const fetchAdvancedSetting = () => {
        axiosInstance.get('/profile/settings/advance-setting').then((res: any) => {
            console.log('resss', res);
            if (res.status === 200) {
                const data = res.data.data[0].advanceSetting
                setAdvancedSettingData(res.data.data[0].advanceSetting.blockedNumbers)
                settAdvancedSettings({
                    recordCalls: data.recordCalls,
                    anounceUserOrDepartment: data.anounceUserOrDepartment,
                    forwardCalls: data.forwardCalls,
                    departmentCalls: data.departmentCalls,
                    anonymousCallers: data.anonymousCallers,
                    directCalls: data.directCalls,
                    receiveEmails: data.receiveEmails,
                    smsAutoReply: data.smsAutoReply,
                    callersNotInContactsList: data.callersNotInContactsList
                })
            }

        }).catch((err) => {

        })
    }

    const getBlockedNumberData = () => {
        setLoading(true);
        ProfileServices.getBlockedNumber()
            .then((res: any) => {
                if (res.data) {
                    setBlockedNumberData(res.data);
                }
            })
            .catch((err: any) => {
                setLoading(false);
                //send err message
            });
    };


    const handleChangeOption = (event: any) => {
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                [event.target.name]: !advancedSettings[event.target.name]
            }
        })
        updatedAdvancedSetting({ [event.target.name]: !advancedSettings[event.target.name] })
        // console.log(advancedSettings)
    };
    const handleChangesms = (event: any) => {
        settAdvancedSettings((prev: any) => {
            return {
                ...prev,
                smsAutoReply: event.target.value
            }
        })
        updatedAdvancedSetting({ smsAutoReply: event.target.value })
        // console.log(advancedSettings)
    };


    const handlePopupModal = () => {
        setOpenModal(!openModal);
    };
    // const onChangeDateandTime = (date: any) => {
    //   // console.log(date)
    //   const dndDatetime = { dndDate: new Date(date).toISOString() };
    //   // updateUserProfile(dndDatetime);
    //   setStartDate(date);
    // };

    const updatedAdvancedSetting = (data: any) => {
        ProfileServices.updateAdvancedSettings(data).then((res: any) => {
            if (res.success) {
                fetchAdvancedSetting()
            }
        }).catch((err: any) => {

        })
    }


    React.useEffect(() => {
        getBlockedNumberData();
        fetchAdvancedSetting()
    }, []);

    // React.useEffect(() => {
    //     if (profileData) {
    //         const {
    //             directCalls,
    //             departmentCalls,
    //             smsAutoReplay,
    //             anonymousCallers,
    //             callersNotInContactsList,
    //             receiveEmailNotifications,
    //             recordAllCallsForDirectNumbers,
    //             announceUser } = profileData;

    //         settAdvancedSettings((prev: any) => {
    //             return {
    //                 ...prev,
    //                 "recordAllCallsForDirectNumbers": recordAllCallsForDirectNumbers,
    //                 "receiveEmailNotifications": receiveEmailNotifications,
    //                 "announceUser": announceUser,
    //                 "directCalls": directCalls,
    //                 "departmentCalls": departmentCalls,
    //                 "smsAutoReplay": smsAutoReplay ? smsAutoReplay === 1 ? "1" : smsAutoReplay === 2 ? "2" : "" : "",
    //                 "anonymousCallers": anonymousCallers,
    //                 "callersNotInContactsList": callersNotInContactsList
    //             }
    //         })
    //     }

    // }, [profileData])
    return (
        <div>
            {openModal && (
                <div>
                    <Backdrop handlePopupModal={handlePopupModal} />
                    <Modal title="Block Number" handlePopupModal={handlePopupModal}>
                        <div className={classes.modalWidth} style={{}}>
                            <AddNumberModel
                                getBlockedNumberData={getBlockedNumberData}
                                handlePopupModal={handlePopupModal}
                                fetchAdvancedSetting={fetchAdvancedSetting}
                            />
                        </div>
                    </Modal>
                </div>
            )}
            <Card className={classes.card}>
                <Accordion
                    expanded={open}
                    onChange={expandopen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="lightgrey" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Advanced Settings
                        </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <AccordionDetails className={classes.accordianNoFlex}>
                        <CardContent style={{ marginTop: -6 }}>
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.recordCalls}
                                            onChange={handleChangeOption}
                                            name="recordCalls"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Record all calls to/from my direct eFone numbers
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.receiveEmails}
                                            onChange={handleChangeOption}
                                            name="receiveEmails"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Receive emails notifications for new voice mails
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.anounceUserOrDepartment}
                                            onChange={handleChangeOption}
                                            name="anounceUserOrDepartment"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Announce User or Department Name upon the answering the
                                            call
                                        </span>
                                    }
                                />
                                {/* <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.directCalls}
                                            onChange={handleChangeOption}
                                            name="directCalls"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Direct calls - Prompt Press1 to Accept or Press2 to
                                            Reject the call
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.departmentCalls}
                                            onChange={handleChangeOption}
                                            name="departmentCalls"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Departments calls - prompt Press1 to Accept or Press2 to
                                            Reject the call{" "}
                                        </span>
                                    }
                                /> */}
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.forwardCalls}
                                            onChange={handleChangeOption}
                                            name="forwardCalls"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Forward Call  - Prompt Press1 to Accept or Press2 to Reject the Call
                                        </span>
                                    }
                                />
                            </FormGroup>
                        </CardContent>

                        <CardContent style={{ marginTop: -10 }}>
                            <Typography className={classes.fontPadding}>
                                SMS AUTO - REPLY
                            </Typography>
                            <Typography gutterBottom className={classes.fontPadding1}>
                                When Enabled,this automatically reply to any text messages you
                                receive
                            </Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    defaultValue=""
                                    aria-label="gender"
                                    style={{ marginLeft: "-16px" }}
                                    value={advancedSettings.smsAutoReply}
                                    onChange={handleChangesms}
                                    name="smsAutoReply"
                                >
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="Automatically reply to message when do not disturb mode"
                                        color="primary"
                                        classes={{ root: classes.radio }}
                                        className={classes.textAbsolute}
                                    />
                                    <FormControlLabel
                                        value="2"
                                        control={<Radio />}
                                        label=" Automatically reply to message outside received of my
                                                personal working hours"
                                        color="primary"
                                        className={classes.textAbsolute}
                                    />

                                </RadioGroup>
                            </FormControl>

                            {/* <div className={classes.form}>
                                <TripOrigin fontSize={"small"} />
                                <Typography className={classes.textAbsolute}>
                                    Automatically reply to message when do not disturb mode
                                </Typography>
                                </div>
                                <div className={classes.form}>
                                <TripOrigin fontSize={"small"} />
                                <Typography className={classes.textAbsolute}>
                                    Automatically reply to message outside received of my
                                    personal working hours
                                </Typography>
                                </div> 
                            */}
                        </CardContent>
                        <CardContent style={{ marginTop: -10 }}>
                            <div className={classes.blockedbutton} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className={classes.fonttPadding}>
                                    Call Blocking & Spam Prevention
                                </Typography>

                                <Button
                                    onClick={handlePopupModal}
                                    variant="outlined"
                                    // size="small"
                                    className={classes.button}
                                    startIcon={<BiMessageSquareAdd />}
                                >
                                    Add Number
                                </Button>
                            </div>
                            <Typography className={classes.fontPadding2} gutterBottom>
                                Prevent Certain type of callers from ringing you directely.
                                Calls and messages from these callers will go to the spam tab
                                of your inbox. send the following to voice mail and directely
                                to the spam folder without(sending you) a notification
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.anonymousCallers}
                                            onChange={handleChangeOption}
                                            name="anonymousCallers"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Anonymous Callers
                                        </span>
                                    }
                                />
                                <FormControlLabel
                                    className={classes.formPadding}
                                    control={
                                        <Checkbox
                                            checked={advancedSettings.callersNotInContactsList}
                                            onChange={handleChangeOption}
                                            name="callersNotInContactsList"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span className={classes.labelText}>
                                            Callers not already in my contacts list
                                        </span>
                                    }
                                />
                            </FormGroup>
                        </CardContent>
                        <CardContent style={{ marginTop: -10 }}>
                            <Card className='marginTable'>
                                <Table style={{ border: '1px solid #D6D6D6' }}>
                                    <TableHead className={classes.tablecolor}>
                                        <TableRow>
                                            <TableCell className={classes.tableHead}>
                                                Number
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Block Date
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Last Call
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Block Calls
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Block SMS
                                            </TableCell>
                                            <TableCell className={classes.tableHead}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {advancedSettingData.map((row: any, i: any) => (
                                            <BlockNumbersTableData
                                                getBlockedNumberData={getBlockedNumberData}
                                                fetchAdvancedSetting={fetchAdvancedSetting}
                                                row={row}
                                                key={i}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Card>
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    )
}

export default AdvancedSettings
import {
  Button,
  Card,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import React from "react";
import { BsThreeDots } from "react-icons/bs";
import Backdrop from "../../components/Modal/Backdrop";
import Modal from "../../components/Modal/Modal";
import AddContact from "./AddContact/AddContact";
import axiosInstance from "../../environment/axiosInstance";
import TableBodyData from "./TableBodyData";
import EditContact from './EditContact/EditContact';

import { useStyles } from "./style";
function Contacts() {
  const classes = useStyles();
  const [deleteModal, setdeleteModal] = React.useState<any>(false);
  const [openModal, setOpenModal] = React.useState<any>(false);
  const anchorRef: any = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const handleAddContactPopup = () => {
    setOpenModal(!openModal);
  };
  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const fetchContacts = () => {
    setLoading(true);
    axiosInstance.get("/contacts").then((res: any) => {
      if (res.status === 200) {
        setData(res.data.Data);
      }
    });
  };

  React.useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <div>
      {
        openModal && (
          <div>
            <Backdrop handlePopupModal={handleAddContactPopup} />
            <Modal title="Add Contact" handlePopupModal={handleAddContactPopup}>
              <div className={classes.divWdth}>
                <AddContact handlePopupModal={handleAddContactPopup} fetchContacts={fetchContacts} />
              </div>
            </Modal>
          </div>
        )
      }
      <Card>
        <div className={classes.dFlex}>
          <Typography className={classes.title}>Contacts</Typography>
          <Button
            onClick={handleAddContactPopup}
            className={classes.addBtn}
            size="small"
            variant="outlined"
          >
            Add Contact
          </Button>
        </div>
        <Divider />
        <div style={{ padding: 20 }}>
          <Card className={classes.tableCard}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>phone Number</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item: any) => {
                    return (
                      <TableBodyData
                        item={item}
                        fetchContacts={fetchContacts}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </Card>
    </div>
  );
}

export default Contacts;

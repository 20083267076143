import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({

  callhandling: {
    fontFamily: 'Calibri ! important',
    fontSize: '20px',
    fontWeight: 700,
    letterSpacing: "0em",
    color: '#4D72F8',
    marginTop: '20px',
    marginLeft: '30px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '15px',
    }
  },
  padding: {
    padding: 15,
  },
  card: {
    marginTop: 10,
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0 0 0.5em #E2E2E2',
    overflow: 'unset',
  },
  divider: {
    marginTop: -8,
    // backgroundColor: '#E2E2E2',
    backgroundColor: '#D2D2D2'
  },
  heading: {
    color: '#2645ad',
    fontSize: 18,
    fontWeight: 600,
  },
  accordianNoFlex: {
    display: 'block'
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  gridJustify: {
    // display: 'grid',
    // gridTemplateColumns: 'auto auto auto auto auto',
    // gridGap: '20px',
    // marginBottom: 20,
    // "@media (max-width: 1024px)": {
    //   gridTemplateColumns: 'auto auto auto',
    // }
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexStart',
    marginBottom: '20px',
    marginLeft: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      marginBottom: '10px',
      marginLeft: '15px',
      display:'inline-grid',
      gridTemplateColumns:'auto auto',
      gap:10,
    },
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: '10px',
    //   marginBottom: '10px',
    //   marginLeft: '15px',
    //   display:'inline-grid',
    //   gridTemplateColumns:'auto auto',
    //   gap:10,
    // }
  },
  cardWidth: {
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
    width: '180px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: '#4D72F8',
  },
  callhandlingcard: {
    width: '125px',
    height: '90px',
    // left: 535px,
    // top: 643px,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    marginRight: '20px',
    marginLeft: '0px'
  },
  callhandlingcardActive: {
    width: '125px',
    height: '90px',
    // left: 371px,
    // top: 636px,
    backgroundColor: '#4D72F8',
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: '10px',
    marginRight: '20px',
    marginLeft: '0px'
  },
  cardWidth1: {
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
    width: '180px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
  },
  align: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgcontainerActive: {
    backgroundColor: "#F3F5FF",
    width: 30,
    height: 30,
    borderRadius: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'

  },
  imgcontainerinActive: {
    backgroundColor: "#EAEAEA",
    width: 30,
    height: 30,
    borderRadius: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceWidth: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#8392AB',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },
  deviceWidth1: {
    width: 40,
    height: 40,
    borderRadius: 50,
    color: '#4D72F8',
    backgroundColor: '#fbfbfb',
    padding: '10px',
    border: '1px solid #ebebeb'
  },

  devicesText: {
    fontSize: '14px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  carddevicesText: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FAFAFA',
    marginTop: '10px'
  },
  carddevicesText1: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#828282',
    marginTop: '10px'
  },
  devicesText1: {
    fontSize: '14px',
    textAlign: 'center',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: '#464646',
  },
  headTexts: {
    fontSize: 20,
    marginTop: 20,
    marginLeft: 20,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#464646',
    letterSpacing: 0.208955,
    textTransform: 'none',
  },
  departmentNameText: {
    fontSize: 16,
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#8392AB",
    textTransform: "none",
    margin: '5px 20px 10px 22px',
  },
  tablecolor: {
    backgroundColor: '#F1F1F1',
  },
  tableHead: {
    fontSize: 18,
    color: '#464646',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  tableBody: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    color: '#787878',
  },
  addNewButton: {
    borderRadius: 50,
    border: '1px solid #CDCDCD',
    padding: '5px 10px',
    textTransform: 'capitalize',
    textAlign: 'center',
    marginRight: 30,
    background: '#FAFAFA',
    boxShadow: '0px 2px 10px rgba(0, 76, 222, 0.25)',
    boxSizing: 'border-box',

  },
  ringHandle: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: '0',
  },
  menuBody: {
    zIndex: 300
  }
}));

export const PrettoSlider = withStyles({
  root: {
    color: '#4D72F8',
    width: '75%',
    marginLeft: 10,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',

    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 14,
  },
  rail: {
    height: 10,
    borderRadius: 14,
  },
})(Slider);

import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    submitButton: {
        padding: '4px 50px',
        border: '1px solid #00407B',
        borderRadius: '8px',
        fontWeight: 'bold',
        color: '#fff',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        marginRight: 15,
        background: '#00407B',
        "&:hover": {
            color: '#00407B',
        },

    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    cancelButton: {
        padding: '4px 34px',
        border: '1px solid #7b7b7c',
        borderRadius: '8px',
        fontWeight: 'bold',
        color: '#7b7b7c',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        background: '#E7E7E7',
    },
    inputFields: {
        margin: '10px 0px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    cardstyles: {
        background: '#FFFFFF',
        borderRadius: '8px',
        boxShadow: '0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)',
    },
    security: {
        color: "#3A416F",
        fontWeight: 500,
        fontFamily: 'Work Sans',
        fontStyle: "normal",
        fontSize: 20,
    },
    divider: {
        background: '#E2E2E2',
        marginTop:-10
    },
    names: {
        color: '#8392AB',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        marginTop: -15,
    },
    password: {
        // marginTop: 6,
        color: '#8392AB',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        marginTop: -15,
    },
    mt10: { marginTop: 10, },
    textField: {
        fontSize: 14,
        // marginTop: 6,
    },
    saveButton: {
        marginTop: 14,
        float: 'right',
        height: 30,
        width: '40%',
        textTransform: 'capitalize',
        border: '1px solid #00407B',
        color: '#00407B',
        borderRadius: 50,
    },
    saveSec: {
        textAlign: "center",
        background: "#fff",
        padding: "20px 0px",
        margin: "20px 0px 0px",
        boxShadow: "0px 2px 10px rgb(0 0 0 / 20%)",
        borderRadius: "5px",
    },
    

}, { index: 1 })
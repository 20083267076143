import React from 'react'
import { Button, TextField } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from "react-router-dom";
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { InputAdornment } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as userAuthActionCreators from '../../../store/Actions/AuthActionCreator'
import './signin.css'
import { useStyles } from './style';
import socket from '../Config/socket';
const formGroup: any = [
    {
        field: 'email',
        valid: true,
        message: ''
    },
    {
        field: 'password',
        valid: true,
        message: ''
    },
]

function Signin(props: any) {
    const classes = useStyles();
    const inputRef: any = React.useRef(null)
    const [checkedRemember, setCheckedRemember] = React.useState<any>(true);
    const [formGroupData, setFormgroup] = React.useState<any>(formGroup)
    const [Loader, setLoader] = React.useState(false)
    const [userData, setUserData] = React.useState({
        email: '',
        password: ''
    })
    const [showPw, setShowPw] = React.useState(false)
    const userAuthState = useSelector((state: any) => state.authState)
    const { loading } = userAuthState
    const dispatch = useDispatch()
    const history = useHistory()

    // console.log('hhhhh', userAuthState);

    //Form validation
    const habdleshowPW = () => {
        setShowPw(!showPw)
    }
    function validateRegexEmail(email: any) {
        // eslint-disable-next-line
        const re: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function phonenumberValidation(phone: any) {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (phone.match(phoneno)) {
            return true;
        }
        else {
            return false;
        }
    }

    const validateForm = (dataToValidate: any) => {
        let formgroupCopy = [...formGroupData];

        

        if (dataToValidate.property === 'email') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Email should not be empty';
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && !validateRegexEmail(dataToValidate.value)) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = false;
                        formG.message = 'Please provide valid email address'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'email') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }

        if (dataToValidate.property === 'password') {
            if (dataToValidate.value === '') {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'Password should not be empty'
                    }
                })
                setFormgroup(formgroupCopy)
            } else if (dataToValidate.value !== '' && dataToValidate.value.length <= 5) {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = false;
                        formG.message = 'Password must be greater than or equal to  6 characters'
                    }
                })
                setFormgroup(formgroupCopy)
            } else {
                formgroupCopy.forEach((formG: any) => {
                    if (formG.field === 'password') {
                        formG.valid = true;
                        formG.message = ''
                    }
                })
                setFormgroup(formgroupCopy)
            }
        }

    }

    const onChangeEvent = (e: any) => {
        validateForm({ property: `${e.target.name}`, value: e.target.value })
        setUserData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleChangeRemember = () => {
        setCheckedRemember(!checkedRemember);
        localStorage.setItem('rememberme', JSON.stringify(!checkedRemember))
    };

    const submitSigninForm = (e: any) => {
        setLoader(true)

        e.preventDefault()

        // validateForm({ property: 'email', value: userData.email })
        validateForm({ property: 'password', value: userData.password })

        // let userLoginType = null;
        // if (validateRegexEmail(userData.email)){
        //     userLoginType = 'EMAIL'
        // } else if (phonenumberValidation(userData.email)){
        //     userLoginType = 'PHONE'
        // }
        // console.log('userLoginType', userLoginType)

        if (userData.email && userData.password) {
            //dispatch
            dispatch(userAuthActionCreators.initAuth(userData))
            setLoader(false)
        }
        else {
            setLoader(false)
        }
        if (checkedRemember) {
            localStorage.setItem('email', JSON.stringify(userData.email))
            localStorage.setItem('password', JSON.stringify(userData.password))
        }
        // socket.emit('user-login', objBody.user.id);
    }

    React.useEffect(() => {
        const getRemember: any = localStorage.getItem('rememberme')
        const parseRemem: any = getRemember === undefined ? undefined : JSON.parse(getRemember);

        if (parseRemem === null) {
            localStorage.setItem('rememberme', checkedRemember)
        } else {
            setCheckedRemember(parseRemem)
        }

    }, [checkedRemember])

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        setFormgroup(formGroup)
    }, [])

    React.useLayoutEffect(() => {
        console.log('2000', userAuthState?.userData?.id);
        if (userAuthState.isAuthenticated) {
            socket.emit('user-login', userAuthState?.userData?.id);
            if (userAuthState?.userData?.userType === 1) {
                history.push("/businessDashboard")
            }
            else if (userAuthState?.userData?.userType === 2 || userAuthState?.userData?.userType === 3) {
                history.push("/dashboard")
            }

        }
        else {
            history.push("/")
        }
    }, [userAuthState.isAuthenticated])

    return (
        <div className="signin_container1">
            <div className="signin_title1">
                <h2>Sign in</h2>
            </div>
            {loading ?
                <Box className={classes.marLt} sx={{}}>
                    <CircularProgress />
                </Box> :
                <div className="form_container1">
                    <form onSubmit={submitSigninForm}>
                        <div className="signin_input_section1">
                            <h4>Email</h4>
                            <input ref={inputRef} type="text" name="email" value={userData.email} onChange={onChangeEvent} />
                            {/* <p className="error_messages_popup">{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'email') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p> */}
                        </div>
                        <div className="signin_input_section1">
                            <h4>Password</h4>
                            {/* <input type="password" name="password" value={userData.password} onChange={onChangeEvent} /> */}
                            <TextField
                                type={showPw ? "text" : "password"} name="password" id="pasword" value={userData.password}
                                onChange={onChangeEvent}
                                style={{ padding: "3px 8px", width: "100%", margin: "2px auto 10px", borderRadius: "20px", outline: 0, border: "1px solid #c7c7c7" }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {showPw ? <FiEye onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} /> :
                                                <FiEyeOff onClick={habdleshowPW} style={{ cursor: "pointer", marginLeft: "-24px", marginTop: "-5px" }} />}
                                        </InputAdornment>
                                    ),
                                }} />
                            <p className="error_messages_popup">{formGroupData.map((fileds: any) => {
                                if (fileds.valid === false && fileds.field === 'password') {
                                    return fileds.message
                                } else {
                                    return ''
                                }
                            })}</p>
                        </div>
                        <div className="sign_form_action1">
                            <Link to={"/forget-password"} style={{ cursor: 'pointer', color: '#8392AB', textDecoration: 'none', }}>Forgot Password ?</Link>
                            <div>
                                <Checkbox
                                    checked={checkedRemember}
                                    onChange={handleChangeRemember}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <p style={{ color: '#2645AD' }}>Remember Me</p>
                            </div>
                        </div>
                        <div className="signin_btn_section1">
                            <Button type="submit">Login</Button>
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}

export default Signin

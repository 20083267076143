import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import ReactSelect from 'react-select'
import AudioCard from './DepartmentsGreetingCard'
import { useStyles } from './styles'
import axiosInstance from '../../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()


function OpenHoursDepartmentsNew(props: any) {
    const classes = useStyles()

    const depOptions: any = []
    props.udsDept?.map((item: any) => {
        const obj: any = {
            value: item._id,
            label: item.name
        }
        depOptions.push(obj)
    })

    const selectedDept = depOptions.find((item:any)=>{
        if(item.value === props.selectedDepId){
            return item
        }
    })
    // console.log(selectedDept)
    const TeamMembOptions: any = []
    props.teamMembers?.map((item: any) => {
        const obj: any = {
            value: item._id,
            label: item.firstName
        }
        TeamMembOptions.push(obj)
    })
    const selectedteamMember = TeamMembOptions.find((item:any)=>{
        if(item.value === props.selectedteamMemberId){
            return item
        }
    })

    const handelDepartmentChange = async(e:any)=>{
        const data = {
            departmentId:e.value
        }
       await axiosInstance.patch(`/departments/${props.depId}/open-hours/user-department`, data).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                toast.success('Selected Department updated', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getOpenHours()
                // fetchOpenHrAudioFiles()
            }
        })
    }
    const handelTeamMemberChange = async(e:any)=>{
        const data = {
            teamMemberId:e.value
        }
       await axiosInstance.patch(`/departments/${props.depId}/open-hours/user-department`, data).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                toast.success('Selected Team Member updated', {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                props.getOpenHours()
                // fetchOpenHrAudioFiles()
            }
        })
    }
 
    return (
        <div className={classes.audioTransferContainer}>
            <div>
                <Grid container style={{ marginBottom: '50px' }}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography className={classes.audioTransferHeading}>
                                To Team Member
                            </Typography>
                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                            <ReactSelect placeholder='Select Team Member'
                                options={TeamMembOptions}
                                onChange={handelTeamMemberChange}
                                value={selectedteamMember}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Typography className={classes.audioTransferHeading}>
                                To Department
                            </Typography>
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                            <ReactSelect placeholder='Select Department'
                                options={depOptions}
                                onChange={handelDepartmentChange}
                                value={selectedDept}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <div>
                    <AudioCard voiceData={props.UdsVoiceData} voiceName={props.UdsVoiceName} depId={props.depId} data={props.callHandlingData?.transferingAudio}
                    getOpenHours={props.getOpenHours}/>
                </div>
            </div>

        </div>
    )
}

export default OpenHoursDepartmentsNew
import React from 'react'
import { useStyles } from '../styles'
import microphone from '../../../../../../assets/images/mic.png'
import edit from '../../../../../../assets/images/scissors 2.png'
import deleteicon from '../../../../../../assets/images/deleteicon.png'
import uploadimg from '../../../../../../assets/images/uploadimg.png';
import { Box, Divider, Grid, TextField } from '@material-ui/core'
import Button from '@mui/material/Button';
import ReactSelect from 'react-select'
import CircularProgress from '@mui/material/CircularProgress';
import { width } from '@mui/system'
import axiosInstance from '../../../../../../environment/axiosInstance'
import Backdrop from '../../../../../../components/Modal/Backdrop';
import Modal from '../../../../../../components/Modal/Modal';
import { isClassExpression } from 'typescript'


function RecordAudio(props: any) {
    const classes = useStyles()
    const [fileBuffer, setFileBuffer] = React.useState('')
    const [source, setSource] = React.useState<any>('')
    const [fileName, setFileName] = React.useState('')
    const audioFileInput: any = React.createRef();
    const [filesList, setFilesList] = React.useState<any>();
    const [loading, SetLoading] = React.useState<any>(true)
    const [deleteModal, setdeleteModal] = React.useState<any>(false);
    const [audioId, setAudioId] = React.useState<any>()
    const [selectedAudio, setSelectedAudio] = React.useState(false)
    const handleDeleteAudio = (id: any) => {
        setAudioId(id)
        setdeleteModal(!deleteModal);
    };
    const handleupdateAudio = (id: any) => {
        console.log(id)
        setAudioId(id)
        setSelectedAudio(true)
    }
    const onChangeAudioFile = (e: any) => {
        const fileData = e.target.files;
        var file: any = fileData[0];
        // setFile(file)
        setFileBuffer(file)
        let reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setSource(reader.result)
        };

        setFileName(fileData[0]?.name)
        // onPostDataToServer(file, fileData[0]?.name)

    }

    const uploadFileClick = () => {
        // setUploadingFile(true)
        if (audioFileInput.current) {
            audioFileInput.current.click()
        }
    }

    const onchangeFilename = ((e: any) => {
        setFileName(e.target.value)
    })

    const fetchAudioFiles = () => {

        axiosInstance.get(`/departments/${props.depId}/open-hours`).then((res: any) => {
            // console.log(res, "res")
            if (res.status === 200) {
                // console.log(res.data.data.voicemail.voicemailAudio)
                setFilesList(res.data.data.InterruptHoldMusicGreeting)
                SetLoading(false)
                // fetchOpenHrAudioFiles()
            }
        })
    }

    React.useEffect(() => {
        // console.log(props.data)
        fetchAudioFiles()
    }, [])


    const onClickSaveFile = async () => {
        const deptId = props.deptId
        let formData = new FormData()
        formData.append('fileName', fileName)
        formData.append('file', fileBuffer)
        formData.append('fileType', '1')
        // formData.append('soundType', 'IvrGreeting')
        // formData.append('hoursType', '1')
        // formData.append('departmentId', depId)
        SetLoading(true)
        await axiosInstance.post(`/departments/${props.depId}/open-hours/sound/interrupt-hold-music-greeting`, formData).then((res: any) => {
            if (res.status === 200) {
                setFileName('')
                setSource('')
                //SetLoading(true)
                // props.getClosedHours()
                fetchAudioFiles()
                SetLoading(false)
            }
        }).catch((err)=>{
            SetLoading(false)
        })
    }

    const onClickdeleteAudio = (id: any) => {
        const depId = props.depId
        SetLoading(true)
        axiosInstance.delete(`/departments/${props.depId}/open-hours/sound/interrupt-hold-music-greeting?id=${id}`).then((res: any) => {
            console.log(res, "res")
            if (res.status === 200) {
                setdeleteModal(!deleteModal);
                SetLoading(false)
                fetchAudioFiles()
            }
        }).catch((err)=>{
            SetLoading(false)
        })
    }

    const updateSelectedAudio = () => {
        const depId = props.depId
        const obj: any = {
            id: audioId
        }
        console.log(obj)
        SetLoading(true)
        axiosInstance.patch(`/departments/${props.depId}/open-hours/sound/interrupt-hold-music-greeting`, obj).then((res: any) => {
            if (res.status === 200) {
                fetchAudioFiles()
                setSelectedAudio(false)
                SetLoading(false)
            }
        }).catch((err)=>{
            SetLoading(false)
        })
    }

    React.useEffect(() => {
        setFilesList(props.openHours?.InterruptHoldMusicGreeting)
    }, [])


    return (

        <div>
            {
                deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeleteAudio} />
                        <Modal title="Are you sure want to Delete this Audio ?" handlePopupModal={handleDeleteAudio} >
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <div style={{ margin: '10px' }}>
                                            <Button
                                                style={{ background: '#4D72F8', padding: '6px 22px', color: 'white', borderRadius: '5px', fontSize: '14px' }}
                                                onClick={() => onClickdeleteAudio(audioId)} >Delete</Button>
                                            <Button size="small"
                                                style={{ fontSize: 14, color: '#787878', padding: '6px 22px', borderRadius: '5px', border: '1px solid #AEAEAE', marginLeft: 15 }}
                                                onClick={handleDeleteAudio} >Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div>
                )
            }

            <div>
                <div className={classes.dFlex}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ background: 'linear-gradient(180deg, #AD2626 0%, #E91C1C 100%)', width: '48px', height: '48px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={microphone} alt="microphone" width="25px" height="35px" style={{ color: '#FFFFFF' }} />
                        </div>
                        <p className={classes.minutes}>01:00</p>
                    </div>
                </div>
            </div>


            <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                        className={classes.uploadContainer}
                        onClick={uploadFileClick}>
                        <div>
                            <img src={uploadimg} alt="microphone" style={{ color: '#FFFFFF' }} />
                        </div>
                        <p className={classes.minutes}>
                            <span style={{ color: '#4D72F8', fontWeight: 700 }}>Browse</span> your file
                        </p>
                        <div style={{ display: 'none' }}>
                            <input type="file" ref={audioFileInput} accept=".mp3,audio/*" onChange={onChangeAudioFile} />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <audio src={source} controls className={classes.playaudioTrack}></audio>
                        <img src={edit} alt="editaudio" style={{ marginLeft: '15px' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '50px' }}>
                    <div>
                        <TextField
                            value={fileName}
                            onChange={onchangeFilename}
                            placeholder='Enter File Name'
                            size="small"
                            variant="outlined"
                            className={classes.inputFieldfilename}                        />
                        <Button variant="contained" className={classes.saveBtn} onClick={onClickSaveFile}>Save</Button>
                    </div>
                </div>
            </div>
            <Divider style={{ border: '1px solid #E2E2E2', width: '100%' }} />
            <div style={{ marginTop: '35px', marginLeft: '15px' }}>

                {
                    loading ?
                        <Box className={classes.dFlex}>
                            <CircularProgress />
                        </Box>
                        :
                        <div>
                            {
                                filesList?.map((item: any, id: any) =>
                                (
                                    <>
                                        {
                                            item.fileType === 1 &&
                                            <div style={{ marginBottom: '15px', cursor: 'pointer' }} onClick={() => handleupdateAudio(item._id)}>
                                                <div
                                                    // className={(!selectedAudio && item.isSelected === true) || (selectedAudio && item._id === audioId)?
                                                    //     classes.selected:classes.unSelected
                                                    // }
                                                    className={item.isSelected ? classes.selected : classes.unSelected}
                                                >
                                                    <audio src={item.path} controls
                                                        className={item.isSelected === true ? classes.audioTrackActive : classes.audioTrack}></audio>
                                                    <img src={deleteicon} alt="deleteaudio" style={{ marginLeft: '15px' }}
                                                        onClick={() => handleDeleteAudio(item._id)} />
                                                </div>
                                                <span className={classes.filename}>{item.fileName}</span>
                                            </div>
                                        }
                                    </>
                                )
                                )}
                            {/* <div className={classes.dFlex}>
                                <Button variant='contained' onClick={updateSelectedAudio}
                                    className={classes.saveAsdefaultBtn}>Save as Default</Button>
                            </div> */}
                        </div>

                }

            </div>
        </div>
    )
}

export default RecordAudio
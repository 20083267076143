import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  padding: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tabCard: {
    borderRadius: 10,
    // height: 49,
    background: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  },
  tabs: {
    padding: 10,
    paddingBottom: 10,
  },
  tab: {
    minHeight: 10,
    // height: 30,
    borderRadius: 5,
    background: '#4D72F8',
    boxSizing: 'border-box',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#FFFFFF',
    textTransform: 'none',
  },
  tabActive: {
    minHeight: 10,
    // height: 30,
    borderRadius: 5,
    background: '#FAFAFA',
    boxSizing: 'border-box',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#B8B8B8',
    textTransform: 'none',
  },
  gridItem: {
    width: '100%',
  },
  text: {
    marginTop: 5,
    marginBottom: 5,
    color: '#464646',
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  availableCard: {
    marginTop: 25,
    borderRadius: 10,
    background: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
  },
  divider: {
    //margin: 5,
    backgroundColor: '#E2E2E2',
  },
  numberButton: {
    display: 'flex',
    marginTop: "10px",
    background: '#F3F3F3',
    border: '1px solid #B8B8B8',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    width: "76%",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: "blue",

    },
  },
  numButtontwo: {
    borderRadius: 5,
    padding: '6px 0px',
    color: '#626873',
    // "&:hover": {
    //   // backgroundColor: "lightgray",
    //   color: "white"
    // },
    "&:active": {
      backgroundColor: "blue",

    },
  },
  numButtontwoActive: {
    background: '#4D72F8',
    padding: '6px 0px',
    color: '#fff',
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#4D72F8",
      color: 'white',
    },
    "&:active": {
      backgroundColor: "blue",
      color: 'white',
    },
  },
  numButtonOne: {
    borderRadius: 5,
    padding: '6px 0px',
    color: '#626873',
    height: "48px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
      color: "white",
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    },
    "&:active": {
      backgroundColor: "blue",

    },
  },
  numButtonOneActive: {
    background: '#4D72F8',
    padding: '6px 0px',
    color: '#fff',
    height: "48px",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#4D72F8",
      color: 'white',
    },
    "&:active": {
      backgroundColor: "blue",
      color: 'white',
    },
  },
  numButton: {
    marginTop: 10,

  },
  callIconActive: {
    color: "white"
  },
  callIcon: {
    color: "lightgray",
    "&:hover": {
      color: '#fff',
    },
    "&:active": {
      color: '#fff',
    },
  },
  bFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availableButton: {
    fontSize: 16,
    textTransform: 'capitalize',
    fontFamily: 'work sans',
    padding: '-1px 20px',
  },
  errMsg: {
    lineHeight: "18px",
    background: "#fff8e1",
    borderRadius: "2px",
    border: " 1px solid #efd581",
    color: "#6c6344",
    padding: "14px",
    display: "table",
    textAlign: "center",
    marginBottom: "18px",

  },
  numberActive: {
    backgroud: 'red'
  },
  dropdownselect: {
    width: "200px",

  },
  viewbatchButton: {
    display: 'flex',
    margin: "10px auto",
    background: '#F3F3F3',
    border: '1px solid #B8B8B8',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    width: '220px',
    padding: '6px',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: "capitalize"
  },
  vanitytext: {
    display: 'flex',
    // width:'297px',

  },
  Numbers: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
  vanityText2: {
    width: '100%',
    height: '38px',
    border: '1px solid #ccc',
    fontSize: '14px',
    // borderLeftColor: 'transparent',
    borderRadius: '0px 3px 3px 0px',
    position: 'relative',
    zIndex: 0,
    paddingLeft: 10,
    borderLeft: '1px solid #ccc',
    left: '-2px',
    "&:focus": {
      border: "2px solid #4D72F8",
    },
  },
  nextIcon: {
    background: '#ECECEC',
    float: 'right',
    margin: 10,
    '&:hover': {
      background: '#ECECEC',
    },
    "&:active": {
      backgroundColor: "blue",
    },
  },
  nextIconActive: {
    background: '#4D72F8',
    float: 'right',
    margin: 10,
    '&:hover': {
      background: '#ECECEC',
    },
    "&:active": {
      backgroundColor: "blue",
    },
  },
  numberButtonActive: {
    display: 'flex',
    marginTop: "10px",
    background: '#4D72F8',
    border: '1px solid #B8B8B8',
    borderRadius: 5,
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    width: "76%",
    fontWeight: 600,
    color: 'white',
    '&:hover': {
      background: '#4D72F8',
      color: 'white',
    }
  },
  icon: {
    // background: '#cfcfcf',
    borderRadius: 14,
    color: '#8392AB'
  }
}))
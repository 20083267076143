import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        padding: {
            padding: 10,
        },
        card: {
            margin: '15px 0px 0px 0px',
            borderRadius: 10,
            background: '#FFFFFF',
            boxShadow: '0 0 0.5em #E2E2E2',
        },
        heading: {
            color: "#2645AD",
            fontSize: 18,
            fontWeight: 600,
            fontFamily: "Work Sans",
            fontStyle: "normal",
        },
        accordianNoFlex: {
            display: 'block'
        },
        tableContainer: {
            marginTop: 20,
            width: '100%',
            boxShadow: '0 0 0.5em #E2E2E2',
            borderRadius: 10,
            borderColor: '#E2E2E2',
        },
        divider: {
            marginTop: -10,
            backgroundColor: '#E2E2E2',
        },
        searchText: {
            color: "#464646",
            fontWeight: 500,
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontSize: 17,

        },
        searchButton: {
            // marginTop: 20,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 13,
            padding: "6px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },
        },
        clearButton: {
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 13,
            color: "#FFFFFF",
            border: "0.791301px solid #8392AB",
            background: "#B70909",
            padding: "6px 20px",
            borderRadius: "2px",
            marginLeft: 20,
            // marginTop: 20,
        },
        dflex: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '65%', marginTop: 10 },
        accountHeading: {
            color: "#464646",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 16,
            marginTop:-20

        },
        addbutton: {
            marginTop: 5,
            marginRight: 19,
            background: "#2645AD",
            border: "0.791301px solid #3A416F",
            color: "#FFFFFF",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "capitalize",
            fontSize: 16,
            padding: "5px 20px",
            borderRadius: "2px",
            "&:hover": {
                color: "#FFFFFF",
                background: " #3A416F",
            },

        },
        tableCellBody: {
            padding: 10,
            fontSize: 16,
            fontFamily: 'Work Sans',
            fontWeight: 500,
            color: '#939292',
        },
        activeButton: {
            background: "#009A19",
            border: "1px solid #00C21F",
            color: "white",
            fontWeight: 600,
            height: 21,
            width: 55,
            fontSize: 11,
            fontFamily: "Lato",
            fontStyle: "normal",
        },
        textColor: {
            color: '#464646',
            marginLeft: 10,
            fontWeight: 'bold',
        },
        tableHead: {
            fontSize: "14px",
            color: "#25282B",
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Work Sans",
        },
        tableBody: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "0.8rem",
            color: "#787878",
        },
        tablecolor: {
            backgroundColor: "#fbfbfb",
        },
        space: {
            marginBottom: 18,
          },
        deviceWidth: {
            width: 30,
            height: 30,
            borderRadius: 50,
            color: 'black',
            // backgroundColor: '#F3F5FF',
            // fill:'#4D72F8',
            padding: '8px',
        },
        callsApp: {
            color: "#8392AB",
            fontFamily: "Work Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 14,
            marginLeft: 4,
          },
          barLine: {
            color: "#8392AB",
            marginLeft: 40,
          },
        mainDiv: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
      
          },
          subDiv: {
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          },


    })
);
import React from "react";
import { useHistory } from 'react-router-dom'
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import * as authenticationCreator from '../../store/Actions/AuthActionCreator'
import { connect } from 'react-redux'
import './loginas.css';

function LoginAs(props: any) {
    const history = useHistory();
    const { isAuthenticated, loading } = props.authState

    const authData = useSelector((state: any) => state.authState)

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")

    React.useEffect(() => {
        props.initAuthentication({ token: token })
    }, [])

    React.useLayoutEffect(() => {
        if (isAuthenticated) {
            history.push("/dashboard")
        }
        // eslint-disable-next-line
    }, [isAuthenticated])


    return (

        <>
            {
                loading &&
                <div className='loginas-loader'>
                    <CircularProgress disableShrink />
                </div>

            }
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        authState: state.authState
    }
}

const mapDispatchToProps = (dispatch: any) => {

    return {

        initAuthentication: (tokenObj: any) => dispatch(authenticationCreator.initAuthLoginAs(tokenObj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAs)
import { makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    editmodal: {
        height: 500,
        [theme.breakpoints.down('xs')]: {
            height: 500,
            width: 380,
            marginTop: -10
        }
    },
    playaudioTrack: {
        width: '432px',
        [theme.breakpoints.down('xs')]: {
            width: '250px', height: 35
        }
    },
    microphone: {
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
            width: 25, height: 25,
        }
    },
    inputFieldfilename: {
        width: '237px',
        marginRight: '10px', color: '#787878',
        [theme.breakpoints.down('xs')]: {
            width: '190px',
            marginRight: '10px'
        }
    },
    padding: {
        padding: 15,
    },
    card: {
        marginTop: 10,
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
        overflow: 'unset',
    },
    divider: {
        margin: 5,
        backgroundColor: '#E2E2E2',
    },
    heading: {
        color: "#2645ad",
        fontSize: 20,
        fontWeight: 600,
    },
    accordianNoFlex: {
        display: 'block'
    },
    grid: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    gridJustify: {
        // display:'grid',
        // gridTemplateColumns:'auto auto auto auto auto auto',
        // gridGap:'20px',
        // marginBottom: 20,
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexStart',
        marginBottom: '50px',
        marginLeft: '30px',
        [theme.breakpoints.down('xs')]: {
            width: 280,
            display: "grid",
            gridTemplateColumns: 'auto auto',
            gridGap: '4px',
            marginBottom: '20px',
            marginLeft: '0px',
            marginRight: 5
        }
    },
    usersandDeptCont: {
        [theme.breakpoints.down('xs')]: {
            display: "flex",
            flexDirection: 'column'
        }
    },
    callhandlingcard: {
        width: '125px',
        height: '90px',
        // left: 535px,
        // top: 643px,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        marginRight: '20px',
        marginLeft: '0px'
    },
    callhandlingcardActive: {
        width: '125px',
        height: '90px',
        // left: 371px,
        // top: 636px,
        backgroundColor: '#4D72F8',
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: '10px',
        marginRight: '20px',
        marginLeft: '0px'
    },
    imgcontainerActive: {
        backgroundColor: "#F3F5FF",
        width: 30,
        height: 30,
        borderRadius: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'

    },
    imgcontainerinActive: {
        backgroundColor: "#EAEAEA",
        width: 30,
        height: 30,
        borderRadius: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    carddevicesText: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#FAFAFA',
        marginTop: '10px'
    },
    carddevicesText1: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#828282',
        marginTop: '10px'
    },
    cardWidth: {
        display: 'flex',
        justifyContent: 'center',
        height: '100px',
        width: '180px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        backgroundColor: '#4D72F8',
    },
    cardWidth1: {
        display: 'flex',
        justifyContent: 'center',
        height: '100px',
        width: '180px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
    },
    align: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deviceWidth: {
        width: 40,
        height: 40,
        borderRadius: 50,
        color: '#8392AB',
        backgroundColor: '#fbfbfb',
        // fill:'#4D72F8',
        padding: '10px',
        border: '1px solid #ebebeb'
    },
    deviceWidth1: {
        width: 40,
        height: 40,
        borderRadius: 50,
        color: '#4D72F8',
        backgroundColor: '#F3F5FF',
        padding: '10px',
        border: '1px solid #ebebeb'
    },
    greetText: {
        marginTop: 10,
        marginLeft: '21px',
        marginBottom: 10,
        fontSize: 18,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: 0.217535,
        margin: 3,
        color: '#4D72F8',
    },

    devicesText: {
        fontSize: '14px',
        textAlign: 'center',
        fontFamily: 'Work Sans',
        fontWeight: 600,
        color: '#FFFFFF',
    },
    devicesText1: {
        fontSize: '14px',
        textAlign: 'center',
        fontFamily: 'Work Sans',
        fontWeight: 600,
        color: '#464646',
    },
    texttypo: {
        fontSize: 14,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        color: '#A0A0A0',
        letterSpacing: '0.02em',
        marginLeft: 22,
        marginTop: 10,
        marginBottom: 45,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }
    },
    headTexts: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 20,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#464646',
        letterSpacing: 0.208955,
        textTransform: 'none',
    },
    departmentNameText: {
        fontSize: 16,
        marginRight: 20,
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 500,
        color: '#A0A0A0',
        letterSpacing: '0.02em',
        marginTop: 22,
        marginLeft: 22,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12, marginLeft: 0,
            marginRight: 0,

        }
    },
    tablecolor: {
        backgroundColor: '#F1F1F1',
    },
    tableHead: {
        fontSize: 18,
        color: '#464646',
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    tableBody: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        color: '#787878',
    },
    addNewButton: {
        borderRadius: 50,
        border: '1px solid #CDCDCD',
        padding: '5px 10px',
        textTransform: 'capitalize',
        textAlign: 'center',
        marginRight: 30,
        background: '#FAFAFA',
        boxShadow: '0px 2px 10px rgba(0, 76, 222, 0.25)',
        boxSizing: 'border-box',

    },
    audiocard: {
        // display:'flex',
        // // justifyContent:'center',
        // alignItems:'center',
        width: '888px',
        // height: '146px',
        background: '#FFFFFF',
        boxShadow: "0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)",
        borderRadius: "12px",
        // marginLeft:'30px',
        marginBottom: '30px',
        marginTop: '30px',
        padding: '40px 25px 40px 25px',
        [theme.breakpoints.down('xs')]: {
            width: '270px',
            padding: '10px 15px 10px 10px',
            marginLeft: -25
        }

    },
    audioContainer: {
        //display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    editbtn: {
        fontFamily: 'Calibri ! important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        color: '#FFFFFF',
        width: "80px",
        height: "42px",
        // left: "16px",
        // top: "9px",
        // right: '10px',
        background: "#4D72F8",
        borderRadius: "5px",
        textTransform: 'initial',
        "&:hover": {
            backgroundColor: '#4D72F8'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            width: "65px",
            height: "30px",
            padding: 5
        }
    },
    editbtnn: {
        fontFamily: 'Calibri ! important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        color: '#FFFFFF',
        width: "90px",
        height: "42px",
        marginTop: -35,
        marginLeft: 40,
        // left: "16px",
        // top: "9px",
        // right: '10px',
        background: "#4D72F8",
        borderRadius: "5px",
        textTransform: 'initial',
        "&:hover": {
            backgroundColor: '#4D72F8'
        },
        [theme.breakpoints.down('xs')]: {
            width: "65px",
            height: "35px",
            marginLeft: 10,
            fontSize: '14px',
            marginTop: 0,
            padding:5
        }
    },
    button: {
        border: '1px solid lightgrey',
        width: 30,
        height: 30,
        padding: 7,
        // marginTop: '16%',
        // marginLeft: -30,
        color: '#4D72F8',
        display: 'grid',
        justifyContent: 'center',
        fontSize: 15,
        borderRadius: 4,
        marginLeft: 5,
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            width: 25,
            height: 25,
            padding: 5,
            fontSize: 15,
        }
    },
    addnumContainer: {
        display: "flex",

    },
    forwardNumAudio: {
        marginTop: -45,
        [theme.breakpoints.down('xs')]: {
            width: 160,
            height: 35
        }
    },
    audioTransferHeading: {
        fontSize: 20,
        fontFamily: 'Calibri ! important',
        fontStyle: "normal",
        fontWeight: 700,
        color: '#A0A0A0',
        marginBottom: '30px'
    },
    ringHandle: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 18,
    },
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: '0',
    },
    menuBody: {
        zIndex: 300
    },
    cardStyle: {
        marginTop: '20px',
        padding: 30,
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        [theme.breakpoints.down('xs')]: {
            marginLeft: -20,
            width: 260,
            padding: 10
        }
    },
    adduserpopup: {
        width: "500px ! important", padding: '10px 40px 10px 30px',
        
    },
    cardStyle1: {
        marginTop: '20px',
        padding: 30,
        background: '#FFFFFF',
        boxShadow: '0px 0.937048px 3.74819px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            width: 240,
            padding: 10
        }
    },
    title: {
        color: '#A0A0A0',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Calibri',
        marginBottom: 15
    },
    audioPlayer: {
        display: "block",
        width: "70%",
        height: 40,
        borderRadius: 'none',
        '&.selected': {
            color: '#0921a9'
        },
        '&:hover': {
            color: '#4D72F8'
        },
    },
    editBtn: {
        background: '#4D72F8',
        marginLeft: '95%',
        marginTop: '-78px',
        borderRadius: 5,
        color: '#fff',
        padding: '6px 25px',
        textTransform: 'capitalize',
        '&:hover': {
            color: '#fff',
            background: '#4D72F8',
        }
    },
    tab: {
        textTransform: "capitalize",
        background: '#E4E4E4 ! important',
        color: '#A1A1A1 ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        width: '315px',
        height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 25,
            fontSize: 13,
        }
    },
    tabActive: {
        background: '#4D72F8 ! important',
        color: '#FFFFFF ! important',
        boxSizing: "border-box",
        fontFamily: 'Arial ! important',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        textTransform: "capitalize",
        // width: '315px',
        // height: '52px',
        letterSpacing: '0.02em',
        borderRadius: '2px',
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 25,
            fontSize: 13,
        }
    },
    tabs: {
        background: "#FFFFFF",
        // borderRadius: "7.51286px",
        // boxShadow: "0px 12.5214px 16.9039px rgba(0, 0, 0, 0.05)",
        // width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '0px',
        
    },
    dFlex: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    minutes: {
        fontFamily: 'Work Sans ! important',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        color: '#8F8E8E',
        marginTop: '10px',
        lineHeight: '19px'
    },
    saveBtn: {
        background: "#4D72F8",
        borderRadius: "3px",
        color: 'white',
        textTransform: 'capitalize',
        marginLeft: 10
    },
    saveAsdefaultBtn: {
        width: '170px',
        height: '42px',
        background: '#4D72F8',
        borderRadius: '3px',
        color: "#FFFFFF",
        textTransform: 'capitalize',
        textAlign: 'center',
        marginLeft: '35%'
    },
    filename: {
        fontFamily: 'Calibri !important',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "12px",
        color: '#848282',
        marginTop: '15px'

    },
    audioPlayerscont: {
        display: 'flex', gap: 50,
        padding: 10,
        [theme.breakpoints.down('xs')]: {
            gap: 10,
            flexDirection: 'column',
            marginleft: 0,
            padding: 0,
            width: 120
        }
    }
    ,
    audioPlayers: {
        width: '597px ! important',
        height: '44px',
        backgroundColor: "#F0F3F5",
        border: '1px solid #F0F3F5',
        borderRadius: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '240px ! important',
            height: '38px',
            marginRight: 10
        }
    },
    uploadContainer: {
        cursor: 'pointer',
        padding: '10px',
        borderRadius: '8px', textAlign: 'center', width: '160px', height: '110px', border: '2px', borderStyle: 'dashed', borderColor: '#CDCCCC', marginBottom: '10px'
    },
    selectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        borderTopLeftRadius: 'none',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        width: '40px', height: '58px',
        [theme.breakpoints.down('xs')]: {
            height:39
        }
        //borderRadius: '4px'
    },
    unSelectedd: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5',
        width: '40px', height: '56px',
        [theme.breakpoints.down('xs')]: {
            height:39
        }
    },
    selected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        background: 'rgba(118, 144, 238, 0.5)',
        border: '2px solid #4D72F8',
        // borderRadius: '4px'
        borderTopLeftRadius: '4px',
        borderTopRightRadius: 'none',
        borderBottomLeftRadius: 4,
        // borderBottomRightRadius:4
    },
    unSelected: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#E9ECED',
        border: '1px solid #F0F3F5'
    },
    audioTrack: {
        width: '500px', background: '#E9ECED',
        [theme.breakpoints.down('xs')]: {
            width: '250px', height: 35
        }
    },
    audioTrackActive: {
        width: '500px',
        //background: 'rgba(118, 144, 238, 0.5)',
        borderRadius: '4px',
        [theme.breakpoints.down('xs')]: {
            width: '250px', height: 35
        }
    },
    modalCard: {
        margin: '10px 0px 10px 0px',
        borderRadius: 10,
        background: '#FFFFFF',
        boxShadow: '0 0 0.5em #E2E2E2',
        height: 180
    },
    userText: {
        margin: '10px 0px 10px 0px',
        fontSize: 18,
        fontFamily: 'Work Sans',
        textAlign: 'left',
        fontWeight: 500,
        color: '#8392AB',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        justifyContent: 'flex-start',
    },
    errorMessage: {
        color: 'red',
        fontSize: '0.8rem',
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0,
    },
    bttns: {
        textAlign: 'center',
        marginTop: 10,
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        }
    },
    SaveButton: {
        background: '#4D72F8',
        border: '1px solid #ccc',
        borderRadius: 5,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        color: '#FFFFFF',
        fontSize: 15,
        padding: '6px 22px',
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
        },
    },
    cancelButton: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        marginLeft: 15,
        background: '#EFEFEF',
        border: '1px solid #AEAEAE',
        borderRadius: 5,
        color: '#8392AB',
        fontSize: 14,
        padding: '6px 22px',
    },
}));

export const PrettoSlider = withStyles({
    root: {
        color: '#4D72F8',
        width: '75%',
        marginLeft: 10,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',

        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 10,
        borderRadius: 14,
    },
    rail: {
        height: 10,
        borderRadius: 14,
    },
})(Slider);

import { Button, Card, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Grid, Box, CircularProgress } from '@material-ui/core'
import React from 'react';
import { Description, Person, RecordVoiceOver, Timeline } from '@material-ui/icons';
import userServices from '../userServices'
import Backdrop from '../../../../components/Modal/Backdrop';
import Modal from '../../../../components/Modal/Modal';
import DepartmentTableBody from './DepartmentTableBody';
import { useStyles } from './styles'
import AddDepartment from '../EditAddDepartment/AddDepartment';

function UpdateUserData(props: any) {
  const classes = useStyles()
  const [userData, setuserData] = React.useState<any>([])
  const [depData, setDepData] = React.useState<any>([]);
  // const [permissions, setPermissions] = React.useState<any>([])
  const [directory, setDirectory] = React.useState<any>(false)
  const [addDepartment, setAddDepartment] = React.useState(false);
  const [selectedDeptId, setSelectedDeptId] = React.useState<any>();
  const [selectedDeparmentList, setSelectedDeparmentList] = React.useState<any>([]);
  const [deleteModal, setdeleteModal] = React.useState<any>(false);
  const [loading, setloading] = React.useState(false)

  const { _id, firstName, lastName, email } = props.data

  const handlePopUpAddDepartmentModal = () => {
    // setsSelecteduserId(uerId);
    setAddDepartment(!addDepartment);
    // setSelectedDeparmentList(deparmentList);
  }



  const fetchUsers = () => {
    setloading(true)
    userServices.fetchUsers().then((res: any) => {
      //  console.log("usersdata", res)
      if (res.success) {
        setloading(false)
        const result = res.data.data.find((item: any) => {
          if (item._id === _id) {
            return item
          }
        })
        setDepData(result.deparmentList)
        //  console.log("result", result)
      }
    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    fetchUsers()
  }, [])

  React.useEffect(() => {
    // console.log(props.data)
    // setDepData(props.data.deparmentList)
    // setPermissions(props.data.deparmentList[0].permissions)
    setDirectory(props.data.directoryEnabled)
    setuserData(props.data)
  }, [props.data, props.data.deparmentList])



  const onChangeOperations = (id: any, permissions: any, deptId: any) => {
    if (permissions.includes(id)) {
      const index = permissions.findIndex((item: any) => {
        if (item === id) {
          return item
        }
      })
      // console.log(index)
      // console.log("permisions", permisions)
      permissions.splice(index, 1)
      const data = {
        departmentId: deptId,
        permissions: permissions
      }
      console.log(data)
      userServices.updateUserDepartmentPermissions(data, _id).then((res: any) => {
        // console.log("patchresponse", res)
        if (res.success) {
          fetchUsers()
        }
      }).catch((err: any) => {

      })

    } else {
      permissions.push(id)
      const data = {
        departmentId: deptId,
        permissions: permissions
      }
      // console.log(data)
      userServices.updateUserDepartmentPermissions(data, _id).then((res: any) => {
        // console.log("patchresponse", res)
        if (res.success) {
          fetchUsers()
        }
      }).catch((err: any) => {

      })
    }
  }

  const handleDelete = () => {
    setdeleteModal(!deleteModal);
  }
  const handleDeleteOption = (id: any) => {
    setSelectedDeptId(id)
    handleDelete()
  };



  const handleDeleteUserFromDept = (selectedDeptId: any) => {
    // console.log("selectedDeptId", selectedDeptId)
    const data: any = {
      departmentIds: selectedDeptId
    }
    // console.log("depid",data)
    userServices.unAsignUserfromDept(_id, data).then((res: any) => {
      if (res.success) {
        handleDelete()
        fetchUsers()
        props.fetchUsersList()
      }
    }).catch((err: any) => {

    })
  }

  return (
    <div className={classes.editUserMainContainer}>
      {
        addDepartment && (
          <div>
            <Backdrop handlePopupModal={handlePopUpAddDepartmentModal} />
            <Modal title="Add Department" handlePopupModal={handlePopUpAddDepartmentModal} >
              <div className="add_user_popup" style={{ width: 680, height: 220 }}>
                <AddDepartment
                  handlePopupModal={handlePopUpAddDepartmentModal}
                  selecteduserId={_id}
                  selectedDeparmentList={depData}
                  fetchUsers={fetchUsers}
                  fetchList={props.fetchUsersList}
                />
              </div>
            </Modal>
          </div>
        )

      }

      {
        deleteModal && (
          <div>
            <Backdrop handlePopupModal={handleDeleteOption} />
            <Modal title="Are you sure want to UnAssign User from this Department?" handlePopupModal={handleDeleteOption} >
              <div className="add_user_popup" style={{ width: "500px" }}>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                    <div style={{ margin: '10px' }}>
                      <Button size="medium" onClick={() => handleDeleteUserFromDept(selectedDeptId)} variant='contained' style={{ marginRight: '5px', background: '#4D72F8', color: 'white', textTransform: 'none' }}>UnAssign</Button>
                      <Button size="medium" onClick={handleDelete} variant='contained' style={{ marginLeft: '5px', background: '#4D72F8', color: 'white' }}>Cancel</Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </div >
        )
      }


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Typography className={classes.userName}>{email}</Typography>
          <Typography className={classes.userEmail}>{firstName + " " + lastName}</Typography>
        </div>
        <div style={{ display: 'block' }}>
          <Button variant='contained' color='primary' className={classes.addDeptBtn}
            onClick={handlePopUpAddDepartmentModal}>Add Department</Button>
        </div>
      </div>
      <Card className={classes.table} style={{ overflow: "auto" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow >
                <TableCell className={classes.text}>Department</TableCell>
                <TableCell className={classes.text}>Primary Number</TableCell>
                <TableCell className={classes.text}>Permission</TableCell>
                <TableCell className={classes.text}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ?
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginLeft: 350, marginTop: 80, marginBottom: 10 }}>
                    <CircularProgress />
                  </Box>
                  :
                  depData.map((item: any, i: any) => {
                    return (
                      <DepartmentTableBody
                        fetchUsersList={props.fetchUsersList}
                        data={item}
                        handleDelete={handleDelete}
                        id={_id}
                        handleDeleteOption={handleDeleteOption}
                        userListdepartmentNumbers={props.userListdepartmentNumbers}
                        key={i}
                      />
                      // <TableRow key={i}>
                      //   <TableCell className={classes.body}>
                      //     <div style={{ display: 'flex', flexDirection: 'column' }}>
                      //       <span >{item.deptName}</span>
                      //       <span>(Agent)</span>
                      //     </div>
                      //   </TableCell>
                      //   <TableCell className={classes.body}>889988554</TableCell>
                      //   <TableCell className={classes.body}>
                      //     <Permissions permissions={item.permissions} deptId={item.deptId} />
                      //   </TableCell>
                      //   <TableCell className={classes.body}>
                      //     <Button
                      //       ref={anchorRef}
                      //       aria-controls={openOptions ? "menu-list-grow" : undefined}
                      //       aria-haspopup="true"
                      //       onClick={handleToggle}>
                      //       <MoreHorizRounded />
                      //     </Button>
                      //     <Popper open={openOptions} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                      //       {({ TransitionProps, placement }) => (
                      //         <Grow
                      //           {...TransitionProps}
                      //           style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      //         >
                      //           <Paper>
                      //             <ClickAwayListener onClickAway={handleClose}>
                      //               <MenuList autoFocusItem={openOptions} id="menu-list-grow" onKeyDown={handleListKeyDown} >
                      //                 <MenuItem >
                      //                   <ListItemIcon style={{ alignItems: 'center' }}
                      //                     onClick={() => handleDeleteOption(item.deptId)}>
                      //                     <AiTwotoneDelete style={{ color: '#BB271A' }} className={classes.iconClor} />
                      //                     <ListItemText className={classes.textColor} >Delete</ListItemText>
                      //                   </ListItemIcon>
                      //                 </MenuItem>
                      //               </MenuList>
                      //             </ClickAwayListener>
                      //           </Paper>
                      //         </Grow>
                      //       )}
                      //     </Popper>
                      //   </TableCell>
                      // </TableRow>
                    )
                  })

              }

            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  )
}

export default UpdateUserData
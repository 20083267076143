import React, { createContext, useContext, useReducer, useEffect } from 'react';
import AppReducer from './AppReducer';

const initialState: any = {
    conversations: [],
    refresh: false,
}

const UserContext = createContext({})

export const GlobalContext = createContext(initialState)

export const GlobalProvider = (props: any) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);


    //add
    const addConversation = (cs: any) => {
        dispatch({ type: 'add', conversation: cs });
    };

    //api integration
    React.useEffect(() => {

    }, [])

    return (
        <GlobalContext.Provider value={{
            conversations: state.conversations,
            addConversation: state.addConversation
        }}>
            {props}
        </GlobalContext.Provider>
    )
}
import React from "react";
import { Button, TextField, Card } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosInstance from '../../../environment/axiosInstance'
// import { FiEye, FiEyeOff } from 'react-icons/fi',

import { InputAdornment } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./style.css";
import { useStyles } from "./style";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function CreateAccount() {
  const classes = useStyles();
  const history = useHistory()
  const [submitted, setSubmitted] = React.useState(false);
  const inputRef: any = React.useRef(null);
  const userAuthState = useSelector((state: any) => state.authState);
  const { loading } = userAuthState;
  const [userData, setUserData] = React.useState({
    auth_number: '',
    password: '',
    firstName: '',
    lastName: '',
    email: ''
  })

  const onSubmiCreateAccount = () => {
    setSubmitted(true);
    const errorData = validate(true);
    if (errorData.isValid) {
      axiosInstance.post('/auth/signup', userData).then((res: any) => {
        // console.log('res', res)
        if (res.status === 201) {
          history.push('/')
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT, autoClose: 1000
          })
        }
      }).catch((err: any) => {
        toast.success(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1000
        })
      })
    }
  }

  const onChangeEvent = (e: any) => {
    setUserData((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const validate = (submitted: any) => {
    const validData = {
      isValid: true,
      message: ""
    };
    const retData = {
      auth_number: { ...validData },
      firstName: { ...validData },
      lastName: { ...validData },
      email: { ...validData },
    };
    let isValid = true;
    if (submitted) {
      if (userData.auth_number === "") {
        retData.auth_number = {
          isValid: false,
          message: "Please enter phone number"
        };
        isValid = false;
      }
      else if (userData.auth_number.length < 10) {
        retData.auth_number = {
          isValid: false,
          message: "Phone Number should be less than 10 digits"
        };
        isValid = false;
      }
      else if (userData.auth_number.length > 15) {
        retData.auth_number = {
          isValid: false,
          message: "Phone Number should not be greater than 15 digits"
        };
        isValid = false;
      }
      if (userData.firstName === "") {
        retData.firstName = {
          isValid: false,
          message: "Please enter first name"
        };
        isValid = false;
      }
      if (userData.lastName === "") {
        retData.lastName = {
          isValid: false,
          message: "Please enter last name"
        };
        isValid = false;
      }

    }
    return {
      ...retData,
      isValid
    };
  };


  const errors = validate(submitted);


  return (
    <Card style={{ width: "500px", height: "640px", margin: "auto", marginTop: '30px' }}>
      <div className="signin_container1">
        <div className="signin_title1">
          <h2 style={{}}>Create Account</h2>
        </div>
        {loading ? (
          <Box className={classes.marLt} sx={{}}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="form_container1">
            <form>
              <div className="signin_input_section1">
                <h4>PhoneNumber</h4>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="auth_number"
                  value={userData.auth_number}
                  onChange={onChangeEvent}
                  error={!errors.auth_number.isValid}
                />
                {
                  !errors.auth_number.isValid &&
                  <p className={classes.errorMessage}>{errors.auth_number.message}</p>
                }
              </div>
              <div className="signin_input_section1">
                <h4>FirstName</h4>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  ref={inputRef} type="text"
                  name="firstName"
                  value={userData.firstName}
                  onChange={onChangeEvent}
                  error={!errors.firstName.isValid}
                />
                {
                  !errors.firstName.isValid &&
                  <p className={classes.errorMessage}>{errors.firstName.message}</p>
                }
              </div>
              <div className="signin_input_section1">
                <h4>LastName</h4>
                <TextField
                  ref={inputRef}
                  name="lastName"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={userData.lastName}
                  onChange={onChangeEvent}
                  error={!errors.lastName.isValid}
                />
                {
                  !errors.lastName.isValid &&
                  <p className={classes.errorMessage}>{errors.lastName.message}</p>
                }
              </div>
              <div className="signin_input_section1">
                <h4>Email</h4>
                <TextField
                  ref={inputRef}
                  name="email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={userData.email}
                  onChange={onChangeEvent} />
              </div>
              <div className="signin_input_section1">
                <h4>Password</h4>
                <TextField
                  ref={inputRef}
                  name="password"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={userData.password}
                  onChange={onChangeEvent} />
                <p className="error_messages_popup"></p>
              </div>
              {/* <div className="sign_form_action1">
                <Link
                  to={"/forget-password"}
                  style={{
                    cursor: "pointer",
                    color: "#8392AB",
                    textDecoration: "none",
                  }}
                >
                  Forgot Password ?
                </Link>
                <div>
                  <Checkbox
                    // checked={checkedRemember}
                    // onChange={handleChangeRemember}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <p style={{ color: "#2645AD" }}>Remember Me</p>
                </div>
              </div> */}
              <div className="signin_btn_section1">

                <Button onClick={onSubmiCreateAccount}>Submit</Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Card>
  );
}

export default CreateAccount;

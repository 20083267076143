
import React from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Switch,
    Divider,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { TableHeaderComponent } from "../../../components/Table/Table";
import ReactSelect from "react-select";
import { CheckBox, Details } from "@material-ui/icons";
import { useSelector, useDispatch } from 'react-redux'
import departmentServices from '../DepartmentServices/DepartmentServices'
import * as DepartmentActionCreators from '../../../store/Actions/DepartmentsActionXCreators'
import { useParams } from "react-router-dom";
import axiosInstance from '../../../environment/axiosInstance'


const generatedHours: any = [];
for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60 / 15; j++) {
        const leftTimer = i.toString().length === 1 ? `${"0" + i}` : i;
        const rightTimer = (j * 15).toString().length === 1 ? `${j + "0"}` : (j * 15);
        let hourData = { value: `${leftTimer + ":" + rightTimer}`, label: `${leftTimer + ":" + rightTimer}` }
        generatedHours.push(hourData)

    }

}

function TimeZone(props: any) {
    const classes = useStyles()
    const params = useParams()
    const dispatch = useDispatch();
    const hoursHeader = ["Day", "Opens At", "Closes At", ""];
    const [weekDaysArray, setWeekDaysArray] = React.useState<any>([]);
    const [week, setsameAsWeek] = React.useState(false);
    const [weekDaysTimings, setWeekDaysTimings] = React.useState<any>({
        Monday: {
            open: "",
            close: "",
        },
        Tuesday: {
            open: "",
            close: "",
        },
        Wednesday: {
            open: "",
            close: "",
        },
        Thursday: {
            open: "",
            close: "",
        },
        Saturday: {
            open: "",
            close: "",
        },
        Friday: {
            open: "",
            close: "",
        },
        Sunday: {
            open: "",
            close: "",
        },
    });


    const onChangeWeekdayTimings1 = (e: any, key: any, index: any, slot_key: any) => {
        if (week) {
            let copyWeeksData = [...props.daysData]
            copyWeeksData.forEach((weeks: any) => {
                // weeks[key] = e.value;
                weeks[index][slot_key][key] = e.value.replaceAll(":", "");
            })
            props.onChangeWeekDaysTimingsData([...copyWeeksData])
        } else {
            let copyWeeksData = [...props.daysData]
            copyWeeksData[index][index][slot_key][key] = e.value.replaceAll(":", "");
            props.onChangeWeekDaysTimingsData([...copyWeeksData])
        }


    }

    const onAddNewSlotClicked = (index: any) => {
        let copyWeeksData = [...props.daysData]
        copyWeeksData[index][index].push({
            "startTime": "",
            "endTime": ""
        });
        props.onChangeWeekDaysTimingsData([...copyWeeksData]);
    }

    const onRemoveSlotClicked = (index: any, slot_key: any) => {
        let copyWeeksData = [...props.daysData];
        copyWeeksData[index][index].splice(slot_key, 1);
        props.onChangeWeekDaysTimingsData([...copyWeeksData]);
    }

    const onChangeSetasallweeks = (e: any) => {
        setsameAsWeek(!week);

        if (e.target.checked) {
            let copyWeeksData: any = [...props.daysData];
            const openValue = copyWeeksData[0]['open']
            const closeValue = copyWeeksData[0]['close']
            copyWeeksData.forEach((week: any) => {
                week['open'] = openValue
                week['close'] = closeValue
            })
            props.onChangeWeekDaysTimingsData(copyWeeksData)
        } else if (e.target.checked === false) {
            let copyWeeksData: any = [...props.daysData];

            copyWeeksData.slice(1).forEach((week: any) => {
                week['open'] = ""
                week['close'] = ""
            })
            props.onChangeWeekDaysTimingsData(copyWeeksData)

        }
    }



    const updateSlotsData = (data: any) => {
        //props.updatedDepartmentSchedule(props.deptId,{ alwaysOpen: props.selectAlwaysOpen,days: props.daysData },'days')
        // props.updatedDepartmentSchedule(props.deptId, { days: props.daysData }, 'days')
        props.updateSlotsData(props.deptId, { slots: props.daysData }, 'slots')
    };

    // React.useEffect(() => {
    //     const { days } = props?.scheduleData[0];
    //     if(days !== undefined && days.length > 0){
    //         props.onChangeWeekDaysTimingsData(days)
    //     }
    // }, [props.scheduleData])
    return (
        <div>
            {/* <CardContent className={classes.specificContent} style={{marginTop:-20}}> */}
            <TableContainer
                // component={Card}
                className={classes.tableContainer1}
            >
                <Table style={{ width: '100%' }}>
                    <TableHeaderComponent theadData={hoursHeader} />
                    <TableBody>
                        {props.daysData.map((detail: any, index: any) => {
                            return (
                                <>
                                    {
                                        detail[index]?.map((slot: any, slot_key: any) => {
                                            return (
                                                <>
                                                    <TableRow key={detail.day}>
                                                        <TableCell className={classes.tableCellBody1}>
                                                            {
                                                                slot_key == 0 ?
                                                                    index == 0 ?
                                                                        "Sunday"
                                                                        :
                                                                        index == 1 ?
                                                                            "Monday"
                                                                            :
                                                                            index == 2 ?
                                                                                "Tuesday"
                                                                                :
                                                                                index == 3 ?
                                                                                    "Wednesday"
                                                                                    :
                                                                                    index == 4 ?
                                                                                        "Thursday"
                                                                                        :
                                                                                        index == 5 ?
                                                                                            "Friday"
                                                                                            :
                                                                                            index == 6 ?
                                                                                                "Saturday"
                                                                                                :
                                                                                                ""
                                                                    :
                                                                    ""
                                                            }
                                                        </TableCell>
                                                        <TableCell className={classes.tableCellBody2}>
                                                            {/* {slot.open} */}
                                                            <ReactSelect
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                isDisabled={week && index >= 1}
                                                                // placeholder={slot.open === "" ? 'Open At' : slot.open}
                                                                value={{
                                                                    value: slot?.startTime !== undefined ? ("0" + Math.floor(slot?.startTime / 100)).slice(-2) + ":" + ("0" + (slot?.startTime % 100)).slice(-2) : "",
                                                                    label: slot?.startTime !== undefined ? ("0" + Math.floor(slot?.startTime / 100)).slice(-2) + ":" + ("0" + (slot?.startTime % 100)).slice(-2) : "",
                                                                }}

                                                                options={generatedHours}
                                                                onChange={(e: any) => onChangeWeekdayTimings1(e, 'startTime', index, slot_key)}
                                                                name="startTime"
                                                            />
                                                        </TableCell>
                                                        {/* {slot.close} */}
                                                        <TableCell className={classes.tableCellBody2}>
                                                            <ReactSelect
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                isDisabled={week && index >= 1}
                                                                // placeholder={slot.close === "" ? 'Close At' : slot.close}
                                                                options={generatedHours}
                                                                value={{
                                                                    value: slot?.endTime !== undefined ? ("0" + Math.floor(slot?.endTime / 100)).slice(-2) + ":" + ("0" + (slot?.endTime % 100)).slice(-2) : "",
                                                                    label: slot?.endTime !== undefined ? ("0" + Math.floor(slot?.endTime / 100)).slice(-2) + ":" + ("0" + (slot?.endTime % 100)).slice(-2) : "",
                                                                }}
                                                                // onChange={onChangeWeekdayTimings}
                                                                onChange={(e: any) => onChangeWeekdayTimings1(e, 'endTime', index, slot_key)}
                                                                name="endTime"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCellBody1}>
                                                            {
                                                                slot_key == 0 ?
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        onClick={() => onAddNewSlotClicked(index)}
                                                                    >Add New</Button>
                                                                    :
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="secondary"
                                                                        onClick={() => onRemoveSlotClicked(index, slot_key)}
                                                                    >Remove</Button>
                                                            }
                                                            {/* {
                                                    index === 0 && (
                                                        <FormControlLabel
                                                            control={<Checkbox name="checkedC" size='small' />}
                                                            label="Same as all week"
                                                            onChange={onChangeSetasallweeks}
                                                        />
                                                    )
                                                } */}
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        (
                                                            (Number((("0" + Math.floor(slot?.startTime / 100)).slice(-2) + ":" + ("0" + (slot?.startTime % 100)).slice(-2))?.split(":")[0]))
                                                            >
                                                            Number((("0" + Math.floor(slot?.endTime / 100)).slice(-2) + ":" + ("0" + (slot?.endTime % 100)).slice(-2))?.split(":")[0])
                                                        )
                                                        && (
                                                            <TableRow>
                                                                <TableCell style={{ padding: 0, border: 0 }}></TableCell>
                                                                <TableCell style={{ padding: 0, border: 0 }}></TableCell>
                                                                <TableCell style={{ padding: 0, border: 0, textAlign: "right" }}>(Next Day)</TableCell>
                                                                <TableCell style={{ padding: 0, border: 0 }}></TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                </>
                                            );
                                        })
                                    }
                                    <TableRow>
                                        <TableCell style={{ padding: 0, border: 0 }}><hr /></TableCell>
                                        <TableCell style={{ padding: 0, border: 0 }}><hr /></TableCell>
                                        <TableCell style={{ padding: 0, border: 0 }}><hr /></TableCell>
                                        <TableCell style={{ padding: 0, border: 0 }}><hr /></TableCell>
                                    </TableRow>
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                <Button className={classes.saveBtn} onClick={updateSlotsData} >Save</Button>
            </div>
            {/* </CardContent> */}
        </div>
    )
}

export default TimeZone
import { Typography, makeStyles, Button } from '@material-ui/core'
import { Card, Grid, Tabs, Tab, } from '@material-ui/core'
import React from 'react'
import { useStyles } from '../styles'
import { TabContext, TabPanel } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
import Backdrop from '../../../../../../components/Modal/Backdrop';
import Modal from '../../../../../../components/Modal/Modal';
import RecordAudio from './RecordAudio';
import UploadAudio from './UploadAudio';
const muiTheme = createMuiTheme({});


const useStyleses = makeStyles(
    (theme: any) => {
        return {
            root: {
                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },
            },
            loopIcon: {
                color: '#4D72F8',
                '&.selected': {
                    color: '#0921a9'
                },
                '&:hover': {
                    color: '#4D72F8'
                },
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            },
            playIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            pauseIcon: {
                color: '#4D72F8',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeIcon: {
                color: '#4D72F8',
                fontSize: '28px',
                '&:hover': {
                    color: '#4D72F8'
                }
            },
            volumeSlider: {
                color: '#4D72F8'
            },
            progressTime: {
                color: '#6b6b6d'
            },
            mainSlider: {
                color: '#4D72F8',
                '& .MuiSlider-rail': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-track': {
                    color: '#4D72F8'
                },
                '& .MuiSlider-thumb': {
                    color: '#4D72F8'
                }
            }
        }
    });

function HoldMusicCard(props: any) {
    const classes = useStyles()
    const [srcss, setSrcss] = React.useState<any>('')
    const [shouldRecord, setShouldRecord] = React.useState(false)
    const [value, setValue] = React.useState("2");
    const handlePopupModalAdd = () => {
        setShouldRecord(!shouldRecord)
        // setopenModalAdd(!openModalAdd)
    }
    const handleLoadMetadata = (meta: any) => {
        const { duration } = meta.target;
        const styles = makeStyles({

        })
        // console.log(duration);
    }
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };
    return (
        <>
            {
                shouldRecord && (
                    <div>
                        <Backdrop handlePopupModal={handlePopupModalAdd} />
                        <Modal title="Record Audio" handlePopupModal={handlePopupModalAdd}>
                            <div className={classes.editmodal}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className={classes.tabs}
                                    onChange={handleChange}
                                    id="editOpenHourGreetingsTab"
                                    
                                >
                                    {/* <Tab
                                        label="Record Audio"
                                        value="1"
                                        className={value === "1" ? classes.tabActive : classes.tab}
                                    /> */}
                                    <Tab
                                        label="Upload Audio"
                                        value="2"
                                        className={value === "2" ? classes.tabActive : classes.tab}
                                    />

                                </Tabs>
                                <TabContext value={value}>
                                    <TabPanel value="1">
                                        <RecordAudio
                                            depId={props.depId}
                                            getOpenHours={props.getOpenHours}
                                            openHours={props.openHours}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <UploadAudio
                                            depId={props.depId}
                                            getOpenHours={props.getOpenHours}
                                            openHours={props.openHours}
                                        />
                                    </TabPanel>
                                </TabContext>

                            </div>
                        </Modal>
                    </div>
                )
            }
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.flexStyles}>
                    <Typography className={classes.greetText}>Hold Music</Typography>
                    <Typography className={classes.actionText}>Set rules for handling call on hold music</Typography>
                </div>
                <div>
                    <div className={classes.audioContainer}>
                        <ThemeProvider theme={muiTheme}>
                            <ReactAudioPlayer
                                controls
                                // className={classes.audioPlayer}
                                onLoadedMetadata={handleLoadMetadata}
                                src={props.holdMusicPath}
                                className={classes.audioPlayers}
                            />
                        </ThemeProvider>
                        <Button size='small' className={classes.editBtn} onClick={handlePopupModalAdd}>
                            Edit
                        </Button>
                    </div>
                    {/* <div style={{ textAlign: 'left', marginTop: '8px' }}>
                    <span className={classes.filename}>{props.holdMusicFileName}</span>
                </div> */}
                </div>
            </div>
        </>
    )
}

export default HoldMusicCard
import { Grid, Typography, TextField, Button, Card, Divider } from '@material-ui/core'
import React from 'react'
import { useStyles } from './styles'
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import Services from './AddonServices/services'
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddonServices from './AddonServices/services'
import FormControl from "@material-ui/core/FormControl";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import AccordionDetails from "@material-ui/core/AccordionDetails";
function EditAddon(props: any) {
    const history = useHistory();
    const classes = useStyles()
    const [value, setValue] = React.useState<any>("1");
    const [open, setOpen] = React.useState(true);
    const [id, setId] = React.useState<any>('')
    const [countrieslist, setCountriesList] = React.useState<any>([]);
    const [addonFormData, setAddonFormData] = React.useState({
        addonName: "",
        addonCost: "",
        addonType: "",
        countries: [],

        freeCount: "",
        status: "1",
    })
    const [status] = React.useState<any>([
        { value: 1, label: "Active" },
        { value: 2, label: "InActive" }
    ])
    const [addonTypes] = React.useState<any>([
        { value: "1", label: "Calls" },
        { value: "2", label: "SMS" },
        { value: "3", label: "Features" }
    ])
    const getCountries = () => {
        AddonServices.getCountries().then((res) => {
            if (res.data) {
                setCountriesList(res.data.data)
            }
        })
    }


    React.useEffect(() => {
        getCountries();
    }, [])
    const onContentChangeEvent = (e: any) => {
        setAddonFormData((prev) => {
            return {
                ...addonFormData,
                [e.target.name]: e.target.value,
            }
        })
    }

    const onContentAddonTypeChangeEvent = (e: any) => {
        setAddonFormData((prev) => {
            return {
                ...addonFormData,
                addonType: e.value,
            }
        })
    }

    const onContentCountryChangeEvent = (e: any) => {
        const data: any = [];
        e.map((item: any) => {
            data.push(item.value)
        })
        setAddonFormData((prev) => {
            return {
                ...addonFormData,
                countries: data,
            }
        })
    }
    var CountryList: any = [];
    countrieslist.map((item: any) => {
        var object = {
            "value": item.id,
            "label": item.CountryName
        }
        CountryList.push(object)
    })

    const handleChangeData = (e: any) => {
        setAddonFormData((prev) => {
            return {
                ...addonFormData,
                status: e.value,
            }
        })
    }
    var defaultCountryName: any = []
    {
        countrieslist.map((options: any) => {
            addonFormData.countries && addonFormData.countries.map((elementId: any) => {
                if (elementId === options.id) {
                    var defautdata = {
                        "value": options.id,
                        "label": options.CountryName
                    }
                    defaultCountryName.push(defautdata)
                }
            })
        })
    }

    const handleOpen = () => {
        setOpen(!open)
    }


    const SubmitAddons = () => {
        AddonServices.UpdateAddon(id, addonFormData).then((res) => {
            if (res.success) {
                if (res.success) {
                    history.push('/addons')
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getAddonsById = (id: any) => {
        AddonServices.getAddonsByID(id).then((res) => {
            if (res.success) {
                const data = res.data.data
                setAddonFormData({
                    addonName: data.addonName,
                    addonCost: data.addonCost,
                    countries: data.countries,
                    addonType: data.addonType,

                    freeCount: data.freeCount,
                    status: data.status,
                })
                setId(data.id)
            }
        })
    }
    React.useEffect(() => {
        getAddonsById(props.match.params.id)
    }, [props.match.params.id])

    return (
        <div>
            <Card className={classes.card}>
                <Accordion expanded={open} onChange={handleOpen}>
                    <AccordionSummary
                        expandIcon={open ? <FiMinusCircle color="#2645ad" /> : <FiPlusCircle color="#2645ad" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Addons Update
                        </Typography>
                    </AccordionSummary>
                    <Divider className={classes.divider} />
                    <AccordionDetails style={{ display: 'block' }}>
                        <Grid container spacing={6}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Grid container className={classes.inputFields}>
                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                        <Typography className={classes.addName}>Addon Name</Typography>

                                    </Grid>
                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            value={addonFormData.addonName}
                                            name="addonName"
                                            onChange={onContentChangeEvent}
                                            className={classes.textField}
                                            placeholder="Enter addon name"

                                        />

                                    </Grid>
                                </Grid>
                                <Grid container className={classes.inputFields}>
                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                        <Typography className={classes.addName}>
                                            Addon Type
                                        </Typography>

                                    </Grid>
                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            onChange={onContentAddonTypeChangeEvent}
                                            name="addonType"
                                            value={
                                                addonTypes.filter((option: any) =>
                                                    option.value == addonFormData.addonType)
                                            }
                                            options={addonTypes}
                                            className={classes.select}

                                        />

                                    </Grid>
                                </Grid>
                                {addonFormData.addonType === "3" ? "" :
                                    <Grid container className={classes.inputFields}>
                                        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                            <Typography className={classes.addName}>
                                                Select Country
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                            <Grid>
                                                <Select
                                                    name="countries"
                                                    isMulti
                                                    options={CountryList}
                                                    onChange={onContentCountryChangeEvent}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    value={defaultCountryName}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>}

                            </Grid>
                            {/* end first col-6 */}
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Grid container className={classes.inputFields}>
                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                        <Typography className={classes.addName}>Addon Cost($)</Typography>

                                    </Grid>
                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            value={addonFormData.addonCost}
                                            name="addonCost"
                                            onChange={onContentChangeEvent}
                                            className={classes.textField}
                                            placeholder="Enter addon cost"

                                        />

                                    </Grid>
                                </Grid>

                                {addonFormData.addonType === "3" ? "" :
                                    <Grid container className={classes.inputFields}>
                                        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                            <Typography className={classes.addName}>Free Count</Typography>
                                        </Grid>
                                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                            <Grid>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={addonFormData.freeCount}
                                                    onChange={onContentChangeEvent}
                                                    name="freeCount"
                                                    className={classes.textField}
                                                    placeholder="Free Count"

                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                {addonFormData.addonType === "3" ? "" :
                                    <Grid container className={classes.inputFields}>
                                        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                            <Typography className={classes.addName}>Status</Typography>

                                        </Grid>
                                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                            <Select
                                                placeholder="Select"
                                                name="status"
                                                options={status}
                                                value={
                                                    status.filter((option: any) =>
                                                        option.value === addonFormData.status)
                                                }
                                                onChange={handleChangeData}
                                            />
                                        </Grid>
                                    </Grid>}

                            </Grid>
                            {/* end last col-6 */}
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item md={12} style={{ textAlign: "center" }}>
                                <Button
                                    //fullWidth
                                    size="small"
                                    variant="outlined"
                                    className={classes.saveButton}
                                    onClick={SubmitAddons}
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className={classes.cancelButton}
                                    //onClick={props.handlePopupModal}
                                    onClick={() => history.push('/addons')}
                                >
                                    Back
                                </Button>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </Card>
        </div>
    )
}
export default EditAddon
import React from 'react'
import {
    Grid, Button, TextField,
} from '@material-ui/core';
import { useStyles } from './styles';
import Select from 'react-select'
import Service from './Services'
export default function SearchFilter(props: any) {
    const classes = useStyles();
    const [tableData, setTableData] = React.useState<any>([]);
    const fetchBusinessUsers = () => {
        Service.getBusiness().then((res) => {
            if (res.data) {
                setTableData(res.data.data)
            }
            else {
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }
    const businessAcList: any = []
    tableData.map((item: any) => {
        var object = {
            "value": item.id,
            "label": item.firstName + item.lastName
        }
        businessAcList.push(object)
    })
    const keyOptions = [
        { label: "Today", value: 1 },
        { label: "Yesterday", value: 2 },
        { label: "Last 7 days", value: 3 },
        { label: "Last 30 days", value: 4 },

    ]
    const calltypeOptions = [
        { label: "Inbound", value: 1 },
        { label: "Outbound", value: 2 },
    ]
    const connectedOptions: any = [
        { label: "Connected", value: true },
        { label: "Not Connected", value: false },
    ]
    const searchData = () => {
        props.searchedData(props.paginate.limit, props.paginate.page)
    }
    React.useEffect(() => {
        fetchBusinessUsers()
    }, [])
    const onContentCountryChangeEvent = (e: any) => {
        props.onChangeSearchData(e.target.name, e.value)
    }
    const handleClear = () => {
        props.clearFilter()
    }

    return (
        <div style={{ marginTop: "10px" }}>
            <Grid container spacing={2} style={{ margin: "0px -7px" }}>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >
                    <Select
                        className={classes.space}
                        placeholder="Business a/c"
                        options={businessAcList}
                        name="account"
                        onChange={(e: any) => props.onChangeSearchData("account", e.value)}
                        value={
                            businessAcList.filter((option: any) =>
                                option.value === props.data.accountValue)
                        }
                    />

                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >

                    <Select
                        className={classes.space}
                        placeholder="Search Date"
                        options={keyOptions}
                        name="type"
                        onChange={(e: any) => props.onChangeSearchData("type", e.value)}
                        value={keyOptions.filter((opt: any) => opt.value === props.data.typeValue)}
                    />

                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >

                    <Select
                        className={classes.space}
                        placeholder="Search CallType"
                        options={calltypeOptions}
                        name="direction"
                        onChange={(e: any) => props.onChangeSearchData("direction", e.value)}
                        value={calltypeOptions.filter((opt: any) => opt.value === props.data.directionValue)} />

                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >

                    <Select
                        className={classes.space}
                        placeholder="Search Callstatus"
                        options={connectedOptions}
                        name="callStatus"
                        onChange={(e: any) => props.onChangeSearchData("callStatus", e.value)}
                        value={connectedOptions.filter((opt: any) => opt.value === props.data.callStatusValue)} />

                </Grid>

                <Grid item xl={2} lg={2} md={2} sm={6} xs={12} >


                    <Grid item md={3} style={{ display: "flex" }}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.searchButton}
                            onClick={searchData}
                        >
                            Search
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.clearButton}
                            onClick={handleClear}

                        >
                            Clear
                        </Button>

                    </Grid>
                </Grid>

            </Grid>

        </div>
    )
}

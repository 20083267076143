import { makeStyles, withStyles, Slider } from "@material-ui/core";
import { color } from "@mui/system";

export const useStyles = makeStyles((theme) => ({
    permissName: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 12,
        color: '#929292',
    },
    tooltip: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 31,
        height: 31,
        borderWidth: 2,
        borderRadius: 50,
        minWidth: 0,
        padding: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
        border: '2px solid #ebebeb',
        color: '#ebebeb',
        // borderColor: '#ebebeb',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
        "@media (max-width: 426px)": {
            width: 20,
            height: 20,
            borderWidth: 1,
        }
    },
    activeButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 31,
        height: 31,
        borderWidth: 2,
        borderRadius: 50,
        padding: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
        border: '2px solid #4D72F8',
        color: '#4D72F8',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&:active': {
            backgroundColor: '#FFFFFF',
        },
        "@media (max-width: 426px)": {
            width: 20,
            height: 20,
            borderWidth: 1,
        }
    },
    activeIcon: {
        width: 20,
        height: 21,
        color: '#4D72F8',
        "@media (max-width: 426px)": {
            width: 15,
            height: 15,
        }
    },
    inActiveIcon: {
        width: 20,
        height: 21,
        color: '#8392AB',
        "@media (max-width: 426px)": {
            width: 15,
            height: 15,
        }
    },
    permCheckbox: {
        "@media (max-width: 426px)": {
            width: 5,
            height: 5,
        }
    },
    actionOptions: {
        borderRadius: "0px 10px 10px 10px", background: '#F3F5FF',
        "@media (max-width: 426px)": {
            width: 125,

        }
    },
    options: {
        "@media (max-width: 426px)": {
            width: 110,

        }
    },
    userDeptCount: {
        width: 25, height: 25, border: '2px solid #4D72F8', background: '#FFFFFF', borderRadius: 50,
        textAlign: 'center', marginLeft: '10px',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        color: '#4D72F8',
        "@media (max-width: 426px)": {
            fontSize: 10,
            width: 20,
            height: 20,
            borderRadius: 50,
            marginLeft: '5px',
        }
    },
    card: {
        borderRadius: "6px",
        "@media (max-width: 426px)": {
            width: 350,

        }
    },
    users: {
        borderRadius: "nullpx",
        fontFamily: "Calibri",
        fontSize: "20px",
        color: '#4D72F8',
        "@media (max-width: 426px)": {
            fontSize: "12px",

        }
    },
    divider: {
        backgroundColor: '#D2D2D2',
        marginTop: -10,
        border: "1px solid #D2D2D2"
    },
    add: {
        background: "#4D72F8",
        border: " 1px solid #F0EEEE",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        color: '#FFFFFF',
        fontSize: '15px',
        padding: '8px 20px',
        textTransform: 'capitalize',
        float: 'right',
        '&:hover': {
            background: '#4D72F8',
            color: '#FFFFFF',
            alignItems: 'center'
        },
        "@media (max-width: 426px)": {
            width: 55,
            height: 25,
            fontSize: 8,
            padding: '0px 0px',
        }
    },
    //     addcard: {
    //         background: "#F7F7F7",
    //         borderRadius: "5px",
    //         height: "85px",
    //         width: "1027px",
    //         left: "332px",
    //         top: "253px",


    // }
    textfield: {
        width: 380,
        height: 45,
        color: '#B4B3B3',
        marginRight: 50,
        "@media (max-width: 426px)": {
            marginRight: 0,
            width: 110,
            height: 25,
            marginBottom: 0
        }
        // background:'#B4B3B3'
    },
    selectperm: {
        "@media (max-width: 426px)": {
            width: 125,
            height: 25,
            marginTop: 0,
        }
    },
    permissionSelect: {
        width: '150px',
        padding: "0px 0px",
        marging: '0px 0px',
        background: '#FFFFFF',
        border: "1px solid",
        borderRadius: 4,
    },
    search: {
        color: '#B4B3B3',
        "@media (max-width: 426px)": {
            width: 5,
            height: 5,
        }
        // 
    },
    permission: {
        // background: '#F7F7F7',
        borderRadius: '5px',
        color: '#FFFEFE',
        marginTop: 80,
        marginBottom: 45,
        padding: 10,
        paddingLeft: 15,
        height: 85,
    },
    table: {
        background: '#FFFFFF',
        border: '1px solid rgba(194, 194, 194, 0.47)',
        borderRadius: '5px',
        marginTop: 40,
        "@media (max-width: 426px)": {
            marginTop: 40,

        }
    },
    text: {
        padding: 15,
        fontSize: 16,
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: 0.21553,
        color: '#000000BF',
        "@media (max-width: 426px)": {
            padding: 8,
            fontSize: 12,
        }
    },
    body: {
        fontFamily: 'Work Sans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        color: '#929292',
        "@media (max-width: 426px)": {
            // padding: 8,
            fontSize: 12,
        }
    },
    usersContainer:{
        // "@media (max-width: 426px)": {
        //    width:450
        // }
    },
    avatar: {
        color: '#4D72F8',
        background: '#FFFFFF',
        border: '1px solid #4D72F8'
    },
    avatarflex: {
        display: 'flex',
        gap: 15
    },
    iconClor: {
        color: '#787878',
        fontWeight: 'bold',
        "@media (max-width: 426px)": {
            width: 15,
            height: 15
        }
    },
    textColor: {
        color: '#464646',
        marginLeft: 10,
        fontWeight: 'bold',
        "@media (max-width: 426px)": {
            marginLeft: 5,
            fontsize: 12
        }
    },
    add_user_popup: {
        width: 680, height: 220,
        "@media (max-width: 426px)": {
            width: 370, height: 120
        }
    },
    delete_user_popup: {
        width: "500px",
        "@media (max-width: 426px)": {
            width: 370, height: 80
        }
    },
    deleteModalBtn: {
        marginRight: '5px', background: '#4D72F8', color: 'white',
        "@media (max-width: 426px)": {
            width: 50, height: 25, fontsize: "8px",
            textTransform: 'none'
        }
    },
    updatemodalpopup: {
        width: 888, height: 500, overflowY: 'auto',
        "@media (max-width: 426px)": {
            width: 380, height: 300, overflowX: 'auto',
        }
    },
    adduserModal:{
       width: 988, height: 530,
       "@media (max-width: 426px)": {
        width: 380, height: 400
    }
    },
    primary: {
        background: 'rgba(77, 114, 248, 0.4)',
        color: '#4D72F8',
        padding: '3px 10px',
        borderRadius: 5,
        fontWeight: 600
    },
    smsbtn: {
        background: 'rgba(219, 94, 162, 0.4)',
        color: '#4D72F8',
        padding: '3px 6px',
        borderRadius: 5,
        fontWeight: 600
    },
    addNumber: {
        color: '#4D72F8',
        padding: '5px 15px',
        borderRadius: 5,
        fontWeight: 600,
        textTransform: 'capitalize',
    }
}))

export const PrettoSlider = withStyles({
    root: {
        color: '#4D72F8',
        width: '75%',
        marginLeft: 10,
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',

        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 10,
        borderRadius: 14,
    },
    rail: {
        height: 10,
        borderRadius: 14,
    },

})(Slider);
import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, ClickAwayListener, Divider, Grid, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { useStyles } from './styles'
import Badge from '@mui/material/Badge';
import { MoreHoriz } from '@material-ui/icons';
import Backdrop from '../../../components/Modal/Backdrop';
import Modal from '../../../components/Modal/Modal';
import axiosInstance from '../../../environment/axiosInstance'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import EditDeskphone from './EditDeskphone';
import ManageNumbers from './ManageNumbers/ManageNumbers';
import Moment from 'moment';
toast.configure()

function RoomPhomeTableBody(props: any) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    const [deleteModal, setdeleteModal] = React.useState(false)
    const [editDeskPhone, setEditDeskPhone] = React.useState<any>(false)
    const { email, name, primaryNumber, _id, status, directoryEnabled } = props?.roomData;
    const [deleteId, setDeleteId] = React.useState<any>()
    const [editId, setEditId] = React.useState<any>()
    const [openManagePhoneNumber, setOpenManagePhoneNumber] = React.useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handlePopupModalEditdeskphone = (id: any) => {
        setEditDeskPhone(!editDeskPhone)
        setEditId(id)
    }

    const handlePopupModalManagePhoneNumber = (id: any) => {
        setOpenManagePhoneNumber(!openManagePhoneNumber)
        setEditId(id)
    }
    const handleManagePhoneNumber = () => {
        setOpenManagePhoneNumber(!openManagePhoneNumber)
    }
    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const handleDeskphoneDelete = (id: any) => {
        setDeleteId(id)
        setdeleteModal(!deleteModal)
    }

    const roomPhoneDelete = (id: any) => {
        axiosInstance.delete(`/deskphone/roomphone?id=${id}`).then((res: any) => {
            if (res.status === 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 1000
                })
                setdeleteModal(!deleteModal)
                props.fetchRoomPhones()
            }
        }).catch((err: any) => {

        })
    }


    // const [ph, setPh] = React.useState([])
    // console.log('ph', ph);
    // React.useEffect(() => {
    //     if (props.roomData) {
    //         props.roomData.phone?.map((ph: any) => {
    //             return (
    //                 setPh(ph)
    //             )
    //         })
    //     }
    // }, [props.roomData])

    var dataa = Moment(props.roomData?.lastLogin).format('YYYY-MM-DD HH:mm:ss')
    // console.log(dataa)
    return (
        <>
            <div>
                {deleteModal && (
                    <div>
                        <Backdrop handlePopupModal={handleDeskphoneDelete} />
                        <Modal title="Are you sure want to Disable this Room phone ?"
                            handlePopupModal={handleDeskphoneDelete}>
                            <div className="add_user_popup" style={{ width: "500px" }}>
                                <Grid container spacing={2} style={{ margin: 10, }}>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            size="medium"
                                            onClick={() => roomPhoneDelete(deleteId)}
                                            className={classes.deleteButton} >
                                            Disable
                                        </Button>
                                        <Button size="medium" className={classes.cancelButton}
                                            onClick={handleDeskphoneDelete}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Modal>
                    </div>
                )}
            </div>
            <div>
                {
                    editDeskPhone && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalEditdeskphone} />
                            <Modal title="Edit Room Phone" handlePopupModal={handlePopupModalEditdeskphone}>
                                <div style={{ height: 600, overflowY: 'scroll' }}>
                                    <EditDeskphone editId={editId} fetchRoomPhones={props.fetchRoomPhones} handlePopupModalEditdeskphone={handlePopupModalEditdeskphone} />
                                </div>
                            </Modal>
                        </div>
                    )
                }
            </div>
            <div>
                {
                    openManagePhoneNumber && (
                        <div>
                            <Backdrop handlePopupModal={handlePopupModalManagePhoneNumber} />
                            <Modal title="Manage Numbers" handlePopupModal={handlePopupModalManagePhoneNumber}>
                                <div >
                                    <ManageNumbers editId={editId} handleManagePhoneNumber={handleManagePhoneNumber} />
                                </div>
                            </Modal>
                        </div>
                    )
                }
            </div>
            <TableRow>
                <TableCell className={classes.tableCell}>{name}</TableCell>
                <TableCell className={classes.tableCell}>
                    {props.roomData.outboundPhone?.map((ph: any) =>
                    (<>
                        <span>{ph}</span><br />
                    </>
                    )
                    )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {props.roomData.phone?.map((ph: any) =>
                    (<>
                        <span>{ph}</span><br />
                    </>
                    )
                    )}

                </TableCell>
                <TableCell className={classes.tableCell}>{props.roomData?.macAddress}</TableCell>
                <TableCell className={classes.tableCell}>
                    {
                        props.roomData?.onlineStatus === true ?
                            <span>
                                <Badge variant='dot' color='success' />
                                <span style={{ marginLeft: 15 }}>{dataa}</span>
                            </span> :
                            <div>
                                <Badge variant='dot' color='error' />
                                <span style={{ marginLeft: 15 }}>{dataa}</span>
                            </div>
                    }
                </TableCell>
                <TableCell>
                    <Button className={classes.actionButton} size="small">
                        <MoreHoriz className={classes.adminIcon}
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        />
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        // placement === "right" ? "right bottom" : "center left",
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}>
                                <Paper style={{ border: '1px solid #D9D9D9', borderRadius: "5px", background: '#FFFFFF', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)' }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem className={classes.delete} onClick={() => handleDeskphoneDelete(_id)}>
                                                <ListItemIcon style={{ alignItems: 'center' }}>
                                                    {/* <AiTwotoneDelete className={classes.iconClor} /> */}
                                                    <ListItemText className={classes.textColor}>Disable</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem>
                                            <MenuItem className={classes.delete}
                                                onClick={() => handlePopupModalEditdeskphone(props.roomData?._id)}
                                            >
                                                <ListItemIcon style={{ alignItems: 'center' }}>
                                                    <ListItemText className={classes.textColor}>Edit</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem>
                                            {/* <MenuItem className={classes.delete}
                                                onClick={()=>handlePopupModalManagePhoneNumber(_id)}
                                            >
                                                <ListItemIcon style={{ alignItems: 'center' }}>
                                                    <ListItemText className={classes.textColor}>Manage phone Numbers</ListItemText>
                                                </ListItemIcon>
                                            </MenuItem> */}


                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </TableCell>
            </TableRow>
        </>
    )
}

export default RoomPhomeTableBody
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CardMedia, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { useStyles } from './styles'
import CardLogo from '../../../../assets/images/cardLogo.png'
import { Link } from 'react-router-dom'
import Backdrop from '../../../../components/Modal/Backdrop'
import Modal from '../../../../components/Modal/Modal'
import Newporting from './Newporting'

function PortNumbers() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const [portOpen, setPortOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }
  const handlePortOpening = () => {
    setPortOpen(!portOpen)
  }
  return (
    <div >
      {
        portOpen && (
          <div>
            <Backdrop handlePopupModal={handlePortOpening} />
            <Modal title="New Porting Request" handlePopupModal={handlePortOpening}>
              <div style={{ width: '600px', padding: '10px', overflowY: 'scroll', height: '540px' }}>
                <Newporting handlePopupModal={handlePortOpening} />
              </div>
            </Modal>
          </div>
        )
      }
      <Card className={classes.card}>
        <Accordion
          expanded={open} onChange={handleOpen}>
          <AccordionSummary
            expandIcon={open ? <FiMinusCircle color="#2645ad" onClick={handleOpen} /> : <FiPlusCircle color="#2645ad" onClick={handleOpen} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} onClick={handleOpen}>Numbers Porting</Typography>
          </AccordionSummary>
          <Divider className={classes.divider} />
          <AccordionDetails className={classes.accordianNoFlex}>
            <CardContent>
              <Card className={classes.contentCard}>
                <CardContent className={classes.cardContent}>
                  <CardMedia className={classes.media} image={CardLogo} title="Contemplative Reptile" />
                </CardContent>
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.requestText}>
                    You Dont Have Any Requests For The Number Porting
                  </Typography>
                </CardContent>
                <Typography className={classes.selfRequestText}>
                  Self-serve port request are only available for US local numbers. Contact Support for
                  canadian, hawalian and international numbers
                </Typography>
                <Link to=''>
                  <Typography className={classes.portRequestText}>Learn More about Porting</Typography>
                </Link>
                <CardContent className={classes.buttonContent}>
                  <Button
                    variant='outlined'
                    color='primary'
                    className={classes.button}
                    onClick={handlePortOpening}
                  >
                    Start new post request
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonHeadset}
                  >
                    Contact Support
                  </Button>
                </CardContent>
              </Card>
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </Card>
    </div>
  )
}

export default PortNumbers
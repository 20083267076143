import React from 'react'
import './layout.css'
import Sidebar from '../Sidebarcomponent/Sidebar'
import HeaderComponent from '../Header/HeaderComponent'
import { Card } from '@material-ui/core'
import { BiMinusBack } from 'react-icons/bi'
import OutgoingCall from '../CallingPage/OutgoingCall'
import { useSelector, useDispatch } from 'react-redux'
import IncomingCall from '../CallingPage/IncomingCall'
import * as CallActionCreator from '../../store/Actions/CallActionCreator'
import NewIncoming from '../../containers/CallingPage/NewIncoming'
import NewOutgoing from '../CallingPage/NewOutgoing'
import NewHeader from '../Header/NewHeader'
import SidebarBackdrop from '../../components/SidebarPopUpModal/SidebarBackdrop'
import SidebarPopUpModal from '../../components/SidebarPopUpModal/SidebarPopUpModal'
import SidebarResponsive from '../Sidebarcomponent/SidebarResponsive/SidebarResponsive'

function NewLayout(props: any) {
    const dispatch = useDispatch()
    const [minimise, setMinimise] = React.useState(true)
    const callingData: any = useSelector((state: any) => state.CallingData);
    const [Incomingminimise, setIncominMinimise] = React.useState(true)
    const [callStatus, setCallStatus] = React.useState(false)
    const [status, setStatus] = React.useState(false)
    const [sidebar, setSidebar] = React.useState(false)

    //start timer

    const handleMinimise = () => {
        setMinimise(!minimise)
    }

    React.useEffect(() => {
        if (props.callStatus === "Accepted") {

        }
        else if (props.callStatus === "ended") {
            setStatus(false)

            dispatch(CallActionCreator.fetchStatus(null))
            dispatch(CallActionCreator.fetchData(false))
        }

    }, [props.callStatus])

    React.useEffect(() => {
        setStatus(callingData?.CallStatus)
        setMinimise(callingData?.CallStatus)
    }, [callingData?.CallStatus])

    React.useEffect(() => {
        setMinimise(props.answerModal)
    }, [props.answerModal])

    React.useEffect(() => {
        if (props.callStatus === "Accepted") {
            setCallStatus(true)
        } else if (props.callStatus === "ended" || props.callStatus === "failed") {
            setCallStatus(false)
            props.parentEnd()
        }
    }, [props.callStatus])

    const handleEndCall = (child: any) => {
        setStatus(child)
        props.parentEnd()
    }
    const parentMinimise = (child: any) => {
        setMinimise(child)
    }
    const parentIncomingMinimise = (child: any) => {
        setIncominMinimise(child)
    }
    const handleIncomingMinimise = () => {
        setIncominMinimise(!Incomingminimise)
    }

    const handleSidebarSection = () => {
        setSidebar(!sidebar)
    }


    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <div className='sidebar_main_layout'>
                <Sidebar unregister={props.handleLogout} />
            </div>
            <div className="sidebar_container_responsive">
                {
                    sidebar && (
                        <>
                            <SidebarBackdrop handlePopupModal={handleSidebarSection} />
                            <SidebarPopUpModal>
                                <SidebarResponsive handlePopupModal={handleSidebarSection} />
                                {/* <SidebarResponsive handlePopupModal={handleSidebarSection} /> */}
                            </SidebarPopUpModal>
                        </>
                    )
                }
            </div>
            <div className='bodylayout'>
                <NewHeader
                    callOptions={props.options}
                    coolPhone={props.coolPhone}
                    callStatus={props.callStatus}
                    status={props.status}
                    handleLogout={props.handleLogout}
                    handleSidebarPopupModal={handleSidebarSection}
                />
                {/* <div>
                    <HeaderComponent
                        callOptions={props.options}
                        coolPhone={props.coolPhone}
                        callStatus={props.callStatus}
                        status={props.status}
                        handleLogout={props.handleLogout}

                    />
                </div> */}
                <div className="childComponenets">
                    {props.children}
                </div>

                {status ?
                    <div className="footer_container">
                        {/* footer container */}
                        {/* <FooterComponent /> */}
                        {minimise &&
                            <Card style={{ boxShadow: 'none' }}>
                                <div>
                                    <NewOutgoing
                                        parentMinimise={parentMinimise}
                                        callingData={callingData}
                                        callOptions={props.options}
                                        coolPhone={props.coolPhone}
                                        callStatus={callStatus}
                                        status={props.status}
                                        time={props.time}
                                        parentEndCall={handleEndCall}
                                        timer={props.timer}
                                    />

                                    {/* <OutgoingCall
                                        parentMinimise={parentMinimise}
                                        callingData={callingData}
                                        callOptions={props.options}
                                        coolPhone={props.coolPhone}
                                        callStatus={callStatus}
                                        status={props.status}
                                        time={props.time}
                                        parentEndCall={handleEndCall}
                                        timer={props.timer}
                                    /> */}
                                </div>
                            </Card>
                            // :
                            // <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            //     <p>Calling...</p>
                            //     <div style={{ cursor: "pointer" }} onClick={handleMinimise}>
                            //         {/* <AiOutlineMinus /> */}
                            //         <BiMinusBack />
                            //     </div>

                            // </div>
                        }
                    </div>
                    :
                    ""
                }
                {
                    props.answerModal ?
                        <div className="footer_container">
                            {/* footer container */}
                            {/* <FooterComponent /> */}
                            {Incomingminimise &&
                                <Card>
                                    <div>
                                        {/* <IncomingCall
                                        parentMinimise={parentIncomingMinimise}
                                        callModal={props.callModal}
                                        session={props.session}
                                        options={props.options}
                                        coolPhone={props.coolPhone}
                                        callStatus={callStatus}
                                        parentEndCall={props.callModal}
                                        end={props.parentEnd}
                                        timer={props.timer}
                                    /> */}
                                        <NewIncoming
                                            //parentMinimise={parentIncomingMinimise}
                                            callModal={props.callModal}
                                            session={props.session}
                                            options={props.options}
                                            coolPhone={props.coolPhone}
                                            callStatus={callStatus}
                                            parentEndCall={props.callModal}
                                            end={props.parentEnd}
                                            timer={props.timer}
                                            CallRouteFrom={props.CallRouteFrom}
                                            CallRouteFromPhoneNumber={props.CallRouteFromPhoneNumber}
                                        />
                                    </div>
                                </Card>

                            }
                        </div>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default NewLayout
import { Button, Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { useStyles } from './styles';
// import usersAndNumbersServices from '../../userNumbersServices/usersNumbersServices';
import ReactSelect from 'react-select'
import axiosInstance from '../../../../environment/axiosInstance'
import { Multiselect } from "multiselect-react-dropdown";
// import userservices from '../userServices';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

function AddDepartment(props: any) {
  const classes = useStyles();
  const [loading, setloading] = React.useState(false)
  const [companyList, setCompanyList] = React.useState<any>([]);
  const [deptList, setDeptList] = React.useState<any>([]);
  const [selectedDeptList, setSelectedDeptList] = React.useState<any>([]);
  const [prefilledDeptList, setPrefilledDeptList] = React.useState<any>([]);
  const [departmentName, setDepartmentName] = React.useState<any>({
    name: "",
    companyId: ""
  })

  // const onDepartmentChange = (e: any) => {
  //     setDepartmentName(e.target.value)
  // }

  const onDepartmentChange = (e: any) => {
    setDepartmentName((prev: any) => {
      return {
        ...prev,
        name: e.target.value,
      };
    });
  }
  const createDepartment = () => {
    console.log("departmentName", departmentName)
    axiosInstance.post('/departments', departmentName).then((res: any) => {
      if (res.data.data) {
        console.log(res.data)
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT, autoClose: 1000
        }
        )
        props.handlePopupModal()
        props.fetchDepartments()
      }
    }).catch((res: any) => {
      toast.error("Department Name exists in Company", {
        position: toast.POSITION.TOP_RIGHT, autoClose: 1000
      }
      )
    })
  }


  const getcompanyList = () => {
    setloading(true);
    axiosInstance.get('/company').then((res: any) => {
      if (res.data.data) {
        const data = res.data.data
        var List: any = [];
        data.map((item: any) => {
          var object = {
            "value": item._id,
            "label": item.name
          }
          List.push(object)
        })
        setCompanyList(List)
      } else {
        setloading(false)
      }
    }).catch((err: any) => {
      setloading(false)
    })
  }

  React.useEffect(() => {
    getcompanyList()
    // setPrefilledDeptList(props.selectedDeparmentList)
  }, [])

  const preSelectedValues: any = []
  prefilledDeptList?.filter((item: any) => {
    deptList.filter((dep: any) => {
      if (dep.value === item.deptId) {
        if (!preSelectedValues.includes(dep)) {
          preSelectedValues.push(dep)
        }

      }
    })
  })

  const handelCompanySelect = (e: any) => {
    console.log('e', e);
    setDepartmentName((prev: any) => {
      return {
        ...prev,
        companyId: e.value
      }
    })
  }

  return (
    <div>
      <CardContent>
        <Card className={classes.modalCard}>
          <Grid container spacing={2} className={classes.gridItem} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item md={12} sm={12} className={classes.gridItem}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item style={{ width: 280, height: 36 }}>
                <ReactSelect
                  placeholder='Select CompanyName'
                  options={companyList}
                  name="departmentName"
                  onChange={handelCompanySelect}
                  value={companyList.filter((opt: any) => opt.value === departmentName.companyId)}
                />
              </Grid>
              <Grid item style={{ width: 280, height: 38 }}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter Department Name"
                  variant="outlined"
                  //value={departmentName}
                  onChange={onDepartmentChange}
                  style={{ marginLeft: 10 }}
                />
              </Grid>
            </Grid>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
              <Button
                variant="outlined"
                type="submit"
                onClick={createDepartment}
                className={classes.addDeptBtn}
              >
                Add
              </Button>
            </div>
          </Grid>
        </Card>
      </CardContent>
    </div>
  )
}

export default AddDepartment
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector, } from "react-redux";



const ProtectedRoute = (props: any) => {
    const { Component, routes, callOptions } = props;
    const location = useLocation();
    const isAuthenticated: any = localStorage.getItem("user_data");
    const parseData: any = JSON.parse(isAuthenticated)
    const profileDataStore: any = useSelector((state: any) => state.profileData);
    const { profileData, loading } = profileDataStore;
    // console.log('props_____',props.callOptions)

    return (
        <Route {...props} 
            render={ 
            (props) => {
                if (parseData !== null && parseData?.authenticated) {
                    return <Component
                        {...props}
                        routes={routes}
                         callOptions={callOptions}
                        // coolPhone={props.coolPhone}
                        // callStatus={props.callStatus}
                    />
                } else {
                    return <Redirect to="/" />
                }
            }
        }
        />
    )
}


export default ProtectedRoute;